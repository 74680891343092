import { Grid } from '@mui/material';
import Headline, { HEADLINE_SIZE } from 'component/atoms/typographie/Headline';
import Text, { TEXT_COLOR, TEXT_TYPE } from 'component/atoms/typographie/Text';
import { IMAGES } from 'core/const/images';
import TextBlockSection from 'component/molecules/TextBlockSection';
import CheckList from 'component/atoms/CheckList';
import Spacer, { SPACER_SIZE } from 'component/atoms/Spacer';
import { useTranslation } from 'react-i18next';
import Step from 'component/atoms/Step';

const ProSectionTenant = () => {
  const { t: i } = useTranslation('images');
  return (
    <>
      <Grid container justifyContent={'center'}>
        <Grid item xs={12} md={8} paddingTop={{ xs: 4, md: 10 }} paddingBottom={{ xs: 4, md: 10 }}>
          <Headline size={HEADLINE_SIZE.DISPLAY} align={'center'}>
            Dein neues Portal für die Wohnungssuche
          </Headline>
        </Grid>
      </Grid>

      <Grid container alignItems={'center'} paddingTop={6} rowSpacing={4} columnSpacing={24}>
        <Grid item xs={12} sm={6} alignItems={'center'}>
          <TextBlockSection
            headlineSize={HEADLINE_SIZE.H1}
            title={'Exklusive Wohnungen - '}
            titleTwo={'Nur bei Wohnsinn.'}
          >
            <Spacer size={SPACER_SIZE.MD} />
            <Text tag={'span'} color={TEXT_COLOR.TEXT_COLOR_ACCENT} type={TEXT_TYPE.TEXT_TYPE_LANDING}>
              Ein Großteil aller Wohnungen, die du bei Wohnsinn findest, ist exklusiv nur bei uns verfügbar.
            </Text>
          </TextBlockSection>

          <Spacer size={SPACER_SIZE.LG} />

          <CheckList accentColor items={EXCLUSIVE_WOHNSINN} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <img src={i(IMAGES.pros.tenant.exclusive.src)} alt={i(IMAGES.pros.tenant.exclusive.alt)} />
        </Grid>
      </Grid>

      <Grid container alignItems={'center'} paddingTop={12} rowSpacing={4} columnSpacing={24}>
        <Grid item xs={12} sm={6}>
          <TextBlockSection
            headlineSize={HEADLINE_SIZE.H1}
            title={'Weniger Mitbewerber -'}
            titleTwo={'Bessere Chancen.'}
          >
            <Spacer size={SPACER_SIZE.MD} />

            <Text tag={'span'} color={TEXT_COLOR.TEXT_COLOR_ACCENT} type={TEXT_TYPE.TEXT_TYPE_LANDING}>
              Bei Wohnsinn findest du Wohnungen, die perfekt zu deinem Mieterprofil und deinen Anforderungen passen.
            </Text>
          </TextBlockSection>

          <Spacer size={SPACER_SIZE.LG} />

          <CheckList accentColor items={BETTER_CHANCES} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <img src={i(IMAGES.pros.tenant.betterChances.src)} alt={i(IMAGES.pros.tenant.betterChances.alt)} />
        </Grid>
      </Grid>

      <Grid
        container
        alignItems={'center'}
        paddingTop={12}
        paddingBottom={{ xs: 4, md: 10 }}
        rowSpacing={4}
        columnSpacing={24}
      >
        <Grid item xs={12} sm={6}>
          <TextBlockSection headlineSize={HEADLINE_SIZE.H1} title={'Wohnungssuche die'} titleTwo={'Spaß macht'}>
            <Spacer size={SPACER_SIZE.MD} />

            <Text tag={'span'} color={TEXT_COLOR.TEXT_COLOR_ACCENT} type={TEXT_TYPE.TEXT_TYPE_LANDING}>
              Werde in Echtzeit über neue Wohnungen benachrichtigt und bewirb dich mit nur einem Klick mit deinem
              digitalen Mietprofil.
            </Text>
          </TextBlockSection>

          <Spacer size={SPACER_SIZE.LG} />

          <CheckList accentColor items={FUN_SECTION} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <img src={i(IMAGES.pros.tenant.fun.src)} alt={i(IMAGES.pros.tenant.fun.alt)} />
        </Grid>
      </Grid>

      <Grid container paddingTop={{ xs: 4, md: 10 }}>
        <Grid item xs={12}>
          <Headline size={HEADLINE_SIZE.DISPLAY} align={'center'}>
            In 4 Schritten zur Traumwohnung
          </Headline>
        </Grid>
      </Grid>
      <Grid container spacing={4} paddingTop={{ xs: 4, md: 10 }}>
        <Grid item xs={12} md={3}>
          <Step number={1} />
          <Spacer size={SPACER_SIZE.MD} />
          <Headline size={HEADLINE_SIZE.H2}>Erstelle dein Mieterprofil</Headline>
          <Spacer size={SPACER_SIZE.MD} />
          <Text>
            Um dir passgenaue Wohnungen vorschlagen zu können, bitten wir dich, einmalig dein Mieterprofil zu
            vervollständigen. Dieses kannst du dann bei jeder Bewerbung wiederverwenden.
          </Text>
        </Grid>
        <Grid item xs={12} md={3}>
          <Step number={2} />
          <Spacer size={SPACER_SIZE.MD} />
          <Headline size={HEADLINE_SIZE.H2}>Checke deine Matches</Headline>
          <Spacer size={SPACER_SIZE.MD} />
          <Text>
            Klicke dich nun durch deine passenden Wohnungsangebote. Wenn dir eine Wohnung nicht gefällt, kannst du sie
            einfach aussortieren, um jederzeit den Überblick zu behalten.
          </Text>
        </Grid>
        <Grid item xs={12} md={3}>
          <Step number={3} />
          <Spacer size={SPACER_SIZE.MD} />
          <Headline size={HEADLINE_SIZE.H2}>Bewirb dich mit nur einem Klick</Headline>
          <Spacer size={SPACER_SIZE.MD} />
          <Text>
            Wenn dir eine Wohnung gefällt, kannst du dich mit einem Klick mit deinem Mieterprofil bewerben. Alle deine
            Bewerbungen findest du jederzeit übersichtlich in deinem Chat.
          </Text>
        </Grid>
        <Grid item xs={12} md={3}>
          <Step number={4} />
          <Spacer size={SPACER_SIZE.MD} />
          <Headline size={HEADLINE_SIZE.H2}>Erhalte Besichtigungstermine</Headline>
          <Spacer size={SPACER_SIZE.MD} />
          <Text>
            Mit Wohnsinn behältst du immer den Überblick über deine Besichtigungstermine. Du kannst sie bestätigen oder
            stornieren, falls sich deine Pläne ändern.
          </Text>
        </Grid>
      </Grid>
    </>
  );
};
const FUN_SECTION = [
  {
    text: 'One-Click Bewerbung',
    bold: false,
    invert: false,
  },
  {
    text: 'Sortiere uninteressante Wohnungen aus',
    bold: false,
    invert: false,
  },
  {
    text: 'Behalte zu jeder Zeit den Überblick',
    bold: false,
    invert: false,
  },
];

const BETTER_CHANCES = [
  {
    text: 'Weniger Mitbewerber als bei anderen Portalen',
    bold: false,
    invert: false,
  },
  {
    text: 'Keine unnötigen Anfragen mehr verschicken',
    bold: false,
    invert: false,
  },
  {
    text: 'Spare Zeit bei der Suche',
    bold: false,
    invert: false,
  },
];

const EXCLUSIVE_WOHNSINN = [
  {
    text: 'Nur bei Wohnsinn verfügbar',
    bold: false,
    invert: false,
  },
  {
    text: 'Bessere Chancen als bei anderen Portalen',
    bold: false,
    invert: false,
  },
  {
    text: 'Echtzeit-Benachrichtigung bei neuen Wohnungen',
    bold: false,
    invert: false,
  },
];

export default ProSectionTenant;
