import { fetchAndActivate, getValue, RemoteConfig, getBoolean } from '@firebase/remote-config';

export class FirebaseRemoteConfigService {
  private isFetched: boolean;

  constructor(private remoteConfig: RemoteConfig) {
    this.setup();
  }

  private async setup() {
    this.remoteConfig.settings.minimumFetchIntervalMillis = 36000;
    this.remoteConfig.app.automaticDataCollectionEnabled = true;

    if (!this.isFetched) {
      this.isFetched = await fetchAndActivate(this.remoteConfig);
    }
  }

  public async getRemoteConfigValue(parameterName: string): Promise<unknown> {
    if (!this.isFetched) {
      this.isFetched = await fetchAndActivate(this.remoteConfig);
    }
    return getValue(this.remoteConfig, parameterName);
  }

  public async getRemoteConfigBoolean(parameterName: string): Promise<boolean> {
    if (!this.isFetched) {
      this.isFetched = await fetchAndActivate(this.remoteConfig);
    }
    return getBoolean(this.remoteConfig, parameterName);
  }
}
