import { FC } from 'react';
import { IImage } from 'core/const/images';
import { useTranslation } from 'react-i18next';
import Text, { TEXT_TYPE, TEXT_WEIGHT } from 'component/atoms/typographie/Text';
import styles from './MediaCard.module.scss';

interface IMediaCardProps {
  image?: IImage;
  wide?: boolean;
  logo?: IImage;
  description?: string;
  leftTopInfo?: string;
  leftBottomInfo?: string;
  logoWidth?: number;
}

const MediaCard: FC<IMediaCardProps> = ({
  image,
  logo,
  description = '',
  leftTopInfo = '',
  logoWidth = 100,
  leftBottomInfo = '',
  wide = false,
}) => {
  const { t: i } = useTranslation('images');

  return (
    <div className={`${styles.wrapper} ${wide ? styles.wide : ''}`}>
      <img className={styles.image} src={i(image.src)} alt={i(image.alt)} />
      <div className={styles.content}>
        <Text type={TEXT_TYPE.TEXT_TYPE_BODY} weight={TEXT_WEIGHT.TEXT_WEIGHT_LIGHT} tag={'p'}>
          {description}
        </Text>

        <div className={styles.footer}>
          <div>
            <Text type={TEXT_TYPE.TEXT_TYPE_BODY} weight={TEXT_WEIGHT.TEXT_WEIGHT_BOLD} tag={'p'}>
              {leftTopInfo}
            </Text>
            <Text type={TEXT_TYPE.TEXT_TYPE_BODY} weight={TEXT_WEIGHT.TEXT_WEIGHT_BOLD} tag={'p'}>
              {leftBottomInfo}
            </Text>
          </div>
          <img src={i(logo.src)} alt={i(logo.src)} width={logoWidth} />
        </div>
      </div>
    </div>
  );
};
export default MediaCard;
