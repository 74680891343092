import { Grid } from '@mui/material';
import PageTitle from '../../../../molecules/PageTitle';
import { useContext, useState } from 'react';
import UserContext from '../../../../../core/context/user.context';
import { wohnsinnServices } from '../../../../../App';
import { IOrganization, REGISTRATION_STATES } from '@wohnsinn/ws-ts-lib';
import { SUBMIT_BUTTON_MODE } from '../../../../../core/enum/submit-button-mode.enum';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { FormContext } from '../../../../../core/context/form.context';
import InputText from '../../../../atoms/formElement/InputText';
import { useTranslation } from 'react-i18next';
import RegistrationLayout from '../../RegistrationLayout';

export const ORGANIZATION_LEGAL_INFORMATION_SCHEME = {
  imprintLink: Joi.string().allow(''),
  dataProtectionLink: Joi.string().allow(''),
  website: Joi.string().allow(''),
};

const ADD_LEGAL_INFO_FORM_ID = 'add-legal-info';

const CreateLandlordAddOrganizationLogo = () => {
  const [buttonSubmitMode, setButtonSubmitMode] = useState<SUBMIT_BUTTON_MODE>(SUBMIT_BUTTON_MODE.NONE);
  const { landlordProfile } = useContext(UserContext);
  const { userService, organizationService } = wohnsinnServices;
  const { t: v } = useTranslation('common', { keyPrefix: 'view.OrganizationProfileView' });
  const { t } = useTranslation('common');

  const { control, handleSubmit, watch } = useForm<{
    website: string;
    imprintLink: string;
    dataProtectionLink: string;
  }>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    defaultValues: {
      website: '',
      dataProtectionLink: '',
      imprintLink: '',
    },
    resolver: joiResolver(Joi.object(ORGANIZATION_LEGAL_INFORMATION_SCHEME)),
  });

  const handleFormSubmit = async (): Promise<boolean | void> => {
    setButtonSubmitMode(SUBMIT_BUTTON_MODE.SUBMITTING);

    try {
      await organizationService.updateOrganization(watch() as Partial<IOrganization>, landlordProfile);

      handleNextStep();
    } catch (e) {
      console.error('Error on CreateLandlordAddOrganization', e);
    }
  };

  const handleNextStep = () => {
    setTimeout(async () => {
      await userService.updateUserData({ registrationState: REGISTRATION_STATES.ADD_CRM }, landlordProfile.uid);
    }, 500);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <PageTitle
          pageTitle={'Ergänze deine rechtlichen Angaben'}
          secondPageTitle={
            'Deine Informationen zu Webseite, Datenschutz und Impressum ermöglichen uns, eine sichere und transparente Online-Erfahrung zu gewährleisten.'
          }
        />
        <RegistrationLayout
          formId={ADD_LEGAL_INFO_FORM_ID}
          isValidForm={!(!watch('website') && !watch('imprintLink') && !watch('dataProtectionLink'))}
          buttonSubmitMode={buttonSubmitMode}
          secondaryButtonAction={handleNextStep}
        >
          <FormContext.Provider value={{ control }}>
            <form id={ADD_LEGAL_INFO_FORM_ID} onSubmit={handleSubmit(handleFormSubmit, console.error)}>
              <InputText label={t('organization.website.label')} name={'website'} />
              <InputText label={t('organization.imprintLink.label')} name={'imprintLink'} />
              <InputText label={t('organization.dataProtectionLink.label')} name={'dataProtectionLink'} />
            </form>
          </FormContext.Provider>
        </RegistrationLayout>
      </Grid>
    </Grid>
  );
};

export default CreateLandlordAddOrganizationLogo;
