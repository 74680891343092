import styles from './Card.module.scss';
import { FC } from 'react';
import Headline, { HEADLINE_SIZE } from '../typographie/Headline';
import Text, { TEXT_COLOR, TEXT_TYPE } from '../typographie/Text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export enum CARD_COLORS {
  BLUE = 'blue',
  RED = 'red',
  GREEN = 'green',
  GREY = 'grey',
}

export interface ICardProps {
  text: string;
  headline: string;
  subtitle?: string;
  iconSrc: string | IconProp;
  iconAltText?: string;
  color?: CARD_COLORS;
}

const Card: FC<ICardProps> = ({ text, iconAltText, subtitle, headline, iconSrc, color = CARD_COLORS.BLUE }) => {
  return (
    <div className={`${styles.wrapper} ${styles[color]}`}>
      <div>
        <div className={styles.headlineWrapper}>
          <Headline size={HEADLINE_SIZE.H2} tag={'span'}>
            {headline}
          </Headline>
          <FontAwesomeIcon className={styles.arrow} icon={faArrowRight} size={'lg'} />
        </div>
        {subtitle && (
          <Headline size={HEADLINE_SIZE.H3} tag={'span'}>
            {subtitle}
          </Headline>
        )}
        <Text color={TEXT_COLOR.TEXT_COLOR_DARK} tag={'p'} align={'left'} type={TEXT_TYPE.TEXT_TYPE_BODY}>
          {text}
        </Text>
      </div>

      <div className={styles.iconWrapper}>
        {typeof iconSrc === 'string' ? (
          <img className={styles.icon} src={iconSrc} alt={iconAltText} />
        ) : (
          <FontAwesomeIcon size={'2x'} icon={iconSrc} />
        )}
      </div>
    </div>
  );
};

export default Card;
