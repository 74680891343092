import { FC } from 'react';
import { IApartmentMainInformation, RENT_TYPE } from '@wohnsinn/ws-ts-lib';
import ItemList, { TItemListItem } from '../ItemList';
import { useTranslation } from 'react-i18next';
import printDate from '../../../core/helper/printDate';
import { RENT_TYPE_SELECT_OPTION_LIST } from 'component/forms/apartment/ApartmentCostsForm';

const ApartmentRent: FC<{ mainInfo: IApartmentMainInformation; aptRentType: RENT_TYPE }> = ({
  mainInfo,
  aptRentType,
}) => {
  const { t } = useTranslation('common');
  const { t: a } = useTranslation('common', { keyPrefix: 'component.molecules.ApartmentRent' });
  const items: TItemListItem[] = [];

  // einzugsdatum
  if (mainInfo?.earliestMoveIn) {
    items.push({
      key: 'earliestMoveIn,',
      label: a('moveIn'),
      value: mainInfo?.earliestMoveIn,
    });
  }

  // befristet
  if (mainInfo?.hasFixedTerm) {
    items.push({
      key: 'hasFixedTerm,',
      label: a('fixedTerm'),
      value: mainInfo?.hasFixedTerm
        ? mainInfo?.fixedTerm
          ? printDate(mainInfo?.fixedTerm, t)
          : t('yes')
        : a('noFixedTerm'),
    });
  }

  // mindesmietzeit
  items.push({
    key: 'minPeriod,',
    label: a('minPeriod'),
    value: mainInfo?.hasMinPeriod ? `${mainInfo?.minPeriod}` : a('noMinPeriod'),
  });

  // mietart
  if (aptRentType) {
    const rentType = RENT_TYPE_SELECT_OPTION_LIST.filter((list) => list.value === aptRentType)[0]?.label;
    items.push({
      key: 'rentType',
      label: a('rentType'),
      value: t(rentType),
    });
  }

  return <ItemList testId={'apartmentRent'} items={items} />;
};

export default ApartmentRent;
