import { interNumbersSemiBold } from 'utilities/interFontFamily.js';
import { Component, h } from 'preact';
import { Color } from 'utilities/color.js';
import { unescapeHtml } from 'utilities/core.js';
import { flexibleDuration } from 'utilities/duration.js';
import { cachedDetect } from 'utilities/detect.js';
import { standardSvgAttrs } from 'utilities/svg_boilerplate.js';
import { getTranslation } from '../../../../../shared/translations.js';

const detect = cachedDetect();

export class BigPlayButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHovering: false,
    };
  }

  render() {
    const { baseHeight, baseWidth, elemRef, scale } = this.props;
    const svgAttrs = standardSvgAttrs({
      width: baseWidth,
      height: baseHeight,
      styleOverride: { position: 'absolute', height: `${baseHeight * scale}px` },
      ariaHidden: true,
    });
    return (
      <div
        class="w-bpb-wrapper w-css-reset w-css-reset-tree"
        ref={elemRef}
        style={this.wrapperStyle()}
      >
        <button
          class="w-big-play-button w-css-reset-button-important w-vulcan-v2-button"
          style={this.buttonStyle()}
          onMouseEnter={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
          onfocusin={this.onFocusIn}
          onfocusout={this.onFocusOut}
          aria-label={`${this.translate('TITLE_WHEN_NOT_PLAYING')}: ${unescapeHtml(
            this.props.videoName,
          )}`}
          tabIndex={this.props.buttonTabIndex}
          type="button"
        >
          <div style={this.blendStyle()} />
          <div style={this.overlayStyle()} />
          <svg {...svgAttrs} alt="">
            <rect
              fill-rule="evenodd"
              clip-rule="evenodd"
              fill="none"
              width={baseWidth}
              height={baseHeight}
            />
            <polygon
              fill-rule="evenodd"
              clip-rule="evenodd"
              fill="#FFFFFF"
              points="53,22 53,58 79,40"
            />
          </svg>
        </button>
        {this.props.showBpbTime && this.props.isLiveMedia !== true ? (
          <div class="w-bpb-time" style={this.timeStyle()}>
            {flexibleDuration(this.props.duration)}
          </div>
        ) : null}
      </div>
    );
  }

  translate(key) {
    return getTranslation(this.props.playerLanguage.code, `PLAY_BUTTON_${key}`);
  }

  wrapperStyle() {
    const {
      baseHeight,
      baseWidth,
      borderRadius,
      isVisible,
      leftNudgeFraction,
      controlBarDistance,
      topNudgeFraction,
      scale,
    } = this.props;
    const width = baseWidth * scale;
    const height = baseHeight * scale;
    return {
      borderRadius: `${borderRadius}px`,
      display: isVisible ? 'block' : 'none',
      left: `calc(50% + ${(leftNudgeFraction || 0) * 100}%)`,
      marginLeft: `-${width / 2}px`,
      marginTop: `-${height / 2}px`,
      overflow: 'hidden',
      position: 'absolute',
      top: `calc(50% + ${(topNudgeFraction || 0) * 100}% - ${controlBarDistance}px)`,
    };
  }

  shouldMixBlendMode() {
    const { noMixBlendMode } = this.props;
    return !detect.edge && !noMixBlendMode;
  }

  blendStyle() {
    const { baseHeight, baseWidth, color: colorStr, scale } = this.props;
    const color = new Color(colorStr || '#000').alpha(1);
    return {
      background: color.toRgba(),
      display: this.shouldMixBlendMode() ? 'block' : 'none',
      left: 0,
      height: `${baseHeight * scale}px`,
      mixBlendMode: 'darken',
      position: 'absolute',
      top: 0,
      width: `${baseWidth * scale}px`,
    };
  }

  overlayStyle() {
    const { baseHeight, baseWidth, color: colorStr, scale } = this.props;
    const { isHovering } = this.state;
    const color = new Color(colorStr || '#000');

    color.alpha(0.7);

    if (!this.shouldMixBlendMode()) {
      color.alpha(0.85);
    }

    if (isHovering) {
      color.lighten('15%');
    }

    return {
      backgroundColor: color.toRgba(),
      height: `${baseHeight * scale}px`,
      left: 0,
      position: 'absolute',
      top: 0,
      transition: 'background-color 150ms',
      width: `${baseWidth * scale}px`,
    };
  }

  buttonStyle() {
    const { scale, baseHeight, baseWidth } = this.props;
    return {
      cursor: 'pointer',
      height: `${baseHeight * scale}px`,
      boxShadow: this.state.isKeyboardFocused ? '0 0 0 2px #fff inset' : 'none',
      width: `${baseWidth * scale}px`,
    };
  }

  timeStyle() {
    const { scale } = this.props;
    return {
      background: 'rgba(0,0,0,.4)',
      color: '#fff',
      fontFamily: interNumbersSemiBold,
      fontSize: `${18 * scale}px`,
      lineHeight: `${30 * scale}px`,
      pointerEvents: 'none',
      textAlign: 'center',
    };
  }

  onMouseEnter = () => {
    this.setState({ isHovering: true });
  };

  onMouseLeave = () => {
    this.setState({ isHovering: false });
  };

  onFocusIn = () => {
    this.setState({ isHovering: true });
  };

  onFocusOut = () => {
    this.setState({ isHovering: false });
  };
}
