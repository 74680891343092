import Headline, { HEADLINE_SIZE } from '../../atoms/typographie/Headline';
import Spacer from '../../atoms/Spacer';
import { Swiper, SwiperSlide } from 'swiper/react';
import CollectionCard from '../../molecules/Cards/CollectionCard';
import { IMAGES } from '../../../core/const/images';
import { BERLIN, BONN, COLOGNE, LEVERKUSEN } from '../../../core/const/city-locations';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../core/const/routes';
import { useTranslation } from 'react-i18next';

const CitySlider = () => {
  const navigate = useNavigate();
  const { t: r } = useTranslation('routes');

  const updateTenantFilterParams = (newParams: any) => {
    const url = new URL(window.location.href);
    // Create Url Param for each key
    Object.keys(newParams).forEach((key) => {
      if (newParams[key] != null) {
        if (key === 'location' || key === 'equipmentList') {
          url.searchParams.set(key, JSON.stringify(newParams[key]));
        } else {
          url.searchParams.set(key, newParams[key]);
        }
      }
    });

    if (url.searchParams.has('searchId')) {
      url.searchParams.delete('searchId');
    }

    navigate(`${r(ROUTES.staticRoutes.searchTunnel.path)}${url.search}`);
  };

  const handleCityClick = (city: string) => {
    switch (city) {
      case 'COLOGNE':
        updateTenantFilterParams({ location: COLOGNE });
        break;
      case 'LEVERKUSEN':
        updateTenantFilterParams({ location: LEVERKUSEN });
        break;
      case 'BONN':
        updateTenantFilterParams({ location: BONN });
        break;
      case 'BERLIN':
        updateTenantFilterParams({ location: BERLIN });
        break;
    }
  };
  return (
    <>
      <Headline size={HEADLINE_SIZE.DISPLAY} align={'center'}>
        Beliebte Städte bei Wohnsinn
      </Headline>
      <Spacer />
      <Swiper
        slidesPerView={4}
        breakpoints={{ 320: { slidesPerView: 1.3, centeredSlides: true }, 768: { slidesPerView: 4 } }}
        spaceBetween={16}
      >
        <SwiperSlide>
          <CollectionCard onClick={() => handleCityClick('COLOGNE')} title={'Köln'} img={IMAGES.cities.cologne} />
        </SwiperSlide>
        <SwiperSlide>
          <CollectionCard onClick={() => handleCityClick('BONN')} title={'Bonn'} img={IMAGES.cities.bonn} />
        </SwiperSlide>
        <SwiperSlide>
          <CollectionCard
            onClick={() => handleCityClick('LEVERKUSEN')}
            title={'Leverkusen'}
            img={IMAGES.cities.leverkusen}
          />
        </SwiperSlide>
        <SwiperSlide>
          <CollectionCard onClick={() => handleCityClick('BERLIN')} title={'Berlin'} img={IMAGES.cities.berlin} />
        </SwiperSlide>
      </Swiper>
    </>
  );
};

export default CitySlider;
