import CTACard from 'component/molecules/Cards/CTACard';
import { Grid } from '@mui/material';
import Headline, { HEADLINE_SIZE } from 'component/atoms/typographie/Headline';
import { ROUTES } from 'core/const/routes';
import { useTranslation } from 'react-i18next';
import LandingPageLayout from '../../../component/layouts/LandingPageLayout';
import { IMAGES } from 'core/const/images';
import FirstSection from 'component/organisms/landing-page-sections/FirstSection';
import ContactSection from '../../../component/organisms/landing-page-sections/ContactSection';
import FAQSection from '../../../component/organisms/landing-page-sections/FAQSection';
import { USER_TYPE } from '@wohnsinn/ws-ts-lib';
import Text, { TEXT_COLOR, TEXT_TYPE } from 'component/atoms/typographie/Text';
import SuccessPartnerSection from 'component/organisms/landing-page-sections/SuccessPartnerSection';
import CustomersSection from 'component/organisms/landing-page-sections/CustomersSection';
import ProSectionLandlord from 'component/organisms/landing-page-sections/ProSection/ProSectionLandlord';
import CustomerTestimonialsSection from 'component/organisms/landing-page-sections/CustomerTestimonialsSection';
import KnownFromSection from 'component/organisms/landing-page-sections/KnownFromSection';

const LandlordLandingView = () => {
  const { t: a } = useTranslation('common', { keyPrefix: 'view.general.AboutUsView' });
  const { t: r } = useTranslation('routes');
  const { t: i } = useTranslation('images');
  const { t: l } = useTranslation('common', { keyPrefix: 'view.LandlordLanding.hero' });

  return (
    <LandingPageLayout pageTitle={r(ROUTES.landingPageRoutes.landlord.title)}>
      <Grid
        item
        xs={12}
        paddingTop={{ xs: 4, md: 10 }}
        paddingBottom={{ xs: 4, md: 10 }}
        className={'ws-full-width-bg'}
      >
        <FirstSection
          title={l('headline_one')}
          titleTwo={l('headline_two')}
          ctaText={'Kostenlos Objekt inserieren'}
          ctaLink={r(ROUTES.staticRoutes.registration.createRegistration.path)}
          thumbnailId={'hljr4dorh0'}
          videoId={'8s2dueci7b'}
        />
      </Grid>

      <Grid
        item
        xs={12}
        className={'ws-full-width-bg ws-full-width-bg--white'}
        paddingTop={{ xs: 4, md: 10 }}
        paddingBottom={{ xs: 4, md: 10 }}
      >
        <Text type={TEXT_TYPE.TEXT_TYPE_BODY} color={TEXT_COLOR.TEXT_COLOR_ACCENT} align={'center'}>
          {a('customers.smallHeadline')}
        </Text>
        <KnownFromSection />
      </Grid>

      <Grid
        item
        xs={12}
        className={`ws-full-width-bg `}
        paddingTop={{ xs: 4, md: 10 }}
        paddingBottom={{ xs: 4, md: 10 }}
      >
        <SuccessPartnerSection />
      </Grid>

      <Grid
        item
        xs={12}
        paddingTop={{ xs: 4, md: 10 }}
        paddingBottom={{ xs: 4, md: 10 }}
        className={'ws-full-width-bg ws-full-width-bg--white'}
      >
        <Grid container justifyContent={'center'}>
          <Grid item xs={12} md={8}>
            <Headline size={HEADLINE_SIZE.DISPLAY} align={'center'}>
              Kommen dir diese Probleme bekannt vor?
            </Headline>
          </Grid>
        </Grid>

        <Grid container spacing={4} paddingTop={{ xs: 4, md: 10 }} paddingBottom={{ xs: 4, md: 10 }}>
          <Grid item xs={12} md={4}>
            <CTACard
              imgHeight={48}
              headlineSize={HEADLINE_SIZE.H3}
              title={'Zu viele unqualifizierte Mietanfragen'}
              text={
                'Mietinteressenten bewerben sich auf deine Wohnung, obwohl sie nicht deinen Anforderungen entsprechen.'
              }
              textColor={TEXT_COLOR.TEXT_COLOR_ACCENT}
              imgSrc={i(IMAGES.pictograms.thumbDown.src)}
              imgAltText={i(IMAGES.pictograms.thumbDown.alt)}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CTACard
              imgHeight={48}
              headlineSize={HEADLINE_SIZE.H3}
              title={'Überfülltes E-Mail Postfach'}
              text={
                'Kurz nach der Veröffentlichung läuft dein Postfach über und du hast Mühe, den Überblick zu behalten.'
              }
              textColor={TEXT_COLOR.TEXT_COLOR_ACCENT}
              imgSrc={i(IMAGES.pictograms.openMail.src)}
              imgAltText={i(IMAGES.pictograms.openMail.alt)}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CTACard
              imgHeight={48}
              headlineSize={HEADLINE_SIZE.H3}
              title={'Hohe Inseratskosten auf anderen Portalen'}
              text={'Andere Portale verlangen hohe Inseratskosten, ohne die Vermietung effizient zu unterstützen.'}
              textColor={TEXT_COLOR.TEXT_COLOR_ACCENT}
              imgSrc={i(IMAGES.pictograms.dollarSign.src)}
              imgAltText={i(IMAGES.pictograms.dollarSign.alt)}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        className={'ws-full-width-bg'}
        paddingTop={{ xs: 4, md: 10 }}
        paddingBottom={{ xs: 4, md: 10 }}
      >
        <ProSectionLandlord />
      </Grid>

      <CustomerTestimonialsSection />

      <Grid item xs={12} className={'ws-full-width-bg ws-full-width-bg--white'} paddingTop={{ xs: 4, md: 10 }}>
        <CustomersSection />
      </Grid>

      <Grid
        item
        xs={12}
        paddingTop={{ xs: 4, md: 10 }}
        paddingBottom={{ xs: 4, md: 10 }}
        className={'ws-full-width-bg ws-full-width-bg--white'}
      >
        <CTACard
          headlineSize={HEADLINE_SIZE.H1}
          alternativeStyle
          title={'Jetzt kostenlos inserieren'}
          text={'Über 150 Verwalter nutzen bereits Wohnsinn.'}
          ctaText={'Objekt inserieren'}
          shrinkButton
          link={r(ROUTES.staticRoutes.registration.createRegistration.path)}
        />
      </Grid>

      <Grid
        item
        xs={12}
        paddingTop={{ xs: 4, md: 10 }}
        paddingBottom={{ xs: 4, md: 10 }}
        className={'ws-full-width-bg ws-full-width-bg--white'}
      >
        <ContactSection />
      </Grid>

      <Grid
        item
        className={'ws-full-width-bg ws-full-width-bg--white'}
        xs={12}
        paddingTop={{ xs: 4, md: 10 }}
        paddingBottom={{ xs: 8, md: 20 }}
      >
        <FAQSection userType={USER_TYPE.LANDLORD} />
      </Grid>
    </LandingPageLayout>
  );
};

const COMMERCIAL_LOGOS = [
  IMAGES.logos.digihub,
  IMAGES.logos.googleForStartups,
  IMAGES.logos.group,
  IMAGES.logos.handelsblatt,
  IMAGES.logos.deutscheStartups,
  IMAGES.logos.nrw,
  IMAGES.logos.startupInsider,
  IMAGES.logos.startupValley,
  IMAGES.logos.theProperty,
  IMAGES.logos.zia,
];

export default LandlordLandingView;
