import ModalWrapper from '../ModalWrapper';
import { useTranslation } from 'react-i18next';
import { FC, useContext } from 'react';
import CropImage from 'component/atoms/CropImage';
import ModalContext from 'core/context/modal.context';

export interface ICropImageModalProps {
  image: Blob | MediaSource;
  onCropFinish: (blobPng: Blob) => void;
  x?: number;
  y?: number;
  dx?: number;
  dy?: number;
  width?: number;
  height?: number;
  isCircularCrop?: boolean;
}

const CropImageModal: FC = () => {
  const { t } = useTranslation('common');
  const { modalData } = useContext(ModalContext);

  return (
    <ModalWrapper title={t('createProfileImage')}>{modalData?.image && <CropImage {...modalData} />}</ModalWrapper>
  );
};

export default CropImageModal;
