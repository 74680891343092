import { SUBMIT_BUTTON_MODE } from 'core/enum/submit-button-mode.enum';
import { useForm } from 'react-hook-form';
import { useContext, useState } from 'react';
import { SEARCH_TUNNEL_STEPS } from 'view/static/SearchTunnelView';
import { SearchTunnelStepContext } from 'core/context/search-tunnel-step.context';
import AddressFinderInput from 'component/molecules/AddressFinderInput';
import { ILocationSelection, ITenantURLParams } from '@wohnsinn/ws-ts-lib';
import TenantFilterParamsContext from '../../../../core/context/tenant-filter-params.context';
import { convertUrlParamsToTFP } from '../../../../core/helper/convert-url-params-to-tfp';
import SearchTunnelStepLayout from '../SearchTunnelStepLayout';
import { useTranslation } from 'react-i18next';

const SELECT_RADIUS_FORM_ID = 'SELECT_RADIUS_FORM_ID';
const SelectLocationStep = () => {
  const { t: s } = useTranslation('common', { keyPrefix: 'component.organisms.searchTunnel.selectLocationStep' });
  const { setCurrentSearchTunnelStep } = useContext(SearchTunnelStepContext);
  const {
    tenantFilterParams,
    updateTenantFilterParams,
    tenantFilterParams: { location },
  } = useContext(TenantFilterParamsContext);
  const [buttonSubmitMode, setButtonSubmitMode] = useState<SUBMIT_BUTTON_MODE>(SUBMIT_BUTTON_MODE.NONE);
  const locationFromParams = convertUrlParamsToTFP(
    Object.fromEntries(new URLSearchParams(window.location.search)) as unknown as ITenantURLParams
  );

  const { control, handleSubmit, watch } = useForm<{
    location: ILocationSelection;
  }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    // resolver: joiResolver(Joi.object({ location: Joi.any() })),
    defaultValues: locationFromParams,
  });

  const handleFormSubmit = () => {
    setButtonSubmitMode(SUBMIT_BUTTON_MODE.SUBMITTING);
    const location = watch('location');
    setCurrentSearchTunnelStep(SEARCH_TUNNEL_STEPS.SELECT_MAX_RENT);
    updateTenantFilterParams({ location });
  };

  return (
    <SearchTunnelStepLayout
      formId={SELECT_RADIUS_FORM_ID}
      control={control}
      disabled={buttonSubmitMode === SUBMIT_BUTTON_MODE.SUBMITTING}
      buttonSubmitMode={buttonSubmitMode}
      onSubmit={handleSubmit(handleFormSubmit, console.error)}
      title={location ? s('title') : s('titleAlternative')}
      backAction={() => {
        const newTfp = tenantFilterParams;
        delete newTfp.location;
        updateTenantFilterParams({ ...newTfp });
        setCurrentSearchTunnelStep(SEARCH_TUNNEL_STEPS.SELECT_CITY);
      }}
    >
      <div style={{ paddingBottom: '30rem' }}>
        <AddressFinderInput hideSubmit name={'location'} />
      </div>
    </SearchTunnelStepLayout>
  );
};

export default SelectLocationStep;
