import styles from './FourDigitInput.module.scss';
import { FC, KeyboardEvent, useContext, useState } from 'react';
import { FormContext } from '../../../../core/context/form.context';
import { Controller, ControllerRenderProps } from 'react-hook-form';
import FormErrorMessage from '../FormErrorMessage';

const DIGIT_KEYS = ['Digit0', 'Digit1', 'Digit2', 'Digit3', 'Digit4', 'Digit5', 'Digit6', 'Digit7', 'Digit8', 'Digit9'];
const PASTE_COMMAND_KEYS = ['v', 'x'];

/** Check if the given code represents a digit key press.
 *
 * @param {string} code - The keyboard code representing a key press.
 * @returns {boolean} - Indicates whether the key press code represents a digit key.
 */
export const isDigit = (code: string): boolean => DIGIT_KEYS.includes(code);

const FourDigitsInput: FC<{ name: string; required?: boolean }> = ({ name, required }) => {
  const { control } = useContext(FormContext);
  const [isPastingCommand, setIsPastingCommand] = useState<boolean>(false);

  /**
   * Replaces the input value if it is not a number and formats it.
   * Updates the value of the field if the input meets certain conditions.
   * Calls the onChange callback of the field with the updated value.
   *
   * @param {any} field - The input field.
   * @param {any} e - The event object containing the input information.
   * @returns {void}
   */
  const handleInput = (field: any, e: any): void => {
    // replace input if it was not a number
    e.target.value = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');

    if (isDigit(e.code) || e.code === 'Backspace' || isPastingCommand) {
      field.value = e.target.value;
    }

    setIsPastingCommand(false);
    field.onChange(field.value);
  };

  const handleKeyDown = ({ ctrlKey, metaKey, key }: KeyboardEvent<HTMLInputElement>) => {
    if ((ctrlKey || metaKey) && PASTE_COMMAND_KEYS.includes(key)) {
      setIsPastingCommand(true);
    }
  };
  const handleOnChange = (field: ControllerRenderProps, e: any) => {
    field.onChange(e.target.value);
  };
  return (
    <Controller
      name={name}
      rules={{ required }}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <div className={styles.wrapper}>
          <input
            id={name}
            onChange={(e) => handleOnChange(field, e)}
            onKeyUp={(e) => handleInput(field, e)}
            onKeyDown={(e) => handleKeyDown(e)}
            className={styles.input}
            placeholder={'----'}
            type="text"
            maxLength={4}
          />
          <FormErrorMessage align={'center'} error={error} />
        </div>
      )}
    />
  );
};

export default FourDigitsInput;
