import { Dispatch, FC, SetStateAction, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import ModalContext from '../../../core/context/modal.context';
import UserContext from '../../../core/context/user.context';
import styles from './DrawerMenu.module.scss';
import { Link } from 'react-router-dom';
import { MODAL_IDENTIFIER } from '../../../core/enum/modals.enum';
import NotificationBadge, { NOTIFICATION_BADGE_SIZE } from '../../atoms/NotificationBadge';
import CTAButton, { BUTTON_STYLE } from '../../atoms/Buttons/CTAButton';
import Headline, { HEADLINE_SIZE } from '../../atoms/typographie/Headline';
import SplitLine from '../../atoms/SplitLine';
import Text, { TEXT_COLOR, TEXT_TYPE } from '../../atoms/typographie/Text';
import { Grid } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-light-svg-icons';
import Badge, { BADGE_COLORS } from '../../atoms/Badge';
import useLogout from '../../../core/hook/logout.hook';
import DrawerWrapper from '../DrawerWrapper';
import { IMenuItemProps, MENU_ITEMS_SECONDARY } from '../../../core/const/menus';
import { ROUTES } from '../../../core/const/routes';
import { REGISTRATION_STATES } from '@wohnsinn/ws-ts-lib';

const DrawerMenu: FC<{ onClose: Dispatch<SetStateAction<boolean>>; menu: IMenuItemProps[] }> = ({ onClose, menu }) => {
  const { t } = useTranslation('common');
  const { t: r } = useTranslation('routes');
  const { openModal } = useContext(ModalContext);
  const { user } = useContext(UserContext);
  const { logout } = useLogout();

  return (
    <DrawerWrapper logo={t('logo.black.logoSrc')} onClose={onClose} route={r(ROUTES.landingPageRoutes.tenant.path)}>
      <ul className={styles.menu}>
        {(!user || user?.registrationState === REGISTRATION_STATES.FINISHED) &&
          menu.map((menuItem, i) => {
            return (
              <li key={`${menuItem.label}-${i}-${menuItem.link}`}>
                <Link
                  to={menuItem.shouldOpenModal && !user ? undefined : (r(menuItem.link) as string)}
                  onClick={
                    !user && menuItem.shouldOpenModal ? () => openModal({ id: MODAL_IDENTIFIER.SIGN_IN }) : undefined
                  }
                >
                  <div className={styles.link}>
                    <span>
                      <Headline tag={'span'} size={HEADLINE_SIZE.H2}>
                        {r(menuItem.label)}
                      </Headline>
                    </span>
                    {menuItem.notifications ? (
                      <div className={styles.notificationsWrapper}>
                        <NotificationBadge
                          size={NOTIFICATION_BADGE_SIZE.MEDIUM}
                          count={typeof menuItem.notifications === 'number' ? menuItem.notifications : '!'}
                        />
                      </div>
                    ) : null}
                    {menuItem.badge ? <Badge color={BADGE_COLORS.SUCCESS} text={menuItem.badge} /> : null}

                    <FontAwesomeIcon className={styles.arrow} icon={faChevronRight} />
                  </div>
                </Link>
              </li>
            );
          })}
      </ul>

      <SplitLine size={'big'} />

      <Grid className={styles.generalLinks} container>
        <Grid item xs={12}>
          <ul className={styles.secondaryMenu}>
            {MENU_ITEMS_SECONDARY.map((link) => (
              <li key={`${link.path} - ${link.title}`}>
                <Link to={r(link.path)}>
                  <Text type={TEXT_TYPE.TEXT_TYPE_BODY} color={TEXT_COLOR.TEXT_COLOR_DARK}>
                    {r(link.title)}
                  </Text>
                </Link>
              </li>
            ))}
          </ul>
        </Grid>
      </Grid>

      <SplitLine size={'big'} />

      <div className={styles.buttonWrapper}>
        {!user ? (
          <Grid container rowSpacing={2}>
            <Grid item xs={12}>
              <CTAButton
                rounded={false}
                expand={'block'}
                buttonText={t('signUp')}
                link={r(ROUTES.staticRoutes.registration.createRegistration.path)}
              />
            </Grid>
            <Grid item xs={12}>
              <CTAButton
                rounded={false}
                buttonStyle={BUTTON_STYLE.SECONDARY}
                expand={'block'}
                buttonText={t('signIn')}
                onClick={() => openModal({ id: MODAL_IDENTIFIER.SIGN_IN })}
              />
            </Grid>
          </Grid>
        ) : (
          <CTAButton
            rounded={false}
            buttonStyle={BUTTON_STYLE.SECONDARY}
            expand={'block'}
            buttonText={'Abmelden'}
            onClick={logout}
          />
        )}
      </div>
    </DrawerWrapper>
  );
};

export default DrawerMenu;
