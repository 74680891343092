import { FC, PropsWithChildren, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/pro-light-svg-icons';
import styles from './ModalWrapper.module.scss';
import { useTranslation } from 'react-i18next';
import ModalContext from 'core/context/modal.context';
import Headline, { HEADLINE_SIZE } from 'component/atoms/typographie/Headline';
import Text, { TEXT_COLOR } from 'component/atoms/typographie/Text';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import CTAButton, { BUTTON_STYLE, ICTAButtonProps } from 'component/atoms/Buttons/CTAButton';

export interface IModalWrapperButtonProps {
  primary?: ICTAButtonProps;
  secondary?: ICTAButtonProps;
  third?: ICTAButtonProps;
}

export interface IModalWrapperProps {
  title?: string;
  subtitle?: string;
  noContentPadding?: boolean;
  icon?: string | IconProp;
  canClose?: boolean;
  buttons?: IModalWrapperButtonProps;
}

const ModalWrapper: FC<PropsWithChildren<IModalWrapperProps>> = ({
  children,
  title,
  subtitle,
  noContentPadding = false,
  icon,
  canClose = true,
  buttons,
}) => {
  const { t: m } = useTranslation('modals');
  const { closeModal } = useContext(ModalContext);

  return (
    <div className={styles.modalWrapper}>
      <div className={styles.modalHeader}>
        {icon ? (
          typeof icon === 'string' ? (
            <img alt={'icon'} className={styles.icon} src={icon} />
          ) : (
            <div className={styles.iconWrapper}>
              <FontAwesomeIcon className={styles.icon} icon={icon} />
            </div>
          )
        ) : null}

        <div className={styles.titleWrapper}>
          {title ? (
            <Headline tag={'span'} size={HEADLINE_SIZE.H3} align={'center'}>
              {title}
            </Headline>
          ) : null}
          {subtitle ? (
            <Text color={TEXT_COLOR.TEXT_COLOR_ACCENT} tag={'span'} align={'center'}>
              {subtitle}
            </Text>
          ) : null}
        </div>

        {canClose ? (
          <button className={`${styles.button} ${styles.close}`} onClick={closeModal}>
            <FontAwesomeIcon icon={faClose} className={styles.closeIcon} />
            <span className={'sr-only'}>{m('close')}</span>
          </button>
        ) : null}
      </div>

      <div className={styles.modalChildren}>
        <div className={`${styles.childrenWrapper} ${noContentPadding ? styles.noContentPadding : ''}`}>{children}</div>

        {buttons ? (
          <div className={styles.modalButtons}>
            {buttons.third ? (
              <CTAButton
                rounded={false}
                color={TEXT_COLOR.TEXT_COLOR_DANGER}
                buttonStyle={BUTTON_STYLE.TERTIARY}
                size={'small'}
                {...buttons.third}
              />
            ) : null}

            {buttons.secondary ? (
              <CTAButton rounded={false} buttonStyle={BUTTON_STYLE.SECONDARY} {...buttons.secondary} />
            ) : null}

            {buttons.primary ? (
              <CTAButton rounded={false} buttonStyle={BUTTON_STYLE.PRIMARY} {...buttons.primary} />
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ModalWrapper;
