import { IMMO_TYPE } from '@wohnsinn/ws-ts-lib';

const getFormattedImmoType = (type: IMMO_TYPE, transLateFn: (key: string) => string) => {
  switch (type) {
    case IMMO_TYPE.APARTMENT:
      return transLateFn('apartmentType.options.apartment');
    case IMMO_TYPE.HOUSE:
      return transLateFn('apartmentType.options.house');
    default:
      return transLateFn('apartmentType.options.apartment');
  }
};

export default getFormattedImmoType;
