import { Dispatch, FC, SetStateAction } from 'react';
import MapboxMap from '../../molecules/Map';
import { useTranslation } from 'react-i18next';
import { IApartment } from '@wohnsinn/ws-ts-lib';
import DrawerWrapper from '../../molecules/DrawerWrapper';

const MapModal: FC<{ apartment: IApartment; onClose: Dispatch<SetStateAction<boolean>> }> = ({
  apartment,
  onClose,
}) => {
  const { t } = useTranslation('common');

  return (
    <DrawerWrapper
      fullScreen
      noTitleMargin
      title={
        apartment
          ? apartment.mainInformation?.address?.hasPublishedAddress
            ? `${apartment.mainInformation.address.street} ${
                apartment.mainInformation.address.houseNumber ? apartment.mainInformation.address.houseNumber : ''
              }`
            : `${apartment.mainInformation.address.postalCode} ${apartment.mainInformation.address.city}`
          : t('address.noApartmentAvailable')
      }
      onClose={onClose}
    >
      <MapboxMap isInteractive apartments={[apartment]} />
    </DrawerWrapper>
  );
};

export default MapModal;
