import { FC } from 'react';
import styles from './SwiperTunnelTitle.module.scss';
import Headline, { HEADLINE_SIZE } from '../../../../atoms/typographie/Headline';
import Text from '../../../../atoms/typographie/Text';
import Spacer, { SPACER_SIZE } from '../../../../atoms/Spacer';

const SwiperTunnelTitle: FC<{ title: string; subtitle?: string; image?: string }> = ({ title, subtitle, image }) => {
  return (
    <div className={styles.titleWrapper}>
      {image ? <img alt={'icon'} className={styles.image} src={image} /> : null}
      <Headline size={HEADLINE_SIZE.H2} align={'center'}>
        {title}
      </Headline>
      <Spacer size={SPACER_SIZE.SM} />
      <Text align={'center'}>{subtitle}</Text>
    </div>
  );
};

export default SwiperTunnelTitle;
