import { FC } from 'react';
import { APARTMENT_TYPE_OF_MARKETING, IApartment, IApartmentMainInformation, IMMO_TYPE } from '@wohnsinn/ws-ts-lib';
import TagList from '../TagList';
import { useTranslation } from 'react-i18next';
import getFormattedImmoType from '../../../core/helper/get-formatted-immo-type';
import getFormattedApartmentType from '../../../core/helper/get-formatted-apartment-type';
import { APARTMENT_TYPE } from '@wohnsinn/ws-ts-lib/dist/types/apartment/common';

export interface IApartmentBasicsProps {
  apartment: IApartment;
}

const ApartmentBasics: FC<IApartmentBasicsProps> = ({ apartment }) => {
  const { t } = useTranslation('common');
  const { t: e } = useTranslation('common', { keyPrefix: 'enum.houseType' });
  const { t: a } = useTranslation('common', { keyPrefix: 'apartment.address' });
  const { t: v } = useTranslation('common', { keyPrefix: 'component.molecules.ApartmentBasics' });
  const basics = [];

  const getImmoStatus = (mainInformation: IApartmentMainInformation): string => {
    if (mainInformation?.typeOfImmo === IMMO_TYPE.HOUSE) {
      console.log(mainInformation?.address?.apartmentType);
      if (mainInformation?.address?.apartmentType) {
        return e(mainInformation?.address?.apartmentType);
      }
      return getFormattedImmoType(mainInformation?.typeOfImmo ?? IMMO_TYPE.APARTMENT, a);
    } else {
      return getFormattedApartmentType(mainInformation?.address?.apartmentType ?? APARTMENT_TYPE.APARTMENT, a);
    }
  };

  basics.push({
    key: 'hu',
    label: `🏠 ${getImmoStatus(apartment.mainInformation)}`,
  });

  if (apartment?.areas?.numberOfRooms) {
    basics.push({
      key: 'rooms',
      label: `🛋️ ${t('apartment.areas.numberOfRooms.label')} ${apartment?.areas?.numberOfRooms}`,
    });
  }

  if (apartment.areas?.totalArea) {
    basics.push({ key: 'size', label: `️📐 ${apartment?.areas?.totalArea} m²` });
  }
  if (apartment.cost?.warmRent) {
    basics.push({ key: 'warmRent', label: `💰 ${apartment.cost.warmRent}€ ${t('apartment.cost.warmRent.label')}` });
  } else {
    if (apartment.cost?.coldRent) {
      basics.push({ key: 'coldRent', label: `💰 ${apartment.cost.coldRent}€ ${t('apartment.cost.coldRent.label')}` });
    }
  }

  if (apartment.desiredTenant) {
    if (apartment.desiredTenant.numberOfPeopleMovingIn) {
      basics.push({
        key: 'maxPeopleMovingIn',
        label: `👨‍👩‍👧‍👦 ${v('maxPeopleMovingIn', {
          amount: apartment.desiredTenant.numberOfPeopleMovingIn,
        })}`,
      });
    }
    if (apartment.mainInformation.typeOfMarketing === APARTMENT_TYPE_OF_MARKETING.RENT) {
      basics.push(
        apartment?.desiredTenant?.arePetsAllowed === null ||
          apartment?.desiredTenant?.arePetsAllowed === undefined ||
          apartment?.desiredTenant?.arePetsAllowed
          ? { key: 'arePetsAllowed', label: `🐶🐱 ${v('arePetsAllowed')}` }
          : { key: 'arePetsAllowed', label: `🐶🐱🚫 ${v('petsNotAllowed')}` }
      );

      if (
        apartment.desiredTenant.isSharedUsagePossible !== null &&
        apartment.desiredTenant.isSharedUsagePossible !== undefined
      ) {
        basics.push(
          apartment.desiredTenant.isSharedUsagePossible
            ? { key: 'sharedUsagePossible', label: `👥 ${v('sharedUsagePossible')}` }
            : { key: 'sharedUsagePossible', label: `👥🚫 ${v('sharedUsageNotPossible')}` }
        );
      }
    }
  }

  if (apartment?.mainInformation?.earliestMoveIn) {
    basics.push({
      key: 'earliestMoveIn',
      label: `🔑 ${v('availableFrom')} ${apartment.mainInformation?.earliestMoveIn}`,
    });
  }

  if (apartment?.desiredTenant?.isHousingEntitlementCertificateMandatory) {
    basics.push({
      key: 'isHousingEntitlementCertificateMandatory',
      label: `🔖 ${v('isHousingEntitlementCertificateMandatory')}`,
    });
  }

  return <TagList testId={'apartmentBasics'} tags={basics} />;
};

export default ApartmentBasics;
