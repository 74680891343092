import styles from './ApartmentLoadingCard.module.scss';

const ApartmentLoadingCard = () => {
  return (
    <div className={styles.loadingApartmentCard}>
      <div className={styles.image} />
      <div className={styles.textWrapper}>
        <div className={styles.text} />
        <div className={styles.text} />
      </div>
      <div className={styles.textWrapper}>
        <div className={styles.text} />
        <div className={styles.text} />
      </div>
    </div>
  );
};

export default ApartmentLoadingCard;
