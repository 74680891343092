import { createContext } from 'react';
import { ITenantFilterParams } from '@wohnsinn/ws-ts-lib';

export interface ITenantFilterParamsContextProps {
  deleteTenantFilterParam: (param: string) => void;
  resetTenantFilterParams: () => void;
  tenantFilterParams: ITenantFilterParams;
  updateTenantFilterParams: ({}) => void;
}

const TenantFilterParamsContext = createContext<ITenantFilterParamsContextProps>({
  deleteTenantFilterParam: null,
  resetTenantFilterParams: null,
  tenantFilterParams: null,
  updateTenantFilterParams: null,
});

export default TenantFilterParamsContext;
