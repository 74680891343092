import { useContext } from 'react';
import { FormContext } from 'core/context/form.context';
import { Controller } from 'react-hook-form';
import { ISelectOption } from '@wohnsinn/ws-ts-lib';
import Text, { TEXT_WEIGHT } from '../../typographie/Text';
import { IconButton, InputLabel, MenuItem, OutlinedInput, Select, Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/pro-light-svg-icons';
import styles from './Dropdown.module.scss';
import FormErrorMessage from 'component/atoms/formElement/FormErrorMessage';

export interface ISelectOptionWithComingSoon<T = unknown> extends ISelectOption {
  isComingSoon?: boolean;
  toolTip?: string;
}

export interface InputSuggestionsDropdownProps<T = unknown> {
  disabled?: boolean;
  hideErrorMessage?: boolean;
  label: string;
  name: string;
  required?: boolean;
  optionList: ISelectOptionWithComingSoon<T>[];
  multiple?: boolean;
  helperText?: string;
  placeholder?: string;
  onChange?: any;
}

const Dropdown = <T,>(props: InputSuggestionsDropdownProps<string[]>) => {
  const { control } = useContext(FormContext);

  const { disabled, label, name, required, optionList, placeholder = 'Bitte wählen', onChange } = props;

  const handleIonChange = (field: any, e: any) => {
    if (onChange) {
      onChange(e.target.value);
    }
    field.onChange(e.target.value);
  };

  const AvailableOptions = optionList.filter((oL) => !oL?.isComingSoon);
  const ComingSoonOptions = optionList.filter((oL) => oL?.isComingSoon);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        return (
          <>
            <InputLabel htmlFor={name} required={required}>
              {label}
            </InputLabel>
            <div className={styles.selectWrapper}>
              {!field?.value && placeholder ? <span className={styles.placeholder}>{placeholder}</span> : null}
              <Select
                required={required}
                value={field?.value ?? ''}
                fullWidth
                displayEmpty={true}
                defaultValue={''}
                size={'small'}
                error={!!error}
                /*keep empty for spacing*/
                id={name}
                onChange={(e) => handleIonChange(field, e)}
                onBlur={field.onBlur}
                multiple={false}
                variant={'outlined'}
                input={<OutlinedInput />}
                disabled={disabled}
              >
                {field?.value && (
                  <MenuItem value={''}>
                    <p className={styles.itemPlaceholder}>{'Zurücksetzen'}</p>
                  </MenuItem>
                )}
                {/* This is to simply suppress the out-of-range value warning when searching filters out a previously selected MenuItem. */}
                {field.value !== '' && (
                  <MenuItem value={field.value} sx={{ display: 'none' }}>
                    {field.value}
                  </MenuItem>
                )}
                {[...AvailableOptions].map((o) => (
                  <MenuItem key={`${name}-${o.value}`} value={(o.value as string) || (o.value as number)}>
                    <Text weight={TEXT_WEIGHT.TEXT_WEIGHT_SEMI_BOLD}>
                      {o.label}
                      {o.toolTip ? (
                        <Tooltip title={o.toolTip}>
                          <IconButton>
                            <FontAwesomeIcon icon={faQuestionCircle} />
                          </IconButton>
                        </Tooltip>
                      ) : null}
                    </Text>
                  </MenuItem>
                ))}
                {ComingSoonOptions.map((o) => (
                  <MenuItem disabled key={`${name}-${o.value}`} value={(o.value as string) || (o.value as number)}>
                    {o.label} <span>(Bald verfügbar)</span>
                  </MenuItem>
                ))}
              </Select>
              <FormErrorMessage error={error} />
            </div>
            {/*keep empty for spacing*/}
          </>
        );
      }}
    />
  );
};

export default Dropdown;
