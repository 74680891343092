import { getDocs, Query } from 'firebase/firestore';
import {
  FIRESTORE_COLLECTION_PATH,
  IAboutMe,
  IHousehold,
  INotificationSettings,
  ITenantFilterParams,
  ITenantPersonalInformation,
  ITenantProfile,
  ITenantSearch,
  ITenantSearchCreate,
  IVideoUpload,
  TDocumentUpload,
  TTenantProfileCreate,
} from '@wohnsinn/ws-ts-lib';
import FirestoreService from './firestore.service';
import { UserInfo } from 'firebase/auth';

class TenantService {
  constructor(private firestoreService: FirestoreService) {}

  /**
   * Create default tenantProfile depending on userInfo
   * @param user
   */
  public async createTenantProfile(user: UserInfo) {
    const tenantProfile: TTenantProfileCreate = {
      email: user.email,
      displayName: user.displayName,
      photoUrl: user.photoURL,
      uid: user.uid,
    };

    try {
      await this.firestoreService.setDbDoc<TTenantProfileCreate>(
        tenantProfile,
        `${FIRESTORE_COLLECTION_PATH.users.tenantProfiles.root.replace('{uid}', user.uid)}/${user.uid}`,
        true,
        {
          setUpdatedAt: true,
          setCreatedAt: true,
        }
      );
    } catch (e) {
      console.error('Error on createTenantProfile', e);
    }
  }

  public getSchufaCheckDocumentListRef(uid: string): Query<TDocumentUpload> {
    const collectionPath = FIRESTORE_COLLECTION_PATH.users.tenantProfiles.schufaCheckDocuments
      .replace('{uid}', uid)
      .replace('{tenantId}', uid);

    const colRef: Query<TDocumentUpload> = this.firestoreService.getCollectionRef<TDocumentUpload>(collectionPath);
    return this.firestoreService.getCollectionRefWithParams<TDocumentUpload>(colRef, {
      where: [{ fieldPath: 'creatorId', opStr: '==', value: uid }],
    });
  }

  public addIncomeProofDocument(uid: string, upload: TDocumentUpload): Promise<void> {
    const path = `${FIRESTORE_COLLECTION_PATH.users.tenantProfiles.incomeProofDocuments
      .replace('{uid}', uid)
      .replace('{tenantId}', uid)}/${upload.fileName}`;

    return this.firestoreService.setDbDoc<TDocumentUpload>(upload, path, false);
  }

  public async addTenantSearchProfile(uid: string, tenantFilterParams: ITenantFilterParams): Promise<void> {
    let newTFP = tenantFilterParams;
    function removeUndefinedProperties(obj: ITenantFilterParams): ITenantFilterParams {
      Object.keys(obj).forEach((key) => {
        if (obj[key as keyof ITenantFilterParams] === undefined) {
          delete obj[key as keyof ITenantFilterParams];
        }
      });
      return obj;
    }
    newTFP = removeUndefinedProperties(newTFP);
    const notificationSettings: INotificationSettings = {
      isWhatsappEnabled: true,
      isMailEnabled: true,
      isPushEnabled: true,
    };

    const path = `${FIRESTORE_COLLECTION_PATH.users.tenantProfiles.searchProfiles
      .replace('{uid}', uid)
      .replace('{tenantId}', uid)}`;

    await this.firestoreService.addDbDoc<ITenantSearchCreate>({ ...newTFP, notificationSettings, uid }, path, {
      setUpdatedAt: true,
      setCreatedAt: true,
    });
  }

  public async getTenantSearchProfiles(uid: string): Promise<any> {
    try {
      const path = `${FIRESTORE_COLLECTION_PATH.users.tenantProfiles.searchProfiles
        .replace('{uid}', uid)
        .replace('{tenantId}', uid)}`;

      const collectionRef = this.firestoreService.getCollectionRef<ITenantSearch[]>(path, { fetchWithId: true });
      const searchProfilesListSnap = await getDocs(collectionRef);
      return searchProfilesListSnap.docs.map((d) => {
        return d.data();
      });
    } catch (e) {
      console.error('Error on getTenantSearchProfiles', e);
    }
  }

  public async deleteTenantSearch(uid: string, searchProfileId: string): Promise<void> {
    try {
      const path = `${FIRESTORE_COLLECTION_PATH.users.tenantProfiles.searchProfiles
        .replace('{uid}', uid)
        .replace('{tenantId}', uid)}/${searchProfileId}`;
      await this.firestoreService.deleteDbDoc(path);
    } catch (e) {
      console.error('Error on deleteTenantSearch', e);
    }
  }
  public async updateTenantSearchProfile(uid: string, searchProfileId: string, data: ITenantSearch): Promise<void> {
    try {
      const path = `${FIRESTORE_COLLECTION_PATH.users.tenantProfiles.searchProfiles
        .replace('{uid}', uid)
        .replace('{tenantId}', uid)}/${searchProfileId}`;

      await this.firestoreService.setDbDoc<ITenantSearch>(data, path, true);
    } catch (e) {
      console.error('Error on updateTenantSearchProfile', e);
    }
  }

  public async setTenantHasSchufaDocuments(uid: string, value: boolean): Promise<void> {
    const path = `${FIRESTORE_COLLECTION_PATH.users.tenantProfiles.root.replace('{uid}', uid)}/${uid}`;

    await this.firestoreService.setDbDoc<{ hasSchufaDocs: boolean }>({ hasSchufaDocs: value }, path, true);
  }

  public async setTenantHasIncomeProofDocuments(uid: string, value: boolean): Promise<void> {
    const path = `${FIRESTORE_COLLECTION_PATH.users.tenantProfiles.root.replace('{uid}', uid)}/${uid}`;

    await this.firestoreService.setDbDoc<{ hasIncomeProofDocs: boolean }>({ hasIncomeProofDocs: value }, path, true);
  }

  public addSchufaCheckDocument(uid: string, upload: TDocumentUpload): Promise<void> {
    const path = `${FIRESTORE_COLLECTION_PATH.users.tenantProfiles.schufaCheckDocuments
      .replace('{uid}', uid)
      .replace('{tenantId}', uid)}/${upload.fileName}`;
    return this.firestoreService.setDbDoc<TDocumentUpload>(upload, path, false);
  }

  public deleteIncomeProofDocument(uid: string, upload: TDocumentUpload): Promise<void> {
    const path = `${FIRESTORE_COLLECTION_PATH.users.tenantProfiles.incomeProofDocuments
      .replace('{uid}', uid)
      .replace('{tenantId}', uid)}/${upload.fileName}`;

    return this.firestoreService.deleteDbDoc(path);
  }

  public deleteSchufaCheckDocument(uid: string, upload: TDocumentUpload): Promise<void> {
    const path = `${FIRESTORE_COLLECTION_PATH.users.tenantProfiles.schufaCheckDocuments
      .replace('{uid}', uid)
      .replace('{tenantId}', uid)}/${upload.fileName}`;

    return this.firestoreService.deleteDbDoc(path);
  }

  public getIncomeProofDocumentListRef(uid: string): Query<TDocumentUpload> {
    const collectionPath = FIRESTORE_COLLECTION_PATH.users.tenantProfiles.incomeProofDocuments
      .replace('{uid}', uid)
      .replace('{tenantId}', uid);

    const colRef: Query<TDocumentUpload> = this.firestoreService.getCollectionRef<TDocumentUpload>(collectionPath);
    return this.firestoreService.getCollectionRefWithParams<TDocumentUpload>(colRef, {
      where: { fieldPath: 'creatorId', opStr: '==', value: uid },
    });
  }

  public updateTenantPersonalInformation(uid: string, personalInformation: ITenantPersonalInformation): Promise<void> {
    const path = `${FIRESTORE_COLLECTION_PATH.users.tenantProfiles.root.replace('{uid}', uid)}/${uid}`;
    return this.firestoreService.setDbDoc<{ personalInformation: ITenantPersonalInformation; updatedAt: Date }>(
      { personalInformation, updatedAt: new Date() },
      path
    );
  }

  public updateTenantIntroductionVideo(uid: string, introductionVideo: IVideoUpload): Promise<void> {
    type TAboutMeVideoUpdate = Omit<IAboutMe, 'description'>;

    const path = `${FIRESTORE_COLLECTION_PATH.users.tenantProfiles.root.replace('{uid}', uid)}/${uid}`;
    return this.firestoreService.setDbDoc<{ aboutMe: TAboutMeVideoUpdate }>(
      {
        aboutMe: {
          introductionVideo: introductionVideo
            ? {
                ...introductionVideo,
                hdVideoRef: null,
                sdVideoRef: null,
              }
            : null,
        },
      },
      path,
      true
    );
  }

  public updateTenantHousehold(uid: string, householdData: IHousehold): Promise<void> {
    const path = `${FIRESTORE_COLLECTION_PATH.users.tenantProfiles.root.replace('{uid}', uid)}/${uid}`;

    return this.firestoreService.setDbDoc<Partial<ITenantProfile>>({ household: householdData }, path);
  }

  public updateTenantProfilePicture(uid: string, photoUrl: string): Promise<void> {
    const path = `${FIRESTORE_COLLECTION_PATH.users.tenantProfiles.root.replace('{uid}', uid)}/${uid}`;
    return this.firestoreService.setDbDoc<Partial<ITenantProfile>>({ photoUrl }, path);
  }
}

export default TenantService;
