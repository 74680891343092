import { useState } from 'react';
import { FieldErrors, FieldValues } from 'react-hook-form';

interface ErrorDetail {
  message: string;
  ref: { name: string };
  type: string;
}

const useErrorHandling = () => {
  const [errorObject, setErrorObject] = useState<ErrorDetail[]>([]);

  const getErrorDetails = (errors: FieldErrors<FieldValues>): ErrorDetail[] => {
    const findErrors = (obj: any): ErrorDetail[] => {
      if (obj && typeof obj === 'object') {
        if ('message' in obj && 'ref' in obj && 'type' in obj) {
          return [obj];
        }
        return Object.values(obj).flatMap(findErrors);
      }
      return [];
    };
    return findErrors(errors);
  };

  const onErrorHandler = (errors: FieldErrors<FieldValues>) => {
    console.error(errors);
    setErrorObject(getErrorDetails(errors));
  };

  return { errorObject, onErrorHandler };
};

export default useErrorHandling;
