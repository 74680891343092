import styles from './ApartmentRatingButtons.module.scss';
import { FC, useContext } from 'react';
import { IApartment, MATCHING_MODE } from '@wohnsinn/ws-ts-lib';
import CTAButton, { BUTTON_STYLE } from '../Buttons/CTAButton';
import { useTranslation } from 'react-i18next';
import { MODAL_IDENTIFIER } from 'core/enum/modals.enum';
import ModalContext from 'core/context/modal.context';

export interface IApartmentRatingButtonsProps {
  onButtonClick: (matchingMode: any) => void;
  hasApplication?: boolean;
  disabled?: boolean;
  isSalesObject?: boolean;
  applicationRating?: MATCHING_MODE;
  apartment: IApartment;
}

const ApartmentRatingButtons: FC<IApartmentRatingButtonsProps> = ({
  onButtonClick,
  hasApplication = false,
  disabled = false,
  isSalesObject = false,
  applicationRating,
  apartment,
}) => {
  const { t } = useTranslation('common');
  const { openModal } = useContext(ModalContext);

  if (hasApplication) {
    return (
      <div className={styles.wrapper}>
        <CTAButton
          rounded={false}
          disabled={disabled}
          buttonText={t('apply')}
          expand={'block'}
          customStyling={styles.singleButton}
          onClick={() => onButtonClick(MATCHING_MODE.LIKE)}
        />
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      {isSalesObject ? (
        <CTAButton
          expand={'block'}
          rounded={false}
          buttonText={'Anfragen'}
          onClick={() => openModal({ id: MODAL_IDENTIFIER.REQUEST_SALE, data: { apartment } })}
        />
      ) : (
        <>
          <CTAButton
            disabled={disabled || applicationRating == MATCHING_MODE.NOPE}
            buttonStyle={BUTTON_STYLE.SECONDARY}
            buttonText={t('nope')}
            customStyling={styles.buttonAnimation}
            hideText
            img={t('icons.heart_nope')}
            onClick={() => onButtonClick(MATCHING_MODE.NOPE)}
            size={'big'}
          />

          <CTAButton
            disabled={disabled}
            buttonText={t('apply')}
            customStyling={styles.buttonAnimation}
            onClick={() => onButtonClick(MATCHING_MODE.LIKE)}
            size={'big'}
          />

          <CTAButton
            disabled={disabled || applicationRating == MATCHING_MODE.MAYBE}
            buttonStyle={BUTTON_STYLE.SECONDARY}
            buttonText={t('like')}
            customStyling={styles.buttonAnimation}
            hideText
            img={t('icons.heart_like')}
            onClick={() => onButtonClick(MATCHING_MODE.MAYBE)}
            size={'big'}
          />
        </>
      )}
    </div>
  );
};

export default ApartmentRatingButtons;
