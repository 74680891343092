import { FC, useContext } from 'react';
import styles from './MediaUploadDropZone.module.scss';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudArrowUp } from '@fortawesome/pro-light-svg-icons';
import Text, { TEXT_COLOR, TEXT_TYPE, TEXT_VARIANT, TEXT_WEIGHT } from '../../atoms/typographie/Text';
import { UPLOAD_TYPE } from '../DocumentUploadDropZone';
import SnackBarContext from '../../../core/context/snackbar.context';

const MediaUploadDropZone: FC<{ text?: string; onDrop: any; multiple?: boolean; uploadType?: UPLOAD_TYPE }> = ({
  text,
  onDrop,
  multiple = false,
  uploadType = UPLOAD_TYPE.APARTMENT_MEDIA,
}) => {
  const { handleSnackBar } = useContext(SnackBarContext);

  const { t: c } = useTranslation('common', { keyPrefix: 'component.molecules.MediaUploadDropZone' });
  const onDropHandler = (acceptedFiles: any, fileRejections: any) => {
    if (fileRejections.length) {
      handleSnackBar('toast.onlyAllowImages', 'error');
    }
    onDrop(acceptedFiles);
  };

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop: (acceptedFiles, fileRejections) => onDropHandler(acceptedFiles, fileRejections),
    noClick: true,
    noKeyboard: true,
    multiple,
  });

  return (
    <div {...getRootProps()} className={`${styles.wrapper} ${isDragActive ? styles.activeDrag : ''}`}>
      <input
        {...getInputProps()}
        accept={uploadType === UPLOAD_TYPE.INTRODUCTION_VIDEO ? 'video/mp4, video/quicktime' : 'image/jpeg, image/png'}
      />
      <button className={styles.dropZone} onClick={open} type={'button'}>
        <span className="sr-only"> {text ? text : c('title')}</span>

        <div className={styles.uploadIconWrapper}>
          <FontAwesomeIcon icon={faCloudArrowUp} size={'2x'} />
        </div>

        <div className={styles.textWrapper}>
          <Text
            align={'center'}
            type={TEXT_TYPE.TEXT_TYPE_CAPTION}
            weight={TEXT_WEIGHT.TEXT_WEIGHT_SEMI_BOLD}
            color={TEXT_COLOR.TEXT_COLOR_PRIMARY}
          >
            {text ? text : c('title')}
          </Text>
          <Text align={'center'} type={TEXT_TYPE.TEXT_TYPE_CAPTION} variant={TEXT_VARIANT.TEXT_VARIANT_SMALL}>
            {uploadType === UPLOAD_TYPE.INTRODUCTION_VIDEO ? c('allowedVideoFiles') : c('allowedImageFiles')}
          </Text>
        </div>
      </button>
    </div>
  );
};

export default MediaUploadDropZone;
