import Joi from 'joi';
import { EQUIPMENT } from '@wohnsinn/ws-ts-lib';

export const APARTMENT_EQUIPMENT_SCHEMA = {
  description: Joi.string().optional().allow(null),
  equipmentList: Joi.array().items(Joi.string().valid(...Object.keys(EQUIPMENT))),
  kitchenPrice: [Joi.number().sign('positive').required(), Joi.allow(null)],
  parkingSpotRent: [Joi.number().sign('positive').required(), Joi.allow(null)],
  equipmentCosts: Joi.object({
    kitchenPrice: Joi.number().optional().allow(null),
    garageRent: Joi.number().optional().allow(null),
    basementGarageRent: Joi.number().optional().allow(null),
    carportRent: Joi.number().optional().allow(null),
    freeSpaceRent: Joi.number().optional().allow(null),
    carParkRent: Joi.number().optional().allow(null),
    duplexRent: Joi.number().optional().allow(null),
  }).allow(null),
};
