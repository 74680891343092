import {
  APARTMENT_RENT_STATUS,
  APARTMENT_TYPE_OF_MARKETING,
  FIRESTORE_COLLECTION_PATH,
  IApartment,
  IGetLandlordApartmentListQueryParams,
  ILandlordProfile,
  IMedia,
  IOrderByFilterArguments,
  IPaginatedApartmentsListResponse,
  ITenantFilterParams,
  IWhereFilterArguments,
} from '@wohnsinn/ws-ts-lib';
import {
  deleteField,
  doc,
  getDocs,
  OrderByDirection,
  Query,
  query,
  QueryDocumentSnapshot,
  QuerySnapshot,
  setDoc,
  startAfter,
} from 'firebase/firestore';
import { FirebaseFunctionsService } from './firebase-functions.service';
import FB_FUNCTION_URLS from '../const/fb-function-names';
import jsonpack from 'jsonpack';
import FirestoreService from './firestore.service';

interface IGetOrganizationApartmentListQueryParams {
  organizationId: string;
  limit?: number;
  directionStr?: OrderByDirection;
  whereFilterArguments?: IWhereFilterArguments;
}
interface UpdateApartmentParams {
  data: Partial<IApartment>;
  creatorId: string;
  apartmentId: string;
}
export interface IGetMatchesParams {
  tenantFilterParams: ITenantFilterParams;
  page?: number;
}

export class ApartmentService {
  constructor(private firestoreService: FirestoreService, private firebaseFunctionService: FirebaseFunctionsService) {}

  public getApartmentRef(apartmentId: string): Query<IApartment> {
    return this.firestoreService.getCollectionRefWithParams<IApartment>(
      this.firestoreService.getCollectionGroupRef('apartments', { fetchWithId: true }),
      { where: { fieldPath: 'id', opStr: '==', value: apartmentId } }
    );
  }

  public getPerfectMatchList = async (
    tenantFilterParams: ITenantFilterParams,
    page: number
  ): Promise<IPaginatedApartmentsListResponse | void> => {
    try {
      const response = await this.firebaseFunctionService.callFbFunction<IGetMatchesParams>(
        FB_FUNCTION_URLS.perfectMatches,
        {
          tenantFilterParams,
          page,
        }
      );

      return jsonpack.unpack(response.data) as IPaginatedApartmentsListResponse;
    } catch (e) {
      console.error('ErrorView on getMatchList', e);
    }
  };

  public getTopApartments = async (): Promise<IApartment[] | void> => {
    try {
      const response = await this.firebaseFunctionService.callFbFunction(FB_FUNCTION_URLS.getTopApartments, {});

      return jsonpack.unpack(response.data) as IApartment[];
    } catch (e) {
      console.error('ErrorView on getMatchList', e);
    }
  };

  public async deleteApartmentList(apartments: IApartment[]) {
    const batch = this.firestoreService.getBatch();
    for await (const apartment of apartments) {
      const apartmentPath = `${FIRESTORE_COLLECTION_PATH.users.landlordProfiles.apartments.root
        .replace('{uid}', apartment.creatorId)
        .replace('{landlordId}', apartment.creatorId)}/${apartment.id}`;

      batch.delete(this.firestoreService.getDocRef(apartmentPath));
    }
    try {
      await batch.commit();
    } catch (e) {
      console.error('Error on updateApartmentsPublishedStates', e);
    }
  }

  public async getOrganizationApartmentDocSnapShots(
    organizationId: string,
    lastVisibleApartment?: QueryDocumentSnapshot<IApartment>
  ): Promise<QuerySnapshot<IApartment>> {
    const apartmentListRef: Query<IApartment> = lastVisibleApartment
      ? query(this.getOrganizationApartmentsListRef({ organizationId }), startAfter(lastVisibleApartment))
      : this.getOrganizationApartmentsListRef({ organizationId });

    return getDocs(apartmentListRef).then((apartmentListSnap) => {
      return apartmentListSnap;
    });
  }

  public getAllApartmentsListRef(limit = 100, isPublished?: boolean): Query<IApartment> {
    const where: IWhereFilterArguments[] = [];
    if (isPublished) {
      where.push({ fieldPath: 'isPublished', opStr: '==', value: true });
    }

    return this.firestoreService.getCollectionRefWithParams<IApartment>(
      this.firestoreService.getCollectionGroupRef('apartments'),
      {
        where,
        limit,
      }
    );
  }

  public async updateApartment(params: UpdateApartmentParams, merge = true): Promise<void> {
    const { data, creatorId, apartmentId } = params;
    const apartmentPath = `${FIRESTORE_COLLECTION_PATH.users.landlordProfiles.apartments.root
      .replace('{uid}', creatorId)
      .replace('{landlordId}', creatorId)}/${apartmentId}`;
    const apartmentDocRef = this.firestoreService.getDocRef(apartmentPath);
    try {
      await setDoc(apartmentDocRef, data, { merge });
    } catch (e) {
      console.error('Error on updateApartment: ', e);
    }
  }

  public async createApartment(createApartmentData: any, landlordProfile: ILandlordProfile): Promise<string> {
    const path = `${FIRESTORE_COLLECTION_PATH.users.landlordProfiles.apartments.root
      .replace('{uid}', landlordProfile.uid)
      .replace('{landlordId}', landlordProfile.uid)}`;
    try {
      const colRef = this.firestoreService.getCollectionRef(path);
      const newRef = doc(colRef);
      const pathDoc = `${FIRESTORE_COLLECTION_PATH.users.landlordProfiles.apartments.root
        .replace('{uid}', landlordProfile.uid)
        .replace('{landlordId}', landlordProfile.uid)}/${newRef?.id}`;
      let landlordIds: string[] = [];

      if (landlordProfile?.organizationId && landlordProfile?.isOrganizationMembershipConfirmed) {
        const landlordsFromOrganizationQuery = this.firestoreService.getCollectionRefWithParams<ILandlordProfile>(
          this.firestoreService.getCollectionGroupRef('landlordProfiles', { fetchWithId: true }),
          { where: { fieldPath: 'organizationId', opStr: '==', value: landlordProfile?.organizationId } }
        );
        const landlordProfiles = await getDocs(landlordsFromOrganizationQuery);
        landlordProfiles.forEach((doc) => {
          landlordIds.push(doc.id);
        });
      } else {
        landlordIds = [landlordProfile?.uid];
      }

      const address = createApartmentData.mainInformation.address;

      const {
        data: { coordinates, geoHash },
      } = await this.firebaseFunctionService.callFbFunction(FB_FUNCTION_URLS.apartments.getGeoCoordinates, address);

      const data: Partial<IApartment> = {
        applicationRefList: [],
        unreadTenantChatsRef: [],
        isPublished: false,
        rentStatus: APARTMENT_RENT_STATUS.NONE,
        newMessageRefList: [],
        creatorId: landlordProfile.uid,
        mainInformation: {
          ...createApartmentData?.mainInformation,
          address: {
            ...address,
            geoHash,
            coordinates,
          },
        },
        cost: { ...createApartmentData?.cost },
        contactPerson: {
          name: landlordProfile?.personalInformation?.lastName ?? undefined,
          directEmail: landlordProfile?.email,
          firstName: landlordProfile?.personalInformation?.firstName ?? undefined,
          addressRelease: true,
          mobile: landlordProfile?.personalInformation?.phoneNumber ?? undefined,
        },
        links: [],
        organizationId: landlordProfile?.organizationId ?? undefined,
        editorList: landlordIds,
        id: newRef.id,
        media: [],
        updatedAt: new Date(),
        createdAt: new Date(),
      };

      await this.firestoreService.setDbDoc(data, pathDoc, true);

      return newRef?.id;
    } catch (e) {
      console.error('Error on creating apartment', e);
    }
  }

  public getOrganizationApartmentsListRef(params: IGetOrganizationApartmentListQueryParams): Query<IApartment> {
    const where: IWhereFilterArguments[] = [
      { fieldPath: 'isPublished', opStr: '==', value: true },
      { fieldPath: 'organizationId', opStr: '==', value: params.organizationId },
    ];
    const orderBy: IOrderByFilterArguments[] = [];
    if (params.whereFilterArguments) {
      where.push(params.whereFilterArguments);
      if (params.whereFilterArguments.opStr === '!=') {
        orderBy.push({ fieldPath: params.whereFilterArguments.fieldPath, directionStr: 'desc' });
      }
    }
    orderBy.push({ fieldPath: 'updatedAt', directionStr: 'desc' });
    return this.firestoreService.getCollectionRefWithParams<IApartment>(
      this.firestoreService.getCollectionGroupRef('apartments'),
      {
        where,
        orderBy,
        limit: params.limit,
      }
    );
  }

  public getLandlordApartmentListRef(params: IGetLandlordApartmentListQueryParams) {
    let where: IWhereFilterArguments[] = [
      { fieldPath: 'editorList', opStr: 'array-contains', value: params.uid },
      { fieldPath: 'mainInformation.typeOfMarketing', opStr: '==', value: APARTMENT_TYPE_OF_MARKETING.RENT },
    ];
    if (params.isAdmin) {
      where = [{ fieldPath: 'mainInformation.typeOfMarketing', opStr: '==', value: APARTMENT_TYPE_OF_MARKETING.RENT }];
    }
    const orderBy: IOrderByFilterArguments[] = [];
    if (params.whereFilterArguments) {
      where.push(params.whereFilterArguments);
      if (params.whereFilterArguments.opStr === '!=') {
        orderBy.push({ fieldPath: params.whereFilterArguments.fieldPath, directionStr: 'desc' });
      }
    }

    orderBy.push({ fieldPath: 'updatedAt', directionStr: 'desc' });

    return this.firestoreService.getCollectionRefWithParams<IApartment>(
      this.firestoreService.getCollectionGroupRef('apartments'),
      {
        where,
        orderBy,
        limit: params.limit,
      }
    );
  }

  public updateApartmentPublishState(apartment: IApartment, isPublished: boolean) {
    const path = `${FIRESTORE_COLLECTION_PATH.users.landlordProfiles.apartments.root
      .replace('{uid}', apartment.creatorId)
      .replace('{landlordId}', apartment.creatorId)}/${apartment.id}`;

    let data: Partial<IApartment> = {
      isPublished: isPublished,
    };

    if (isPublished) {
      data = {
        ...data,
        publishedAt: new Date(),
        rentStatus: APARTMENT_RENT_STATUS.ACQUISITION,
      };
    }

    return this.firestoreService.setDbDoc<Partial<IApartment>>(data, path);
  }

  /**
   * Update a list of apartments by given ID Array
   * @param apartments
   * @param setPublishedState
   */
  public async updateApartmentsPublishedStates(apartments: IApartment[], setPublishedState: boolean): Promise<void> {
    const batch = this.firestoreService.getBatch();

    for await (const apartment of apartments) {
      const apartmentPath = `${FIRESTORE_COLLECTION_PATH.users.landlordProfiles.apartments.root
        .replace('{uid}', apartment.creatorId)
        .replace('{landlordId}', apartment.creatorId)}/${apartment.id}`;

      let data: Partial<IApartment> = {
        isPublished: setPublishedState,
        publishedAt: setPublishedState ? new Date() : null,
      };

      if (setPublishedState) {
        data = {
          ...data,
          rentStatus: APARTMENT_RENT_STATUS.ACQUISITION,
        };
      }

      batch.set(this.firestoreService.getDocRef(apartmentPath), data, { merge: true });
    }

    try {
      await batch.commit();
    } catch (e) {
      console.error('Error on updateApartmentsPublishedStates', e);
    }
  }

  public async updateApartmentContactPersonsProfileImage(apartment: IApartment, photo?: IMedia) {
    const path = `${FIRESTORE_COLLECTION_PATH.users.landlordProfiles.apartments.root
      .replace('{uid}', apartment.creatorId)
      .replace('{landlordId}', apartment.creatorId)}/${apartment.id}`;

    try {
      await this.firestoreService.setDbDoc({ contactPerson: { photo: photo ? photo : deleteField() } }, path, true);
    } catch (e) {
      console.error('Error on updating apartments contact person', e);
    }
  }

  public updateApartmentMediaList(apartment: IApartment, media: IMedia[]): Promise<void> {
    const path = `${FIRESTORE_COLLECTION_PATH.users.landlordProfiles.apartments.root
      .replace('{uid}', apartment.creatorId)
      .replace('{landlordId}', apartment.creatorId)}/${apartment.id}`;
    return this.firestoreService.setDbDoc<{ media: IMedia[] }>({ media }, path);
  }
}
