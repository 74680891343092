import ModalWrapper from '../ModalWrapper';
import { FC, useContext } from 'react';
import ModalContext from 'core/context/modal.context';
import Text from 'component/atoms/typographie/Text';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { wohnsinnServices } from 'App';
import SnackBarContext from 'core/context/snackbar.context';
import { APARTMENT_RENT_STATUS, IApartment } from '@wohnsinn/ws-ts-lib';
import FB_FUNCTION_URLS from 'core/const/fb-function-names';
import UserContext from 'core/context/user.context';

const RentStatusModal: FC = () => {
  const { closeModal, modalData } = useContext(ModalContext);
  const { handleSnackBar } = useContext(SnackBarContext);
  const { t } = useTranslation('common');
  const { t: m } = useTranslation('common', { keyPrefix: 'component.molecules.modals.RentStatusModal' });
  const apartment = modalData?.apartment as IApartment;
  const queryClient = useQueryClient();
  const { landlordProfile } = useContext(UserContext);
  const { apartmentService, firebaseFunctionsService } = wohnsinnServices;

  const isInAcquisition = apartment?.rentStatus === APARTMENT_RENT_STATUS.ACQUISITION;
  const { mutate: updateRentStatus, isPending } = useMutation({
    mutationFn: () =>
      apartmentService.updateApartment({
        data: {
          rentStatus: isInAcquisition ? APARTMENT_RENT_STATUS.NONE : APARTMENT_RENT_STATUS.ACQUISITION,
          isPublished: !isInAcquisition,
        },
        creatorId: apartment?.creatorId,
        apartmentId: apartment?.id,
      }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['apartments'] });
      await firebaseFunctionsService.callFbFunction(FB_FUNCTION_URLS.apartments.publishedChangeNotification, {
        body: {
          apartments: [apartment],
          landlordProfile,
          isPublished: apartment?.isPublished,
        },
      });
      handleSnackBar(t(!isInAcquisition ? 'toast.success.published' : 'toast.success.deactivate'), 'success');
    },
    onError: () => handleSnackBar('toast.error.unknown', 'error'),
    onSettled: closeModal,
  });

  const MODAL_BUTTONS = {
    primary: {
      buttonText: m(isInAcquisition ? 'buttons.stop' : 'buttons.start'),
      onClick: updateRentStatus,
      disabled: isPending,
    },
    third: {
      onClick: closeModal,
      buttonText: t('cancel'),
      disabled: isPending,
    },
  };

  return (
    <ModalWrapper
      buttons={MODAL_BUTTONS}
      title={m(isInAcquisition ? 'title.stop' : 'title.start')}
      icon={t('pictogram.check')}
    >
      <Text align={'center'} tag={'p'}>
        {m(isInAcquisition ? 'rentStatus.acquisition.stop' : 'rentStatus.acquisition.start')}
      </Text>
    </ModalWrapper>
  );
};

export default RentStatusModal;
