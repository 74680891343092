import { createContext } from 'react';
import { IApplication } from '@wohnsinn/ws-ts-lib';

export interface IApplicationListContext {
  maybeList: IApplication[];
  likeList: IApplication[];
  nopeList: IApplication[];
  unreadNotifications: number;
  isLoading: boolean;
}

const ApplicationListContext = createContext<IApplicationListContext>({
  maybeList: null,
  likeList: null,
  nopeList: null,
  isLoading: null,
  unreadNotifications: null,
});

export default ApplicationListContext;
