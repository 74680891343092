import { FC, PropsWithChildren } from 'react';
import Headline, { HEADLINE_SIZE } from '../../atoms/typographie/Headline';
import styles from './PageTitle.module.scss';
import Text, { TEXT_COLOR, TEXT_TYPE } from '../../atoms/typographie/Text';
import CTAButton, { BUTTON_STYLE, getSubmitIcon } from '../../atoms/Buttons/CTAButton';
import { useTranslation } from 'react-i18next';
import { SUBMIT_BUTTON_MODE } from '../../../core/enum/submit-button-mode.enum';
import { INotSubmitButton } from '../../organisms/FormLayout';
import Badge, { BADGE_COLORS } from '../../atoms/Badge';
import { Grid } from '@mui/material';
import BackButton from 'component/atoms/Buttons/BackButton';

export interface IPageTitleProps {
  pageTitle: string;
  secondPageTitle?: string;
  showSubmitButton?: boolean;
  showSecondActionButton?: boolean;
  notSubmitButton?: INotSubmitButton;
  submitMode?: SUBMIT_BUTTON_MODE;
  center?: boolean;
  badgeText?: string;
  backButtonRoute?: string;
  showBackButton?: boolean;
  submitText?: string;
  badgeColor?: BADGE_COLORS;
  tag?: 'h1' | 'h2' | 'h3' | 'h4' | 'span' | 'p';
}

const PageTitle: FC<PropsWithChildren<IPageTitleProps>> = ({
  children,
  pageTitle,
  secondPageTitle,
  showBackButton = false,
  backButtonRoute,
  showSubmitButton = false,
  submitMode,
  notSubmitButton,
  center = false,
  badgeText,
  badgeColor = BADGE_COLORS.PRIMARY,
  submitText = 'save',
  tag = 'span',
}) => {
  const { t } = useTranslation('common');

  return (
    <Grid item xs={12}>
      <div className={styles.wrapper}>
        <div className={styles.title}>
          <div className={styles.badgeWrapper}>
            {showBackButton && <BackButton backRoute={backButtonRoute} />}
            <Headline size={HEADLINE_SIZE.H2} tag={tag} align={center ? 'center' : 'left'}>
              {pageTitle}
            </Headline>
            {badgeText ? (
              <div>
                <Badge text={badgeText} color={badgeColor} />
              </div>
            ) : null}
          </div>
          {secondPageTitle && (
            <div className={styles.secondPageTitle}>
              <Text
                align={center ? 'center' : 'left'}
                color={TEXT_COLOR.TEXT_COLOR_ACCENT}
                type={TEXT_TYPE.TEXT_TYPE_BODY}
                tag={'p'}
              >
                {secondPageTitle}
              </Text>
            </div>
          )}
        </div>

        {children}

        <div className={styles.submitButton}>
          {notSubmitButton ? (
            <CTAButton
              type={'button'}
              onClick={notSubmitButton.action}
              buttonStyle={BUTTON_STYLE.SECONDARY}
              buttonText={notSubmitButton.text}
            />
          ) : null}
          {showSubmitButton && (
            <CTAButton
              icon={getSubmitIcon(submitMode)}
              spinIcon={submitMode === SUBMIT_BUTTON_MODE.SUBMITTING}
              buttonText={t(submitText)}
            />
          )}
        </div>
      </div>
    </Grid>
  );
};

export default PageTitle;
