import ModalWrapper from '../ModalWrapper';
import { FC, useContext } from 'react';
import ModalContext from 'core/context/modal.context';
import VirtualTour from 'component/atoms/VirtualTour';

const VirtualTourModal: FC = () => {
  const { modalData } = useContext(ModalContext);

  return (
    <ModalWrapper noContentPadding title={'Virtuelle 3D Tour'}>
      <VirtualTour
        matterportId={modalData?.matterPortId ?? null}
        playInFrame={true}
        virtualTourLink={modalData?.virtualTourLink ?? null}
      />
    </ModalWrapper>
  );
};

export default VirtualTourModal;
