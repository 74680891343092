import { Box, Card, CardContent, Typography } from '@mui/material';
import { FC, useEffect } from 'react';
import FormErrorMessage from 'component/atoms/formElement/FormErrorMessage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/pro-light-svg-icons';

interface ErrorDetail {
  message: string;
}

interface FormErrorDisplayProps {
  errors: ErrorDetail[];
}

const FormErrorDisplay: FC<FormErrorDisplayProps> = ({ errors }) => {
  useEffect(() => {
    if (errors.length > 0) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [errors]);

  return (
    <>
      {Object.keys(errors).length > 0 ? (
        <Box sx={{ minWidth: 275, paddingBottom: '2rem' }}>
          <Card variant="outlined" sx={{ borderColor: 'danger', border: '2px solid red' }}>
            <CardContent style={{ display: 'flex' }}>
              <Box paddingRight={3}>
                <FontAwesomeIcon icon={faTriangleExclamation} size={'2x'} color={'red'} />
              </Box>
              <Box>
                <Typography variant="h5" component="div">
                  Bitte korrigieren Sie die rot markierten Felder.
                </Typography>
                <div style={{ padding: '1rem' }}>
                  {errors?.map((err: any, i: number) => {
                    return (
                      <li
                        key={`${err.name}-
                  ${i}`}
                      >
                        <FormErrorMessage isErrorBox={true} showName={true} error={err} />
                      </li>
                    );
                  })}
                </div>
              </Box>
            </CardContent>
          </Card>
        </Box>
      ) : null}
    </>
  );
};

export default FormErrorDisplay;
