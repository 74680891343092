import styles from './LoadingScreen.module.scss';
const LoadingScreen = () => {
  return (
    <div className={styles.loadingScreen}>
      <img src="/assets/icon/wohnsinn-logo.svg" alt="Wohnsinn Logo" />
    </div>
  );
};

export default LoadingScreen;
