export const faqTenant = [
  {
    label: 'Ist Wohnsinn für Mietsuchende kostenlos?',
    content: 'Ja! Als Mietsuchender kannst du Wohnsinn kostenfrei nutzen.',
  },
  {
    label: 'Sind meine Daten sicher?',
    content:
      'Ja! Wohnsinn behandelt deine persönlichen Daten sicher und vertraulich gemäß den geltenden Vorschriften. Dabei gehören dir zu jedem Zeitpunkt alle Daten und du kannst diese einfach verwalten.',
  },
  {
    label: 'Von wem sind die Wohnungen?',
    content:
      'Die Wohnungen stammen von privaten Vermietern und Hausverwaltungen. Der Großteil der Objekte ist exklusiv bei uns verfügbar und nicht auf anderen Portalen zu finden.',
  },
  {
    label: 'Wer steckt hinter Wohnsinn?',
    content:
      'Wohnsinn wurde 2022 von Felix und Christian gegründet, um die Wohnungssuche für alle Beteiligten einfacher und entspannter zu gestalten. Mit innovativen digitalen Lösungen optimieren wir den gesamten Vermietungsprozess von der Bewerbung bis zum Einzug.',
  },
];

export const faqTenantTunnel = [
  {
    label: 'Ist es wirklich kostenlos? ',
    content: 'Ja! Du kannst Wohnsinn mit allen Funktionen kostenlos nutzen. ',
  },
  {
    label: 'Wer steckt hinter Wohnsinn?',
    content:
      'Wohnsinn ist ein Kölner Startup, das von Felix und Christian gegründet wurde. Unser Ziel ist es, dich bestmöglich bei der Wohnungssuche zu unterstützen. Wir arbeiten hart daran, damit auch du bald deine neue Wohnung findest! ',
  },
  {
    label: 'Was passiert mit meinen Daten? ',
    content:
      'Wir behandeln deine Daten sicher und vertraulich gemäß den geltenden Vorschriften. Dabei gehören dir zu jedem Zeitpunkt alle Daten und kannst diese einfach verwalten. ',
  },
  {
    label: 'Woher kommt das Wohnungsangebot?',
    content:
      'Bereits über 100 Makler, Verwalter und private Vermieter nutzen Wohnsinn zur Vermarktung ihrer Wohnungen. Täglich kommen neue Anbieter hinzu, was zu einem großen Wohnungsangebot führt. ',
  },
];

export const faqLandlord = [
  {
    label: 'Wo kommen die Mietanfragen her?',
    content:
      'In unserer Datenbank befinden sich über 10.000 Mietsuchende, was sicherstellt, dass du jederzeit genügend Anfragen erhältst. Zudem veröffentlichen wir dein Inserat kostenlos auf weiteren Portalen, um die Reichweite zu maximieren.',
  },
  {
    label: 'Ist Wohnsinn für Vermieter kostenlos?',
    content:
      'Ja! Du kannst alle Funktionen nutzen und unbegrenzt Inserate kostenlos erstellen. Wohnsinn finanziert sich durch die Mietsuchenden über ein monatliches Premium-Abo.',
  },
  {
    label: 'Ist Wohnsinn deutschlandweit verfügbar?',
    content: 'Ja! Du kannst deine Mietobjekte überall in Deutschland inserieren, ganz gleich, wo sie sich befinden.',
  },
  {
    label: 'Gibt es Schnittstellen für meine Immobilien-Software?',
    content:
      'Ja! Wohnsinn unterstützt den OpenImmo-Standard, sodass du problemlos alle deine Mietobjekte aus deiner Immobiliensoftware zu uns übertragen kannst. Dadurch wird die Vermietung noch einfacher und effizienter. ',
  },
];
