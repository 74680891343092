import { FC } from 'react';
import styles from './PoiWrapper.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/pro-solid-svg-icons';
import { POI_TYPE } from '@wohnsinn/ws-ts-lib';

export interface IMapPoiWrapperProps {
  togglePoi: (poi: POI_TYPE) => void;
  activePoiList: POI_TYPE[];
}

const PoiWrapper: FC<IMapPoiWrapperProps> = ({ togglePoi, activePoiList }) => {
  return (
    <div className={styles.poiWrapper} data-testid={'poiWrapper'}>
      <button
        type={'button'}
        data-testid={'poiWrapper.restaurants'}
        className={styles.poiButton}
        onClick={() => togglePoi(POI_TYPE.RESTAURANTS)}
      >
        <div
          data-testid={'poiWrapper.restaurants.style'}
          className={`${styles.poiIcon} ${styles.poiIconRestaurant} ${
            activePoiList.includes(POI_TYPE.RESTAURANTS) ? styles.active : ''
          }`}
        >
          <FontAwesomeIcon icon={faMapMarkerAlt} />
        </div>
        <span className={styles.poiFont}>Restaurants</span>
      </button>

      <button
        type={'button'}
        data-testid={'poiWrapper.hospitals'}
        className={styles.poiButton}
        onClick={() => togglePoi(POI_TYPE.HOSPITALS)}
      >
        <div
          className={`${styles.poiIcon} ${styles.poiIconHospital} ${
            activePoiList.includes(POI_TYPE.HOSPITALS) ? styles.active : ''
          }`}
        >
          <FontAwesomeIcon icon={faMapMarkerAlt} />
        </div>
        <span className={styles.poiFont}>Krankenhäuser</span>
      </button>

      <button
        type={'button'}
        data-testid={'poiWrapper.trains'}
        className={styles.poiButton}
        onClick={() => togglePoi(POI_TYPE.TRAINS)}
      >
        <div
          className={`${styles.poiIcon} ${styles.poiIconTrains} ${
            activePoiList.includes(POI_TYPE.TRAINS) ? styles.active : ''
          }`}
        >
          <FontAwesomeIcon icon={faMapMarkerAlt} />
        </div>
        <span className={styles.poiFont}>ÖPNV</span>
      </button>

      <button
        type={'button'}
        data-testid={'poiWrapper.schools'}
        className={styles.poiButton}
        onClick={() => togglePoi(POI_TYPE.SCHOOLS)}
      >
        <div
          className={`${styles.poiIcon} ${styles.poiIconSchool} ${
            activePoiList.includes(POI_TYPE.SCHOOLS) ? styles.active : ''
          }`}
        >
          <FontAwesomeIcon icon={faMapMarkerAlt} />
        </div>
        <span className={styles.poiFont}>Schulen</span>
      </button>

      <button
        type={'button'}
        data-testid={'poiWrapper.pharmacy'}
        className={styles.poiButton}
        onClick={() => togglePoi(POI_TYPE.PHARMACY)}
      >
        <div
          className={`${styles.poiIcon} ${styles.poiIconPharmacy} ${
            activePoiList.includes(POI_TYPE.PHARMACY) ? styles.active : ''
          }`}
        >
          <FontAwesomeIcon icon={faMapMarkerAlt} />
        </div>
        <span className={styles.poiFont}>Apotheken</span>
      </button>

      <button
        type={'button'}
        data-testid={'poiWrapper.kita'}
        className={styles.poiButton}
        onClick={() => togglePoi(POI_TYPE.KITA)}
      >
        <div
          className={`${styles.poiIcon} ${styles.poiIconKita} ${
            activePoiList.includes(POI_TYPE.KITA) ? styles.active : ''
          }`}
        >
          <FontAwesomeIcon icon={faMapMarkerAlt} />
        </div>
        <span className={styles.poiFont}>Kita</span>
      </button>

      <button
        type={'button'}
        data-testid={'poiWrapper.supermarket'}
        className={styles.poiButton}
        onClick={() => togglePoi(POI_TYPE.SUPERMARKET)}
      >
        <div
          className={`${styles.poiIcon} ${styles.poiIconSuperMarket} ${
            activePoiList.includes(POI_TYPE.SUPERMARKET) ? styles.active : ''
          }`}
        >
          <FontAwesomeIcon icon={faMapMarkerAlt} />
        </div>
        <span className={styles.poiFont}>Supermarkt</span>
      </button>

      <button
        type={'button'}
        data-testid={'poiWrapper.fastFood'}
        className={styles.poiButton}
        onClick={() => togglePoi(POI_TYPE.FAST_FOOD)}
      >
        <div
          className={`${styles.poiIcon} ${styles.poiIconFastFood} ${
            activePoiList.includes(POI_TYPE.FAST_FOOD) ? styles.active : ''
          }`}
        >
          <FontAwesomeIcon icon={faMapMarkerAlt} />
        </div>
        <span className={styles.poiFont}>Fast Food</span>
      </button>
    </div>
  );
};

export default PoiWrapper;
