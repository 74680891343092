import PageLayout from '../../../../component/layouts/PageLayout';
import { ROUTES } from '../../../../core/const/routes';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import queryString from 'querystring';
import { decodeToken } from 'react-jwt';
import { wohnsinnServices } from '../../../../App';
import {
  EMAIL_SCHEMA,
  ICheckIfUserExistsResponse,
  IOrganization,
  LANDLORD_ROLES,
  REGISTRATION_STATES,
  USER_TYPE,
} from '@wohnsinn/ws-ts-lib';
import { useContext, useEffect, useState } from 'react';
import SnackBarContext from '../../../../core/context/snackbar.context';
import { FormContext } from '../../../../core/context/form.context';
import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi/dist/joi';
import { useForm } from 'react-hook-form';
import TextInput from '../../../../component/atoms/formElement/InputText';
import FB_FUNCTION_URLS from '../../../../core/const/fb-function-names';
import { Grid } from '@mui/material';
import { SUBMIT_BUTTON_MODE } from '../../../../core/enum/submit-button-mode.enum';
import PageTitle from '../../../../component/molecules/PageTitle';
import RegistrationLayout from '../../../../component/organisms/registration/RegistrationLayout';

const ADD_ORGANIZATION_MEMBER_FORM = 'add-organization-member-form';

const PASSWORD_SCHEMA = Joi.string().min(8).max(24).regex(/\d/).regex(/\W|_/).required();

export const USER_CREDENTIALS_FORM_SCHEMA = Joi.object({
  email: EMAIL_SCHEMA,
  password: PASSWORD_SCHEMA,
  passwordRepeat: Joi.any().equal(Joi.ref('password')).required(),
  organization: Joi.string().optional(),
});

const InvitationRegistrationView = () => {
  const [buttonSubmitMode, setButtonSubmitMode] = useState<SUBMIT_BUTTON_MODE>(SUBMIT_BUTTON_MODE.NONE);
  const { t } = useTranslation('common');
  const { t: r } = useTranslation('routes');
  const { organizationService, firebaseFunctionsService, landlordService, firebaseAuthService, userService } =
    wohnsinnServices;
  const [organization, setOrganization] = useState<IOrganization>(null);
  const { handleSnackBar } = useContext(SnackBarContext);

  const location = useLocation();
  const values = queryString.parse(location.search);
  const encodedToken: string = values.token as string;
  const organizationFromParams: {
    email: string;
    organizationId: string;
    role: LANDLORD_ROLES;
    iat: number;
    exp: number;
  } = encodedToken ? decodeToken(encodedToken) : null;

  useEffect(() => {
    // GET ORGANIZATION IF IS INVITATION LINK
    if (organizationFromParams) {
      (async () => {
        try {
          const organization = await organizationService.getOrganizationById(organizationFromParams.organizationId);
          const isOrganizationRequestValid = await organizationService.isOrganizationActivationRequestValid(
            organization.id,
            organizationFromParams.email
          );

          if (isOrganizationRequestValid) {
            setOrganization(organization);
            setValue('organization', organization?.title);
            setValue('email', organizationFromParams?.email);
          } else {
            handleSnackBar('toast.invalidInvite', 'info');
          }
        } catch (e) {
          console.error('Error on getOrganizationById:', e);
        }
      })();
    }
  }, [location]);

  const {
    control,
    handleSubmit,
    formState: { isValid },
    watch,
    setValue,
  } = useForm<{
    organization: string;
    email: string;
    password: string;
    passwordRepeat: string;
  }>({
    mode: 'onChange',
    reValidateMode: 'onBlur',
    resolver: joiResolver(USER_CREDENTIALS_FORM_SCHEMA),
  });

  const onSubmit = async () => {
    setButtonSubmitMode(SUBMIT_BUTTON_MODE.SUBMITTING);
    const email = watch('email');
    try {
      // check if user exists
      const response: { data: ICheckIfUserExistsResponse } = (await firebaseFunctionsService.callFbFunction(
        FB_FUNCTION_URLS.user.checkIfUserExists,
        {
          params: { email },
        }
      )) as { data: ICheckIfUserExistsResponse };
      if (!response.data.userExists) {
        // create user
        const uid = await firebaseAuthService.createUserWithoutPassword(email, USER_TYPE.LANDLORD, true);
        // add password
        await firebaseAuthService.updateUserPassword(watch('password'));
        // add to organization to landlordProfile

        await landlordService.addOrganizationToLandlord(uid, organization?.id, organizationFromParams.role);
        await userService.updateUserData({ registrationState: REGISTRATION_STATES.ADD_PROFILE }, uid);
        setButtonSubmitMode(SUBMIT_BUTTON_MODE.SUCCESS);
      }
    } catch (e) {
      console.error(e);
      setButtonSubmitMode(SUBMIT_BUTTON_MODE.ERROR);
    }
  };

  return (
    <PageLayout hideFooter hideMenu showPageTitle={false} pageTitle={r(ROUTES.staticRoutes.invitation.title)}>
      <Grid container>
        <Grid item xs={12}>
          <PageTitle
            pageTitle={'Du wurdest eingeladen'}
            secondPageTitle={`Du wurdest eingeladen der Firma ${organization?.title} beizutreten`}
          />

          <RegistrationLayout
            buttonSubmitMode={buttonSubmitMode}
            isValidForm={isValid}
            formId={ADD_ORGANIZATION_MEMBER_FORM}
            submitButtonText={'Firma beitreten'}
            emailProp={organizationFromParams?.email}
          >
            {organization ? (
              <FormContext.Provider value={{ control }}>
                <form id={ADD_ORGANIZATION_MEMBER_FORM} onSubmit={handleSubmit(onSubmit, console.error)}>
                  <TextInput label={t('password.label')} name={'password'} type={'password'} required />
                  <TextInput label={t('passwordRepeat.label')} name={'passwordRepeat'} type={'password'} required />
                </form>
              </FormContext.Provider>
            ) : (
              'Organisation nicht gefunden.'
            )}
          </RegistrationLayout>
        </Grid>
      </Grid>
    </PageLayout>
  );
};

export default InvitationRegistrationView;
