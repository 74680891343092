import { FC, PropsWithChildren } from 'react';
import ApplicationListContext from '../context/application-list.context';
import useTenantApplicationListHook from '../hook/tenant-application-list.hook';

const ApplicationListContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const applications = useTenantApplicationListHook();

  return (
    <ApplicationListContext.Provider
      value={{
        likeList: applications.applicationListLike,
        maybeList: applications.applicationListMaybe,
        nopeList: applications.applicationListNope,
        isLoading: applications.isLoading,
        unreadNotifications: applications.unreadChatNotifications,
      }}
    >
      {children}
    </ApplicationListContext.Provider>
  );
};

export default ApplicationListContextProvider;
