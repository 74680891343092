import { FC } from 'react';
import { IApartmentCost } from '@wohnsinn/ws-ts-lib';
import ItemList, { TItemListItem } from '../ItemList';
import { useTranslation } from 'react-i18next';
import Headline, { HEADLINE_SIZE } from 'component/atoms/typographie/Headline';
import { formatNumberGermanStyle } from 'core/helper/get-formatted-price';

export interface IApartmentCostsProps {
  apartmentCosts?: IApartmentCost;
  kitchenPrice?: number;
  carParkRent?: number;
  carportRent?: number;
  garageRent?: number;
  freeSpaceRent?: number;
  basementGarageRent?: number;
  duplexRent?: number;
  isSalesObject?: boolean;
}

const ApartmentCosts: FC<IApartmentCostsProps> = ({
  apartmentCosts,
  kitchenPrice,
  carParkRent,
  carportRent,
  garageRent,
  freeSpaceRent,
  basementGarageRent,
  duplexRent,
  isSalesObject,
}) => {
  const { t } = useTranslation('common');
  const isWogeEnvironment = process.env.REACT_APP_ENVIRONMENT === 'woge';

  const valueIsNotSet = (value: any) => {
    return value === undefined || value === null;
  };

  const items: TItemListItem[] = [];
  const parkingSpots: TItemListItem[] = [];

  if (apartmentCosts) {
    if (apartmentCosts?.purchasePrice) {
      items.push({
        key: 'purchasePrice',
        label: t('apartment.cost.purchasePrice.value.label'),
        value: apartmentCosts?.purchasePrice?.onRequest
          ? t(`priceOnRequest`)
          : `${formatNumberGermanStyle(apartmentCosts?.purchasePrice?.value)} € ${
              valueIsNotSet(apartmentCosts?.plusVAT) ? '' : apartmentCosts.plusVAT ? 'zzgl. MwSt.' : 'inkl. MwSt.'
            }`,
      });
    }

    if (apartmentCosts?.developmentCosts) {
      items.push({
        key: 'developmentCosts',
        label: t('apartment.cost.developmentCosts.label'),
        value: `${formatNumberGermanStyle(apartmentCosts?.developmentCosts)} €`,
      });
    }

    if (apartmentCosts?.houseMoney) {
      items.push({
        key: 'houseMoney',
        label: t('apartment.cost.houseMoney.label'),
        value: `${formatNumberGermanStyle(apartmentCosts?.houseMoney)} €`,
      });
    }

    if (apartmentCosts?.outsideCommission) {
      items.push({
        key: 'outsideCommission',
        label: t('apartment.cost.outsideCommission.content'),
        value: `${apartmentCosts?.outsideCommission.content} € ${
          valueIsNotSet(apartmentCosts?.outsideCommission.includesVAT)
            ? ''
            : apartmentCosts?.outsideCommission.includesVAT
            ? 'inkl. MwSt.'
            : 'zzgl. MwSt.'
        }`,
      });
    }

    if (apartmentCosts?.coldRent) {
      items.push({
        key: 'coldRent',
        label: t('apartment.cost.coldRent.label'),
        value: `${formatNumberGermanStyle(apartmentCosts?.coldRent)} €`,
      });
    }
    if (apartmentCosts?.operationalCosts) {
      items.push({
        key: 'operationalCosts',
        label: t('apartment.cost.operationalCost.label'),
        value: `${formatNumberGermanStyle(apartmentCosts?.operationalCosts)} €`,
      });
    }

    if (!isSalesObject) {
      if (apartmentCosts?.isHeatingIncluded) {
        items.push({ key: 'isHeatingIncluded', label: t('apartment.cost.isHeatingIncluded.short'), value: t('yes') });
      } else {
        items.push({ key: 'isHeatingIncluded', label: t('apartment.cost.isHeatingIncluded.short'), value: t('no') });
      }
    }

    if (apartmentCosts?.heatingCosts) {
      items.push({
        key: 'heatingCosts',
        label: t('apartment.cost.heatingCosts'),
        value: `${formatNumberGermanStyle(apartmentCosts?.heatingCosts)} €`,
      });
    }

    if (apartmentCosts?.warmRent) {
      items.push({
        key: 'warmRent',
        label: t('apartment.cost.warmRent.label'),
        value: `${formatNumberGermanStyle(apartmentCosts?.warmRent)} €`,
      });
    }
    if (apartmentCosts?.deposit) {
      let isStringEmpty = false;

      if (typeof apartmentCosts?.deposit === 'string') {
        const checkIfEmptyString = apartmentCosts?.deposit.replaceAll(' ', '');
        if (checkIfEmptyString.length <= 0) {
          isStringEmpty = true;
        }
      }

      if (!isStringEmpty) {
        items.push({
          key: 'deposit',
          label: isWogeEnvironment ? t('apartment.cost.deposit.cooperative.label') : t('apartment.cost.deposit.label'),
          value: `${apartmentCosts?.deposit} ${
            !isWogeEnvironment && typeof apartmentCosts?.deposit === 'number' ? '€' : ''
          }`,
        });
      }
    }
    if (kitchenPrice) {
      items.push({
        key: 'kitchenPrice',
        label: t('apartment.cost.kitchenPrice.label'),
        value: `${formatNumberGermanStyle(kitchenPrice)} €`,
      });
    }

    if (carParkRent) {
      items.push({
        key: 'carParkRent',
        label: t('apartment.cost.carParkRent.label'),
        value: `${formatNumberGermanStyle(carParkRent)} €`,
      });
    }

    if (carportRent) {
      items.push({
        key: 'carportRent',
        label: t('apartment.cost.carportRent.label'),
        value: `${formatNumberGermanStyle(carportRent)} €`,
      });
    }

    if (garageRent) {
      items.push({
        key: 'garageRent',
        label: t('apartment.cost.garageRent.label'),
        value: `${formatNumberGermanStyle(garageRent)} €`,
      });
    }

    if (freeSpaceRent) {
      items.push({
        key: 'freeSpaceRent',
        label: t('apartment.cost.freeSpaceRent.label'),
        value: `${formatNumberGermanStyle(freeSpaceRent)} €`,
      });
    }

    if (basementGarageRent) {
      items.push({
        key: 'basementGarageRent',
        label: t('apartment.cost.basementGarageRent.label'),
        value: `${formatNumberGermanStyle(basementGarageRent)} €`,
      });
    }

    if (duplexRent) {
      items.push({
        key: 'duplexRent',
        label: t('apartment.cost.duplexRent.label'),
        value: `${formatNumberGermanStyle(duplexRent)} €`,
      });
    }

    if (apartmentCosts?.stpCarport?.count) {
      parkingSpots.push({
        key: 'stpCarport',
        label: t('apartment.cost.stpCarport.count.label'),
        value: `${formatNumberGermanStyle(apartmentCosts?.stpCarport?.count)}`,
      });
    }
    if (apartmentCosts?.stpCarport?.purchasePrice) {
      parkingSpots.push({
        key: 'stpCarport',
        label: t('apartment.cost.stpCarport.purchasePrice.label'),
        value: `${formatNumberGermanStyle(apartmentCosts?.stpCarport?.purchasePrice)} €`,
      });
    }

    if (apartmentCosts?.stpDuplex?.count) {
      parkingSpots.push({
        key: 'stpDuplex',
        label: t('apartment.cost.stpDuplex.count.label'),
        value: `${formatNumberGermanStyle(apartmentCosts?.stpDuplex.count)}`,
      });
    }
    if (apartmentCosts?.stpDuplex?.purchasePrice) {
      parkingSpots.push({
        key: 'stpDuplex',
        label: t('apartment.cost.stpDuplex.purchasePrice.label'),
        value: `${formatNumberGermanStyle(apartmentCosts?.stpDuplex.purchasePrice)} €`,
      });
    }

    if (apartmentCosts?.stpOutdoorParking?.count) {
      parkingSpots.push({
        key: 'stpOutdoorParking',
        label: t('apartment.cost.stpOutdoorParking.count.label'),
        value: `${apartmentCosts?.stpOutdoorParking.count}`,
      });
    }
    if (apartmentCosts?.stpOutdoorParking?.purchasePrice) {
      parkingSpots.push({
        key: 'stpOutdoorParking',
        label: t('apartment.cost.stpOutdoorParking.purchasePrice.label'),
        value: `${formatNumberGermanStyle(apartmentCosts?.stpOutdoorParking.purchasePrice)} €`,
      });
    }

    if (apartmentCosts?.stpGarage?.count) {
      parkingSpots.push({
        key: 'stpGarage',
        label: t('apartment.cost.stpGarage.count.label'),
        value: `${apartmentCosts?.stpGarage.count}`,
      });
    }
    if (apartmentCosts?.stpGarage?.purchasePrice) {
      parkingSpots.push({
        key: 'stpGarage',
        label: t('apartment.cost.stpGarage.purchasePrice.label'),
        value: `${formatNumberGermanStyle(apartmentCosts?.stpGarage.purchasePrice)} €`,
      });
    }

    if (apartmentCosts?.stpParkingHouse?.count) {
      parkingSpots.push({
        key: 'stpParkingHouse',
        label: t('apartment.cost.stpParkingHouse.count.label'),
        value: `${apartmentCosts?.stpParkingHouse.count}`,
      });
    }
    if (apartmentCosts?.stpParkingHouse?.purchasePrice) {
      parkingSpots.push({
        key: 'stpParkingHouse',
        label: t('apartment.cost.stpParkingHouse.purchasePrice.label'),
        value: `${formatNumberGermanStyle(apartmentCosts?.stpParkingHouse.purchasePrice)} €`,
      });
    }

    if (apartmentCosts?.stpUndergroundParking?.count) {
      parkingSpots.push({
        key: 'stpUndergroundParking',
        label: t('apartment.cost.stpUndergroundParking.count.label'),
        value: `${apartmentCosts?.stpUndergroundParking.count}`,
      });
    }
    if (apartmentCosts?.stpUndergroundParking?.purchasePrice) {
      parkingSpots.push({
        key: 'stpUndergroundParking',
        label: t('apartment.cost.stpUndergroundParking.purchasePrice.label'),
        value: `${formatNumberGermanStyle(apartmentCosts?.stpUndergroundParking.purchasePrice)} €`,
      });
    }
  }

  return (
    <>
      <ItemList items={items} />
      {parkingSpots?.length ? (
        <>
          <Headline size={HEADLINE_SIZE.H3} tag={'span'}>
            {t('apartment.cost.parkingSpace')}
            {parkingSpots?.length}
          </Headline>
          <ItemList items={parkingSpots} />
        </>
      ) : null}
    </>
  );
};

export default ApartmentCosts;
