import { secondsConverter } from './duration.js';

export const secondsToIso8601Duration = (totalSeconds) => {
  const { hours, minutes, seconds } = secondsConverter(totalSeconds, 'hms');

  let result = '';

  if (hours) {
    result += `${hours}H`;
  }

  if (minutes || (totalSeconds > 60 && seconds !== 0)) {
    result += `${minutes}M`;
  }

  if (seconds || totalSeconds === 0) {
    result += `${seconds}S`;
  }

  return result;
};
