import styles from './Testimonial.module.scss';
import Headline, { HEADLINE_SIZE } from '../../atoms/typographie/Headline';
import Avatar, { AVATAR_SIZE } from '../../atoms/Avatar';
import Text, { TEXT_WEIGHT } from '../../atoms/typographie/Text';
import { FC } from 'react';

export interface ITestimonialProps {
  logo?: string;
  logoAlt?: string;
  avatar: string;
  name: string;
  quote: string;
  company: string;
}

const Testimonial: FC<ITestimonialProps> = ({ logo, logoAlt, avatar, quote, name, company }) => {
  return (
    <div>
      <Headline mobileSize={HEADLINE_SIZE.H3} align={'center'}>
        {quote}
      </Headline>
      <div className={styles.avatar}>
        <Avatar size={AVATAR_SIZE.lg} avatarUrl={avatar} />
      </div>
      <Text align={'center'} weight={TEXT_WEIGHT.TEXT_WEIGHT_BOLD}>
        {name}
      </Text>
      <Text align={'center'}>{company}</Text>
      {logo ? (
        <div className={styles.logo}>
          <img src={logo} alt={logoAlt} />
        </div>
      ) : null}
    </div>
  );
};

export default Testimonial;
