import PageLayout from '../../../../component/layouts/PageLayout';
import { ROUTES } from '../../../../core/const/routes';
import { useTranslation } from 'react-i18next';
import { wohnsinnServices } from '../../../../App';
import TextInput from '../../../../component/atoms/formElement/InputText';
import { FormContext } from '../../../../core/context/form.context';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { useContext, useState } from 'react';
import UserContext from '../../../../core/context/user.context';
import { REGISTRATION_STATES, USER_TYPE } from '@wohnsinn/ws-ts-lib';
import RegistrationLayout from '../../../../component/organisms/registration/RegistrationLayout';
import { SUBMIT_BUTTON_MODE } from '../../../../core/enum/submit-button-mode.enum';
import { useNavigate } from 'react-router-dom';

const PASSWORD_SCHEMA = Joi.string().min(8).max(24).regex(/\d/).regex(/\W|_/).required();
export const PASSWORD_FORM_SCHEMA = Joi.object({
  password: PASSWORD_SCHEMA,
  passwordRepeat: Joi.any().equal(Joi.ref('password')).required(),
});

const CREATE_PASSWORD_FORM_ID = 'create-password-view';

const CreatePasswordView = () => {
  const [buttonSubmitMode, setButtonSubmitMode] = useState<SUBMIT_BUTTON_MODE>(SUBMIT_BUTTON_MODE.NONE);
  const { t: r } = useTranslation('routes');
  const { t } = useTranslation('common');
  const { firebaseAuthService, userService } = wohnsinnServices;
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  const { control, handleSubmit, watch, formState } = useForm<{
    password: string;
    passwordRepeat: string;
  }>({
    mode: 'onChange',
    reValidateMode: 'onBlur',
    resolver: joiResolver(PASSWORD_FORM_SCHEMA),
  });

  const onSubmit = async () => {
    setButtonSubmitMode(SUBMIT_BUTTON_MODE.SUBMITTING);
    try {
      await firebaseAuthService.updateUserPassword(watch('password'));
      await userService.updateUserData(
        {
          registrationState:
            user?.activeUserType === USER_TYPE.LANDLORD
              ? REGISTRATION_STATES.SELECT_LANDLORD_TYPE
              : REGISTRATION_STATES.FINISH_LOADING_SCREEN,
        },
        user.uid
      );
      setButtonSubmitMode(SUBMIT_BUTTON_MODE.SUCCESS);
      navigate(
        user?.activeUserType === USER_TYPE.LANDLORD
          ? r(ROUTES.userRoutes.registration.createLandlordTunnel.path)
          : r(ROUTES.userRoutes.registration.createTenantTunnel.path)
      );
    } catch (e) {
      setButtonSubmitMode(SUBMIT_BUTTON_MODE.ERROR);
      console.error(e);
    }
  };

  return (
    <PageLayout
      pageTitle={r(ROUTES.userRoutes.registration.createPassword.title)}
      secondPageTitle={
        'Es ist wichtig, ein Passwort festzulegen, um die Sicherheit deiner persönlichen Informationen zu gewährleisten und unbefugten Zugriff zu verhindern.'
      }
    >
      <RegistrationLayout
        formId={CREATE_PASSWORD_FORM_ID}
        isValidForm={formState.isValid}
        buttonSubmitMode={buttonSubmitMode}
      >
        <FormContext.Provider value={{ control }}>
          <form id={CREATE_PASSWORD_FORM_ID} onSubmit={handleSubmit(onSubmit, console.error)}>
            <TextInput label={t('password.label')} name={'password'} type={'password'} required />
            <TextInput label={t('passwordRepeat.label')} name={'passwordRepeat'} type={'password'} required />
          </form>
        </FormContext.Provider>
      </RegistrationLayout>
    </PageLayout>
  );
};

export default CreatePasswordView;
