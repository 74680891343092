import { FC } from 'react';
import styles from './CreateSchufaView.module.scss';
import { useTranslation } from 'react-i18next';
import PageLayout from 'component/layouts/PageLayout';
import { ROUTES } from 'core/const/routes';

const CreateSchufaView: FC = () => {
  const { t: r } = useTranslation('routes');

  return (
    <PageLayout showStaticMenu showBackButton pageTitle={r(ROUTES.staticRoutes.move.createSchufa.title)}>
      <iframe
        className={styles.responsiveIframe}
        src="https://bonitaetscheck.meineschufa.de/econ/process/14/bonitaetscheck-ms"
      />
    </PageLayout>
  );
};

export default CreateSchufaView;
