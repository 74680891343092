import { FC } from 'react';
import styles from './MobileHeaderButton.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import Text, { TEXT_WEIGHT } from '../../typographie/Text';

const MobileHeaderButton: FC<{
  onClick: () => void;
  icon: IconProp;
  activeIcon?: IconProp;
  active?: boolean;
  hideText?: boolean;
  text: string;
  activeText?: string;
}> = ({ onClick, icon, activeIcon, active = false, text, activeText, hideText = false }) => {
  return (
    <button onClick={onClick} className={styles.mobileHeaderButton}>
      {hideText ? (
        <span className="sr-only">{active && activeText ? activeText : text ? text : null}</span>
      ) : (
        <Text weight={TEXT_WEIGHT.TEXT_WEIGHT_SEMI_BOLD}>{active && activeText ? activeText : text ? text : null}</Text>
      )}
      <FontAwesomeIcon className={hideText ? '' : styles.showText} icon={active ? activeIcon : icon} size={'lg'} />
    </button>
  );
};

export default MobileHeaderButton;
