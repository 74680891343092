import Joi from 'joi';

import { APARTMENT_DESIRED_TENANT_SCHEMA } from './desired-tenant';
import { APARTMENT_EQUIPMENT_SCHEMA } from './equipment';
import {
  ADDRESS_SCHEMA,
  APARTMENT_COST_SCHEMA,
  APARTMENT_RENT_STATUS,
  APARTMENT_TYPE,
  APARTMENT_TYPE_OF_MARKETING,
  APARTMENT_TYPE_OF_USAGE,
  COORDINATES_SCHEMA,
  DATE_SCHEMA,
  HOUSE_TYPE,
  ID_SCHEMA,
  IMMO_TYPE,
  MEDIA_SCHEMA,
} from '@wohnsinn/ws-ts-lib';
import { APARTMENT_ENERGY_SUPPLY_SCHEMA } from 'core/schemas/apartment/energy-supply';

export * from './cost';
export * from './desired-tenant';
export * from './energy-supply';
export * from './equipment';

export const APARTMENT_ADDRESS_SCHEMA = {
  ...ADDRESS_SCHEMA,
  apartmentType: Joi.string()
    .valid(...Object.keys(APARTMENT_TYPE), ...Object.keys(HOUSE_TYPE))
    .optional()
    .allow(null),
  coordinates: [COORDINATES_SCHEMA, Joi.allow(null)],
  floor: Joi.when('apartmentType', {
    is: APARTMENT_TYPE.ROOF || APARTMENT_TYPE.LEVEL,
    then: Joi.number().sign('positive').required(),
    otherwise: Joi.any(),
  }),
  hasPublishedAddress: Joi.boolean().required(),
  buildingLevel: Joi.any(),
  locationInBuilding: Joi.string().optional().allow(null),
};

export const APARTMENT_MAIN_INFORMATION_SCHEMA = {
  address: APARTMENT_ADDRESS_SCHEMA,
  earliestMoveIn: Joi.string().optional().allow(null),
  hasFixedTerm: Joi.boolean().optional().allow(null),
  fixedTerm: Joi.when('hasFixedTerm', {
    is: true,
    then: DATE_SCHEMA,
    otherwise: [Joi.allow(null), Joi.allow('')],
  }),
  hasMinPeriod: Joi.boolean().optional().allow(null),
  minPeriod: Joi.when('hasMinPeriod', {
    is: true,
    then: Joi.any(),
    otherwise: [Joi.allow(null), Joi.allow('')],
  }),
  propertyArea: Joi.number().sign('positive'),
  typeOfUsage: Joi.string().valid(...Object.keys(APARTMENT_TYPE_OF_USAGE)),
  typeOfImmo: Joi.string().valid(...Object.keys(IMMO_TYPE)),
  typeOfMarketing: Joi.string().valid(...Object.keys(APARTMENT_TYPE_OF_MARKETING)),
  buildingLevel: Joi.any(),
};

export const APARTMENT_RENT_STATUS_SCHEMA = {
  rentStatus: Joi.array().items(APARTMENT_RENT_STATUS).length(1).required(),
};

export const APARTMENT_CUSTOM_NOTES_SCHEMA = { customNotes: [Joi.string(), Joi.allow(null)] };

export const APARTMENT_MEDIA_SCHEMA = { media: Joi.array().items(MEDIA_SCHEMA).min(0).max(50).required() };

export const APARTMENT_MATTERPORT_ID_SCHEMA = { matterPortId: [Joi.string(), Joi.allow(null)] };
export const APARTMENT_VIRTUAL_TOUR_LINK_SCHEMA = { virtualTourLink: [Joi.string(), Joi.allow(null)] };

export const APARTMENT_IS_PUBLISHED_SCHEMA = {
  matterPortId: [Joi.boolean(), Joi.allow(null)],
  publishedAt: DATE_SCHEMA,
};

export const APARTMENT_SCHEMA = {
  ...APARTMENT_VIRTUAL_TOUR_LINK_SCHEMA,
  ...APARTMENT_RENT_STATUS_SCHEMA,
  ...APARTMENT_CUSTOM_NOTES_SCHEMA,
  ...APARTMENT_MEDIA_SCHEMA,
  ...APARTMENT_MATTERPORT_ID_SCHEMA,
  ...APARTMENT_IS_PUBLISHED_SCHEMA,
  ...APARTMENT_COST_SCHEMA,
  cost: [APARTMENT_COST_SCHEMA, Joi.allow(null)],
  creatorId: ID_SCHEMA,
  description: [Joi.string(), Joi.allow(null)],
  desiredTenant: [APARTMENT_DESIRED_TENANT_SCHEMA, Joi.allow(null)],
  energySupply: [APARTMENT_ENERGY_SUPPLY_SCHEMA, Joi.allow(null)],
  equipment: [APARTMENT_EQUIPMENT_SCHEMA, Joi.allow(null)],
  mainInformation: APARTMENT_MAIN_INFORMATION_SCHEMA,
};
