import { FC } from 'react';
import styles from './BlogPreviewGrid.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRight } from '@fortawesome/pro-light-svg-icons';
import Text, { TEXT_COLOR, TEXT_WEIGHT, TEXT_TYPE } from '../../atoms/typographie/Text';
import Headline, { HEADLINE_SIZE } from '../../atoms/typographie/Headline';

export interface IBlogPreview {
  headline: string;
  text: string;
  link: string;
  tag: string;
  logo?: string;
}

export interface IBlogPreviewGridProps {
  blogs: IBlogPreview[];
}

const BlogPreviewGrid: FC<IBlogPreviewGridProps> = ({ blogs }) => {
  return (
    <div className={styles.wrapper}>
      {blogs.map((blog, index) => (
        <a key={index} href={blog.link} target={'_blank'} rel={'noreferrer noopener'}>
          <div className={styles.blog}>
            {blog.logo ? <img className={styles.logo} src={blog.logo} alt={blog.tag} /> : null}
            <div className={styles.icon}>
              <FontAwesomeIcon icon={faArrowUpRight} />
            </div>
            <Text
              type={TEXT_TYPE.TEXT_TYPE_CAPTION}
              weight={TEXT_WEIGHT.TEXT_WEIGHT_BOLD}
              color={TEXT_COLOR.TEXT_COLOR_PRIMARY}
            >
              {blog.tag}
            </Text>
            <Headline tag={'span'} size={HEADLINE_SIZE.H3}>
              {blog.headline}
            </Headline>
            <Text type={TEXT_TYPE.TEXT_TYPE_BODY} color={TEXT_COLOR.TEXT_COLOR_ACCENT}>
              {blog.text}
            </Text>
          </div>
        </a>
      ))}
    </div>
  );
};

export default BlogPreviewGrid;
