import ApartmentCard from '../../molecules/Cards/ApartmentCard';
import { IApartment } from '@wohnsinn/ws-ts-lib';
import { FC } from 'react';
import { IApartmentWithRating } from '../../../view/static/organization/OrganizationPortfolioView';
import Headline, { HEADLINE_SIZE } from '../../../component/atoms/typographie/Headline';
import { Grid } from '@mui/material';

const ApartmentCardGrid: FC<{
  apartmentList: IApartment[] | IApartmentWithRating[];
  route: string;
  noApartmentsMessage: string;
}> = ({ apartmentList, route, noApartmentsMessage }) => {
  if (!apartmentList?.length) {
    return <Headline size={HEADLINE_SIZE.H3}>{noApartmentsMessage}</Headline>;
  }

  return (
    <Grid spacing={2} container>
      {apartmentList.map((apartment) => {
        return (
          <Grid item key={apartment.id} xs={12} sm={6} lg={4}>
            <ApartmentCard
              apartment={apartment}
              route={route.replace(':apartmentId', apartment.id)}
              rating={'rating' in apartment && apartment?.rating ? apartment.rating : null}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};
export default ApartmentCardGrid;
