import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import PageLayout from '../../../component/layouts/PageLayout';
import { ROUTES } from '../../../core/const/routes';
import {
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import { wohnsinnServices } from '../../../App';

const AdminUserManagement = () => {
  const { t } = useTranslation('common');
  const { t: r } = useTranslation('routes');
  const { userService, landlordService } = wohnsinnServices;

  const { data: countData, isLoading: isCountLoading } = useQuery({
    queryKey: ['landlordCount'],

    queryFn: () => landlordService.getLandLordCount(),
  });

  const { data, isLoading, isError, fetchNextPage, hasNextPage } = useInfiniteQuery({
    queryKey: ['users'],
    queryFn: ({ pageParam = 0 }) => userService.fetchPaginatedUsers(pageParam),
    getNextPageParam: (lastPage) => lastPage.lastVisible || undefined,
    initialPageParam: null,
  });

  return (
    <PageLayout pageTitle={r(ROUTES.adminRoutes.users.title)}>
      {!isCountLoading ? <div>USER ANZAHL: {countData}</div> : 'Läd noch'}
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell align="right">Email Verified</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.pages.map((page) =>
              page?.users?.map((user) => (
                <TableRow key={user?.id}>
                  <TableCell component="th" scope="row">
                    {user?.id}
                  </TableCell>
                  <TableCell align="right">{user?.isEmailVerified ? 'Yes' : 'No'}</TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {isError && <div>{t('errorFetchingUsers')}</div>}
      <Button
        variant="contained"
        onClick={() => fetchNextPage()}
        disabled={!hasNextPage || isLoading}
        style={{ marginTop: '16px' }}
      >
        {isLoading ? <CircularProgress size={24} /> : t('loadMore')}
      </Button>
      {!hasNextPage && <p>{t('allUsersLoaded')}</p>}
    </PageLayout>
  );
};

export default AdminUserManagement;
