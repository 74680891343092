import { FC } from 'react';
import { IImage } from '../../../core/const/images';
import { useTranslation } from 'react-i18next';
import styles from './Image.module.scss';

const Image: FC<{ image: IImage; rounded: boolean }> = ({ image, rounded = false }) => {
  const { t: i } = useTranslation('images');
  return <img className={`${styles.image} ${rounded ? styles.rounded : ''}`} src={i(image.src)} alt={i(image.alt)} />;
};

export default Image;
