import { FC, useContext } from 'react';
import { ROUTES } from 'core/const/routes';
import PageLayout from 'component/layouts/PageLayout';
import { MODAL_IDENTIFIER } from 'core/enum/modals.enum';
import ModalContext from 'core/context/modal.context';
import { useTranslation } from 'react-i18next';
import Headline from 'component/atoms/typographie/Headline';
import { Grid } from '@mui/material';

const PasswordResetConfirmationView: FC = () => {
  const { t } = useTranslation('common');
  const { t: r } = useTranslation('routes');
  const { openModal } = useContext(ModalContext);
  return (
    <PageLayout showStaticMenu showPageTitle={false} pageTitle={r(ROUTES.staticRoutes.passwordResetConfirmation.title)}>
      <Grid container justifyContent={'center'}>
        <Headline>{t('component.atoms.formElement.FormErrorMessage.passwordReseted')}</Headline>
        <button onClick={() => openModal({ id: MODAL_IDENTIFIER.SIGN_IN })} type={'button'}>
          {t('signIn')}
        </button>
      </Grid>
    </PageLayout>
  );
};

export default PasswordResetConfirmationView;
