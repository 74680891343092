import { useContext, useState } from 'react';
import UserContext from '../../../../core/context/user.context';
import { wohnsinnServices } from '../../../../App';
import { SUBMIT_BUTTON_MODE } from '../../../../core/enum/submit-button-mode.enum';
import { Grid } from '@mui/material';
import CallToActionScreen from '../../../molecules/CallToActionScreen';
import { getSubmitIcon } from '../../../atoms/Buttons/CTAButton';
import { REGISTRATION_STATES } from '@wohnsinn/ws-ts-lib';

const CreateRegistrationWelcomeScreen = () => {
  const { user } = useContext(UserContext);
  const { userService } = wohnsinnServices;
  const [buttonSubmitMode, setButtonSubmitMode] = useState<SUBMIT_BUTTON_MODE>(SUBMIT_BUTTON_MODE.NONE);
  return (
    <Grid container maxWidth={null} marginX={'auto'} paddingTop={{ xs: 6, md: 8 }}>
      <Grid item xs={12}>
        <CallToActionScreen
          headline={'👋 Willkommen bei Wohnsinn'}
          text={'Deine ersten Schritte mit Wohnsinn'}
          buttonText={'Weiter mit der Registrierung'}
          buttonSpinIcon={buttonSubmitMode === SUBMIT_BUTTON_MODE.SUBMITTING}
          buttonIcon={getSubmitIcon(buttonSubmitMode)}
          onClick={() => {
            setButtonSubmitMode(SUBMIT_BUTTON_MODE.SUBMITTING);
            setTimeout(async () => {
              await userService.updateUserData(
                { registrationState: REGISTRATION_STATES.SELECT_LANDLORD_TYPE },
                user.uid
              );
              setButtonSubmitMode(SUBMIT_BUTTON_MODE.NONE);
            }, 500);
          }}
        />
      </Grid>
    </Grid>
  );
};

export default CreateRegistrationWelcomeScreen;
