import ModalWrapper, { IModalWrapperButtonProps } from '../ModalWrapper';
import { useTranslation } from 'react-i18next';
import { faUserPlus } from '@fortawesome/pro-light-svg-icons/faUserPlus';
import { SUBMIT_BUTTON_MODE } from 'core/enum/submit-button-mode.enum';
import { FormContext } from 'core/context/form.context';
import TextInput from 'component/atoms/formElement/InputText';
import { FC, useContext, useState } from 'react';
import UserContext from 'core/context/user.context';
import SnackBarContext from 'core/context/snackbar.context';
import ModalContext from 'core/context/modal.context';
import { wohnsinnServices } from 'App';
import { LANDLORD_ROLES, USER_TYPE, EMAIL_SCHEMA } from '@wohnsinn/ws-ts-lib';
import { useForm } from 'react-hook-form';
import { getSubmitIcon } from 'component/atoms/Buttons/CTAButton';
import Dropdown, { ISelectOptionWithComingSoon } from 'component/atoms/formElement/Dropdown';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import FB_FUNCTION_URLS from 'core/const/fb-function-names';

const INVITATION_SCHEME: any = {
  email: EMAIL_SCHEMA.required(),
  role: Joi.string().required(),
};

const CreateUserInvitationModal: FC<{ onSuccess?: () => any }> = ({ onSuccess }) => {
  const { t: c } = useTranslation('common');
  const { t } = useTranslation('createUserInvitation');
  const { activeUserType, landlordProfile, tenantProfile } = useContext(UserContext);
  const [buttonSubmitMode, setButtonSubmitMode] = useState(SUBMIT_BUTTON_MODE.NONE);
  const { handleSnackBar } = useContext(SnackBarContext);
  const { closeModal } = useContext(ModalContext);
  const { firebaseFunctionsService } = wohnsinnServices;
  const landlordName: string =
    landlordProfile?.personalInformation?.firstName && landlordProfile?.personalInformation?.lastName
      ? `${landlordProfile?.personalInformation.firstName} ${landlordProfile?.personalInformation.lastName}`
      : '';
  const tenantName: string =
    tenantProfile?.personalInformation?.firstName && tenantProfile?.personalInformation?.lastName
      ? `${tenantProfile?.personalInformation.firstName} ${tenantProfile?.personalInformation.lastName}`
      : '';
  const senderName: string = activeUserType === USER_TYPE.LANDLORD ? landlordName : tenantName;
  const organizationId = landlordProfile?.organizationId;

  const {
    control,
    handleSubmit,
    watch,
    formState: { isValid },
  } = useForm<{ email: string; role: string }>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    resolver: joiResolver(Joi.object(INVITATION_SCHEME)),
  });

  const submitForm = async (formData: any) => {
    setButtonSubmitMode(SUBMIT_BUTTON_MODE.SUBMITTING);
    console.log('formdata', formData);

    try {
      await firebaseFunctionsService.callFbFunction(FB_FUNCTION_URLS.user.sendUserInvitationMail, {
        invitedUsers: [formData],
        senderName,
        organizationId,
      });

      setButtonSubmitMode(SUBMIT_BUTTON_MODE.SUCCESS);
      if (onSuccess) {
        handleSnackBar('toast.invitedUser', 'success');
        onSuccess();
      }
      closeModal();
    } catch (e) {
      setButtonSubmitMode(SUBMIT_BUTTON_MODE.ERROR);
      console.error('Error on invite user modal', e);
    }
  };

  const MODAL_BUTTONS: IModalWrapperButtonProps = {
    primary: {
      form: 'userInviteForm',
      icon: getSubmitIcon(buttonSubmitMode),
      buttonText: t('buttonText'),
      spinIcon: buttonSubmitMode === SUBMIT_BUTTON_MODE.SUBMITTING,
      disabled:
        buttonSubmitMode === SUBMIT_BUTTON_MODE.ERROR || buttonSubmitMode === SUBMIT_BUTTON_MODE.SUBMITTING || !isValid,
    },
    third: {
      onClick: closeModal,
      buttonText: c('cancel'),
    },
  };

  const USER_ROLE_OPTION_LIST: ISelectOptionWithComingSoon[] = [
    {
      value: LANDLORD_ROLES.ORGANIZATION_ADMIN,
      label: t('organizationAdmin.label'),
      toolTip: t('organizationAdmin.toolTip'),
    },
    { value: LANDLORD_ROLES.ORGANIZATION_MEMBER, label: t('organizationMember.label') },
  ];

  return (
    <ModalWrapper icon={faUserPlus} title={t('title')} buttons={MODAL_BUTTONS}>
      <FormContext.Provider value={{ control }}>
        <form
          id={'userInviteForm'}
          autoComplete={'off'}
          onSubmit={handleSubmit(submitForm, (error) => console.error(error))}
        >
          <TextInput label={c('email.label')} name={`email`} type={'email'} />
          <Dropdown label={t('userRole')} name={'role'} optionList={USER_ROLE_OPTION_LIST} />
        </form>
      </FormContext.Provider>
    </ModalWrapper>
  );
};

export default CreateUserInvitationModal;
