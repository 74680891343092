import { IApartment } from '@wohnsinn/ws-ts-lib';

export const createLocationString = (apartment: IApartment): string => {
  if (apartment?.mainInformation?.address?.hasPublishedAddress) {
    const address = apartment.mainInformation.address;
    let location = '';

    if (address.street) {
      location += address.street;
    }
    if (address.houseNumber) {
      if (address.street) {
        location += ' ';
      }
      location += address.houseNumber;
    }

    if (address.postalCode) {
      if (address.houseNumber) {
        location += ', ';
      }
      location += address.postalCode;
    }

    if (address.city) {
      if (address.postalCode) {
        location += ' ';
      }
      location += address.city;
    }

    return location;
  } else {
    return 'Wird vom Vermieter bekanntgegeben';
  }
};
