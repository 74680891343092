import { useTranslation } from 'react-i18next';
import { ROUTES } from 'core/const/routes';
import { Grid } from '@mui/material';
import { HEADLINE_SIZE } from 'component/atoms/typographie/Headline';
import { USER_TYPE } from '@wohnsinn/ws-ts-lib';
import { useState } from 'react';
import PricingCard from 'component/molecules/Cards/PricingCard';
import { ICheckListItem } from 'component/atoms/CheckList';
import LandlordSection from 'component/organisms/landing-page-sections/LandlordSection';
import LandingPageLayout from '../../../component/layouts/LandingPageLayout';
import ContactSection from 'component/organisms/landing-page-sections/ContactSection';
import ToggleButton from 'component/atoms/ToggleButton';
import Headline from '../../../component/atoms/typographie/Headline';

const PriceView = () => {
  const { t: r } = useTranslation('routes');
  const { t: p } = useTranslation('common', { keyPrefix: 'view.general.PricesView' });
  const [selectedUserType, setSelectedUserType] = useState<USER_TYPE>(USER_TYPE.LANDLORD);

  const CHECK_LIST_BUSINESS: ICheckListItem[] = [{ text: p('cards.business.one') }];

  const CHECK_LIST_TENANT: ICheckListItem[] = [{ text: 'Zugriff auf exklusive Wohnungen' }];

  return (
    <LandingPageLayout pageTitle={r(ROUTES.landingPageRoutes.prices.title)}>
      <Grid
        container
        justifyContent={'center'}
        alignItems={'center'}
        className={'ws-full-width-bg ws-full-width-bg--white'}
      >
        <Grid item xs={12} md={8} paddingTop={{ xs: 4, md: 10 }} paddingBottom={{ xs: 4, md: 10 }}>
          <Headline size={HEADLINE_SIZE.HERO} align={'center'}>
            Faire & transparente Preise
          </Headline>
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent={'center'} paddingBottom={{ xs: 4, md: 10 }}>
            <Grid item xs={12} md={5}>
              <ToggleButton
                option1={{
                  text: 'Für Mieter',
                  onClick: () => setSelectedUserType(USER_TYPE.TENANT),
                  active: selectedUserType === USER_TYPE.TENANT,
                }}
                option2={{
                  text: 'Für Vermieter',
                  onClick: () => setSelectedUserType(USER_TYPE.LANDLORD),
                  active: selectedUserType === USER_TYPE.LANDLORD,
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        {selectedUserType === USER_TYPE.LANDLORD ? (
          <>
            <Grid item xs={12} md={5} paddingBottom={{ xs: 4, md: 10 }}>
              <PricingCard
                badge={'Kostenlos!'}
                userType={USER_TYPE.LANDLORD}
                subLine={p('cards.business.subLine')}
                price={p('cards.business.price')}
                title={p('cards.business.title')}
                checkListItems={CHECK_LIST_BUSINESS}
              />
            </Grid>
          </>
        ) : (
          <Grid item xs={12} md={5} paddingBottom={{ xs: 4, md: 10 }}>
            <PricingCard
              badge={'Monatlich kündbar!'}
              userType={USER_TYPE.TENANT}
              subLine={'pro Monat'}
              price={'4,90€'}
              title={'Für Mieter'}
              checkListItems={CHECK_LIST_TENANT}
            />
          </Grid>
        )}

        {selectedUserType === USER_TYPE.LANDLORD ? (
          <Grid
            item
            xs={12}
            className={'ws-full-width-bg ws-full-width-bg--dark'}
            paddingTop={{ xs: 4, md: 10 }}
            paddingBottom={{ xs: 4, md: 10 }}
          >
            <LandlordSection
              title={'Du bist eine Genossenschaft?'}
              subtitle={'Melde dich gerne bei uns. Wir machen dir ein individuelles Angebot.'}
              ctaText={'Kontaktiere uns'}
              ctaHref={'mailto:anfragen@wohnsinn.com'}
              ctaText2={'0221-95490428'}
              ctaLink2={'tel:022195490428'}
            />
          </Grid>
        ) : null}

        <Grid item xs={12} paddingTop={{ xs: 4, md: 10 }} paddingBottom={{ xs: 4, md: 10 }}>
          <ContactSection />
        </Grid>
      </Grid>
    </LandingPageLayout>
  );
};

export default PriceView;
