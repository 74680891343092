import { FC } from 'react';
import styles from './BouncingHeart.module.scss';
import { IImage } from '../../../core/const/images';
import { useTranslation } from 'react-i18next';

const BouncingHeart: FC<{ animate: boolean; img: IImage; size?: 'small' | 'medium' | 'large' }> = ({
  animate,
  img,
  size = 'small',
}) => {
  const { t: i } = useTranslation('images');
  return (
    <div className={styles.imgWrapper}>
      <img
        className={`${styles.img} ${animate ? styles.animate : ''} ${styles[size]}`}
        src={i(img.src)}
        alt={i(img.alt)}
      />
    </div>
  );
};

export default BouncingHeart;
