const validateLink = (link: string): string => {
  // Trim whitespace from the link before processing
  const trimmedLink = link?.trim();
  // Regular expression to check if the link starts with http:// or https://
  const pattern = /^(http:\/\/|https:\/\/)/;

  // Check if the link matches the pattern
  if (pattern.test(trimmedLink)) {
    // If it does, return the link as is
    return trimmedLink;
  } else {
    // If it doesn't, prepend https:// to the link and return it
    return `https://${trimmedLink}`;
  }
};
export default validateLink;
