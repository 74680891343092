import CTAButton, { BUTTON_STYLE } from 'component/atoms/Buttons/CTAButton';
import ModalContext from 'core/context/modal.context';
import TenantFilterParamsContext from 'core/context/tenant-filter-params.context';
import styles from './FilterList.module.scss';
import { APARTMENT_TYPE_OF_MARKETING, EQUIPMENT, ITenantFilterParams } from '@wohnsinn/ws-ts-lib';
import { ITag } from '../../atoms/Tag';
import { MODAL_IDENTIFIER } from 'core/enum/modals.enum';
import { TENANT_FILTER } from 'core/provider/TenantFilterParamsContextProvider';
import { TEXT_COLOR } from '../../atoms/typographie/Text';
import { createEquipmentList } from '../ApartmentEquipment';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { formatNumberGermanStyle } from 'core/helper/get-formatted-price';

const FilterList = () => {
  const { t } = useTranslation('common', { keyPrefix: 'apartment.equipment.equipmentList' });

  const { tenantFilterParams, deleteTenantFilterParam, updateTenantFilterParams } =
    useContext(TenantFilterParamsContext);
  const { openModal } = useContext(ModalContext);

  const hasFilterSet = (tenantFilterParams: ITenantFilterParams): boolean => {
    return !!(
      tenantFilterParams?.rooms ||
      tenantFilterParams?.arePetsAllowed ||
      tenantFilterParams?.location ||
      tenantFilterParams?.equipmentList?.length ||
      tenantFilterParams?.isSharedUsagePossible ||
      tenantFilterParams?.canBeSubsidized ||
      tenantFilterParams?.numberOfPeopleMovingIn ||
      tenantFilterParams?.rooms ||
      tenantFilterParams?.size ||
      tenantFilterParams?.warmRent ||
      tenantFilterParams?.typeOfMarketing ||
      tenantFilterParams?.purchasePrice
    );
  };

  const removeFilter = async (filter: TENANT_FILTER, equipment?: ITag) => {
    if (equipment) {
      const newEquipmentList = tenantFilterParams.equipmentList.filter((e: EQUIPMENT) => e !== equipment.value);
      if (newEquipmentList.length) {
        updateTenantFilterParams({ equipmentList: newEquipmentList });
      } else {
        deleteTenantFilterParam(filter);
      }
    } else {
      deleteTenantFilterParam(filter);
    }
  };

  return (
    <div className={styles.wrapper}>
      {hasFilterSet(tenantFilterParams) ? (
        <CTAButton
          size={'tiny'}
          icon={faTimes}
          buttonStyle={BUTTON_STYLE.TERTIARY}
          color={TEXT_COLOR.TEXT_COLOR_DARK}
          buttonText={'Alle Filter entfernen'}
          onClick={() => openModal({ id: MODAL_IDENTIFIER.FILTER_RESET })}
        />
      ) : null}

      {tenantFilterParams?.location ? (
        <CTAButton
          size={'tiny'}
          icon={faTimes}
          buttonStyle={BUTTON_STYLE.TERTIARY}
          color={TEXT_COLOR.TEXT_COLOR_DARK}
          buttonText={`${tenantFilterParams.location.place_name}, ${tenantFilterParams.location.radius}km Umkreis`}
          onClick={() => removeFilter(TENANT_FILTER.CITY)}
        />
      ) : null}

      {tenantFilterParams?.purchasePrice ? (
        <CTAButton
          size={'tiny'}
          icon={faTimes}
          buttonStyle={BUTTON_STYLE.TERTIARY}
          color={TEXT_COLOR.TEXT_COLOR_DARK}
          buttonText={`bis ${formatNumberGermanStyle(tenantFilterParams.purchasePrice)} €`}
          onClick={() => removeFilter(TENANT_FILTER.PURCHASE_PRICE)}
        />
      ) : null}

      {tenantFilterParams?.warmRent ? (
        <CTAButton
          size={'tiny'}
          icon={faTimes}
          buttonStyle={BUTTON_STYLE.TERTIARY}
          color={TEXT_COLOR.TEXT_COLOR_DARK}
          buttonText={`bis ${tenantFilterParams.warmRent} €`}
          onClick={() => removeFilter(TENANT_FILTER.WARM_RENT)}
        />
      ) : null}

      {tenantFilterParams?.numberOfPeopleMovingIn ? (
        <CTAButton
          size={'tiny'}
          icon={faTimes}
          color={TEXT_COLOR.TEXT_COLOR_DARK}
          buttonStyle={BUTTON_STYLE.TERTIARY}
          buttonText={`Ab ${tenantFilterParams.numberOfPeopleMovingIn} Personen`}
          onClick={() => removeFilter(TENANT_FILTER.PERSONS)}
        />
      ) : null}

      {tenantFilterParams?.rooms ? (
        <CTAButton
          size={'tiny'}
          icon={faTimes}
          buttonStyle={BUTTON_STYLE.TERTIARY}
          color={TEXT_COLOR.TEXT_COLOR_DARK}
          buttonText={`Ab ${tenantFilterParams.rooms} Zimmer`}
          onClick={() => removeFilter(TENANT_FILTER.ROOMS)}
        />
      ) : null}

      {tenantFilterParams?.size ? (
        <CTAButton
          size={'tiny'}
          icon={faTimes}
          buttonStyle={BUTTON_STYLE.TERTIARY}
          color={TEXT_COLOR.TEXT_COLOR_DARK}
          buttonText={`Ab ${tenantFilterParams.size} m2`}
          onClick={() => removeFilter(TENANT_FILTER.SIZE)}
        />
      ) : null}

      {tenantFilterParams?.equipmentList && tenantFilterParams.equipmentList.length
        ? createEquipmentList(tenantFilterParams.equipmentList, t).map((equipment) => (
            <CTAButton
              key={equipment.key}
              size={'tiny'}
              icon={faTimes}
              buttonStyle={BUTTON_STYLE.TERTIARY}
              color={TEXT_COLOR.TEXT_COLOR_DARK}
              buttonText={`${equipment.label}`}
              onClick={() => removeFilter(TENANT_FILTER.EQUIPMENT, equipment)}
            />
          ))
        : null}

      {tenantFilterParams?.isSharedUsagePossible ? (
        <CTAButton
          size={'tiny'}
          icon={faTimes}
          buttonStyle={BUTTON_STYLE.TERTIARY}
          color={TEXT_COLOR.TEXT_COLOR_DARK}
          buttonText={tenantFilterParams?.isSharedUsagePossible ? 'WG-Geeignet' : 'keine WG'}
          onClick={() => removeFilter(TENANT_FILTER.SHARED_USAGE)}
        />
      ) : null}

      {tenantFilterParams?.canBeSubsidized ? (
        <CTAButton
          size={'tiny'}
          icon={faTimes}
          buttonStyle={BUTTON_STYLE.TERTIARY}
          color={TEXT_COLOR.TEXT_COLOR_DARK}
          buttonText={tenantFilterParams?.canBeSubsidized ? 'Nur mit WBS' : 'kein WBS'}
          onClick={() => removeFilter(TENANT_FILTER.CAN_BE_SUBSIDIZED)}
        />
      ) : null}

      {tenantFilterParams?.arePetsAllowed ? (
        <CTAButton
          size={'tiny'}
          icon={faTimes}
          buttonStyle={BUTTON_STYLE.TERTIARY}
          color={TEXT_COLOR.TEXT_COLOR_DARK}
          buttonText={tenantFilterParams.arePetsAllowed ? 'Haustiere erlaubt' : 'Keine Haustiere'}
          onClick={() => removeFilter(TENANT_FILTER.PETS)}
        />
      ) : null}

      {tenantFilterParams?.typeOfMarketing && process.env.REACT_APP_SHOW_SALE === 'true' ? (
        <CTAButton
          size={'tiny'}
          icon={faTimes}
          buttonStyle={BUTTON_STYLE.TERTIARY}
          color={TEXT_COLOR.TEXT_COLOR_DARK}
          buttonText={
            tenantFilterParams.typeOfMarketing === APARTMENT_TYPE_OF_MARKETING.RENT
              ? 'Mieten'
              : tenantFilterParams.typeOfMarketing === APARTMENT_TYPE_OF_MARKETING.SALE
              ? 'Kaufen'
              : 'Sonstige'
          }
          onClick={() => removeFilter(TENANT_FILTER.TYPE_OF_MARKETING)}
        />
      ) : null}
    </div>
  );
};

export default FilterList;
