import { FC } from 'react';
import styles from './SearchInput.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-solid-svg-icons';

export interface ISearchInputProps {
  onChange: (searchKey: string) => void;
  placeholder: string;
  name: string;
}

const SearchInput: FC<ISearchInputProps> = ({ onChange, placeholder, name }) => {
  return (
    <div className={styles.inputWrapper}>
      <FontAwesomeIcon icon={faSearch} />
      <input
        className={styles.input}
        type="text"
        name={name}
        placeholder={placeholder}
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  );
};

export default SearchInput;
