import { ChangeEvent, FC } from 'react';
import styles from 'component/atoms/formElement/InputNumber/InputNumber.module.scss';
import { InputLabel } from '@mui/material';

const SelectNumber: FC<{
  label?: string;
  disabled?: boolean;
  placeHolder?: string;
  value: number;
  onChange: any;
  step: number;
  max?: number;
  min?: number;
  error?: any;
  required?: boolean;
}> = ({ label, placeHolder, disabled, value, onChange, step = 0.5, max, error, min = 0, required = false }) => {
  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  const handleButtonClick = (value: any, type: string) => {
    const val = value ? parseFloat(value) : 0;
    if (type === 'increment') {
      console.log(val + step);
      onChange(val + step);
    }

    if (type === 'decrement' && val - step >= 0) {
      console.log(val + step);
      onChange(val - step);
    }
  };

  return (
    <div>
      {label && <InputLabel required={required}>{label}</InputLabel>}
      <div className={'floating-wrapper floating-wrapper-input'}>
        <div className={'floating-field'}>
          <div className={styles.wrapper}>
            <button
              onClick={() => handleButtonClick(value, 'decrement')}
              type={'button'}
              className={`${styles.button} ${styles.left}`}
            >
              -
            </button>
            <input
              disabled={disabled}
              placeholder={placeHolder ?? label}
              className={styles.input}
              maxLength={4}
              max={max}
              required={required}
              min={min}
              autoComplete={'false'}
              pattern={'[0-9]*'}
              step={step}
              type="number"
              onChange={(e) => handleOnChange(e)}
              value={value}
            />
            <button
              onClick={() => handleButtonClick(value, 'increment')}
              type={'button'}
              className={`${styles.button} ${styles.right}`}
            >
              +
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectNumber;
