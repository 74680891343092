import { ROUTES } from 'core/const/routes';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { HEADLINE_SIZE } from 'component/atoms/typographie/Headline';
import BlogPreviewGrid from 'component/molecules/BlogPreviewGrid';
import CTAButton, { BUTTON_STYLE } from 'component/atoms/Buttons/CTAButton';
import LandingPageLayout from '../../../component/layouts/LandingPageLayout';
import Headline from '../../../component/atoms/typographie/Headline';
import SplitLine from '../../../component/atoms/SplitLine';
import Text, { TEXT_TYPE } from '../../../component/atoms/typographie/Text';

const PressView = () => {
  const { t: r } = useTranslation('routes');
  return (
    <LandingPageLayout pageTitle={r(ROUTES.landingPageRoutes.contact.title)}>
      <Grid
        container
        paddingTop={{ xs: 4, md: 10 }}
        paddingBottom={{ xs: 8, md: 20 }}
        className={'ws-full-width-bg ws-full-width-bg--white'}
      >
        <Grid container justifyContent={'center'}>
          <Grid item xs={12} md={8}>
            <Headline size={HEADLINE_SIZE.HERO} align={'center'}>
              Presse
            </Headline>
          </Grid>
          <Grid item xs={12} paddingTop={{ xs: 1, md: 2 }}>
            <Text type={TEXT_TYPE.TEXT_TYPE_LANDING} align={'center'}>
              Hier findest du die neusten Wohnsinn News
            </Text>
          </Grid>
        </Grid>
        <Grid item xs={12} paddingTop={{ xs: 2, md: 4 }}>
          <Grid container spacing={2} justifyContent={'center'}>
            <Grid item xs={12} sm={6} md={4}>
              <CTAButton
                buttonText={'Media Kit downloaden'}
                buttonStyle={BUTTON_STYLE.SECONDARY}
                target={'_blank'}
                expand={'full'}
                rounded={false}
                href={'https://wohnsinn.notion.site/Willkommen-zur-Pressemappe-a3a34773cd604be79c3212a852a416e9?pvs=4'}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <CTAButton
                rounded={false}
                expand={'block'}
                buttonText={'Presseanfrage stellen'}
                href={'mailto:hallo@wohnsinn.com'}
              />
            </Grid>
            <Grid item xs={12} paddingTop={{ xs: 4, md: 10 }}>
              <SplitLine />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} paddingTop={{ xs: 4, md: 10 }} paddingBottom={{ xs: 8, md: 20 }}>
          <BlogPreviewGrid
            blogs={[
              {
                tag: 'Deutsche Startups',
                headline: '5 frische Kölner Startups',
                text: 'In Köln tummeln sich zahlreiche spannende Startups. Hier wieder einige - insbesondere sehr jung...',
                link: 'https://www.deutsche-startups.de/2022/12/21/neue-koelner-startups-aiconver/',
              },
              {
                tag: 'Apti Award',
                headline: 'Apti Award 2022',
                text: 'Am Donnerstagabend wurden von der Austrian PropTech-Initiative apti die besten Immobilien Start-ups gekürt.',
                link: 'https://www.diepresse.com/6196631/apti-award-2022-die-gewinner-stehen-fest',
              },
              {
                tag: 'Immobilienzeitung',
                headline: 'PropTech Germany',
                text: 'Gewinner Kategorie Vermarktung, Vermietung und Verkauf: Wohnsinn. Wohnsinn.com ist ein...',
                link: 'https://www.iz.de/digitales/news/proptech-awards-kategorie-vermarktung-vermietung-und-verkauf-wohnsinn-2000008945',
              },
            ]}
          />
        </Grid>
      </Grid>
    </LandingPageLayout>
  );
};

export default PressView;
