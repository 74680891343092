import { useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfoBox from '../../atoms/InfoBox';
import UserContext from '../../../core/context/user.context';
import Card, { CARD_COLORS } from '../../atoms/Card';
import { PopupButton } from '@typeform/embed-react';
import { PopupButton as CalendlyButton, PopupModal } from 'react-calendly';
import { Grid } from '@mui/material';

const LandlordServices = () => {
  const { landlordProfile } = useContext(UserContext);
  const { t: v } = useTranslation('common', { keyPrefix: 'view.ServicesView' });
  const calendlyButtonRef = useRef(null);
  const [isCalendlyOpen, setIsCalendlyOpen] = useState(false);

  return (
    <>
      <PopupModal
        url={process.env.REACT_APP_CALENDLY_3D_TOUR_BOOKING_LINK}
        onModalClose={() => setIsCalendlyOpen(false)}
        open={isCalendlyOpen}
        rootElement={document.getElementById('root')}
      />
      {landlordProfile?.isCommercialLandlord &&
        !landlordProfile?.isOrganizationMembershipConfirmed &&
        landlordProfile?.organizationId && (
          <InfoBox text={'Dein Account ist noch nicht dem Unternehmen hinzugefügt.'} />
        )}
      <CalendlyButton
        url={process.env.calendly3DTourBookingLink}
        rootElement={document.getElementById('root')}
        text=""
        className={'hidden'}
        ref={calendlyButtonRef}
      />
      <Grid item xs={12} md={6}>
        <button type={'button'} onClick={() => setIsCalendlyOpen(true)} style={{ height: '100%' }}>
          <Card
            headline={v('iconBox.3dTour.title')}
            text={v('iconBox.3dTour.text')}
            iconSrc={v('iconBox.3dTour.iconSrc')}
            iconAltText={v('iconBox.3dTour.altText')}
          />
        </button>
      </Grid>
      <Grid item xs={12} md={6}>
        <PopupButton id={'CysxZlWc'} style={{ height: '100%' }}>
          <Card
            headline={v('iconBox.skz.title')}
            text={v('iconBox.skz.text')}
            iconSrc={v('iconBox.skz.iconSrc')}
            iconAltText={v('iconBox.skz.altText')}
            color={CARD_COLORS.RED}
          />
        </PopupButton>
      </Grid>
    </>
  );
};

export default LandlordServices;
