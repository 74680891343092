import { FC, SyntheticEvent } from 'react';
import Text, { TEXT_TYPE, TEXT_VARIANT } from '../../atoms/typographie/Text';
import { MATCHING_MODE } from '@wohnsinn/ws-ts-lib';
import styles from '../../../view/landlord/apartment/ApartmentApplicationsView/ApartmentApplicationsView.module.scss';
import { useTranslation } from 'react-i18next';
import { Tab, Tabs } from '@mui/material';

const RatingTabs: FC<{
  selectedRating: MATCHING_MODE;
  setSelectedRating: any;
  likeApplicationsLength: number;
  maybeApplicationsLength: number;
  nopeApplicationsLength: number;
  unsortedApplicationsLength: number;
}> = ({
  selectedRating,
  setSelectedRating,
  likeApplicationsLength,
  maybeApplicationsLength,
  nopeApplicationsLength,
  unsortedApplicationsLength,
}) => {
  const { t } = useTranslation('common');
  const { t: a } = useTranslation('common', { keyPrefix: 'view.ApplicationOverviewView' });

  const handleTabChange = (event: SyntheticEvent, newValue: MATCHING_MODE | 'ALL') => {
    switch (newValue) {
      case MATCHING_MODE.MAYBE:
        setSelectedRating(MATCHING_MODE.MAYBE);
        break;
      case MATCHING_MODE.LIKE:
        setSelectedRating(MATCHING_MODE.LIKE);
        break;
      case MATCHING_MODE.NOPE:
        setSelectedRating(MATCHING_MODE.NOPE);
        break;
      case MATCHING_MODE.NONE:
        setSelectedRating(MATCHING_MODE.NONE);
        break;
      default:
        setSelectedRating(MATCHING_MODE.NONE);
    }
  };

  return (
    <Tabs centered value={selectedRating} onChange={handleTabChange} className={styles.wrapper}>
      <Tab
        value={MATCHING_MODE.NONE}
        label={
          <div>
            <Text type={TEXT_TYPE.TEXT_TYPE_CAPTION} variant={TEXT_VARIANT.TEXT_VARIANT_SMALL}>{`${a(
              'new'
            )} (${unsortedApplicationsLength})`}</Text>
          </div>
        }
      />
      <Tab
        value={MATCHING_MODE.LIKE}
        label={
          <div>
            <Text
              type={TEXT_TYPE.TEXT_TYPE_CAPTION}
              className={styles.segmentLabel}
              variant={TEXT_VARIANT.TEXT_VARIANT_SMALL}
            >
              {a('favorite')}{' '}
            </Text>
            <Text tag={'span'} type={TEXT_TYPE.TEXT_TYPE_CAPTION} variant={TEXT_VARIANT.TEXT_VARIANT_SMALL}>
              ({likeApplicationsLength})
            </Text>
            <img className={styles.segmentIcon} src={t('icons.heart_like')} alt={'Likes'} />
          </div>
        }
      />
      <Tab
        value={MATCHING_MODE.MAYBE}
        label={
          <div>
            <Text
              tag={'span'}
              type={TEXT_TYPE.TEXT_TYPE_CAPTION}
              className={styles.segmentLabel}
              variant={TEXT_VARIANT.TEXT_VARIANT_SMALL}
            >
              {a('interesting')}{' '}
            </Text>
            <Text tag={'span'} type={TEXT_TYPE.TEXT_TYPE_CAPTION} variant={TEXT_VARIANT.TEXT_VARIANT_SMALL}>
              ({maybeApplicationsLength})
            </Text>
            <img className={styles.segmentIcon} src={t('icons.heart_maybe')} alt={'Likes'} />
          </div>
        }
      />
      <Tab
        value={MATCHING_MODE.NOPE}
        label={
          <div>
            <Text
              type={TEXT_TYPE.TEXT_TYPE_CAPTION}
              className={styles.segmentLabel}
              variant={TEXT_VARIANT.TEXT_VARIANT_SMALL}
            >
              {a('uninteresting')}{' '}
            </Text>
            <Text tag={'span'} type={TEXT_TYPE.TEXT_TYPE_CAPTION} variant={TEXT_VARIANT.TEXT_VARIANT_SMALL}>
              ({nopeApplicationsLength})
            </Text>
            <img className={styles.segmentIcon} src={t('icons.heart_nope')} alt={'Likes'} />
          </div>
        }
      />
    </Tabs>
  );
};

export default RatingTabs;
