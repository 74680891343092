import { APARTMENT_TYPE_OF_MARKETING, EQUIPMENT, IApartment, MATCHING_MODE } from '@wohnsinn/ws-ts-lib';
import { FC, useContext, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import ApartmentDrawer from 'view/tenant/matching/ApartmentDrawer';
import ApartmentCardMedia from '../ApartmentCardMedia';
import ApartmentBadge, { checkIfApartmentIsNew } from '../../../atoms/ApartmentBadge';
import ApartmentCardApartmentInformation from '../ApartmentCardApartmentInformation';
import ApartmentCardWrapper from '../ApartmentCardWrapper';
import { IApartmentWithRating } from 'view/static/organization/OrganizationPortfolioView';
import ApartmentRatingButtons from 'component/atoms/ApartmentRatingButtons';
import { MODAL_IDENTIFIER } from 'core/enum/modals.enum';
import { getTenantApplicationState, TENANT_APPLICATION_STATE } from 'core/helper/get-tenant-application-state';
import LOCAL_STORAGE_KEYS from 'core/enum/local-storage.enum';
import { ROUTES } from 'core/const/routes';
import { getAnimation, TAnimationDirection } from 'view/static/ShareApartmentView';
import UserContext from 'core/context/user.context';
import ModalContext from 'core/context/modal.context';
import { wohnsinnServices } from 'App';
import { useTranslation } from 'react-i18next';
import SnackBarContext from 'core/context/snackbar.context';
import { motion } from 'framer-motion';
import styles from 'view/static/ShareApartmentView/ShareApartmentView.module.scss';
import { useQueryClient } from '@tanstack/react-query';
import BouncingHeart from 'component/atoms/BouncingHeart';
import { IMAGES } from 'core/const/images';
import useDesiredTenant from 'core/hook/desired-tenant.hook';

export interface IApartmentCardProps {
  apartment: IApartmentWithRating | IApartment;
  handleApartmentDrawerClose: any;
}

const PreviewApartmentCard: FC<IApartmentCardProps> = ({ apartment, handleApartmentDrawerClose }) => {
  const [showApartment, setShowApartment] = useState<boolean>(false);
  const { address } = apartment.mainInformation;
  const [animationDirection, setAnimationDirection] = useState<TAnimationDirection>('initial');
  const { user, tenantProfile } = useContext(UserContext);
  const queryClient = useQueryClient();
  const [showBouncingHeart, setShowBouncingHeart] = useState<{ showHeart: boolean; rating: MATCHING_MODE }>({
    showHeart: false,
    rating: MATCHING_MODE.NONE,
  });

  const { openModal } = useContext(ModalContext);
  const { applicationService } = wohnsinnServices;
  const { t: r } = useTranslation('routes');
  const { handleSnackBar } = useContext(SnackBarContext);
  const { desiredTenantErrors } = useDesiredTenant(apartment, tenantProfile);

  const { ref } = useInView({
    trackVisibility: true,
    delay: 100,
    triggerOnce: true,
    rootMargin: '100px',
    threshold: 0,
  });

  const submitRatingHandler = async (rating: MATCHING_MODE) => {
    if (user) {
      if (rating === MATCHING_MODE.MAYBE || rating === MATCHING_MODE.NOPE) {
        setShowBouncingHeart({ showHeart: true, rating });
      }

      setTimeout(async () => {
        await createApplication(rating);
        setShowBouncingHeart({ showHeart: false, rating: MATCHING_MODE.NONE });
        setAnimationDirection(rating === MATCHING_MODE.NOPE ? 'left' : 'right');
        await queryClient.invalidateQueries({ queryKey: ['matches'] });
      }, 700);
    } else {
      setAnimationDirection(rating === MATCHING_MODE.NOPE ? 'left' : 'right');
      // IF USER TRIES TO APPLY - FORCE REGISTRATION / LOGIN
      setTimeout(() => {
        setAnimationDirection('initial');
        openModal({
          id: MODAL_IDENTIFIER.SIGN_UP_USER,
          data: {
            title: 'Erstelle ein Konto, um loszulegen',
            isProbablyTenant: true,
          },
        });
      }, 300);
    }
  };

  const createApplication = async (rating: MATCHING_MODE) => {
    const tenantApplicationState = getTenantApplicationState(tenantProfile);

    localStorage.setItem(
      LOCAL_STORAGE_KEYS.REDIRECT_URL,
      `${r(ROUTES.staticRoutes.shareApartment.path).replace(':apartmentId', apartment.id)}`
    );

    if (rating === MATCHING_MODE.LIKE) {
      if (!user.isEmailVerified) {
        setTimeout(() => {
          setAnimationDirection('initial');
          openModal({ id: MODAL_IDENTIFIER.CONFIRM_EMAIL });
        }, 300);
        return;
      }

      if (tenantApplicationState === TENANT_APPLICATION_STATE.NOT_READY_FOR_APPLICATION) {
        setAnimationDirection('right');
        setTimeout(() => {
          setAnimationDirection('initial');
          openModal({ id: MODAL_IDENTIFIER.COMPLETE_APPLICATION_FOLDER });
        }, 300);
        return;
      }
    }

    if (desiredTenantErrors?.length) {
      setTimeout(() => {
        setAnimationDirection('initial');
        openModal({ id: MODAL_IDENTIFIER.DESIRED_TENANT, data: desiredTenantErrors });
      }, 300);
      return;
    }

    if (tenantApplicationState === TENANT_APPLICATION_STATE.MISSING_DOCS) {
      setAnimationDirection('right');
      setTimeout(() => {
        setAnimationDirection('initial');
        openModal({ id: MODAL_IDENTIFIER.COMPLETE_DOCUMENT_FOLDER });
      }, 300);
      return;
    }

    try {
      await applicationService.handleRating(apartment, tenantProfile, rating, true);

      if (rating === MATCHING_MODE.LIKE) {
        await applicationService.createApplication(apartment, tenantProfile, rating);
        handleSnackBar('toast.success.applied', 'success');
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <motion.div
      key={apartment?.id}
      style={{
        zIndex: 10,
      }}
      animate={getAnimation(true, animationDirection)}
    >
      <div ref={ref} id={`apartment-card-${apartment.id}`}>
        <ApartmentCardWrapper>
          <ApartmentDrawer
            isBookmark={false}
            show={showApartment}
            close={setShowApartment}
            onClose={handleApartmentDrawerClose}
            apartment={apartment}
          />

          <ApartmentBadge
            apartmentIsNew={checkIfApartmentIsNew(apartment)}
            apartmentHasFixedTerm={apartment?.mainInformation?.hasFixedTerm}
            apartmentIsFurnished={apartment?.equipment?.equipmentList?.includes(EQUIPMENT.IS_FURNISHED)}
          />

          <ApartmentCardMedia
            showBouncingHeart={showBouncingHeart?.showHeart}
            reelsView
            onGalleryClick={() => setShowApartment(true)}
            apartmentId={apartment.id}
            media={apartment.media}
            isIntersecting={true}
          />

          <button type={'button'} onClick={() => setShowApartment(true)}>
            <ApartmentCardApartmentInformation
              address={address}
              cost={apartment?.cost}
              typeOfMarketing={apartment?.mainInformation?.typeOfMarketing}
              rooms={apartment?.areas?.numberOfRooms}
              size={apartment?.areas?.totalArea}
            />
          </button>
          <div className={styles.ratingButtons}>
            <ApartmentRatingButtons
              apartment={apartment}
              isSalesObject={apartment.mainInformation.typeOfMarketing === APARTMENT_TYPE_OF_MARKETING.SALE}
              onButtonClick={(matchingMode) => submitRatingHandler(matchingMode)}
            />
          </div>
          {showBouncingHeart?.showHeart ? (
            <div className={styles.bouncingHeartsWrapper}>
              <BouncingHeart
                size={'medium'}
                img={showBouncingHeart?.rating === MATCHING_MODE.MAYBE ? IMAGES.icons.heart : IMAGES.icons.brokenHeart}
                animate={true}
              />
            </div>
          ) : null}
        </ApartmentCardWrapper>
      </div>
    </motion.div>
  );
};

export default PreviewApartmentCard;
