import ModalWrapper from 'component/modals/ModalWrapper';
import { Grid } from '@mui/material';
import InputText from 'component/atoms/formElement/InputText';
import { useTranslation } from 'react-i18next';
import { FormContext } from 'core/context/form.context';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { EMAIL_SCHEMA, PHONE_SCHEMA } from '@wohnsinn/ws-ts-lib';
import CTAButton, { getSubmitIcon } from 'component/atoms/Buttons/CTAButton';
import TextEditor, { TEXT_EDITOR_SIZE } from 'component/atoms/formElement/TextEditor';
import Spacer, { SPACER_SIZE } from 'component/atoms/Spacer';
import { wohnsinnServices } from 'App';
import FB_FUNCTION_URLS from 'core/const/fb-function-names';
import { useContext, useState } from 'react';
import SnackBarContext from 'core/context/snackbar.context';
import ModalContext from 'core/context/modal.context';
import { SUBMIT_BUTTON_MODE } from 'core/enum/submit-button-mode.enum';

export const TENANT_PERSONAL_INFORMATION_SCHEMA = {
  email: EMAIL_SCHEMA,
  firstName: Joi.string().required().min(3),
  lastName: Joi.string().required().min(3),
  phoneNumber: PHONE_SCHEMA,
  message: Joi.string().required().min(10),
};

interface ITenantContactInformation {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  message: string;
}

const RequestSaleModal = () => {
  const [submitButtonMode, setSubmitButtonMode] = useState<SUBMIT_BUTTON_MODE>(SUBMIT_BUTTON_MODE.NONE);
  const { handleSnackBar } = useContext(SnackBarContext);
  const { closeModal, modalData } = useContext(ModalContext);

  const { firebaseFunctionsService } = wohnsinnServices;
  const { t } = useTranslation('common');
  const { t: p } = useTranslation('common', { keyPrefix: 'view.AccountView.TenantProfileView' });
  const { control, watch, setValue, handleSubmit } = useForm<ITenantContactInformation>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    resolver: joiResolver(Joi.object(TENANT_PERSONAL_INFORMATION_SCHEMA)),
  });

  const onSuccess = async () => {
    setSubmitButtonMode(SUBMIT_BUTTON_MODE.SUBMITTING);
    try {
      await firebaseFunctionsService.callFbFunction(FB_FUNCTION_URLS.messaging.requestSale, {
        body: { contact: watch(), apartment: modalData?.apartment },
      });

      setSubmitButtonMode(SUBMIT_BUTTON_MODE.SUCCESS);

      handleSnackBar('toast.messageSend', 'success');
      closeModal();
    } catch (e) {
      setSubmitButtonMode(SUBMIT_BUTTON_MODE.ERROR);

      handleSnackBar('toast.error.unknown', 'error');
      console.error('Error on onSuccess in RequestSalesModal', e);
    }
  };

  return (
    <ModalWrapper title={'Kontaktanfrage'}>
      <FormContext.Provider value={{ control, setValue, watch }}>
        <form onSubmit={handleSubmit(onSuccess, (e) => console.error('Error on RequestSalesModal', e))}>
          <Grid item xs={12}>
            {p('personalInformation')}
            <InputText label={t('firstName.label')} name={'firstName'} required />
            <InputText label={t('lastName.label')} name={'lastName'} required />
            <InputText type={'tel'} label={t('phoneNumber.label')} name={'phoneNumber'} required />
            <InputText label={t('email.label')} name={'email'} required />
            <TextEditor
              label={t('message.label')}
              hideToolbar
              size={TEXT_EDITOR_SIZE.MEDIUM}
              name={'message'}
              placeholder={t('message.placeholder')}
              required
            />
          </Grid>
          <Spacer size={SPACER_SIZE.SM} />
          <CTAButton
            icon={getSubmitIcon(submitButtonMode)}
            spinIcon={submitButtonMode === SUBMIT_BUTTON_MODE.SUBMITTING}
            expand={'block'}
            buttonText={'Abschicken'}
            rounded={false}
            type={'submit'}
          />
        </form>
      </FormContext.Provider>
    </ModalWrapper>
  );
};

export default RequestSaleModal;
