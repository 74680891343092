import { FC } from 'react';
import useApartment from '../../../../core/hook/apartment.hook';
import ApartmentInformation from '../../../../component/organisms/ApartmentInformation';
import MapboxMap from '../../../../component/molecules/Map';
import { ROUTES } from 'core/const/routes';
import { useTranslation } from 'react-i18next';
import styles from './ApartmentPreviewView.module.scss';
import useWindowSize from '../../../../core/hook/windowsize.hook';
import ApartmentStackPageLayout from '../../../../component/layouts/ApartmentStackPageLayout';

const ApartmentPreviewView: FC = () => {
  const { apartment, isLoading } = useApartment();
  const { t: r } = useTranslation('routes');
  const { isGreaterMd } = useWindowSize();

  return (
    <ApartmentStackPageLayout pageTitle={r(ROUTES.landlordRoutes.apartment.preview.title)}>
      <div className={styles.wrapper}>
        <div className={styles.apartmentInformation}>
          {apartment && !isLoading && <ApartmentInformation apartment={apartment} />}
        </div>
        <div className={styles.map}>
          {!isLoading && !!apartment && isGreaterMd && <MapboxMap isInteractive={true} apartments={[apartment]} />}
        </div>
      </div>
    </ApartmentStackPageLayout>
  );
};

export default ApartmentPreviewView;
