import i18next, { InitOptions } from 'i18next';
import common_de from './common.de.json';
import images_de from './images.de.json';
import routes_de from './routes.de.json';
import translations_de from './translations.de.json';
import create_user_invitation_de from 'component/modals/CreateUserInvitationModal/createUserInvitationModal.de.json';

export const I18N_NEXT_CONFIG: InitOptions = {
  interpolation: { escapeValue: false },
  lng: 'de',
  debug: false,
  defaultNS: 'translations',
  resources: {
    de: {
      translations: translations_de,
      common: common_de,
      images: images_de,
      routes: routes_de,
      createUserInvitation: create_user_invitation_de,
    },
  },
};

i18next.init(I18N_NEXT_CONFIG);

export default i18next;
