import isIsoDate from './is-iso-date';
import { TFunction } from 'i18next';
import { format } from 'date-fns';
import { Timestamp } from 'firebase/firestore';

export default (
  date: string | Date | Timestamp | null | undefined,
  translate: TFunction,
  dateFormat = 'dateFormat'
): string => {
  if (date?.hasOwnProperty('_seconds') && date?.hasOwnProperty('_nanoseconds')) {
    /*@ts-ignore*/
    const newDada = new Timestamp(date['_seconds'], date['_nanoseconds']);
    return `${format(newDada.toDate(), translate(dateFormat))}`;
  }

  switch (typeof date) {
    case 'string':
      if (isIsoDate(date)) {
        return `${format(new Date(date), translate(dateFormat))}`;
      } else {
        return `${format(new Date(), translate(dateFormat))}`;
      }
    case 'object':
      if (date instanceof Timestamp) {
        date = date.toDate();
        return `${format(date, translate(dateFormat))}`;
      }
      if (date instanceof Date) {
        return `${format(date, translate(dateFormat))}`;
      }
      return `${format(new Date(), translate(dateFormat))}`;
    default:
      return `${format(new Date(), translate(dateFormat))}`;
  }
};
