import Map, { Layer, MapRef, Source } from 'react-map-gl';
import { FC, useEffect, useRef, useState } from 'react';
import * as turf from '@turf/turf';
import { IViewPort, TCenter } from '@wohnsinn/ws-ts-lib';

export interface IAddressFinderMapProps {
  height?: string;
  radius?: number;
  center?: TCenter;
}

const MAPBOX_TOKEN = process.env.REACT_APP_MAP_BOX_TOKEN;
const INITIAL_ZOOM = 8;

const AddressFinderMap: FC<IAddressFinderMapProps> = ({ height = 'calc(100vh - 80px)', center, radius }) => {
  const mapRef = useRef<MapRef>(null);
  // Disable dragging of the map
  mapRef?.current?.dragPan?.disable();
  const [viewPort, setViewPort] = useState<IViewPort>({
    longitude: 0,
    latitude: 0,
    zoom: INITIAL_ZOOM,
    pitch: 1,
  });

  useEffect(() => {
    if (center) {
      setViewPort({
        longitude: center[0],
        latitude: center[1],
        zoom: INITIAL_ZOOM,
        pitch: 1,
      });
    } else {
      setViewPort({
        longitude: 6.9603,
        latitude: 50.9375,
        zoom: INITIAL_ZOOM,
        pitch: 1,
      });
    }
  }, [center]);

  const setZoomOnRadiusChange = (): number => {
    if (radius >= 35) return 7;
    if (radius >= 25) return 7.7;
    if (radius >= 17) return 8;
    if (radius >= 15) return 8.5;
    if (radius >= 10) return 9;
    if (radius >= 7) return 9;
    if (radius >= 4) return 10;
    if (radius >= 3) return 10;
    if (radius >= 2) return 11;
    return 12;
  };

  const renderRadiusSelection = () => {
    const turfCircle = turf.circle(center, radius);

    const layerStyle = {
      paint: {
        'fill-color': '#18CCDE',
        'fill-opacity': 0.4,
        'fill-outline-color': '#FFFFFF',
      },
    };

    return (
      <Source id={'location-radius'} type={'geojson'} data={turfCircle}>
        <Layer id={'location-layer'} type={'fill'} source={'location-radius'} {...layerStyle} />
      </Source>
    );
  };

  useEffect(() => {
    if (radius && center) {
      if (mapRef.current) {
        mapRef.current?.flyTo({ center: [center[0], center[1]], duration: 300, zoom: setZoomOnRadiusChange() });
      }
    }
  }, [radius, center]);

  return (
    <Map
      onLoad={() => {
        resizeBy(viewPort.latitude, viewPort.longitude);
        // @ts-ignore
        mapRef?.current?.setLanguage('de');
      }}
      interactive={false}
      ref={mapRef}
      attributionControl={false}
      mapboxAccessToken={MAPBOX_TOKEN}
      maxZoom={20}
      {...viewPort}
      onMove={(evt: any) => {
        setViewPort(evt.viewState);
      }}
      style={{ width: '100%', height, borderRadius: '10px' }}
      mapStyle="mapbox://styles/mapbox/streets-v11"
    >
      {center && radius && renderRadiusSelection()}
    </Map>
  );
};

export default AddressFinderMap;
