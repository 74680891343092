import { FC, useContext, useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEllipsis,
  faPause,
  faPencil,
  faPlay,
  faShare,
  faTrash,
  faCircleStop,
} from '@fortawesome/pro-light-svg-icons';
import styles from './ContextMenu.module.scss';
import Text, { TEXT_COLOR } from '../typographie/Text';
import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { APARTMENT_RENT_STATUS, IApartment } from '@wohnsinn/ws-ts-lib';
import { ROUTES } from '../../../core/const/routes';
import { ACTION_TYPE, useHandleApartmentActionMutation } from '../../../core/hook/apartment-queries.hook';
import { MODAL_IDENTIFIER } from '../../../core/enum/modals.enum';
import ModalContext from '../../../core/context/modal.context';
import UserContext from 'core/context/user.context';

export interface IMenuItemsList {
  apartment?: IApartment;
}

const ContextMenu: FC<IMenuItemsList> = ({ apartment }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const { openModal } = useContext(ModalContext);
  const { user } = useContext(UserContext);

  const { t } = useTranslation('common');
  const { t: r } = useTranslation('routes');

  const handleDeleteMutation = useHandleApartmentActionMutation([apartment], ACTION_TYPE.DELETE);

  const handleEdit = () => {
    navigate(r(ROUTES.landlordRoutes.apartment.overview.path).replace(':apartmentId', apartment.id));
  };

  const handleClick = (event: any, onClick?: () => void) => {
    setAnchorEl(event.currentTarget);
    if (onClick) {
      onClick();
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <button type={'button'} className={styles.button} onClick={(e) => handleClick(e)}>
        <FontAwesomeIcon className={styles.icon} icon={faEllipsis} size={'3x'} />
      </button>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose} onClick={handleClose}>
        <MenuItem onClick={handleEdit} className={styles.menuItem}>
          <FontAwesomeIcon className={styles.icon} icon={faPencil} size={'1x'} />
          <Text color={TEXT_COLOR.TEXT_COLOR_DARK}>{t('edit')} </Text>
        </MenuItem>
        <MenuItem
          onClick={() => openModal({ id: MODAL_IDENTIFIER.PUBLISH_APARTMENT, data: { apartment } })}
          className={styles.menuItem}
        >
          <FontAwesomeIcon className={styles.icon} icon={apartment.isPublished ? faPause : faPlay} size={'1x'} />
          <Text color={TEXT_COLOR.TEXT_COLOR_DARK}>
            {apartment.isPublished ? t('deactivateListing') : t('publish')}{' '}
          </Text>
        </MenuItem>
        {apartment?.isPublished ? (
          <MenuItem
            onClick={() => openModal({ id: MODAL_IDENTIFIER.SHARE_LINK, data: { apartmentId: apartment.id } })}
            className={styles.menuItem}
          >
            <FontAwesomeIcon className={styles.icon} icon={faShare} size={'1x'} />
            <Text color={TEXT_COLOR.TEXT_COLOR_DARK}>{t('Anzeige teilen')} </Text>
          </MenuItem>
        ) : null}

        <MenuItem
          onClick={() => openModal({ id: MODAL_IDENTIFIER.RENT_STATUS, data: { apartment } })}
          className={styles.menuItem}
        >
          <FontAwesomeIcon className={styles.icon} icon={faCircleStop} size={'1x'} />
          <Text color={TEXT_COLOR.TEXT_COLOR_DARK}>
            {apartment?.rentStatus === APARTMENT_RENT_STATUS.ACQUISITION ? t('stopAcquisition') : t('startAcquisition')}
          </Text>
        </MenuItem>

        <MenuItem
          onClick={() =>
            openModal({
              id: MODAL_IDENTIFIER.DELETE_MODAL,
              data: {
                title: 'Objekt löschen?',
                text: 'Bist du dir sicher dass du dieses Objekt mit allen Bewerbungen löschen möchtest?',
                deleteHandler: async () => {
                  handleDeleteMutation.mutate();
                },
              },
            })
          }
          className={styles.menuItem}
        >
          <FontAwesomeIcon className={styles.icon} icon={faTrash} size={'1x'} />
          <Text color={TEXT_COLOR.TEXT_COLOR_DARK}>{t('deleteListing')} </Text>
        </MenuItem>
      </Menu>
    </>
  );
};

export default ContextMenu;
