import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faTrash, faTimes } from '@fortawesome/free-solid-svg-icons';
import styles from './../../ApartmentListView.module.scss';
import Text, { TEXT_COLOR } from '../../../../../../component/atoms/typographie/Text';
import { faSparkles } from '@fortawesome/pro-light-svg-icons';
import { ACTION_TYPE, useHandleApartmentActionMutation } from '../../../../../../core/hook/apartment-queries.hook';
import { IApartment } from '@wohnsinn/ws-ts-lib';
import { FC } from 'react';
import { ROUTES } from '../../../../../../core/const/routes';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom'; // assuming you have a CSS module for styles

interface IControlsProps {
  selectedApartments: IApartment[];
  onSettled: () => void; // Assuming t is a translation function
}

const ApartmentListControls: FC<IControlsProps> = ({ selectedApartments, onSettled }) => {
  const handleDeleteMutation = useHandleApartmentActionMutation(selectedApartments, ACTION_TYPE.DELETE, () =>
    onSettled()
  );
  const handlePublishMutation = useHandleApartmentActionMutation(selectedApartments, ACTION_TYPE.PUBLISH);
  const handleDepublishMutation = useHandleApartmentActionMutation(selectedApartments, ACTION_TYPE.DEPUBLISH);
  const navigate = useNavigate();
  const { t } = useTranslation('common');
  const { t: r } = useTranslation('routes');

  const handleEdit = (apartmentId: string) => {
    navigate(r(ROUTES.landlordRoutes.apartment.overview.path).replace(':apartmentId', apartmentId));
  };
  return (
    <div className={styles.controls}>
      {selectedApartments?.length === 1 ? (
        <button type="button" className={styles.controlButtons} onClick={() => handleEdit(selectedApartments[0]?.id)}>
          <FontAwesomeIcon icon={faPencil} size="lg" color="white" />
          <Text color={TEXT_COLOR.TEXT_COLOR_LIGHT}>{t('edit')}</Text>
        </button>
      ) : null}

      <div className={styles.divider} />
      <button type="button" className={styles.controlButtons} onClick={() => handleDeleteMutation.mutate()}>
        <FontAwesomeIcon icon={faTrash} size="lg" color="white" />
        <Text color={TEXT_COLOR.TEXT_COLOR_LIGHT}>{t('delete')}</Text>
      </button>
      <div className={styles.divider} />
      <button type="button" className={styles.controlButtons} onClick={() => handlePublishMutation.mutate()}>
        <FontAwesomeIcon icon={faSparkles} size="lg" color="white" />
        <Text color={TEXT_COLOR.TEXT_COLOR_LIGHT}>{t('publish')}</Text>
      </button>
      <div className={styles.divider} />
      <button type="button" className={styles.controlButtons} onClick={() => handleDepublishMutation.mutate()}>
        <FontAwesomeIcon icon={faTimes} size="lg" color="white" />
        <Text color={TEXT_COLOR.TEXT_COLOR_LIGHT}>{t('deactivate')}</Text>
      </button>
    </div>
  );
};

export default ApartmentListControls;
