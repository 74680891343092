import { FC, useContext, useEffect, useState } from 'react';
import useApartment from '../../../core/hook/apartment.hook';
import ApartmentInformation from '../../../component/organisms/ApartmentInformation';
import MapboxMap from '../../../component/molecules/Map';
import { ROUTES } from '../../../core/const/routes';
import { useTranslation } from 'react-i18next';
import styles from './ShareApartmentView.module.scss';
import useWindowSize from '../../../core/hook/windowsize.hook';
import UserContext from '../../../core/context/user.context';
import { IApplication, MATCHING_MODE } from '@wohnsinn/ws-ts-lib';
import LOCAL_STORAGE_KEYS from '../../../core/enum/local-storage.enum';
import { MODAL_IDENTIFIER } from '../../../core/enum/modals.enum';
import ModalContext from '../../../core/context/modal.context';
import CTAButton from '../../../component/atoms/Buttons/CTAButton';
import { motion } from 'framer-motion';
import { wohnsinnServices } from '../../../App';
import SnackBarContext from '../../../core/context/snackbar.context';
import { Grid } from '@mui/material';
import CTACard from '../../../component/molecules/Cards/CTACard';
import ApartmentStackPageLayout from '../../../component/layouts/ApartmentStackPageLayout';
import { getTenantApplicationState, TENANT_APPLICATION_STATE } from '../../../core/helper/get-tenant-application-state';
import { animateToLeft, animateToRight, initial } from '../../../core/const/matching-animations';
import ApartmentInformationLoading from '../../../component/molecules/LoadingElements/ApartmentInformationLoading';
import ApartmentRatingButtons from '../../../component/atoms/ApartmentRatingButtons';
import { useNavigate } from 'react-router-dom';
import useDesiredTenant from 'core/hook/desired-tenant.hook';

export type TAnimationDirection = 'left' | 'right' | 'initial';

const ShareApartmentView: FC = () => {
  const { handleSnackBar } = useContext(SnackBarContext);
  const { openModal } = useContext(ModalContext);
  const { applicationService, mixpanelTrackingService } = wohnsinnServices;
  const { apartment, isLoading } = useApartment();
  const { t } = useTranslation('common');
  const { t: r } = useTranslation('routes');
  const { t: v } = useTranslation('common', { keyPrefix: 'view.ShareApartmentView' });
  const { isGreaterMd } = useWindowSize();
  const navigate = useNavigate();
  const { user, tenantProfile } = useContext(UserContext);
  const [application, setApplication] = useState<IApplication>(null);
  const [animationDirection, setAnimationDirection] = useState<TAnimationDirection>('initial');
  const { desiredTenantErrors } = useDesiredTenant(apartment, tenantProfile);

  const fetchData = async (): Promise<void> => {
    if (apartment?.creatorId && apartment?.id && user?.uid) {
      const application: IApplication = await applicationService.getApplicationRating(
        apartment?.creatorId,
        apartment?.id,
        user?.uid
      );
      setApplication(application ?? null);
    }
  };

  useEffect(() => {
    if (apartment && !apartment.isPublished) {
      handleSnackBar('toast.apartment.notAvailable', 'warning');
    }
    fetchData();
  }, [apartment]);

  const submitRatingHandler = async (rating: MATCHING_MODE) => {
    mixpanelTrackingService.trackRatingClick(rating, apartment.id);
    setAnimationDirection(rating === MATCHING_MODE.NOPE ? 'left' : 'right');

    if (user) {
      await createApplication(rating);
    } else {
      // IF USER TRIES TO APPLY - FORCE REGISTRATION / LOGIN
      setTimeout(() => {
        setAnimationDirection('initial');
        openModal({
          id: MODAL_IDENTIFIER.SIGN_UP_USER,
          data: {
            title: 'Erstelle ein Konto, um loszulegen',
            isProbablyTenant: true,
          },
        });
      }, 300);
    }
  };

  const createApplication = async (rating: MATCHING_MODE) => {
    const tenantApplicationState = getTenantApplicationState(tenantProfile);

    localStorage.setItem(
      LOCAL_STORAGE_KEYS.REDIRECT_URL,
      `${r(ROUTES.staticRoutes.shareApartment.path).replace(':apartmentId', apartment.id)}`
    );

    if (rating === MATCHING_MODE.LIKE) {
      if (!user.isEmailVerified) {
        setTimeout(() => {
          setAnimationDirection('initial');
          openModal({ id: MODAL_IDENTIFIER.CONFIRM_EMAIL });
        }, 300);
        return;
      }

      if (tenantApplicationState === TENANT_APPLICATION_STATE.NOT_READY_FOR_APPLICATION) {
        setAnimationDirection('right');
        setTimeout(() => {
          setAnimationDirection('initial');
          openModal({ id: MODAL_IDENTIFIER.COMPLETE_APPLICATION_FOLDER });
        }, 300);
        return;
      }
    }

    if (desiredTenantErrors?.length) {
      setTimeout(() => {
        setAnimationDirection('initial');
        openModal({ id: MODAL_IDENTIFIER.DESIRED_TENANT, data: desiredTenantErrors });
      }, 300);
      return;
    }

    if (tenantApplicationState === TENANT_APPLICATION_STATE.MISSING_DOCS) {
      setAnimationDirection('right');
      setTimeout(() => {
        setAnimationDirection('initial');
        openModal({ id: MODAL_IDENTIFIER.COMPLETE_DOCUMENT_FOLDER });
      }, 300);
      return;
    }
    try {
      localStorage.setItem(LOCAL_STORAGE_KEYS.REDIRECT_URL, '/');
      await applicationService.handleRating(apartment, tenantProfile, rating, true);

      if (rating === MATCHING_MODE.LIKE) {
        await applicationService.createApplication(apartment, tenantProfile, rating);
        handleSnackBar('toast.success.applied', 'success');
      }

      redirectAfterRating();
    } catch (error) {
      console.error(error);
    }
  };

  const redirectAfterRating = () => {
    localStorage.setItem(LOCAL_STORAGE_KEYS.REDIRECT_URL, '/');
    navigate(r(ROUTES.staticRoutes.redirect.path));
  };

  if (!isLoading && !apartment) {
    return (
      <ApartmentStackPageLayout backButtonRoute={'/'} pageTitle={r(ROUTES.landlordRoutes.apartment.preview.title)}>
        <Grid container justifyContent={'center'}>
          <Grid item xs={12} md={6}>
            <div className={styles.notExistingApartmentWrapper}>
              <CTACard
                title={v('apartmentNotExisting.title')}
                text={v('apartmentNotExisting.text')}
                imgSrc={t('pictogram.object')}
                imgAltText={t('bookmarkIcon')}
                link={'/'}
                ctaText={'Zur Übersicht'}
              />
            </div>
          </Grid>
        </Grid>
      </ApartmentStackPageLayout>
    );
  }

  if (isLoading) {
    return (
      <ApartmentStackPageLayout backButtonRoute={'/'} pageTitle={r(ROUTES.landlordRoutes.apartment.preview.title)}>
        <ApartmentInformationLoading />
      </ApartmentStackPageLayout>
    );
  }

  return (
    <ApartmentStackPageLayout backButtonRoute={'/'} pageTitle={r(ROUTES.landlordRoutes.apartment.preview.title)}>
      {apartment ? (
        <div className={styles.wrapper}>
          <motion.div
            key={apartment?.id}
            className={styles.apartment}
            style={{
              zIndex: 10,
            }}
            animate={getAnimation(true, animationDirection)}
          >
            <ApartmentInformation apartment={apartment} />
          </motion.div>
          <div className={styles.ratings}>
            {apartment?.applicationRefList?.includes(user?.uid) ? (
              <div className={styles.applicationLink}>
                <CTAButton
                  rounded={false}
                  buttonText={'Zur Bewerbung'}
                  expand={'block'}
                  onClick={() => navigate(r(ROUTES.tenantRoutes.chat.path))}
                />
              </div>
            ) : apartment?.isPublished ? (
              <ApartmentRatingButtons
                apartment={apartment}
                hasApplication={!!application}
                onButtonClick={(matchingMode) => submitRatingHandler(matchingMode)}
              />
            ) : null}
          </div>
          <div className={styles.map}>
            {!isLoading && !!apartment && isGreaterMd && <MapboxMap isInteractive={true} apartments={[apartment]} />}
          </div>
        </div>
      ) : null}
    </ApartmentStackPageLayout>
  );
};

/**
 * Get styles for rating animations
 * @param {boolean} canAnimate
 * @param  {TAnimationDirection} animationDirection
 */
export const getAnimation = (canAnimate: boolean, animationDirection: TAnimationDirection) => {
  switch (canAnimate && animationDirection) {
    case 'initial':
      return initial;
    case 'left':
      return animateToLeft;
    case 'right':
      return animateToRight;
  }
};

export default ShareApartmentView;
