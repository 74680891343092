import { TableCell, TableHead, TableRow } from '@mui/material';
import UserContext from '../../../../../../core/context/user.context';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../../ApartmentListView.module.scss';
export const ApartmentListTableHead = () => {
  const { user } = useContext(UserContext);
  const { t } = useTranslation('common');
  return (
    <TableHead>
      <TableRow>
        {user?.isAdmin ? <TableCell width={70}></TableCell> : null}
        <TableCell width={70}>{t('image')}</TableCell>
        <TableCell>{t('street')}</TableCell>
        <TableCell>{t('numberShortCut')}</TableCell>
        <TableCell>{t('postCodeShortCut')}</TableCell>
        <TableCell>{t('area')}</TableCell>

        <TableCell>{t('applications')}</TableCell>
        <TableCell>{t('status')}</TableCell>
        {process.env.REACT_APP_SHOW_SALE === 'true' && <TableCell>{t('marketingType')}</TableCell>}
        <TableCell>{t('type')}</TableCell>
        <TableCell>{t('floor')}</TableCell>
        <TableCell>{t('floorLocation')}</TableCell>
        <TableCell>{t('size')}</TableCell>
        <TableCell>{t('price')}</TableCell>
        <TableCell>{t('rooms')}</TableCell>
        <TableCell className={styles.stickyCell}>{t('more')}</TableCell>
      </TableRow>
    </TableHead>
  );
};
