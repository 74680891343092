import { FC, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { SUBMIT_BUTTON_MODE } from '../../../core/enum/submit-button-mode.enum';
import CTAButton, { BUTTON_STYLE, getSubmitIcon } from '../../atoms/Buttons/CTAButton';
import styles from './FormLayout.module.scss';
import { Grid } from '@mui/material';

export interface INotSubmitButton {
  text: string;
  action: () => any;
}

export interface IFormWrapperProps {
  submitMode?: SUBMIT_BUTTON_MODE;
  showSubmitButton?: boolean;
  notSubmitButton?: INotSubmitButton;
  disabled?: boolean;
  isTunnel?: boolean;
}

const FormLayout: FC<PropsWithChildren<IFormWrapperProps>> = ({
  children,
  showSubmitButton = true,
  submitMode,
  notSubmitButton,
  disabled = false,
  isTunnel = false,
}) => {
  const { t: c } = useTranslation('common');

  return (
    <div className={styles.formWrapper}>
      {children}

      {notSubmitButton || showSubmitButton ? (
        <div className={styles.mobileButtons}>
          <Grid container rowSpacing={1} paddingX={2}>
            <Grid item xs={12}>
              {notSubmitButton ? (
                <CTAButton
                  rounded={false}
                  type={'button'}
                  onClick={notSubmitButton.action}
                  expand={'block'}
                  buttonStyle={BUTTON_STYLE.SECONDARY}
                  buttonText={notSubmitButton.text}
                />
              ) : null}
            </Grid>

            <Grid item xs={12}>
              {showSubmitButton ? (
                <CTAButton
                  rounded={false}
                  disabled={disabled}
                  icon={getSubmitIcon(submitMode)}
                  spinIcon={submitMode === SUBMIT_BUTTON_MODE.SUBMITTING}
                  expand={'block'}
                  buttonText={isTunnel ? c('next') : c('save')}
                />
              ) : null}
            </Grid>
          </Grid>
        </div>
      ) : null}

      <div className={styles.desktopButtons}>
        {notSubmitButton ? (
          <CTAButton
            type={'button'}
            buttonStyle={BUTTON_STYLE.SECONDARY}
            onClick={notSubmitButton.action}
            buttonText={notSubmitButton.text}
          />
        ) : null}
        {showSubmitButton && (
          <CTAButton
            disabled={disabled}
            icon={getSubmitIcon(submitMode)}
            spinIcon={submitMode === SUBMIT_BUTTON_MODE.SUBMITTING}
            buttonText={isTunnel ? c('next') : c('save')}
          />
        )}
      </div>
    </div>
  );
};

export default FormLayout;
