import { FC, PropsWithChildren, useContext } from 'react';
import styles from '../ThreeColumnsLayout.module.scss';
import ThreeColumnsLayoutContext from '../../../../core/context/three-columns-layout-context';

const ThreeColumnsLayoutRightSide: FC<PropsWithChildren> = ({ children }) => {
  const { showRightSideBar } = useContext(ThreeColumnsLayoutContext);

  return (
    <div className={`${styles.right} ${showRightSideBar ? styles.show : ''}`}>
      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default ThreeColumnsLayoutRightSide;
