import PageLayout from '../../../../component/layouts/PageLayout';
import { ROUTES } from '../../../../core/const/routes';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import UserContext from '../../../../core/context/user.context';
import { REGISTRATION_STATES } from '@wohnsinn/ws-ts-lib';
import CreateLandlordAddProfileScreen from '../../../../component/organisms/registration/landlord/CreateLandlordAddProfileScreen';
import CreateLandlordSelectTypeScreen from '../../../../component/organisms/registration/landlord/CreateLandlordSelectTypeScreen';
import CreateRegistrationWelcomeScreen from '../../../../component/organisms/registration/CreateRegistrationWelcomeScreen';
import CreateLandlordAddOrganization from '../../../../component/organisms/registration/landlord/CreateLandlordAddOrganization';
import CreateLandlordAddOrganizationLogo from '../../../../component/organisms/registration/landlord/CreateLandlordAddOrganizationLogo';
import CreateLandlordAddOrganizationLegalInformation from '../../../../component/organisms/registration/landlord/CreateLandlordAddOrganizationLegalInformation';
import CreateLandlordAddOrganizationCRM from '../../../../component/organisms/registration/landlord/CreateLandlordAddOrganizationCRM';
import CreateLandlordInviteOrganizationMember from '../../../../component/organisms/registration/landlord/CreateLandlordInviteOrganizationMember';
import CreateLandlordVerifyPhoneNumber from '../../../../component/organisms/registration/landlord/CreateLandlordVerifyPhoneNumber';
import FinishedTunnelRedirectScreen from '../../../../component/organisms/registration/FinishedTunnelRedirectScreen';
import ProgressBar from '../../../../component/atoms/ProgressBar';
import { Navigate } from 'react-router-dom';
import LOCAL_STORAGE_KEYS from '../../../../core/enum/local-storage.enum';
import { wohnsinnServices } from '../../../../App';

const CreateLandlordTunnel = () => {
  const { t: r } = useTranslation('routes');
  const { user, landlordProfile } = useContext(UserContext);
  const [progress, setProgress] = useState<number>(0);
  const { userService } = wohnsinnServices;

  useEffect(() => {
    switch (user?.registrationState) {
      case REGISTRATION_STATES.START:
        setProgress(0);
        break;
      case REGISTRATION_STATES.SELECT_LANDLORD_TYPE:
        setProgress(20);
        break;
      case REGISTRATION_STATES.ADD_PROFILE:
        setProgress(landlordProfile?.isCommercialLandlord ? 25 : 100);
        break;
      case REGISTRATION_STATES.VERIFY_PHONE_NUMBER:
        setProgress(37.5);
        break;
      case REGISTRATION_STATES.ADD_ORGANIZATION_PROFILE:
        setProgress(50);
        break;
      case REGISTRATION_STATES.ADD_ORGANIZATION_LOGO:
        setProgress(62.5);
        break;
      case REGISTRATION_STATES.ADD_ORGANIZATION_LEGAL_INFORMATION:
        setProgress(75);
        break;
      case REGISTRATION_STATES.ADD_CRM:
        setProgress(87.5);
        break;
      case REGISTRATION_STATES.INVITE_ORGANIZATION_MEMBER:
        setProgress(100);
        break;
      default:
        setProgress(0);
    }
  }, [user?.registrationState]);

  const renderCreateLandlordScreen = () => {
    // Business Landlord: 8 Screens with progress
    // Private Landlord: 2 Screens with progress
    switch (user?.registrationState) {
      case REGISTRATION_STATES.START:
        return <CreateRegistrationWelcomeScreen />;
      case REGISTRATION_STATES.SELECT_LANDLORD_TYPE:
        return <CreateLandlordSelectTypeScreen />;
      case REGISTRATION_STATES.ADD_PROFILE:
        return <CreateLandlordAddProfileScreen />;
      case REGISTRATION_STATES.VERIFY_PHONE_NUMBER:
        return <CreateLandlordVerifyPhoneNumber />;
      case REGISTRATION_STATES.ADD_ORGANIZATION_PROFILE:
        return <CreateLandlordAddOrganization />;
      case REGISTRATION_STATES.ADD_ORGANIZATION_LOGO:
        return <CreateLandlordAddOrganizationLogo />;
      case REGISTRATION_STATES.ADD_ORGANIZATION_LEGAL_INFORMATION:
        return <CreateLandlordAddOrganizationLegalInformation />;
      case REGISTRATION_STATES.ADD_CRM:
        return <CreateLandlordAddOrganizationCRM />;
      case REGISTRATION_STATES.INVITE_ORGANIZATION_MEMBER:
        return <CreateLandlordInviteOrganizationMember />;
      case REGISTRATION_STATES.FINISH_LOADING_SCREEN:
        return (
          <FinishedTunnelRedirectScreen
            onFinish={async () =>
              await userService.updateUserData({ registrationState: REGISTRATION_STATES.FINISHED }, user.uid)
            }
          />
        );
      case REGISTRATION_STATES.FINISHED:
        let redirectUrl = '/';
        const urlFromStorage = localStorage.getItem(LOCAL_STORAGE_KEYS.REDIRECT_URL);
        if (urlFromStorage) {
          redirectUrl = urlFromStorage;
          localStorage.removeItem(LOCAL_STORAGE_KEYS.REDIRECT_URL);
        }

        return <Navigate to={redirectUrl} />;
      default:
        return <CreateRegistrationWelcomeScreen />;
    }
  };

  return (
    <PageLayout showPageTitle={false} pageTitle={r(ROUTES.userRoutes.registration.createLandlordTunnel.title)}>
      {progress ? <ProgressBar progress={progress} /> : null}
      {user ? renderCreateLandlordScreen() : null}
    </PageLayout>
  );
};

export default CreateLandlordTunnel;
