import CTAButton from 'component/atoms/Buttons/CTAButton';
import styles from './AddressFinderInput.module.scss';
import useAccessibleNavigation from '../../../core/hook/use-accessible.navigation';
import { ChangeEvent, FC, useContext, useRef, useState } from 'react';
import { Controller, ControllerRenderProps } from 'react-hook-form';
import { DebounceInput } from 'react-debounce-input';
import { FormContext } from 'core/context/form.context';
import { IGeoPoint, ILocationSelection, TCenter } from '@wohnsinn/ws-ts-lib';
import { useTranslation } from 'react-i18next';
import { wohnsinnServices } from 'App';
import AddressFinderMap from 'component/molecules/AddressFinderMap';
import { Slider } from '@mui/material';
import Text, { TEXT_COLOR, TEXT_TYPE } from 'component/atoms/typographie/Text';
import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import useWindowSize from 'core/hook/windowsize.hook';

const AddressFinderInput: FC<{ name: string; hideSubmit?: boolean }> = ({ name, hideSubmit }) => {
  const { control } = useContext(FormContext);
  const INITIAL_RADIUS_IN_KM = 10;
  const { handleKeyboardNavigation } = useAccessibleNavigation();
  const ref = useRef(null);
  const [suggestionList, setSuggestionList] = useState<any>([]);
  const { t } = useTranslation('common');
  const { mapBoxService } = wohnsinnServices;
  const [sliderValue, setSliderValue] = useState<number>(INITIAL_RADIUS_IN_KM);
  const { isSmallerMd } = useWindowSize();

  const fetchSuggestions = async (searchString: string): Promise<void> => {
    try {
      const suggestions = await mapBoxService.getLocationSuggestionList(searchString);
      setSuggestionList(suggestions);
    } catch (e) {
      console.error('error on fetchSuggestions', e);
    }
  };

  const handleSearch = async (searchString: string): Promise<void> => {
    if (searchString.length) {
      await fetchSuggestions(searchString);
    }
  };

  const onSelect = async (selection: any, field: ControllerRenderProps) => {
    const center: TCenter = selection.center;
    const geoPoint: IGeoPoint = { lat: selection.center[1], lng: selection.center[0] };
    const radius: number = selection?.radius ? selection.radius : INITIAL_RADIUS_IN_KM;

    const selectedParams: ILocationSelection = {
      center,
      geoPoint,
      place_name: selection.place_name,
      radius,
      id: selection.id,
    };

    field.onChange({ ...selectedParams, radius });
    ref.current.value = selection.place_name;
    setSuggestionList([]);
  };

  const clearInput = (e: any, field: ControllerRenderProps) => {
    field.onChange(null);
    e.target.value = '';
    ref.current.value = '';
    setSuggestionList([]);
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        return (
          <div className={styles.wrapper}>
            <div className={styles.inputWrapper}>
              <DebounceInput
                ref={ref}
                debounceTimeout={200}
                key={'searchInput'}
                type={'search'}
                onFocus={(e: any) => clearInput(e, field)}
                onClick={(e: any) => clearInput(e, field)}
                autoComplete={'off'}
                className={styles.input}
                placeholder={t('component.organisms.searchTunnelSlides.City.placeholder')}
                name="addressSearch"
                value={field.value?.place_name ?? null}
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleSearch((e.target as HTMLInputElement).value)}
              />
              {hideSubmit ? null : (
                <CTAButton
                  type={'submit'}
                  rounded={true}
                  customStyling={styles.cta}
                  icon={isSmallerMd ? faSearch : null}
                  color={TEXT_COLOR.TEXT_COLOR_DARK}
                  hideText={isSmallerMd}
                  buttonText={'Wohnung finden'}
                />
              )}
            </div>

            {suggestionList?.length > 0 && (
              <ul className={styles.optionWrapper}>
                {suggestionList.map((suggestion: any) => (
                  <li
                    onKeyDown={(e) =>
                      handleKeyboardNavigation(e, {
                        enableArrowKeyNavigation: true,
                        enterHandler: () => onSelect(suggestion, field),
                        spaceHandler: () => onSelect(suggestion, field),
                      })
                    }
                    onClick={() => onSelect(suggestion, field)}
                    key={suggestion.place_name}
                    tabIndex={0}
                    className={styles.option}
                  >
                    {suggestion.place_name}
                  </li>
                ))}
              </ul>
            )}
            {error && <h1>this is error </h1>}

            {field.value?.place_name ? (
              <div className={styles.mapWrapper}>
                <div>
                  <Text type={TEXT_TYPE.TEXT_TYPE_CAPTION} align={'center'}>
                    {field.value.radius} km Umkreis
                  </Text>
                </div>
                <Slider
                  value={sliderValue}
                  min={1}
                  step={1}
                  valueLabelFormat={(val) => val + ' km'}
                  valueLabelDisplay={'auto'}
                  max={25}
                  /*@ts-ignore*/
                  onChangeCommitted={(e: Event, value: any) => {
                    console.log('onchange');
                    field.onChange({ ...field.value, radius: value as number });
                  }}
                  onChange={(e: Event) => {
                    /*@ts-ignore */
                    setSliderValue(e.target.value);
                  }}
                />

                <AddressFinderMap height={'250px'} radius={field.value?.radius ?? 5} center={field.value?.center} />
              </div>
            ) : null}
          </div>
        );
      }}
    />
  );
};

export default AddressFinderInput;
