import { FC, useEffect, useState } from 'react';
import { ITenantProfile, IUpload } from '@wohnsinn/ws-ts-lib';
import Avatar, { AVATAR_SIZE } from '../../../atoms/Avatar';
import styles from '../ApplicantInformationSection/ApplicantInformationSection.module.scss';
import Text, { TEXT_TYPE, TEXT_WEIGHT } from '../../../atoms/typographie/Text';
import SanitizedHTML from '../../../atoms/SanitzedHtml';
import {
  getJobStatusItems,
  getLegalItems,
  getPersonalInformationItems,
  getSelfDisclosureItems,
} from '../../../../core/helper/get-applicant-informations-helper';
import { useTranslation } from 'react-i18next';
import useTenantDocuments from '../../../../core/hook/tenant-document.hook';
import { Grid } from '@mui/material';
import FormSectionTitle from '../../../atoms/FormSectionTitle';

const ApplicantInformationPrint: FC<{ tenantProfile: ITenantProfile }> = ({ tenantProfile }) => {
  const { t } = useTranslation('common', { keyPrefix: 'component.organisms.ApplicantInformation' });
  const { t: c } = useTranslation('common');
  const [schufaCheckDocumentUploadList, setSchufaCheckUploadList] = useState<IUpload[]>([]);
  const [incomeProofDocumentUploadList, setIncomeProofDocumentUploadList] = useState<IUpload[]>([]);
  const { schufaCheckDocumentList, incomeProofDocumentList } = useTenantDocuments(tenantProfile?.uid);

  useEffect(() => {
    setSchufaCheckUploadList(schufaCheckDocumentList);
    setIncomeProofDocumentUploadList(incomeProofDocumentList);
  }, [schufaCheckDocumentList, incomeProofDocumentList]);
  return (
    <div className={styles.wrapper}>
      <Avatar bordered size={AVATAR_SIZE.sm} avatarUrl={tenantProfile?.photoUrl} />
      <Grid container>
        <Grid item xs={6}>
          <FormSectionTitle title={c('personalInformation')} isFirst />
          {getPersonalInformationItems(tenantProfile, c).map((item, index) => (
            <div key={`${item.value}-${index}`}>
              {item.label}: {item.value}
            </div>
          ))}
        </Grid>
        <Grid item xs={6}>
          <FormSectionTitle title={t('selfDisclosure')} isFirst />
          {getSelfDisclosureItems(tenantProfile, t).map((item, index) => (
            <div key={`${item.value}-${index}`}>
              {item.label}: {item.value}
            </div>
          ))}
        </Grid>
        <Grid item xs={6}>
          <FormSectionTitle title={t('jobStatus')} />
          {getJobStatusItems(tenantProfile, t).map((item, index) => (
            <div key={`${item.value}-${index}`}>
              {item.label}: {item.value}
            </div>
          ))}
        </Grid>
        <Grid item xs={6}>
          <FormSectionTitle title={t('legalItems')} />
          {getLegalItems(tenantProfile, t, c).map((item, index) => (
            <div key={`${item.value}-${index}`}>
              {item.label}: {item.value}
            </div>
          ))}
        </Grid>
        <Grid item xs={6}>
          <FormSectionTitle title={t('documents')} />
          {schufaCheckDocumentUploadList && schufaCheckDocumentUploadList.length ? (
            <Text>Der Bewerber hat Bonitätsnachweise hochgeladen</Text>
          ) : (
            <Text>Der Bewerber hat keine Bonitätsnachweise hochgeladen</Text>
          )}
          {incomeProofDocumentUploadList && incomeProofDocumentUploadList.length ? (
            <Text>Der Bewerber Einkommensnachweise hochgeladen</Text>
          ) : (
            <Text>Der Bewerber hat keine Einkommensnachweise hochgeladen</Text>
          )}
        </Grid>
        <Grid item xs={6}>
          <FormSectionTitle title={t('introductionVideo.title')} />
          {tenantProfile && tenantProfile?.aboutMe?.introductionVideo ? (
            <Text>Der Bewerber ein Vorstellungsvideo hochgeladen.</Text>
          ) : (
            <Text>Der Bewerber hat kein Vorstellungsvideo hochgeladen.</Text>
          )}
        </Grid>
      </Grid>
      <FormSectionTitle title={t('aboutMe')} />
      <Text weight={TEXT_WEIGHT.TEXT_WEIGHT_LIGHT} type={TEXT_TYPE.TEXT_TYPE_CAPTION}>
        <SanitizedHTML
          className={styles.aboutMeText}
          options={{ allowedTags: ['br'] }}
          dirty={tenantProfile?.aboutMe.description}
        />
      </Text>
    </div>
  );
};

export default ApplicantInformationPrint;
