import { APARTMENT_CONDITION, ENERGY_PASS_STATUS, ENERGY_PASS_TYPE } from '@wohnsinn/ws-ts-lib';
import Joi from 'joi';

export const APARTMENT_ENERGY_SUPPLY_SCHEMA = {
  condition: Joi.string()
    .valid(...Object.keys(APARTMENT_CONDITION))
    .optional()
    .allow(null),
  yearOfLastActivity: Joi.string().allow(null).allow(''),
  heatingType: Joi.any(),
  heatingSystem: Joi.any(),

  buildYear: Joi.number().min(1000).max(2025).allow(null).allow(''),
  energyPassStatus: Joi.string()
    .valid(...Object.keys(ENERGY_PASS_STATUS))
    .allow(null)
    .allow(''),
  energyEfficiency: Joi.string().allow(null).allow(''),
  energyPassType: Joi.when('isEnergyPassAvailable', {
    is: true,
    then: Joi.string()
      .valid(...Object.keys(ENERGY_PASS_TYPE))
      .allow(null)
      .allow(''),
    otherwise: Joi.allow(null).allow(''),
  }),
  primaryEnergySource: Joi.string().allow(''),
  description: Joi.any().allow(''),
  energyConsumption: Joi.when('isEnergyPassAvailable', {
    is: true,
    then: Joi.number().sign('positive').allow(''),
    otherwise: Joi.allow(null).allow(''),
  }),

  isEnergyPassAvailable: Joi.boolean().allow(null),
  isEnergyConsumptionForHotWaterIncluded: Joi.boolean().allow(null),
};
