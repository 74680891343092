import styles from './ApartmentContactPersonCard.module.scss';
import { FC } from 'react';
import Text, { TEXT_COLOR, TEXT_WEIGHT } from '../../atoms/typographie/Text';
import { useTranslation } from 'react-i18next';
import Headline, { HEADLINE_SIZE } from '../../atoms/typographie/Headline';
import { IApartmentContactPerson } from '@wohnsinn/ws-ts-lib';
import Avatar, { AVATAR_SIZE } from 'component/atoms/Avatar';

const ApartmentContactPersonCard: FC<{ contactPerson: IApartmentContactPerson }> = ({ contactPerson }) => {
  const { t } = useTranslation('common');

  const renderPhone = (phone: string) => (
    <a href={`tel:${phone}`}>
      <br />
      <Text color={TEXT_COLOR.TEXT_COLOR_PRIMARY} tag={'span'} weight={TEXT_WEIGHT.TEXT_WEIGHT_REGULAR}>
        {phone}
      </Text>
    </a>
  );

  return (
    <div className={styles.wrapper}>
      <Headline size={HEADLINE_SIZE.H3}>{t('view.ApartmentOverviewView.contactPerson')}</Headline>
      <div className={styles.landlord}>
        {contactPerson?.photo ? <Avatar size={AVATAR_SIZE.md} avatarUrl={contactPerson?.photo.url} /> : null}
        <p>
          {contactPerson?.company ? (
            <Text weight={TEXT_WEIGHT.TEXT_WEIGHT_REGULAR} tag={'span'}>
              {contactPerson?.company} <br />
            </Text>
          ) : null}
          <Text weight={TEXT_WEIGHT.TEXT_WEIGHT_REGULAR} tag={'span'}>
            {contactPerson?.firstName} {contactPerson?.name}
          </Text>
          {contactPerson?.privatePhone && contactPerson?.addressRelease
            ? renderPhone(contactPerson.privatePhone)
            : null}
          {contactPerson?.mobile && contactPerson?.addressRelease ? renderPhone(contactPerson.mobile) : null}
          {contactPerson?.centralPhone && contactPerson?.addressRelease
            ? renderPhone(contactPerson.centralPhone)
            : null}
        </p>
      </div>
    </div>
  );
};

export default ApartmentContactPersonCard;
