import styles from 'component/organisms/ApartmentInformation/ApartmentInformation.module.scss';
import Headline, { HEADLINE_SIZE } from 'component/atoms/typographie/Headline';
import Tag from 'component/atoms/Tag';
import { createLocationString } from 'core/helper/create-apartment-string';
import CTAButton from 'component/atoms/Buttons/CTAButton';
import MapboxMap from 'component/molecules/Map';
import { useTranslation } from 'react-i18next';
import { Dispatch, FC, SetStateAction } from 'react';
import { IApartment } from '@wohnsinn/ws-ts-lib';

const ApartmentLocationMap: FC<{ apartment: IApartment; setMapIsOpen: Dispatch<SetStateAction<boolean>> }> = ({
  apartment,
  setMapIsOpen,
}) => {
  const { t: a } = useTranslation('common', { keyPrefix: 'component.organisms.ApartmentInformation' });
  const { t } = useTranslation('common');
  return (
    <div className={styles.informationSection}>
      <Headline size={HEADLINE_SIZE.H3} tag={'span'}>
        {t('location')}
      </Headline>
      <div>
        <Tag
          element={'span'}
          tag={{
            key: 'location',
            label: `📍${createLocationString(apartment)}`,
          }}
        />
      </div>
      <div className={styles.mapPreview}>
        <CTAButton onClick={() => setMapIsOpen(true)} customStyling={styles.openMapButton} buttonText={a('openCard')} />
        <div className={styles.mapPlaceHolder} />
      </div>
      <div className={styles.desktopMap}>
        <MapboxMap scrollZoom={false} apartments={[apartment]} isInteractive />
      </div>
    </div>
  );
};

export default ApartmentLocationMap;
