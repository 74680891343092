import { faBars } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './LPBurgerMenu.module.scss';
import { Drawer } from '@mui/material';
import { useState } from 'react';
import LPDrawerMenu from '../LPDrawerMenu';

const LPBurgerMenu = () => {
  const [showMenu, setShowMenu] = useState<boolean>(false);

  const toggleDrawer = () => {
    setShowMenu(!showMenu);
  };

  return (
    <>
      <button onClick={toggleDrawer} type={'button'} className={styles.wrapper}>
        <FontAwesomeIcon size={'xl'} icon={faBars} />
      </button>
      <Drawer anchor={'right'} open={showMenu} onClose={() => setShowMenu(false)}>
        <LPDrawerMenu onClose={() => setShowMenu(false)} />
      </Drawer>
    </>
  );
};

export default LPBurgerMenu;
