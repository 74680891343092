import { Wistia } from '../wistia_namespace.js';
import { mediaDataHost } from './hosts.js';
import { mediaDataTransforms } from './media-data-transforms.js';
import { cacheMediaData } from './media-data-cache.js';
import type {
  MediaDataServerResponse,
  MediaDataServerErrorResponse,
  MediaData,
  WistiaGlobal,
} from '../types/player-api-types.js';

// initialize the cache if it doesn't exist
if (window.Wistia) {
  if (!window.Wistia._mediaDataPromises) {
    window.Wistia._mediaDataPromises = {};
  }
}

type FetchMediaDataOptions = {
  embedHost?: string;
};

export const fetchMediaData = async (
  hashedId: string,
  options: FetchMediaDataOptions = {},
): Promise<MediaData | MediaDataServerErrorResponse> => {
  const windowWistia = Wistia as WistiaGlobal;
  const cacheKey = hashedId;
  if (windowWistia._mediaDataPromises?.[hashedId]) {
    return windowWistia._mediaDataPromises[cacheKey];
  }

  const host = mediaDataHost(options) as string;
  const url = `https://${host}/embed/medias/${hashedId}.json`;

  const promise = fetch(url)
    .then(async (resp) => resp.json())
    .then((response: MediaDataServerResponse): MediaData | MediaDataServerErrorResponse => {
      if (response.error) {
        return response;
      }
      const { media: mediaData } = response;

      if (mediaData) {
        mediaDataTransforms(mediaData, options);
        cacheMediaData(mediaData.hashedId, mediaData);
        return mediaData;
      }

      return {};
    });

  if (windowWistia._mediaDataPromises) {
    windowWistia._mediaDataPromises[cacheKey] = promise;
  } else {
    windowWistia._mediaDataPromises = { [cacheKey]: promise };
  }
  return promise;
};
