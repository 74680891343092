// routes only landlords can see
import AccountView from 'view/landlord/AccountView';
import ApartmentCreateView from 'view/landlord/apartment/ApartmentCreateView';
import ApartmentListView from 'view/landlord/apartment/ApartmentListView';
import ApartmentOverviewView from 'view/landlord/apartment/ApartmentOverviewView';
import ApartmentPreviewView from 'view/landlord/apartment/ApartmentPreviewView';
import ApartmentApplicationsView from 'view/landlord/apartment/ApartmentApplicationsView';
import { IProtectedRouteProps } from 'AppRouter';
import { ROUTES } from '../routes';
import MainInformationFormView from 'view/landlord/apartment/forms/MainInformationFormView';
import ApartmentCostFormView from 'view/landlord/apartment/forms/ApartmentCostFormView';
import ApartmentEquipmentFormView from 'view/landlord/apartment/forms/ApartmentEquipmentFormView';
import ApartmentDesiredTenantFormView from 'view/landlord/apartment/forms/ApartmentDesiredTenantFormView';
import ApartmentEngergySupplyFormView from 'view/landlord/apartment/forms/ApartmentEnergySupplyFormView';
import ApartmentFreeTextsFormView from 'view/landlord/apartment/forms/ApartmentFreeTextsFormView';
import ApartmentMediaFormView from 'view/landlord/apartment/forms/ApartmentMediaFormView';
import ApartmentContactPersonFormView from 'view/landlord/apartment/forms/ApartmentContactPersonFormView';
import ApartmentEnergySupplyFormView from 'view/landlord/apartment/forms/ApartmentEnergySupplyFormView';

// routes only landlords can see
export const LANDLORD_ROUTES: IProtectedRouteProps[] = [
  { path: ROUTES.landlordRoutes.account.path, Component: AccountView },
  { path: ROUTES.landlordRoutes.apartment.list.path, Component: ApartmentListView },
  { path: ROUTES.landlordRoutes.apartment.overview.path, Component: ApartmentOverviewView },
  {
    path: ROUTES.landlordRoutes.apartment.mainInformation.path,
    Component: MainInformationFormView,
    props: { isTunnelView: false },
  },
  { path: ROUTES.landlordRoutes.apartment.cost.path, Component: ApartmentCostFormView, props: { isTunnelView: false } },
  {
    path: ROUTES.landlordRoutes.apartment.equipment.path,
    Component: ApartmentEquipmentFormView,
    props: { isTunnelView: false },
  },
  {
    path: ROUTES.landlordRoutes.apartment.desiredTenant.path,
    Component: ApartmentDesiredTenantFormView,
    props: { isTunnelView: false },
  },
  {
    path: ROUTES.landlordRoutes.apartment.energyPass.path,
    Component: ApartmentEngergySupplyFormView,
    props: { isTunnelView: false },
  },
  {
    path: ROUTES.landlordRoutes.apartment.freeTexts.path,
    Component: ApartmentFreeTextsFormView,
    props: { isTunnelView: false },
  },
  {
    path: ROUTES.landlordRoutes.apartment.media.path,
    Component: ApartmentMediaFormView,
    props: { isTunnelView: false },
  },
  {
    path: ROUTES.landlordRoutes.apartment.contactPerson.path,
    Component: ApartmentContactPersonFormView,
    props: { isTunnelView: false },
  },
  { path: ROUTES.landlordRoutes.apartment.new.path, Component: ApartmentCreateView },
  { path: ROUTES.landlordRoutes.apartment.preview.path, Component: ApartmentPreviewView },
  { path: ROUTES.landlordRoutes.apartment.applications.path, Component: ApartmentApplicationsView },
  {
    path: ROUTES.landlordRoutes.apartment.address.path,
    Component: ApartmentCreateView,
    props: { isTunnelView: false },
  },

  {
    path: ROUTES.landlordRoutes.apartment.create.new.path,
    Component: ApartmentCreateView,
    props: { isTunnelView: true, progress: 10 },
  },
  {
    path: ROUTES.landlordRoutes.apartment.create.mainInformation.path,
    Component: MainInformationFormView,
    props: { isTunnelView: true, progress: 20 },
  },
  {
    path: ROUTES.landlordRoutes.apartment.create.cost.path,
    Component: ApartmentCostFormView,
    props: { isTunnelView: true, progress: 30 },
  },
  {
    path: ROUTES.landlordRoutes.apartment.create.equipment.path,
    Component: ApartmentEquipmentFormView,
    props: { isTunnelView: true, progress: 40 },
  },
  {
    path: ROUTES.landlordRoutes.apartment.create.energySupply.path,
    Component: ApartmentEnergySupplyFormView,
    props: { isTunnelView: true, progress: 50 },
  },
  {
    path: ROUTES.landlordRoutes.apartment.create.freeTexts.path,
    Component: ApartmentFreeTextsFormView,
    props: { isTunnelView: true, progress: 60 },
  },

  {
    path: ROUTES.landlordRoutes.apartment.create.media.path,
    Component: ApartmentMediaFormView,
    props: { isTunnelView: true, progress: 70 },
  },
  {
    path: ROUTES.landlordRoutes.apartment.create.desiredTenant.path,
    Component: ApartmentDesiredTenantFormView,
    props: { isTunnelView: true, progress: 80 },
  },

  {
    path: ROUTES.landlordRoutes.apartment.create.contactPerson.path,
    Component: ApartmentContactPersonFormView,
    props: { isTunnelView: true, progress: 90 },
  },
  {
    path: ROUTES.landlordRoutes.apartment.create.overview.path,
    Component: ApartmentOverviewView,
    props: { isTunnelView: true, progress: 98 },
  },
];
