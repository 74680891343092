import { FC } from 'react';
import Tag, { ITag, TAG_COLOR } from '../../atoms/Tag';
import styles from './TagList.module.scss';

export interface ITagListProps {
  tags: ITag[];
  testId?: string;
  size?: 'small' | 'default';
  color?: TAG_COLOR;
}

const TagList: FC<ITagListProps> = ({ tags, testId, size, color = TAG_COLOR.DEFAULT }) => {
  return (
    <ul data-testid={`tagList.${testId}`} className={styles.tagList}>
      {tags.map((tag, index) => (
        <Tag element={'li'} color={color} size={size} key={index} tag={tag} />
      ))}
    </ul>
  );
};

export default TagList;
