import Headline, { HEADLINE_SIZE } from '../../../atoms/typographie/Headline';
import Text, { TEXT_COLOR } from '../../../atoms/typographie/Text';
import { FC } from 'react';
import { IImage } from '../../../../core/const/images';
import styles from './CollectionCard.module.scss';
import { useTranslation } from 'react-i18next';

export interface ICollectionCardProps {
  title: string;
  text?: string;
  img: IImage;
  onClick?: () => any;
}

const CollectionCard: FC<ICollectionCardProps> = ({ title, text, img, onClick }) => {
  const { t: i } = useTranslation('images');

  return (
    <button className={styles.button} onClick={onClick}>
      <div className={styles.wrapper}>
        <picture>
          <img
            className={styles.image}
            sizes="(max-width: 1380px) 100vw, 1380px"
            srcSet={`
              ${i(img.sm)} 360w,
              ${i(img.md)} 811w,
              ${i(img.lg)} 1122w,
              ${i(img.src)} 1380w`}
            src={`${i(img.src)}`}
            alt={i(img.alt)}
          />
        </picture>
        {/*<img className={styles.image} src={i(img.src)} alt={i(img.alt)} />*/}
        <Headline size={HEADLINE_SIZE.H2} align={'center'}>
          {title}
        </Headline>
        {text ? (
          <Text color={TEXT_COLOR.TEXT_COLOR_ACCENT} align={'center'}>
            {text}
          </Text>
        ) : null}
      </div>
    </button>
  );
};

export default CollectionCard;
