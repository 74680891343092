export const standardSvgAttrs = ({
  width,
  height,
  styleOverride = {},
  ariaHidden = false,
} = {}) => {
  return {
    x: '0px',
    y: '0px',
    viewBox: `0 0 ${width} ${height}`,
    'enable-background': `new 0 0 ${width} ${height}`,
    'aria-hidden': `${!!ariaHidden}`,
    style: {
      fill: '#ffffff',
      height: '100%',
      left: 0,
      strokeWidth: 0,
      top: 0,
      width: '100%',
      ...styleOverride,
    },
  };
};
