import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLightbulbOn } from '@fortawesome/pro-solid-svg-icons';
import styles from './InfoText.module.scss';
import { FC, PropsWithChildren } from 'react';

const InfoText: FC<PropsWithChildren<{ noMarginTop?: boolean }>> = ({ children, noMarginTop = false }) => {
  return (
    <div className={`${styles.wrapper} ${noMarginTop ? styles.noMarginTop : ''}`}>
      <div>
        <span className={styles.iconWrapper}>
          <FontAwesomeIcon icon={faLightbulbOn} size={'2x'} />
        </span>
      </div>
      {children}
    </div>
  );
};

export default InfoText;
