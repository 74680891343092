import { InputLabel, TextField } from '@mui/material';
import { Controller, ControllerRenderProps } from 'react-hook-form';
import { FC, useContext } from 'react';
import { FormContext } from 'core/context/form.context';
import { errorHandler } from 'component/atoms/formElement/FormErrorMessage';
import { useTranslation } from 'react-i18next';

interface ITextInputProps {
  disabled?: boolean;
  helperText?: string;
  label?: string;
  max?: number;
  min?: number;
  name: string;
  onBlurHandler?: (e: any) => void;
  onFocus?: (e: any) => void;
  pattern?: string;
  required?: boolean;
  tooltipText?: string;
  type?: 'tel' | 'text' | 'password' | 'email' | 'number';
  unit?: string;
  endAdornment?: any;
  placeholder?: string;
  showLabel?: boolean;
  id?: string;
}

const TextInput: FC<ITextInputProps> = ({
  disabled,
  label,
  max,
  min,
  placeholder,
  name,
  endAdornment,
  onBlurHandler,
  showLabel = true,
  onFocus,
  pattern,
  required,
  type = 'text',
  id = name,
}) => {
  const { control } = useContext(FormContext);
  const { t } = useTranslation('common', { keyPrefix: 'component.atoms.formElement.FormErrorMessage' });

  const onBlur = (e: any, field: ControllerRenderProps) => {
    field.onBlur();
    if (onBlurHandler) {
      onBlurHandler(e);
    }
  };

  const handleOnChange = (field: ControllerRenderProps, e: any) => {
    field.onChange(e.target.value);
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        return (
          <>
            {showLabel && (
              <InputLabel color={'secondary'} required={required}>
                {label}
              </InputLabel>
            )}

            <TextField
              hiddenLabel
              id={id}
              InputProps={{
                inputProps: { min, max, pattern },
                endAdornment: endAdornment && endAdornment,
              }}
              disabled={disabled}
              name={name}
              fullWidth
              /*keep empty for spacing*/
              error={!!error}
              helperText={error ? errorHandler(error, t) : ' '}
              type={type}
              placeholder={placeholder ?? label}
              size="small"
              onInput={(e) => handleOnChange(field, e)}
              /*@ts-ignore*/
              onWheel={type === 'number' ? (e) => e.target.blur() : null}
              required={required}
              variant={'outlined'}
              value={field?.value ? field.value : ''}
              onBlur={(e) => {
                onBlur(e, field);
              }}
              onFocus={() => {
                onFocus ? onFocus : null;
              }}
            />
          </>
        );
      }}
    />
  );
};

export default TextInput;
