import { Dispatch, FC, SetStateAction } from 'react';
import styles from '../../../../view/landing-page/LandingPageView/LandingPageView.module.scss';
import ToggleButton from '../../../atoms/ToggleButton';
import { USER_TYPE } from '@wohnsinn/ws-ts-lib';
import { Grid } from '@mui/material';
import ProSectionLandlord from './ProSectionLandlord';
import ProSectionTenant from './ProSectionTenant';

const ProSection: FC<{ userType: USER_TYPE; setUserType: Dispatch<SetStateAction<USER_TYPE>> }> = ({
  userType,
  setUserType,
}) => {
  const scrollToTopOfSection = () => {
    const element = document.getElementById('pro-section-parent');
    if (element) {
      const topPosition = element.offsetTop;
      window.scrollTo({
        top: topPosition,
        behavior: 'smooth',
      });
    }
  };

  return (
    <Grid
      item
      id={'pro-section-parent'}
      xs={12}
      className={`ws-full-width-bg ${userType === USER_TYPE.LANDLORD ? 'ws-full-width-bg--green' : ''}`}
      paddingTop={{ xs: 4, md: 10 }}
      paddingBottom={{ xs: 4, md: 10 }}
    >
      <div className={styles.stickyToggle}>
        <ToggleButton
          option2={{
            text: 'Für Mieter',
            onClick: () => {
              setUserType(USER_TYPE.TENANT);
              scrollToTopOfSection();
            },
            active: userType === USER_TYPE.TENANT,
          }}
          option1={{
            text: 'Für Vermieter',
            onClick: () => {
              setUserType(USER_TYPE.LANDLORD);
              scrollToTopOfSection();
            },
            active: userType === USER_TYPE.LANDLORD,
          }}
        />
      </div>
      {userType === USER_TYPE.TENANT ? <ProSectionTenant /> : <ProSectionLandlord />}
    </Grid>
  );
};

export default ProSection;
