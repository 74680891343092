import ModalWrapper from '../ModalWrapper';
import { FC, useContext } from 'react';
import ModalContext from 'core/context/modal.context';
import styles from './PublishApartmentModal.module.scss';
import Text from 'component/atoms/typographie/Text';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { wohnsinnServices } from 'App';
import SnackBarContext from 'core/context/snackbar.context';
import FB_FUNCTION_URLS from 'core/const/fb-function-names';
import UserContext from 'core/context/user.context';

const PublishApartmentModal: FC = () => {
  const { closeModal, modalData } = useContext(ModalContext);
  const { handleSnackBar } = useContext(SnackBarContext);
  const { apartmentService, firebaseFunctionsService } = wohnsinnServices;
  const { landlordProfile } = useContext(UserContext);
  const { t } = useTranslation('common');
  const { t: m } = useTranslation('common', { keyPrefix: 'component.molecules.modals.PublishApartmentModal' });
  const apartment = modalData.apartment;
  const queryClient = useQueryClient();

  const allApartmentDataAvailable =
    apartment?.mainInformation &&
    apartment?.energySupply &&
    apartment?.cost &&
    apartment?.desiredTenant &&
    apartment?.equipment &&
    apartment?.media?.length;

  const { mutate: updatePublishedState, isPending } = useMutation({
    mutationFn: () => apartmentService.updateApartmentPublishState(apartment, !apartment?.isPublished),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['apartments'] });
      handleSnackBar(m(!apartment?.isPublished ? 'toast.published.success' : 'toast.deactivate.success'), 'success');
      await firebaseFunctionsService.callFbFunction(FB_FUNCTION_URLS.apartments.publishedChangeNotification, {
        body: {
          apartments: [apartment],
          landlordProfile,
          isPublished: !apartment?.isPublished,
        },
      });
    },
    onError: () => handleSnackBar('toast.error.unknown', 'error'),
    onSettled: closeModal,
  });

  const MODAL_BUTTONS = {
    primary: {
      buttonText: m(
        apartment?.isPublished
          ? 'button.deactivate.label'
          : allApartmentDataAvailable
          ? 'button.activate.label'
          : 'button.complete.label'
      ),
      onClick: updatePublishedState,
      disabled: isPending,
    },
    third: {
      onClick: closeModal,
      buttonText: t('cancel'),
      disabled: isPending,
    },
  };

  return (
    <ModalWrapper
      buttons={MODAL_BUTTONS}
      title={m(
        apartment?.isPublished ? 'deactivate.title' : allApartmentDataAvailable ? 'activate.title' : 'unComplete.title'
      )}
      icon={allApartmentDataAvailable && !apartment?.isPublished ? t('pictogram.check') : t('pictogram.attention')}
    >
      <Text align={'center'} tag={'p'}>
        {m(
          apartment?.isPublished ? 'deactivate.text' : allApartmentDataAvailable ? 'activate.text' : 'unComplete.text'
        )}
      </Text>

      {!apartment?.isPublished && (
        <ul className={styles.list}>
          {!!!apartment?.mainInformation && (
            <li>
              <Text italic tag={'span'}>
                {m('mainInfo')}
              </Text>
            </li>
          )}
          {!!!apartment?.energySupply && (
            <li>
              <Text italic tag={'span'}>
                {m('energySupply')}
              </Text>
            </li>
          )}
          {!!!apartment?.cost && (
            <li>
              <Text italic tag={'span'}>
                {m('cost')}
              </Text>
            </li>
          )}
          {!!!apartment?.desiredTenant && (
            <li>
              <Text italic tag={'span'}>
                {m('desiredTenant')}
              </Text>
            </li>
          )}
          {!!!apartment?.equipment && (
            <li>
              <Text italic tag={'span'}>
                {m('equipment')}
              </Text>
            </li>
          )}
          {apartment?.media?.length === 0 && (
            <li>
              <Text italic tag={'span'}>
                {m('media')}
              </Text>
            </li>
          )}
        </ul>
      )}
    </ModalWrapper>
  );
};

export default PublishApartmentModal;
