import { useContext, useState } from 'react';
import { SUBMIT_BUTTON_MODE } from '../../../../../core/enum/submit-button-mode.enum';
import UserContext from '../../../../../core/context/user.context';
import { wohnsinnServices } from '../../../../../App';
import { useTranslation } from 'react-i18next';
import OptionList, { IOptionListItem } from '../../../../atoms/formElement/OptionList';
import { faBuildings, faUser } from '@fortawesome/pro-light-svg-icons';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { REGISTRATION_STATES, USER_TYPE } from '@wohnsinn/ws-ts-lib';
import { Grid } from '@mui/material';
import PageTitle from '../../../../molecules/PageTitle';
import { FormContext } from '../../../../../core/context/form.context';
import RegistrationLayout from '../../RegistrationLayout';
import { TEXT_COLOR } from 'component/atoms/typographie/Text';
import LOCAL_STORAGE_KEYS from 'core/enum/local-storage.enum';
import { ROUTES } from 'core/const/routes';

const SELECT_LANDLORD_TYPE_FORM_ID = 'select-landlord-type';
const CreateLandlordSelectTypeScreen = () => {
  const [buttonSubmitMode, setButtonSubmitMode] = useState<SUBMIT_BUTTON_MODE>(SUBMIT_BUTTON_MODE.NONE);
  const { user } = useContext(UserContext);
  const { userService, landlordService } = wohnsinnServices;
  const { t } = useTranslation('common', {
    keyPrefix: 'component.molecules.RegisterLandlordSteps.StepSelectLandlordType',
  });
  const { t: c } = useTranslation('common');
  const { t: r } = useTranslation('routes');

  const landLordTypeOptions: [IOptionListItem, IOptionListItem] = [
    {
      heading: t('options.commercial.heading'),
      text: t('options.commercial.text'),
      iconName: faBuildings,
      value: true,
    },
    {
      heading: t('options.private.heading'),
      text: t('options.private.text'),
      iconName: faUser,
      value: false,
    },
  ];

  const { control, handleSubmit, watch, formState } = useForm<{
    landlordType: boolean;
  }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: joiResolver(Joi.object({ landlordType: Joi.boolean().required() })),
  });

  const handleFormSubmit = async () => {
    setButtonSubmitMode(SUBMIT_BUTTON_MODE.SUBMITTING);

    const isCommercialLandlord = watch('landlordType');
    await landlordService.updateIsLandlordCommercial(user.uid, isCommercialLandlord);

    setTimeout(async () => {
      await userService.updateUserData({ registrationState: REGISTRATION_STATES.ADD_PROFILE }, user.uid);
    }, 500);
  };

  const cancelLandlordCreation = async () => {
    try {
      setTimeout(async () => {
        localStorage.setItem(LOCAL_STORAGE_KEYS.REDIRECT_URL, r(ROUTES.userRoutes.settings.path));
        await userService.updateUserData(
          { activeUserType: USER_TYPE.TENANT, isLandlord: false, registrationState: REGISTRATION_STATES.FINISHED },
          user.uid
        );
      }, 500);
    } catch (e) {
      console.error('Error on cancelOrganizationCreation: ', e);
    }
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <PageTitle
          pageTitle={'Was beschreibt dich am besten?'}
          secondPageTitle={'Wähle aus ob du professioneller Makler oder privater Vermieter bist'}
        />
        <RegistrationLayout
          buttonSubmitMode={buttonSubmitMode}
          isValidForm={formState.isValid}
          formId={SELECT_LANDLORD_TYPE_FORM_ID}
          secondaryButtonText={user?.isTenant ? c('cancel') : null}
          secondaryButtonAction={user?.isTenant ? () => cancelLandlordCreation() : null}
          secondaryButtonColor={user?.isTenant ? TEXT_COLOR.TEXT_COLOR_DANGER : null}
        >
          <FormContext.Provider value={{ control }}>
            <form id={SELECT_LANDLORD_TYPE_FORM_ID} onSubmit={handleSubmit(handleFormSubmit, console.error)}>
              <OptionList optionsList={landLordTypeOptions} name={'landlordType'} />
            </form>
          </FormContext.Provider>
        </RegistrationLayout>
      </Grid>
    </Grid>
  );
};

export default CreateLandlordSelectTypeScreen;
