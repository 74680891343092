import { getSubmitIcon } from 'component/atoms/Buttons/CTAButton';
import ModalWrapper from '../../ModalWrapper';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import LOCAL_STORAGE_KEYS from 'core/enum/local-storage.enum';
import UserContext from 'core/context/user.context';
import { FC, useContext, useEffect, useState } from 'react';
import { MODAL_IDENTIFIER } from 'core/enum/modals.enum';
import TextInput from 'component/atoms/formElement/InputText';
import { FormContext } from 'core/context/form.context';
import ModalContext from 'core/context/modal.context';
import { useNavigate } from 'react-router-dom';

import { SUBMIT_BUTTON_MODE } from 'core/enum/submit-button-mode.enum';
import { FirebaseError } from 'firebase/app';
import { joiResolver } from '@hookform/resolvers/joi';
import { EMAIL_SCHEMA } from '@wohnsinn/ws-ts-lib';
import Joi from 'joi';
import { wohnsinnServices } from 'App';
import SnackBarContext from 'core/context/snackbar.context';
import { TEXT_COLOR } from 'component/atoms/typographie/Text';

const ResetPasswordModal: FC = () => {
  const { t } = useTranslation('common');
  const { t: m } = useTranslation('common', { keyPrefix: 'component.molecules.modals.signIn.ResetPasswordModal' });
  const { firebaseAuthService } = wohnsinnServices;
  const { user } = useContext(UserContext);
  const { openModal, closeModal } = useContext(ModalContext);
  const [buttonSubmitMode, setButtonSubmitMode] = useState<SUBMIT_BUTTON_MODE>(SUBMIT_BUTTON_MODE.NONE);
  const { handleSnackBar } = useContext(SnackBarContext);
  const navigate = useNavigate();

  const { control, handleSubmit, watch } = useForm<{ email: string }>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    defaultValues: { email: localStorage.getItem(LOCAL_STORAGE_KEYS.EMAIL) ?? '' },
    resolver: joiResolver(Joi.object({ email: EMAIL_SCHEMA })),
  });

  const email = watch('email');
  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_KEYS.EMAIL, email);
  }, [email]);

  useEffect(() => {
    if (user) {
      navigate('/');
      closeModal();
    }
  }, [user]);

  const resetPassword = async (email: string): Promise<boolean | void> => {
    setButtonSubmitMode(SUBMIT_BUTTON_MODE.SUBMITTING);
    try {
      await firebaseAuthService.sendPasswordResetMail(email);
      handleSnackBar(m('successMessage'), 'error');
      setButtonSubmitMode(SUBMIT_BUTTON_MODE.SUCCESS);
      setTimeout(() => {
        openModal({ id: MODAL_IDENTIFIER.SIGN_IN });
      }, 500);
    } catch (e: any) {
      console.error('Error on resetPassword', e);
      const error = e as FirebaseError;
      setButtonSubmitMode(SUBMIT_BUTTON_MODE.ERROR);

      if (error.code.includes('user-not-found')) {
        handleSnackBar(t('toast.signIn.givenEmailAccountDoesNotExist').replace('{{email}}', email), 'info');
      } else {
        handleSnackBar('toast.error.unknown', 'error');
      }
    }
  };

  return (
    <ModalWrapper
      title={m('title')}
      icon={t('logo.black.logoSrc')}
      buttons={{
        primary: {
          form: 'passwordReset',
          spinIcon: buttonSubmitMode === SUBMIT_BUTTON_MODE.SUBMITTING,
          icon: getSubmitIcon(buttonSubmitMode),
          buttonText: m('button'),
        },
        third: {
          type: 'button',
          buttonText: m('login'),
          color: TEXT_COLOR.TEXT_COLOR_PRIMARY,
          onClick: () => openModal({ id: MODAL_IDENTIFIER.SIGN_IN }),
        },
      }}
    >
      <form
        autoComplete={'off'}
        id={'passwordReset'}
        onSubmit={handleSubmit((data: { email: string }) => resetPassword(data.email), console.error)}
      >
        <FormContext.Provider value={{ control }}>
          <TextInput label={t('email.label')} name={'email'} type={'email'} required />
          <button className={'hidden'}>submit</button>
        </FormContext.Provider>
      </form>
    </ModalWrapper>
  );
};

export default ResetPasswordModal;
