import AdminApartmentManagement from '../../../view/admin/AdminApartmentManagement';
import AdminOverviewView from '../../../view/admin/AdminOverviewView';
import AdminUserManagement from '../../../view/admin/AdminUserManagement';
import { IProtectedRouteProps } from '../../../AppRouter';
import { ROUTES } from '../routes';

// routes only admins can see
export const ADMIN_ROUTES: IProtectedRouteProps[] = [
  { path: ROUTES.adminRoutes.apartments.path, Component: AdminApartmentManagement },
  { path: ROUTES.adminRoutes.overview.path, Component: AdminOverviewView },
  { path: ROUTES.adminRoutes.users.path, Component: AdminUserManagement },
];
