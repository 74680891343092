import { useTranslation } from 'react-i18next';
import ModalWrapper, { IModalWrapperButtonProps } from '../ModalWrapper';
import ModalContext from 'core/context/modal.context';
import { useContext } from 'react';
import { ROUTES } from 'core/const/routes';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import CompletedFormCheck from 'component/atoms/CompletedFormCheck';
import UserContext from 'core/context/user.context';

const CompleteDocumentFolderModal = () => {
  const { closeModal } = useContext(ModalContext);
  const navigate = useNavigate();
  const { tenantProfile } = useContext(UserContext);
  const { t: c } = useTranslation('common');
  const { t: r } = useTranslation('routes');

  const handleNextStep = () => {
    closeModal();
    if (!tenantProfile?.hasSchufaDocs) {
      navigate(r(ROUTES.tenantRoutes.account.applicationFolder.schufa));
    } else if (!tenantProfile?.hasIncomeProofDocs) {
      navigate(r(ROUTES.tenantRoutes.account.applicationFolder.incomeProof));
    }
  };

  const MODAL_BUTTONS: IModalWrapperButtonProps = {
    primary: {
      buttonText: c('upload.documents'),
      onClick: handleNextStep,
    },
    third: {
      buttonText: c('cancel'),
      onClick: closeModal,
    },
  };

  return (
    <ModalWrapper title={c('upload.documents.title')} buttons={MODAL_BUTTONS} subtitle={c('upload.documents.subTitle')}>
      <Grid container spacing={1}>
        {!tenantProfile?.hasSchufaDocs ? (
          <Grid item xs={12}>
            <CompletedFormCheck
              status={tenantProfile?.hasSchufaDocs}
              positiveText={c('uploaded')}
              negativeText={c('upload')}
              text={r(ROUTES.tenantRoutes.account.schufa.title)}
            />
          </Grid>
        ) : null}
        {!tenantProfile?.hasIncomeProofDocs ? (
          <Grid item xs={12}>
            <CompletedFormCheck
              status={tenantProfile?.hasIncomeProofDocs}
              positiveText={c('uploaded')}
              negativeText={c('upload')}
              text={r(ROUTES.tenantRoutes.account.incomeProof.title)}
            />
          </Grid>
        ) : null}
      </Grid>
    </ModalWrapper>
  );
};

export default CompleteDocumentFolderModal;
