import PageLayout from '../../../component/layouts/PageLayout';
import { ROUTES } from '../../../core/const/routes';
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import UserContext from '../../../core/context/user.context';
import { faBuilding, faPerson, faShareNodes, faUsers } from '@fortawesome/pro-light-svg-icons';
import InfoBox from '../../../component/atoms/InfoBox';
import styles from '../../tenant/account/AccountOverviewView/AccountOverviewView.module.scss';
import ActionBanner from '../../../component/atoms/ActionBanner';
import LandlordProfileForm from '../../../component/forms/landlord/LandlordProfileForm';
import LandlordOrganizationProfileForm from '../../../component/forms/landlord/LandlordOrganizationProfileForm';
import FormTabs, { IFormTab } from '../../../component/organisms/FormTabs';
import LandlordUserManagementForm from '../../../component/forms/landlord/LandlordUserManagementForm';
import LandlordInterfacesForm from '../../../component/forms/landlord/LandlordInterfacesForm';
import LandlordCreateOrganizationForm from '../../../component/forms/landlord/LandlordCreateOrganizationForm';
import scrollTo from '../../../core/helper/scroll-to';
import { LANDLORD_ROLES } from '@wohnsinn/ws-ts-lib';
import { wohnsinnServices } from '../../../App';
import SnackBarContext from '../../../core/context/snackbar.context';

export interface ILandlordAccountFormProps {
  scrollToTop: () => void;
}

export enum LANDLORD_FORMS {
  CUSTOMER = 'CUSTOMER',
  ORGANIZATION = 'ORGANIZATION',
  USER_MANAGEMENT = 'USER_MANAGEMENT',
  INTERFACE = 'INTERFACE',
  CREATE_ORGANIZATION = 'CREATE_ORGANIZATION',
}

const AccountView = () => {
  const { firebaseAuthService } = wohnsinnServices;
  const { user, tenantProfile, landlordProfile, activeUserType } = useContext(UserContext);
  const { t: r } = useTranslation('routes');
  const { t } = useTranslation('common');
  const { handleSnackBar } = useContext(SnackBarContext);
  const [activeTab, setActiveTab] = useState<LANDLORD_FORMS>(LANDLORD_FORMS.CUSTOMER);

  const FORM_TABS: IFormTab[] = [{ icon: faPerson, label: 'Kundendaten', value: LANDLORD_FORMS.CUSTOMER }];

  if (!landlordProfile?.isCommercialLandlord) {
    FORM_TABS.push({ icon: faBuilding, label: 'Firma erstellen', value: LANDLORD_FORMS.CREATE_ORGANIZATION });
  } else {
    FORM_TABS.push({ icon: faBuilding, label: 'Firmendaten', value: LANDLORD_FORMS.ORGANIZATION });

    if (landlordProfile?.roles && landlordProfile?.roles?.includes(LANDLORD_ROLES.ORGANIZATION_ADMIN)) {
      FORM_TABS.push({ icon: faUsers, label: 'Nutzerverwaltung', value: LANDLORD_FORMS.USER_MANAGEMENT });
    }
    FORM_TABS.push({ icon: faShareNodes, label: 'Schnittstelle', value: LANDLORD_FORMS.INTERFACE });
  }

  const resendVerificationMail = async (): Promise<void> => {
    try {
      await firebaseAuthService.triggerSendVerificationLink(tenantProfile.email, activeUserType);
      handleSnackBar('toast.resendEmailVerificationMail', 'info');
    } catch (e) {
      console.error('Error on resendVerificationMail', e);
    }
  };

  const renderSelectedForm = () => {
    switch (activeTab) {
      case LANDLORD_FORMS.CUSTOMER:
        return <LandlordProfileForm scrollToTop={() => scrollTo()} />;
      case LANDLORD_FORMS.ORGANIZATION:
        return <LandlordOrganizationProfileForm scrollToTop={() => scrollTo()} />;
      case LANDLORD_FORMS.USER_MANAGEMENT:
        return <LandlordUserManagementForm />;
      case LANDLORD_FORMS.INTERFACE:
        return <LandlordInterfacesForm />;
      case LANDLORD_FORMS.CREATE_ORGANIZATION:
        return <LandlordCreateOrganizationForm />;
      default:
        return <LandlordProfileForm scrollToTop={() => scrollTo()} />;
    }
  };

  return (
    <PageLayout
      pageTitle={r(ROUTES.landlordRoutes.account.title)}
      secondPageTitle={'Hier siehst du dein Vermieterprofil'}
    >
      {!user.isEmailVerified ? (
        <>
          <div className={styles.banner}>
            <ActionBanner
              label={t('actionBanner.verifyYourEmail.label')}
              buttonText={t('actionBanner.verifyYourEmail.buttonText')}
              onClick={resendVerificationMail}
            />
          </div>
          <div className={styles.bannerSpace} />
        </>
      ) : null}

      {landlordProfile?.isCommercialLandlord && !landlordProfile?.isOrganizationMembershipConfirmed && (
        <InfoBox text={t('view.AccountOverviewView.infobox')} />
      )}

      <FormTabs tabs={FORM_TABS} activeTab={activeTab} setActiveTab={setActiveTab}>
        {renderSelectedForm()}
      </FormTabs>
    </PageLayout>
  );
};

export default AccountView;
