import { FC } from 'react';
import styles from './DownloadItemsList.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/pro-solid-svg-icons';
import { IUpload } from '@wohnsinn/ws-ts-lib';
import Text, { TEXT_COLOR, TEXT_WEIGHT } from '../../atoms/typographie/Text';
import { wohnsinnServices } from '../../../App';

const DownloadItemsList: FC<{ list: IUpload[]; label: string }> = ({ list, label }) => {
  const { firebaseStorageService } = wohnsinnServices;
  const downloadDocument = (upload: IUpload) => {
    const windowReference = window.open();
    firebaseStorageService.getDownloadUrl(`${upload.uploadPath}`).then((href) => {
      windowReference.location = href;
    });
  };

  return (
    <div className={styles.wrapper}>
      {list.map((upload, i) => (
        <div key={`incomeProof-${i}`} className={styles.item} onClick={() => downloadDocument(upload)}>
          <div className={styles.label}>
            <Text weight={TEXT_WEIGHT.TEXT_WEIGHT_LIGHT} color={TEXT_COLOR.TEXT_COLOR_ACCENT}>
              {`${label} Nr. ${i + 1}`}
            </Text>
          </div>
          <span className={styles.download} slot={'end'}>
            <FontAwesomeIcon icon={faDownload} />
          </span>
        </div>
      ))}
    </div>
  );
};

export default DownloadItemsList;
