import { useContext, useEffect, useState } from 'react';
import { IApplication, MATCHING_MODE, USER_TYPE } from '@wohnsinn/ws-ts-lib';
import { onSnapshot, Unsubscribe } from 'firebase/firestore';
import UserContext from '../context/user.context';
import { wohnsinnServices } from '../../App';

export interface ITenantApplicationList {
  applicationListLike: IApplication[];
  applicationListMaybe: IApplication[];
  applicationListNope: IApplication[];
  unreadChatNotifications: number;
  isLoading: boolean;
}

function useTenantApplicationListHook(): ITenantApplicationList {
  const matchingMode = [MATCHING_MODE.LIKE, MATCHING_MODE.NOPE, MATCHING_MODE.MAYBE];
  const { user } = useContext(UserContext);
  const { applicationService } = wohnsinnServices;
  const [applicationListNope, setApplicationListNope] = useState<IApplication[]>([]);
  const [applicationListLike, setApplicationListLike] = useState<IApplication[]>([]);
  const [applicationListMaybe, setApplicationListMaybe] = useState<IApplication[]>([]);
  const [unreadChatNotifications, setUnreadChatNotifications] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    setIsLoading(true);
    let unsubscribe: Unsubscribe;
    if (user && user?.activeUserType === USER_TYPE.TENANT) {
      const applicationRef = applicationService.getTenantApplicationListRef({ tenantId: user.uid, matchingMode });

      unsubscribe = onSnapshot(
        applicationRef,
        (snap) => {
          let unreadChatNotifications = 0;
          const applicationListLike: IApplication[] = [];
          const applicationListMaybe: IApplication[] = [];
          const applicationListNope: IApplication[] = [];

          snap.forEach((doc) => {
            const application = doc.data();

            if (application.rating === MATCHING_MODE.LIKE) {
              applicationListLike.push(application);
              if (application?.unreadLandlordMessagesRef?.length) {
                unreadChatNotifications += application.unreadLandlordMessagesRef.length;
              }
            }

            if (application.rating === MATCHING_MODE.MAYBE) {
              applicationListMaybe.push(application);
            }

            if (application.rating === MATCHING_MODE.NOPE) {
              applicationListNope.push(application);
            }
          });

          setApplicationListLike(applicationListLike);
          setApplicationListMaybe(applicationListMaybe);
          setApplicationListNope(applicationListNope);
          setUnreadChatNotifications(unreadChatNotifications);
        },
        (error) => {
          console.error(error);
        }
      );
    } else {
      setApplicationListLike([]);
      setApplicationListMaybe([]);
      setApplicationListNope([]);
      setUnreadChatNotifications(0);
    }
    setIsLoading(false);

    return unsubscribe;
  }, [user]);

  return {
    applicationListLike,
    applicationListMaybe,
    applicationListNope,
    unreadChatNotifications,
    isLoading,
  };
}

export default useTenantApplicationListHook;
