import { IApartmentFreeTexts } from '@wohnsinn/ws-ts-lib';
import { SchemaOf } from './cost';
import Joi from 'joi';

export const APARTMENT_FREE_TEXTS_SCHEMA = <SchemaOf<IApartmentFreeTexts>>{
  generellDescription: Joi.any(),
  location: Joi.any(),
  other: Joi.any(),
  equipment: Joi.string(),
};
