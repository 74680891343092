import { FC, useContext, useState } from 'react';
import { Controller } from 'react-hook-form';
import { FormContext } from '../../../../core/context/form.context';
import { Slider as MuiSlider } from '@mui/material';
import { ControllerRenderProps } from 'react-hook-form/dist/types/controller';
import FormErrorMessage from '../FormErrorMessage';

const Slider: FC<{ defaultValue?: number; name: string; max: number; min?: number; step?: number }> = ({
  defaultValue,
  name,
  max,
  step = 1,
  min = 1,
}) => {
  const [value, setValue] = useState(defaultValue);
  const { control } = useContext(FormContext);

  const handleValue = (event: Event, newValue: number | number[], field: ControllerRenderProps) => {
    setValue(newValue as number);

    field.onChange(newValue);
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        return (
          <div style={{ paddingLeft: 24, paddingRight: 24 }}>
            <MuiSlider
              name={name}
              value={value}
              min={min}
              step={step}
              max={max}
              onChange={(e, newValue) => handleValue(e, newValue, field)}
            />
            <FormErrorMessage error={error} />
          </div>
        );
      }}
    />
  );
};

export default Slider;
