import styles from './BackButtonStyles.module.scss';
import { FC } from 'react';
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from '@mui/material';

const BackButton: FC<{ backRoute?: string }> = ({ backRoute }) => {
  const navigate = useNavigate();
  return (
    <IconButton onClick={backRoute ? () => navigate(backRoute) : () => navigate(-1)} className={styles.backButton}>
      <FontAwesomeIcon icon={faChevronLeft} size={'1x'} />
    </IconButton>
  );
};
export default BackButton;
