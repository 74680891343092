import styles from '../HowToSlides.module.scss';
import Headline from '../../../atoms/typographie/Headline';
import Text, { TEXT_COLOR, TEXT_VARIANT } from '../../../atoms/typographie/Text';
import { FC, PropsWithChildren } from 'react';

const HowToSlideWrapper: FC<PropsWithChildren<{ title: string; subtitle: string }>> = ({
  title,
  subtitle,
  children,
}) => {
  return (
    <div className={styles.howToSlide}>
      <div className={'swiper-no-swiping'}>
        <div>
          <Headline align={'left'}>{title}</Headline>
          <Text variant={TEXT_VARIANT.TEXT_VARIANT_SMALL} color={TEXT_COLOR.TEXT_COLOR_ACCENT} align={'left'}>
            {subtitle}
          </Text>
        </div>
        {children}
      </div>
    </div>
  );
};

export default HowToSlideWrapper;
