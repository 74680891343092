import HeroSection from 'component/organisms/landing-page-sections/HeroSection';
import LandingAboutWohnsinnSection from 'component/organisms/landing-page-sections/AboutWohnsinnSection';
import PageLayout from 'component/layouts/PageLayout';
import Text, { TEXT_COLOR, TEXT_TYPE } from 'component/atoms/typographie/Text';
import { Grid } from '@mui/material';
import { IMAGES } from 'core/const/images';
import { ROUTES } from 'core/const/routes';
import { useTranslation } from 'react-i18next';
import LandlordProSection from 'component/organisms/landing-page-sections/LandlordProSection';
import WohnsinnExplainedSection from 'component/organisms/landing-page-sections/WohnsinnExplainedSection';
import TextBlockSection from 'component/molecules/TextBlockSection';
import { HEADLINE_SIZE } from 'component/atoms/typographie/Headline';
import Spacer, { SPACER_SIZE } from 'component/atoms/Spacer';
import CheckList from 'component/atoms/CheckList';
import { REGISTRATION_REFERRER } from '@wohnsinn/ws-ts-lib';
import ContactSection from 'component/organisms/landing-page-sections/ContactSection';
import KnownFromSection from 'component/organisms/landing-page-sections/KnownFromSection';

const HuGLandingPageView = () => {
  const { t: i } = useTranslation('images');
  const { t: r } = useTranslation('routes');
  const { t: a } = useTranslation('common', { keyPrefix: 'view.general.AboutUsView' });

  return (
    <PageLayout
      referrer={REGISTRATION_REFERRER.HUG_BONN}
      showStaticMenu
      showPageTitle={false}
      pageTitle={r(ROUTES.landingPageRoutes.tenant.title)}
    >
      <Grid container justifyContent={'center'} alignItems={'center'} rowSpacing={{ xs: 8, md: 12 }}>
        <Grid item xs={12} paddingTop={{ xs: 4, md: 10 }} paddingBottom={{ xs: 4, md: 10 }}>
          <HeroSection
            ctaText={'Kostenlos inserieren'}
            ctaLink={`${r(ROUTES.staticRoutes.registration.createRegistration.referrer).replace(
              ':referrer',
              REGISTRATION_REFERRER.HUG_BONN
            )}`}
            title={'Finde deinen'}
            titleTwo={'perfekten Mieter!'}
            subTitle={'Mietersuche war nie leichter - vermiete schnell & stressfrei.'}
            image={IMAGES.images.hugXws}
          />
        </Grid>

        <Grid
          item
          xs={12}
          className={'ws-full-width-bg ws-full-width-bg--white'}
          paddingTop={{ xs: 4, md: 10 }}
          paddingBottom={{ xs: 4, md: 10 }}
        >
          <Text type={TEXT_TYPE.TEXT_TYPE_BODY} color={TEXT_COLOR.TEXT_COLOR_ACCENT} align={'center'}>
            {a('customers.smallHeadline')}
          </Text>
          <KnownFromSection />
        </Grid>

        <Grid
          item
          xs={12}
          className={'ws-full-width-bg ws-full-width-bg--tertiary'}
          paddingTop={{ xs: 4, md: 10 }}
          paddingBottom={{ xs: 4, md: 10 }}
        >
          <Grid container alignItems={'center'} paddingTop={12} rowSpacing={4} columnSpacing={24}>
            <Grid item xs={12} sm={6} order={{ xs: 2, md: 1 }}>
              <img
                src={'/assets/images/landlord_hero/Vermieter_Hero_peyxsm_c_scale,w_1200.png'}
                alt={i(IMAGES.images.hugXws.alt)}
              />
            </Grid>
            <Grid item xs={12} sm={6} order={{ xs: 1, md: 2 }}>
              <TextBlockSection
                icon={IMAGES.pictograms.speech}
                headlineSize={HEADLINE_SIZE.H2}
                title={'Deine Vorteile der Kooperation'}
              >
                <Spacer size={SPACER_SIZE.MD} />

                <Text tag={'span'} color={TEXT_COLOR.TEXT_COLOR_ACCENT} type={TEXT_TYPE.TEXT_TYPE_LANDING}>
                  Wohnsinn ist unser neuer Partner, der bei der Wohnungsvermietung unterstützt.
                </Text>
              </TextBlockSection>

              <Spacer size={SPACER_SIZE.LG} />

              <CheckList accentColor items={ProCooperation} />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} paddingTop={{ xs: 4, md: 10 }} paddingBottom={{ xs: 4, md: 10 }}>
          <LandlordProSection />
        </Grid>

        <Grid
          item
          xs={12}
          className={'ws-full-width-bg ws-full-width-bg--tertiary'}
          paddingTop={{ xs: 4, md: 10 }}
          paddingBottom={{ xs: 4, md: 10 }}
        >
          <WohnsinnExplainedSection />
        </Grid>

        <Grid item xs={12} paddingTop={{ xs: 4, md: 10 }} paddingBottom={{ xs: 4, md: 10 }}>
          <LandingAboutWohnsinnSection />
        </Grid>

        <Grid item xs={12} paddingTop={{ xs: 4, md: 10 }} paddingBottom={{ xs: 4, md: 10 }}>
          <ContactSection />
        </Grid>
      </Grid>
    </PageLayout>
  );
};

const ProCooperation = [
  {
    text: '100% kostenlos für Mitglieder des Haus & Grund Bonn / Rhein-Sieg',
    bold: false,
    invert: false,
  },
  {
    text: 'Alle Daten sind sicher und datenschutzkonform gespeichert',
    bold: false,
    invert: false,
  },
  {
    text: 'Weniger Stress mit der Vermietung mit Wohnsinn',
    bold: false,
    invert: false,
  },
];

export default HuGLandingPageView;
