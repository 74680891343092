import ReactQuill from 'react-quill';
import { FC } from 'react';
import 'react-quill/dist/quill.snow.css';
import styles from './QuillEditor.module.scss';
import { TEXT_EDITOR_SIZE } from '../TextEditor';

const MODULES = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['link', 'image'],
  ],
};

const changeHandler = (e: string, field: any) => {
  const text = e;
  // Create a div element to use the browser's HTML parsing to strip tags
  const div = document.createElement('div');
  div.innerHTML = text;
  const strippedText = div.textContent || div.innerText || '';

  if (strippedText.trim() === '') {
    return field.onChange(null);
  }
  field.onChange(e);
};

const QuillEditor: FC<{
  hideToolbar?: boolean;
  field: any;
  placeholder: string;
  size: TEXT_EDITOR_SIZE;
}> = ({ field, placeholder, size = TEXT_EDITOR_SIZE.SMALL, hideToolbar = false }) => {
  return (
    <ReactQuill
      modules={hideToolbar ? { toolbar: null } : MODULES}
      className={`${styles.wrapper} ${styles[size]} ${hideToolbar ? styles.noMargin : ''}`}
      placeholder={placeholder}
      theme="snow"
      value={field.value}
      onChange={(e) => changeHandler(e, field)}
    />
  );
};

export default QuillEditor;
