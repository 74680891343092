import { FileRejection } from 'react-dropzone';
import { FC, useContext, useEffect, useState } from 'react';
import { FILE_UPLOAD_STATUS, IUpload, IVideoUpload, MEDIA_TYPE, TFileUploadParam } from '@wohnsinn/ws-ts-lib';
import { getDownloadURL } from 'firebase/storage';
import UserContext from '../../../core/context/user.context';
import { UPLOAD_TYPE } from '../../molecules/DocumentUploadDropZone';
import { useTranslation } from 'react-i18next';
import MediaUploadButton from '../../molecules/MediaUploadButton';
import CTAButton from '../../atoms/Buttons/CTAButton';
import { wohnsinnServices } from '../../../App';
import uuid4 from 'uuid4';

const IntroductionVideoUpload: FC = () => {
  const { tenantProfile } = useContext(UserContext);
  const { firebaseStorageService, tenantService } = wohnsinnServices;
  const [videoUpload, setVideoUpload] = useState<IUpload>(null);
  const [isVideoUploading, setIsVideoUploading] = useState<boolean>(false);
  const [uploadPercentage, setUploadPercentage] = useState<number>(0);
  const { t: a } = useTranslation('common', { keyPrefix: 'view.ApartmentOverviewView' });

  const onDrop = async (acceptedFiles: File[], rejectedFiles: FileRejection[]): Promise<void> => {
    if (acceptedFiles.length) {
      try {
        const videoFile = acceptedFiles[0];
        const id = uuid4();
        const fileUpload: IUpload = await firebaseStorageService.createFileUpload(videoFile, {
          creatorId: tenantProfile.uid,
          mediaType: MEDIA_TYPE.VIDEO,
          uploadType: UPLOAD_TYPE.INTRODUCTION_VIDEO,
          id,
        });
        setVideoUpload(fileUpload);
      } catch (e) {
        // SEND TOAST
        console.error(e);
      }
    }

    if (rejectedFiles?.length) {
      // SEND TOAST
      console.error('no accepted files');
    }
  };

  const changeUploadFileField = (
    parameter: TFileUploadParam,
    value: File | FILE_UPLOAD_STATUS | string | number
  ): void => {
    const newVideoUpload: IUpload = videoUpload;
    // @ts-ignore
    newVideoUpload[parameter as string] = value;
    setVideoUpload(newVideoUpload);
  };

  const applyUserDocumentStateListener = (upload: IUpload): void => {
    // SET UPLOAD PROGRESS
    changeUploadFileField('status', FILE_UPLOAD_STATUS.UPLOADING);
    upload.uploadTask.on(
      'state_changed',
      (snapshot: { bytesTransferred: number; totalBytes: number }) => {
        setUploadPercentage((snapshot.bytesTransferred / snapshot.totalBytes) * 100); // SET UPLOAD PROGRESS
        // TODO: Prevent page leave when download not finished
      },
      (err) => {
        console.error('Error state_change uploadTask', err.message);
      },
      async () => {
        const downloadURL = await getDownloadURL(firebaseStorageService.getRef(upload.uploadPath));
        changeUploadFileField('url', downloadURL);
        changeUploadFileField('status', FILE_UPLOAD_STATUS.DONE);
        const firstName = tenantProfile?.personalInformation?.firstName;
        const lastName = tenantProfile?.personalInformation?.lastName;
        const introductionVideo: IVideoUpload = {
          alt: `Vorstellungsvideo ${firstName ? firstName : ''} ${lastName ? lastName : ''}`,
          creatorId: tenantProfile.uid,
          extension: upload.extension,
          fileName: upload.fileName,
          mediaType: upload.mediaType,
          id: upload.id,
          uploadPath: upload.uploadPath,
          updatedAt: new Date(),
          sdVideoRef: null,
          fhdVideoRef: null,
          hdVideoRef: null,
          url: downloadURL,
        };

        await tenantService.updateTenantIntroductionVideo(tenantProfile.uid, introductionVideo).catch(console.error);
        setIsVideoUploading(false);
      }
    );
  };

  useEffect(() => {
    if (videoUpload?.status === FILE_UPLOAD_STATUS.CREATED) {
      setIsVideoUploading(true);
      applyUserDocumentStateListener(videoUpload);
    }
  }, [videoUpload]);

  return (
    <>
      {isVideoUploading ? (
        <CTAButton mobileNotRounded buttonText={`${a('loading')} ${uploadPercentage.toFixed(2)}%`} />
      ) : (
        <>
          <MediaUploadButton onDrop={onDrop} uploadType={UPLOAD_TYPE.INTRODUCTION_VIDEO} multiple={false} />
        </>
      )}
    </>
  );
};

export default IntroductionVideoUpload;
