import LandlordServices from '../../../component/organisms/LandlordServices';
import ModalContext from '../../../core/context/modal.context';
import PageLayout from '../../../component/layouts/PageLayout';
import TenantServices from '../../../component/organisms/TenantServices';
import UserContext from '../../../core/context/user.context';
import { FC, useContext, useEffect } from 'react';
import { Grid } from '@mui/material';
import { MODAL_IDENTIFIER } from '../../../core/enum/modals.enum';
import { ROUTES } from '../../../core/const/routes';
import { USER_TYPE } from '@wohnsinn/ws-ts-lib';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ServicesView: FC = () => {
  const { t: r } = useTranslation('routes');
  const { t } = useTranslation('common');
  const { activeUserType, user } = useContext(UserContext);
  const { openModal } = useContext(ModalContext);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === t('routes.signIn.path') && !!!user) {
      openModal({ id: MODAL_IDENTIFIER.SIGN_IN, allowBackdropDismiss: false });
    }

    if (location.pathname === t('routes.register.path') && !!!user) {
      navigate(r(ROUTES.staticRoutes.registration.createRegistration.path));
    }
  }, [location]);

  return (
    <PageLayout pageTitle={r(ROUTES.staticRoutes.services.title)}>
      <Grid container spacing={2}>
        {activeUserType === USER_TYPE.LANDLORD && user.isLandlord ? <LandlordServices /> : <TenantServices />}
      </Grid>
    </PageLayout>
  );
};

export default ServicesView;
