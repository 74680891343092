import {
  FIRESTORE_COLLECTION_PATH,
  IApartment,
  IApplication,
  IFirestoreQueryParams,
  IGetLandlordApplicationListQueryParams,
  IGetTenantApplicationListQueryParams,
  ITenantProfile,
  IWhereFilterArguments,
  MATCHING_MODE,
  TApplicationCreate,
  USER_TYPE,
} from '@wohnsinn/ws-ts-lib';
import 'firebase/firestore';
import { arrayRemove, arrayUnion, Query } from 'firebase/firestore';
import FirestoreService from './firestore.service';
import { ChatService } from './chat.service';

export interface IGetLandlordApplicationListQueryParamsWithRating extends IGetLandlordApplicationListQueryParams {
  rating?: MATCHING_MODE;
  isAdmin?: boolean;
}

class ApplicationService {
  constructor(private readonly firestoreService: FirestoreService, private readonly chatService: ChatService) {}

  /**
   * Create or update an application rating by tenant
   * @param apartment
   * @param tenantProfile
   * @param rating
   * @param createNewRating
   */
  public async handleRating(
    apartment: IApartment,
    tenantProfile: ITenantProfile,
    rating: MATCHING_MODE,
    createNewRating: boolean
  ) {
    const apartmentPath = `${FIRESTORE_COLLECTION_PATH.users.landlordProfiles.apartments.root
      .replace('{uid}', apartment.creatorId)
      .replace('{landlordId}', apartment.creatorId)}/${apartment.id}`;
    const applicationPath = `${apartmentPath}/applications/${tenantProfile.uid}`;
    const application = this.parseApplication(apartment, tenantProfile, rating);

    // Check if is a rating update or a new application
    const data = createNewRating ? application : { rating: application.rating };

    await this.firestoreService.setDbDoc(data, applicationPath, true, {
      setCreatedAt: createNewRating,
      setUpdatedAt: true,
    });
  }

  /**
   * Create tenant application
   * @param apartment
   * @param tenantProfile
   * @param rating
   */
  public async createApplication(apartment: IApartment, tenantProfile: ITenantProfile, rating: MATCHING_MODE) {
    const apartmentPath = `${FIRESTORE_COLLECTION_PATH.users.landlordProfiles.apartments.root
      .replace('{uid}', apartment.creatorId)
      .replace('{landlordId}', apartment.creatorId)}/${apartment.id}`;
    const applicationPath = `${apartmentPath}/applications/${tenantProfile.uid}`;
    const chatPath = `${applicationPath}/chatMessages/${tenantProfile.uid}`;

    await this.firestoreService.setDbDoc(
      {
        applicationRefList: arrayUnion(tenantProfile.uid),
        unreadTenantChatsRef: arrayUnion(tenantProfile.uid),
      },
      apartmentPath,
      true
    );

    // Add editorList to tenantProfile to grant readPermissions
    const tenantProfilePath = `${FIRESTORE_COLLECTION_PATH.users.tenantProfiles.root.replace(
      '{uid}',
      tenantProfile.uid
    )}/${tenantProfile.uid}`;

    await this.firestoreService.setDbDoc({ readPermissionGrantedUsers: apartment.editorList }, tenantProfilePath, true);

    // add chat
    const application = this.parseApplication(apartment, tenantProfile, rating);
    await this.chatService.createChat(application);
    const chatDocRef = this.firestoreService.getDocRef(chatPath, { setCreatedAt: true });

    // update unreadTenantMessagesRef
    await this.firestoreService.setDbDoc(
      {
        lastMessage: application.lastMessage,
        lastMessageSenderId: application.lastMessageSenderId,
        unreadTenantMessagesRef: chatDocRef?.id ? arrayUnion(chatDocRef.id) : [],
        lastMessageSent: new Date(),
      },
      applicationPath,
      true
    );
  }

  public getLandlordApplicationListRef(params: IGetLandlordApplicationListQueryParamsWithRating): Query<IApplication> {
    const where: IWhereFilterArguments[] = [{ fieldPath: 'rating', opStr: '==', value: MATCHING_MODE.LIKE }];

    //For not admins, push a second where with check for id in editorList
    if (!params.isAdmin) {
      where.push({ fieldPath: 'editorList', opStr: 'array-contains', value: params.landlordId });
    }

    if (params.rating) {
      where.push({ fieldPath: 'landlordRating', opStr: '==', value: params.rating });
    }

    if (params.tenantId) {
      where.push({ fieldPath: 'tenantProfile.uid', opStr: '==', value: params.tenantId });
    }
    if (params.apartmentId) {
      where.push({ fieldPath: 'apartmentId', opStr: '==', value: params.apartmentId });
    }

    const queryParams: IFirestoreQueryParams = {
      where,
      orderBy: { fieldPath: 'updatedAt', directionStr: 'desc' },
    };

    return this.firestoreService.getCollectionRefWithParams<IApplication>(
      this.firestoreService.getCollectionGroupRef('applications', { fetchWithId: true }),
      queryParams
    );
  }

  public getTenantApplicationListRef(params: IGetTenantApplicationListQueryParams): Query<IApplication> {
    const options: IFirestoreQueryParams = {
      where: [{ fieldPath: 'tenantProfile.uid', opStr: '==', value: params.tenantId }],
      orderBy: { fieldPath: 'updatedAt', directionStr: 'desc' },
    };

    if (params?.matchingMode.length && Array.isArray(options.where)) {
      options.where.push({ fieldPath: 'rating', opStr: 'in', value: params.matchingMode });
    }

    return this.firestoreService.getCollectionRefWithParams<IApplication>(
      this.firestoreService.getCollectionGroupRef('applications'),
      options
    );
  }

  public getApplicationRating(landlordId: string, apartmentId: string, tenantId: string): Promise<IApplication> {
    const apartmentPath = `${FIRESTORE_COLLECTION_PATH.users.landlordProfiles.apartments.root
      .replace('{uid}', landlordId)
      .replace('{landlordId}', landlordId)}/${apartmentId}`;
    const applicationPath = `${apartmentPath}/applications/${tenantId}`;
    return this.firestoreService.getDbDoc(applicationPath);
  }

  public updateApplicationLandlordRating(
    landlordId: string,
    apartmentId: string,
    tenantId: string,
    landlordRating: MATCHING_MODE
  ): Promise<void> {
    const apartmentPath = `${FIRESTORE_COLLECTION_PATH.users.landlordProfiles.apartments.root
      .replace('{uid}', landlordId)
      .replace('{landlordId}', landlordId)}/${apartmentId}`;
    const applicationPath = `${apartmentPath}/applications/${tenantId}`;
    return this.firestoreService.updateDbDoc({ landlordRating }, applicationPath);
  }

  public updateApplication(
    landlordId: string,
    apartmentId: string,
    tenantId: string,
    data: Partial<IApplication>
  ): Promise<void> {
    const apartmentPath = `${FIRESTORE_COLLECTION_PATH.users.landlordProfiles.apartments.root
      .replace('{uid}', landlordId)
      .replace('{landlordId}', landlordId)}/${apartmentId}`;
    const applicationPath = `${apartmentPath}/applications/${tenantId}`;
    return this.firestoreService.updateDbDoc({ ...data }, applicationPath);
  }

  public async markUnread(application: IApplication, readByUserType: USER_TYPE): Promise<void> {
    const apartmentPath = `${FIRESTORE_COLLECTION_PATH.users.landlordProfiles.apartments.root
      .replace('{uid}', application.landlordId)
      .replace('{landlordId}', application.landlordId)}/${application.apartmentId}`;
    const apartmentDocRef = this.firestoreService.getDocRef(apartmentPath);
    const applicationPath = `${apartmentPath}/applications/${application.tenantProfile.uid}`;
    const applicationDocRef = this.firestoreService.getDocRef(applicationPath);
    const batch = this.firestoreService.getBatch();

    switch (readByUserType) {
      case USER_TYPE.TENANT:
        batch.update(applicationDocRef, { unreadLandlordMessagesRef: [] });
        break;
      case USER_TYPE.LANDLORD:
        application.unreadTenantMessagesRef.forEach((unreadTenantMessageId) => {
          batch.update(apartmentDocRef, { unreadTenantMessagesRef: arrayRemove(unreadTenantMessageId) });
        });
        batch.update(applicationDocRef, { unreadTenantMessagesRef: [] });
        break;
      default:
        break;
    }
    return await batch.commit().catch((e) => console.error('Error ApplicationService markUnread', e));
  }

  /**
   * Create and return application from given parameters
   * @param apartment
   * @param tenantProfile
   * @param rating
   * @return TApplicationCreate
   */
  public parseApplication = (
    apartment: IApartment,
    tenantProfile: ITenantProfile,
    rating: MATCHING_MODE
  ): TApplicationCreate => {
    return {
      address: apartment.mainInformation.address,
      apartmentId: apartment.id,
      editorList: apartment.editorList,
      isLandlordTyping: false,
      isTenantTyping: false,
      landlordId: apartment.creatorId,
      landlordRating: MATCHING_MODE.NONE,
      lastMessage: rating === MATCHING_MODE.LIKE ? tenantProfile?.aboutMe?.description : '',
      lastMessageSent: new Date(),
      lastMessageSenderId: tenantProfile.uid,
      media: apartment?.media?.length ? apartment.media[0] : null,
      rating,
      rooms: apartment?.areas?.numberOfRooms ?? 0,
      seenByLandlord: false,
      size: apartment?.areas?.totalArea ?? 0,
      tenantProfile,
      warmRent: apartment?.cost?.warmRent ?? 0,
    };
  };
}

export default ApplicationService;
