import AddressFinderInput from 'component/molecules/AddressFinderInput';
import InputOptionList, { RADIO_INPUT_COLUMNS_COUNT } from 'component/atoms/formElement/InputOptionList';
import Joi from 'joi';
import styles from './SearchAddressForm.module.scss';
import { FormContext } from 'core/context/form.context';
import { APARTMENT_TYPE_OF_MARKETING, ILocationSelection } from '@wohnsinn/ws-ts-lib';
import { ROUTES } from 'core/const/routes';
import { getTranslatedOptions, SEARCH_RENT_OBJECT_SELECT_OPTIONS } from 'core/const/select-options';
import { joiResolver } from '@hookform/resolvers/joi';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const SEARCH_LOCATION_FORM_SCHEMA = Joi.object({
  location: Joi.any(),
  typeOfMarketing: Joi.string().required(),
});

const SearchAddressForm = () => {
  const { t } = useTranslation('common');
  const { t: r } = useTranslation('routes');
  const navigate = useNavigate();

  const { control, handleSubmit, watch, formState } = useForm<{
    location: ILocationSelection;
    typeOfMarketing: APARTMENT_TYPE_OF_MARKETING;
  }>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: joiResolver(SEARCH_LOCATION_FORM_SCHEMA),
    defaultValues: {
      location: undefined,
      typeOfMarketing: APARTMENT_TYPE_OF_MARKETING.RENT,
    },
  });

  const handleFormSubmit = () => {
    const location = watch('location');
    const typeOfMarketing = watch('typeOfMarketing');
    const url = new URL(window.location.href);

    url.searchParams.set('typeOfMarketing', typeOfMarketing);

    if (location) {
      url.searchParams.set('location', JSON.stringify(location));
    }
    navigate(`${r(ROUTES.staticRoutes.search.path)}/${url.search}`);
  };

  return (
    <div className={styles.wrapper}>
      <FormContext.Provider value={{ control }}>
        <form onSubmit={handleSubmit(handleFormSubmit, console.error)}>
          {process.env.REACT_APP_SHOW_SALE === 'true' && (
            <InputOptionList
              centerLabel
              mode={'radio'}
              columns={RADIO_INPUT_COLUMNS_COUNT.TWO}
              options={getTranslatedOptions(SEARCH_RENT_OBJECT_SELECT_OPTIONS, t)}
              name={'typeOfMarketing'}
            />
          )}
          <AddressFinderInput name={'location'} />
        </form>
      </FormContext.Provider>
    </div>
  );
};

export default SearchAddressForm;
