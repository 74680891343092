import { IFirebaseInstances } from './setup-firebase';
import { FirebaseFunctionsService } from './service/firebase-functions.service';
import FirebaseStorageService from './service/firebase-storage.service';
import FirestoreService from './service/firestore.service';
import { ApartmentService } from './service/apartment.service';
import { ChatService } from './service/chat.service';
import MixpanelTrackingService from './service/mixpanel-tracking.service';
import UserService from './service/user.service';
import FirebaseAuthService from './service/firebase-auth.service';
import ApplicationService from './service/application.service';
import OrganizationService from './service/organization.service';
import { FirebaseRemoteConfigService } from './service/firebase-remote-config.service';
import RegistrationRequestService from './service/registrationRequest.service';
import TenantService from './service/tenant.service';
import LandlordService from './service/landlord.service';
import MapBoxService from 'core/service/mapbox.service';

export interface IWohnsinnServices {
  apartmentService: ApartmentService;
  applicationService: ApplicationService;
  chatService: ChatService;
  firebaseAuthService: FirebaseAuthService;
  firebaseFunctionsService: FirebaseFunctionsService;
  firebaseRemoteConfigService: FirebaseRemoteConfigService;
  firebaseStorageService: FirebaseStorageService;
  firestoreService: FirestoreService;
  landlordService: LandlordService;
  mapBoxService: MapBoxService;
  mixpanelTrackingService: MixpanelTrackingService;
  organizationService: OrganizationService;
  registrationRequestService: RegistrationRequestService;
  tenantService: TenantService;
  userService: UserService;
}

const setupServices = ({
  firebaseStorage,
  firebaseFunctions,
  firebaseAuth,
  firestore,
  firebaseRemoteConfig,
}: IFirebaseInstances): IWohnsinnServices => {
  const firebaseFunctionsService = new FirebaseFunctionsService(firebaseFunctions);
  const registrationRequestService = new RegistrationRequestService(firebaseFunctionsService);
  const firebaseRemoteConfigService = new FirebaseRemoteConfigService(firebaseRemoteConfig);
  const firestoreService = new FirestoreService(firestore);
  const apartmentService = new ApartmentService(firestoreService, firebaseFunctionsService);
  const chatService = new ChatService(firestoreService);
  const mixpanelTrackingService = new MixpanelTrackingService();
  const userService = new UserService(firestoreService, mixpanelTrackingService);
  const tenantService = new TenantService(firestoreService);
  const landlordService = new LandlordService(firestoreService, firebaseFunctionsService);
  const firebaseStorageService = new FirebaseStorageService(firebaseStorage, userService, tenantService);
  const mapBoxService = new MapBoxService();
  const firebaseAuthService = new FirebaseAuthService(
    firebaseAuth,
    userService,
    firebaseFunctionsService,
    tenantService,
    landlordService
  );
  const applicationService: ApplicationService = new ApplicationService(firestoreService, chatService);
  const organizationService: OrganizationService = new OrganizationService(
    firestoreService,
    landlordService,
    firebaseFunctionsService
  );

  return {
    apartmentService,
    applicationService,
    chatService,
    firebaseAuthService,
    firebaseFunctionsService,
    firebaseRemoteConfigService,
    firebaseStorageService,
    firestoreService,
    landlordService,
    mapBoxService,
    mixpanelTrackingService,
    organizationService,
    registrationRequestService,
    tenantService,
    userService,
  };
};

export default setupServices;
