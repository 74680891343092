import { FC, PropsWithChildren } from 'react';
import Headline, { HEADLINE_SIZE } from '../../atoms/typographie/Headline';
import Text from '../../atoms/typographie/Text';
import styles from './TextParagraph.module.scss';

interface ITextPararaph {
  title: string;
  description: string;
  count: number;
  children?: any;
}

const TextParagraph: FC<PropsWithChildren<ITextPararaph>> = ({ title, description, children, count }) => {
  return (
    <>
      <Headline size={HEADLINE_SIZE.H4}>
        {count} {title}
      </Headline>
      <Text>{description}</Text>
      <Text className={styles.text}>{children}</Text>
    </>
  );
};
export default TextParagraph;
