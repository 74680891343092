import { FC, useContext } from 'react';
import { IUpload } from '@wohnsinn/ws-ts-lib';
import DocumentUploadItem from '../DoumentUploadItem';
import { UPLOAD_TYPE } from '../index';
import UserContext from '../../../../core/context/user.context';

const DocumentUploadFileList: FC<{
  documentUploadList: IUpload[];
  uploadType: UPLOAD_TYPE;
  onDocumentDelete: (onDocumentDelete: IUpload, uploadType: UPLOAD_TYPE) => void;
}> = ({ documentUploadList, uploadType, onDocumentDelete }) => {
  const { tenantProfile } = useContext(UserContext);
  return (
    <div>
      {documentUploadList.map((documentUpload, i) => (
        <DocumentUploadItem
          key={`${documentUpload?.id}_${new Date().getTime()}`}
          uploadItemData={documentUpload}
          uploadType={uploadType}
          onDocumentDelete={onDocumentDelete}
          documentName={`${uploadType === UPLOAD_TYPE.SCHUFA ? 'Schufa' : 'Einkommensnachweis'}-${
            tenantProfile?.personalInformation?.firstName ? tenantProfile.personalInformation.firstName + '-' : ''
          }${tenantProfile?.personalInformation?.lastName ? tenantProfile.personalInformation.lastName + '-' : ''}${
            i + 1
          }`}
        />
      ))}
    </div>
  );
};

export default DocumentUploadFileList;
