import {
  APARTMENT_TYPE,
  APARTMENT_TYPE_OF_MARKETING,
  APARTMENT_TYPE_OF_USAGE,
  HOUSE_TYPE,
  IMMO_TYPE,
} from '@wohnsinn/ws-ts-lib';
import { ISelectOptionWithComingSoon } from 'component/atoms/formElement/Dropdown';

export const APARTMENT_TYPES_OF_MARKETING: ISelectOptionWithComingSoon<APARTMENT_TYPE_OF_MARKETING>[] = [
  { value: APARTMENT_TYPE_OF_MARKETING.RENT, label: 'Miete' },
  { value: APARTMENT_TYPE_OF_MARKETING.SALE, label: 'Verkauf' },
];

export const APARTMENT_TYPES_OF_USAGE_FOR_RENT: ISelectOptionWithComingSoon<APARTMENT_TYPE_OF_USAGE>[] = [
  { value: APARTMENT_TYPE_OF_USAGE.RESIDE, label: 'Wohnen' },
  { value: APARTMENT_TYPE_OF_USAGE.TEMPORARY_LIVING, label: 'Wohnen auf Zeit' },
  { value: APARTMENT_TYPE_OF_USAGE.COMMERCIAL, label: 'Gewerbe', isComingSoon: true },
];
export const APARTMENT_TYPES_OF_USAGE_FOR_SALE: ISelectOptionWithComingSoon<APARTMENT_TYPE_OF_USAGE>[] = [
  { value: APARTMENT_TYPE_OF_USAGE.RESIDE, label: 'Wohnen' },
  { value: APARTMENT_TYPE_OF_USAGE.INVESTMENT, label: 'Investment' },
  { value: APARTMENT_TYPE_OF_USAGE.COMMERCIAL, label: 'Gewerbe', isComingSoon: true },
];

export const IMMO_TYPES_SELECT_OPTION_LIST_FOR_RENT: ISelectOptionWithComingSoon<IMMO_TYPE>[] = [
  { value: IMMO_TYPE.APARTMENT, label: 'Wohnung' },
  { value: IMMO_TYPE.HOUSE, label: 'Haus' },
  { value: IMMO_TYPE.ROOM, label: 'Zimmer', isComingSoon: true },
  // { value: IMMO_TYPE.PARKING, label: 'Parkmöglichkeit', isComingSoon: true },
  // { value: IMMO_TYPE.OFFICE, label: 'Büro / Praxis', isComingSoon: true },
];

export const IMMO_TYPES_SELECT_OPTION_LIST_FOR_SALE: ISelectOptionWithComingSoon<IMMO_TYPE>[] = [
  { value: IMMO_TYPE.APARTMENT, label: 'Wohnung' },
  { value: IMMO_TYPE.HOUSE, label: 'Haus' },
  { value: IMMO_TYPE.PROPERTY, label: 'Wohngrundstück' },
  { value: IMMO_TYPE.PARKING, label: 'Parkmöglichkeit', isComingSoon: true },
  { value: IMMO_TYPE.OFFICE, label: 'Büro / Praxis', isComingSoon: true },
];

export const APARTMENT_TYPES_SELECT_OPTION_LIST: ISelectOptionWithComingSoon<APARTMENT_TYPE>[] = [
  { value: APARTMENT_TYPE.GROUND, label: 'Erdgeschosswohnung' },
  { value: APARTMENT_TYPE.LEVEL, label: 'Etagenwohnung' },
  { value: APARTMENT_TYPE.APARTMENT, label: 'Apartment' },
  { value: APARTMENT_TYPE.MAISONETTE, label: 'Maisonette' },
  { value: APARTMENT_TYPE.BASEMENT, label: 'Souterrain' },
  { value: APARTMENT_TYPE.ATTIC_APARTMENT, label: 'Dachgeschosswohnung' },
  { value: APARTMENT_TYPE.RAW_ATTIC, label: 'Rohdachboden' },
  { value: APARTMENT_TYPE.GALLERY, label: 'Galerie' },
  { value: APARTMENT_TYPE.VACATION_APARTMENT, label: 'Ferienwohnung' },
  { value: APARTMENT_TYPE.TERRACE_APARTMENT, label: 'Terrassenwohnung' },
  { value: APARTMENT_TYPE.PENTHOUSE, label: 'Penthouse / Attikawohnung' },
  { value: APARTMENT_TYPE.LOFT_STUDIO_ATELIER, label: 'Loft/Studio/Atelier' },
  { value: APARTMENT_TYPE.NONE, label: 'Keine Angabe' },
];

export const HOUSE_TYPES_SELECT_OPTION_LIST: ISelectOptionWithComingSoon<HOUSE_TYPE>[] = [
  { value: HOUSE_TYPE.TOWNHOUSE, label: 'Reihenhaus' },
  { value: HOUSE_TYPE.END_TERRACE_HOUSE, label: 'Reihenendhaus' },
  { value: HOUSE_TYPE.MID_TERRACE_HOUSE, label: 'Reihenmittelhaus' },
  { value: HOUSE_TYPE.CORNER_TERRACE_HOUSE, label: 'Reiheneckhaus' },
  { value: HOUSE_TYPE.SEMI_DETACHED_HOUSE, label: 'Doppelhaushälfte' },
  { value: HOUSE_TYPE.SINGLE_FAMILY_HOUSE, label: 'Einfamilienhaus' },
  { value: HOUSE_TYPE.TOWN_HOUSE, label: 'Stadthaus' },
  { value: HOUSE_TYPE.BUNGALOW, label: 'Bungalow' },
  { value: HOUSE_TYPE.VILLA, label: 'Villa' },
  { value: HOUSE_TYPE.FARMSTEAD, label: 'Resthof' },
  { value: HOUSE_TYPE.FARMHOUSE, label: 'Bauernhaus' },
  { value: HOUSE_TYPE.COUNTRY_HOUSE, label: 'Landhaus' },
  { value: HOUSE_TYPE.CASTLE, label: 'Schloss' },
  { value: HOUSE_TYPE.TWO_FAMILY_HOUSE, label: 'Zweifamilienhaus' },
  { value: HOUSE_TYPE.MULTI_FAMILY_HOUSE, label: 'Mehrfamilienhaus' },
  { value: HOUSE_TYPE.HOLIDAY_HOME, label: 'Ferienhaus' },
  { value: HOUSE_TYPE.MOUNTAIN_HUT, label: 'Berghütte' },
  { value: HOUSE_TYPE.CHALET, label: 'Chalet' },
  { value: HOUSE_TYPE.BEACH_HOUSE, label: 'Strandhaus' },
  { value: HOUSE_TYPE.SUMMER_HOUSE, label: 'Laube/Datsche/Gartenhaus' },
  { value: HOUSE_TYPE.APARTMENT_BUILDING, label: 'Apartmenthaus' },
  { value: HOUSE_TYPE.FORTRESS, label: 'Burg' },
  { value: HOUSE_TYPE.MANOR_HOUSE, label: 'Herrenhaus' },
  { value: HOUSE_TYPE.FINCA, label: 'Finca' },
  { value: HOUSE_TYPE.RUSTICO, label: 'Rustico' },
  { value: HOUSE_TYPE.PREFAB_HOUSE, label: 'Fertighaus' },
  { value: HOUSE_TYPE.NO_INFORMATION, label: 'Keine Angabe' },
];
