import PageLayout from 'component/layouts/PageLayout';
import { ROUTES } from 'core/const/routes';
import { useTranslation } from 'react-i18next';
import LinkElement from 'component/atoms/LinkElement';
import { MODAL_IDENTIFIER } from 'core/enum/modals.enum';
import { faChevronRight, faRightLeft, faSignOut, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { USER_TYPE } from '@wohnsinn/ws-ts-lib';
import { useContext } from 'react';
import UserContext from 'core/context/user.context';
import ModalContext from 'core/context/modal.context';
import { wohnsinnServices } from 'App';
import { Grid } from '@mui/material';
import Text, { TEXT_WEIGHT } from 'component/atoms/typographie/Text';
import useLogout from 'core/hook/logout.hook';
import { USER_TYPE_STATE } from 'component/modals/SwitchUserTypeModal';
import { useNavigate } from 'react-router-dom';
import FB_FUNCTION_URLS from 'core/const/fb-function-names';
import SnackBarContext from 'core/context/snackbar.context';

const SettingsView = () => {
  const { t: r } = useTranslation('routes');
  const { t } = useTranslation('common');
  const { t: m } = useTranslation('common', { keyPrefix: 'component.molecules.modals.SettingsModal' });
  const { activeUserType, user, tenantProfile, landlordProfile } = useContext(UserContext);
  const navigate = useNavigate();
  const { logout } = useLogout();
  const { firebaseFunctionsService } = wohnsinnServices;
  const isWogeEnvironment = process.env.REACT_APP_ENVIRONMENT === 'woge';
  const isLandlord = user?.activeUserType === USER_TYPE.LANDLORD;
  const isTenant = user?.activeUserType === USER_TYPE.TENANT;
  const { handleSnackBar } = useContext(SnackBarContext);

  const profile =
    isLandlord && user?.isTenant
      ? USER_TYPE_STATE.LANDLORD_WITH_TENANT_PROFILE
      : isLandlord && !user?.isTenant
      ? USER_TYPE_STATE.LANDLORD_WITHOUT_TENANT_PROFILE
      : isTenant && user?.isLandlord
      ? USER_TYPE_STATE.TENANT_WITH_LANDLORD_PROFILE
      : isTenant && !user?.isLandlord
      ? USER_TYPE_STATE.TENANT_WITHOUT_LANDLORD_PROFILE
      : 'error';

  const renderButtonText = (profile: USER_TYPE_STATE | 'error'): string => {
    switch (profile) {
      case USER_TYPE_STATE.LANDLORD_WITH_TENANT_PROFILE:
        return 'Zum Mietermodus';
      case USER_TYPE_STATE.LANDLORD_WITHOUT_TENANT_PROFILE:
        return 'Mieterprofil erstellen';
      case USER_TYPE_STATE.TENANT_WITH_LANDLORD_PROFILE:
        return 'Zum Vermietermodus';
      case USER_TYPE_STATE.TENANT_WITHOUT_LANDLORD_PROFILE:
        return 'Vermieter-Profil erstellen';
      default:
        return 'error';
    }
  };

  const { openModal } = useContext(ModalContext);
  const firstName =
    activeUserType === USER_TYPE.TENANT
      ? tenantProfile?.personalInformation?.firstName
      : landlordProfile?.personalInformation?.firstName;
  const lastName =
    activeUserType === USER_TYPE.TENANT
      ? tenantProfile?.personalInformation?.lastName
      : landlordProfile?.personalInformation?.lastName;

  const deleteUser = async (userID: string) => {
    try {
      navigate('/');
      await firebaseFunctionsService.callFbFunction(FB_FUNCTION_URLS.user.delete, {
        params: { userID },
      });
      handleSnackBar('Dein Konto wurde gelöscht', 'info');
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <PageLayout pageTitle={r(ROUTES.userRoutes.settings.title)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Text align={'center'} weight={TEXT_WEIGHT.TEXT_WEIGHT_BOLD}>
            {firstName ?? ''} {lastName ?? ''}
          </Text>
          <Text align={'center'}>
            {activeUserType === USER_TYPE.TENANT ? tenantProfile?.email : landlordProfile?.email}
          </Text>
        </Grid>

        {user && user.isTenant && user?.activeUserType === USER_TYPE.TENANT && (
          <Grid item xs={12} md={6}>
            <LinkElement
              routerLink={r(ROUTES.tenantRoutes.searchProfiles.path)}
              text={r(ROUTES.tenantRoutes.searchProfiles.title)}
            />
          </Grid>
        )}

        <Grid item xs={12} md={6}>
          <LinkElement
            text={m('notifications')}
            onClick={() => openModal({ id: MODAL_IDENTIFIER.NOTIFICATIONS })}
            icon={faChevronRight}
          />
        </Grid>

        {!isWogeEnvironment ? (
          <Grid item xs={12} md={6}>
            <LinkElement
              text={renderButtonText(profile)}
              onClick={() => openModal({ id: MODAL_IDENTIFIER.SWITCH_USER_TYPE })}
              icon={faRightLeft}
            />
          </Grid>
        ) : null}

        <Grid item xs={12} md={6}>
          <LinkElement text={m('signOut')} onClick={logout} icon={faSignOut} />
        </Grid>
        <Grid item xs={12} md={6}>
          <LinkElement
            color={'red'}
            text={t('view.AccountView.deleteAccount')}
            onClick={() =>
              openModal({
                id: MODAL_IDENTIFIER.DELETE_MODAL,
                data: {
                  deleteHandler: () => deleteUser(user.uid),
                  title: m('delete.title'),
                  text: m('delete.text'),
                },
              })
            }
            icon={faTrash}
          />
        </Grid>
      </Grid>
    </PageLayout>
  );
};

export default SettingsView;
