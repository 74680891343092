import styles from '../HowToSlides.module.scss';
import HowToSlideWrapper from '../HowToSlideWrapper';
import { useSwiper } from 'swiper/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownLong } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import CTAButton, { BUTTON_STYLE } from '../../../atoms/Buttons/CTAButton';

const HowToSlideNope = () => {
  const swiper = useSwiper();
  const { t } = useTranslation('common', { keyPrefix: 'component.organisms.HowToSlides' });
  const { t: c } = useTranslation('common');

  const handleNextSlide = () => {
    swiper.allowSlideNext = true;
    swiper.slideNext(300);
    swiper.allowSlideNext = false;
  };
  return (
    <HowToSlideWrapper title={t('nope.title')} subtitle={t('nope.text')}>
      <div className={styles.ratingsWrapper}>
        <div className={styles.arrowWrapper}>
          <span className={styles.click}>{t('click')}</span>
          <FontAwesomeIcon className={`${styles.arrowDown} ${styles.nope}`} icon={faDownLong} size={'2x'} />

          <CTAButton
            buttonStyle={BUTTON_STYLE.SECONDARY}
            buttonText={'Bewerben'}
            customStyling={styles.buttonAnimation}
            hideText
            img={c('icons.heart_nope')}
            onClick={handleNextSlide}
            size={'big'}
          />
        </div>
        <CTAButton
          customStyling={styles.buttonAnimation}
          size={'big'}
          buttonText={'Bewerben'}
          onClick={() => console.log('WRONG CLICK :)')}
        />

        <CTAButton
          buttonStyle={BUTTON_STYLE.SECONDARY}
          buttonText={'Bewerben'}
          customStyling={styles.buttonAnimation}
          hideText
          img={c('icons.heart_like')}
          onClick={() => console.log('WRONG CLICK :)')}
          size={'big'}
        />
      </div>
    </HowToSlideWrapper>
  );
};

export default HowToSlideNope;
