import { Dispatch, FC, SetStateAction, useState } from 'react';
import Accordion from '../../../atoms/Accordion';
import { faqLandlord, faqTenant } from '../../../../core/const/faq';
import Headline, { HEADLINE_SIZE } from '../../../atoms/typographie/Headline';
import Spacer, { SPACER_SIZE } from '../../../atoms/Spacer';
import { USER_TYPE } from '@wohnsinn/ws-ts-lib';
import ToggleButton from '../../../atoms/ToggleButton';

const FAQSection: FC<{ userType: USER_TYPE; setUserType?: Dispatch<SetStateAction<USER_TYPE>> }> = ({
  userType,
  setUserType,
}) => {
  const [selectedTab, setSelectedTab] = useState(null);
  return (
    <>
      <Headline size={HEADLINE_SIZE.DISPLAY} align={'center'}>
        Häufig gestellte Fragen
      </Headline>

      <Spacer size={SPACER_SIZE.LG} />
      {setUserType ? (
        <div style={{ maxWidth: 350, margin: 'auto' }}>
          <ToggleButton
            option2={{
              text: 'Mieter',
              onClick: () => setUserType(USER_TYPE.TENANT),
              active: userType === USER_TYPE.TENANT,
            }}
            option1={{
              text: 'Vermieter',
              onClick: () => setUserType(USER_TYPE.LANDLORD),
              active: userType === USER_TYPE.LANDLORD,
            }}
          />
        </div>
      ) : null}
      <Spacer size={SPACER_SIZE.LG} />
      {userType === USER_TYPE.TENANT ? (
        <Accordion isLanding items={faqTenant} onChange={setSelectedTab} selectedTab={selectedTab} />
      ) : (
        <Accordion isLanding items={faqLandlord} onChange={setSelectedTab} selectedTab={selectedTab} />
      )}
    </>
  );
};

export default FAQSection;
