import { Grid } from '@mui/material';
import PageTitle from '../../../../molecules/PageTitle';
import { wohnsinnServices } from '../../../../../App';
import { useContext, useEffect, useState } from 'react';
import UserContext from '../../../../../core/context/user.context';
import InputText from '../../../../atoms/formElement/InputText';
import { FormContext } from '../../../../../core/context/form.context';
import { useForm } from 'react-hook-form';
import TwilioService from '../../../../../core/service/twilio.service';
import SnackBarContext from '../../../../../core/context/snackbar.context';
import { REGISTRATION_STATES } from '@wohnsinn/ws-ts-lib';
import CTAButton, { getSubmitIcon } from '../../../../atoms/Buttons/CTAButton';
import { SUBMIT_BUTTON_MODE } from '../../../../../core/enum/submit-button-mode.enum';
import { joiResolver } from '@hookform/resolvers/joi/dist/joi';
import Joi from 'joi';
import Spacer from '../../../../atoms/Spacer';
import RegistrationLayout from '../../RegistrationLayout';
import { useTranslation } from 'react-i18next';
import { TEXT_COLOR } from '../../../../atoms/typographie/Text';
import LOCAL_STORAGE_KEYS from '../../../../../core/enum/local-storage.enum';
import { ROUTES } from '../../../../../core/const/routes';

const VERIFY_PHONE_FORM_ID = 'verify-phone-form-id';
const REQUEST_TIMEOUT = 30;
const CreateLandlordVerifyPhoneNumber = () => {
  const [buttonSubmitMode, setButtonSubmitMode] = useState<SUBMIT_BUTTON_MODE>(SUBMIT_BUTTON_MODE.NONE);
  const [disableNextRequest, setDisableNextRequest] = useState<boolean>(false);
  const [requestTimeout, setRequestTimeout] = useState<number>(REQUEST_TIMEOUT);
  const { userService, firebaseFunctionsService } = wohnsinnServices;
  const twilioService = new TwilioService(firebaseFunctionsService);
  const { landlordProfile } = useContext(UserContext);
  const { handleSnackBar } = useContext(SnackBarContext);
  const [buttonSubmitModeVerify, setButtonSubmitModeVery] = useState<SUBMIT_BUTTON_MODE>(SUBMIT_BUTTON_MODE.NONE);
  const [showVerification, setShowVerification] = useState<boolean>(false);
  const { t } = useTranslation('common');
  const { t: r } = useTranslation('routes');

  // PHONE NUMBER FORM
  const { control, setValue, formState, watch, handleSubmit } = useForm<{ phoneNumber: string }>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    resolver: joiResolver(Joi.object({ phoneNumber: Joi.string().required().min(1) })),
  });
  const phoneNumber = watch('phoneNumber');

  useEffect(() => {
    if (landlordProfile?.personalInformation?.phoneNumber) {
      setValue('phoneNumber', landlordProfile.personalInformation.phoneNumber, { shouldValidate: true });
    }
  }, [landlordProfile]);

  const sendVerificationCode = async () => {
    setButtonSubmitMode(SUBMIT_BUTTON_MODE.SUBMITTING);
    if (phoneNumber) {
      try {
        await twilioService.requestVerificationCode(phoneNumber);
        setDisableNextRequest(true);
        setTimeout(() => {
          setDisableNextRequest(false);
        }, REQUEST_TIMEOUT * 1000);

        let counter = REQUEST_TIMEOUT;
        const requestInterval = setInterval(() => {
          if (counter > 0) {
            counter--;
            setRequestTimeout(counter);
          } else {
            setRequestTimeout(REQUEST_TIMEOUT);
            clearInterval(requestInterval);
          }
        }, 1000);

        setShowVerification(true);
        handleSnackBar('toast.sendVerificationCode', 'success');
      } catch (e) {
        handleSnackBar('toast.error.unknown', 'error');
        console.error(e);
      }
    } else {
      handleSnackBar('toast.error.phoneNumberMissing', 'info');
    }
    setButtonSubmitMode(SUBMIT_BUTTON_MODE.NONE);
  };

  // VERIFY CODE
  const {
    control: controlVerify,
    formState: { errors, isValid },
    watch: watchVerify,
    handleSubmit: handleSubmitVerify,
  } = useForm<{ verificationCode: string }>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: joiResolver(Joi.object({ verificationCode: Joi.string().required().min(1) })),
  });
  const verificationCode = watchVerify('verificationCode');

  const verifyCode = async (): Promise<void> => {
    setButtonSubmitModeVery(SUBMIT_BUTTON_MODE.SUBMITTING);
    if (!Object.keys(errors).length && phoneNumber) {
      try {
        await twilioService.verifyVerificationCode(phoneNumber, parseInt(verificationCode));
        handleSnackBar('toast.success.verified', 'success');
        setButtonSubmitModeVery(SUBMIT_BUTTON_MODE.SUCCESS);

        setTimeout(async () => {
          await userService.updateUserData(
            { registrationState: REGISTRATION_STATES.ADD_ORGANIZATION_PROFILE },
            landlordProfile.uid
          );
        }, 500);
      } catch (e) {
        setButtonSubmitModeVery(SUBMIT_BUTTON_MODE.ERROR);
        // @ts-ignore
        if (e?.message) {
          // @ts-ignore
          handleSnackBar(e.message, 'error');
        } else {
          handleSnackBar('toast.error.unknown', 'error');
        }
      }
    }
  };

  const cancelOrganizationCreation = () => {
    try {
      setTimeout(async () => {
        localStorage.setItem(LOCAL_STORAGE_KEYS.REDIRECT_URL, r(ROUTES.landlordRoutes.account.path));
        await userService.updateUserData({ registrationState: REGISTRATION_STATES.FINISHED }, landlordProfile.uid);
      }, 500);
    } catch (e) {
      console.error('Error on cancelOrganizationCreation: ', e);
    }
  };

  return (
    <Grid container spacing={{ xs: 0, md: 2 }}>
      <Grid item>
        <PageTitle
          pageTitle={'Bitte verifiziere dein Konto'}
          secondPageTitle={
            'Durch die einmalige Verifizierung schützt du dein Konto vor Dritten. Diese erfolgt per SMS und ist in wenigen Sekunden erledigt.'
          }
        />
        <RegistrationLayout
          formId={VERIFY_PHONE_FORM_ID}
          isValidForm={isValid}
          buttonSubmitMode={buttonSubmitModeVerify}
          submitButtonText={t('verifyAndContinue')}
          secondaryButtonText={!landlordProfile?.isCommercialLandlord ? t('cancel') : null}
          secondaryButtonAction={!landlordProfile?.isCommercialLandlord ? () => cancelOrganizationCreation() : null}
          secondaryButtonColor={!landlordProfile?.isCommercialLandlord ? TEXT_COLOR.TEXT_COLOR_DANGER : null}
        >
          <FormContext.Provider value={{ control }}>
            <form
              id={'enter-phone-number'}
              onSubmit={handleSubmit(sendVerificationCode, (error) =>
                console.error('error on sending verification code:', error)
              )}
            >
              <InputText
                disabled={buttonSubmitMode === SUBMIT_BUTTON_MODE.SUBMITTING || disableNextRequest}
                name={'phoneNumber'}
                label={'Mobilrufnummer'}
                required
              />
              <CTAButton
                form={'enter-phone-number'}
                rounded={false}
                expand={'block'}
                spinIcon={buttonSubmitMode === SUBMIT_BUTTON_MODE.SUBMITTING}
                icon={getSubmitIcon(buttonSubmitMode)}
                buttonText={
                  showVerification ? `Code erneut senden  ${disableNextRequest ? requestTimeout : ''}` : 'Code senden'
                }
                disabled={
                  buttonSubmitMode === SUBMIT_BUTTON_MODE.SUBMITTING || !formState.isValid || disableNextRequest
                }
              />
            </form>
          </FormContext.Provider>

          <Spacer />

          {showVerification ? (
            <FormContext.Provider
              value={{
                control: controlVerify,
              }}
            >
              <form onSubmit={handleSubmitVerify(verifyCode)} id={VERIFY_PHONE_FORM_ID}>
                <InputText label={'Verifizierungscode'} name={'verificationCode'} />
              </form>
            </FormContext.Provider>
          ) : null}
        </RegistrationLayout>
      </Grid>
    </Grid>
  );
};

export default CreateLandlordVerifyPhoneNumber;
