import { SymbolLayer } from 'react-map-gl';

export const restaurantLayer: SymbolLayer = {
  id: 'restaurants',
  type: 'symbol',
  source: 'mapbox-streets',
  'source-layer': 'poi_label',
  paint: {
    'icon-color': '#DE1873',
  },
  filter: ['==', 'category_en', 'Restaurant'],
};

export const hospitalLayer: SymbolLayer = {
  id: 'hospitals',
  type: 'symbol',
  source: 'mapbox-streets',
  'source-layer': 'poi_label',
  paint: {
    'icon-color': '#8A18DE',
  },
  filter: ['==', 'maki', 'hospital'],
};

export const trainsLayer: SymbolLayer = {
  id: 'trains',
  type: 'symbol',
  source: 'mapbox-streets',
  'source-layer': 'transit_stop_label',
  paint: {
    'icon-color': '#FFE521',
  },
};

export const schoolsLayer: SymbolLayer = {
  id: 'schools',
  type: 'symbol',
  source: 'mapbox-streets',
  'source-layer': 'poi_label',
  paint: {
    'icon-color': '#188ADE',
  },
  filter: ['==', 'category_en', 'School'],
};

export const pharmacyLayer: SymbolLayer = {
  id: 'pharmacys',
  type: 'symbol',
  source: 'mapbox-streets',
  'source-layer': 'poi_label',
  paint: {
    'icon-color': '#FFA700',
  },
  filter: ['==', 'type', 'Pharmacy'],
};

export const kitaLayer: SymbolLayer = {
  id: 'kitas',
  type: 'symbol',
  source: 'mapbox-streets',
  'source-layer': 'poi_label',
  paint: {
    'icon-color': '#29D0A3',
  },
  filter: ['==', 'type', 'Kindergarten'],
};

export const supermarketLayer: SymbolLayer = {
  id: 'supermarkets',
  type: 'symbol',
  source: 'mapbox-streets',
  'source-layer': 'poi_label',
  paint: {
    'icon-color': '#18CCDE',
  },
  filter: ['==', 'type', 'Supermarket'],
};

export const fastFoodLayer: SymbolLayer = {
  id: 'fast-foods',
  type: 'symbol',
  source: 'mapbox-streets',
  'source-layer': 'poi_label',
  paint: {
    'icon-color': '#A1DE18',
  },
  filter: ['==', 'type', 'Fast Food'],
};
