import { ISelectOption, OPEN_IMMO_SOFTWARES } from '@wohnsinn/ws-ts-lib';

export const OPENIMMO_SOFTWARE_OPTION_LIST: ISelectOption<OPEN_IMMO_SOFTWARES>[] = [
  { value: OPEN_IMMO_SOFTWARES.FLOWFACT_PERFORMER, label: 'Flowfact Performer' },
  { value: OPEN_IMMO_SOFTWARES.FLOWFACT, label: 'Flowfact' },
  { value: OPEN_IMMO_SOFTWARES.ONOFFICE, label: 'onOffice' },
  { value: OPEN_IMMO_SOFTWARES.PROPSTACK, label: 'Propstack' },
  { value: OPEN_IMMO_SOFTWARES.LAGLER, label: 'Lagler' },
  { value: OPEN_IMMO_SOFTWARES.OTHER, label: 'Eigene Angabe' },
];
