import { FC } from 'react';
import styles from './LinkElement.module.scss';
import { IconDefinition } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Badge from '../Badge';
import { Link } from 'react-router-dom';

export interface ILinkElementProps {
  text: string;
  routerLink?: string;
  testId?: string;
  icon?: IconDefinition;
  iconFront?: IconDefinition;
  iconText?: string;
  color?: 'red' | 'blue';
  onClick?: () => any;
  showStatus?: boolean;
  badge?: string;
}

const LinkElement: FC<ILinkElementProps> = ({
  text,
  routerLink,
  testId,
  icon,
  color,
  onClick,
  showStatus = true,
  badge,
  iconFront,
}) => {
  const renderContent = () => (
    <>
      <div className={styles.label}>
        {iconFront ? <FontAwesomeIcon color={'black'} icon={iconFront} /> : null}
        {text}
        {badge ? <Badge text={badge} /> : null}
      </div>

      {showStatus && (
        <span id="hover-trigger" slot={'end'} className={`${styles.icon} ${styles[color]}`}>
          {icon ? <FontAwesomeIcon color={!color ? 'black' : 'white'} icon={icon} /> : null}
        </span>
      )}
    </>
  );

  if (routerLink) {
    return (
      <Link className={styles.linkElement} to={routerLink} data-testid={testId}>
        {renderContent()}
      </Link>
    );
  }

  return (
    <button className={styles.linkElement} onClick={onClick} data-testid={testId}>
      {renderContent()}
    </button>
  );
};

export default LinkElement;
