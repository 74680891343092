import { FC, useContext, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

import { FormContext } from 'core/context/form.context';
import UserContext from 'core/context/user.context';
import FormHeader from 'component/atoms/FormHeader';
import FormErrorBox from 'component/molecules/FormErrorBox';
import Dropdown from 'component/atoms/formElement/Dropdown';
import InputText from 'component/atoms/formElement/InputText';
import InputOptionList, { RADIO_INPUT_COLUMNS_COUNT } from 'component/atoms/formElement/InputOptionList';
import FormNavigation from 'component/molecules/FormNavigation';
import BackButton from 'component/atoms/Buttons/BackButton';
import useFormErrorHook from 'core/hook/use-form-error.hook';
import useApartment from 'core/hook/apartment.hook';
import useWindowSize from 'core/hook/windowsize.hook';
import { wohnsinnServices } from 'App';
import { SUBMIT_BUTTON_MODE } from 'core/enum/submit-button-mode.enum';
import { APARTMENT_CREATE_FORM_SCHEMA } from 'core/schemas/apartment/apartment-create-schema';
import { BOOLEAN_SELECT_OPTIONS, getTranslatedOptions } from 'core/const/select-options';
import {
  APARTMENT_TYPES_OF_MARKETING,
  APARTMENT_TYPES_OF_USAGE_FOR_RENT,
  APARTMENT_TYPES_OF_USAGE_FOR_SALE,
  IMMO_TYPES_SELECT_OPTION_LIST_FOR_RENT,
  IMMO_TYPES_SELECT_OPTION_LIST_FOR_SALE,
} from 'core/const/apartment-options';
import { ROUTES } from 'core/const/routes';
import { APARTMENT_TYPE_OF_MARKETING, APARTMENT_TYPE_OF_USAGE, IApartment } from '@wohnsinn/ws-ts-lib';
import { ICreateApartmentTunnel } from 'view/landlord/apartment/ApartmentCreateView';
import { TEXT_COLOR } from 'component/atoms/typographie/Text';
import useErrorHandling from 'core/hook/errorhandling.hook';

const ApartmentCreateForm: FC<ICreateApartmentTunnel> = ({ isTunnelView = false }) => {
  const { t } = useTranslation('common');
  const { t: r } = useTranslation('routes');
  const { handleError } = useFormErrorHook('ApartmentCreateForm');
  const { apartmentService } = wohnsinnServices;
  const { landlordProfile } = useContext(UserContext);
  const [buttonSubmitMode, setButtonSubmitMode] = useState<SUBMIT_BUTTON_MODE>(SUBMIT_BUTTON_MODE.NONE);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { apartment } = useApartment();
  const { isSmallerMd } = useWindowSize();
  const { errorObject, onErrorHandler } = useErrorHandling();

  const {
    control,
    reset,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    resolver: joiResolver(APARTMENT_CREATE_FORM_SCHEMA),
  });

  const typeOfMarketing = watch('mainInformation.typeOfMarketing');
  const isSalesObject = typeOfMarketing === APARTMENT_TYPE_OF_MARKETING.SALE;

  useEffect(() => {
    reset({
      mainInformation: {
        typeOfUsage: apartment?.mainInformation?.typeOfUsage,
        typeOfImmo: apartment?.mainInformation?.typeOfImmo,
        typeOfMarketing: apartment?.mainInformation?.typeOfMarketing,
        address: {
          hasPublishedAddress: apartment?.mainInformation?.address?.hasPublishedAddress,
          street: apartment?.mainInformation?.address?.street,
          houseNumber: apartment?.mainInformation?.address?.houseNumber,
          postalCode: apartment?.mainInformation?.address?.postalCode,
          city: apartment?.mainInformation?.address?.city,
        },
      },
    });
  }, [apartment]);

  useEffect(() => {
    if (process.env.REACT_APP_SHOW_SALE === 'false') {
      setValue('mainInformation.typeOfMarketing', APARTMENT_TYPE_OF_MARKETING.RENT);
      setValue('mainInformation.typeOfUsage', APARTMENT_TYPE_OF_USAGE.RESIDE);
    }
  }, []);

  const onFormSubmit = async () => {
    try {
      setButtonSubmitMode(SUBMIT_BUTTON_MODE.SUBMITTING);
      const mainInfo = watch('mainInformation');
      const updates: Partial<IApartment> = { mainInformation: mainInfo };

      if (isTunnelView) {
        const apartmentId = await apartmentService.createApartment(watch(), landlordProfile);
        navigate(r(ROUTES.landlordRoutes.apartment.create.mainInformation.path).replace(':apartmentId', apartmentId));
      } else {
        await apartmentService.updateApartment({
          data: updates,
          creatorId: apartment.creatorId,
          apartmentId: apartment.id,
        });
        navigate(r(ROUTES.landlordRoutes.apartment.overview.path).replace(':apartmentId', apartment?.id));
      }
      await queryClient.invalidateQueries({ queryKey: ['apartments'] });
      setButtonSubmitMode(SUBMIT_BUTTON_MODE.NONE);
    } catch (e) {
      handleError(e);
      setButtonSubmitMode(SUBMIT_BUTTON_MODE.ERROR);
    }
  };

  const resetOnMarketingTypeChange = () => {
    setValue('mainInformation.typeOfUsage', undefined);
    setValue('mainInformation.typeOfImmo', undefined);
    setValue('cost.purchasePrice.value', undefined);
    setValue('cost.purchasePrice.onRequest', undefined);
    setValue('cost.coldRent', undefined);
  };

  return (
    <FormContext.Provider value={{ control }}>
      <form onSubmit={handleSubmit(onFormSubmit, onErrorHandler)} id="createApartmentForm" noValidate>
        {!isTunnelView && !isSmallerMd && (
          <div style={{ paddingBottom: '1rem' }}>
            <BackButton />
          </div>
        )}
        {Object.keys(errors).length > 0 && <FormErrorBox errors={errorObject} />}
        <FormHeader
          title={r(ROUTES.landlordRoutes.apartment.create.new.title)}
          subtitle={t('apartmentCreateForm.subTitle')}
        />
        <Grid container rowSpacing={{ xs: 0, md: 0 }} className="form-with-navigation">
          <Grid item xs={12} md={6} className="form-with-navigation">
            {process.env.REACT_APP_SHOW_SALE === 'true' && (
              <>
                <Dropdown
                  label={t('objectCategory.label')}
                  placeholder={t('objectCategory.label')}
                  name="mainInformation.typeOfUsage"
                  optionList={getTranslatedOptions(
                    isSalesObject ? APARTMENT_TYPES_OF_USAGE_FOR_SALE : APARTMENT_TYPES_OF_USAGE_FOR_RENT,
                    t
                  )}
                  required
                />
                <Dropdown
                  label={t('typeOfMarketing.label')}
                  placeholder={t('objectTypeOfMarketing.label')}
                  name="mainInformation.typeOfMarketing"
                  optionList={getTranslatedOptions(APARTMENT_TYPES_OF_MARKETING, t)}
                  onChange={resetOnMarketingTypeChange}
                  required
                />
              </>
            )}
            <Dropdown
              placeholder="Bitte wählen"
              label={t('typeOfImmo.label')}
              name="mainInformation.typeOfImmo"
              optionList={getTranslatedOptions(
                isSalesObject ? IMMO_TYPES_SELECT_OPTION_LIST_FOR_SALE : IMMO_TYPES_SELECT_OPTION_LIST_FOR_RENT,
                t
              )}
              required
            />
            <InputText
              type="text"
              label={t('address.street.label')}
              placeholder={t('address.street.placeholder')}
              name="mainInformation.address.street"
              required
            />
            <InputText
              type="text"
              label={t('address.houseNumber.label')}
              placeholder={t('address.houseNumber.placeholder')}
              name="mainInformation.address.houseNumber"
              required
            />
            <InputText
              type="text"
              label={t('address.postalCode.label')}
              placeholder={t('address.postalCode.placeholder')}
              name="mainInformation.address.postalCode"
              required
            />
            <InputText
              type="text"
              label={t('address.city.label')}
              placeholder={t('address.city.placeholder')}
              name="mainInformation.address.city"
              required
            />
            <InputOptionList
              label={t('mainInformation.address.hasPublishedAddress.label')}
              mode="radio"
              columns={RADIO_INPUT_COLUMNS_COUNT.TWO}
              options={getTranslatedOptions(BOOLEAN_SELECT_OPTIONS, t)}
              name="mainInformation.address.hasPublishedAddress"
              required
            />
          </Grid>
        </Grid>
        <FormNavigation
          flipButtons
          buttonSubmitMode={buttonSubmitMode}
          disabled={false}
          showSecondaryButtonMobile={isTunnelView}
          formId="createApartmentForm"
          submitButtonText={isTunnelView ? t('saveAndContinue') : t('save')}
          secondaryButtonAction={() => navigate(-1)}
          secondaryButtonColor={TEXT_COLOR.TEXT_COLOR_ACCENT}
          secondaryButtonText={isTunnelView ? t('cancel') : t('back')}
        />
      </form>
    </FormContext.Provider>
  );
};

export default ApartmentCreateForm;
