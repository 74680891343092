import styles from './ApartmentInformationLoading.module.scss';

const ApartmentInformationLoading = () => {
  return (
    <div className={styles.loadingApartmentCard}>
      <div className={styles.image} />
      <div>
        <div className={styles.textWrapper}>
          <div className={styles.text} />
          <div className={styles.text} />
        </div>
        <div className={styles.textWrapper}>
          <div className={styles.text} />
          <div className={styles.text} />
        </div>
        <div className={styles.textWrapper}>
          <div className={styles.text} />
          <div className={styles.text} />
        </div>
        <div className={styles.textWrapper}>
          <div className={styles.text} />
          <div className={styles.text} />
        </div>
        <div className={styles.textWrapper}>
          <div className={styles.text} />
          <div className={styles.text} />
        </div>
      </div>
      <div className={styles.buttonWrapper}>
        <div className={styles.button}></div>
        <div className={styles.button}></div>
        <div className={styles.button}></div>
      </div>
    </div>
  );
};

export default ApartmentInformationLoading;
