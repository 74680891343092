import { FC, useContext } from 'react';
import { IApplication } from '@wohnsinn/ws-ts-lib';
import { useTranslation } from 'react-i18next';
import Text, { TEXT_COLOR, TEXT_TYPE, TEXT_VARIANT, TEXT_WEIGHT } from '../../atoms/typographie/Text';
import SanitizedHTML from '../../atoms/SanitzedHtml';
import styles from './ChatListItem.module.scss';
import UserContext from '../../../core/context/user.context';
import Badge, { BADGE_COLORS } from '../../atoms/Badge';

export interface IChatListItemProps {
  itemClickHandler: (application: IApplication) => void;
  isActive?: boolean;
  hasUnreadMessages?: boolean;
  application?: IApplication;
}

const ChatListItem: FC<IChatListItemProps> = ({ isActive, itemClickHandler, application, hasUnreadMessages }) => {
  const { t: c } = useTranslation('common', { keyPrefix: 'component.molecules.ChatListItem' });
  const { user } = useContext(UserContext);

  return (
    <li className={`${styles.wrapper} ${isActive && styles.isActive}`} onClick={() => itemClickHandler(application)}>
      <div className={`${styles.newMessageIndicator} ${hasUnreadMessages ? styles.show : ''}`}>
        {hasUnreadMessages && <span className="sr-only">new message</span>}
      </div>

      <div className={styles.badgeWrapper}>
        {application?.lastMessageSenderId === application.landlordId ? (
          <Badge text={c('nowYou')} color={BADGE_COLORS.PRIMARY} />
        ) : null}
      </div>

      <div className={styles.apartmentInfoWrapper}>
        <div className={styles.apartmentImage} style={{ backgroundImage: `url(${application.media?.url})` }} />

        <div className={styles.apartmentText}>
          <Text
            type={TEXT_TYPE.TEXT_TYPE_BODY}
            variant={TEXT_VARIANT.TEXT_VARIANT_SMALL}
            weight={TEXT_WEIGHT.TEXT_WEIGHT_REGULAR}
            tag={'span'}
          >
            {application.address.hasPublishedAddress ? (
              <>
                {application.address.street} {application.address.houseNumber}
              </>
            ) : (
              <>
                {application.address.postalCode} {application.address.city}
              </>
            )}
          </Text>{' '}
          {!application.address.hasPublishedAddress ? null : (
            <>
              <br />
              <Text
                color={TEXT_COLOR.TEXT_COLOR_ACCENT}
                type={TEXT_TYPE.TEXT_TYPE_BODY}
                variant={TEXT_VARIANT.TEXT_VARIANT_SMALL}
                tag={'span'}
              >
                {application.address.postalCode} {application.address.city}
              </Text>
            </>
          )}
          <div className={styles.message}>
            {application?.lastMessage && (
              <div className={styles.text}>
                <Text tag={'span'} weight={TEXT_WEIGHT.TEXT_WEIGHT_LIGHT}>
                  {application.lastMessageSenderId === user.uid ? 'Du: ' : ''}
                </Text>
                <SanitizedHTML
                  className={` ${hasUnreadMessages && styles.bold}`}
                  dirty={application.lastMessage}
                  removeNewLines
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </li>
  );
};

export default ChatListItem;
