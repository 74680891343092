import { FC, PropsWithChildren } from 'react';
import Headline, { HEADLINE_SIZE } from '../../atoms/typographie/Headline';
import Text from '../../atoms/typographie/Text';
import { IImage } from '../../../core/const/images';
import Spacer from '../../atoms/Spacer';
import { useTranslation } from 'react-i18next';

export interface TextBlockSectionProps {
  title?: string;
  titleTwo?: string;
  icon?: IImage;
  headlineSize?: HEADLINE_SIZE;
}

const TextBlockSection: FC<PropsWithChildren<TextBlockSectionProps>> = ({
  children,
  title,
  titleTwo,
  icon,
  headlineSize = HEADLINE_SIZE.H3,
}) => {
  const { t: i } = useTranslation('images');
  return (
    <>
      {icon && <img src={i(icon.src)} alt={i(icon.alt)} />}
      <Spacer />
      <Headline size={headlineSize}>{title}</Headline>
      {titleTwo ? <Headline size={headlineSize}>{titleTwo}</Headline> : null}
      <Text tag={'div'}>{children}</Text>
    </>
  );
};

export default TextBlockSection;
