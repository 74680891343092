import { FC, useEffect } from 'react';
import '@wistia/wistia-player';
import { WistiaPlayer } from '@wistia/wistia-player/react';
import styles from './WistiaPlayer.module.scss';

const CustomWistiaPlayer: FC<{ videoId: string; wrapper: string; thumbnailId: string }> = ({
  videoId,
  thumbnailId = videoId,
}) => {
  useEffect(() => {
    // Wistia embed code
    const script1 = document.createElement('script');
    script1.src = `https://fast.wistia.com/embed/medias/${videoId}.jsonp`;
    script1.async = true;
    const script2 = document.createElement('script');
    script2.src = 'https://fast.wistia.com/assets/external/E-v1.js';
    script2.async = true;

    // Append scripts to the document head
    document.head.appendChild(script1);
    document.head.appendChild(script2);

    // Cleanup function
    return () => {
      if (script1) {
        document.head.removeChild(script1);
      }
      if (script2) {
        // Remove the appended scripts
        document.head.removeChild(script2);
      }
    };
  }, [videoId]);

  const handleClick = () => {
    // Programmatically trigger the Wistia modal
    const modalTrigger = document.querySelector(`.wistia_embed.wistia_async_${videoId}`);
    if (modalTrigger) {
      // @ts-ignore
      modalTrigger.click();
    }
  };

  return (
    <button className={styles.wrapper} onClick={handleClick}>
      <WistiaPlayer mediaId={thumbnailId} />
      <div
        className={`wistia_embed wistia_async_${videoId} popover=true popoverContent=link videoFoam=false ${styles.imageWrapper}`}
      />
      <img className={styles.playButton} src={'/assets/icon/playButton.svg'} alt={'playButton'} />
    </button>
  );
};

export default CustomWistiaPlayer;
