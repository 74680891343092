import styles from './VirtualTourStatusIndicator.module.scss';
import { FC } from 'react';
import Text, { TEXT_TYPE } from '../typographie/Text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCircleExclamation, faPlayCircle } from '@fortawesome/pro-light-svg-icons';

const VirtualTourStatusIndicator: FC<{ has3DTour: boolean }> = ({ has3DTour }) => {
  let icon: IconProp, label, color;

  if (has3DTour) {
    color = 'green';
    icon = faPlayCircle;
    label = '3D-Tour Live';
  } else {
    color = 'red';
    icon = faCircleExclamation;
    label = 'Keine 3D-Tour';
  }

  return (
    <div className={`${styles.virtualTourStatus} ${styles[color]}`}>
      <FontAwesomeIcon icon={icon} size={'sm'} />
      <Text type={TEXT_TYPE.TEXT_TYPE_CAPTION} tag={'span'}>
        {label}
      </Text>
    </div>
  );
};

export default VirtualTourStatusIndicator;
