import { Alert, Snackbar } from '@mui/material';
import { useContext } from 'react';
import SnackBarContext from '../../../core/context/snackbar.context';
import { AlertColor } from '@mui/material/Alert/Alert';
import { useTranslation } from 'react-i18next';

export interface ISnackBarProps {
  message: string;
  type: AlertColor;
}

const SnackBarLoader = () => {
  const { t } = useTranslation('common');
  const { isSnackBarOpen, setIsSnackBarOpen, snackBarProps } = useContext(SnackBarContext);

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      autoHideDuration={3000}
      onClose={() => setIsSnackBarOpen(false)}
      open={isSnackBarOpen}
    >
      <Alert
        severity={snackBarProps.type}
        sx={{ width: '100%' }}
        variant={'filled'}
        onClose={() => setIsSnackBarOpen(false)}
      >
        {t(snackBarProps.message)}
      </Alert>
    </Snackbar>
  );
};
export default SnackBarLoader;
