import styles from './Step.module.scss';
import { FC } from 'react';

const Step: FC<{ number: string | number }> = ({ number }) => {
  return (
    <div className={styles.wrapper}>
      <span>{number}</span>
    </div>
  );
};

export default Step;
