import { TItemListItem } from 'component/molecules/ItemList';
import { ITenantProfile } from '@wohnsinn/ws-ts-lib';
import printDate from './printDate';
import { TENANT_JOB_SELECT_OPTION_LIST } from 'component/forms/tenant/TenantProfileForm';

export const getSelfDisclosureItems = (tenantProfile: ITenantProfile, t: any): TItemListItem[] => {
  return [
    { key: 'income', label: t('monthlyIncome'), value: `${tenantProfile.household.monthlyIncome} €` },

    { key: 'hasPledge', label: t('hasPledge'), value: tenantProfile.household.hasPledge ? t('yes') : t('no') },
    {
      key: 'canBeSubsidized',
      label: t('canBeSubsidized'),
      value: tenantProfile.household.canBeSubsidized ? t('yes') : t('no'),
    },
    {
      key: 'numberOfPeopleMovingIn',
      label: t('numberOfPeopleMovingIn'),
      value: tenantProfile.household.numberOfPeopleMovingIn ? tenantProfile.household.numberOfPeopleMovingIn : 0,
    },
    {
      key: 'numberOfChildrenMovingIn',
      label: t('numberOfChildrenMovingIn'),
      value: tenantProfile.household.numberOfChildrenMovingIn ? tenantProfile.household.numberOfChildrenMovingIn : 0,
    },
  ];
};

export const getPersonalInformationItems = (tenantProfile: ITenantProfile, t: any): TItemListItem[] => {
  const items = [
    {
      key: 'phoneNumber',
      label: t('phoneNumber.label'),
      value: tenantProfile.personalInformation.phoneNumber,
    },
    { key: 'email', label: t('email.label'), value: tenantProfile.email },
    {
      key: 'dateOfBirth',
      label: t('dateOfBirth.label'),
      value: printDate(tenantProfile.personalInformation.dateOfBirth, t),
    },
  ];
  if (tenantProfile?.personalInformation?.firstName && tenantProfile?.personalInformation?.firstName) {
    items.push(
      { key: 'firstName', label: t('firstName.label'), value: tenantProfile?.personalInformation?.firstName },
      { key: 'lastName', label: t('lastName.label'), value: tenantProfile?.personalInformation?.lastName }
    );
  }
  return items;
};

export const getJobStatusItems = (tenantProfile: ITenantProfile, t: any): TItemListItem[] => {
  const jobStatusItems: TItemListItem[] = [
    {
      key: 'jobStatus',
      label: t('jobStatus'),
      value: TENANT_JOB_SELECT_OPTION_LIST.filter(
        (list) => list.value === tenantProfile.personalInformation.jobStatus
      )[0].label,
    },
  ];

  if (tenantProfile.personalInformation.customJobDescription) {
    jobStatusItems.push({
      key: 'customJobDescription',
      label: t('customJobDescription'),
      value: tenantProfile.personalInformation.customJobDescription,
    });
  }

  if (tenantProfile.personalInformation.employerName) {
    jobStatusItems.push({
      key: 'employerName',
      label: t('employerName'),
      value: tenantProfile.personalInformation.employerName,
    });
  }
  return jobStatusItems;
};

export const getLegalItems = (tenantProfile: ITenantProfile, t: any, c: any): TItemListItem[] => [
  {
    key: 'hadEvictions',
    label: t('hadEvictions'),
    value: tenantProfile.personalInformation.hadEvictions ? c('yes') : c('no'),
  },
  {
    key: 'hasInsolvencyProceedings',
    label: t('hasInsolvencyProceedings'),
    value: tenantProfile.personalInformation.hasInsolvencyProceedings ? c('yes') : c('no'),
  },
];
