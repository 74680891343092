import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { ENERGY_PASS_STATUS, ENERGY_PASS_TYPE, IApartmentEnergySupply } from '@wohnsinn/ws-ts-lib';
import { FormContext } from 'core/context/form.context';
import { useTranslation } from 'react-i18next';
import { SUBMIT_BUTTON_MODE } from 'core/enum/submit-button-mode.enum';
import { FieldErrorsImpl, useForm } from 'react-hook-form';
import Dropdown from 'component/atoms/formElement/Dropdown';
import { BOOLEAN_SELECT_OPTIONS, getTranslatedOptions } from 'core/const/select-options';
import {
  APARTMENT_CONDITION_SELECT_OPTION_LIST,
  APARTMENT_HEATING_SYSTEM_SELECT_OPTION_LIST,
  APARTMENT_HEATING_TYPE_SELECT_OPTION_LIST,
  ENERGY_PASS_STATUS_LIST,
  ENERGY_PASS_TYPE_SELECT_OPTION_LIST,
} from './energy-supply-form-select-options';
import InputText from 'component/atoms/formElement/InputText';
import InputOptionList, { RADIO_INPUT_COLUMNS_COUNT } from 'component/atoms/formElement/InputOptionList';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { wohnsinnServices } from 'App';
import { Grid } from '@mui/material';
import SnackBarContext from 'core/context/snackbar.context';
import { useQueryClient } from '@tanstack/react-query';
import useFormErrorHook from 'core/hook/use-form-error.hook';
import { APARTMENT_ENERGY_SUPPLY_SCHEMA } from 'core/schemas/apartment';
import { ICreateApartmentTunnel } from 'view/landlord/apartment/ApartmentCreateView';
import useApartment from 'core/hook/apartment.hook';
import { ROUTES } from 'core/const/routes';
import { useNavigate } from 'react-router-dom';
import FormNavigation from 'component/molecules/FormNavigation';
import { TEXT_COLOR } from 'component/atoms/typographie/Text';
import FormHeader from 'component/atoms/FormHeader';
import MultiSelect from 'component/atoms/formElement/MultiSelect';
import BackButton from 'component/atoms/Buttons/BackButton';
import useWindowSize from 'core/hook/windowsize.hook';

const ApartmentEnergySupplyForm: FC<ICreateApartmentTunnel> = ({ isTunnelView }) => {
  const { apartmentService } = wohnsinnServices;
  const queryClient = useQueryClient();
  const { apartment } = useApartment();
  const { handleError } = useFormErrorHook('ApartmentEnergySupplyForm');
  const { t } = useTranslation('common');
  const { t: r } = useTranslation('routes');
  const { t: f } = useTranslation('common', { keyPrefix: 'view.ApartmentOverviewView.EnergySupplyFormView' });
  const { handleSnackBar } = useContext(SnackBarContext);
  const [buttonSubmitMode, setButtonSubmitMode] = useState<SUBMIT_BUTTON_MODE>(SUBMIT_BUTTON_MODE.NONE);
  const navigate = useNavigate();
  const { isSmallerMd } = useWindowSize();

  const { control, handleSubmit, setValue, watch, reset } = useForm<IApartmentEnergySupply>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    resolver: joiResolver(Joi.object(APARTMENT_ENERGY_SUPPLY_SCHEMA)),
    defaultValues: apartment?.energySupply ? apartment.energySupply : null,
  });

  useEffect(() => {
    reset(apartment?.energySupply);
  }, [apartment]);

  const energyPassType = watch('energyPassType');
  const energyPassStatus = watch('energyPassStatus');

  // reset isEnergyConsumptionForHotWaterIncluded if energyPassType is changed
  useCallback(() => {
    if (energyPassType === null || energyPassType === ENERGY_PASS_TYPE.USAGE_PASS) {
      setValue('isEnergyConsumptionForHotWaterIncluded', null);
    }
    if (energyPassType === null) {
      setValue('energyConsumption', null);
    }
  }, [energyPassType]);

  // reset energyPassType if energyPassStatus is changed
  useEffect(() => {
    if (
      energyPassStatus === null ||
      energyPassStatus === ENERGY_PASS_STATUS.WITHOUT ||
      energyPassStatus === ENERGY_PASS_STATUS.NOT_NECESSARY
    ) {
      setValue('energyPassType', null);
    }
  }, [energyPassStatus]);

  const onError = (err: Partial<FieldErrorsImpl<IApartmentEnergySupply>>): void => {
    console.error(err);
    setButtonSubmitMode(SUBMIT_BUTTON_MODE.ERROR);
  };

  const saveDataToDB = async (): Promise<void> => {
    setButtonSubmitMode(SUBMIT_BUTTON_MODE.SUBMITTING);
    try {
      await apartmentService.updateApartment({
        data: { energySupply: watch() },
        creatorId: apartment.creatorId,
        apartmentId: apartment.id,
      });
      await queryClient.invalidateQueries({ queryKey: ['apartments'] });
      handleSnackBar('toast.success.saved', 'success');
      setButtonSubmitMode(SUBMIT_BUTTON_MODE.SUCCESS);
      if (isTunnelView) {
        navigate(r(ROUTES.landlordRoutes.apartment.create.freeTexts.path).replace(':apartmentId', apartment?.id));
      } else {
        navigate(r(ROUTES.landlordRoutes.apartment.overview.path).replace(':apartmentId', apartment?.id));
      }
    } catch (e) {
      handleError(e);
      setButtonSubmitMode(SUBMIT_BUTTON_MODE.ERROR);
    }
  };

  return (
    <FormContext.Provider value={{ control }}>
      <form
        onSubmit={handleSubmit(saveDataToDB, onError)}
        noValidate
        autoComplete="off"
        id={'apartmentEnergySupplyForm'}
      >
        {!isTunnelView && !isSmallerMd && (
          <div style={{ paddingBottom: '1rem' }}>
            <BackButton />
          </div>
        )}
        <FormHeader title={f('buildingInformationHeader')} />
        <Grid container className={'form-with-navigation'}>
          <Grid item xs={12} md={6}>
            <InputText
              type={'text'}
              label={f('field.buildYear.label')}
              name={'buildYear'}
              placeholder={f('field.buildYear.placeholder')}
            />

            <Dropdown
              name={'condition'}
              label={t('apartment.energySupply.condition.label')}
              optionList={getTranslatedOptions(APARTMENT_CONDITION_SELECT_OPTION_LIST, t)}
            />

            <InputText
              name={'yearOfLastActivity'}
              label={t('apartment.energySupply.yearOfLastActivityLabel')}
              placeholder={t('apartment.energySupply.yearOfLastActivityPlaceholder')}
            />

            <MultiSelect
              optionList={getTranslatedOptions(APARTMENT_HEATING_SYSTEM_SELECT_OPTION_LIST, t)}
              name={'heatingSystem'}
              label={t('apartment.energySupply.heatingSystem.label')}
            />
            <MultiSelect
              optionList={getTranslatedOptions(APARTMENT_HEATING_TYPE_SELECT_OPTION_LIST, t)}
              name={'heatingType'}
              label={t('apartment.energySupply.heatingType.label')}
            />
            <Dropdown
              name={'energyPassStatus'}
              optionList={getTranslatedOptions(ENERGY_PASS_STATUS_LIST, t)}
              label={t('Energieausweis')}
            />

            {energyPassStatus !== ENERGY_PASS_STATUS.WITHOUT &&
            energyPassStatus !== ENERGY_PASS_STATUS.AVAILABLE_AT_VIEWING &&
            energyPassStatus !== ENERGY_PASS_STATUS.NOT_NECESSARY ? (
              <>
                <Dropdown
                  name={'energyPassType'}
                  optionList={getTranslatedOptions(ENERGY_PASS_TYPE_SELECT_OPTION_LIST, t)}
                  label={'Art des Energieausweises'}
                />
                <InputText label="Energieeffizienzklasse" name={'energyEfficiency'} />
              </>
            ) : null}

            {energyPassType === ENERGY_PASS_TYPE.CONSUMPTION_PASS && (
              <InputOptionList
                mode={'radio'}
                name={'isEnergyConsumptionForHotWaterIncluded'}
                options={getTranslatedOptions(BOOLEAN_SELECT_OPTIONS, t)}
                columns={RADIO_INPUT_COLUMNS_COUNT.TWO}
                label={'Ist der Energieverbrauch für Warmwasser enthalten?'}
              />
            )}
            {energyPassType !== null && (
              <InputText
                name={'energyConsumption'}
                type={'number'}
                pattern="[0-9]*"
                endAdornment={'kWh/(m²a)'}
                label={
                  energyPassType === ENERGY_PASS_TYPE.CONSUMPTION_PASS
                    ? 'Energieverbrauchskennwert'
                    : 'Endenergiebedarfskennwert'
                }
              />
            )}
          </Grid>
        </Grid>
        <FormNavigation
          showSecondaryButtonMobile={isTunnelView}
          flipButtons={true}
          buttonSubmitMode={buttonSubmitMode}
          disabled={false}
          formId={'apartmentEnergySupplyForm'}
          submitButtonText={isTunnelView ? t('saveAndContinue') : t('save')}
          secondaryButtonAction={() => navigate(-1)}
          secondaryButtonColor={TEXT_COLOR.TEXT_COLOR_ACCENT}
          secondaryButtonText={t('back')}
        />
      </form>
    </FormContext.Provider>
  );
};

export default ApartmentEnergySupplyForm;
