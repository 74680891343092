import { APARTMENT_TYPE_OF_MARKETING, ITenantFilterParams, ITenantURLParams } from '@wohnsinn/ws-ts-lib';

/**
 * takes urlParams and converts them to tenantFilterParams
 * @param urlParams
 */
export const convertUrlParamsToTFP = (urlParams: ITenantURLParams): ITenantFilterParams => {
  const tfp: ITenantFilterParams = {};
  const parseBoolean = (value: string) => value === 'true';

  if (urlParams.location) {
    tfp.location = JSON.parse(urlParams.location);
  }
  if (urlParams.equipmentList) {
    tfp.equipmentList = JSON.parse(urlParams.equipmentList);
  }
  if (urlParams.numberOfPeopleMovingIn) {
    tfp.numberOfPeopleMovingIn = parseInt(urlParams.numberOfPeopleMovingIn);
  }
  if (urlParams.rooms) {
    tfp.rooms = parseInt(urlParams.rooms);
  }
  if (urlParams.isSharedUsagePossible) {
    tfp.isSharedUsagePossible = parseBoolean(urlParams.isSharedUsagePossible);
  }
  if (urlParams.canBeSubsidized) {
    tfp.canBeSubsidized = parseBoolean(urlParams.canBeSubsidized);
  }
  if (urlParams.arePetsAllowed) {
    tfp.arePetsAllowed = parseBoolean(urlParams.arePetsAllowed);
  }
  if (urlParams.warmRent) {
    tfp.warmRent = parseInt(urlParams.warmRent);
  }
  if (urlParams.size) {
    tfp.size = parseInt(urlParams.size);
  }
  if (urlParams.purchasePrice) {
    tfp.purchasePrice = parseInt(urlParams.purchasePrice);
  }
  if (urlParams.typeOfMarketing) {
    tfp.typeOfMarketing = urlParams.typeOfMarketing as APARTMENT_TYPE_OF_MARKETING;
  }

  return tfp;
};
