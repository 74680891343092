import { FC, PropsWithChildren } from 'react';
import AppHeader from '../../molecules/AppHeader';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import styles from './ApartmentStackPageLayout.module.scss';

export interface ILayoutProps {
  backButtonRoute?: string;
  headerEndButton?: any;
  headerStartButton?: any;
  pageTitle: string;
}

const ApartmentStackPageLayout: FC<PropsWithChildren<ILayoutProps>> = ({
  backButtonRoute,
  children,
  headerEndButton,
  headerStartButton,
  pageTitle,
}) => {
  const { t } = useTranslation('common');

  return (
    <div className={styles.appWrapper}>
      <div id="lightshow-portal" />
      <Helmet>
        <title>{`${t('appTitle')} - ${t(pageTitle)}`}</title>
      </Helmet>

      <header className={styles.appHeader}>
        <AppHeader
          pageTitle={pageTitle}
          showBackButton={true}
          backButtonRoute={backButtonRoute}
          headerStartButton={headerStartButton}
          headerEndButton={headerEndButton}
        />
      </header>

      <main>{children}</main>
    </div>
  );
};

export default ApartmentStackPageLayout;
