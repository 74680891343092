import ModalWrapper from '../ModalWrapper';
import { FC, useContext, useState } from 'react';
import ModalContext from 'core/context/modal.context';
import Text from 'component/atoms/typographie/Text';
import { useTranslation } from 'react-i18next';
import UserContext from 'core/context/user.context';
import { USER_TYPE } from '@wohnsinn/ws-ts-lib';
import { wohnsinnServices } from 'App';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'core/const/routes';
import { SUBMIT_BUTTON_MODE } from 'core/enum/submit-button-mode.enum';
import { getSubmitIcon } from 'component/atoms/Buttons/CTAButton';

export enum USER_TYPE_STATE {
  LANDLORD_WITH_TENANT_PROFILE = 'LANDLORD_WITH_TENANT_PROFILE',
  LANDLORD_WITHOUT_TENANT_PROFILE = 'LANDLORD_WITHOUT_TENANT_PROFILE',
  TENANT_WITH_LANDLORD_PROFILE = 'TENANT_WITH_LANDLORD_PROFILE',
  TENANT_WITHOUT_LANDLORD_PROFILE = 'TENANT_WITHOUT_LANDLORD_PROFILE',
}

interface ModalTexts {
  title: string;
  text: string;
  button: string;
}

const SwitchUserTypeModal: FC = () => {
  const { closeModal } = useContext(ModalContext);
  const { user, landlordProfile, tenantProfile } = useContext(UserContext);
  const { t } = useTranslation('common');
  const { t: r } = useTranslation('routes');
  const { userService } = wohnsinnServices;
  const isLandlord = user?.activeUserType === USER_TYPE.LANDLORD;
  const isTenant = user?.activeUserType === USER_TYPE.TENANT;
  const navigate = useNavigate();
  const [buttonSubmitMode, setButtonSubmitMode] = useState(SUBMIT_BUTTON_MODE.NONE);

  const getUserTypeState = (): USER_TYPE_STATE | 'error' => {
    if (isLandlord && user?.isTenant) return USER_TYPE_STATE.LANDLORD_WITH_TENANT_PROFILE;
    if (isLandlord && !user?.isTenant) return USER_TYPE_STATE.LANDLORD_WITHOUT_TENANT_PROFILE;
    if (isTenant && user?.isLandlord) return USER_TYPE_STATE.TENANT_WITH_LANDLORD_PROFILE;
    if (isTenant && !user?.isLandlord) return USER_TYPE_STATE.TENANT_WITHOUT_LANDLORD_PROFILE;
    return 'error';
  };
  const profile = getUserTypeState();

  const clickHandler = async () => {
    setButtonSubmitMode(SUBMIT_BUTTON_MODE.SUBMITTING);
    try {
      if (
        profile === USER_TYPE_STATE.TENANT_WITHOUT_LANDLORD_PROFILE ||
        profile === USER_TYPE_STATE.LANDLORD_WITHOUT_TENANT_PROFILE
      ) {
        await handleUserTypeCreation(
          user?.activeUserType === USER_TYPE.LANDLORD ? USER_TYPE.TENANT : USER_TYPE.LANDLORD
        );
        closeModal();
        navigate(r(ROUTES.staticRoutes.redirect.path));
      } else {
        await handleActiveUserTypeSwitch();
        closeModal();
        navigate(r(ROUTES.staticRoutes.redirect.path));
      }
      setButtonSubmitMode(SUBMIT_BUTTON_MODE.SUCCESS);
    } catch (e) {
      setButtonSubmitMode(SUBMIT_BUTTON_MODE.ERROR);
      console.error('Error on switchUserType', e);
    }
  };

  const renderTexts = (profile: USER_TYPE_STATE | 'error'): ModalTexts => {
    switch (profile) {
      case USER_TYPE_STATE.LANDLORD_WITH_TENANT_PROFILE:
        return {
          title: 'Zum Mietermodus',
          text: 'Möchtest du zum Mietermodus wechseln?',
          button: 'Wechseln',
        };
      case USER_TYPE_STATE.LANDLORD_WITHOUT_TENANT_PROFILE:
        return {
          title: 'Mieterprofil erstellen',
          text: 'Finde deine neue Wohnung',
          button: 'Jetzt Wohnung finden',
        };
      case USER_TYPE_STATE.TENANT_WITH_LANDLORD_PROFILE:
        return {
          title: 'Zum Vermietermodus',
          text: 'Möchtest du zum Vermietermodus wechseln?',
          button: 'Wechseln',
        };
      case USER_TYPE_STATE.TENANT_WITHOUT_LANDLORD_PROFILE:
        return {
          title: 'Vermieter-Profil erstellen',
          text: 'Möchtest du Objekte inserieren?',
          button: 'Jetzt inserieren',
        };
      default:
        return { title: 'error', text: 'error', button: 'error' };
    }
  };

  const text = renderTexts(profile);

  const handleUserTypeCreation = async (newUserType: USER_TYPE) => {
    await userService.createNewProfileType(
      newUserType,
      newUserType === USER_TYPE.LANDLORD ? tenantProfile : landlordProfile
    );
    await handleActiveUserTypeSwitch();
  };

  const handleActiveUserTypeSwitch = async () => {
    await userService.setActiveUserType(
      user.uid,
      user?.activeUserType === USER_TYPE.LANDLORD ? USER_TYPE.TENANT : USER_TYPE.LANDLORD
    );
  };

  return (
    <ModalWrapper
      title={text.title}
      buttons={{
        primary: {
          buttonText: text.button,
          onClick: () => clickHandler(),
          spinIcon: buttonSubmitMode === SUBMIT_BUTTON_MODE.SUBMITTING,
          icon: getSubmitIcon(buttonSubmitMode),
        },
        secondary: {
          buttonText: t('close'),
          onClick: closeModal,
        },
      }}
    >
      <Text align={'center'}>{text.text}</Text>
    </ModalWrapper>
  );
};

export default SwitchUserTypeModal;
