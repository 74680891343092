enum LOCAL_STORAGE_KEYS {
  CURRENT_MODAL = 'CURRENT_MODAL',
  EMAIL = 'EMAIL',
  HAS_SEEN_BOOKMARK_INFO = 'HAS_SEEN_BOOKMARK_INFO',
  PHONE_NUMBER = 'PHONE_NUMBER',
  REDIRECT_URL = 'REDIRECT_URL',
  USER_HAS_SEEN_HOW_TO_SLIDES = 'USER_HAS_SEEN_HOW_TO_SLIDES',
  USER_TYPE = 'USER_TYPE',
}

export default LOCAL_STORAGE_KEYS;
