import { getCountFromServer } from 'firebase/firestore';
import {
  FIRESTORE_COLLECTION_PATH,
  ILandlordPersonalInformation,
  ILandlordProfile,
  LANDLORD_ROLES,
  OPEN_IMMO_SOFTWARES,
  TLandlordProfileCreate,
} from '@wohnsinn/ws-ts-lib';
import FB_FUNCTION_URLS from '../const/fb-function-names';
import { FirebaseFunctionsService } from './firebase-functions.service';
import FirestoreService from './firestore.service';
import { UserInfo } from 'firebase/auth';
import { User } from '@firebase/auth';

class LandlordService {
  constructor(private firestoreService: FirestoreService, private firebaseFunctionsService: FirebaseFunctionsService) {}

  public async createLandlordProfile(user: UserInfo | User) {
    const landlordProfile: TLandlordProfileCreate = {
      email: user.email,
      displayName: user.displayName,
      photoUrl: user.photoURL,
      uid: user.uid,
    };

    try {
      await this.firestoreService.setDbDoc<TLandlordProfileCreate>(
        landlordProfile,
        `${FIRESTORE_COLLECTION_PATH.users.landlordProfiles.root.replace('{uid}', user.uid)}/${user.uid}`,
        true,
        {
          setUpdatedAt: true,
          setCreatedAt: true,
        }
      );
    } catch (e) {
      console.error('Error on createLandlordProfile', e);
    }
  }

  public async getLandlordProfile(uid: string): Promise<ILandlordProfile | void> {
    const path = `${FIRESTORE_COLLECTION_PATH.users.landlordProfiles.root.replace('{uid}', uid)}/${uid}`;

    try {
      return await this.firestoreService.getDbDoc<ILandlordProfile>(path);
    } catch (err) {
      console.error(err);
    }
  }

  /**
   * Update Landlords personal inforamtion
   * @param uid
   * @param personalInformation
   */
  public updateLandlordPersonalInformation(
    uid: string,
    personalInformation: ILandlordPersonalInformation
  ): Promise<void> {
    const path = `${FIRESTORE_COLLECTION_PATH.users.landlordProfiles.root.replace('{uid}', uid)}/${uid}`;

    return this.firestoreService.setDbDoc<{ personalInformation: ILandlordPersonalInformation; updatedAt: Date }>(
      { personalInformation, updatedAt: new Date() },
      path
    );
  }
  /**
   * Add organization to a landlordProfile
   * @param uid
   * @param organizationId
   * @param role
   */
  public addOrganizationToLandlord(uid: string, organizationId: string, role: LANDLORD_ROLES): Promise<void> {
    const path = `${FIRESTORE_COLLECTION_PATH.users.landlordProfiles.root.replace('{uid}', uid)}/${uid}`;

    return this.firestoreService.setDbDoc(
      {
        isCommercialLandlord: true,
        isOrganizationMembershipConfirmed: true,
        organizationId: organizationId,
        updatedAt: new Date(),
        roles: [role],
      },
      path
    );
  }

  public async requestFTPData(
    email: string,
    uid: string,
    openImmoTools: {
      software: OPEN_IMMO_SOFTWARES;
      custom?: string;
    }
  ): Promise<void> {
    try {
      await this.firebaseFunctionsService.callFbFunction(FB_FUNCTION_URLS.user.requestFTPDataMail, {
        body: {
          email,
          software:
            openImmoTools.software !== OPEN_IMMO_SOFTWARES.OTHER ? openImmoTools.software : openImmoTools.custom,
        },
      });
    } catch (e) {
      console.error(e);
    }
  }

  public updateLandlordProfilePicture(uid: string, photoUrl: string): Promise<void> {
    const path = `${FIRESTORE_COLLECTION_PATH.users.landlordProfiles.root.replace('{uid}', uid)}/${uid}`;
    return this.firestoreService.setDbDoc<Partial<ILandlordProfile>>({ photoUrl }, path);
  }

  public async getLandLordCount(): Promise<number> {
    const coll = this.firestoreService.getCollectionRef('users');
    const snapshot = await getCountFromServer(coll);
    return snapshot.data().count;
  }

  public updateIsLandlordCommercial(uid: string, isCommercialLandlord: boolean) {
    const path = `${FIRESTORE_COLLECTION_PATH.users.landlordProfiles.root.replace('{uid}', uid)}/${uid}`;
    return this.firestoreService.setDbDoc<Partial<ILandlordProfile>>(
      { isCommercialLandlord: isCommercialLandlord },
      path
    );
  }

  public updateLandlordOrganization(
    uid: string,
    organizationId: string | null,
    isOrganizationMembershipConfirmed: boolean
  ): Promise<void> {
    const path = `${FIRESTORE_COLLECTION_PATH.users.landlordProfiles.root.replace('{uid}', uid)}/${uid}`;
    return this.firestoreService.setDbDoc<Partial<ILandlordProfile>>(
      { organizationId, isOrganizationMembershipConfirmed },
      path
    );
  }
}

export default LandlordService;
