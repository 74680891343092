import { FC } from 'react';

const VersionView: FC = () => {
  return (
    <ul>
      {Object.keys(process.env).map((key) => (
        <li key={key}>
          {key}: {process.env[key]}
        </li>
      ))}
    </ul>
  );
};

export default VersionView;
