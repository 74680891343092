import FormSectionTitle from 'component/atoms/FormSectionTitle';
import InputOptionList, { RADIO_INPUT_COLUMNS_COUNT } from 'component/atoms/formElement/InputOptionList';
import { BOOLEAN_MWST_LIST, BOOLEAN_SELECT_OPTIONS } from 'core/const/select-options';
import InputText from 'component/atoms/formElement/InputText';
import { useTranslation } from 'react-i18next';
import TextEditor, { TEXT_EDITOR_SIZE } from 'component/atoms/formElement/TextEditor';
import { Grid } from '@mui/material';
import ParkingSlotsInputs from 'component/forms/apartment/ApartmentCostsForm/ParkingSlotsInputs';

const ApartmentCostFormSale = () => {
  const { t } = useTranslation('common');
  const { t: ac } = useTranslation('common', { keyPrefix: 'apartment.cost' });

  return (
    <>
      <Grid item xs={12}>
        <FormSectionTitle title={ac('sale')} />
      </Grid>
      <Grid item xs={12} md={6}>
        <InputOptionList
          options={BOOLEAN_SELECT_OPTIONS}
          columns={RADIO_INPUT_COLUMNS_COUNT.TWO}
          mode={'radio'}
          label={ac('purchasePrice.onRequest.label')}
          name={'purchasePrice.onRequest'}
        />
      </Grid>
      <Grid item xs={12} md={6}></Grid>
      <Grid item xs={12} md={6}>
        <InputText
          pattern="[0-9]*"
          type={'number'}
          name={'purchasePrice.value'}
          label={ac('purchasePrice.value.label')}
          unit={t('currency')}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <InputOptionList
          options={BOOLEAN_MWST_LIST}
          columns={RADIO_INPUT_COLUMNS_COUNT.TWO}
          mode={'radio'}
          name={'plusVAT'}
        />
      </Grid>
      <Grid item xs={12}>
        <FormSectionTitle title={ac('more')} />
      </Grid>
      <Grid item xs={12} md={6}>
        <InputText
          pattern="[0-9]*"
          type={'number'}
          name={'developmentCosts'}
          label={ac('developmentCosts.label')}
          unit={t('currency')}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <InputText
          pattern="[0-9]*"
          type={'number'}
          name={'houseMoney'}
          label={ac('houseMoney.label')}
          unit={t('currency')}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <InputOptionList
          options={BOOLEAN_SELECT_OPTIONS}
          columns={RADIO_INPUT_COLUMNS_COUNT.TWO}
          mode={'radio'}
          label={ac('outsideCommission.includesVAT')}
          name={'outsideCommission.includesVAT'}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <InputText type={'text'} label={ac('outsideCommission.content')} name={'outsideCommission.content'} />
      </Grid>

      <ParkingSlotsInputs />
      <Grid item xs={12}>
        <TextEditor
          label={ac('freeTextPrice.label')}
          placeholder={ac('freeTextPrice.placeholder')}
          name={'freeTextPrice'}
          size={TEXT_EDITOR_SIZE.LARGE}
        />
      </Grid>
    </>
  );
};

export default ApartmentCostFormSale;
