import Headline, { HEADLINE_SIZE } from 'component/atoms/typographie/Headline';
import Text, { TEXT_COLOR } from 'component/atoms/typographie/Text';
import SplitLine from 'component/atoms/SplitLine';

const InfoTextContent = () => {
  return (
    <>
      <Headline size={HEADLINE_SIZE.H2}>Warum ist das wichtig?</Headline>
      <Text color={TEXT_COLOR.TEXT_COLOR_ACCENT}>
        Damit wir deine Bewerbung für die Wohnung bestmöglich gestalten können, bitten wir dich um einige Informationen
        zu dir, deinem Haushalt und deinem Einkommen. Diese Angaben sind entscheidend, um dem Vermieter ein umfassendes
        Bild zu vermitteln und deine Chancen auf eine erfolgreiche Bewerbung zu maximieren.
      </Text>

      <SplitLine />

      <Headline size={HEADLINE_SIZE.H2}>Wozu das Ganze?</Headline>
      <Text color={TEXT_COLOR.TEXT_COLOR_ACCENT}>
        Der Vermieter benötigt diese Details, um sicherzustellen, dass die Wohnung zu deinen Bedürfnissen und
        finanziellen Möglichkeiten passt. Bitte fülle das Formular so vollständig wie möglich aus, damit wir eine
        aussagekräftige Bewerbung erstellen können. Deine Kooperation ermöglicht es uns, den Vermieter von deiner
        Eignung als Mieter zu überzeugen und den Weg für einen reibungslosen Mietprozess zu ebnen.
      </Text>
    </>
  );
};

export default InfoTextContent;
