import PageLayout from '../../../component/layouts/PageLayout';
import { ROUTES } from '../../../core/const/routes';
import { useTranslation } from 'react-i18next';
import LinkElement from '../../../component/atoms/LinkElement';
import { Grid } from '@mui/material';

const AdminOverviewView = () => {
  const { t: r } = useTranslation('routes');
  return (
    <PageLayout pageTitle={r(ROUTES.adminRoutes.overview.title)}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <LinkElement
            text={r(ROUTES.adminRoutes.apartments.title)}
            routerLink={r(ROUTES.adminRoutes.apartments.path)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <LinkElement text={r(ROUTES.adminRoutes.users.title)} routerLink={r(ROUTES.adminRoutes.users.path)} />
        </Grid>
      </Grid>
    </PageLayout>
  );
};

export default AdminOverviewView;
