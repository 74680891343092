import { Auth, connectAuthEmulator, getAuth } from 'firebase/auth';
import { connectFunctionsEmulator, Functions, getFunctions } from 'firebase/functions';
import { getRemoteConfig } from 'firebase/remote-config';
import { connectStorageEmulator, FirebaseStorage, getStorage } from 'firebase/storage';
import { connectFirestoreEmulator, Firestore, getFirestore, initializeFirestore } from 'firebase/firestore';
import { getApps, initializeApp } from 'firebase/app';
import FIREBASE_OPTIONS from '../firebase.config';
import { RemoteConfig } from '@firebase/remote-config';

export interface IFirebaseInstances {
  firebaseAuth: Auth;
  firebaseFunctions: Functions;
  firebaseStorage: FirebaseStorage;
  firestore: Firestore;
  firebaseRemoteConfig: RemoteConfig;
}

const setupFirebase = (): IFirebaseInstances => {
  let firebaseAuth: Auth;
  let firebaseFunctions: Functions;
  let firebaseRemoteConfig: RemoteConfig;
  let firebaseStorage: FirebaseStorage;
  let firestore: Firestore;

  if (getApps().length === 0) {
    const app = initializeApp(FIREBASE_OPTIONS);

    initializeFirestore(app, {
      ignoreUndefinedProperties: true,
    });

    firebaseAuth = getAuth(app);
    firebaseFunctions = getFunctions(app, 'europe-west4');
    firebaseRemoteConfig = getRemoteConfig(app);
    firebaseStorage = getStorage(app);

    firestore = getFirestore(app);
  } else {
    firebaseAuth = getAuth(getApps()[0]);
    firebaseFunctions = getFunctions(getApps()[0], 'europe-west4');
    firebaseRemoteConfig = getRemoteConfig(getApps()[0]);
    firebaseStorage = getStorage(getApps()[0]);
    firestore = getFirestore(getApps()[0]);
  }

  if (process.env.REACT_APP_USE_EMULATOR === 'true') {
    connectFirestoreEmulator(firestore, process.env.REACT_APP_EMULATOR_HOST, 8080);
    connectStorageEmulator(firebaseStorage, process.env.REACT_APP_EMULATOR_HOST, 9199);
    connectFunctionsEmulator(firebaseFunctions, process.env.REACT_APP_EMULATOR_HOST, 5001);
    connectAuthEmulator(firebaseAuth, `http://${process.env.REACT_APP_EMULATOR_HOST}:9099`);
  }

  return {
    firebaseAuth,
    firebaseFunctions,
    firebaseStorage,
    firestore,
    firebaseRemoteConfig,
  };
};

export default setupFirebase;
