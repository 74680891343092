import { FC } from 'react';
import { IMedia, IUpload, IVideoUpload, MEDIA_TYPE } from '@wohnsinn/ws-ts-lib';
import styles from './DocumentUploadListItem.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-light-svg-icons';
import Text, { TEXT_WEIGHT } from '../typographie/Text';
import { faEye } from '@fortawesome/pro-solid-svg-icons';

const renderDocumentIcon = (mediatype?: MEDIA_TYPE): string => {
  switch (mediatype) {
    case MEDIA_TYPE.DOCUMENT:
      return '/assets/icon/bookmark_pictogram.svg';
    case MEDIA_TYPE.VIDEO:
      return '/assets/icon/play-icon.svg';
    case MEDIA_TYPE.IMAGE:
      return '/assets/icon/play-icon.svg';
    default:
      return '/assets/icon/play-icon.svg';
  }
};

export interface IDocumentUploadListItemProps {
  upload?: IUpload | IVideoUpload | IMedia;
  deleteHandler?: any;
  previewHandler?: any;
}

const DocumentUploadListItem: FC<IDocumentUploadListItemProps> = ({ upload, deleteHandler, previewHandler }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.label}>
        <img src={renderDocumentIcon(upload?.mediaType)} alt="play" />
        <div className={styles.nameWrapper}>
          <Text tag={'span'} weight={TEXT_WEIGHT.TEXT_WEIGHT_REGULAR}>
            {upload.alt}
          </Text>
        </div>
      </div>
      <div className={styles.iconWrapper}>
        <button type={'button'} onClick={previewHandler}>
          <FontAwesomeIcon className={styles.icon} color={'black'} icon={faEye} />
        </button>

        {!!deleteHandler && (
          <button type={'button'} onClick={() => deleteHandler()}>
            <FontAwesomeIcon className={`${styles.icon} ${styles.delete}`} color={'black'} icon={faTrash} />
          </button>
        )}
      </div>
    </div>
  );
};

export default DocumentUploadListItem;
