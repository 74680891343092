import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import ApartmentMainInformationForm from 'component/forms/apartment/ApartmentMainInformationForm';
import { ROUTES } from 'core/const/routes';
import PageLayout from 'component/layouts/PageLayout';
import ProgressBar from 'component/atoms/ProgressBar';
import { ICreateApartmentTunnel } from 'view/landlord/apartment/ApartmentCreateView';
import useWindowSize from 'core/hook/windowsize.hook';

const MainInformationFormView: FC<ICreateApartmentTunnel> = ({ isTunnelView = false, progress = 10 }) => {
  const { t: r } = useTranslation('routes');
  const { isSmallerMd } = useWindowSize();

  return (
    <PageLayout
      showPageTitle={false}
      pageTitle={r(ROUTES.landlordRoutes.apartment.mainInformation.title)}
      showBackButton={isSmallerMd && !isTunnelView}
      hideMenu={isTunnelView}
    >
      {isTunnelView && <ProgressBar progress={progress} />}

      <ApartmentMainInformationForm isTunnelView={isTunnelView} />
    </PageLayout>
  );
};

export default MainInformationFormView;
