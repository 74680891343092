import { Drawer } from '@mui/material';
import { FC } from 'react';
import { IApplication, ITenantProfile } from '@wohnsinn/ws-ts-lib';
import styles from './ApplicantModal.module.scss';
import ApplicantInformationModal from '../ApplicantInformation/ApplicantInformationModal';
import Chat from '../Chat';
import Avatar, { AVATAR_SIZE } from 'component/atoms/Avatar';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import RoundIconButton from 'component/atoms/Buttons/RoundIconButton';
import Text from 'component/atoms/typographie/Text';
import { useTranslation } from 'react-i18next';

const ApplicationModal: FC<{
  isOpen: boolean;
  onClose: () => void;
  application: IApplication;
  tenantProfile: ITenantProfile;
  landlordId: string;
  apartmentId: string;
}> = ({ isOpen, onClose, application, tenantProfile, apartmentId, landlordId }) => {
  const { t } = useTranslation('common');
  return (
    <Drawer anchor={'right'} open={isOpen} onClose={onClose}>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.left}>
            <Avatar size={AVATAR_SIZE.md} avatarUrl={application.tenantProfile.photoUrl} />
            <Text>
              {tenantProfile?.personalInformation?.firstName} <br />
              {tenantProfile?.personalInformation?.lastName}
            </Text>
          </div>
          <RoundIconButton onClick={onClose} buttonText={t('close')} icon={faTimes} />
        </div>
        <div className={styles.content}>
          <div className={styles.scrollableContent}>
            <Chat customStyling={styles.chat} key={application.apartmentId} application={application} />
          </div>

          <div className={`${styles.scrollableContent} ${styles.applicant}`}>
            <ApplicantInformationModal
              showHeader={false}
              apartmentId={apartmentId}
              tenantProfile={tenantProfile}
              landlordId={landlordId}
              onClose={onClose}
            />
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default ApplicationModal;
