import Breadcrumbs from '../index';
import { ROUTES } from '../../../../core/const/routes';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { Link } from 'react-router-dom';

const AccountBreadcrumbs: FC<{ page: any }> = ({ page }) => {
  const { t: r } = useTranslation('routes');
  return (
    <Breadcrumbs backRoute={r(ROUTES.tenantRoutes.account.overview.path)}>
      <Link color={'black'} to={r(ROUTES.tenantRoutes.account.overview.path) as string}>
        {r(ROUTES.tenantRoutes.account.overview.title)}
      </Link>
      <Link to={r(page.path) as string}>{r(page.title)}</Link>
    </Breadcrumbs>
  );
};

export default AccountBreadcrumbs;
