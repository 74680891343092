import AppHeader from 'component/molecules/AppHeader';
import Footer from 'component/molecules/Footer';
import PageTitle from 'component/molecules/PageTitle';
import styles from './PageLayout.module.scss';
import { BREAKPOINTS } from 'core/const/breakpoints';
import { FC, PropsWithChildren } from 'react';
import { Grid } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { REGISTRATION_REFERRER } from '@wohnsinn/ws-ts-lib';

export interface ILayoutProps {
  backButtonRoute?: string;
  pageTitle: string;
  secondPageTitle?: string;
  showBackButton?: boolean;
  showPageTitle?: boolean;
  removeMobileVerticalPadding?: boolean;
  headerEndButton?: any;
  hideMenu?: boolean;
  tunnelMode?: boolean;
  hideFooterLinks?: boolean;
  hideFooter?: boolean;
  showStaticMenu?: boolean;
  referrer?: REGISTRATION_REFERRER;
}

const PageLayout: FC<PropsWithChildren<ILayoutProps>> = ({
  backButtonRoute,
  children,
  pageTitle,
  secondPageTitle,
  showBackButton = false,
  showPageTitle = true,
  removeMobileVerticalPadding = false,
  headerEndButton,
  hideMenu = false,
  hideFooter = false,
  hideFooterLinks = false,
  showStaticMenu = false,
  tunnelMode = false,
  referrer,
}) => {
  const { t } = useTranslation('common');

  return (
    <div className={styles.appWrapper}>
      <div id="lightshow-portal" />
      <Helmet>
        <title>{`${t('appTitle')} - ${t(pageTitle)}`}</title>
      </Helmet>

      <header className={styles.appHeader}>
        <AppHeader
          tunnelMode={tunnelMode}
          showStaticMenu={showStaticMenu}
          headerEndButton={headerEndButton}
          pageTitle={pageTitle}
          hideMenu={hideMenu}
          showBackButton={showBackButton}
          backButtonRoute={backButtonRoute}
          referrer={referrer}
        />
      </header>

      <main className={styles.appContent}>
        <Grid
          container
          alignSelf={'center'}
          marginX={'auto'}
          maxWidth={{ lg: BREAKPOINTS.xl }}
          paddingX={removeMobileVerticalPadding ? { xs: 0, sm: 2 } : 2}
          justifyContent="center"
        >
          {showPageTitle ? (
            <Grid item xs={12}>
              <PageTitle pageTitle={pageTitle} secondPageTitle={secondPageTitle} backButtonRoute={backButtonRoute} />
            </Grid>
          ) : null}
          <Grid item xs={12}>
            {children}
          </Grid>
        </Grid>
      </main>
      {!hideFooter ? <Footer /> : null}
    </div>
  );
};

export default PageLayout;
