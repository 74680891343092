import { FC } from 'react';
export interface IVirtualTourProps {
  matterportId?: string;
  playInFrame?: boolean;
  virtualTourLink?: string;
}

const VirtualTour: FC<IVirtualTourProps> = ({ matterportId, playInFrame = false, virtualTourLink }) => {
  const src = virtualTourLink ?? `https://my.matterport.com/show/?m=${matterportId}${playInFrame ? '&play=1' : ''}`;

  return (
    <iframe
      data-testid={'virtualTour'}
      id="showcase"
      width="100%"
      height="500px"
      src={src}
      allowFullScreen
      allow="vr"
      title="Space"
      className="space_space__mhBYE"
    />
  );
};

export default VirtualTour;
