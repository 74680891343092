import { ROUTES } from 'core/const/routes';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import LandingPageLayout from '../../../component/layouts/LandingPageLayout';
import Headline, { HEADLINE_SIZE } from 'component/atoms/typographie/Headline';
import Spacer, { SPACER_SIZE } from 'component/atoms/Spacer';
import ContactCard from 'component/atoms/Card/ContactCard';
import { IMAGES } from 'core/const/images';
import JobItem from 'component/molecules/JobItem';
import { BADGE_COLORS } from 'component/atoms/Badge';

const JobsView = () => {
  const { t: r } = useTranslation('routes');
  return (
    <LandingPageLayout pageTitle={r(ROUTES.landingPageRoutes.jobs.title)}>
      <Grid container justifyContent={'center'} className={'ws-full-width-bg ws-full-width-bg--white'}>
        <Grid item xs={12} md={8} paddingY={{ xs: 4, md: 10 }}>
          <Headline size={HEADLINE_SIZE.DISPLAY} align={'center'}>
            Jobs
          </Headline>
        </Grid>

        <Grid item xs={12} md={8}>
          <JobItem job={'Backend Entwickler'} badgeColor={BADGE_COLORS.PRIMARY} badgeText={'Software Development'} />
        </Grid>
        <Grid item xs={12} md={8}>
          <JobItem job={'Werkstudium / Praktikum'} badgeColor={BADGE_COLORS.DANGER} badgeText={'Alle Bereiche'} />
        </Grid>
        <Grid item xs={12} md={8}>
          <JobItem job={'Sales Manager'} badgeColor={BADGE_COLORS.SUCCESS} badgeText={'Vertrieb'} />
        </Grid>
        <Grid item xs={12} md={8}>
          <JobItem job={'Initiativbewerbung'} badgeColor={BADGE_COLORS.DANGER} badgeText={'Alle Bereiche'} />
        </Grid>

        <Grid item xs={12} paddingTop={{ xs: 4, md: 10 }} paddingBottom={{ xs: 4, md: 10 }}>
          <Grid container justifyContent={'center'}>
            <Grid item xs={12} md={8}>
              <Headline size={HEADLINE_SIZE.DISPLAY} align={'center'}>
                Du möchtest Teil von Wohnsinn werden?
              </Headline>
            </Grid>
          </Grid>
          <Spacer size={SPACER_SIZE.XL} />
          <Grid container spacing={4} justifyContent={'center'}>
            <Grid item xs={12} md={4}>
              <ContactCard
                image={IMAGES.pictograms.emailUs}
                title={'Schreibe uns eine E-Mail'}
                link={'mailto:christian.philippi@wohnsinn.com'}
                linkText={'christian.philippi@wohnsinn.com'}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <ContactCard
                image={IMAGES.pictograms.callUs}
                title={'Rufe uns an'}
                link={'tel:+4922195490428'}
                linkText={'+49 221 954 904 28'}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </LandingPageLayout>
  );
};

export default JobsView;
