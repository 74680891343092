import { FC, useContext, useState } from 'react';
import useFormErrorHook from 'core/hook/use-form-error.hook';
import { wohnsinnServices } from 'App';
import SnackBarContext from 'core/context/snackbar.context';
import { useTranslation } from 'react-i18next';
import { SUBMIT_BUTTON_MODE } from 'core/enum/submit-button-mode.enum';
import { useFieldArray, useForm } from 'react-hook-form';
import { IApartment } from '@wohnsinn/ws-ts-lib';
import { useQueryClient } from '@tanstack/react-query';
import { FormContext } from 'core/context/form.context';
import { Grid } from '@mui/material';
import CTAButton, { BUTTON_STYLE, getSubmitIcon } from 'component/atoms/Buttons/CTAButton';
import { ILink } from '@wohnsinn/ws-ts-lib/dist/types/links';
import Text, { TEXT_COLOR } from 'component/atoms/typographie/Text';
import Spacer, { SPACER_SIZE } from 'component/atoms/Spacer';
import TextInput from 'component/atoms/formElement/InputText';
import { faTrash } from '@fortawesome/pro-light-svg-icons';

const ApartmentLinksUpload: FC<{ apartment: IApartment }> = ({ apartment }) => {
  const { handleError } = useFormErrorHook('ApartmentCostForm');
  const { apartmentService } = wohnsinnServices;
  const { handleSnackBar } = useContext(SnackBarContext);
  const queryClient = useQueryClient();

  const { t } = useTranslation('common');
  const { t: ac } = useTranslation('common', { keyPrefix: 'apartment.links' });
  const [buttonSubmitMode, setButtonSubmitMode] = useState<SUBMIT_BUTTON_MODE>(SUBMIT_BUTTON_MODE.NONE);
  const {
    control,
    watch,
    formState: { isValid },
    handleSubmit,
    reset,
  } = useForm<{ links: ILink[] }>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    defaultValues: {
      links: apartment?.links ?? [],
    },
  });

  const { fields } = useFieldArray({
    name: 'links',
    control,
  });

  const onError = (err: any): void => {
    console.error(err);
    setButtonSubmitMode(SUBMIT_BUTTON_MODE.ERROR);
  };

  const saveDataToDB = async (): Promise<void> => {
    setButtonSubmitMode(SUBMIT_BUTTON_MODE.SUBMITTING);

    try {
      const formValue = watch();
      console.log('formValue', formValue);
      await apartmentService.updateApartment({
        data: { links: formValue.links },
        creatorId: apartment.creatorId,
        apartmentId: apartment.id,
      });

      reset({ links: apartment?.links?.length ? apartment.links : [] });

      await queryClient.invalidateQueries({ queryKey: ['apartments'] });
      handleSnackBar('toast.success.saved', 'success');
      setButtonSubmitMode(SUBMIT_BUTTON_MODE.NONE);
    } catch (e) {
      handleError(e);
      setButtonSubmitMode(SUBMIT_BUTTON_MODE.ERROR);
    }
  };

  const deleteLink = async (index: number) => {
    const links = apartment.links;
    links.splice(index, 1);
    try {
      await apartmentService.updateApartment({
        data: { links },
        creatorId: apartment.creatorId,
        apartmentId: apartment.id,
      });
      reset({ links: links?.length ? links : [] });
    } catch (error) {
      console.error('Error on deleteLink', error);
    }
  };

  return (
    <div>
      <FormContext.Provider value={{ control }}>
        <form onSubmit={handleSubmit(saveDataToDB, onError)} noValidate autoComplete={'off'}>
          <Grid container columnSpacing={2}>
            <Grid item xs={12}>
              <ul>
                {apartment?.links?.map((item, index) => (
                  <li key={`${item?.id}-${index}`}>
                    <a href={item?.url} style={{ display: 'inline' }}>
                      <Text tag={'span'} color={TEXT_COLOR.TEXT_COLOR_PRIMARY}>
                        {item?.alt}
                      </Text>
                    </a>{' '}
                    <CTAButton
                      buttonStyle={BUTTON_STYLE.TERTIARY}
                      size={'small'}
                      icon={faTrash}
                      hideText
                      type={'button'}
                      buttonText={t('delete')}
                      onClick={() => deleteLink(index)}
                    />
                    <Spacer size={SPACER_SIZE.XS} />
                  </li>
                ))}
              </ul>

              <TextInput
                disabled={buttonSubmitMode === SUBMIT_BUTTON_MODE.SUBMITTING}
                label={ac('link.name.label')}
                placeholder={ac('link.name.placeholder')}
                name={`links.${apartment?.links?.length}.alt`}
              />
              <TextInput
                disabled={buttonSubmitMode === SUBMIT_BUTTON_MODE.SUBMITTING}
                label={ac('link.url.label')}
                placeholder={ac('link.url.placeholder')}
                name={`links.${apartment?.links?.length}.url`}
              />

              <CTAButton
                size={'small'}
                icon={getSubmitIcon(buttonSubmitMode)}
                disabled={
                  !watch(`links.${apartment?.links?.length}.alt`) ||
                  !watch(`links.${apartment?.links?.length}.url`) ||
                  buttonSubmitMode === SUBMIT_BUTTON_MODE.SUBMITTING
                }
                buttonText={'Link hinzufügen'}
              />
            </Grid>
          </Grid>
        </form>
      </FormContext.Provider>
    </div>
  );
};

export default ApartmentLinksUpload;
