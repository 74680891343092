import styles from './ApartmentListLoadingView.module.scss';
const ApartmentListLoadingView = () => {
  const renderLoadingCards = () =>
    [1, 2, 3, 4, 5, 6].map((card, index) => (
      <div key={`loading-card-${index}`} className={styles.loadingApartmentCard}>
        <div className={styles.image}></div>
        <div className={styles.content}>
          <div className={styles.text}></div>
          <div className={styles.text}></div>
          <div className={styles.text}></div>
        </div>
      </div>
    ));

  return <div className={styles.view}>{renderLoadingCards()}</div>;
};

export default ApartmentListLoadingView;
