import styles from './TestimonialSection.module.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { Navigation } from 'swiper';
import Testimonial, { ITestimonialProps } from '../../../molecules/Testimonial';
import { FC } from 'react';

const TestimonialSection: FC<{ testimonials: ITestimonialProps[] }> = ({ testimonials }) => {
  if (testimonials.length === 1) {
    return (
      <Testimonial
        name={testimonials[0].name}
        avatar={testimonials[0].avatar}
        logo={testimonials[0].logo}
        logoAlt={testimonials[0].logoAlt}
        quote={testimonials[0].quote}
        company={testimonials[0].company}
      />
    );
  }

  return (
    <div className={styles.wrapper}>
      {testimonials.length <= 1 ? null : (
        <>
          <button className={`swiper-prev ${styles.swiperNav} ${styles.prev}`}>
            <span className={'sr-only'}>prev</span>
            <FontAwesomeIcon size={'3x'} icon={faChevronLeft} />
          </button>
          <button className={`swiper-next ${styles.swiperNav} ${styles.next}`}>
            <span className={'sr-only'}>next</span>
            <FontAwesomeIcon size={'3x'} icon={faChevronRight} />
          </button>
        </>
      )}
      <Swiper
        loop={true}
        autoplay={true}
        modules={[Navigation]}
        navigation={{
          nextEl: `.swiper-next`,
          prevEl: `.swiper-prev`,
        }}
      >
        {testimonials.map((testimonial, index) => (
          <SwiperSlide key={`${testimonial.name}-${index}`}>
            <Testimonial
              name={testimonial.name}
              avatar={testimonial.avatar}
              logo={testimonial.logo}
              logoAlt={testimonial.logoAlt}
              quote={testimonial.quote}
              company={testimonial.company}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default TestimonialSection;
