import styles from './Footer.module.scss';
import { useTranslation } from 'react-i18next';
import UserContext from '../../../core/context/user.context';
import { FC, useContext } from 'react';
import { Link } from 'react-router-dom';
import Text, { TEXT_COLOR, TEXT_TYPE, TEXT_VARIANT, TEXT_WEIGHT } from '../../atoms/typographie/Text';
import { Box, Container, Divider, Grid, IconButton, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { ROUTES } from 'core/const/routes';

const Footer: FC = () => {
  const { user } = useContext(UserContext);
  const { t: r } = useTranslation('routes');
  const { t } = useTranslation('common');

  if (user) {
    return null;
  }

  return (
    <footer className={'ws-full-width-bg ws-full-width-bg--white'}>
      <Box
        sx={{
          width: '100%',
          height: 'auto',
          py: 3,
          mt: 'auto',
        }}
      >
        <Container maxWidth="xl">
          <Divider />
          <div className={styles.wrapper}>
            <div className={styles.logosWrapper}>
              <Grid container spacing={3} direction="column">
                <Grid item>
                  <div className={styles.logoWrapper}>
                    <img src={t('logos.wohnsinn_svg')} alt="wohnsinn logo" width={108} />
                  </div>
                </Grid>
                <Grid item>
                  <Text
                    weight={TEXT_WEIGHT.TEXT_WEIGHT_LIGHT}
                    color={TEXT_COLOR.TEXT_COLOR_ACCENT}
                    type={TEXT_TYPE.TEXT_TYPE_BODY}
                  >
                    Zusammen revolutionieren wir die Immobilienbranche.
                  </Text>
                </Grid>
                <Grid item>
                  <div className={styles.logoWrapper}>
                    <img src={t('logos.madeInGermany')} alt="madeInGermany logo" width={100} />
                    <img src={t('logos.dsgvo')} alt="wohnsinn logo" width={100} />
                  </div>
                </Grid>
              </Grid>
            </div>
            <div className={styles.links}>
              <Text
                weight={TEXT_WEIGHT.TEXT_WEIGHT_LIGHT}
                color={TEXT_COLOR.TEXT_COLOR_ACCENT}
                type={TEXT_TYPE.TEXT_TYPE_CAPTION}
                variant={TEXT_VARIANT.TEXT_VARIANT_SMALL}
              >
                Unternehmen
              </Text>
              <Link to={r(ROUTES.landingPageRoutes.about.path)}>
                <Typography variant="body2">Über Wohnsinn</Typography>
              </Link>
              <Link to={r(ROUTES.landingPageRoutes.press.path)}>
                <Typography variant="body2">Presse</Typography>
              </Link>
              <Link to={r(ROUTES.landingPageRoutes.jobs.path)}>
                <Typography variant="body2">Jobs</Typography>
              </Link>
              <Link to={r(ROUTES.landingPageRoutes.contact.path)}>
                <Typography variant="body2">Kontakt</Typography>
              </Link>
            </div>
            <div className={styles.links}>
              <Text
                weight={TEXT_WEIGHT.TEXT_WEIGHT_LIGHT}
                color={TEXT_COLOR.TEXT_COLOR_ACCENT}
                type={TEXT_TYPE.TEXT_TYPE_CAPTION}
                variant={TEXT_VARIANT.TEXT_VARIANT_SMALL}
              >
                Kunden
              </Text>
              <Link to={r(ROUTES.landingPageRoutes.propertyManagement.path)}>
                <Typography variant="body2">Hausverwaltungen</Typography>
              </Link>
              <Link to={r(ROUTES.landingPageRoutes.cooperative.path)}>
                <Typography variant="body2">Genossenschaften</Typography>
              </Link>
              <Link to={r(ROUTES.landingPageRoutes.landlord.path)}>
                <Typography variant="body2">Private Vermieter</Typography>
              </Link>
              <Link to={r(ROUTES.landingPageRoutes.realEstateAgent.path)}>
                <Typography variant="body2">Makler</Typography>
              </Link>
            </div>
            <div className={styles.links}>
              <Text
                weight={TEXT_WEIGHT.TEXT_WEIGHT_LIGHT}
                color={TEXT_COLOR.TEXT_COLOR_ACCENT}
                type={TEXT_TYPE.TEXT_TYPE_CAPTION}
                variant={TEXT_VARIANT.TEXT_VARIANT_SMALL}
              >
                Rechtliches
              </Text>
              <Link to={r(ROUTES.landingPageRoutes.privacy.path)}>
                <Typography variant="body2">Datenschutz</Typography>
              </Link>
              <Link to={r(ROUTES.landingPageRoutes.agb.path)}>
                <Typography variant="body2">AGB</Typography>
              </Link>
              <Link to={r(ROUTES.landingPageRoutes.imprint.path)}>
                <Typography variant="body2">Impressum</Typography>
              </Link>
            </div>
          </div>

          <Divider />
          <div className={styles.bottomWrapper}>
            <div>
              <Text weight={TEXT_WEIGHT.TEXT_WEIGHT_LIGHT} color={TEXT_COLOR.TEXT_COLOR_ACCENT}>
                © 2024 Wohnsinn GmbH - Made in Köln 💙
              </Text>
            </div>
            <div>
              <IconButton onClick={() => window.open(r('socialMedia.twitter.path'))}>
                <FontAwesomeIcon icon={faTwitter} size={'1x'} />
              </IconButton>
              <IconButton onClick={() => window.open(r('socialMedia.linkedIn.path'))}>
                <FontAwesomeIcon icon={faLinkedin} size={'1x'} />
              </IconButton>
              <IconButton onClick={() => window.open(r('socialMedia.facebook.path'))}>
                <FontAwesomeIcon icon={faFacebook} size={'1x'} />
              </IconButton>
              <IconButton onClick={() => window.open(r('socialMedia.instagram.path'))}>
                <FontAwesomeIcon icon={faInstagram} size={'1x'} />
              </IconButton>
            </div>
          </div>
        </Container>
      </Box>
    </footer>
  );
};

export default Footer;
