import { FC } from 'react';
import { IImage } from '../../../../core/const/images';
import { useTranslation } from 'react-i18next';
import styles from './TeamCard.module.scss';
import Text, { TEXT_COLOR } from 'component/atoms/typographie/Text';
import Headline, { HEADLINE_COLOR, HEADLINE_SIZE } from 'component/atoms/typographie/Headline';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';

export interface ITeamMember {
  image: IImage;
  name: string;
  position: string;
  socialLink?: string;
}

const TeamCard: FC<ITeamMember> = ({ name, position, socialLink, image }) => {
  const { t: i } = useTranslation('images');
  return (
    <div className={styles.wrapper}>
      <img className={styles.image} src={i(image.src)} alt={i(image.alt)} />
      <div className={styles.blurred}>
        <div className={styles.content}>
          <Headline color={HEADLINE_COLOR.HEADLINE_COLOR_LIGHT} size={HEADLINE_SIZE.H3}>
            {name}
          </Headline>
          <Text className={styles.position} color={TEXT_COLOR.TEXT_COLOR_LIGHT}>
            {position}
          </Text>

          {socialLink ? (
            <a href={socialLink} target={'_blank'} rel={'noopener noreferrer'}>
              <FontAwesomeIcon className={styles.social} icon={faLinkedin} size={'xl'} />
            </a>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default TeamCard;
