import { HelmetProvider } from 'react-helmet-async';
import './theme/variables.scss';
import './theme/globals.scss';
import 'swiper/css';
import { FC, useEffect, useState } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ThemeProvider } from '@mui/material';
import { theme } from 'theme/theme';
import UserContextProvider from './core/provider/UserContextProvider';
import setupFirebase from './core/setup-firebase';
import setupServices from './core/setup-services';
import useModal from './core/hook/modal.hook';
import ModalContext from './core/context/modal.context';
import SnackBarContext from './core/context/snackbar.context';
import useSnackbarHook from './core/hook/use-snackbar.hook';
import SnackBarLoader from './component/molecules/SnackBarLoader';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import AppRouter from './AppRouter';

// Setup Firebase instances and all Wohnsinn services
export const wohnsinnServices = setupServices(setupFirebase());

const App: FC = () => {
  // STATES
  const [queryClient] = useState(
    new QueryClient({
      defaultOptions: {
        queries: {
          staleTime: 60000,
        },
      },
    })
  );
  const useModalValues = useModal();
  const { isSnackBarOpen, handleSnackBar, snackBarProps, setIsSnackBarOpen } = useSnackbarHook();
  const [isMaintenanceMode, setIsMaintenanceMode] = useState<boolean>(false);

  useEffect(() => {
    const checkIfMaintenanceModeIsOn = async () => {
      const isMaintenanceMode = await wohnsinnServices.firebaseRemoteConfigService.getRemoteConfigBoolean(
        'isInMaintainanceMode'
      );

      setIsMaintenanceMode(isMaintenanceMode);
    };
    checkIfMaintenanceModeIsOn();
  }, [isMaintenanceMode]);

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools client={queryClient} />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <UserContextProvider>
          <ThemeProvider theme={theme}>
            <ModalContext.Provider value={useModalValues}>
              <HelmetProvider>
                <SnackBarContext.Provider value={{ isSnackBarOpen, handleSnackBar, snackBarProps, setIsSnackBarOpen }}>
                  <SnackBarLoader />
                  <AppRouter isMaintenanceMode={isMaintenanceMode} />
                </SnackBarContext.Provider>
              </HelmetProvider>
            </ModalContext.Provider>
          </ThemeProvider>
        </UserContextProvider>
      </LocalizationProvider>
    </QueryClientProvider>
  );
};

export default App;
