import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import CTAButton from '../../../atoms/Buttons/CTAButton';

const DocumentUploadButton: FC<{ onClick: () => void }> = ({ onClick }) => {
  const { t } = useTranslation('common');
  return (
    <CTAButton
      expandMobile={true}
      buttonText={t('component.molecules.DocumentUploadDropZone.addDocument')}
      onClick={onClick}
      mobileNotRounded
      icon={faPlus}
    />
  );
};

export default DocumentUploadButton;
