import { FC, useContext, useState } from 'react';
import styles from 'component/forms/landlord/LandlordInterfacesForm/LandlordInterfacesForm.module.scss';
import Text, { TEXT_TYPE, TEXT_WEIGHT } from '../../atoms/typographie/Text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faEye, faEyeSlash } from '@fortawesome/pro-light-svg-icons';
import { Trans, useTranslation } from 'react-i18next';
import SnackBarContext from '../../../core/context/snackbar.context';
import { IOrganization } from '@wohnsinn/ws-ts-lib';
import Headline, { HEADLINE_SIZE } from '../../atoms/typographie/Headline';
import Spacer from 'component/atoms/Spacer';

const FTPCredentials: FC<{ organization: IOrganization }> = ({ organization }) => {
  const { t } = useTranslation('common');
  const { t: o } = useTranslation('common', { keyPrefix: 'view.AccountView.OpenImmoView' });
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const { handleSnackBar } = useContext(SnackBarContext);

  const copyInputValueToClipboard = (id: string): void => {
    const text = document.getElementById(id).innerHTML;
    navigator.clipboard
      .writeText(text)
      .then(() => handleSnackBar('view.AccountView.OpenImmoView.toast.copied', 'success'));
  };

  const copyTextToClipboard = (text: string): void => {
    navigator.clipboard
      .writeText(text)
      .then(() => handleSnackBar('view.AccountView.OpenImmoView.toast.copied', 'success'));
  };
  return (
    <div className={styles.wrapper}>
      <Trans
        i18nKey={o('introductionSection.text')}
        components={{
          link1: (
            <a
              onClick={() => window.open(o('introductionSection.url'))}
              title={o('introductionSection.title')}
              className={styles.link}
              target={'_blank'}
              rel={'noreferrer noopener'}
            />
          ),
        }}
      />
      <Spacer />

      <Headline size={HEADLINE_SIZE.H4}>{o('accessDataSection.providerNumber')}</Headline>
      <div className={styles.copyButtonWrapper}>
        <Text tag={'span'} id={'provider'} type={TEXT_TYPE.TEXT_TYPE_BODY} weight={TEXT_WEIGHT.TEXT_WEIGHT_LIGHT}>
          {organization?.FTPInformation?.credentials?.providerNumber}
        </Text>
        <button className={styles.button} type={'button'} onClick={() => copyInputValueToClipboard('provider')}>
          <FontAwesomeIcon icon={faCopy} />
        </button>
      </div>

      <Headline size={HEADLINE_SIZE.H4}>{o('accessDataSection.server')}</Headline>
      <div className={styles.copyButtonWrapper}>
        <Text tag={'span'} type={TEXT_TYPE.TEXT_TYPE_BODY} weight={TEXT_WEIGHT.TEXT_WEIGHT_LIGHT}>
          {'ftp.wohnsinn.com'}
        </Text>
        <button className={styles.button} type={'button'} onClick={() => copyTextToClipboard('ftp.wohnsinn.com')}>
          <FontAwesomeIcon icon={faCopy} />
        </button>
      </div>

      <Headline size={HEADLINE_SIZE.H4}>{o('accessDataSection.userName')}</Headline>
      <div className={styles.copyButtonWrapper}>
        <span id={'username'} className={'hidden'}>
          {organization?.FTPInformation?.credentials?.userName}
        </span>
        <Text tag={'span'} type={TEXT_TYPE.TEXT_TYPE_BODY} weight={TEXT_WEIGHT.TEXT_WEIGHT_LIGHT}>
          {organization?.FTPInformation?.credentials?.userName}
        </Text>
        <button className={styles.button} type={'button'} onClick={() => copyInputValueToClipboard('username')}>
          <FontAwesomeIcon icon={faCopy} />
        </button>
      </div>

      <Headline size={HEADLINE_SIZE.H4}>{o('accessDataSection.password')}</Headline>
      <div className={styles.copyButtonWrapper}>
        <span id={'pass'} className={'hidden'}>
          {organization?.FTPInformation?.credentials?.password}
        </span>
        <Text tag={'span'} type={TEXT_TYPE.TEXT_TYPE_BODY} weight={TEXT_WEIGHT.TEXT_WEIGHT_LIGHT}>
          {showPassword ? organization?.FTPInformation?.credentials?.password : '******************'}
        </Text>

        <div className={styles.actions}>
          <button className={styles.button} type={'button'} onClick={() => setShowPassword(!showPassword)}>
            {showPassword ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />}
          </button>
          <button className={styles.button} type={'button'} onClick={() => copyInputValueToClipboard('pass')}>
            <FontAwesomeIcon icon={faCopy} />
          </button>
        </div>
      </div>

      <Text>
        <Trans
          i18nKey={o('accessDataSection.sendEmailText')}
          components={{
            link1: <a href={t('component.atoms.GetInTouch.hrefMail')} rel={'noreferrer noopener'} />,
          }}
        />
      </Text>
    </div>
  );
};

export default FTPCredentials;
