import { FC } from 'react';
import { EQUIPMENT } from '@wohnsinn/ws-ts-lib';
import TagList from '../TagList';
import { useTranslation } from 'react-i18next';
import { ITag } from '../../atoms/Tag';

export interface IApartmentEquipmentProps {
  equipmentList: EQUIPMENT[];
}

const ApartmentEquipment: FC<IApartmentEquipmentProps> = ({ equipmentList }) => {
  const { t } = useTranslation('common', { keyPrefix: 'apartment.equipment.equipmentList' });

  const equipmentTagList = createEquipmentList(equipmentList, t);

  return <TagList testId={'apartmentEquipment'} tags={equipmentTagList} />;
};

export const createEquipmentList = (equipmentList: EQUIPMENT[], t: any): ITag[] => {
  const equipmentTagList: ITag[] = [];

  if (equipmentList.includes(EQUIPMENT.HAS_KITCHEN)) {
    equipmentTagList.push({ value: EQUIPMENT.HAS_KITCHEN, key: 'kitchen', label: `🍴 ${t(EQUIPMENT.HAS_KITCHEN)}` });
  }

  if (equipmentList.includes(EQUIPMENT.HAS_GARDEN)) {
    equipmentTagList.push({ value: EQUIPMENT.HAS_GARDEN, key: 'garden', label: `🌿 ${t(EQUIPMENT.HAS_GARDEN)}` });
  }

  if (equipmentList.includes(EQUIPMENT.HAS_BALCONY_OR_TERRACE)) {
    equipmentTagList.push({
      value: EQUIPMENT.HAS_BALCONY_OR_TERRACE,
      key: 'balcony',
      label: `☀️ ${t(EQUIPMENT.HAS_BALCONY_OR_TERRACE)}`,
    });
  }

  if (equipmentList.includes(EQUIPMENT.HAS_BASEMENT)) {
    equipmentTagList.push({ value: EQUIPMENT.HAS_BASEMENT, key: 'basement', label: `🚪 ${t(EQUIPMENT.HAS_BASEMENT)}` });
  }

  if (equipmentList.includes(EQUIPMENT.HAS_ELEVATOR)) {
    equipmentTagList.push({ value: EQUIPMENT.HAS_ELEVATOR, key: 'elevator', label: `🛗 ${t(EQUIPMENT.HAS_ELEVATOR)}` });
  }

  if (equipmentList.includes(EQUIPMENT.HAS_GUEST_TOILET)) {
    equipmentTagList.push({
      value: EQUIPMENT.HAS_GUEST_TOILET,
      key: 'guestToilet',
      label: `🚽 ${t(EQUIPMENT.HAS_GUEST_TOILET)}`,
    });
  }

  if (equipmentList.includes(EQUIPMENT.HAS_PARKING_SPOT)) {
    equipmentTagList.push({
      value: EQUIPMENT.HAS_PARKING_SPOT,
      key: 'parkingSpot',
      label: `🚙 ${t(EQUIPMENT.HAS_PARKING_SPOT)}`,
    });
  }

  if (equipmentList.includes(EQUIPMENT.IS_BARRIER_FREE)) {
    equipmentTagList.push({
      value: EQUIPMENT.IS_BARRIER_FREE,
      key: 'isBarrierFree',
      label: `🦽 ${t(EQUIPMENT.IS_BARRIER_FREE)}`,
    });
  }

  if (equipmentList.includes(EQUIPMENT.IS_WHEEL_CHAIR_ACCESSIBLE)) {
    equipmentTagList.push({
      value: EQUIPMENT.IS_WHEEL_CHAIR_ACCESSIBLE,
      key: 'isWheelChairAccessible',
      label: `🦽 ${t(EQUIPMENT.IS_WHEEL_CHAIR_ACCESSIBLE)}`,
    });
  }

  if (equipmentList.includes(EQUIPMENT.IS_FURNISHED)) {
    equipmentTagList.push({
      value: EQUIPMENT.IS_FURNISHED,
      key: 'isFurnished',
      label: `🪑 ${t(EQUIPMENT.IS_FURNISHED)}`,
    });
  }

  return equipmentTagList;
};

export default ApartmentEquipment;
