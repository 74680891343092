import { FC } from 'react';
import sanitizeHtml, { IOptions } from 'sanitize-html';

export interface ISanitizeHTMLProps {
  dirty: string;
  className?: string;
  options?: IOptions;
  removeNewLines?: boolean;
}

export const defaultOptions: IOptions = {
  allowedTags: ['b', 'i', 'em', 'strong', 'a', 'span', 'h1', 'p', 'br', 'u'],
  allowedAttributes: {
    a: ['href'],
  },
};

const SanitizedHTML: FC<ISanitizeHTMLProps> = ({
  className,
  dirty,
  options = defaultOptions,
  removeNewLines = false,
}) => {
  let sanitizedText = sanitizeHtml(dirty, options);
  sanitizedText = removeNewLines ? sanitizedText.replaceAll('<br />', ' ') : sanitizedText;
  return <span className={className ? className : ''} dangerouslySetInnerHTML={{ __html: sanitizedText }} />;
};

export default SanitizedHTML;
