import InputText from 'component/atoms/formElement/InputText';
import InputOptionList, { RADIO_INPUT_COLUMNS_COUNT } from 'component/atoms/formElement/InputOptionList';
import { BOOLEAN_SELECT_OPTIONS, getTranslatedOptions } from 'core/const/select-options';
import Dropdown from 'component/atoms/formElement/Dropdown';
import { RENT_TYPE_SELECT_OPTION_LIST } from 'component/forms/apartment/ApartmentCostsForm/index';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { IApartmentCost } from '@wohnsinn/ws-ts-lib';
import { Grid } from '@mui/material';

const ApartmentCostFormRent: FC<{ formValue: IApartmentCost }> = ({ formValue }) => {
  const { t } = useTranslation('common');
  const { t: ac } = useTranslation('common', { keyPrefix: 'apartment.cost' });
  const isWogeEnvironment = process.env.REACT_APP_ENVIRONMENT === 'woge';
  return (
    <>
      <Grid item xs={12} md={6}>
        <InputText
          pattern="[0-9]*"
          type={'number'}
          placeholder={t('placeholder.coldRent')}
          endAdornment={'€'}
          name={'coldRent'}
          label={ac('coldRent.label')}
          unit={t('currency')}
          required={true}
        />
        <InputText
          pattern="[0-9]*"
          type={'number'}
          placeholder={t('placeholder.operationalCosts')}
          name={'operationalCosts'}
          endAdornment={'€'}
          label={ac('operationalCost.label')}
          unit={t('currency')}
        />
        <InputOptionList
          options={BOOLEAN_SELECT_OPTIONS}
          columns={RADIO_INPUT_COLUMNS_COUNT.TWO}
          mode={'radio'}
          label={ac('isHeatingIncluded.long')}
          name={'isHeatingIncluded'}
        />
        {formValue?.isHeatingIncluded === false && (
          <InputText
            label={ac('heatingCosts')}
            type={'number'}
            name={'heatingCosts'}
            unit={t('currency')}
            placeholder={t('placeholder.heatingCosts')}
            endAdornment={'€'}
          />
        )}

        <InputText
          name={'warmRent'}
          pattern="[0-9]*"
          type={'number'}
          label={ac('warmRent.label')}
          unit={t('currency')}
          placeholder={t('placeholder.warmRent')}
          endAdornment={'€'}
        />

        <Dropdown
          name={'rentType'}
          label={ac('rentType.label')}
          optionList={getTranslatedOptions(RENT_TYPE_SELECT_OPTION_LIST, t)}
        />
        <InputText
          type={'text'}
          name={'deposit'}
          label={isWogeEnvironment ? ac('deposit.cooperative.label') : ac('deposit.label')}
          placeholder={isWogeEnvironment ? '' : t('placeholder.deposit')}
          endAdornment={isWogeEnvironment ? 'Anteile' : '€'}
        />
      </Grid>
    </>
  );
};

export default ApartmentCostFormRent;
