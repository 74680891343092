import { DebounceInput } from 'react-debounce-input';
import { FC, useContext } from 'react';
import { FormContext } from '../../../../core/context/form.context';
import { Controller, ControllerRenderProps } from 'react-hook-form';
import Text, { TEXT_COLOR } from '../../typographie/Text';

interface IInputWithDebounceProps {
  debounceTimeout: number;
  label: string;
  name: string;
  required: boolean;
  onChange?: () => void;
}

const InputWithDebounce: FC<IInputWithDebounceProps> = ({ debounceTimeout, label, name, required, onChange }) => {
  const { control } = useContext(FormContext);

  const handleOnChange = (field: ControllerRenderProps, e: any) => {
    if (onChange) onChange();
    field.onChange(e.target.value);
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        return (
          <>
            <div className={'floating-wrapper'}>
              <div className={'floating-field'}>
                <DebounceInput
                  className={'floating-input'}
                  debounceTimeout={debounceTimeout}
                  onChange={(e: any) => handleOnChange(field, e)}
                  label={label}
                  name={name}
                  required={required}
                  value={field?.value ? field.value : ''}
                />
                <label htmlFor={name} className={`floating-label ${field.value ? 'isInputFilled' : ''}`}>
                  <Text tag={'span'} color={TEXT_COLOR.TEXT_COLOR_ACCENT}>
                    {label} {label && required ? '*' : ''}
                  </Text>
                </label>
              </div>
            </div>
          </>
        );
      }}
    />
  );
};

export default InputWithDebounce;
