import Text, { TEXT_COLOR, TEXT_TYPE } from '../typographie/Text';
import { Trans, useTranslation } from 'react-i18next';
import styles from './AcceptPrivacyInfoText.module.scss';
import { ROUTES } from '../../../core/const/routes';
import { Link } from 'react-router-dom';

const AcceptPrivacyInfoText = () => {
  const { t: r } = useTranslation('routes');
  const { t } = useTranslation('common');
  return (
    <div className={styles.wrapper}>
      <Text align={'center'} color={TEXT_COLOR.TEXT_COLOR_ACCENT} type={TEXT_TYPE.TEXT_TYPE_CAPTION}>
        <Trans
          i18nKey={t('termsText.text')}
          components={{
            link1: (
              <Link
                className={styles.link}
                to={r(ROUTES.landingPageRoutes.agb.path)}
                target={'_blank'}
                rel={'noreferrer noopener'}
                title={r(ROUTES.landingPageRoutes.agb.title)}
              >
                {r(ROUTES.landingPageRoutes.agb.title)}
              </Link>
            ),
            link2: (
              <Link
                className={styles.link}
                to={r(ROUTES.landingPageRoutes.privacy.path)}
                target={'_blank'}
                rel={'noreferrer noopener'}
                title={r(ROUTES.landingPageRoutes.privacy.title)}
              >
                {r(ROUTES.landingPageRoutes.privacy.title)}
              </Link>
            ),
          }}
        />
      </Text>
    </div>
  );
};

export default AcceptPrivacyInfoText;
