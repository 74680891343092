import { FirebaseOptions } from 'firebase/app';

const FIREBASE_OPTIONS: FirebaseOptions = {
  apiKey: process.env.REACT_APP_FIREBASE_PUBLIC_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_DEFAULT_BUCKET_URL,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

export default FIREBASE_OPTIONS;
