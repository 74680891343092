import BackButton from '../../atoms/Buttons/BackButton';
import styles from './Breadcrumbs.module.scss';
import { FC, PropsWithChildren } from 'react';
import { Grid, Breadcrumbs as Bread } from '@mui/material';

export interface IFormsPageHeaderProps {
  backRoute?: string;
}

const Breadcrumbs: FC<PropsWithChildren<IFormsPageHeaderProps>> = ({ children, backRoute }) => {
  return (
    <Grid item xs={12}>
      <div className={styles.wrapper}>
        {backRoute ? <BackButton /> : null}
        <Bread separator="›" aria-label="breadcrumb">
          {children}
        </Bread>
      </div>
    </Grid>
  );
};

export default Breadcrumbs;
