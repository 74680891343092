import TextEditor, { TEXT_EDITOR_SIZE } from 'component/atoms/formElement/TextEditor';
import { FormContext } from 'core/context/form.context';
import { SUBMIT_BUTTON_MODE } from 'core/enum/submit-button-mode.enum';
import UserContext from 'core/context/user.context';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { FC, useContext, useState } from 'react';
import FB_FUNCTION_URLS from 'core/const/fb-function-names';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { IAboutMe, TENANT_ABOUT_ME_SCHEMA } from '@wohnsinn/ws-ts-lib';
import PageTitle from 'component/molecules/PageTitle';
import { ROUTES } from 'core/const/routes';
import { ITenantFormProps } from '../TenantProfileForm';
import { wohnsinnServices } from 'App';
import InfoBox, { INFO_BOX_ICONS } from 'component/atoms/InfoBox';
import { Grid } from '@mui/material';
import SnackBarContext from 'core/context/snackbar.context';
import FormSectionTitle from 'component/atoms/FormSectionTitle';
import InfoText from 'component/molecules/InfoText';
import Headline, { HEADLINE_SIZE } from 'component/atoms/typographie/Headline';
import Text, { TEXT_COLOR } from 'component/atoms/typographie/Text';
import { useNavigate } from 'react-router-dom';
import FormLayout from 'component/organisms/FormLayout';

const DEFAULT_ABOUT_ME_DATA: IAboutMe = {
  description: null,
  introductionVideo: null,
};

const TenantAboutMeForm: FC<ITenantFormProps> = ({ isApplicationFolderTunnel }) => {
  const { tenantProfile } = useContext(UserContext);
  const { handleSnackBar } = useContext(SnackBarContext);
  const { t: r } = useTranslation('routes');
  const { t: c } = useTranslation('common');
  const { t } = useTranslation('common', { keyPrefix: 'component.organisms.forms.tenant.TenantAboutMeForm' });
  const [submitMode, setSubmitMode] = useState<SUBMIT_BUTTON_MODE>(SUBMIT_BUTTON_MODE.NONE);
  const { firebaseFunctionsService } = wohnsinnServices;
  const navigate = useNavigate();

  const { control, handleSubmit } = useForm<IAboutMe>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    resolver: joiResolver(Joi.object(TENANT_ABOUT_ME_SCHEMA)),
    defaultValues: tenantProfile?.aboutMe ? tenantProfile?.aboutMe : DEFAULT_ABOUT_ME_DATA,
  });

  const onSuccess = async (): Promise<void> => {
    setSubmitMode(SUBMIT_BUTTON_MODE.NONE);
    try {
      if (isApplicationFolderTunnel) {
        navigate(r(ROUTES.tenantRoutes.account.applicationFolder.schufa));
      } else {
        navigate(r(ROUTES.tenantRoutes.account.overview.path));
      }

      if (!isApplicationFolderTunnel) {
        handleSnackBar('toast.profileUpdated', 'success');
      }
    } catch (e) {
      console.error(e);
    }
  };

  const updateTenantAboutMe = (formData: IAboutMe): Promise<any> => {
    setSubmitMode(SUBMIT_BUTTON_MODE.SUBMITTING);
    return firebaseFunctionsService
      .callFbFunction(FB_FUNCTION_URLS.user.aboutMe.update, {
        body: {
          ...formData,
          introductionVideo: tenantProfile?.aboutMe?.introductionVideo
            ? tenantProfile?.aboutMe?.introductionVideo
            : null,
        },
        params: { tenantProfileId: tenantProfile.uid },
      })
      .then(onSuccess)
      .catch((e) => console.error('error updating tenantProfile?.aboutMe', e));
  };

  const CANCEL_BUTTON = {
    text: isApplicationFolderTunnel ? c('back') : c('cancel'),
    action: () =>
      isApplicationFolderTunnel
        ? navigate(r(ROUTES.tenantRoutes.account.applicationFolder.introductionVideo))
        : navigate(r(ROUTES.tenantRoutes.account.overview.path)),
  };

  return (
    <FormContext.Provider value={{ control }}>
      <form
        onSubmit={handleSubmit(
          (formData) => updateTenantAboutMe(formData),
          (err) => console.error(err)
        )}
      >
        <FormLayout isTunnel={isApplicationFolderTunnel} submitMode={submitMode} notSubmitButton={CANCEL_BUTTON}>
          <PageTitle
            pageTitle={r(ROUTES.tenantRoutes.account.aboutMe.title)}
            showSubmitButton
            submitMode={submitMode}
            notSubmitButton={CANCEL_BUTTON}
            submitText={isApplicationFolderTunnel ? 'next' : 'save'}
          />
          <Grid container spacing={4}>
            <Grid item xs={12} md={7} lg={8}>
              <Grid item xs={12}>
                <FormSectionTitle isFirst title={t('description.label')} />
                <TextEditor
                  hideToolbar
                  size={TEXT_EDITOR_SIZE.LARGE}
                  name={'description'}
                  placeholder={t('description.placeholder')}
                  helperText={t('description.helperText')}
                />
              </Grid>
              <Grid item xs={12}>
                <InfoBox
                  icon={INFO_BOX_ICONS.LIGHT_BULB}
                  text={'WICHTIGE INFO: Dieser Text ist gleichzeitig das Anschreiben an den Vermieter.'}
                />
              </Grid>
            </Grid>
            <Grid item md={5} lg={4} display={{ xs: 'none', md: 'block' }}>
              <InfoText>
                <Headline size={HEADLINE_SIZE.H2}>Erhöhe deine Chancen</Headline>
                <Text color={TEXT_COLOR.TEXT_COLOR_ACCENT}>
                  Ein aussagekräftiger Vorstellungstext ist eine hervorragende Möglichkeit, dem Vermieter einen ersten
                  Eindruck von dir zu vermitteln, indem du deine Persönlichkeit, beruflichen Hintergrund und
                  Wohnabsichten prägnant darlegst.
                </Text>
              </InfoText>
            </Grid>
          </Grid>
        </FormLayout>
      </form>
    </FormContext.Provider>
  );
};

export default TenantAboutMeForm;
