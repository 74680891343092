import { FC } from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import Text, { TEXT_COLOR, TEXT_WEIGHT } from 'component/atoms/typographie/Text';

const WsCheckbox: FC<{ checked: boolean; label: string; onChange: any }> = ({ checked, label, onChange }) => {
  return (
    <FormControlLabel
      control={<Checkbox checked={checked} onChange={onChange} />}
      label={
        <Text tag={'span'} weight={TEXT_WEIGHT.TEXT_WEIGHT_SEMI_BOLD} color={TEXT_COLOR.TEXT_COLOR_DARK}>
          {label}
        </Text>
      }
    />
  );
};

export default WsCheckbox;
