import CompleteApplicationFolderModal from './CompleteApplicationFolderModal';
import ConfirmEmailModal from './signIn/ConfirmEmailModal';
import CreateOrUploadSchufaModal from './CreateOrUploadSchufaModal';
import CreateUserInvitationModal from './CreateUserInvitationModal';
import CropImageModal from './CropImageModal';
import DeleteModal from './DeleteModal';
import EmailVerificationModal from './signIn/EmailVerificationModal';
import FilterLocationModal from './filter/FilterLocationModal';
import HowToSlidesModal from './HowToSlidesModal';
import InfoTextModal from './InfoTextModal';
import MissingApplicationFolderModal from './MissingApplicationFolderModal';
import ModalContext from 'core/context/modal.context';
import NotificationsModal from './NotificationsModal';
import PublishApartmentModal from './PublishApartmentModal';
import SignInModal from './signIn/SignInModal';
import VirtualTourModal from './VirtualTourModal';
import YouHaveBeenInvitedModal from './YouHaveBeenInvitedModal';
import { MODAL_IDENTIFIER } from 'core/enum/modals.enum';
import React, { useContext } from 'react';
import FilterResetModal from './filter/FilterResetModal';
import BeforeApplicationProfileOverviewModal from './BeforeApplicationProfileOverviewModal';
import ResetPasswordModal from './signIn/ResetPasswordModal';
import { Dialog, Slide } from '@mui/material';
import { Box } from '@mui/system';
import { TransitionProps } from '@mui/material/transitions';
import ShareApartmentLinkModal from './ShareApartmentLinkModal';
import SwitchUserTypeModal from './SwitchUserTypeModal';
import SignUpUserModal from 'component/modals/signIn/SignUpUserModal';
import RequestSaleModal from 'component/modals/RequestSaleModal';
import RentStatusModal from 'component/modals/RentStatusModal';
import MultiMessageModal from 'component/modals/MultiMessageModal';
import ApplicantNotesModal from 'component/modals/ApplicantNotesModal';
import CompleteDocumentFolderModal from 'component/modals/CompleteDocumentFolderModal';
import DesiredTenantModal from 'component/modals/DesiredTenantModal';

const ModalLoader = () => {
  const { currentModalId, closeModal, onDismiss } = useContext(ModalContext);

  const renderModal = () => {
    switch (currentModalId) {
      case MODAL_IDENTIFIER.COMPLETE_APPLICATION_FOLDER:
        return <CompleteApplicationFolderModal />;
      case MODAL_IDENTIFIER.COMPLETE_DOCUMENT_FOLDER:
        return <CompleteDocumentFolderModal />;
      case MODAL_IDENTIFIER.BEFORE_APPLICATION_OVERVIEW:
        return <BeforeApplicationProfileOverviewModal />;
      case MODAL_IDENTIFIER.MISSING_APPLICATION_FOLDER:
        return <MissingApplicationFolderModal />;
      case MODAL_IDENTIFIER.CONFIRM_EMAIL:
        return <ConfirmEmailModal />;
      case MODAL_IDENTIFIER.EMAIL_VERIFICATION:
        return <EmailVerificationModal />;
      case MODAL_IDENTIFIER.CREATE_OR_UPLOAD_SCHUFA:
        return <CreateOrUploadSchufaModal />;
      case MODAL_IDENTIFIER.CROP_IMAGE:
        return <CropImageModal />;
      case MODAL_IDENTIFIER.DELETE_MODAL:
        return <DeleteModal />;
      case MODAL_IDENTIFIER.DESIRED_TENANT:
        return <DesiredTenantModal />;
      case MODAL_IDENTIFIER.FILTER_LOCATION:
        return <FilterLocationModal />;
      case MODAL_IDENTIFIER.FILTER_RESET:
        return <FilterResetModal />;
      case MODAL_IDENTIFIER.HOW_TO_SLIDES:
        return <HowToSlidesModal />;
      case MODAL_IDENTIFIER.INFO_TEXT:
        return <InfoTextModal />;
      case MODAL_IDENTIFIER.NOTIFICATIONS:
        return <NotificationsModal />;
      case MODAL_IDENTIFIER.PUBLISH_APARTMENT:
        return <PublishApartmentModal />;
      case MODAL_IDENTIFIER.RESET_PASSWORD:
        return <ResetPasswordModal />;
      case MODAL_IDENTIFIER.REQUEST_SALE:
        return <RequestSaleModal />;
      case MODAL_IDENTIFIER.RENT_STATUS:
        return <RentStatusModal />;
      case MODAL_IDENTIFIER.SIGN_IN:
        return <SignInModal />;
      case MODAL_IDENTIFIER.SHARE_LINK:
        return <ShareApartmentLinkModal />;
      case MODAL_IDENTIFIER.SWITCH_USER_TYPE:
        return <SwitchUserTypeModal />;
      case MODAL_IDENTIFIER.VIRTUAL_TOUR:
        return <VirtualTourModal />;
      case MODAL_IDENTIFIER.MULTI_MESSAGE:
        return <MultiMessageModal />;
      case MODAL_IDENTIFIER.YOU_HAVE_BEEN_INVITED_MODAL:
        return <YouHaveBeenInvitedModal />;
      case MODAL_IDENTIFIER.CREATE_USER_INVITATION:
        return <CreateUserInvitationModal />;
      case MODAL_IDENTIFIER.APPLICANT_NOTES_MODAL:
        return <ApplicantNotesModal />;
      case MODAL_IDENTIFIER.SIGN_UP_USER:
        return <SignUpUserModal />;
      default:
        return <div></div>;
    }
  };

  const onDismissHandler = () => {
    if (onDismiss) {
      onDismiss();
    }
    closeModal();
  };

  return (
    <Dialog
      PaperProps={{
        sx: {
          borderRadius: '10px',
          margin: '10px',
          width: 'calc(100vw - 20px)',
        },
      }}
      TransitionComponent={Transition}
      onClose={onDismissHandler}
      open={!!currentModalId}
    >
      <Box style={{ height: '100%' }}>{renderModal()}</Box>
    </Dialog>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return (
    <Slide direction={'up'} ref={ref} {...props}>
      {props.children}
    </Slide>
  );
});

export default ModalLoader;
