import { FC } from 'react';
import { IImage } from '../../../core/const/images';
import styles from './LogoSlider.module.scss';
import HeadlineTextBanner from '../../molecules/HeadlineTextBanner';

const LogoSlider: FC<{ items: IImage[]; tag?: string; text?: string; headline?: string; animate?: boolean }> = ({
  items,
  text,
  tag,
  headline,
  animate = true,
}) => {
  const width = items.length * 250 * 2;

  return (
    <>
      <style>
        {`
        .aniKey {
        ${animate ? 'animation: slide 40s 1s linear infinite;' : ''}
            }
  
            @keyframes slide {
              0% {
                transform: translateX(0);
              }
              100% {
                transform: translateX(calc(-250px * ${items.length}));
              }    
          `}
      </style>

      <div className={styles.wrapper}>
        <HeadlineTextBanner tag={tag} headline={headline} text={text} />
        <div className={styles.slider}>
          <div className={`${styles.slideTrack} aniKey`} style={{ width: width }}>
            {items.map((im, i) => {
              return (
                <div key={i} className={styles.slide}>
                  <img src={im.src} alt={im.alt} key={i} width={im.width ?? 250} />
                </div>
              );
            })}
            {items.map((im, i) => {
              return (
                <div key={i} className={styles.slide}>
                  <img src={im.src} alt={im.alt} key={i} width={im.width ?? 250} />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};
export default LogoSlider;
