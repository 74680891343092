import { FC } from 'react';
import { IApartmentArea } from '@wohnsinn/ws-ts-lib';
import ItemList, { TItemListItem } from '../ItemList';
import { useTranslation } from 'react-i18next';
import styles from 'component/organisms/ApartmentInformation/ApartmentInformation.module.scss';
import Headline, { HEADLINE_SIZE } from 'component/atoms/typographie/Headline';

const ApartmentAreas: FC<{ areas: IApartmentArea }> = ({ areas }) => {
  const { t } = useTranslation('common');
  const { t: a } = useTranslation('common', { keyPrefix: 'apartment.areas' });
  const items: TItemListItem[] = [];

  if (areas?.totalArea) {
    items.push({
      key: 'totalArea,',
      label: a('totalArea.label'),
      value: areas?.totalArea + ' ' + t('qm'),
    });
  }

  if (areas?.livingArea) {
    items.push({
      key: 'livingArea,',
      label: a('livingArea.label'),
      value: areas?.livingArea + ' ' + t('qm'),
    });
  }

  if (areas?.usableArea) {
    items.push({
      key: 'usableArea,',
      label: a('usableArea.label'),
      value: areas?.usableArea + ' ' + t('qm'),
    });
  }

  if (areas?.shopArea) {
    items.push({
      key: 'shopArea,',
      label: a('shopArea.label'),
      value: areas?.shopArea + ' ' + t('qm'),
    });
  }

  if (areas?.storageArea) {
    items.push({
      key: 'storageArea,',
      label: a('storageArea.label'),
      value: areas?.storageArea + ' ' + t('qm'),
    });
  }

  if (areas?.salesArea) {
    items.push({
      key: 'salesArea,',
      label: a('salesArea.label'),
      value: areas?.salesArea + ' ' + t('qm'),
    });
  }

  if (areas?.openSpace) {
    items.push({
      key: 'openSpace,',
      label: a('openSpace.label'),
      value: areas?.openSpace + ' ' + t('qm'),
    });
  }

  if (areas?.officeArea) {
    items.push({
      key: 'officeArea,',
      label: a('officeArea.label'),
      value: areas?.officeArea + ' ' + t('qm'),
    });
  }

  if (areas?.partOfficeArea) {
    items.push({
      key: 'partOfficeArea,',
      label: a('partOfficeArea.label'),
      value: areas?.partOfficeArea + ' ' + t('qm'),
    });
  }

  if (areas?.windowFront) {
    items.push({
      key: 'windowFront,',
      label: a('windowFront.label'),
      value: areas?.windowFront + ' m',
    });
  }

  if (areas?.windowFrontArea) {
    items.push({
      key: 'windowFrontArea,',
      label: a('windowFrontArea.label'),
      value: areas?.windowFrontArea + ' ' + t('qm'),
    });
  }

  if (areas?.administrativeArea) {
    items.push({
      key: 'administrativeArea,',
      label: a('administrativeArea.label'),
      value: areas?.administrativeArea + ' ' + t('qm'),
    });
  }

  if (areas?.gastronomyArea) {
    items.push({
      key: 'gastronomyArea,',
      label: a('gastronomyArea.label'),
      value: areas?.gastronomyArea + ' ' + t('qm'),
    });
  }

  if (areas?.divisibleFrom) {
    items.push({
      key: 'divisibleFrom,',
      label: a('divisibleFrom.label'),
      value: areas?.divisibleFrom + ' ' + t('qm'),
    });
  }

  if (areas?.heatableArea) {
    items.push({
      key: 'heatableArea,',
      label: a('heatableArea.label'),
      value: areas?.heatableArea + ' ' + t('qm'),
    });
  }
  if (areas?.balconyTerraceArea) {
    items.push({
      key: 'balconyTerraceArea,',
      label: a('balconyTerraceArea.label'),
      value: areas?.balconyTerraceArea + ' ' + t('qm'),
    });
  }
  if (areas?.gardenArea) {
    items.push({
      key: 'gardenArea,',
      label: a('gardenArea.label'),
      value: areas?.gardenArea + ' ' + t('qm'),
    });
  }
  if (areas?.basementArea) {
    items.push({
      key: 'basementArea,',
      label: a('basementArea.label'),
      value: areas?.basementArea + ' ' + t('qm'),
    });
  }
  if (areas?.plotFront) {
    items.push({
      key: 'plotFront,',
      label: a('plotFront.label'),
      value: areas?.plotFront + ' m',
    });
  }
  if (areas?.atticArea) {
    items.push({
      key: 'atticArea,',
      label: a('atticArea.label'),
      value: areas?.atticArea + ' ' + t('qm'),
    });
  }
  if (areas?.plotArea) {
    items.push({
      key: 'plotArea,',
      label: a('plotArea.label'),
      value: areas?.plotArea + ' ' + t('qm'),
    });
  }
  if (areas?.otherArea) {
    items.push({
      key: 'otherArea,',
      label: a('otherArea.label'),
      value: areas?.otherArea + ' ' + t('qm'),
    });
  }
  if (areas?.leasableArea) {
    items.push({
      key: 'leasableArea,',
      label: a('leasableArea.label'),
      value: areas?.leasableArea + ' ' + t('qm'),
    });
  }
  if (areas?.areaFrom) {
    items.push({
      key: 'areaFrom,',
      label: a('areaFrom.label'),
      value: areas?.areaFrom + ' ' + t('qm'),
    });
  }
  if (areas?.areaTo) {
    items.push({
      key: 'areaTo,',
      label: a('areaTo.label'),
      value: areas?.areaTo + ' ' + t('qm'),
    });
  }
  if (areas?.cubature) {
    items.push({
      key: 'cubature,',
      label: a('cubature.label'),
      value: areas?.cubature + ' ' + t('qm'),
    });
  }
  if (areas?.numberOfParkingSpaces) {
    items.push({
      key: 'noOfParkingSpaces,',
      label: a('numberOfParkingSpaces.label'),
      value: areas?.numberOfParkingSpaces,
    });
  }

  if (items?.length) {
    return (
      <div className={styles.informationSection}>
        <Headline size={HEADLINE_SIZE.H3} tag={'span'}>
          {t('apartment.areas.areas')}
        </Headline>
        <ItemList items={items} />
      </div>
    );
  }
};

export default ApartmentAreas;
