import styles from './ProgressBar.module.scss';
import { FC } from 'react';

const ProgressBar: FC<{ progress: number; withPadding?: boolean }> = ({ progress, withPadding = true }) => {
  return (
    <div className={styles.progressBar} style={withPadding ? { marginTop: '2rem', marginBottom: '4rem' } : {}}>
      <div className={styles.progress} style={{ width: `${progress}%` }} />
    </div>
  );
};

export default ProgressBar;
