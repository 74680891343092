import CTAButton from 'component/atoms/Buttons/CTAButton';
import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import FormHeader from 'component/atoms/FormHeader';
import { wohnsinnServices } from 'App';
import { REGISTRATION_STATES } from '@wohnsinn/ws-ts-lib';
import UserContext from 'core/context/user.context';
import Spacer from 'component/atoms/Spacer';

const LandlordCreateOrganizationForm: FC = () => {
  const { t } = useTranslation('common');
  const { t: f } = useTranslation('common', { keyPrefix: 'component.organisms.forms.landlord.form.organization' });
  const { userService } = wohnsinnServices;
  const { landlordProfile, user } = useContext(UserContext);

  const onSubmit = async (): Promise<void> => {
    await userService.updateUserData(
      {
        registrationState: user.isPhoneNumberVerified
          ? REGISTRATION_STATES.ADD_ORGANIZATION_PROFILE
          : REGISTRATION_STATES.VERIFY_PHONE_NUMBER,
      },
      landlordProfile.uid
    );
  };

  return (
    <div>
      <FormHeader title={f('title')} subtitle={'Starte mit einem kostenlosen Konto.'} />
      <Spacer />
      <CTAButton onClick={() => onSubmit()} buttonText={t('registerButtonText')} />
    </div>
  );
};

export default LandlordCreateOrganizationForm;
