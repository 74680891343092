import { FC } from 'react';
import OrganizationInformation from 'component/atoms/OrganizationInformation';
import { IOrganization } from '@wohnsinn/ws-ts-lib';
import styles from './ApartmentOrganizationCard.module.scss';

const ApartmentOrganizationCard: FC<{ organization?: IOrganization }> = ({ organization }) => {
  return (
    <div className={styles.wrapper}>
      <OrganizationInformation organization={organization} />
    </div>
  );
};

export default ApartmentOrganizationCard;
