import ItemList, { TItemListItem } from '../ItemList';
import { FC } from 'react';
import { IApartmentEnergySupply } from '@wohnsinn/ws-ts-lib';
import { useTranslation } from 'react-i18next';

export interface IApartmentEnergySupplyProps {
  energySupply: IApartmentEnergySupply;
}

const ApartmentEnergySupply: FC<IApartmentEnergySupplyProps> = ({ energySupply }) => {
  const { t } = useTranslation('common', { keyPrefix: 'apartment.energySupply' });
  const { t: c } = useTranslation('common');
  const items: TItemListItem[] = [];

  if (energySupply) {
    if (energySupply.buildYear) {
      items.push({ key: 'buildYear', label: t('buildYear'), value: energySupply.buildYear });
    }

    if (energySupply.condition && energySupply.condition.length) {
      items.push({
        key: 'condition',
        label: t('condition.label'),
        value: t(`condition.options.${energySupply.condition}`),
      });
    }

    if (Array.isArray(energySupply?.heatingSystem)) {
      const heating = energySupply.heatingSystem;
      const concatenatedValues = heating?.map((heat) => t(`heatingSystem.options.${heat}`)).join('-');

      items.push({
        key: 'heatingSystem',
        label: t('heatingSystem.label'),
        value: concatenatedValues,
      });
    }

    if (Array.isArray(energySupply?.heatingType)) {
      const heatingType = energySupply.heatingType;
      const concatenatedValues = heatingType?.map((heat) => t(`heatingType.options.${heat}`)).join('-');

      items.push({
        key: 'heatingType',
        label: t('heatingType.label'),
        value: concatenatedValues,
      });
    }

    if (energySupply?.heatingSystem && typeof energySupply?.heatingSystem === 'string') {
      items.push({
        key: 'heatingSystem',
        label: t('heatingSystem.label'),
        value: t(`heatingSystem.options.${energySupply.heatingSystem as string}`),
      });
    }

    if (energySupply?.heatingType && typeof energySupply?.heatingType === 'string') {
      items.push({
        key: 'heatingType',
        label: t('heatingType.label'),
        value: t(`heatingType.options.${energySupply.heatingType as string}`),
      });
    }
    if (energySupply.primaryEnergySource) {
      items.push({
        key: 'primaryEnergySource',
        label: t('primaryEnergySource'),
        value: energySupply.primaryEnergySource,
      });
    }

    if (energySupply.yearOfLastActivity) {
      items.push({
        key: 'yearOfLastActivity',
        label: t('yearOfLastActivity'),
        value: energySupply.yearOfLastActivity,
      });
    }

    if (energySupply.energyEfficiency && energySupply.energyEfficiency.length) {
      items.push({
        key: 'energyEfficiency',
        label: t('energyEfficiency'),
        value: energySupply.energyEfficiency,
      });
    }

    if (energySupply.energyPassType) {
      items.push({
        key: 'energyPassType',
        label: t('energyPassType.label'),
        value: t(`energyPassType.options.${energySupply.energyPassType}`),
      });
    }

    if (energySupply.energyConsumption) {
      items.push({
        key: 'energyConsumption',
        label: t('energyConsumption'),
        value: energySupply.energyConsumption + ' kWh/(m²a)',
      });
    }

    if (typeof energySupply?.isEnergyConsumptionForHotWaterIncluded === 'boolean') {
      items.push({
        key: 'isEnergyConsumptionForHotWaterIncluded',
        label: t('isEnergyConsumptionForHotWaterIncluded'),
        value: energySupply.isEnergyConsumptionForHotWaterIncluded ? c('yes') : c('no'),
      });
    }
  }

  return energySupply ? <ItemList testId={'energySupply'} items={items} /> : <></>;
};

export default ApartmentEnergySupply;
