const convertToNiceChatDate = (date: Date): string => {
  const diff = (new Date().getTime() - date.getTime()) / 1000;
  const dayDiff = Math.floor(diff / 86400);

  if (isNaN(dayDiff) || dayDiff < 0 || dayDiff >= 31) return '';

  return (
    (dayDiff == 0 &&
      ((diff < 60 && 'Jetzt') ||
        (diff < 120 && 'vor einer Minute') ||
        (diff < 3600 && 'vor ' + Math.floor(diff / 60) + ' Minuten') ||
        (diff < 7200 && 'vor einer Stunde') ||
        (diff < 86400 && 'vor ' + Math.floor(diff / 3600) + ' Stunden'))) ||
    (dayDiff == 1 && 'Gestern') ||
    (dayDiff < 7 && 'vor ' + dayDiff + ' Tagen') ||
    (dayDiff < 31 && 'vor ' + Math.ceil(dayDiff / 7) + ' Wochen')
  );
};

export default convertToNiceChatDate;
