import { FC } from 'react';
import { MATCHING_MODE } from '@wohnsinn/ws-ts-lib';
import styles from './LandlordRatingButtons.module.scss';
import useApartment from '../../../core/hook/apartment.hook';
import CTAButton, { BUTTON_STYLE } from '../../atoms/Buttons/CTAButton';
import { useTranslation } from 'react-i18next';
import { wohnsinnServices } from '../../../App';

export interface ILandlordRatingButtonsProps {
  landlordRating: MATCHING_MODE;
  tenantProfileId: string;
  apartmentId?: string;
  big?: boolean;
}

const LandlordRatingButtons: FC<ILandlordRatingButtonsProps> = ({
  landlordRating,
  tenantProfileId,
  apartmentId,
  big = true,
}) => {
  const { applicationService } = wohnsinnServices;
  const { apartment } = useApartment(apartmentId);
  const { t } = useTranslation('common');

  const handleLandlordRatingClick = (landlordRating: MATCHING_MODE): Promise<void> => {
    return applicationService.updateApplicationLandlordRating(
      apartment.creatorId,
      apartment.id,
      tenantProfileId,
      landlordRating
    );
  };
  return (
    <div className={`${styles.ratings} ${big ? styles.big : ''}`}>
      <CTAButton
        onClick={() => handleLandlordRatingClick(MATCHING_MODE.LIKE)}
        buttonText={t('like')}
        buttonStyle={BUTTON_STYLE.SECONDARY}
        size={big ? 'big' : 'small'}
        img={landlordRating === MATCHING_MODE.LIKE ? t('icons.heart_like') : t('icons.heart_like_outlined')}
        hideText
      />
      <CTAButton
        onClick={() => handleLandlordRatingClick(MATCHING_MODE.MAYBE)}
        buttonText={t('maybe')}
        buttonStyle={BUTTON_STYLE.SECONDARY}
        size={big ? 'big' : 'small'}
        img={landlordRating === MATCHING_MODE.MAYBE ? t('icons.heart_maybe') : t('icons.heart_maybe_outlined')}
        hideText
      />
      <CTAButton
        onClick={() => handleLandlordRatingClick(MATCHING_MODE.NOPE)}
        buttonText={t('nope')}
        buttonStyle={BUTTON_STYLE.SECONDARY}
        size={big ? 'big' : 'small'}
        img={landlordRating === MATCHING_MODE.NOPE ? t('icons.heart_nope') : t('icons.heart_nope_outlined')}
        hideText
      />
    </div>
  );
};

export default LandlordRatingButtons;
