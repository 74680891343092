import { ISelectOption, PETS } from '@wohnsinn/ws-ts-lib';

export const APARTMENT_PEOPLE_NUMBER_OPTION_LIST: ISelectOption<string>[] = [...new Array(5)].map((_, i) => {
  return {
    label: `${(i < 4 ? i + 1 : '5+').toString()}`,
    value: `${(i + 1).toString()}`,
  };
});

export const TENANT_PETS_SELECT_OPTION_LIST: ISelectOption<PETS>[] = [
  { value: PETS.CAT, label: 'Katze' },
  { value: PETS.DOG, label: 'Hund' },
  { value: PETS.OTHER, label: 'Andere' },
];
