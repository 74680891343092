import { FC, ReactElement } from 'react';
import styles from './Accordion.module.scss';
import Text, { TEXT_COLOR } from '../typographie/Text';
import { useTranslation } from 'react-i18next';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Accordion as MAccordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { faChevronDown } from '@fortawesome/pro-light-svg-icons';
import Headline, { HEADLINE_SIZE } from '../typographie/Headline';

export interface IAccordionItem {
  icon?: IconProp;
  label: string | ReactElement;
  content: string | ReactElement;
  image?: string;
  imageAlt?: string;
}

export interface IAccordionProps {
  items?: IAccordionItem[];
  selectedTab?: string;
  onChange?: any;
  isLanding?: boolean;
  hideImagesOnDesktop?: boolean;
}

const Accordion: FC<IAccordionProps> = ({ items, onChange, selectedTab, isLanding = false, hideImagesOnDesktop }) => {
  const { t } = useTranslation('common');

  const renderItems = (items: IAccordionItem[]) =>
    items.map((item, index) => (
      <MAccordion
        expanded={selectedTab === `accordion-${index}`}
        className={`${styles.optionWrapper} ${
          selectedTab === `accordion-${index}` ? styles.activeTab : styles.notActiveTab
        }`}
        key={`accordion-${index}`}
        onChange={() => onChange(selectedTab === `accordion-${index}` ? null : `accordion-${index}`)}
      >
        <AccordionSummary expandIcon={<FontAwesomeIcon icon={faChevronDown} />}>
          <div className={styles.label}>
            {item.icon ? <FontAwesomeIcon icon={item.icon} /> : null}
            {typeof item.label === 'string' ? <Headline size={HEADLINE_SIZE.H2}>{t(item.label)}</Headline> : item.label}
          </div>
        </AccordionSummary>
        <AccordionDetails className={styles.content} slot="content">
          {typeof item.content === 'string' ? (
            <Text color={TEXT_COLOR.TEXT_COLOR_ACCENT}>{t(item.content)}</Text>
          ) : (
            item.content
          )}
          {item.image ? (
            <img
              className={`${styles.image} ${hideImagesOnDesktop ? styles.hideOnDesktop : ''}`}
              src={item.image}
              alt={item.imageAlt}
            />
          ) : null}
        </AccordionDetails>
      </MAccordion>
    ));

  return <div className={`${styles.accordion} ${isLanding ? styles.isLanding : ''}`}>{renderItems(items)}</div>;
};

export default Accordion;
