import { FormContext } from 'core/context/form.context';
import InputText from 'component/atoms/formElement/InputText';
import Dropdown from 'component/atoms/formElement/Dropdown';
import { BOOLEAN_SELECT_OPTIONS, getTranslatedOptions } from 'core/const/select-options';
import { APARTMENT_TYPE_OF_MARKETING, APARTMENT_TYPE, IMMO_TYPE } from '@wohnsinn/ws-ts-lib';
import DateInput from 'component/atoms/formElement/DateInput';
import InputOptionList, { RADIO_INPUT_COLUMNS_COUNT } from 'component/atoms/formElement/InputOptionList';
import { FC, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { SUBMIT_BUTTON_MODE } from 'core/enum/submit-button-mode.enum';
import { useForm } from 'react-hook-form';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { wohnsinnServices } from 'App';

import {
  APARTMENT_TYPES_OF_MARKETING,
  APARTMENT_TYPES_SELECT_OPTION_LIST,
  HOUSE_TYPES_SELECT_OPTION_LIST,
} from 'core/const/apartment-options';
import TextInput from 'component/atoms/formElement/InputText';
import FormNavigation from 'component/molecules/FormNavigation';
import { TEXT_COLOR } from 'component/atoms/typographie/Text';
import { ICreateApartmentTunnel } from 'view/landlord/apartment/ApartmentCreateView';
import InputNumber from 'component/atoms/formElement/InputNumber';
import { ROUTES } from 'core/const/routes';
import { useNavigate } from 'react-router-dom';
import FormHeader from 'component/atoms/FormHeader';
import useApartment from 'core/hook/apartment.hook';
import InfoBox from 'component/atoms/InfoBox';
import BackButton from 'component/atoms/Buttons/BackButton';
import useWindowSize from 'core/hook/windowsize.hook';
import useErrorHandling from 'core/hook/errorhandling.hook';
import FormErrorBox from 'component/molecules/FormErrorBox';
import { joiResolver } from '@hookform/resolvers/joi';
import { APARTMENT_MAIN_INFORMATION_FORM_SCHEMA } from 'core/schemas/apartment/apartment-main-information-form-schema';

const ApartmentMainInformationForm: FC<ICreateApartmentTunnel> = ({ isTunnelView }) => {
  const { t: r } = useTranslation('routes');
  const { t } = useTranslation('common');
  const { t: a } = useTranslation('common', { keyPrefix: 'apartment.areas' });
  const [buttonSubmitMode, setButtonSubmitMode] = useState<SUBMIT_BUTTON_MODE>(SUBMIT_BUTTON_MODE.NONE);
  const queryClient = useQueryClient();
  const { apartmentService } = wohnsinnServices;
  const navigate = useNavigate();
  const { apartment } = useApartment();
  const { isSmallerMd } = useWindowSize();
  const { errorObject, onErrorHandler } = useErrorHandling();

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    resolver: joiResolver(APARTMENT_MAIN_INFORMATION_FORM_SCHEMA),

    defaultValues: { mainInformation: apartment?.mainInformation, areas: apartment?.areas },
  });

  useEffect(() => {
    reset({ mainInformation: apartment?.mainInformation, areas: apartment?.areas });
  }, [apartment]);

  const typeOfMarketing = watch('mainInformation.typeOfMarketing');
  const hasMinPeriod = watch('mainInformation.hasMinPeriod');
  const hasFixedTerm = watch('mainInformation.hasFixedTerm');
  const apartmentType = watch('mainInformation.address.apartmentType');
  const isHouse =
    watch('mainInformation.typeOfImmo') === IMMO_TYPE.HOUSE ||
    apartment?.mainInformation?.typeOfImmo === IMMO_TYPE.HOUSE;
  const isApartment =
    watch('mainInformation.typeOfImmo') === IMMO_TYPE.APARTMENT ||
    apartment?.mainInformation?.typeOfImmo === IMMO_TYPE.APARTMENT;
  const showFloorNumber =
    apartmentType === APARTMENT_TYPE.ROOF ||
    apartmentType === APARTMENT_TYPE.PENTHOUSE ||
    apartmentType === APARTMENT_TYPE.RAW_ATTIC ||
    apartmentType === APARTMENT_TYPE.MAISONETTE ||
    apartmentType === APARTMENT_TYPE.LEVEL;
  const isSalesObject = typeOfMarketing === APARTMENT_TYPE_OF_MARKETING.SALE;

  const onSuccess = async (): Promise<void> => {
    setButtonSubmitMode(SUBMIT_BUTTON_MODE.NONE);
    await queryClient.invalidateQueries({ queryKey: ['apartments'] });

    if (isTunnelView) {
      navigate(r(ROUTES.landlordRoutes.apartment.create.cost.path).replace(':apartmentId', apartment?.id));
    } else {
      navigate(r(ROUTES.landlordRoutes.apartment.overview.path).replace(':apartmentId', apartment?.id));
    }
  };

  const onFormSubmit = async () => {
    setButtonSubmitMode(SUBMIT_BUTTON_MODE.SUBMITTING);

    try {
      const mainInfos = watch();
      await apartmentService.updateApartment({
        data: { ...mainInfos },
        creatorId: apartment.creatorId,
        apartmentId: apartment.id,
      });
      await onSuccess();
    } catch (e) {
      console.error('Error on ApartmentMainInformationForm onFormSubmit', e);
    }
  };

  useEffect(() => {
    if (!hasMinPeriod) {
      setValue('mainInformation.minPeriod', null);
    } else {
      setValue('mainInformation.minPeriod', apartment?.mainInformation?.minPeriod);
    }
  }, [hasMinPeriod]);

  useEffect(() => {
    if (apartment?.mainInformation?.address) {
      if (!showFloorNumber) {
        setValue('mainInformation.address.floor', null);
        setValue('mainInformation.address.buildingLevel', null);
      }
    }
  }, [apartmentType]);

  const resetOnMarketingTypeChange = () => {
    setValue('mainInformation.typeOfUsage', null);
    setValue('mainInformation.typeOfImmo', null);
    setValue('mainInformation.address.apartmentType', null);
  };

  return (
    <FormContext.Provider value={{ control, watch }}>
      <form
        onSubmit={handleSubmit(onFormSubmit, onErrorHandler)}
        autoComplete="off"
        noValidate
        id={'apartmentMainInformationForm'}
      >
        {!isTunnelView && !isSmallerMd && (
          <div style={{ paddingBottom: '1rem' }}>
            <BackButton />
          </div>
        )}
        <Grid container className={'form-with-navigation'}>
          <Grid item xs={12} md={6}>
            {Object.keys(errors).length > 0 && <FormErrorBox errors={errorObject} />}
            <FormHeader title={t('Eckdaten')} />

            {process.env.REACT_APP_SHOW_SALE === 'true' && (
              <Dropdown
                label={t('typeOfMarketing.label')}
                placeholder={t('objectTypeOfMarketing.label')}
                name="mainInformation.typeOfMarketing"
                optionList={getTranslatedOptions(APARTMENT_TYPES_OF_MARKETING, t)}
                onChange={resetOnMarketingTypeChange}
                required
              />
            )}
            {isApartment && (
              <Dropdown
                label={t('apartment.address.apartmentType.label')}
                name="mainInformation.address.apartmentType"
                optionList={getTranslatedOptions(APARTMENT_TYPES_SELECT_OPTION_LIST, t)}
              />
            )}
            <InputNumber
              name={'areas.numberOfRooms'}
              label={a('numberOfRooms.label')}
              placeHolder={t('0')}
              max={10}
              required={true}
            />

            {isHouse && (
              <Dropdown
                label={t('apartment.address.houseType.label')}
                placeholder={t('apartment.address.houseType.label')}
                name="mainInformation.address.apartmentType"
                optionList={getTranslatedOptions(HOUSE_TYPES_SELECT_OPTION_LIST, t)}
              />
            )}
            <InputText
              type={'number'}
              label={a('livingArea.label')}
              placeholder={a('livingArea.placeholder')}
              name={'areas.livingArea'}
              endAdornment={t('qm')}
              required={true}
            />
            <InputText
              type={'text'}
              label={t('address.locationInBuilding.label')}
              placeholder={t('address.locationInBuilding.placeholder')}
              name={'mainInformation.address.locationInBuilding'}
            />
            <InfoBox size={'small'} bold={false} text={t('apartmentMainInformationForm.infobox.text')}></InfoBox>
            {showFloorNumber && (
              <>
                <InputText
                  type={'number'}
                  pattern="[0-9]*"
                  name={'mainInformation.address.floor'}
                  label={t('apartment.address.floor.label')}
                />
                <InputText
                  type={'number'}
                  pattern="[0-9]*"
                  name={'mainInformation.address.buildingLevel'}
                  label={t('apartment.address.buildingLevel.label')}
                />
              </>
            )}
            {isSalesObject ? null : (
              <>
                <InputText
                  name={'mainInformation.earliestMoveIn'}
                  label={t('apartment.mainInformation.earliestMoveIn.label')}
                  placeholder={t('apartment.mainInformation.earliestMoveIn.placeholder')}
                />
                <InputOptionList
                  mode={'radio'}
                  columns={RADIO_INPUT_COLUMNS_COUNT.TWO}
                  options={getTranslatedOptions(BOOLEAN_SELECT_OPTIONS, t)}
                  label={t('apartment.mainInformation.hasMinPeriod.label')}
                  name={'mainInformation.hasMinPeriod'}
                />
                {watch('mainInformation.hasMinPeriod') && (
                  <TextInput
                    showLabel={false}
                    placeholder={t('apartment.mainInformation.minPeriod.placeholder')}
                    name={'mainInformation.minPeriod'}
                  />
                )}

                <InputOptionList
                  mode={'radio'}
                  columns={RADIO_INPUT_COLUMNS_COUNT.TWO}
                  label={t('apartment.mainInformation.hasFixedTerm.label')}
                  name={'mainInformation.hasFixedTerm'}
                  options={getTranslatedOptions(BOOLEAN_SELECT_OPTIONS, t)}
                />
                {hasFixedTerm && (
                  <DateInput
                    label={t('apartment.mainInformation.fixedTerm.label')}
                    name={'mainInformation.fixedTerm'}
                  />
                )}
              </>
            )}
          </Grid>
        </Grid>
        <FormNavigation
          flipButtons={true}
          buttonSubmitMode={buttonSubmitMode}
          disabled={false}
          showSecondaryButtonMobile={false}
          formId={'apartmentMainInformationForm'}
          submitButtonText={isTunnelView ? t('saveAndContinue') : t('save')}
          secondaryButtonAction={!isTunnelView ? () => navigate(-1) : undefined}
          secondaryButtonColor={TEXT_COLOR.TEXT_COLOR_ACCENT}
          secondaryButtonText={!isTunnelView && t('back')}
        />
      </form>
    </FormContext.Provider>
  );
};

export default ApartmentMainInformationForm;
