import { FC, PropsWithChildren, useContext } from 'react';
import styles from '../ThreeColumnsLayout.module.scss';
import ThreeColumnsLayoutContext from '../../../../core/context/three-columns-layout-context';

const ThreeColumnsLayoutMiddle: FC<PropsWithChildren> = ({ children }) => {
  const { showLeftSideBar, showRightSideBar } = useContext(ThreeColumnsLayoutContext);

  return (
    <div
      className={`${styles.middle} ${showLeftSideBar ? styles.showLeft : ''} ${
        showRightSideBar ? styles.showRight : ''
      }`}
    >
      <div className={styles.children}>{children}</div>
    </div>
  );
};

export default ThreeColumnsLayoutMiddle;
