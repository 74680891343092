import { APARTMENT_TYPE_OF_MARKETING } from '@wohnsinn/ws-ts-lib';

export const formatNumberGermanStyle = (num: number | string) => {
  num = typeof num === 'string' ? parseFloat(num) : num;
  return num.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
};

export const getFormattedPrice = (
  typeOfMarketing: APARTMENT_TYPE_OF_MARKETING,
  warmRent?: number,
  coldRent?: number,
  purchasePriceOnRequest?: boolean,
  purchasePriceValue?: number
) => {
  if (typeOfMarketing === APARTMENT_TYPE_OF_MARKETING.RENT) {
    if (warmRent) {
      return `${formatNumberGermanStyle(warmRent)} €`;
    } else if (coldRent) {
      return `${formatNumberGermanStyle(coldRent)} €`;
    }
  }

  if (typeOfMarketing === APARTMENT_TYPE_OF_MARKETING.SALE) {
    if (purchasePriceOnRequest) {
      return 'Auf Anfrage';
    } else if (purchasePriceValue) {
      return `${formatNumberGermanStyle(purchasePriceValue)} €`;
    }
  }

  return 'Keine Angabe';
};
