import { KeyboardEvent } from 'react';

export interface IHandleKeyboardNavigationProps {
  enterHandler?: (e: KeyboardEvent) => void;
  enableArrowKeyNavigation?: boolean;
  tabHandler?: (e: KeyboardEvent) => void;
  spaceHandler?: (e: KeyboardEvent) => void;
}

export default function useAccessibleNavigation(): {
  handleKeyboardNavigation: (event: KeyboardEvent, keyboardNavigationConfig?: IHandleKeyboardNavigationProps) => void;
} {
  const handleKeyboardNavigation = (event: KeyboardEvent, keyboardNavigationConfig: IHandleKeyboardNavigationProps) => {
    switch (event.key) {
      case 'Enter':
        if (keyboardNavigationConfig?.enterHandler) {
          event.preventDefault();
          keyboardNavigationConfig?.enterHandler(event);
        }
        return;

      case 'Tab':
        if (keyboardNavigationConfig?.tabHandler) {
          event.preventDefault();
          keyboardNavigationConfig?.tabHandler(event);
        }
        return;

      case 'ArrowUp':
        if (keyboardNavigationConfig?.enableArrowKeyNavigation) {
          event.preventDefault();
          tryToFocus(event.key);
        }

        return;

      case 'ArrowLeft':
        if (keyboardNavigationConfig?.enableArrowKeyNavigation) {
          event.preventDefault();
          tryToFocus(event.key);
        }
        return;

      case 'ArrowRight':
        if (keyboardNavigationConfig?.enableArrowKeyNavigation) {
          event.preventDefault();
          tryToFocus(event.key);
        }
        return;

      case 'ArrowDown':
        if (keyboardNavigationConfig?.enableArrowKeyNavigation) {
          event.preventDefault();
          tryToFocus(event.key);
        }
        return;

      default:
        if (event.code === 'Space' && keyboardNavigationConfig?.spaceHandler) {
          event.preventDefault();
          keyboardNavigationConfig?.spaceHandler(event);
        }
    }
  };

  const tryToFocus = (key: string) => {
    const prevNext = {
      ArrowDown: 'nextElementSibling',
      ArrowUp: 'previousElementSibling',
    };
    if (Object.keys(prevNext).find((k) => key === k)) {
      document &&
        document.activeElement &&
        // @ts-ignore
        document.activeElement[prevNext[key]] &&
        // @ts-ignore
        document.activeElement[prevNext[key]].focus();
    }
  };

  return {
    handleKeyboardNavigation,
  };
}
