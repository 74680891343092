import ModalWrapper, { IModalWrapperButtonProps } from '../ModalWrapper';
import { useTranslation } from 'react-i18next';
import { ROUTES } from 'core/const/routes';
import { FC, useContext } from 'react';
import ModalContext from 'core/context/modal.context';
import Text, { TEXT_TYPE } from 'component/atoms/typographie/Text';
import { useNavigate } from 'react-router-dom';

const MissingApplicationFolderModal: FC = () => {
  const { t } = useTranslation('common');
  const { t: r } = useTranslation('routes');
  const navigate = useNavigate();
  const { closeModal } = useContext(ModalContext);

  const handleCompleteHouseholdClick = () => {
    closeModal();
    navigate(r(ROUTES.tenantRoutes.account.overview.path));
  };

  const MODAL_BUTTONS: IModalWrapperButtonProps = {
    primary: {
      onClick: handleCompleteHouseholdClick,
      buttonText: 'Bewerbermappe vervollständigen',
    },
  };

  return (
    <ModalWrapper title={t('Der letzte Schritt vor der Bewerbung')} buttons={MODAL_BUTTONS}>
      <Text align={'center'} type={TEXT_TYPE.TEXT_TYPE_BODY}>
        Um dich auf die Wohnung zu bewerben benötigst du eine vollständige Bewerbermappe.
      </Text>
    </ModalWrapper>
  );
};

export default MissingApplicationFolderModal;
