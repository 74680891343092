import { APARTMENT_TYPE_OF_MARKETING, ISelectOption } from '@wohnsinn/ws-ts-lib';
import { ISelectOptionWithComingSoon } from '../../component/atoms/formElement/Dropdown';

export const getTranslatedOptions = <T>(
  selectOptions: ISelectOptionWithComingSoon<T>[],
  transLateFn: (key: string) => string
): ISelectOptionWithComingSoon[] => {
  return selectOptions.map((o) => {
    return { value: o.value, label: transLateFn(o.label), isComingSoon: o?.isComingSoon ?? false };
  });
};

export const BOOLEAN_SELECT_OPTIONS: ISelectOption<boolean>[] = [
  { value: true, label: 'Ja' },
  { value: false, label: 'Nein' },
];

export const BOOLEAN_MWST_LIST: ISelectOption<boolean>[] = [
  { value: true, label: 'zzgl. MwSt.' },
  { value: false, label: 'inkl. Mwst.' },
];

export const USER_TYPE_SELECT_OPTIONS: ISelectOption<boolean>[] = [
  { value: true, label: 'Objekt inserieren' },
  { value: false, label: 'Wohnung suchen' },
];

export const PETS_ALLOWED_SELECT_OPTIONS: ISelectOption<boolean>[] = [
  { value: true, label: 'Nach Vereinbarung' },
  { value: false, label: 'Nein' },
];

export const SEARCH_RENT_OBJECT_SELECT_OPTIONS: ISelectOption<APARTMENT_TYPE_OF_MARKETING>[] = [
  { value: APARTMENT_TYPE_OF_MARKETING.RENT, label: 'Mieten' },
  { value: APARTMENT_TYPE_OF_MARKETING.SALE, label: 'Kaufen' },
];
