import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ROUTES } from 'core/const/routes';
import PageLayout from 'component/layouts/PageLayout';
import ApartmentCreateForm from 'component/forms/apartment/ApartmentCreateForm';
import ProgressBar from 'component/atoms/ProgressBar';
import { IApartment } from '@wohnsinn/ws-ts-lib';
import useWindowSize from 'core/hook/windowsize.hook';

export interface ICreateApartmentTunnel {
  isTunnelView?: boolean;
  apartment?: IApartment;
  progress?: number;
}

const ApartmentCreateView: FC<ICreateApartmentTunnel> = ({ isTunnelView = false, progress = 0 }) => {
  const { t: r } = useTranslation('routes');
  const { isSmallerMd } = useWindowSize();

  return (
    <PageLayout
      showPageTitle={false}
      pageTitle={r(ROUTES.landlordRoutes.apartment.mainInformation.title)}
      showBackButton={isSmallerMd && !isTunnelView}
      hideMenu={isTunnelView}
    >
      {isTunnelView && <ProgressBar progress={progress} />}
      <ApartmentCreateForm isTunnelView={isTunnelView} />
    </PageLayout>
  );
};

export default ApartmentCreateView;
