import { useTranslation } from 'react-i18next';
import HeadlineTextBanner from '../../../../component/molecules/HeadlineTextBanner';
import { Grid } from '@mui/material';
import { HEADLINE_SIZE } from '../../../atoms/typographie/Headline';
import CTACard from '../../../molecules/Cards/CTACard';
import { TEXT_COLOR } from '../../../atoms/typographie/Text';
import { IMAGES } from '../../../../core/const/images';

const LandlordProSection = () => {
  const { t: l } = useTranslation('common', { keyPrefix: 'view.LandlordLanding.pro' });
  const { t: i } = useTranslation('images');

  return (
    <Grid container rowSpacing={8} columnSpacing={4} justifyContent={'center'} alignItems={'center'}>
      <Grid item xs={12}>
        <HeadlineTextBanner
          tag={l('tag')}
          headline={l('headline')}
          headlineSize={HEADLINE_SIZE.H1}
          text={l('subtitle')}
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container paddingTop={6} rowSpacing={5} columnSpacing={4}>
          <Grid item xs={12} md={4}>
            <CTACard
              imgHeight={150}
              headlineSize={HEADLINE_SIZE.H3}
              title={l('pros.first.headline')}
              text={l('pros.first.text')}
              textColor={TEXT_COLOR.TEXT_COLOR_ACCENT}
              imgSrc={i(IMAGES.graphics.faster.src)}
              imgAltText={i(IMAGES.graphics.faster.alt)}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CTACard
              imgHeight={150}
              headlineSize={HEADLINE_SIZE.H3}
              title={l('pros.second.headline1')}
              text={l('pros.second.text')}
              textColor={TEXT_COLOR.TEXT_COLOR_ACCENT}
              imgSrc={i(IMAGES.graphics.heart.src)}
              imgAltText={i(IMAGES.graphics.heart.alt)}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CTACard
              imgHeight={150}
              headlineSize={HEADLINE_SIZE.H3}
              title={l('pros.third.headline')}
              text={l('pros.third.text')}
              textColor={TEXT_COLOR.TEXT_COLOR_ACCENT}
              imgSrc={i(IMAGES.graphics.profit.src)}
              imgAltText={i(IMAGES.graphics.profit.alt)}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LandlordProSection;
