import ModalWrapper, { IModalWrapperButtonProps } from '../ModalWrapper';
import { useTranslation } from 'react-i18next';
import { ROUTES } from 'core/const/routes';
import { useContext } from 'react';
import ModalContext from 'core/context/modal.context';
import Text from 'component/atoms/typographie/Text';
import { useNavigate } from 'react-router-dom';

const CreateOrUploadSchufaModal = () => {
  const { t } = useTranslation('common', { keyPrefix: 'component.molecules.modals.createOrUploadSchufa' });
  const { t: r } = useTranslation('routes');
  const navigate = useNavigate();
  const { closeModal } = useContext(ModalContext);

  const onSelectSchufaCreate = () => {
    closeModal();
    return navigate(r(ROUTES.staticRoutes.move.createSchufa.path));
  };

  const onSelectSchufaUpload = (): void => {
    document.getElementById('schufaCheckFileInput').click();
  };

  const MODAL_BUTTONS: IModalWrapperButtonProps = {
    primary: {
      buttonText: t('addNewButton'),
      onClick: onSelectSchufaCreate,
    },
    secondary: {
      buttonText: t('uploadSchufa'),
      onClick: onSelectSchufaUpload,
    },
  };

  return (
    <ModalWrapper title={t('title')} buttons={MODAL_BUTTONS}>
      <Text align={'center'}>
        Wähle aus ob du einen bestehenden Schufa-Auszug hochladen oder einen Bonitätscheck durchführen möchtest.
      </Text>
    </ModalWrapper>
  );
};

export default CreateOrUploadSchufaModal;
