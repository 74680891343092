import { useState } from 'react';
import Text, { TEXT_WEIGHT } from '../../../atoms/typographie/Text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-light-svg-icons';
import styles from './LPNavigation.module.scss';
import { ROUTES } from '../../../../core/const/routes';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Badge, { BADGE_COLORS } from '../../../atoms/Badge';
import { useLocation } from 'react-router-dom';

enum ACTIVE_NAV_ITEM {
  NONE = 'NONE',
  PROVIDER = 'PROVIDER',
  ABOUT_US = 'ABOUT_US',
}
const LPNavigation = () => {
  const { t: r } = useTranslation('routes');
  const [isActive, setIsActive] = useState(ACTIVE_NAV_ITEM.NONE);
  const location = useLocation();

  return (
    <>
      <div
        className={`${styles.backdrop} ${isActive !== ACTIVE_NAV_ITEM.NONE ? styles.show : ''}`}
        onClick={() => setIsActive(ACTIVE_NAV_ITEM.NONE)}
      />
      <ul className={styles.navigation}>
        <li className={styles.listItem} onClick={() => setIsActive(ACTIVE_NAV_ITEM.PROVIDER)}>
          <Text className={styles.listItemText} weight={TEXT_WEIGHT.TEXT_WEIGHT_REGULAR} tag={'span'}>
            Anbieter
            <FontAwesomeIcon icon={isActive === ACTIVE_NAV_ITEM.PROVIDER ? faChevronUp : faChevronDown} />
          </Text>
          <ul className={`${styles.subMenu} ${isActive === ACTIVE_NAV_ITEM.PROVIDER ? styles.show : ''}`}>
            <li>
              <Link
                className={`${styles.linkWithIcon} ${
                  location?.pathname?.includes(r(ROUTES.landingPageRoutes.landlord.path)) ? styles.active : ''
                }`}
                to={r(ROUTES.landingPageRoutes.landlord.path)}
              >
                <img src="/assets/icon/navigation/Für_private_Vermieter_Icon.svg" alt="fuer vermieter" />
                <Text weight={TEXT_WEIGHT.TEXT_WEIGHT_REGULAR} tag={'span'}>
                  Für private Vermieter
                </Text>
              </Link>
            </li>
            <li>
              <Link
                className={`${styles.linkWithIcon} ${
                  location?.pathname?.includes(r(ROUTES.landingPageRoutes.propertyManagement.path)) ? styles.active : ''
                }`}
                to={r(ROUTES.landingPageRoutes.propertyManagement.path)}
              >
                <img src="/assets/icon/navigation/Für_Hausverwaltungen_Icon.svg" alt="fuer hausverwaltungen" />
                <Text weight={TEXT_WEIGHT.TEXT_WEIGHT_REGULAR} tag={'span'}>
                  Für Hausverwaltungen
                </Text>
              </Link>
            </li>
            <li>
              <Link
                className={`${styles.linkWithIcon} ${
                  location?.pathname?.includes(r(ROUTES.landingPageRoutes.realEstateAgent.path)) ? styles.active : ''
                }`}
                to={r(ROUTES.landingPageRoutes.realEstateAgent.path)}
              >
                <img src="/assets/icon/navigation/Für_Makler_Icon.svg" alt="fuer makler" />
                <Text weight={TEXT_WEIGHT.TEXT_WEIGHT_REGULAR} tag={'span'}>
                  Für Makler
                </Text>
              </Link>
            </li>
            <li>
              <Link
                className={`${styles.linkWithIcon} ${
                  location?.pathname?.includes(r(ROUTES.landingPageRoutes.cooperative.path)) ? styles.active : ''
                }`}
                to={r(ROUTES.landingPageRoutes.cooperative.path)}
              >
                <img src="/assets/icon/navigation/Für_Genossenschaften_Icon.svg" alt="fuer genossenschaften" />
                <Text weight={TEXT_WEIGHT.TEXT_WEIGHT_REGULAR} tag={'span'}>
                  Für Genossenschaften
                </Text>
              </Link>
            </li>
          </ul>
        </li>
        <li className={styles.listItem} onClick={() => setIsActive(ACTIVE_NAV_ITEM.ABOUT_US)}>
          <Text className={styles.listItemText} weight={TEXT_WEIGHT.TEXT_WEIGHT_REGULAR} tag={'span'}>
            Über uns
            <FontAwesomeIcon icon={isActive === ACTIVE_NAV_ITEM.ABOUT_US ? faChevronUp : faChevronDown} />
          </Text>
          <ul className={`${styles.subMenu} ${isActive === ACTIVE_NAV_ITEM.ABOUT_US ? styles.show : ''}`}>
            <li>
              <Link
                className={`${styles.linkWithIcon} ${
                  location?.pathname?.includes(r(ROUTES.landingPageRoutes.about.path)) ? styles.active : ''
                }`}
                to={r(ROUTES.landingPageRoutes.about.path)}
              >
                <img src="/assets/icon/navigation/Über_Wohnsinn_Icon.svg" alt="fuer vermieter" />
                <Text weight={TEXT_WEIGHT.TEXT_WEIGHT_REGULAR} tag={'span'}>
                  Über Wohnsinn
                </Text>
              </Link>
            </li>
            <li>
              <Link
                className={`${styles.linkWithIcon} ${
                  location?.pathname?.includes(r(ROUTES.landingPageRoutes.contact.path)) ? styles.active : ''
                }`}
                to={r(ROUTES.landingPageRoutes.contact.path)}
              >
                <img src="/assets/icon/navigation/Kontakt_Icon.svg" alt="fuer vermieter" />
                <Text weight={TEXT_WEIGHT.TEXT_WEIGHT_REGULAR} tag={'span'}>
                  Kontakt
                </Text>
              </Link>
            </li>
            <li>
              <Link
                className={`${styles.linkWithIcon} ${
                  location?.pathname?.includes(r(ROUTES.landingPageRoutes.press.path)) ? styles.active : ''
                }`}
                to={r(ROUTES.landingPageRoutes.press.path)}
              >
                <img src="/assets/icon/navigation/Presse_Icon.svg" alt="fuer vermieter" />
                <Text weight={TEXT_WEIGHT.TEXT_WEIGHT_REGULAR} tag={'span'}>
                  Presse
                </Text>
              </Link>
            </li>
            <li>
              <Link
                className={`${styles.linkWithIcon} ${
                  location?.pathname?.includes(r(ROUTES.landingPageRoutes.jobs.path)) ? styles.active : ''
                }`}
                to={r(ROUTES.landingPageRoutes.jobs.path)}
              >
                <img src="/assets/icon/navigation/Jobs_Icon.svg" alt="fuer vermieter" />
                <Text weight={TEXT_WEIGHT.TEXT_WEIGHT_REGULAR} tag={'span'}>
                  Jobs
                </Text>
                <Badge text={'Wir stellen ein!'} color={BADGE_COLORS.SUCCESS} weight={TEXT_WEIGHT.TEXT_WEIGHT_LIGHT} />
              </Link>
            </li>
          </ul>
        </li>
        <li className={styles.listItem}>
          <Link to={r(ROUTES.landingPageRoutes.prices.path)}>
            <Text className={styles.listItemText} weight={TEXT_WEIGHT.TEXT_WEIGHT_REGULAR} tag={'span'}>
              Preise
            </Text>
          </Link>
        </li>
      </ul>
    </>
  );
};

export default LPNavigation;
