import { Grid } from '@mui/material';
import Headline, { HEADLINE_SIZE } from 'component/atoms/typographie/Headline';
import Text, { TEXT_COLOR, TEXT_TYPE } from 'component/atoms/typographie/Text';
import { IMAGES } from 'core/const/images';
import TextBlockSection from 'component/molecules/TextBlockSection';
import CheckList from 'component/atoms/CheckList';
import Spacer, { SPACER_SIZE } from 'component/atoms/Spacer';
import { useTranslation } from 'react-i18next';

const SuccessPartnerSection = () => {
  const { t: i } = useTranslation('images');
  return (
    <>
      <Grid container justifyContent={'center'}>
        <Grid item xs={12} md={8} paddingBottom={{ xs: 4, md: 10 }}>
          <Headline size={HEADLINE_SIZE.DISPLAY} align={'center'}>
            Gemeinsam zum Erfolg mit starken Partnern
          </Headline>
        </Grid>
      </Grid>

      <Grid container alignItems={'center'} paddingTop={6} rowSpacing={4} columnSpacing={24}>
        <Grid item xs={12} sm={6} alignItems={'center'}>
          <TextBlockSection
            headlineSize={HEADLINE_SIZE.H1}
            title={'Zuverlässige Mieter - '}
            titleTwo={'weniger Stress.'}
          >
            <Spacer size={SPACER_SIZE.MD} />
            <Text tag={'span'} color={TEXT_COLOR.TEXT_COLOR_ACCENT} type={TEXT_TYPE.TEXT_TYPE_LANDING}>
              Wohnsinn unterstützt dich dabei, dein Objekt schnell und unkompliziert zu vermieten.
            </Text>
          </TextBlockSection>

          <Spacer size={SPACER_SIZE.LG} />

          <CheckList accentColor items={SUCCESS_PARTNER} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <img src={i(IMAGES.pros.landlord.hug.src)} alt={i(IMAGES.pros.landlord.hug.alt)} />
        </Grid>
      </Grid>
    </>
  );
};

const SUCCESS_PARTNER = [
  {
    text: 'Partner des Haus & Grund Bonn/Rhein-Sieg',
    bold: false,
    invert: false,
  },
  {
    text: 'Keine unnötigen Anfragen bearbeiten',
    bold: false,
    invert: false,
  },
  {
    text: 'Mehr Zeit fürs das Wesentliche',
    bold: false,
    invert: false,
  },
];

export default SuccessPartnerSection;
