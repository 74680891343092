import { FC } from 'react';
import styles from './ApartmentCardApartmentInformation.module.scss';
import Text, { TEXT_COLOR } from '../../../atoms/typographie/Text';
import Headline, { HEADLINE_COLOR, HEADLINE_SIZE } from '../../../atoms/typographie/Headline';
import { APARTMENT_TYPE_OF_MARKETING, IApartmentAddress, IApartmentCost } from '@wohnsinn/ws-ts-lib';
import { useTranslation } from 'react-i18next';
import { getFormattedPrice } from 'core/helper/get-formatted-price';

const ApartmentCardApartmentInformation: FC<{
  address: IApartmentAddress;
  cost?: IApartmentCost;
  typeOfMarketing: APARTMENT_TYPE_OF_MARKETING;
  rooms?: number;
  size?: number;
}> = ({ address, cost, rooms, size, typeOfMarketing }) => {
  const { t } = useTranslation('common');
  return (
    <div className={styles.content}>
      <div className={styles.bar}>
        <Text tag={'span'} color={TEXT_COLOR.TEXT_COLOR_ACCENT}>
          {address.postalCode} {address.city}
        </Text>
        <Text tag={'span'} color={TEXT_COLOR.TEXT_COLOR_ACCENT}>
          {rooms ? `${rooms} ${t('rooms')}` : ''}
          {rooms && size ? ' · ' : ''}
          {size ? `${size} ${t('qm')}` : ''}
        </Text>
      </div>

      <div className={styles.bar}>
        <Headline size={HEADLINE_SIZE.H2} color={HEADLINE_COLOR.HEADLINE_COLOR_PRIMARY}>
          {getFormattedPrice(
            typeOfMarketing,
            cost?.warmRent,
            cost?.coldRent,
            cost?.purchasePrice?.onRequest,
            cost?.purchasePrice?.value
          )}
        </Headline>

        <Headline size={HEADLINE_SIZE.H3} color={HEADLINE_COLOR.HEADLINE_COLOR_DARK} underline={true}>
          {'Mehr Infos'}
        </Headline>
      </div>
    </div>
  );
};

export default ApartmentCardApartmentInformation;
