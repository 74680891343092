import { FirebaseFunctionsService } from './firebase-functions.service';
import FB_FUNCTION_URLS from '../const/fb-function-names';
import { IRegistrationRequest, ITenantFilterParams, REGISTRATION_REFERRER, USER_TYPE } from '@wohnsinn/ws-ts-lib';

class RegistrationRequestService {
  constructor(private firebaseFunctionsService: FirebaseFunctionsService) {}

  /**
   * Create a registrationRequest document in a database with the given email and with a created activation-pin
   * Send an email with the pin to the user
   * @param email
   */
  public async sendRegistrationRequestMail(email: string) {
    try {
      await this.firebaseFunctionsService.callFbFunction(
        FB_FUNCTION_URLS.registrationRequest.sendRegistrationRequestMail,
        {
          body: { email },
        }
      );
    } catch (e) {
      console.error('Error on sendActivationMail', e);
    }
  }

  /**
   * Create a registrationRequest document in a database with the given email and with a created activation-pin
   * Send an email with the pin to the user
   * @param email
   * @param userType
   * @param referrer
   * @param tenantFilterParams
   */
  public async createUserRegistrationRequest(
    email: string,
    userType: USER_TYPE,
    referrer?: REGISTRATION_REFERRER,
    tenantFilterParams?: ITenantFilterParams
  ) {
    try {
      await this.firebaseFunctionsService.callFbFunction(
        FB_FUNCTION_URLS.registrationRequest.createUserRegistrationRequest,
        {
          body: { email, userType, referrer, tenantFilterParams },
        }
      );
    } catch (e) {
      console.error('Error on createUserRegistrationRequest', e);
    }
  }

  /**
   * Check if the passed code and email is a valid registration request
   * @param email
   * @param code
   */
  public async verifyRegistrationRequest(email: string, code: number): Promise<IRegistrationRequest> {
    /* TODO: check if pin is the same and if createdAt date is no to old */
    try {
      const response = await this.firebaseFunctionsService.callFbFunction(
        FB_FUNCTION_URLS.registrationRequest.verifyRegistrationRequest,
        {
          body: { email, code },
        }
      );

      return response.data;
    } catch (e) {
      console.error('Error on getRegistrationRequest', e);
    }
  }

  public async deleteRegistrationRequest(email: string) {
    try {
      await this.firebaseFunctionsService.callFbFunction(
        FB_FUNCTION_URLS.registrationRequest.deleteRegistrationRequest,
        {
          body: { email },
        }
      );
    } catch (e) {
      console.error('Error on deleteRegistrationRequest', e);
    }
  }
}

export default RegistrationRequestService;
