import { FC } from 'react';
import { Layer, Source } from 'react-map-gl';
import {
  fastFoodLayer,
  hospitalLayer,
  kitaLayer,
  pharmacyLayer,
  restaurantLayer,
  schoolsLayer,
  supermarketLayer,
  trainsLayer,
} from './PoiLayers';
import { POI_TYPE } from '@wohnsinn/ws-ts-lib';

export interface IMapLayerProps {
  activePoiList: POI_TYPE[];
}

const PoiLayer: FC<IMapLayerProps> = ({ activePoiList }) => {
  return (
    <Source id={'mapbox-streets'} type={'vector'} url={'mapbox://mapbox.mapbox-streets-v8'}>
      {activePoiList.includes(POI_TYPE.RESTAURANTS) && (
        <Layer {...restaurantLayer} layout={{ 'icon-image': 'poiSymbol', 'icon-size': 0.25 }} />
      )}
      {activePoiList.includes(POI_TYPE.HOSPITALS) && (
        <Layer {...hospitalLayer} layout={{ 'icon-image': 'poiSymbol', 'icon-size': 0.25 }} />
      )}
      {activePoiList.includes(POI_TYPE.TRAINS) && (
        <Layer {...trainsLayer} layout={{ 'icon-image': 'poiSymbol', 'icon-size': 0.25 }} />
      )}
      {activePoiList.includes(POI_TYPE.SCHOOLS) && (
        <Layer {...schoolsLayer} layout={{ 'icon-image': 'poiSymbol', 'icon-size': 0.25 }} />
      )}
      {activePoiList.includes(POI_TYPE.PHARMACY) && (
        <Layer {...pharmacyLayer} layout={{ 'icon-image': 'poiSymbol', 'icon-size': 0.25 }} />
      )}
      {activePoiList.includes(POI_TYPE.KITA) && (
        <Layer {...kitaLayer} layout={{ 'icon-image': 'poiSymbol', 'icon-size': 0.25 }} />
      )}
      {activePoiList.includes(POI_TYPE.SUPERMARKET) && (
        <Layer {...supermarketLayer} layout={{ 'icon-image': 'poiSymbol', 'icon-size': 0.25 }} />
      )}
      {activePoiList.includes(POI_TYPE.FAST_FOOD) && (
        <Layer {...fastFoodLayer} layout={{ 'icon-image': 'poiSymbol', 'icon-size': 0.25 }} />
      )}
    </Source>
  );
};

export default PoiLayer;
