import { FC, PropsWithChildren, useContext } from 'react';
import styles from '../ThreeColumnsLayout.module.scss';
import ThreeColumnsLayoutContext from '../../../../core/context/three-columns-layout-context';

const ThreeColumnsLayoutLeftSide: FC<PropsWithChildren> = ({ children }) => {
  const { showLeftSideBar } = useContext(ThreeColumnsLayoutContext);
  return <div className={`${styles.left} ${showLeftSideBar ? styles.show : ''}`}>{children}</div>;
};

export default ThreeColumnsLayoutLeftSide;
