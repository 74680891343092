import { useTranslation } from 'react-i18next';
import { ROUTES } from '../../../core/const/routes';
import Card, { CARD_COLORS } from '../../atoms/Card';
import { PopupButton } from '@typeform/embed-react';
import { Grid } from '@mui/material';
import { Link } from 'react-router-dom';

const TenantServices = () => {
  const { t: r } = useTranslation('routes');
  const { t: v } = useTranslation('common', { keyPrefix: 'view.ServicesView' });

  return (
    <>
      <Grid item xs={12} md={6}>
        <Link to={r(ROUTES.staticRoutes.move.createSchufa.path) as string}>
          <Card
            headline={v('iconBox.schufa.title')}
            text={v('iconBox.schufa.text')}
            iconSrc={v('iconBox.schufa.iconSrc')}
            iconAltText={v('iconBox.schufa.altText')}
          />
        </Link>
      </Grid>
      <Grid item xs={12} md={6}>
        <Link to={r(ROUTES.staticRoutes.move.compareCheck24.path).replace(':type', 'gas')}>
          <Card
            headline={v('iconBox.gas.title')}
            text={v('iconBox.gas.text')}
            iconSrc={v('iconBox.gas.iconSrc')}
            iconAltText={v('iconBox.gas.altText')}
            color={CARD_COLORS.RED}
          />
        </Link>
      </Grid>
      <Grid item xs={12} md={6}>
        <Link to={r(ROUTES.staticRoutes.move.compareCheck24.path).replace(':type', 'power')}>
          <Card
            headline={v('iconBox.power.title')}
            text={v('iconBox.power.text')}
            iconSrc={v('iconBox.power.iconSrc')}
            iconAltText={v('iconBox.power.altText')}
            color={CARD_COLORS.GREEN}
          />
        </Link>
      </Grid>
      <Grid item xs={12} md={6}>
        <Link to={r(ROUTES.staticRoutes.move.compareCheck24.path).replace(':type', 'dsl')}>
          <Card
            headline={v('iconBox.dsl.title')}
            text={v('iconBox.dsl.text')}
            iconSrc={v('iconBox.dsl.iconSrc')}
            iconAltText={v('iconBox.dsl.altText')}
            color={CARD_COLORS.GREY}
          />
        </Link>
      </Grid>
      <Grid item xs={12} md={6}>
        <PopupButton id={'CysxZlWc'}>
          <Card
            headline={v('iconBox.skz.title')}
            text={v('iconBox.skz.text')}
            iconSrc={v('iconBox.skz.iconSrc')}
            iconAltText={v('iconBox.skz.altText')}
            color={CARD_COLORS.RED}
          />
        </PopupButton>
      </Grid>
    </>
  );
};

export default TenantServices;
