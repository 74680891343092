import AdminTableHead from './helper/ApartmentListControls';
import ApartmentListTableBody from './helper/ApartmentListTableBody';
import CTAButton from 'component/atoms/Buttons/CTAButton';
import CTACard from 'component/molecules/Cards/CTACard';
import LoadingAnimation from 'component/atoms/LoadingAnimation';
import PageLayout from 'component/layouts/PageLayout';
import SearchInput from 'component/atoms/formElement/SearchInput';
import Tabs from 'component/atoms/Tabs';
import Text, { TEXT_COLOR } from 'component/atoms/typographie/Text';
import UserContext from 'core/context/user.context';
import styles from './ApartmentListView.module.scss';
import { ApartmentListTableHead } from './helper/ApartmentListTableHead';
import { Grid, Paper, Table, TableContainer, TableFooter, TablePagination, TableRow } from '@mui/material';
import { IApartment } from '@wohnsinn/ws-ts-lib';
import { LIST_MODE, useFetchApartments } from 'core/hook/apartment-queries.hook';
import { ROUTES } from 'core/const/routes';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

const ApartmentListView = () => {
  const { t: r } = useTranslation('routes');
  const { t } = useTranslation('common');
  const [selectedApartments, setSelectedApartments] = useState<IApartment[]>([]);
  const [searchKey, setSearchKey] = useState<string>(null);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const { t: v } = useTranslation('common', { keyPrefix: 'view.ApartmentListView' });
  const [listMode, setListMode] = useState<LIST_MODE>(LIST_MODE.ACQUISITION);
  const { data: fetchedApartments, isFetching, isLoading } = useFetchApartments();
  const { user } = useContext(UserContext);

  const APARTMENT_LIST_TABS = [
    {
      value: LIST_MODE.ACQUISITION,
      label: `Im Bewerbungsprozess ${
        isLoading || isFetching ? `` : `(${fetchedApartments?.acquisitionApartmentList?.length})`
      }`,
      id: 'tab-marketing',
      badge: fetchedApartments?.acquisitionApartmentList.some(
        (obj) => obj.unreadTenantChatsRef && obj.unreadTenantChatsRef.length > 0
      ),
    },
    {
      value: LIST_MODE.PUBLISHED,
      label: `Veröffentlicht ${
        isLoading || isFetching ? `` : `(${fetchedApartments?.publishedApartmentList?.length})`
      }`,
      id: 'tab-published',
      badge: fetchedApartments?.publishedApartmentList.some(
        (obj) => obj.unreadTenantChatsRef && obj.unreadTenantChatsRef.length > 0
      ),
    },
    {
      value: LIST_MODE.DEACTIVATED,
      label: `Deaktiviert ${isLoading || isFetching ? `` : `(${fetchedApartments?.deactivatedApartmentList?.length})`}`,
      id: 'tab-deactivated',
      badge: fetchedApartments?.deactivatedApartmentList.some(
        (obj) => obj.unreadTenantChatsRef && obj.unreadTenantChatsRef.length > 0
      ),
    },
    {
      value: LIST_MODE.ALL,
      label: `Alle Objekte ${isLoading || isFetching ? `` : `(${fetchedApartments?.allApartmentList?.length})`}`,
      id: 'tab-all',
      badge: fetchedApartments?.allApartmentList.some(
        (obj) => obj.unreadTenantChatsRef && obj.unreadTenantChatsRef.length > 0
      ),
    },
  ];

  const handleSearch = (searchKey: string) => {
    setSearchKey(searchKey);
    setPage(0);
  };

  const filteredAndPaginatedApartments = () => {
    let filteredList = fetchedApartments?.[`${listMode}ApartmentList`] || [];
    if (searchKey) {
      const searchComponents = searchKey
        .toLowerCase()
        .split(/[ ,.]+/)
        .map((component) => component.trim());
      filteredList = filteredList.filter((apartment) => {
        return searchComponents.every((component) => {
          return [
            apartment.mainInformation.address.street,
            apartment.mainInformation.address.houseNumber,
            apartment.mainInformation.address.postalCode,
            apartment.mainInformation.address.city,
          ].some((field) => field?.toLowerCase().includes(component));
        });
      });
    }
    const startIndex = page * rowsPerPage;
    return rowsPerPage > 0 ? filteredList.slice(startIndex, startIndex + rowsPerPage) : filteredList;
  };

  const handleTabChange = (event: any, newValue: LIST_MODE) => {
    setPage(0);
    setListMode(newValue);
  };

  const handleChangePage = (event: any, newPage: number) => setPage(newPage);

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const totalItems = searchKey
    ? fetchedApartments?.[`${listMode}ApartmentList`]?.filter((apartment) =>
        [
          apartment.id,
          apartment.mainInformation.address.street,
          apartment.mainInformation.address.postalCode,
          apartment.mainInformation.address.city,
        ].some((field) => field?.toLowerCase().includes(searchKey.toLowerCase()))
      ).length
    : fetchedApartments?.[`${listMode}ApartmentList`]?.length;

  if (isFetching) {
    return (
      <PageLayout pageTitle={r(ROUTES.landlordRoutes.apartment.list.title)}>
        <LoadingAnimation />
      </PageLayout>
    );
  }
  if (!isLoading && !isFetching && fetchedApartments?.allApartmentsCount < 1) {
    return (
      <PageLayout pageTitle={r(ROUTES.landlordRoutes.apartment.list.title)}>
        <Grid container justifyContent={'center'}>
          <Grid item xs={12} md={6}>
            <div className={styles.emptyList}>
              <CTACard
                title={v('noObjects')}
                text={v('noApartmentsText')}
                imgSrc={t('pictogram.object')}
                imgAltText={'keine Objekte vorhanden'}
                ctaText={v('createNew')}
                link={r(ROUTES.landlordRoutes.apartment.create.new.path)}
              />
            </div>
          </Grid>
        </Grid>
      </PageLayout>
    );
  }

  const getName = () => {
    switch (listMode) {
      case LIST_MODE.ALL:
        return 'Alle Objekte';
      case LIST_MODE.PUBLISHED:
        return 'Veröffentlichte Objekte';
      case LIST_MODE.DEACTIVATED:
        return 'Deaktivierte Objekte';
      case LIST_MODE.ACQUISITION:
        return 'Im Bewerbungsprozess';
      default:
        return '';
    }
  };

  return (
    <PageLayout pageTitle={getName()}>
      <Grid container>
        <Grid item xs={12} alignItems={'center'} alignSelf={'center'}>
          <Grid container direction="row" justifyContent="space-between" spacing={2}>
            <Grid item xs={12} md={9}>
              <SearchInput name="search" placeholder={t('Suche')} onChange={handleSearch} />
            </Grid>
            <Grid item alignItems={'center'} xs={12} md={3}>
              <CTAButton
                rounded={false}
                customStyling={styles.fullWidthButton}
                icon={faPlus}
                buttonText={v('createNew')}
                link={r(ROUTES.landlordRoutes.apartment.create.new.path)}
              />
            </Grid>
          </Grid>
        </Grid>
        <Tabs tabs={APARTMENT_LIST_TABS} onChange={handleTabChange} value={listMode} id="apartment-list-tabs" />

        <Grid item xs={12}>
          {isFetching || isLoading ? (
            <LoadingAnimation />
          ) : filteredAndPaginatedApartments().length ? (
            <TableContainer component={Paper}>
              {selectedApartments?.length && user?.isAdmin ? (
                <div className={styles.selectionActiveBar}>
                  <Text color={TEXT_COLOR.TEXT_COLOR_LIGHT}>
                    {selectedApartments?.length} {`Objekt${selectedApartments.length > 1 ? 'e' : ''} ausgewählt`}
                  </Text>
                  <AdminTableHead
                    selectedApartments={selectedApartments}
                    onSettled={() => setSelectedApartments([])}
                  ></AdminTableHead>
                </div>
              ) : null}
              <Table cellPadding={'none'}>
                <ApartmentListTableHead />
                <ApartmentListTableBody
                  apartments={filteredAndPaginatedApartments()}
                  selectedApartments={selectedApartments}
                  setSelectedApartments={setSelectedApartments}
                />
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      labelDisplayedRows={({ from, to, count }) => {
                        return '' + from + '-' + to + ' von ' + count;
                      }}
                      labelRowsPerPage={'Reihen pro Seite'}
                      rowsPerPageOptions={[5, 10, 30, 50, 100]}
                      count={totalItems}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          ) : (
            'Keine Wohnungen in dieser Kategorie.'
          )}
        </Grid>
      </Grid>
    </PageLayout>
  );
};

export default ApartmentListView;
