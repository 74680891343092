import { MODAL_IDENTIFIER } from 'core/enum/modals.enum';
import ModalContext from 'core/context/modal.context';
import UserContext from 'core/context/user.context';
import { FC, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ITenantFormProps } from '../TenantProfileForm';
import PageTitle from 'component/molecules/PageTitle';
import { ROUTES } from 'core/const/routes';
import LightShow from 'component/molecules/LightShow';
import DocumentUploadListItem from 'component/atoms/DocumentUploadListItem';
import styles from './TenantIntroductionVideoForm.module.scss';
import CheckList from 'component/atoms/CheckList';
import { MEDIA_TYPE } from '@wohnsinn/ws-ts-lib/dist/types/media';
import { IMedia } from '@wohnsinn/ws-ts-lib';
import { faPlay } from '@fortawesome/pro-solid-svg-icons';
import CTAButton, { BUTTON_STYLE } from 'component/atoms/Buttons/CTAButton';
import Headline, { HEADLINE_SIZE } from 'component/atoms/typographie/Headline';
import { BADGE_COLORS } from 'component/atoms/Badge';
import InfoBox from 'component/atoms/InfoBox';
import { wohnsinnServices } from 'App';
import { Grid } from '@mui/material';
import SnackBarContext from 'core/context/snackbar.context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InfoText from 'component/molecules/InfoText';
import Text, { TEXT_COLOR } from 'component/atoms/typographie/Text';
import Spacer, { SPACER_SIZE } from 'component/atoms/Spacer';
import FormSectionTitle from 'component/atoms/FormSectionTitle';
import { useNavigate } from 'react-router-dom';
import IntroductionVideoUpload from 'component/organisms/IntroductionVideoUpload';

const TenantIntroductionVideoForm: FC<ITenantFormProps> = ({ isApplicationFolderTunnel }) => {
  const { tenantService } = wohnsinnServices;
  const { tenantProfile } = useContext(UserContext);
  const { openModal, closeModal } = useContext(ModalContext);
  const navigate = useNavigate();
  const [isLightShowOpen, setIsLightShowOpen] = useState<boolean>(false);
  const [showExampleVideo, setShowExampleVideo] = useState<boolean>(false);
  const { handleSnackBar } = useContext(SnackBarContext);

  const { t } = useTranslation('common', { keyPrefix: 'view.AccountView.IntroductionVideoView' });
  const { t: c } = useTranslation('common');
  const { t: r } = useTranslation('routes');

  const deleteVideoHandler = async (): Promise<void> => {
    await tenantService
      .updateTenantIntroductionVideo(tenantProfile.uid, null)
      .then(() => {
        closeModal();
      })
      .catch(async (e) => {
        console.error('error on deleteVideoHandler', e);
        handleSnackBar('toast.error.unknown', 'error');
      });
  };

  const EXAMPLE_VIDEO: IMedia = {
    creatorId: 'Wohnsinn',
    alt: 'Beispiel Vorstellungsvideo',
    id: 'xxxx1',
    url: 'https://firebasestorage.googleapis.com/v0/b/wohnsinn-prod.appspot.com/o/static%2Fvideos%2FBeispielvideo.mp4?alt=media&token=1b5dd053-4f22-43c3-9f85-854397c167fc&_gl=1*19p0cg*_ga*MTMzMjU1MTc5OS4xNjQ5MjMxODY1*_ga_CW55HF8NVT*MTY4NTY4OTMyNi40OC4xLjE2ODU2ODk1NDkuMC4wLjA.',
    mediaType: MEDIA_TYPE.VIDEO,
    updatedAt: new Date(),
  };

  const CANCEL_BUTTON = {
    text: tenantProfile?.aboutMe?.introductionVideo ? c('cancel') : c('skip'),
    action: () =>
      !isApplicationFolderTunnel
        ? navigate(r(ROUTES.tenantRoutes.account.overview.path))
        : tenantProfile?.aboutMe?.introductionVideo
        ? navigate(r(ROUTES.tenantRoutes.account.applicationFolder.household))
        : navigate(r(ROUTES.tenantRoutes.account.applicationFolder.aboutMe)),
  };

  return (
    <Grid container>
      <Grid item>
        <LightShow open={showExampleVideo} onClose={() => setShowExampleVideo(false)} media={[EXAMPLE_VIDEO]} />

        <div className={styles.pageTitleActionWrapper}>
          <PageTitle
            pageTitle={r(ROUTES.tenantRoutes.account.introductionVideo.title)}
            secondPageTitle={t('subTitle')}
            badgeText={t('recommended')}
            badgeColor={BADGE_COLORS.SUCCESS}
            notSubmitButton={CANCEL_BUTTON}
          />

          <div className={styles.videoUpload}>
            {!isApplicationFolderTunnel || (isApplicationFolderTunnel && !tenantProfile?.aboutMe?.introductionVideo) ? (
              <IntroductionVideoUpload />
            ) : (
              <CTAButton
                expandMobile
                link={r(ROUTES.tenantRoutes.account.applicationFolder.aboutMe)}
                buttonText={c('next')}
              />
            )}
          </div>
        </div>

        <Grid container spacing={{ xs: 0, md: 4 }}>
          <Grid item xs={12} md={7} lg={8}>
            {tenantProfile?.aboutMe?.introductionVideo ? (
              <>
                <FormSectionTitle title={t('upload.title')} isFirst />
                <LightShow
                  open={isLightShowOpen}
                  onClose={() => setIsLightShowOpen(false)}
                  media={[tenantProfile?.aboutMe?.introductionVideo]}
                />
                <DocumentUploadListItem
                  upload={tenantProfile?.aboutMe?.introductionVideo}
                  deleteHandler={() =>
                    openModal({
                      id: MODAL_IDENTIFIER.DELETE_MODAL,
                      data: {
                        deleteHandler: () => deleteVideoHandler(),
                        title: t('delete.title'),
                        text: t('delete.text'),
                      },
                    })
                  }
                  previewHandler={() => setIsLightShowOpen(true)}
                />
                <Spacer size={SPACER_SIZE.MD} />
              </>
            ) : null}
            <FormSectionTitle title={t('thumbnail.title')} />

            <button
              style={{ backgroundImage: `url('${t('thumbnail.img')}')` }}
              onClick={() => setShowExampleVideo(true)}
              className={styles.thumbnailButton}
            >
              <div className={styles.playButton}>
                <FontAwesomeIcon icon={faPlay} size={'lg'} />
              </div>
            </button>

            <div className={styles.mobilePlayButton}>
              <CTAButton
                expand={'block'}
                icon={faPlay}
                buttonText={t('thumbnail.title')}
                mobileNotRounded
                onClick={() => setShowExampleVideo(true)}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={5} lg={4}>
            <InfoText>
              <Headline size={HEADLINE_SIZE.H2}>Erhöhe deine Chancen</Headline>
              <Text color={TEXT_COLOR.TEXT_COLOR_ACCENT}>
                Ein Vorstellungsvideo erhöht signifikant die Erfolgschancen deiner Bewerbung, da es dem Vermieter die
                Möglichkeit bietet, einen persönlichen Eindruck von dir zu gewinnen und deine Eignung als Mieter besser
                zu beurteilen.
              </Text>
              <Spacer size={SPACER_SIZE.MD} />
              <CheckList
                items={[{ text: t('benefits.first') }, { text: t('benefits.second') }, { text: t('benefits.third') }]}
              />
              <Spacer size={SPACER_SIZE.MD} />
              <InfoBox text={t('privacy')} />
            </InfoText>
          </Grid>
        </Grid>

        <Grid item xs={12} className={styles.mediaUploadWrapper}>
          <CTAButton
            type={'button'}
            expandMobile
            onClick={CANCEL_BUTTON.action}
            buttonStyle={BUTTON_STYLE.SECONDARY}
            buttonText={CANCEL_BUTTON.text}
            mobileNotRounded
          />
          {!isApplicationFolderTunnel || (isApplicationFolderTunnel && !tenantProfile?.aboutMe?.introductionVideo) ? (
            <IntroductionVideoUpload />
          ) : (
            <CTAButton
              expandMobile
              link={r(ROUTES.tenantRoutes.account.applicationFolder.aboutMe)}
              buttonText={c('next')}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TenantIntroductionVideoForm;
