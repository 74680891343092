import { wohnsinnServices } from '../../App';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

const useLogout = () => {
  const { firebaseAuthService } = wohnsinnServices;
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const logout = async (): Promise<void> => {
    await firebaseAuthService.signOut(navigate);
    queryClient.clear();
  };
  return { logout };
};
export default useLogout;
