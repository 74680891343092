import Headline, { HEADLINE_SIZE } from '../../../atoms/typographie/Headline';
import Spacer, { SPACER_SIZE } from '../../../atoms/Spacer';
import { Grid } from '@mui/material';
import ContactCard from '../../../atoms/Card/ContactCard';
import { IMAGES } from 'core/const/images';
import { FC } from 'react';

const ContactSection: FC<{ headline?: string }> = ({ headline }) => {
  return (
    <>
      <Grid container justifyContent={'center'}>
        <Grid item xs={12} md={8}>
          <Headline size={HEADLINE_SIZE.DISPLAY} align={'center'}>
            {headline ?? 'Melde dich, wenn du mehr erfahren willst'}
          </Headline>
        </Grid>
      </Grid>
      <Spacer size={SPACER_SIZE.XL} />
      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          <ContactCard
            image={IMAGES.pictograms.bookAppointment}
            title={'Termin buchen'}
            link={'https://calendly.com/christian-philippi/kostenlose-demo-vereinbaren'}
            linkText={'Hier buchen'}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <ContactCard
            image={IMAGES.pictograms.emailUs}
            title={'E-Mail schreiben'}
            link={'mailto:felix.weiss@wohnsinn.com'}
            linkText={'felix.weiss@wohnsinn.com'}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <ContactCard
            image={IMAGES.pictograms.callUs}
            title={'Rufe uns an'}
            link={'tel:+4922195490428'}
            linkText={'+49 221 954 904 28'}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ContactSection;
