import { Grid } from '@mui/material';
import Headline, { HEADLINE_SIZE } from 'component/atoms/typographie/Headline';
import { Swiper, SwiperSlide } from 'swiper/react';
import MediaCard from 'component/atoms/Card/MediaCard';
import { IMAGES } from 'core/const/images';

const CustomerTestimonialsSection = () => {
  return (
    <>
      <Grid container justifyContent={'center'} className={'ws-full-width-bg ws-full-width-bg--white'}>
        <Grid item xs={12} md={8} paddingTop={{ xs: 4, md: 10 }} paddingBottom={{ xs: 4, md: 10 }}>
          <Headline size={HEADLINE_SIZE.DISPLAY} align={'center'}>
            Was unsere Kunden über Wohnsinn sagen
          </Headline>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        className={'ws-full-width-bg ws-full-width-bg--white'}
        paddingTop={{ xs: 4, md: 10 }}
        paddingBottom={{ xs: 4, md: 10 }}
      >
        <Swiper
          slidesPerView={3}
          breakpoints={{
            320: { slidesPerView: 1.3, centeredSlides: true },
            576: { slidesPerView: 1.7, centeredSlides: false },
            768: { slidesPerView: 2.3, centeredSlides: false },
            993: { slidesPerView: 3, centeredSlides: false },
          }}
          spaceBetween={16}
        >
          {testimonials.map((testimonial, index) => (
            <SwiperSlide key={`${testimonial?.name}-${index}`} style={{ padding: '3px', height: 'auto' }}>
              <MediaCard
                leftBottomInfo={testimonial.company}
                leftTopInfo={testimonial.name}
                image={testimonial.avatar}
                logo={testimonial.logo}
                description={testimonial?.quote}
                logoWidth={testimonial?.logoWidth}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </Grid>
    </>
  );
};

const testimonials = [
  {
    name: 'Julia Heidel',
    avatar: IMAGES.images.juliaHeidel,
    logo: IMAGES.logos.sps,
    quote:
      '“Die gewonnene Zeit ermöglicht es uns, unsere Kunden individueller zu betreuen. Das stärkt die Kundenbindung und unsere Positionierung als kundenorientierter Dienstleister.”',
    company: 'SPS',
    logoWidth: 150,
  },
  {
    name: 'Michael Reiserer',
    avatar: IMAGES.images.michaelReiserer,
    logo: IMAGES.logos.ndi,
    quote:
      '“Dank Wohnsinn finden wir schnell und einfach neue Mieter - eine absolute Bereicherung für unsere Hausverwaltung”',
    company: 'NDI GmbH',
    logoWidth: 70,
  },
  {
    name: 'Pascal Kurth',
    avatar: IMAGES.images.pascalKurth,
    logo: IMAGES.logos.ivvg,
    quote:
      '“Die digitale Selbstauskunft optimiert den Austausch mit den Mietsuchenden. Qualifizierte und vollständige Unterlagen sparen wertvolle Zeit bei dem Wohnungsvergabeprozess.”',
    company: 'Ivvg Immobilien',
    logoWidth: 100,
  },
];

export default CustomerTestimonialsSection;
