import { createTheme, ThemeOptions } from '@mui/material';
import { BREAKPOINTS } from '../core/const/breakpoints';

const WOHNSINN_COLOR_PALETTE = {
  text: {
    primary: '#012f40',
    secondary: '#667085',
  },
  primary: {
    light: '#E1F7FB',
    main: '#18CCDE',
    // dark: '#15b4c3',
    dark: '#0B9BA8',
  },
  secondary: {
    light: '#DE18CC',
    main: '#DE18CC',
    dark: '#DE18CC',
  },
  light: {
    shade: '#d7d8da',
  },
  error: {
    light: '#FCF2F3',
    main: '#FF4646',
    dark: '#F6090D',
  },
  warning: {
    light: '#FFF07D',
    main: '#FFEE6E',
    dark: '#E0D161',
  },
  success: {
    light: '#F5FFE0',
    main: '#A1DE18',
    dark: '#8FCE00',
  },
  info: {
    light: '#4fc3f7',
    main: '#29b6f6',
    dark: '#0288d1',
  },
};

export const theme: ThemeOptions = createTheme({
  breakpoints: {
    values: {
      xs: BREAKPOINTS.xs,
      sm: BREAKPOINTS.sm,
      md: BREAKPOINTS.md,
      lg: BREAKPOINTS.lg,
      xl: BREAKPOINTS.xl,
    },
  },
  components: {
    MuiSwitch: {
      styleOverrides: {
        root: {
          height: '32px',
          paddingTop: '9px',
          paddingBottom: '9px',
        },
        switchBase: {
          paddingTop: '6px',
          paddingBottom: '6px',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: WOHNSINN_COLOR_PALETTE.text.primary,
          paddingBottom: '10px',
          fontWeight: 'bold',
          whiteSpace: 'unset',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: WOHNSINN_COLOR_PALETTE.text.primary,
          paddingBottom: '10px',
          fontWeight: 'bold',
          whiteSpace: 'unset',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          notchedOutline: {
            borderColor: WOHNSINN_COLOR_PALETTE.light.shade,
            '&:hover': {
              outlineColor: WOHNSINN_COLOR_PALETTE.light.shade,
              borderColor: WOHNSINN_COLOR_PALETTE.light.shade,
            },
          },
          '&:hover': {
            outlineColor: WOHNSINN_COLOR_PALETTE.light.shade,
            borderColor: WOHNSINN_COLOR_PALETTE.light.shade,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: WOHNSINN_COLOR_PALETTE.light.shade,
          '&:hover': {
            outlineColor: WOHNSINN_COLOR_PALETTE.light.shade,
            borderColor: WOHNSINN_COLOR_PALETTE.light.shade,
          },
        },
        root: {
          borderRadius: '10px',
          outlineColor: WOHNSINN_COLOR_PALETTE.light.shade,
          background: '#ffffff',
          '&:hover': {
            outlineColor: WOHNSINN_COLOR_PALETTE.light.shade,
            borderColor: WOHNSINN_COLOR_PALETTE.light.shade,
          },
        },
      },
    },
    MuiSelect: {},
  },
  typography: {
    fontFamily: 'F37 Ginger, Arial',
  },
  palette: WOHNSINN_COLOR_PALETTE,
});
