import CallToActionScreen from 'component/molecules/CallToActionScreen';
import FB_FUNCTION_URLS from 'core/const/fb-function-names';
import InputOptionList, { RADIO_INPUT_COLUMNS_COUNT } from 'component/atoms/formElement/InputOptionList';
import Joi from 'joi';
import PageLayout from 'component/layouts/PageLayout';
import RegistrationFormWrapper from 'component/forms/signIn/RegistrationFormWrapper';
import SnackBarContext from 'core/context/snackbar.context';
import Spacer, { SPACER_SIZE } from 'component/atoms/Spacer';
import TextInput from 'component/atoms/formElement/InputText';
import {
  AUTH_PROVIDER,
  EMAIL_SCHEMA,
  ICheckIfUserExistsResponse,
  REGISTRATION_REFERRER,
  USER_TYPE,
} from '@wohnsinn/ws-ts-lib';
import { FormContext } from 'core/context/form.context';
import { Grid } from '@mui/material';
import { IMAGES } from 'core/const/images';
import { ROUTES } from 'core/const/routes';
import { SUBMIT_BUTTON_MODE } from 'core/enum/submit-button-mode.enum';
import { getTranslatedOptions, USER_TYPE_SELECT_OPTIONS } from 'core/const/select-options';
import { joiResolver } from '@hookform/resolvers/joi';
import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { wohnsinnServices } from 'App';
import TenantFilterParamsContext from '../../../../core/context/tenant-filter-params.context';

export const USER_CREDENTIALS_FORM_SCHEMA = Joi.object({
  email: EMAIL_SCHEMA.required(),
  createLandlordAccount: Joi.boolean().required(),
});

const CreateRegistrationView = () => {
  const [isCreatingAccount, setIsCreatingAccount] = useState<boolean>(false);
  const [buttonSubmitMode, setButtonSubmitMode] = useState<SUBMIT_BUTTON_MODE>(SUBMIT_BUTTON_MODE.NONE);
  const { handleSnackBar } = useContext(SnackBarContext);
  const isWogeEnvironment = process.env.REACT_APP_ENVIRONMENT === 'woge';
  const { tenantFilterParams } = useContext(TenantFilterParamsContext);

  const navigate = useNavigate();
  const { firebaseFunctionsService, registrationRequestService } = wohnsinnServices;
  const { t } = useTranslation('common');
  const { t: r } = useTranslation('routes');
  const { t: f } = useTranslation('common', { keyPrefix: 'view.general.CreateAccountView' });
  const { referrer } = useParams<{ referrer: REGISTRATION_REFERRER }>();

  const { control, handleSubmit, watch, formState } = useForm<{
    email: string;
    createLandlordAccount: boolean;
  }>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: joiResolver(USER_CREDENTIALS_FORM_SCHEMA),
    defaultValues: {
      email: undefined,
      createLandlordAccount: isWogeEnvironment ? false : referrer ? true : undefined,
    },
  });

  /**
   * Check if account with given email exists
   * If not send create a user registration request
   * If exists, output info message
   */
  const handleFormSubmit = async () => {
    setButtonSubmitMode(SUBMIT_BUTTON_MODE.SUBMITTING);
    const email = watch().email;
    const createLandlordProfile = watch().createLandlordAccount;

    try {
      // Check if account with given email exists
      const response: { data: ICheckIfUserExistsResponse } = (await firebaseFunctionsService.callFbFunction(
        FB_FUNCTION_URLS.user.checkIfUserExists,
        {
          params: { email },
        }
      )) as { data: ICheckIfUserExistsResponse };

      // if no account exists, create a user registration request
      if (!response.data.userExists) {
        setIsCreatingAccount(true);
        await registrationRequestService.createUserRegistrationRequest(
          email,
          createLandlordProfile ? USER_TYPE.LANDLORD : USER_TYPE.TENANT,
          referrer ?? REGISTRATION_REFERRER.NONE,
          tenantFilterParams
        );

        // show cool ui
        setTimeout(() => {
          setButtonSubmitMode(SUBMIT_BUTTON_MODE.NONE);
          navigate(r(ROUTES.staticRoutes.registration.activateAccount.path).replace(':email', email));
        }, 1500);
      } else {
        // if email already exists, check an account provider and output info message
        switch (response.data.provider) {
          case AUTH_PROVIDER.PASSWORD:
            handleSnackBar('toast.signIn.emailAccountExistsAlready', 'info');
            break;
          case AUTH_PROVIDER.GOOGLE:
            handleSnackBar('toast.signIn.googleAccountExistsAlready', 'info');
            break;
          default:
            handleSnackBar('toast.signIn.emailAccountExistsAlready', 'info');
        }
        setButtonSubmitMode(SUBMIT_BUTTON_MODE.NONE);
      }
    } catch (e) {
      console.error('e', e);
      handleSnackBar('toast.signIn.unknown', 'info');
      setButtonSubmitMode(SUBMIT_BUTTON_MODE.NONE);
    }
  };

  return (
    <PageLayout hideMenu showPageTitle={false} pageTitle={r(ROUTES.staticRoutes.registration.createRegistration.title)}>
      <Grid container maxWidth={isCreatingAccount ? null : 400} marginX={'auto'} paddingTop={{ xs: 6, md: 8 }}>
        <Grid item xs={12}>
          {isCreatingAccount ? (
            <CallToActionScreen text={f('creatingAccountMessage')} img={IMAGES.icons.heart} animateImg={true} />
          ) : (
            <FormContext.Provider value={{ control }}>
              <form onSubmit={handleSubmit(handleFormSubmit, console.error)}>
                <RegistrationFormWrapper
                  userTypeSelected={watch('createLandlordAccount') === undefined}
                  setButtonSubmitMode={setButtonSubmitMode}
                  createLandlordAccount={watch().createLandlordAccount}
                  buttonSubmitMode={buttonSubmitMode}
                  formIsValid={formState.isValid}
                >
                  {!isWogeEnvironment ? (
                    <>
                      <InputOptionList
                        centerLabel
                        mode={'radio'}
                        columns={RADIO_INPUT_COLUMNS_COUNT.TWO}
                        options={getTranslatedOptions(USER_TYPE_SELECT_OPTIONS, t)}
                        name={'createLandlordAccount'}
                        helperText={'Du kannst diese Auswahl später jederzeit ändern.'}
                      />
                      <Spacer size={SPACER_SIZE.XS} />
                    </>
                  ) : null}
                  <TextInput label={t('email.label')} name={'email'} type={'email'} required />
                </RegistrationFormWrapper>
              </form>
            </FormContext.Provider>
          )}
        </Grid>
      </Grid>
    </PageLayout>
  );
};

export default CreateRegistrationView;
