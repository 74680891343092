import CTAButton, { BUTTON_STYLE } from 'component/atoms/Buttons/CTAButton';
import ChangeUser from 'component/molecules/ChangeUser';
import MediaUploadDropZone from 'component/molecules/MediaUploadDropZone';
import PageTitle from 'component/molecules/PageTitle';
import SnackBarContext from 'core/context/snackbar.context';
import UserContext from 'core/context/user.context';
import styles from './CreateLandlordAddOrganizationLogo.module.scss';
import useOrganization from 'core/hook/organization.hook';
import { FIRESTORE_COLLECTION_PATH, REGISTRATION_STATES } from '@wohnsinn/ws-ts-lib';
import { FileRejection } from 'react-dropzone';
import { Grid } from '@mui/material';
import { faTrash } from '@fortawesome/pro-light-svg-icons';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { wohnsinnServices } from 'App';
import FormNavigation from 'component/molecules/FormNavigation';
import { SUBMIT_BUTTON_MODE } from 'core/enum/submit-button-mode.enum';

const CreateLandlordAddOrganizationLogo = () => {
  const [buttonSubmitMode, setButtonSubmitMode] = useState<SUBMIT_BUTTON_MODE>(SUBMIT_BUTTON_MODE.NONE);
  const { handleSnackBar } = useContext(SnackBarContext);
  const { organization } = useOrganization();
  const { firebaseStorageService, userService, organizationService } = wohnsinnServices;
  const { t: v } = useTranslation('common', { keyPrefix: 'view.OrganizationProfileView' });
  const { landlordProfile, tenantProfile } = useContext(UserContext);
  const email = landlordProfile ? landlordProfile?.email : tenantProfile?.email;

  const onDrop = async (acceptedFiles: File[], rejectedFiles: FileRejection[]): Promise<void> => {
    const path = `${FIRESTORE_COLLECTION_PATH.organizations.root}/${landlordProfile.organizationId}/${acceptedFiles[0].name}`;

    try {
      const downloadUrl = await firebaseStorageService.uploadBlob(
        acceptedFiles[0],
        path,
        landlordProfile.uid,
        acceptedFiles[0].type
      );

      await organizationService.updateOrganizationLogo(organization.id, downloadUrl);
    } catch (e) {
      console.error(e);
    }

    if (rejectedFiles?.length) {
      // SEND TOAST
      console.error('no accepted files');
    }
  };

  const deleteOrganizationLogo = async () => {
    try {
      await organizationService.updateOrganizationLogo(organization?.id, null);
      handleSnackBar(v('logo.deletedSuccessfully'), 'success');
    } catch (e) {
      console.error('Error on deleting organization logo', e);
    }
  };

  const handleNextStep = () => {
    setButtonSubmitMode(SUBMIT_BUTTON_MODE.SUBMITTING);
    try {
      setTimeout(async () => {
        setButtonSubmitMode(SUBMIT_BUTTON_MODE.NONE);
        await userService.updateUserData(
          { registrationState: REGISTRATION_STATES.ADD_ORGANIZATION_LEGAL_INFORMATION },
          landlordProfile.uid
        );
      }, 500);
    } catch (e) {
      console.error('Error handleNextStep', e);
    }
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <PageTitle
          pageTitle={'Lade dein Firmenlogo hoch'}
          secondPageTitle={'Präsentiere deine Firma von ihrer besten Seite'}
        />
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Grid container>
              <Grid item xs={12}>
                <div style={{ paddingBottom: 48 }}>
                  {organization?.photoUrl ? (
                    <>
                      <img
                        className={styles.organizationImage}
                        src={organization.photoUrl}
                        alt={`${organization?.title} Logo`}
                      />
                      <CTAButton
                        icon={faTrash}
                        buttonStyle={BUTTON_STYLE.SECONDARY}
                        rounded={false}
                        onClick={deleteOrganizationLogo}
                        buttonText={v('logo.delete')}
                      />
                    </>
                  ) : (
                    <MediaUploadDropZone multiple={false} onDrop={onDrop} />
                  )}

                  <ChangeUser email={email} />
                  <FormNavigation
                    disabled={!organization?.photoUrl}
                    buttonSubmitMode={buttonSubmitMode}
                    primaryAction={handleNextStep}
                    secondaryButtonAction={!organization?.photoUrl ? handleNextStep : null}
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CreateLandlordAddOrganizationLogo;
