import { useContext, useState } from 'react';
import { wohnsinnServices } from '../../../../../App';
import UserContext from '../../../../../core/context/user.context';
import { SUBMIT_BUTTON_MODE } from '../../../../../core/enum/submit-button-mode.enum';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Grid } from '@mui/material';
import PageTitle from '../../../../molecules/PageTitle';
import { FormContext } from '../../../../../core/context/form.context';
import { TOrganizationCreate } from '../../../../../core/service/organization.service';
import { ADDRESS_SCHEMA, REGISTRATION_STATES } from '@wohnsinn/ws-ts-lib';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import InputText from '../../../../atoms/formElement/InputText';
import RegistrationLayout from '../../RegistrationLayout';
import { TEXT_COLOR } from '../../../../atoms/typographie/Text';
import LOCAL_STORAGE_KEYS from '../../../../../core/enum/local-storage.enum';
import { ROUTES } from '../../../../../core/const/routes';

export const ORGANIZATION_PROFILE_SCHEME = {
  address: ADDRESS_SCHEMA,
  title: Joi.string().required().min(1),
};
const ADD_ORGANIZATION_PROFILE_FORM_ID = 'add-organization-profile';

const CreateLandlordAddOrganization = () => {
  const { organizationService, userService } = wohnsinnServices;
  const { landlordProfile } = useContext(UserContext);
  const [buttonSubmitMode, setButtonSubmitMode] = useState<SUBMIT_BUTTON_MODE>(SUBMIT_BUTTON_MODE.NONE);

  const { control, handleSubmit, formState, watch } = useForm<TOrganizationCreate>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: joiResolver(Joi.object(ORGANIZATION_PROFILE_SCHEME)),
  });

  const { t: r } = useTranslation('routes');
  const { t: c } = useTranslation('common');
  const { t } = useTranslation('common', {
    keyPrefix: 'component.molecules.RegisterLandlordSteps.StepAddOrganization',
  });

  const formValue = watch();

  const handleFormSubmit = async (): Promise<boolean | void> => {
    setButtonSubmitMode(SUBMIT_BUTTON_MODE.SUBMITTING);

    try {
      await organizationService.addOrganization(
        {
          ...formValue,
        } as TOrganizationCreate,
        landlordProfile.uid
      );

      setTimeout(async () => {
        await userService.updateUserData(
          { registrationState: REGISTRATION_STATES.ADD_ORGANIZATION_LOGO },
          landlordProfile.uid
        );
      }, 500);
    } catch (e) {
      console.error('Error on CreateLandlordAddOrganization', e);
    }
  };

  const cancelOrganizationCreation = () => {
    try {
      setTimeout(async () => {
        localStorage.setItem(LOCAL_STORAGE_KEYS.REDIRECT_URL, r(ROUTES.landlordRoutes.account.path));
        await userService.updateUserData({ registrationState: REGISTRATION_STATES.FINISHED }, landlordProfile.uid);
      }, 500);
    } catch (e) {
      console.error('Error on cancelOrganizationCreation: ', e);
    }
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <PageTitle pageTitle={t('formTitle')} secondPageTitle={t('formSubTitle')} />

        <RegistrationLayout
          buttonSubmitMode={buttonSubmitMode}
          isValidForm={formState.isValid}
          formId={ADD_ORGANIZATION_PROFILE_FORM_ID}
          secondaryButtonText={!landlordProfile?.isCommercialLandlord ? c('cancel') : null}
          secondaryButtonAction={!landlordProfile?.isCommercialLandlord ? () => cancelOrganizationCreation() : null}
          secondaryButtonColor={!landlordProfile?.isCommercialLandlord ? TEXT_COLOR.TEXT_COLOR_DANGER : null}
        >
          <FormContext.Provider value={{ control }}>
            <form id={ADD_ORGANIZATION_PROFILE_FORM_ID} onSubmit={handleSubmit(handleFormSubmit, console.error)}>
              <InputText label={c('organization.title.label')} name={'title'} required />
              <InputText label={c('address.street.label')} name={'address.street'} required />
              <InputText label={c('address.houseNumber.label')} name={'address.houseNumber'} required />
              <InputText type={'text'} label={c('address.postalCode.label')} name={'address.postalCode'} required />
              <InputText type={'text'} label={c('address.city.placeholder')} name={'address.city'} required />
            </form>
          </FormContext.Provider>
        </RegistrationLayout>
      </Grid>
    </Grid>
  );
};

export default CreateLandlordAddOrganization;
