import PageLayout from 'component/layouts/PageLayout';
import styles from './NotFoundView.module.scss';
import { useTranslation } from 'react-i18next';
import { ROUTES } from 'core/const/routes';
import { Link } from 'react-router-dom';
import Headline from 'component/atoms/typographie/Headline';
import Text, { TEXT_COLOR, TEXT_TYPE } from 'component/atoms/typographie/Text';

const NotFoundView = () => {
  const { t: r } = useTranslation('routes');

  return (
    <PageLayout showStaticMenu pageTitle={r(ROUTES.staticRoutes.notFound.title)} showPageTitle={false}>
      <div className={styles.wrapper}>
        <div className={styles.contentWrapper}>
          <Headline>{r(ROUTES.staticRoutes.notFound.title)}</Headline>
          <Text type={TEXT_TYPE.TEXT_TYPE_LANDING} align={'center'}>
            Bring mich nach{' '}
            <Link to="/">
              <Text tag={'span'} color={TEXT_COLOR.TEXT_COLOR_PRIMARY}>
                Hause
              </Text>
            </Link>
          </Text>
        </div>
        <img alt={'not found'} src={'/assets/images/redirect/throwing-balls.gif'} />
      </div>
    </PageLayout>
  );
};

export default NotFoundView;
