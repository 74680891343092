import {
  FirebaseStorage,
  uploadBytesResumable,
  UploadTask,
  StorageReference,
  ref,
  getDownloadURL,
  deleteObject,
  uploadBytes,
  UploadResult,
} from 'firebase/storage';
import { FILE_UPLOAD_STATUS, FIRESTORE_COLLECTION_PATH, IUpload, MEDIA_TYPE } from '@wohnsinn/ws-ts-lib';
import { UPLOAD_TYPE } from 'component/molecules/DocumentUploadDropZone';
import sanitizeFileName from 'core/helper/sanitize-file-name';
import UserService from './user.service';
import TenantService from './tenant.service';

export interface IUploadMetaInformations {
  uploadType: UPLOAD_TYPE;
  creatorId: string;
  mediaType: MEDIA_TYPE;
  id: string;
  subDirectoryPath?: any;
}

class FirebaseStorageService {
  constructor(
    public readonly storage: FirebaseStorage,
    public readonly userService: UserService,
    public readonly tenantService: TenantService
  ) {}

  public getRef(path: string) {
    return ref(this.storage, path);
  }

  private getUploadPathPrefix(metaInformation: IUploadMetaInformations): string {
    switch (metaInformation.uploadType) {
      case UPLOAD_TYPE.APARTMENT_MEDIA:
        return `${FIRESTORE_COLLECTION_PATH.users.landlordProfiles.apartments.root
          .replace('{uid}', metaInformation.creatorId)
          .replace('{landlordId}', metaInformation.creatorId)}/${metaInformation.subDirectoryPath}`;
      case UPLOAD_TYPE.APARTMENT_DOCUMENT:
        return `${FIRESTORE_COLLECTION_PATH.users.landlordProfiles.apartments.root
          .replace('{uid}', metaInformation.creatorId)
          .replace('{landlordId}', metaInformation.creatorId)}/${metaInformation.subDirectoryPath}/documents`;
      case UPLOAD_TYPE.INCOME_PROOF:
        return `${FIRESTORE_COLLECTION_PATH.users.tenantProfiles.root.replace('{uid}', metaInformation.creatorId)}/${
          metaInformation.creatorId
        }/incomeProofDocuments`;
      case UPLOAD_TYPE.SCHUFA:
        return `${FIRESTORE_COLLECTION_PATH.users.tenantProfiles.root.replace('{uid}', metaInformation.creatorId)}/${
          metaInformation.creatorId
        }/schufaCheckDocuments`;
      case UPLOAD_TYPE.INTRODUCTION_VIDEO:
        return `${FIRESTORE_COLLECTION_PATH.users.tenantProfiles.root.replace('{uid}', metaInformation.creatorId)}/${
          metaInformation.creatorId
        }/introductionVideo`;
    }
  }

  public createFileUpload(file: File, metaInformation: IUploadMetaInformations): Promise<IUpload> {
    const uploadPathPrefix = this.getUploadPathPrefix(metaInformation);
    const setSchufa = metaInformation.uploadType === UPLOAD_TYPE.SCHUFA;
    const setIncomeProof = metaInformation.uploadType === UPLOAD_TYPE.INCOME_PROOF;

    const extension: string = file.type.split('/')[1];
    const fileName: string = metaInformation.id;
    const uploadPath = `${uploadPathPrefix}/${metaInformation.id}.${extension}`;
    const storageRef: StorageReference = this.getRef(uploadPath);

    return new Promise(async (res, reject) => {
      const customMetadata = {
        creatorId: metaInformation.creatorId,
        mediaType: metaInformation.mediaType,
        id: metaInformation.id,
      };

      const uploadTask: UploadTask = uploadBytesResumable(storageRef, file, { customMetadata });
      const fileUpload: IUpload = {
        alt: sanitizeFileName(file.name, '_'),
        errors: [],
        extension,
        fileName: metaInformation.id,
        id: `${fileName}_${new Date().getTime()}`,
        url: null,
        uploadPath,
        uploadTask,
        updatedAt: new Date(),
        status: FILE_UPLOAD_STATUS.CREATED,
        ...customMetadata,
      };

      fileUpload.uploadTask.catch((err) => {
        reject(err);
      });

      if (setSchufa) {
        await this.tenantService.setTenantHasSchufaDocuments(metaInformation.creatorId, true);
      }

      if (setIncomeProof) {
        await this.tenantService.setTenantHasIncomeProofDocuments(metaInformation.creatorId, true);
      }

      return res(fileUpload);
    });
  }

  public async deleteFiles(filePaths: string[]): Promise<void> {
    try {
      await Promise.all(filePaths.map((path) => deleteObject(ref(this.storage, path))));
    } catch (message) {
      return console.error(message);
    }
  }

  public getDownloadUrl(path: string): Promise<string> {
    const fileRef = this.getRef(path);
    return getDownloadURL(fileRef);
  }

  public async uploadBlob(
    data: Blob | Uint8Array | ArrayBuffer | File,
    path: string,
    uid: string,
    fileType: string
  ): Promise<string> {
    const fileRef = ref(this.storage, path);

    const uploadResult: UploadResult = await uploadBytes(fileRef, data, {
      contentType: fileType,
      customMetadata: { creatorId: uid },
    });

    return getDownloadURL(uploadResult.ref);
  }
}

export default FirebaseStorageService;
