import styles from './ApplicantInformationModal.module.scss';
import { FC } from 'react';

import ApplicantInformationSection from '../ApplicantInformationSection';
import ApplicantInformationHeader from '../ApplicantInformationHeader';
import useApplication from '../../../../core/hook/application.hook';
import { ITenantProfile } from '@wohnsinn/ws-ts-lib';
import ApplicantInformationPrint from '../ApplicantInformationPrint';

const ApplicantInformationModal: FC<{
  apartmentId?: string;
  onClose: () => void;
  tenantProfile: ITenantProfile;
  landlordId?: string;
  showHeader?: boolean;
}> = ({ onClose, tenantProfile, landlordId, apartmentId, showHeader }) => {
  const { applicationList, loadingApplication } = useApplication(apartmentId, tenantProfile.uid, landlordId);

  if (landlordId && loadingApplication) {
    return <div>lädt application daten</div>;
  }

  return (
    <>
      <div className={styles.wrapper}>
        {showHeader ? (
          <ApplicantInformationHeader
            landlordRating={landlordId && applicationList ? applicationList[0].landlordRating : null}
            tenantProfile={tenantProfile}
            closeAction={onClose}
          />
        ) : null}

        <ApplicantInformationSection
          tenantProfile={tenantProfile}
          application={applicationList[0]}
          closeAction={onClose}
        />
      </div>

      <div className={`${styles.hideNonPrint}`}>
        <ApplicantInformationPrint tenantProfile={tenantProfile} />
      </div>
    </>
  );
};

export default ApplicantInformationModal;
