import styles from './WohnsinnExplainedSection.module.scss';
import { useState } from 'react';
import { faPlayPause, faFile, faBank, faCalendar, faMoneyBill, faUser } from '@fortawesome/pro-light-svg-icons';
import { useTranslation } from 'react-i18next';
import HeadlineTextBanner from '../../../molecules/HeadlineTextBanner';
import { HEADLINE_SIZE } from '../../../atoms/typographie/Headline';
import Accordion from '../../../atoms/Accordion';
import { Grid } from '@mui/material';

const WohnsinnExplainedSection = () => {
  const [selectedTab, setSelectedTab] = useState('accordion-0');
  const { t: l } = useTranslation('common', { keyPrefix: 'view.LandlordLanding.explanation' });

  return (
    <Grid container rowSpacing={8} columnSpacing={4} justifyContent={'center'} alignItems={'center'}>
      <Grid item xs={12}>
        <HeadlineTextBanner tag={l('tag')} headlineSize={HEADLINE_SIZE.H1} headline={l('headline')} text={l('text')} />
      </Grid>

      <Grid item xs={12} lg={5}>
        <Accordion isLanding selectedTab={selectedTab} onChange={setSelectedTab} items={CONTENTS} hideImagesOnDesktop />
      </Grid>

      <Grid item lg={7} sx={{ display: { xs: 'none', lg: 'block' } }}>
        <div className={styles.mockup}>
          <img
            className={selectedTab === 'accordion-0' || !selectedTab ? styles.showImage : styles.hideImage}
            src={'/assets/images/vorstellungsvideos.svg'}
            alt="vorstellungsvideos"
          />
          <img
            className={selectedTab === 'accordion-1' ? styles.showImage : styles.hideImage}
            src={'/assets/images/wunschmieter-matching.svg'}
            alt="wunschmieter-matching"
          />
          <img
            className={selectedTab === 'accordion-2' ? styles.showImage : styles.hideImage}
            src={'/assets/images/schnittstellen.svg'}
            alt="schnittstellen"
          />
          <img
            className={selectedTab === 'accordion-3' ? styles.showImage : styles.hideImage}
            src={'/assets/images/documentManagement.svg'}
            alt="dokumenten-management"
          />
          <img
            className={selectedTab === 'accordion-4' ? styles.showImage : styles.hideImage}
            src={'/assets/images/vorstellungsvideos.svg'}
            alt="vorstellungsvideos"
          />
          <img
            className={selectedTab === 'accordion-5' ? styles.showImage : styles.hideImage}
            src={'/assets/images/vorstellungsvideos.svg'}
            alt="vorstellungsvideos"
          />
        </div>
      </Grid>
    </Grid>
  );
};

const CONTENTS = [
  {
    icon: faPlayPause,
    label: 'view.LandlordLanding.explanation.introductionVideo.label',
    content: 'view.LandlordLanding.explanation.introductionVideo.content',
    image: '/assets/images/vorstellungsvideos.svg',
  },
  {
    icon: faMoneyBill,
    label: 'view.LandlordLanding.explanation.digitalIncomeProof.label',
    content: 'view.LandlordLanding.explanation.digitalIncomeProof.content',
    image: '/assets/images/documentManagement.svg',
  },
  {
    icon: faUser,
    label: 'view.LandlordLanding.explanation.desiredTenant.label',
    content: 'view.LandlordLanding.explanation.desiredTenant.content',
    image: '/assets/images/wunschmieter-matching.svg',
  },
  {
    icon: faBank,
    label: 'view.LandlordLanding.explanation.openBanking.label',
    content: 'view.LandlordLanding.explanation.openBanking.content',
    image: '/assets/images/schnittstellen.svg',
  },
  {
    icon: faFile,
    label: 'view.LandlordLanding.explanation.digitalContracts.label',
    content: 'view.LandlordLanding.explanation.digitalContracts.content',
    image: '/assets/images/documentManagement.svg',
  },
  {
    icon: faCalendar,
    label: 'view.LandlordLanding.explanation.automatedAppointments.label',
    content: 'view.LandlordLanding.explanation.automatedAppointments.content',
    image: '/assets/images/schnittstellen.svg',
  },
];

export default WohnsinnExplainedSection;
