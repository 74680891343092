import { ROUTES } from 'core/const/routes';
import { IImage, IMAGES } from 'core/const/images';

export interface MEGA_MENU_ITEM {
  title: string;
  subtitle?: string;
  link: string;
  children?: MEGA_MENU_ITEM[];
  icon?: IImage;
}

export const SOLUTIONS_MENU_ITEMS: MEGA_MENU_ITEM[] = [
  {
    title: ROUTES.landingPageRoutes.cooperative.title,
    link: ROUTES.landingPageRoutes.cooperative.path,
    subtitle: 'Wohnsinn als White-Label Lösung',
    icon: IMAGES.pictograms.building,
  },
];

export const MAIN_MENU_ITEMS: MEGA_MENU_ITEM[] = [
  { title: 'Für Anbieter', link: ROUTES.landingPageRoutes.landlord.path },
  { title: 'Für Genossenschaften', link: ROUTES.landingPageRoutes.cooperative.path },
  { title: 'Preise', link: ROUTES.landingPageRoutes.prices.path },
  { title: 'Über Wohnsinn', link: ROUTES.landingPageRoutes.about.path },
  // { title: 'Lösungen', link: '#', children: SOLUTIONS_MENU_ITEMS },
];
