import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import styles from './RoundIconButton.module.scss';

export interface IRoundIconButtonProps {
  onClick: () => any;
  buttonText: string;
  icon: IconProp;
  notification?: boolean;
}

const RoundIconButton: FC<IRoundIconButtonProps> = ({ onClick, buttonText, icon, notification = false }) => (
  <button title={buttonText} className={styles.actionBtn} onClick={onClick}>
    {notification ? (
      <div className={styles.notification}>
        <span className="sr-only">new message</span>
      </div>
    ) : null}
    <span className="sr-only">{buttonText}</span>
    <FontAwesomeIcon icon={icon} />
  </button>
);

export default RoundIconButton;
