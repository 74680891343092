import { ITenantSearch } from '@wohnsinn/ws-ts-lib';

/**
 * Create URL from ITenantSearch profile
 * @param searchProfile
 */
export const createUrlFromTenantSearch = (searchProfile: ITenantSearch): string => {
  const url = new URL(window.location.href);

  // Create Url from ITeanntSearch
  if (searchProfile.location) {
    url.searchParams.set('location', JSON.stringify(searchProfile.location));
  }

  if (searchProfile.equipmentList) {
    url.searchParams.set('equipmentList', JSON.stringify(searchProfile.equipmentList));
  }

  if (searchProfile.numberOfPeopleMovingIn) {
    url.searchParams.set('numberOfPeopleMovingIn', searchProfile.numberOfPeopleMovingIn.toString());
  }

  if (searchProfile.rooms) {
    url.searchParams.set('rooms', searchProfile.rooms.toString());
  }

  if (searchProfile.isSharedUsagePossible != null) {
    url.searchParams.set('isSharedUsagePossible', searchProfile.isSharedUsagePossible ? 'true' : 'false');
  }

  if (searchProfile.arePetsAllowed != null) {
    url.searchParams.set('arePetsAllowed', searchProfile.arePetsAllowed ? 'true' : 'false');
  }

  if (searchProfile.warmRent) {
    url.searchParams.set('warmRent', searchProfile.warmRent.toString());
  }
  if (searchProfile.size) {
    url.searchParams.set('size', searchProfile.size.toString());
  }

  url.searchParams.set('searchId', searchProfile.uid);

  return `/suche/${url.search}`;
};
