import FormHeader from 'component/atoms/FormHeader';
import FormSectionTitle from 'component/atoms/FormSectionTitle';
import { FormContext } from 'core/context/form.context';
import Dropdown from 'component/atoms/formElement/Dropdown';
import InputText from 'component/atoms/formElement/InputText';
import { useContext, useState } from 'react';
import UserContext from 'core/context/user.context';
import { useTranslation } from 'react-i18next';
import { OPEN_IMMO_SOFTWARES } from '@wohnsinn/ws-ts-lib';
import { useForm } from 'react-hook-form';
import CTAButton from 'component/atoms/Buttons/CTAButton';
import SplitLine from 'component/atoms/SplitLine';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-light-svg-icons';
import styles from './LandlordInterfacesForm.module.scss';
import { wohnsinnServices } from 'App';
import SnackBarContext from 'core/context/snackbar.context';
import { useMutation, useQuery } from '@tanstack/react-query';
import { OPENIMMO_SOFTWARE_OPTION_LIST } from 'core/const/open-immo-software';
import FTPCredentials from 'component/organisms/FTPCredentials';

const LandlordInterfacesForm = () => {
  const { landlordService, organizationService } = wohnsinnServices;
  const { landlordProfile } = useContext(UserContext);
  const { handleSnackBar } = useContext(SnackBarContext);
  const { t: o } = useTranslation('common', { keyPrefix: 'view.AccountView.OpenImmoView' });
  const [showCustomSoftware, setShowCustomSoftware] = useState<boolean>(false);
  const [selectedSoftware, setSelectedSoftware] = useState<OPEN_IMMO_SOFTWARES>(null);

  const fetchOrganization = async () => {
    return await organizationService.getOrganizationById(landlordProfile?.organizationId);
  };
  const { data: organization, refetch } = useQuery({
    queryKey: ['organization', landlordProfile?.organizationId],
    queryFn: fetchOrganization,
  });

  const { control, getValues } = useForm<{
    software: OPEN_IMMO_SOFTWARES;
    custom?: string;
  }>({
    mode: 'onChange',
    reValidateMode: 'onBlur',
  });

  const handleSoftware = (software: OPEN_IMMO_SOFTWARES): void => {
    setSelectedSoftware(software);
    switch (software) {
      case OPEN_IMMO_SOFTWARES.OTHER:
        setShowCustomSoftware(true);
        break;
      default:
        setShowCustomSoftware(false);
        break;
    }
  };

  const { mutate: requestFtpData, isPending: isCreatingFtpData } = useMutation({
    mutationFn: (values: { software: OPEN_IMMO_SOFTWARES; custom?: string }) =>
      landlordService.requestFTPData(landlordProfile.email, landlordProfile.uid, values),

    onSuccess: async () => {
      handleSnackBar('view.AccountView.OpenImmoView.toast.requestSuccessful', 'success');
      await refetch();
    },
    onError: (err) => {
      console.error('Error requesting FTP Data', err);
      handleSnackBar('view.AccountView.OpenImmoView.toast.requestFailed', 'error');
    },
  });

  return (
    <div>
      <FormHeader
        title={'OpenImmo Schnittstelle'}
        subtitle={'Lade deine Objekte einfach per Schnittstelle in unser Portal.'}
      />
      <SplitLine />
      {isCreatingFtpData ? (
        <div className={styles.spinner}>
          <FontAwesomeIcon spin={true} width={'23px'} height={'23px'} icon={faSpinner} size={'1x'} />{' '}
        </div>
      ) : null}

      {!organization?.FTPInformation?.isRequested && !organization?.FTPInformation?.isApproved && !isCreatingFtpData ? (
        <>
          <FormSectionTitle
            title={'Zugangsdaten beantragen'}
            subtitle={'Beantrage deine Zugangsdaten und übertrage Objekte einfach über deine Software.'}
          />
          <FormContext.Provider value={{ control }}>
            <form>
              <Dropdown
                label={o('accessDataSection.label')}
                name={'software'}
                optionList={OPENIMMO_SOFTWARE_OPTION_LIST}
                onChange={(e: any) => handleSoftware(e)}
                required
              />
              {showCustomSoftware && <InputText label={'Andere Software'} name={'custom'} />}
            </form>
            <CTAButton
              disabled={!selectedSoftware}
              buttonText={'Zugangsdaten erstellen'}
              onClick={() => requestFtpData(getValues())}
            />
          </FormContext.Provider>
        </>
      ) : null}

      {organization?.FTPInformation?.isRequested && organization?.FTPInformation?.isApproved ? (
        <FTPCredentials organization={organization} />
      ) : null}
    </div>
  );
};

export default LandlordInterfacesForm;
