import { Grid } from '@mui/material';
import Headline, { HEADLINE_SIZE } from 'component/atoms/typographie/Headline';
import Text, { TEXT_COLOR, TEXT_TYPE } from 'component/atoms/typographie/Text';
import { IMAGES } from 'core/const/images';
import TextBlockSection from 'component/molecules/TextBlockSection';
import CheckList from 'component/atoms/CheckList';
import Spacer, { SPACER_SIZE } from 'component/atoms/Spacer';
import { useTranslation } from 'react-i18next';

const MaxReachSection = () => {
  const { t: i } = useTranslation('images');
  return (
    <>
      <Grid container justifyContent={'center'}>
        <Grid item xs={12} md={8} paddingBottom={{ xs: 4, md: 10 }}>
          <Headline size={HEADLINE_SIZE.DISPLAY} align={'center'}>
            Erhalte ich genug Anfragen über Wohnsinn?
          </Headline>
        </Grid>
      </Grid>

      <Grid container alignItems={'center'} paddingTop={6} rowSpacing={4} columnSpacing={24}>
        <Grid item xs={12} sm={6} alignItems={'center'}>
          <TextBlockSection
            headlineSize={HEADLINE_SIZE.H1}
            title={'Maximale Reichweite - alles '}
            titleTwo={'an einem Ort bearbeiten.'}
          >
            <Spacer size={SPACER_SIZE.MD} />
            <Text tag={'span'} color={TEXT_COLOR.TEXT_COLOR_ACCENT} type={TEXT_TYPE.TEXT_TYPE_LANDING}>
              Mit der Veröffentlichung bei Wohnsinn wird dein Inserat automatisch auch auf anderen Portalen
              veröffentlicht, wodurch deine Reichweite maximiert wird. Alle Anfragen werden qualifiziert und in deinem
              Wohnsinn-Konto gesammelt.{' '}
            </Text>
          </TextBlockSection>

          <Spacer size={SPACER_SIZE.LG} />

          <CheckList accentColor items={MAX_REACH} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <img src={i(IMAGES.pros.landlord.maxReach.src)} alt={i(IMAGES.pros.landlord.maxReach.alt)} />
        </Grid>
      </Grid>
    </>
  );
};

const MAX_REACH = [
  {
    text: 'Hohe Reichweite deines Inserats',
    bold: false,
    invert: false,
  },
  {
    text: 'Vollständige Informationen aller Bewerber',
    bold: false,
    invert: false,
  },
  {
    text: 'Keine Zusatzkosten',
    bold: false,
    invert: false,
  },
];

export default MaxReachSection;
