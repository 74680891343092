import { createContext, Dispatch, SetStateAction } from 'react';
import { SEARCH_TUNNEL_STEPS } from '../../view/static/SearchTunnelView';

export const SearchTunnelStepContext = createContext<{
  currentSearchTunnelStep: SEARCH_TUNNEL_STEPS;
  setCurrentSearchTunnelStep: Dispatch<SetStateAction<SEARCH_TUNNEL_STEPS>>;
}>({
  currentSearchTunnelStep: null,
  setCurrentSearchTunnelStep: null,
});
