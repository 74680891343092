import { FC, useContext } from 'react';
import { Controller } from 'react-hook-form';
import { FormContext } from '../../../../core/context/form.context';
import styles from './Toggle.module.scss';
import Text, { TEXT_WEIGHT } from '../../typographie/Text';
import { InputLabel, Switch } from '@mui/material';

export interface ISwitchProps {
  name: string;
  topLabel?: string;
  formField?: boolean;
  label?: string;
  required?: boolean;
  disabled?: boolean;
  onFocus?: (event: any) => void;
}

const Toggle: FC<ISwitchProps> = ({ name, label, required = false, disabled = false, topLabel, formField = true }) => {
  const { control } = useContext(FormContext);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <div className={`${styles.wrapper} ${formField ? styles.formField : ''}`}>
          {topLabel && (
            <InputLabel color={'secondary'} required={required}>
              {topLabel}
            </InputLabel>
          )}

          <div className={styles.toggle}>
            <Text tag={'span'} weight={TEXT_WEIGHT.TEXT_WEIGHT_SEMI_BOLD}>
              {label} {label && required && <span> *</span>}
            </Text>
            <Switch
              disabled={disabled}
              value={field.value}
              onClick={() => field.onChange(!field.value)}
              checked={!!field.value}
            />
          </div>
        </div>
      )}
    />
  );
};

export default Toggle;
