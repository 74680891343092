import { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ROUTES } from '../../../core/const/routes';
import PageLayout from '../../../component/layouts/PageLayout';
import CTACard from '../../../component/molecules/Cards/CTACard';
import UserContext from '../../../core/context/user.context';
import CTAButton, { BUTTON_STYLE } from '../../../component/atoms/Buttons/CTAButton';
import { MODAL_IDENTIFIER } from '../../../core/enum/modals.enum';
import ModalContext from '../../../core/context/modal.context';
import ApplicationListContext from '../../../core/context/application-list.context';
import { wohnsinnServices } from '../../../App';
import { Grid } from '@mui/material';
import styles from './BookmarksView.module.scss';
import { IApplication, MATCHING_MODE } from '@wohnsinn/ws-ts-lib';
import BookmarkApartmentCard from '../../../component/molecules/Cards/BookmarkApartmentCard';
import Tabs, { TABS_TAB_PROPS } from '../../../component/atoms/Tabs';

const BookmarksView: FC = () => {
  const { t } = useTranslation('common');
  const { t: r } = useTranslation('routes');
  const { t: b } = useTranslation('common', { keyPrefix: 'view.BookmarksView' });
  const { user } = useContext(UserContext);
  const { openModal } = useContext(ModalContext);
  const { mixpanelTrackingService } = wohnsinnServices;
  const { nopeList, maybeList, isLoading } = useContext(ApplicationListContext);
  const [matchingMode, setMatchingMode] = useState<MATCHING_MODE>(MATCHING_MODE.MAYBE);
  const [applicationList, setApplicationList] = useState<IApplication[]>(maybeList);

  useEffect(() => {
    mixpanelTrackingService.trackEnterPage('Merkliste');

    if (maybeList && !maybeList.length) {
      if (nopeList && nopeList.length) {
        setApplicationList(nopeList);
        setMatchingMode(MATCHING_MODE.NOPE);
      }
    }
  }, []);

  useEffect(() => {
    if (matchingMode === MATCHING_MODE.MAYBE) {
      setApplicationList(maybeList);
    }
    if (matchingMode === MATCHING_MODE.NOPE) {
      setApplicationList(nopeList);
    }
  }, [matchingMode, nopeList, maybeList]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: MATCHING_MODE) => {
    switch (newValue) {
      case MATCHING_MODE.MAYBE:
        setMatchingMode(MATCHING_MODE.MAYBE);
        break;
      case MATCHING_MODE.NOPE:
        setMatchingMode(MATCHING_MODE.NOPE);
        break;
      default:
        setMatchingMode(MATCHING_MODE.MAYBE);
    }
  };

  const handleApartmentDrawerClose = (closeDrawer: () => void, newData?: any) => {
    closeDrawer();
  };

  if (!user) {
    return (
      <PageLayout
        pageTitle={'Logge dich ein um deine Merkliste zu sehen'}
        secondPageTitle={'Du kannst dich bewerben und deine Wunschlisten bearbeiten, sobald du eingeloggt bist.'}
      >
        <Grid container rowSpacing={1}>
          <Grid item xs={12}>
            <Grid item xs={12} md={4}>
              <CTAButton
                expand={'block'}
                onClick={() => openModal({ id: MODAL_IDENTIFIER.SIGN_IN })}
                buttonText={t('signIn')}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid item xs={12} md={4}>
              <CTAButton
                expand={'block'}
                buttonStyle={BUTTON_STYLE.SECONDARY}
                onClick={() =>
                  openModal({
                    id: MODAL_IDENTIFIER.SIGN_UP_USER,
                    data: { title: 'Erstelle ein Konto, um dich zu bewerben', isProbablyTenant: true },
                  })
                }
                buttonText={t('signUp')}
              />
            </Grid>
          </Grid>
        </Grid>
      </PageLayout>
    );
  }

  if (isLoading) {
    return <div>loading</div>;
  }

  if (!maybeList?.length && !nopeList?.length) {
    return (
      <PageLayout showPageTitle={false} pageTitle={r(ROUTES.tenantRoutes.bookmark.title)}>
        <Grid paddingTop={12} container justifyContent={'center'}>
          <Grid item xs={12} md={6}>
            <div className={styles.emptyBookmarks}>
              <CTACard
                title={b('noApartmentSaved.title')}
                text={b('noApartmentSaved.text')}
                imgSrc={t('pictogram.bookmark')}
                imgAltText={b('bookmarkIcon')}
              />
            </div>
          </Grid>
        </Grid>
      </PageLayout>
    );
  }

  const MATCHING_MODE_TABS: TABS_TAB_PROPS[] = [
    {
      value: MATCHING_MODE.MAYBE,
      label: t('iLike'),
      icon: t('icons.heart_like'),
      id: 'application-tab-maybe',
      iconAlt: t('iLike'),
    },
    {
      value: MATCHING_MODE.NOPE,
      label: t('iDislike'),
      icon: t('icons.heart_nope'),
      id: 'application-tab-nope',
      iconAlt: t('iDislike'),
    },
  ];

  return (
    <PageLayout showPageTitle={false} removeMobileVerticalPadding pageTitle={r(ROUTES.tenantRoutes.bookmark.title)}>
      <div className={styles.tabs}>
        <Tabs tabs={MATCHING_MODE_TABS} onChange={handleTabChange} value={matchingMode} id={'bookmark-tabs'} />
      </div>
      <div className={styles.apartments}>
        <Grid spacing={{ xs: 0, sm: 2 }} container>
          {!applicationList.length ? (
            <Grid container paddingTop={12} justifyContent={'center'}>
              <Grid item xs={12} md={6}>
                <div className={styles.emptyBookmarks}>
                  <CTACard
                    title={b(
                      matchingMode === MATCHING_MODE.MAYBE ? 'noApartmentLiked.title' : 'noApartmentDisliked.title'
                    )}
                    text={b(
                      matchingMode === MATCHING_MODE.MAYBE ? 'noApartmentLiked.text' : 'noApartmentDisliked.text'
                    )}
                    imgSrc={matchingMode === MATCHING_MODE.MAYBE ? t('icons.heart_like') : t('icons.heart_nope')}
                    imgAltText={b('bookmarkIcon')}
                    link={r(ROUTES.landingPageRoutes.tenant.path)}
                    ctaText={'Jetzt Wohnung finden'}
                  />
                </div>
              </Grid>
            </Grid>
          ) : (
            applicationList.map((application, index) => (
              <Grid key={`${application.apartmentId}-${index}`} item xs={12} sm={6} md={4}>
                <div className={styles.card} id={`apartment-card-${index}`}>
                  <BookmarkApartmentCard
                    handleApartmentDrawerClose={() => handleApartmentDrawerClose}
                    application={application}
                  />
                </div>
              </Grid>
            ))
          )}
        </Grid>
      </div>
    </PageLayout>
  );
};

export default BookmarksView;
