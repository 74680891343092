// services/useApartmentService.js
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getDocs } from 'firebase/firestore';
import { wohnsinnServices } from 'App';
import { APARTMENT_RENT_STATUS, IApartment } from '@wohnsinn/ws-ts-lib';
import { useContext } from 'react';
import UserContext from '../context/user.context';
import SnackBarContext from '../context/snackbar.context';
import { useTranslation } from 'react-i18next';
import FB_FUNCTION_URLS from 'core/const/fb-function-names';

export enum LIST_MODE {
  ALL = 'all',
  PUBLISHED = 'published',
  DEACTIVATED = 'deactivated',
  ACQUISITION = 'acquisition',
}

export enum ACTION_TYPE {
  PUBLISH = 'PUBLISH',
  DEPUBLISH = 'DEPUBLISH',
  DELETE = 'DELETE',
}

export function useFetchApartments() {
  const { apartmentService } = wohnsinnServices;
  const { user } = useContext(UserContext);

  return useQuery({
    queryKey: ['apartments'],
    queryFn: async () => {
      const allApartmentsListRef = apartmentService.getLandlordApartmentListRef({
        isAdmin: user.isAdmin,
        uid: user.uid,
      });

      const apartmentListSnap = await getDocs(allApartmentsListRef);
      return apartmentListSnap.docs.map((doc) => doc.data());
    },
    select: (data) => {
      const allApartmentsCount = data.length;
      const publishedApartments = data.filter((apartment) => apartment.isPublished);
      const publishedApartmentsCount = publishedApartments.length;
      const allApartmentList = data;
      const publishedApartmentList = data.filter((apartment) => apartment.isPublished);
      const deactivatedApartmentList = data.filter((apartment) => !apartment.isPublished);
      const acquisitionApartmentList = data.filter(
        (apartment) => apartment.rentStatus === APARTMENT_RENT_STATUS.ACQUISITION
      );

      return {
        allApartmentsCount,
        publishedApartmentsCount,
        allApartmentList,
        publishedApartmentList,
        deactivatedApartmentList,
        acquisitionApartmentList,
      };
    },
  });
}
export function useHandleApartmentActionMutation(
  selectedApartments: IApartment[],
  actionType: ACTION_TYPE,
  onSettled?: () => void
) {
  const queryClient = useQueryClient();
  const { apartmentService, firebaseFunctionsService } = wohnsinnServices;
  const { handleSnackBar } = useContext(SnackBarContext);
  const { t } = useTranslation('common');
  const { landlordProfile } = useContext(UserContext);

  return useMutation({
    mutationFn: async () => {
      switch (actionType) {
        case 'DELETE':
          await apartmentService.deleteApartmentList(selectedApartments);
          await firebaseFunctionsService.callFbFunction(FB_FUNCTION_URLS.apartments.deletedNotification, {
            body: {
              apartments: selectedApartments,
              landlordProfile,
            },
          });
          break;
        case 'DEPUBLISH':
          await apartmentService.updateApartmentsPublishedStates(selectedApartments, false);

          await firebaseFunctionsService.callFbFunction(FB_FUNCTION_URLS.apartments.publishedChangeNotification, {
            body: {
              apartments: selectedApartments,
              landlordProfile,
              published: false,
            },
          });
          break;
        case 'PUBLISH':
        default:
          await apartmentService.updateApartmentsPublishedStates(selectedApartments, true);
          await firebaseFunctionsService.callFbFunction(FB_FUNCTION_URLS.apartments.publishedChangeNotification, {
            body: {
              apartments: selectedApartments,
              landlordProfile,
              published: true,
            },
          });
      }
    },
    onSettled: async () => {
      handleSnackBar(t('toast.success.apartments.updated'), 'success');
      await queryClient.invalidateQueries({ queryKey: ['apartments'] });
      if (onSettled) onSettled();
    },
  });
}
