const formatDate = (inputDate: Date): string => {
  const now = new Date();
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const inputDateMidnight = new Date(inputDate.getFullYear(), inputDate.getMonth(), inputDate.getDate());
  const msPerDay = 24 * 60 * 60 * 1000;
  const daysDifference = (today.getTime() - inputDateMidnight.getTime()) / msPerDay;

  // If the date is today
  if (daysDifference < 1) {
    return `${inputDate.getHours()}:${inputDate.getMinutes().toString().padStart(2, '0')}`;
  }

  // If the date is within the last 7 days (not including today)
  if (daysDifference < 8) {
    return inputDate.toLocaleDateString('de-DE', { weekday: 'long' });
  }

  // If the date is more than 7 days old
  return `${inputDate.getDate().toString().padStart(2, '0')}.${(inputDate.getMonth() + 1)
    .toString()
    .padStart(2, '0')}.${inputDate.getFullYear().toString().substring(2, 4)}`;
};
export default formatDate;
