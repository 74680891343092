import { FC, useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import SnackBarContext from '../../../core/context/snackbar.context';
import { useTranslation } from 'react-i18next';

export interface IRouteRestriction {
  shouldRedirect: boolean;
  redirectPath: string;
  errorMessage?: string;
  name: string;
}

/**
 * Parses the route restrictions and returns the first route restriction that requires a redirect, or a default route restriction if none require redirect.
 *
 * @param {IRouteRestriction[]} routeRestrictions - The array of route restrictions to parse.
 * @returns {IRouteRestriction} - The first route restriction that requires a redirect, or a default route restriction if none require redirect.
 */
const parseRouteRestrictions = (routeRestrictions?: IRouteRestriction[]) => {
  let restriction: IRouteRestriction = {
    shouldRedirect: false,
    errorMessage: '',
    redirectPath: '/',
    name: 'default',
  };

  if (routeRestrictions?.length) {
    routeRestrictions.every((routRestriction) => {
      if (routRestriction.shouldRedirect) {
        console.log('routRestriction: ', routRestriction.name);
        restriction = routRestriction;
      }
      // iterates to next item only if the current one doesn't require redirect
      return !restriction.shouldRedirect;
    });
  }

  return restriction;
};

const ProtectedRoute: FC<{ routeRestrictions?: IRouteRestriction[] }> = ({ routeRestrictions }) => {
  const { handleSnackBar } = useContext(SnackBarContext);
  const { t: r } = useTranslation('routes');
  const { shouldRedirect, redirectPath, errorMessage } = parseRouteRestrictions(routeRestrictions);

  if (errorMessage) {
    handleSnackBar(errorMessage, 'info');
  }
  if (shouldRedirect) {
    return <Navigate to={r(redirectPath)} replace />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
