import { Grid } from '@mui/material';
import CTAButton, { BUTTON_STYLE } from '../../../atoms/Buttons/CTAButton';
import { useTranslation } from 'react-i18next';
import Spacer, { SPACER_SIZE } from '../../../atoms/Spacer';
import Headline, { HEADLINE_COLOR } from '../../../atoms/typographie/Headline';
import Text, { TEXT_COLOR, TEXT_TYPE } from '../../../atoms/typographie/Text';
import { FC } from 'react';

const LandlordSection: FC<{
  title: string;
  subtitle: string;
  ctaText: string;
  ctaLink?: string;
  ctaHref?: string;
  ctaLink2?: string;
  ctaHref2?: string;
  ctaText2?: string;
}> = ({ title, subtitle, ctaText, ctaLink, ctaHref, ctaText2, ctaLink2, ctaHref2 }) => {
  const { t: r } = useTranslation('routes');
  return (
    <Grid container rowSpacing={2} columnSpacing={4} justifyContent={'center'}>
      <Grid item xs={12}>
        <Headline align={'center'} color={HEADLINE_COLOR.HEADLINE_COLOR_LIGHT}>
          {title}
        </Headline>
        <Spacer size={SPACER_SIZE.SM} />
        <Text align={'center'} color={TEXT_COLOR.TEXT_COLOR_LIGHT} type={TEXT_TYPE.TEXT_TYPE_LANDING}>
          {subtitle}
        </Text>
        <Spacer size={SPACER_SIZE.XS} />
      </Grid>

      <Grid item xs={12} sm={6} lg={3}>
        <Spacer size={SPACER_SIZE.XS} />
        <div style={{ display: 'flex', gap: 16, justifyContent: 'center' }}>
          <CTAButton rounded={false} buttonText={ctaText} link={ctaLink ?? undefined} href={ctaHref ?? undefined} />
          <CTAButton
            buttonStyle={BUTTON_STYLE.SECONDARY}
            rounded={false}
            buttonText={ctaText2}
            link={ctaLink2 ?? undefined}
            href={ctaHref2 ?? undefined}
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default LandlordSection;
