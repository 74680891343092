import {
  APARTMENT_CONDITION,
  ENERGY_PASS_STATUS,
  ENERGY_PASS_TYPE,
  HEATING_SYSTEM,
  HEATING_TYPE,
  ISelectOption,
} from '@wohnsinn/ws-ts-lib';

export const APARTMENT_CONDITION_SELECT_OPTION_LIST: ISelectOption<APARTMENT_CONDITION>[] = [
  { value: APARTMENT_CONDITION.NONE, label: 'Keine Angabe' },
  { value: APARTMENT_CONDITION.REFURBISHED, label: 'Saniert' },
  { value: APARTMENT_CONDITION.RENOVATED, label: 'Renoviert' },
  { value: APARTMENT_CONDITION.MODERNISED, label: 'Modernisiert' },
  { value: APARTMENT_CONDITION.FIRST_TIME_USE, label: 'Erstbezug' },
  { value: APARTMENT_CONDITION.PARTIAL_FULL_RENOVATION_NEEDS, label: 'Teil/Voll-Renovierungsbedürftig' },
  { value: APARTMENT_CONDITION.MINT_CONDITION, label: 'Neuwertig' },
  { value: APARTMENT_CONDITION.PARTIALLY_FULLY_SANITIZED, label: 'Teil/Voll-Saniert' },
  { value: APARTMENT_CONDITION.PARTIALLY_FULLY_RENOVATED, label: 'Teil/Voll-Renoviert' },
  { value: APARTMENT_CONDITION.FULLY_SANITIZED, label: 'Voll saniert' },
  { value: APARTMENT_CONDITION.FULLY_RENOVATED, label: 'Voll renoviert' },
  { value: APARTMENT_CONDITION.SANITATION_NEEDED, label: 'Sanierungsbedürftig' },
  { value: APARTMENT_CONDITION.CONSTRUCTION_DOWN, label: 'Baufällig' },
  { value: APARTMENT_CONDITION.GROOMED, label: 'Gepflegt' },
  { value: APARTMENT_CONDITION.SHELL, label: 'Rohbau' },
  { value: APARTMENT_CONDITION.SEEDED, label: 'Entkernt' },
  { value: APARTMENT_CONDITION.DEMOLITION_PROJECT, label: 'Abrissobjekt' },
  { value: APARTMENT_CONDITION.PROJECTED, label: 'Projektiert' },
];

export const APARTMENT_HEATING_TYPE_SELECT_OPTION_LIST: ISelectOption<HEATING_TYPE>[] = [
  { value: HEATING_TYPE.OEL, label: 'Öl' },
  { value: HEATING_TYPE.GAS, label: 'Gas' },
  { value: HEATING_TYPE.ELECTRO, label: 'Elektro' },
  { value: HEATING_TYPE.ALTERNATIVE, label: 'Alternativ' },
  { value: HEATING_TYPE.SOLAR, label: 'Solar' },
  { value: HEATING_TYPE.GEOTHERMAL_ENERGY, label: 'Erdwärme' },
  { value: HEATING_TYPE.AIR_SOURCE_HEAT_PUMP, label: 'Wärmepumpe' },
  { value: HEATING_TYPE.DISTRICT_HEATING, label: 'Fernwärme' },
  { value: HEATING_TYPE.BLOCK_HEAT, label: 'Block' },
  { value: HEATING_TYPE.WATER_ELECTRO, label: 'Wasser-Elektro' },
  { value: HEATING_TYPE.PELLET, label: 'Pellet' },
  { value: HEATING_TYPE.COAL, label: 'Kohle' },
  { value: HEATING_TYPE.WOODEN, label: 'Holz' },
  { value: HEATING_TYPE.LIQUID_GAS, label: 'Flüssiggas' },
];

export const APARTMENT_HEATING_SYSTEM_SELECT_OPTION_LIST: ISelectOption<HEATING_SYSTEM>[] = [
  { value: HEATING_SYSTEM.CENTRAL, label: 'Zentralheizung' },
  { value: HEATING_SYSTEM.DISTRICT, label: 'Fernwärme' },
  { value: HEATING_SYSTEM.FLOOR, label: 'Fußbodenheizung' },
  { value: HEATING_SYSTEM.LEVEL, label: 'Etagenheizung' },
  { value: HEATING_SYSTEM.OVEN, label: 'Ofen' },
];

export const BOOLEAN_SELECT_OPTION_ENERGY_PASS_AGE_LIST: ISelectOption<boolean>[] = [
  { label: 'Bis 30.04.2014', value: true },
  { label: 'Nach 01.05.2014', value: false },
];

export const BOOLEAN_SELECT_OPTION_ENERGY_PASS_AVAILABLE_LIST: ISelectOption<boolean>[] = [
  { label: 'Vorhanden', value: true },
  { label: 'Nicht notwendig', value: false },
];

export const ENERGY_PASS_TYPE_SELECT_OPTION_LIST: ISelectOption<ENERGY_PASS_TYPE>[] = [
  { label: 'Verbrauchsausweis', value: ENERGY_PASS_TYPE.CONSUMPTION_PASS },
  { label: 'Bedarfsausweis', value: ENERGY_PASS_TYPE.USAGE_PASS },
];

export const ENERGY_PASS_STATUS_LIST: ISelectOption<string>[] = [
  { label: 'Erstellt bis 30.04.2014', value: ENERGY_PASS_STATUS.BEFORE_ENERGY_REGULATIONS },
  { label: 'Erstellt nach 01.05.2014', value: ENERGY_PASS_STATUS.AFTER_ENERGY_REGULATIONS },
  { label: 'Nicht notwendig', value: ENERGY_PASS_STATUS.NOT_NECESSARY },
  { label: 'Nicht vorhanden', value: ENERGY_PASS_STATUS.WITHOUT },
  { label: 'Liegt zur Besichtigung vor', value: ENERGY_PASS_STATUS.AVAILABLE_AT_VIEWING },
];
