import { useContext, useState } from 'react';
import { SUBMIT_BUTTON_MODE } from '../../../../core/enum/submit-button-mode.enum';
import TenantFilterParamsContext from '../../../../core/context/tenant-filter-params.context';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import InputOptionList from '../../../atoms/formElement/InputOptionList';
import { getTranslatedOptions } from '../../../../core/const/select-options';
import { EQUIPMENT_LIST_SELECT_OPTIONS } from '../../FilterMenu';
import { useTranslation } from 'react-i18next';
import { SearchTunnelStepContext } from '../../../../core/context/search-tunnel-step.context';
import { SEARCH_TUNNEL_STEPS } from '../../../../view/static/SearchTunnelView';
import SearchTunnelStepLayout from '../SearchTunnelStepLayout';

const SELECT_MAX_RENT_FORM_ID = 'SELECT_MAX_RENT_FORM_ID';
const SelectEquipmentStep = () => {
  const { t } = useTranslation('common');
  const { tenantFilterParams, updateTenantFilterParams } = useContext(TenantFilterParamsContext);
  const { setCurrentSearchTunnelStep } = useContext(SearchTunnelStepContext);
  const [buttonSubmitMode, setButtonSubmitMode] = useState<SUBMIT_BUTTON_MODE>(SUBMIT_BUTTON_MODE.NONE);
  const { t: s } = useTranslation('common', { keyPrefix: 'component.organisms.searchTunnel.selectEquipmentStep' });

  const { control, handleSubmit, watch, formState } = useForm<{
    equipmentList: number;
  }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: joiResolver(Joi.object({ equipmentList: Joi.any() })),
  });
  const equipmentList = watch('equipmentList');

  const handleFormSubmit = () => {
    setButtonSubmitMode(SUBMIT_BUTTON_MODE.SUBMITTING);
    updateTenantFilterParams({ ...tenantFilterParams, equipmentList });
    setCurrentSearchTunnelStep(SEARCH_TUNNEL_STEPS.FINISH_LOADING_SCREEN);
  };

  return (
    <SearchTunnelStepLayout
      formId={SELECT_MAX_RENT_FORM_ID}
      control={control}
      disabled={buttonSubmitMode === SUBMIT_BUTTON_MODE.SUBMITTING || !formState.isValid}
      buttonSubmitMode={buttonSubmitMode}
      onSubmit={handleSubmit(handleFormSubmit, console.error)}
      title={s('title')}
      backAction={() => {
        const newTfp = tenantFilterParams;
        delete newTfp.location;
        updateTenantFilterParams({ ...newTfp });
        setCurrentSearchTunnelStep(SEARCH_TUNNEL_STEPS.SELECT_APARTMENT_SIZE);
      }}
    >
      <InputOptionList
        mode={'checkbox'}
        name={'equipmentList'}
        options={getTranslatedOptions(EQUIPMENT_LIST_SELECT_OPTIONS, t)}
      />
    </SearchTunnelStepLayout>
  );
};

export default SelectEquipmentStep;
