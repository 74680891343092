import { FormContext } from 'core/context/form.context';
import Dropdown from 'component/atoms/formElement/Dropdown';
import InputText from 'component/atoms/formElement/InputText';
import { useForm } from 'react-hook-form';
import { OPENIMMO_SOFTWARE_OPTION_LIST } from 'core/const/open-immo-software';
import { OPEN_IMMO_SOFTWARES, REGISTRATION_STATES } from '@wohnsinn/ws-ts-lib';
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { wohnsinnServices } from 'App';
import UserContext from 'core/context/user.context';
import SnackBarContext from 'core/context/snackbar.context';
import styles from 'component/forms/landlord/LandlordInterfacesForm/LandlordInterfacesForm.module.scss';
import { Grid } from '@mui/material';
import PageTitle from 'component/molecules/PageTitle';
import FTPCredentials from '../../../FTPCredentials';
import useOrganization from 'core/hook/organization.hook';
import ChangeUser from 'component/molecules/ChangeUser';
import BouncingHeart from 'component/atoms/BouncingHeart';
import { IMAGES } from 'core/const/images';
import FormNavigation from 'component/molecules/FormNavigation';
import { SUBMIT_BUTTON_MODE } from 'core/enum/submit-button-mode.enum';

const ADD_CRM_FORM_ID = 'add-crm-form';
const CreateLandlordAddOrganizationCRM = () => {
  const [buttonSubmitMode, setButtonSubmitMode] = useState<SUBMIT_BUTTON_MODE>(SUBMIT_BUTTON_MODE.NONE);
  const [showCustomSoftware, setShowCustomSoftware] = useState<boolean>(false);
  const [selectedSoftware, setSelectedSoftware] = useState<OPEN_IMMO_SOFTWARES>(null);
  const { landlordService, userService } = wohnsinnServices;
  const { organization } = useOrganization();
  const { landlordProfile, tenantProfile } = useContext(UserContext);
  const email = landlordProfile ? landlordProfile?.email : tenantProfile?.email;
  const { handleSnackBar } = useContext(SnackBarContext);
  const { t } = useTranslation('common');
  const [isCreatingFtpData, setIsCreatingFtpData] = useState(false);
  const { t: o } = useTranslation('common', { keyPrefix: 'view.AccountView.OpenImmoView' });
  const { control, getValues } = useForm<{
    software: OPEN_IMMO_SOFTWARES;
    custom?: string;
  }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const handleSoftware = (software: OPEN_IMMO_SOFTWARES): void => {
    setSelectedSoftware(software);
    switch (software) {
      case OPEN_IMMO_SOFTWARES.OTHER:
        setShowCustomSoftware(true);
        break;
      default:
        setShowCustomSoftware(false);
        break;
    }
  };

  const { mutate: requestFtpData, isPending } = useMutation({
    mutationFn: (values: { software: OPEN_IMMO_SOFTWARES; custom?: string }) => {
      setIsCreatingFtpData(true);
      return landlordService.requestFTPData(landlordProfile.email, landlordProfile.uid, values);
    },
    onSuccess: async () => {
      setTimeout(() => {
        setIsCreatingFtpData(false);
      }, 1000);
      handleSnackBar('view.AccountView.OpenImmoView.toast.requestSuccessful', 'success');
    },
    onError: (err) => {
      setTimeout(() => {
        setIsCreatingFtpData(false);
      }, 1000);
      console.error('Error requesting FTP Data', err);
      handleSnackBar('view.AccountView.OpenImmoView.toast.requestFailed', 'error');
    },
  });

  const handleNextStep = () => {
    setButtonSubmitMode(SUBMIT_BUTTON_MODE.SUBMITTING);
    try {
      setTimeout(async () => {
        await userService.updateUserData(
          { registrationState: REGISTRATION_STATES.INVITE_ORGANIZATION_MEMBER },
          landlordProfile.uid
        );

        setButtonSubmitMode(SUBMIT_BUTTON_MODE.SUCCESS);
      }, 500);
    } catch (e) {
      setButtonSubmitMode(SUBMIT_BUTTON_MODE.ERROR);
      console.error('Error on handleNextStep', e);
    }
  };

  const hasCredentials = organization?.FTPInformation?.isRequested && organization?.FTPInformation?.isApproved;

  const handleFtpRequest = () => {
    setButtonSubmitMode(SUBMIT_BUTTON_MODE.SUBMITTING);
    requestFtpData(getValues());
    setButtonSubmitMode(SUBMIT_BUTTON_MODE.NONE);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <PageTitle
          pageTitle={hasCredentials ? 'Trage nun folgende Daten in deine Software' : 'Welche Maklersoftware nutzt du?'}
          secondPageTitle={
            hasCredentials
              ? 'Diese Daten findest du auch jederzeit in deinem Konto'
              : 'Wähle das in Deinem Unternehmen eingesetzte Maklersystem aus'
          }
        />
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Grid container>
              <Grid item xs={12}>
                <div style={{ paddingBottom: 48 }}>
                  {hasCredentials ? (
                    <FTPCredentials organization={organization} />
                  ) : (
                    <FormContext.Provider value={{ control }}>
                      {isCreatingFtpData || isPending ? (
                        <div className={styles.animationWrapper}>
                          <BouncingHeart img={IMAGES.icons.heart} animate={true} />
                        </div>
                      ) : (
                        <form id={ADD_CRM_FORM_ID}>
                          <Dropdown
                            label={o('accessDataSection.label')}
                            name={'software'}
                            optionList={OPENIMMO_SOFTWARE_OPTION_LIST}
                            onChange={(e: any) => handleSoftware(e)}
                            required
                          />
                          {showCustomSoftware && <InputText label={'Andere Software'} name={'custom'} />}
                        </form>
                      )}
                    </FormContext.Provider>
                  )}

                  <ChangeUser email={email} />

                  <FormNavigation
                    primaryAction={() => (hasCredentials ? handleNextStep() : handleFtpRequest())}
                    submitButtonText={hasCredentials ? null : t('createCredentials')}
                    disabled={hasCredentials ? false : !selectedSoftware}
                    buttonSubmitMode={buttonSubmitMode}
                    secondaryButtonAction={!hasCredentials ? handleNextStep : null}
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CreateLandlordAddOrganizationCRM;
