import ChangeUser from '../../../molecules/ChangeUser';
import FormNavigation from 'component/molecules/FormNavigation';
import UserContext from '../../../../core/context/user.context';
import styles from './RegistrationLayout.module.scss';
import { FC, PropsWithChildren, useContext } from 'react';
import { Grid } from '@mui/material';
import { SUBMIT_BUTTON_MODE } from 'core/enum/submit-button-mode.enum';
import { TEXT_COLOR } from '../../../atoms/typographie/Text';

const RegistrationLayout: FC<
  PropsWithChildren<{
    formId: string;
    isValidForm: boolean;
    buttonSubmitMode: SUBMIT_BUTTON_MODE;
    submitButtonText?: string;
    secondaryButtonAction?: any;
    secondaryButtonText?: string;
    secondaryButtonColor?: TEXT_COLOR;
    emailProp?: string;
  }>
> = ({
  children,
  formId,
  isValidForm,
  buttonSubmitMode,
  submitButtonText,
  secondaryButtonAction,
  secondaryButtonText,
  secondaryButtonColor,
  emailProp,
}) => {
  const { landlordProfile, tenantProfile } = useContext(UserContext);
  const email = landlordProfile ? landlordProfile?.email : tenantProfile?.email;
  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={6}>
        <Grid container>
          <Grid item xs={12}>
            <div className={styles.wrapper}>
              {children}
              <ChangeUser email={emailProp ?? email} />
              <FormNavigation
                buttonSubmitMode={buttonSubmitMode}
                disabled={buttonSubmitMode === SUBMIT_BUTTON_MODE.SUBMITTING || !isValidForm}
                formId={formId}
                submitButtonText={submitButtonText}
                secondaryButtonAction={secondaryButtonAction}
                secondaryButtonColor={secondaryButtonColor ?? TEXT_COLOR.TEXT_COLOR_ACCENT}
                secondaryButtonText={secondaryButtonText}
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RegistrationLayout;
