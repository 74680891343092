import CTAButton, { getSubmitIcon } from 'component/atoms/Buttons/CTAButton';
import CTACard from 'component/molecules/Cards/CTACard';
import ChangeUser from 'component/molecules/ChangeUser';
import FourDigitsInput from 'component/atoms/formElement/FourDigitsInput';
import Joi from 'joi';
import PageLayout from 'component/layouts/PageLayout';
import SnackBarContext from 'core/context/snackbar.context';
import Spacer, { SPACER_SIZE } from 'component/atoms/Spacer';
import Text, { TEXT_COLOR } from 'component/atoms/typographie/Text';
import { FormContext } from 'core/context/form.context';
import { Grid } from '@mui/material';
import { IRegistrationRequest } from '@wohnsinn/ws-ts-lib';
import { ROUTES } from 'core/const/routes';
import { SUBMIT_BUTTON_MODE } from 'core/enum/submit-button-mode.enum';
import { joiResolver } from '@hookform/resolvers/joi/dist/joi';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { wohnsinnServices } from 'App';

const ACTIVATION_CODE_SCHEMA = Joi.string()
  .min(4)
  .max(4)
  .regex(/\d\d\d\d/)
  .required();

const ActivateAccountView = () => {
  const { firebaseAuthService, registrationRequestService } = wohnsinnServices;
  const [buttonSubmitMode, setButtonSubmitMode] = useState<SUBMIT_BUTTON_MODE>(SUBMIT_BUTTON_MODE.NONE);
  const { t } = useTranslation('common', { keyPrefix: 'view.ActivateAccountView' });
  const { t: r } = useTranslation('routes');
  const { handleSnackBar } = useContext(SnackBarContext);
  const { email } = useParams<{ email: string }>();
  const [coolDown, setCoolDown] = useState<number>(0);
  const { control, handleSubmit, watch, formState } = useForm<{
    activationCode: string;
  }>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    resolver: joiResolver(Joi.object({ activationCode: ACTIVATION_CODE_SCHEMA })),
  });

  const resendMail = async (email: string) => {
    setCoolDown(30);
    handleSnackBar('Email wurde verschickt', 'success');
    await registrationRequestService.sendRegistrationRequestMail(email);
  };

  const handleVerification = async (email: string, code: number) => {
    const registrationRequest: IRegistrationRequest = await registrationRequestService.verifyRegistrationRequest(
      email,
      code
    );

    if (registrationRequest) {
      await firebaseAuthService.createUserWithoutPassword(
        email,
        registrationRequest.userType,
        false,
        registrationRequest.referrer,
        registrationRequest.tenantFilterParams
      );
      await registrationRequestService.deleteRegistrationRequest(email);
    } else {
      handleSnackBar('Der eingegebene Code stimmt ist leider nicht korrekt!', 'error');
      setButtonSubmitMode(SUBMIT_BUTTON_MODE.NONE);
    }
  };

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (coolDown > 0) {
      interval = setInterval(() => {
        setCoolDown(coolDown - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [coolDown]);

  const submitSuccess = async () => {
    setButtonSubmitMode(SUBMIT_BUTTON_MODE.SUBMITTING);
    await handleVerification(email, parseInt(watch('activationCode')));
  };

  return (
    <PageLayout hideMenu pageTitle={r(ROUTES.staticRoutes.registration.activateAccount.title)} showPageTitle={false}>
      <Grid container justifyContent={'center'} paddingTop={8}>
        <Grid item xs={12} md={6}>
          <FormContext.Provider value={{ control }}>
            <form onSubmit={handleSubmit(submitSuccess, console.error)}>
              <CTACard title={t('title')} text={t('text', { email })} />
              <FourDigitsInput name={'activationCode'} required={true} />
              <div style={{ textAlign: 'center' }}>
                <CTAButton
                  expandMobile
                  disabled={buttonSubmitMode === SUBMIT_BUTTON_MODE.SUBMITTING || !formState.isValid}
                  icon={getSubmitIcon(buttonSubmitMode)}
                  spinIcon={buttonSubmitMode === SUBMIT_BUTTON_MODE.SUBMITTING}
                  rounded={false}
                  buttonText={t('submit')}
                />
              </div>
              <Spacer />
            </form>
            <Text align={'center'}>
              Keine E-Mail erhalten?{' '}
              {coolDown ? (
                `${coolDown} Sekunden warten`
              ) : (
                <button onClick={() => resendMail(email)}>
                  <Text color={TEXT_COLOR.TEXT_COLOR_PRIMARY}>E-Mail erneut senden</Text>
                </button>
              )}
            </Text>
            <Spacer size={SPACER_SIZE.SM} />
            <ChangeUser align={'center'} email={email} />
          </FormContext.Provider>
        </Grid>
      </Grid>
    </PageLayout>
  );
};

export default ActivateAccountView;
