import styles from './ApartmentBadge.module.scss';
import Badge, { BADGE_COLORS } from '../Badge';
import { IApartment } from '@wohnsinn/ws-ts-lib';
import { FC } from 'react';
import { Timestamp } from 'firebase/firestore';
import { useTranslation } from 'react-i18next';

const ApartmentBadge: FC<{
  apartmentHasFixedTerm?: boolean;
  apartmentIsFurnished?: boolean;
  apartmentIsNew?: boolean;
  isDisabled?: boolean;
}> = ({ apartmentIsFurnished, isDisabled = false, apartmentIsNew, apartmentHasFixedTerm }) => {
  const { t } = useTranslation('common');
  const twoBadgesActive = [apartmentIsNew, isDisabled].filter(Boolean).length === 2;
  const threeBadgesActive = [apartmentIsNew, apartmentIsFurnished, isDisabled].filter(Boolean).length === 2;

  if (apartmentIsNew || apartmentIsFurnished || apartmentHasFixedTerm || isDisabled) {
    return (
      <div className={styles.wrapper}>
        {isDisabled ? <Badge text={t('deactivated')} color={BADGE_COLORS.DISABLED} /> : null}
        {apartmentIsNew ? <Badge text={`${t('new').toUpperCase()}!`} color={BADGE_COLORS.PRIMARY} /> : null}
        {apartmentIsFurnished && !twoBadgesActive ? (
          <Badge text={`${t('furnished').toUpperCase()}!`} color={BADGE_COLORS.PRIMARY} />
        ) : null}
        {apartmentHasFixedTerm && !twoBadgesActive && !threeBadgesActive ? (
          <Badge text={`${t('limited').toUpperCase()}!`} color={BADGE_COLORS.PRIMARY} />
        ) : null}
      </div>
    );
  }

  return null;
};

export const checkIfApartmentIsNew = (apartment: IApartment): boolean => {
  if (apartment.publishedAt) {
    const publishedDate = new Timestamp(
      // @ts-ignore
      apartment.publishedAt['_seconds'],
      // @ts-ignore
      apartment.publishedAt['_nanoseconds']
    ).toDate();
    const diff = (new Date().getTime() - publishedDate.getTime()) / 1000;
    const dayDiff = Math.floor(diff / 86400);
    if (isNaN(dayDiff) || dayDiff < 0 || dayDiff >= 31) return false;

    return dayDiff < 4;
  }
  return false;
};

export default ApartmentBadge;
