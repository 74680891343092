import { FC, useState } from 'react';
import { IApplication } from '@wohnsinn/ws-ts-lib';
import ApartmentDrawer from '../../../../view/tenant/matching/ApartmentDrawer';
import useApartment from '../../../../core/hook/apartment.hook';
import { useInView } from 'react-intersection-observer';
import ApartmentCardMedia from '../ApartmentCardMedia';
import ApartmentCardApartmentInformation from '../ApartmentCardApartmentInformation';
import ApartmentCardWrapper from '../ApartmentCardWrapper';

export interface IBookmarkApartmentCard {
  application: IApplication;
  handleApartmentDrawerClose: any;
}

const BookmarkApartmentCard: FC<IBookmarkApartmentCard> = ({ application, handleApartmentDrawerClose }) => {
  const [showApartment, setShowApartment] = useState<boolean>(false);
  const { apartment, isLoading } = useApartment(application.apartmentId);

  const { ref, entry } = useInView({
    trackVisibility: true,
    delay: 100,
    triggerOnce: true,
    rootMargin: '100px',
    threshold: 0,
  });

  return (
    <div ref={ref} id={`apartment-card-${application.apartmentId}`}>
      <ApartmentCardWrapper isDisabled={application.isChatDisabled}>
        {!isLoading ? (
          <ApartmentDrawer
            isBookmark={true}
            show={showApartment}
            close={setShowApartment}
            onClose={handleApartmentDrawerClose}
            apartment={apartment}
            applicationRating={application.rating}
          />
        ) : null}

        <ApartmentCardMedia
          reelsView
          apartmentId={application.apartmentId}
          media={application.media ? [application.media] : null}
          isIntersecting={entry?.isIntersecting}
          onGalleryClick={() => setShowApartment(true)}
        />

        <button type={'button'} onClick={() => setShowApartment(true)}>
          <ApartmentCardApartmentInformation
            address={application.address}
            typeOfMarketing={apartment?.mainInformation.typeOfMarketing}
            cost={apartment?.cost}
            rooms={application.rooms}
            size={application.size}
          />
        </button>
      </ApartmentCardWrapper>
    </div>
  );
};

export default BookmarkApartmentCard;
