import { useEffect, useState } from 'react';
import { ROUTES } from 'core/const/routes';
import PageLayout from 'component/layouts/PageLayout';
import { useTranslation } from 'react-i18next';
import ProgressBar from 'component/atoms/ProgressBar';
import SelectCityStep from 'component/organisms/searchTunnel/SelectCityStep';
import SelectLocationStep from 'component/organisms/searchTunnel/SelectLocationStep';
import { SearchTunnelStepContext } from '../../../core/context/search-tunnel-step.context';
import SelectMaxRentStep from 'component/organisms/searchTunnel/SelectMaxRentStep';
import SelectNumberOfRoomsStep from 'component/organisms/searchTunnel/SelectNumberOfRoomsStep';
import SelectApartmentSizeStep from 'component/organisms/searchTunnel/SelectApartmentSizeStep';
import SelectEquipmentStep from 'component/organisms/searchTunnel/SelectEquipmentStep';
import Text from 'component/atoms/typographie/Text';
import FinishedTunnelRedirectScreen from 'component/organisms/registration/FinishedTunnelRedirectScreen';
import { useLocation } from 'react-router-dom';
import { wohnsinnServices } from 'App';

export enum SEARCH_TUNNEL_STEPS {
  SELECT_CITY = 'SELECT_CITY',
  SELECT_LOCATION = 'SELECT_LOCATION',
  SELECT_MAX_RENT = 'SELECT_MAX_RENT',
  SELECT_ROOM_NUMBER = 'SELECT_ROOM_NUMBER',
  SELECT_APARTMENT_SIZE = 'SELECT_APARTMENT_SIZE',
  SELECT_EQUIPMENT = 'SELECT_EQUIPMENT',
  FINISH_LOADING_SCREEN = 'FINISH_LOADING_SCREEN',
}
const SearchTunnelView = () => {
  const { t: r } = useTranslation('routes');
  const { mixpanelTrackingService } = wohnsinnServices;
  const [progress, setProgress] = useState<number>(10);
  const [question, setQuestion] = useState<number>(1);
  const [currentSearchTunnelStep, setCurrentSearchTunnelStep] = useState<SEARCH_TUNNEL_STEPS>(
    SEARCH_TUNNEL_STEPS.SELECT_CITY
  );
  const location = useLocation();

  useEffect(() => {
    if (location?.search?.includes('location')) {
      setCurrentSearchTunnelStep(SEARCH_TUNNEL_STEPS.SELECT_LOCATION);
    }
  }, []);

  const renderTunnelStep = () => {
    switch (currentSearchTunnelStep) {
      case SEARCH_TUNNEL_STEPS.SELECT_CITY:
        return <SelectCityStep />;
      case SEARCH_TUNNEL_STEPS.SELECT_LOCATION:
        return <SelectLocationStep />;
      case SEARCH_TUNNEL_STEPS.SELECT_MAX_RENT:
        return <SelectMaxRentStep />;
      case SEARCH_TUNNEL_STEPS.SELECT_ROOM_NUMBER:
        return <SelectNumberOfRoomsStep />;
      case SEARCH_TUNNEL_STEPS.SELECT_APARTMENT_SIZE:
        return <SelectApartmentSizeStep />;
      case SEARCH_TUNNEL_STEPS.SELECT_EQUIPMENT:
        return <SelectEquipmentStep />;
      case SEARCH_TUNNEL_STEPS.FINISH_LOADING_SCREEN:
        return (
          <FinishedTunnelRedirectScreen
            headline={'✨ Deine Treffer werden erstellt...'}
            hideText
            redirectUrl={`${r(ROUTES.staticRoutes.registration.tunnel.path)}/${location.search}`}
          />
        );
      default:
        return <SelectLocationStep />;
    }
  };

  useEffect(() => {
    mixpanelTrackingService?.trackEnterPage(currentSearchTunnelStep);
    const stepProgressMap = {
      [SEARCH_TUNNEL_STEPS.SELECT_CITY]: 1,
      [SEARCH_TUNNEL_STEPS.SELECT_LOCATION]: 2,
      [SEARCH_TUNNEL_STEPS.SELECT_MAX_RENT]: 3,
      [SEARCH_TUNNEL_STEPS.SELECT_ROOM_NUMBER]: 4,
      [SEARCH_TUNNEL_STEPS.SELECT_APARTMENT_SIZE]: 5,
      [SEARCH_TUNNEL_STEPS.SELECT_EQUIPMENT]: 6,
      [SEARCH_TUNNEL_STEPS.FINISH_LOADING_SCREEN]: 0,
    };

    const progressValue = stepProgressMap[currentSearchTunnelStep];

    if (progressValue !== undefined) {
      setProgress((100 / 6) * progressValue);
      setQuestion(progressValue);
    }
  }, [currentSearchTunnelStep]);

  return (
    <PageLayout
      tunnelMode
      hideFooter
      hideMenu
      showPageTitle={false}
      pageTitle={r(ROUTES.staticRoutes.searchTunnel.title)}
    >
      <SearchTunnelStepContext.Provider value={{ currentSearchTunnelStep, setCurrentSearchTunnelStep }}>
        {progress ? <ProgressBar progress={progress} /> : null}
        {question ? (
          <div style={{ marginTop: 24 }}>
            <Text mobileAlign={'center'}>{question === 6 ? 'Letzte Frage 🚀' : `Frage ${question} von 6 🤘`}</Text>
          </div>
        ) : null}
        {renderTunnelStep()}
      </SearchTunnelStepContext.Provider>
    </PageLayout>
  );
};

export default SearchTunnelView;
