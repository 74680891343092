import { FIRESTORE_COLLECTION_PATH, IApartment, IMedia, MEDIA_TYPE } from '@wohnsinn/ws-ts-lib';
import MediaUpload from 'MediaUpload';
import { Grid } from '@mui/material';
import DocumentUploadListItem from 'component/atoms/DocumentUploadListItem';
import { wohnsinnServices } from 'App';
import { FC, useContext } from 'react';
import UserContext from 'core/context/user.context';
import { useQueryClient } from '@tanstack/react-query';

const ApartmentDocumentUpload: FC<{ apartment: IApartment }> = ({ apartment }) => {
  const { apartmentService, firebaseStorageService } = wohnsinnServices;
  const { landlordProfile } = useContext(UserContext);
  const queryClient = useQueryClient();
  const { user } = useContext(UserContext);

  const onUpload = async (uploadedFiles: IMedia[]) => {
    let newApartmentDocuments: IMedia[] = [];
    if (apartment?.documents?.length) {
      newApartmentDocuments = [...apartment.documents];
    }
    try {
      await apartmentService.updateApartment({
        data: { documents: [...newApartmentDocuments, ...uploadedFiles] },
        creatorId: apartment.creatorId,
        apartmentId: apartment.id,
      });
      await queryClient.invalidateQueries({ queryKey: ['apartments'] });
    } catch (e) {
      console.error('Error on ApartmentMainInformationForm onFormSubmit', e);
    }
  };

  const documentDeleteHandler = async (media: IMedia) => {
    const path = `${FIRESTORE_COLLECTION_PATH.users.landlordProfiles.apartments.root
      .replace('{uid}', apartment.creatorId)
      .replace('{landlordId}', apartment.creatorId)}/${apartment.id}/documents/${media.id + '.pdf'}`;
    await firebaseStorageService.deleteFiles([path]);

    try {
      const newApartmentDocuments: IMedia[] = apartment.documents.filter((doc) => doc.id !== media.id);
      await apartmentService.updateApartment({
        data: { documents: [...newApartmentDocuments] },
        creatorId: apartment.creatorId,
        apartmentId: apartment.id,
      });
      await queryClient.invalidateQueries({ queryKey: ['apartments'] });
    } catch (e) {
      console.error('Error on ApartmentMainInformationForm onFormSubmit', e);
    }
  };

  const downloadDocument = (upload: IMedia) => {
    const windowReference = window.open();
    firebaseStorageService.getDownloadUrl(`${upload.url}`).then((href) => {
      windowReference.location = href;
    });
  };

  return (
    <Grid container spacing={2} md={4} direction={'column'}>
      {apartment?.documents?.length
        ? apartment.documents.map((doc) => (
            <Grid item xs={6} md={2} key={doc.id}>
              <DocumentUploadListItem
                upload={doc}
                deleteHandler={() => documentDeleteHandler(doc)}
                previewHandler={() => downloadDocument(doc)}
              />
            </Grid>
          ))
        : null}
      <Grid item xs={6} md={2}>
        <MediaUpload
          onUpdate={onUpload}
          allowMultipleFiles={true}
          creatorId={user.uid}
          subDirectoryPath={apartment.id}
          mediaType={MEDIA_TYPE.DOCUMENT}
        />
      </Grid>
    </Grid>
  );
};

export default ApartmentDocumentUpload;
