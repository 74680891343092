import { FC } from 'react';
import styles from './HeadlineTextBanner.module.scss';
import Headline, { HEADLINE_COLOR, HEADLINE_SIZE } from '../../atoms/typographie/Headline';
import Text, { TEXT_COLOR, TEXT_TYPE, TEXT_WEIGHT } from '../../atoms/typographie/Text';
import CTAButton, { BUTTON_STYLE } from '../../atoms/Buttons/CTAButton';
import Spacer, { SPACER_SIZE } from '../../atoms/Spacer';

export interface IHeadlineTextBanner {
  tag?: string;
  headline?: string;
  headlineSecond?: string;
  text?: string;
  headlineSize?: HEADLINE_SIZE;
  buttonText?: string;
  secondButtonText?: string;
  link?: string;
  href?: string;
  secondLink?: string;
  onClick?: () => void;
  icon?: string;
  color?: 'light' | 'dark';
}

const HeadlineTextBanner: FC<IHeadlineTextBanner> = ({
  tag,
  headline,
  headlineSecond,
  text,
  headlineSize,
  buttonText,
  secondLink,
  secondButtonText,
  link = HEADLINE_SIZE.DISPLAY,
  onClick,
  icon,
  href,
  color = 'dark',
}) => {
  return (
    <div className={styles.wrapper}>
      {icon ? <img className={styles.icon} src={icon} alt="" /> : null}
      {tag && (
        <>
          <Text
            color={TEXT_COLOR.TEXT_COLOR_PRIMARY}
            weight={TEXT_WEIGHT.TEXT_WEIGHT_BOLD}
            type={TEXT_TYPE.TEXT_TYPE_BODY}
            tag={'span'}
            align={'center'}
          >
            {tag}
          </Text>
          {text || headline ? <Spacer size={SPACER_SIZE.SM} /> : null}
        </>
      )}

      {headline ? (
        <Headline breakWord size={headlineSize} align={'center'}>
          <Headline
            color={color === 'light' ? HEADLINE_COLOR.HEADLINE_COLOR_LIGHT : null}
            tag={'span'}
            size={headlineSize}
            align={'center'}
          >
            {headline}
          </Headline>
          {headlineSecond ? (
            <>
              <br />
              <Headline tag={'span'} size={headlineSize} align={'center'}>
                {headlineSecond}
              </Headline>
            </>
          ) : null}
        </Headline>
      ) : null}

      {text || headline ? <Spacer /> : null}

      {text ? (
        <div className={styles.content}>
          <Text
            align={'center'}
            color={color === 'light' ? TEXT_COLOR.TEXT_COLOR_LIGHT : TEXT_COLOR.TEXT_COLOR_ACCENT}
            type={TEXT_TYPE.TEXT_TYPE_LANDING}
          >
            {text}
          </Text>
        </div>
      ) : null}
      {secondButtonText && (
        <div className={styles.buttonWrapper}>
          <CTAButton
            onClick={onClick}
            buttonText={secondButtonText}
            link={secondLink}
            buttonStyle={BUTTON_STYLE.SECONDARY}
          />
        </div>
      )}
      {buttonText && (
        <div className={styles.buttonWrapper}>
          <CTAButton onClick={onClick} buttonText={buttonText} href={href ?? link} buttonStyle={BUTTON_STYLE.PRIMARY} />
        </div>
      )}
    </div>
  );
};

export default HeadlineTextBanner;
