import { createContext, Dispatch, SetStateAction } from 'react';

const ThreeColumnsLayoutContext = createContext<{
  showLeftSideBar: boolean;
  showRightSideBar: boolean;
  showBackButton?: boolean;
  showInfoButton?: boolean;
  setShowInfoButton: Dispatch<SetStateAction<boolean>> | null;
  setShowBackButton?: Dispatch<SetStateAction<boolean>> | null;
  setPageTitle?: Dispatch<SetStateAction<string>> | null;
  showLeft: () => void;
  showMiddle: () => void;
  showRight: () => void;
}>({
  showBackButton: false,
  showLeftSideBar: false,
  setShowInfoButton: null,
  showRightSideBar: false,
  setPageTitle: null,
  showInfoButton: null,
  showLeft: null,
  showMiddle: null,
  showRight: null,
});

export default ThreeColumnsLayoutContext;
