import { ChangeEvent, FC, KeyboardEvent, MouseEvent, useContext, useEffect, useRef, useState } from 'react';
import styles from './ApartmentInLocationFinder.module.scss';
import useAccessibleNavigation from '../../../core/hook/use-accessible.navigation';
import { DebounceInput } from 'react-debounce-input';
import AddressFinderMap from 'component/molecules/AddressFinderMap';
import { IGeoPoint, ILocationSelection, TCenter } from '@wohnsinn/ws-ts-lib';
import { useTranslation } from 'react-i18next';
import Headline, { HEADLINE_SIZE } from '../../atoms/typographie/Headline';
import Text, { TEXT_TYPE, TEXT_VARIANT } from '../../atoms/typographie/Text';
import { Slider } from '@mui/material';
import TenantFilterParamsContext from '../../../core/context/tenant-filter-params.context';

const ApartmentInLocationFinder: FC<{ onChange: any; hideTitle?: boolean; withRedirect: boolean }> = ({
  onChange,
  hideTitle = false,
  withRedirect = false,
}) => {
  const INITIAL_RADIUS_IN_KM = 10;
  const { handleKeyboardNavigation } = useAccessibleNavigation();
  const { tenantFilterParams } = useContext(TenantFilterParamsContext);
  const ref = useRef(null);
  const [suggestionList, setSuggestionList] = useState<any>([]);
  const [selectedSuggestion, setSelectedSuggestion] = useState<ILocationSelection>();
  const { t } = useTranslation('common');
  const { t: a } = useTranslation('common', { keyPrefix: 'component.molecules.ApartmentInLocationFinder' });
  const [sliderValue, setSliderValue] = useState<number>(
    selectedSuggestion?.radius ? selectedSuggestion.radius : INITIAL_RADIUS_IN_KM
  );

  useEffect(() => {
    if (tenantFilterParams?.location) {
      const selectedParams: ILocationSelection = {
        ...tenantFilterParams?.location,
      };
      setSelectedSuggestion(selectedParams);
    }
  }, [tenantFilterParams]);

  const fetchSuggestions = async (searchString: string): Promise<void> => {
    try {
      const mapBoxApiUrl = `https://api.mapbox.com/geocoding/v5/mapbox.places/${searchString}.json`;
      const suggestionListData = await (
        await fetch(
          `${mapBoxApiUrl}?country=DE&language=DE&&types=place,locality,postcode,district,neighborhood&&&access_token=${process.env.REACT_APP_MAP_BOX_TOKEN}`
        )
      ).json();

      setSuggestionList(suggestionListData?.features);
    } catch (e) {
      console.error('error on fetchSuggestions', e);
    }
  };

  const handleSearch = async (searchString: string): Promise<void> => {
    if (searchString.length) {
      await fetchSuggestions(searchString);
    }
  };

  const onSelect = async (e: KeyboardEvent | MouseEvent, selection: any) => {
    const center: TCenter = selection.center;
    const geoPoint: IGeoPoint = { lat: selection.center[1], lng: selection.center[0] };
    const radius: number = selection?.radius ? selection.radius : INITIAL_RADIUS_IN_KM;

    const selectedParams: ILocationSelection = {
      center,
      geoPoint,
      place_name: selection.place_name,
      radius,
      id: selection.id,
    };
    setSelectedSuggestion({ ...selectedParams, radius });
    onChange(selectedParams, withRedirect);
    ref.current.value = selection.place_name;
    setSuggestionList([]);
  };

  function valuetext(value: number) {
    return `${value}°C`;
  }

  const clearInput = (e: any) => {
    setSelectedSuggestion({
      center: null,
      geoPoint: null,
      radius: 0,
      place_name: '',
      id: '',
    });
    onChange('');
    e.target.value = '';
    ref.current.value = '';
    setSuggestionList([]);
  };

  return (
    <div className={styles.formWrapper}>
      {hideTitle ? null : (
        <div className={styles.suggestionsTitle}>
          <>
            <Headline size={HEADLINE_SIZE.H3}>Wohnort</Headline>
            <Text tag={'span'} type={TEXT_TYPE.TEXT_TYPE_BODY} variant={TEXT_VARIANT.TEXT_VARIANT_SMALL}>
              {a('title')}
            </Text>
          </>
        </div>
      )}
      <div className={styles.wrapper}>
        <DebounceInput
          ref={ref}
          debounceTimeout={200}
          key={'searchInput'}
          type={'search'}
          onFocus={clearInput}
          onClick={clearInput}
          autoComplete={'off'}
          className={styles.input}
          placeholder={t('component.organisms.searchTunnelSlides.City.placeholder')}
          name="addressSearch"
          value={selectedSuggestion?.place_name ? selectedSuggestion.place_name : null}
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleSearch((e.target as HTMLInputElement).value)}
        />

        {suggestionList?.length > 0 && (
          <ul className={styles.optionWrapper}>
            {suggestionList.map((suggestion: any) => (
              <li
                onKeyDown={(e) =>
                  handleKeyboardNavigation(e, {
                    enableArrowKeyNavigation: true,
                    enterHandler: (e) => onSelect(e, suggestion),
                    spaceHandler: (e) => onSelect(e, suggestion),
                  })
                }
                onClick={(e) => onSelect(e, suggestion)}
                key={suggestion.place_name}
                tabIndex={0}
                className={styles.option}
              >
                {suggestion.place_name}
              </li>
            ))}
          </ul>
        )}
      </div>
      {selectedSuggestion?.place_name && (
        <Slider
          valueLabelFormat={(val) => val + ' km'}
          valueLabelDisplay={'auto'}
          getAriaValueText={valuetext}
          value={sliderValue}
          min={1}
          step={1}
          max={25}
          /*@ts-ignore*/
          onChange={(e: Event, newValue: number) => setSliderValue(newValue)}
          onChangeCommitted={(e: any, event: any) => {
            setSelectedSuggestion({ ...selectedSuggestion, radius: event as number });
            onChange({
              ...selectedSuggestion,
              radius: event as number,
            });
          }}
        />
      )}
      <div className={styles.mapWrapper}>
        <AddressFinderMap
          height={'250px'}
          radius={selectedSuggestion?.radius ?? 5}
          center={selectedSuggestion?.center}
        />
      </div>
    </div>
  );
};

export default ApartmentInLocationFinder;
