import { FC, useContext, useState } from 'react';
import styles from './CropImage.module.scss';
import CTAButton from '../Buttons/CTAButton';
import ReactCrop, { PixelCrop } from 'react-image-crop';
import ModalContext from 'core/context/modal.context';
import { ICropImageModalProps } from 'component/modals/CropImageModal';
import { useTranslation } from 'react-i18next';

const CROP_ASPECT_RATIO = 1;
const CropImage: FC<ICropImageModalProps> = (props) => {
  const { t } = useTranslation('common');
  const {
    image,
    onCropFinish,
    x = 150,
    y = 100,
    width = 200,
    height = 200,
    isCircularCrop = true,
    dx = 0,
    dy = 0,
  } = props;
  const { closeModal } = useContext(ModalContext);
  const [src] = useState(URL.createObjectURL(image));
  const [crop, setCrop] = useState<PixelCrop>({
    unit: 'px',
    x,
    y,
    width,
    height,
  });

  const cropImageNow = () => {
    // CREATE IMAGE FROM SRC
    const image = new Image();
    image.src = src;
    // GET DISPLAYED IMAGE SIZE
    const imgToCrop = document.getElementById('image-to-crop');
    const imgWidth = imgToCrop.clientWidth;
    const imgHeight = imgToCrop.clientHeight;

    // CREATE CANVAS
    const canvas = document.createElement('canvas');
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    // CALCULATE PIXEL RATIO
    const pixelRatio = window.devicePixelRatio;
    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;

    // GET SCALES
    const scaleX = image.naturalWidth / imgWidth;
    const scaleY = image.naturalHeight / imgHeight;

    // DRAW CROPPED IMAGE
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      dx,
      dy,
      crop.width,
      crop.height
    );

    // CONVERT TO BASE64 AND DISPLAY IMAGE
    canvas.toBlob((blobPng) => {
      onCropFinish(blobPng);
    });
    // setOutput(base64Image);

    closeModal();
  };

  return (
    <div className={styles.modal}>
      <div className={styles.wrapper}>
        {src && (
          <div className={styles.cropWrapper}>
            <ReactCrop
              circularCrop={isCircularCrop}
              aspect={CROP_ASPECT_RATIO}
              crop={crop}
              minHeight={200}
              minWidth={200}
              onChange={(c: PixelCrop) => setCrop(c)}
            >
              <img alt="crop-img" id="image-to-crop" width={500} src={src} />
            </ReactCrop>

            <div className={styles.buttonWrapper}>
              <CTAButton expand={'block'} onClick={cropImageNow} buttonText={t('save')} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CropImage;
