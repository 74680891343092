import { createContext, Dispatch, SetStateAction } from 'react';
import { ISnackBarProps } from '../../component/molecules/SnackBarLoader';
import { AlertColor } from '@mui/material/Alert/Alert';

export interface ISnackbarContextProps {
  isSnackBarOpen: boolean;
  snackBarProps: ISnackBarProps;
  handleSnackBar: (message: string, type: AlertColor) => void;
  setIsSnackBarOpen: Dispatch<SetStateAction<boolean>>;
}

const SnackBarContext = createContext<ISnackbarContextProps>({
  isSnackBarOpen: false,
  handleSnackBar: null,
  snackBarProps: null,
  setIsSnackBarOpen: null,
});

export default SnackBarContext;
