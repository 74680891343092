import ModalWrapper from '../../ModalWrapper';
import ApartmentInLocationFinder from 'component/molecules/ApartmentInLocationFinder';
import { useContext, useState } from 'react';
import ModalContext from 'core/context/modal.context';
import { ILocationSelection } from '@wohnsinn/ws-ts-lib';
import { useTranslation } from 'react-i18next';
import TenantFilterParamsContext from 'core/context/tenant-filter-params.context';
import { ROUTES } from 'core/const/routes';
import { useNavigate } from 'react-router-dom';

const FilterLocationModal = () => {
  const { t: r } = useTranslation('routes');
  const { t } = useTranslation('common');
  const { tenantFilterParams, updateTenantFilterParams } = useContext(TenantFilterParamsContext);
  const { closeModal, modalData } = useContext(ModalContext);
  const [location, setLocation] = useState<ILocationSelection>(tenantFilterParams?.location);
  const navigate = useNavigate();
  const { t: a } = useTranslation('common', { keyPrefix: 'component.molecules.ApartmentInLocationFinder' });

  const handleModalClose = () => {
    if (modalData?.withRedirect) {
      const url = new URL(window.location.href);
      if (location) {
        url.searchParams.set('location', JSON.stringify(location));
      }
      navigate(`${r(ROUTES.staticRoutes.search.path)}/${url.search}`);
    } else {
      updateTenantFilterParams({ location: location });
    }
    closeModal();
  };

  return (
    <ModalWrapper
      title={a('modal.title')}
      buttons={{
        primary: {
          buttonText: t('startSearch'),
          onClick: handleModalClose,
        },
      }}
    >
      <ApartmentInLocationFinder
        withRedirect={modalData?.withRedirect}
        hideTitle
        onChange={(location: ILocationSelection) => setLocation(location)}
      />
    </ModalWrapper>
  );
};

export default FilterLocationModal;
