import styles from './Badge.module.scss';
import { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import Text, { TEXT_WEIGHT } from '../typographie/Text';

export enum BADGE_COLORS {
  PRIMARY = 'PRIMARY',
  DANGER = 'DANGER',
  LIGHT = 'LIGHT',
  SUCCESS = 'SUCCESS',
  DISABLED = 'DISABLED',
}

export interface IBadgeProps {
  text: string;
  color?: BADGE_COLORS;
  icon?: IconProp;
  action?: () => any;
  weight?: TEXT_WEIGHT;
}

const Badge: FC<IBadgeProps> = ({
  text,
  color = BADGE_COLORS.PRIMARY,
  icon,
  action,
  weight = TEXT_WEIGHT.TEXT_WEIGHT_BOLD,
}) => {
  return (
    <div className={`${styles.badge} ${styles[color]}`}>
      {icon && action ? <FontAwesomeIcon className={styles.icon} icon={icon} onClick={action} /> : null}
      <Text className={styles[color]} weight={weight} tag={'span'}>
        {text}
      </Text>
    </div>
  );
};

export default Badge;
