import { IApplication } from '@wohnsinn/ws-ts-lib';
import { getAgeFromDateString } from 'core/helper/get-age-from-date-string';
import { IRange } from 'view/landlord/apartment/ApartmentApplicationsView/index';

/**
 * Filter the given list by given key and value
 * @param list - Application List to filter
 * @param key - Filter to be set
 * @param value - Given value for the filter
 */
const filterByAppliedFilters = (
  list: IApplication[],
  key: string,
  value: string | IRange | boolean
): IApplication[] => {
  if (key === 'numberOfPeopleMovingIn' && value) {
    return list.filter((application) => {
      return parseInt(String(application.tenantProfile.household.numberOfPeopleMovingIn)) <= parseInt(value as string);
    });
  }
  if (key === 'ageRange') {
    const rangeValue = value as IRange;
    return list.filter((application) => {
      const age = getAgeFromDateString(
        new Date(
          /*@ts-ignore */
          application.tenantProfile.personalInformation.dateOfBirth.seconds * 1000 +
            /*@ts-ignore */
            application.tenantProfile.personalInformation.dateOfBirth.nanoseconds / 1000000
        ).toISOString()
      );
      return age >= rangeValue.low && age <= rangeValue.high;
    });
  }

  if (key === 'jobs') {
    let jobList = list;
    const includedJobStatuses = Object.entries(value)
      .filter(([_, shouldBeIncluded]) => shouldBeIncluded)
      .map(([jobStatus]) => jobStatus);

    if (includedJobStatuses.length > 0) {
      jobList = jobList.filter((application) =>
        includedJobStatuses.includes(application.tenantProfile.personalInformation.jobStatus)
      );
    }

    return jobList;
  }

  if (key === 'hasIntroductionVideo' && value) {
    return list.filter((application) => {
      return !!application.tenantProfile?.aboutMe?.introductionVideo === true;
    });
  }
  if (key === 'hasIncomeProof' && value) {
    return list.filter((application) => {
      return application.tenantProfile?.hasIncomeProofDocs === true;
    });
  }
  if (key === 'hasSchufa' && value) {
    return list.filter((application) => {
      return application.tenantProfile?.hasSchufaDocs === true;
    });
  }

  if (key === 'hasPledge' && value) {
    return list.filter((application) => {
      return application.tenantProfile?.household?.hasPledge === true;
    });
  }

  if (key === 'petsForbidden' && value) {
    return list.filter((application) => {
      return application.tenantProfile?.household?.arePetsAllowed === false;
    });
  }

  if (key === 'sharedUsageForbidden' && value) {
    return list.filter((application) => {
      return application.tenantProfile?.household?.isSharedUsagePossible === false;
    });
  }

  return list;
};

export default filterByAppliedFilters;
