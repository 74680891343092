import styles from '../HowToSlides.module.scss';
import HowToSlideWrapper from '../HowToSlideWrapper';
import CTAButton from '../../../atoms/Buttons/CTAButton';
import { useTranslation } from 'react-i18next';
import { useSwiper } from 'swiper/react';

const HowToSlideIntro = () => {
  const swiper = useSwiper();
  const { t } = useTranslation('common', { keyPrefix: 'component.organisms.HowToSlides.intro' });

  const handleNextSlide = () => {
    swiper.allowSlideNext = true;
    swiper.slideNext(300);
  };

  return (
    <HowToSlideWrapper title={t('title')} subtitle={t('text')}>
      <div className={styles.applyWrapper}>
        <img className={styles.image} src={t('image')} alt="how-to-apply" />
        <div className={styles.button}>
          <CTAButton expand={'full'} onClick={handleNextSlide} buttonText={t('cta')} />
        </div>
      </div>
    </HowToSlideWrapper>
  );
};

export default HowToSlideIntro;
