import { FC, useContext, useEffect, useState } from 'react';
import PageLayout from 'component/layouts/PageLayout';
import { ROUTES } from 'core/const/routes';
import { useTranslation } from 'react-i18next';
import { SUBMIT_BUTTON_MODE } from 'core/enum/submit-button-mode.enum';
import FB_FUNCTION_URLS from 'core/const/fb-function-names';
import { wohnsinnServices } from 'App';
import SnackBarContext from 'core/context/snackbar.context';
import { useNavigate, useParams } from 'react-router-dom';
import LoadingAnimation from 'component/atoms/LoadingAnimation';

const UnsubscribeView: FC = () => {
  const { id, email } = useParams<{ id: string; email: string }>();
  const { handleSnackBar } = useContext(SnackBarContext);
  const [buttonSubmitMode, setButtonSubmitMode] = useState<SUBMIT_BUTTON_MODE>(SUBMIT_BUTTON_MODE.NONE);
  const { t: r } = useTranslation('routes');
  const navigate = useNavigate();
  const { firebaseFunctionsService } = wohnsinnServices;

  const updateNotificationSettings = async (): Promise<boolean | void> => {
    setButtonSubmitMode(SUBMIT_BUTTON_MODE.SUBMITTING);

    try {
      await firebaseFunctionsService.callFbFunction(FB_FUNCTION_URLS.user.updateNotificationSettings, {
        body: { email, uid: id },
      });

      setButtonSubmitMode(SUBMIT_BUTTON_MODE.NONE);
      handleSnackBar('toast.notificationSettingsUpdated', 'success');
      navigate(r('/'));
    } catch (e) {
      handleSnackBar('toast.error', 'error');
      setButtonSubmitMode(SUBMIT_BUTTON_MODE.NONE);
      console.error('Error updating notification Settings', e);
    }
  };

  useEffect(() => {
    updateNotificationSettings();
  }, [email, id]);

  return (
    <PageLayout showStaticMenu pageTitle={r(ROUTES.staticRoutes.unsubscribe.title)} showBackButton>
      {buttonSubmitMode === SUBMIT_BUTTON_MODE.SUBMITTING ? <LoadingAnimation /> : null}
    </PageLayout>
  );
};

export default UnsubscribeView;
