export enum MODAL_IDENTIFIER {
  BEFORE_APPLICATION_OVERVIEW = 'BEFORE_APPLICATION_OVERVIEW',
  COMPLETE_APPLICATION_FOLDER = 'COMPLETE_APPLICATION_FOLDER',
  COMPLETE_DOCUMENT_FOLDER = 'COMPLETE_DOCUMENT_FOLDER',
  CONFIRM_EMAIL = 'CONFIRM_EMAIL',
  CREATE_OR_UPLOAD_SCHUFA = 'CREATE_OR_UPLOAD_SCHUFA',
  CREATE_USER_INVITATION = 'CREATE_USER_INVITATION',
  CROP_IMAGE = 'CROP_IMAGE',
  DELETE_MODAL = 'DELETE_MODAL',
  DESIRED_TENANT = 'DESIRED_TENANT',
  EMAIL_VERIFICATION = 'EMAIL_VERIFICATION',
  FILTER_LOCATION = 'FILTER_LOCATION',
  FILTER_RESET = 'FILTER_RESET',
  HOW_TO_SLIDES = 'HOW_TO_SLIDES',
  INFO_TEXT = 'INFO_TEXT',
  MISSING_APPLICATION_FOLDER = 'MISSING_APPLICATION_FOLDER',
  NONE = 'NONE',
  NOTIFICATIONS = 'NOTIFICATIONS',
  PUBLISH_APARTMENT = 'PUBLISH_APARTMENT',
  RESET_PASSWORD = 'RESET_PASSWORD',
  REQUEST_SALE = 'REQUEST_SALE',
  RENT_STATUS = 'RENT_STATUS',
  SIGN_IN = 'SIGN_IN',
  SWITCH_USER_TYPE = 'SWITCH_USER_TYPE',
  SIGN_UP_USER = 'SIGN_UP_USER',
  SHARE_LINK = 'SHARE_LINK',
  MULTI_MESSAGE = 'MULTI_MESSAGE',
  VIRTUAL_TOUR = 'VIRTUAL_TOUR',
  YOU_HAVE_BEEN_INVITED_MODAL = 'YOU_HAVE_BEEN_INVITED_MODAL',
  APPLICANT_NOTES_MODAL = 'APPLICANT_NOTES_MODAL',
}
