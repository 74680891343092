import {
  FIRESTORE_COLLECTION_PATH,
  ILandlordPersonalInformation,
  LANDLORD_PERSONAL_INFORMATION_SCHEMA,
  REGISTRATION_STATES,
} from '@wohnsinn/ws-ts-lib';
import InputText from 'component/atoms/formElement/InputText';
import Joi from 'joi';
import PageTitle from 'component/molecules/PageTitle';
import ProfileAvatar from 'component/molecules/ProfileAvatar';
import RegistrationLayout from 'component/organisms/registration/RegistrationLayout';
import Spacer from 'component/atoms/Spacer';
import UserContext from 'core/context/user.context';
import { FormContext } from 'core/context/form.context';
import { Grid } from '@mui/material';
import { SUBMIT_BUTTON_MODE } from 'core/enum/submit-button-mode.enum';
import { joiResolver } from '@hookform/resolvers/joi';
import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { wohnsinnServices } from 'App';
import SnackBarContext from 'core/context/snackbar.context';

const ADD_PROFILE_FORM_ID = 'create-landlord-add-profile';
const CreateLandlordAddProfileScreen = () => {
  const { handleSnackBar } = useContext(SnackBarContext);
  const { landlordService, userService, firebaseStorageService } = wohnsinnServices;
  const { landlordProfile, user } = useContext(UserContext);
  const PROFILE_IMAGE_PATH = `${FIRESTORE_COLLECTION_PATH.users.landlordProfiles.root.replace('{uid}', user.uid)}/${
    user.uid
  }/profileImage.png`;
  const [buttonSubmitMode, setButtonSubmitMode] = useState<SUBMIT_BUTTON_MODE>(SUBMIT_BUTTON_MODE.NONE);
  const { t: c } = useTranslation('common');
  const { control, watch, handleSubmit, formState } = useForm<ILandlordPersonalInformation>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: joiResolver(Joi.object(LANDLORD_PERSONAL_INFORMATION_SCHEMA)),
  });

  const handleFormSubmit = async (): Promise<boolean | void> => {
    setButtonSubmitMode(SUBMIT_BUTTON_MODE.SUBMITTING);
    try {
      await landlordService.updateLandlordPersonalInformation(
        landlordProfile.uid,
        watch() as ILandlordPersonalInformation
      );

      setTimeout(async () => {
        let nextState = REGISTRATION_STATES.VERIFY_PHONE_NUMBER;
        // if is not a commercial landlord or is a member of organization
        if (!landlordProfile?.isCommercialLandlord || user?.isPhoneNumberVerified) {
          nextState = REGISTRATION_STATES.FINISH_LOADING_SCREEN;
        }
        await userService.updateUserData({ registrationState: nextState }, landlordProfile.uid);
      }, 500);
    } catch (e) {
      console.error('Error: ', e);
    }
  };

  const handleProfileImageUpload = async (photoUrl: string) => {
    try {
      await landlordService.updateLandlordProfilePicture(user.uid, photoUrl);
    } catch (e) {
      console.error('Error on handleProfileImageUpload: ', e);
    }
  };

  const deleteProfileImage = async () => {
    try {
      await landlordService.updateLandlordProfilePicture(user.uid, null);
      await firebaseStorageService.deleteFiles([PROFILE_IMAGE_PATH]);
      handleSnackBar('toast.profileImage.deleted', 'success');
    } catch (e) {
      console.error('Error on deleteProfileImage', e);
    }
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <PageTitle
          pageTitle={'Sag uns wer du bist'}
          secondPageTitle={
            'Wir brauchen Name, Vorname und Telefonnummer, um dich besser unterstützen und individuell ansprechen zu können. Deine Daten sind bei uns in sicheren Händen!'
          }
        />
        <ProfileAvatar
          storagePath={PROFILE_IMAGE_PATH}
          onUpload={handleProfileImageUpload}
          onDelete={deleteProfileImage}
          photoUrl={landlordProfile?.photoUrl}
        />
        <Spacer />
        <RegistrationLayout
          buttonSubmitMode={buttonSubmitMode}
          isValidForm={formState.isValid}
          formId={ADD_PROFILE_FORM_ID}
          submitButtonText={!landlordProfile?.isCommercialLandlord ? c('finishRegistration') : null}
        >
          <FormContext.Provider value={{ control }}>
            <form id={ADD_PROFILE_FORM_ID} onSubmit={handleSubmit(handleFormSubmit, console.error)}>
              <InputText label={c('firstName.label')} name={'firstName'} required />
              <InputText label={c('lastName.label')} name={'lastName'} required />
              <InputText type={'tel'} label={c('phoneNumber.label')} name={'phoneNumber'} required />
            </form>
          </FormContext.Provider>
        </RegistrationLayout>
      </Grid>
    </Grid>
  );
};

export default CreateLandlordAddProfileScreen;
