import { Grid } from '@mui/material';
import { IMAGES } from '../../../../core/const/images';
import MetricsBanner from '../../../molecules/MetricsBanner';
import { useTranslation } from 'react-i18next';

const AboutWohnsinnSection = () => {
  const { t: a } = useTranslation('common', { keyPrefix: 'view.general.AboutUsView' });
  return (
    <Grid container rowSpacing={8} columnSpacing={4} justifyContent={'center'}>
      <Grid item>
        <MetricsBanner
          headlineTextBanner
          headline={'Über Wohnsinn'}
          tag={'Wer wir sind'}
          metrics={[
            {
              title: a('metrics.one.title'),
              value: a('metrics.one.value'),
              text: a('metrics.one.text'),
            },
            {
              title: a('metrics.two.title'),
              value: a('metrics.two.value'),
              text: a('metrics.two.text'),
            },
            {
              title: a('metrics.three.title'),
              value: a('metrics.three.value'),
              text: a('metrics.three.text'),
            },
            {
              title: a('metrics.four.title'),
              value: a('metrics.four.value'),
              text: a('metrics.four.text'),
            },
          ]}
          image={IMAGES.graphics.about.src}
          imageAlt={IMAGES.graphics.about.alt}
        />
      </Grid>
    </Grid>
  );
};

export default AboutWohnsinnSection;
