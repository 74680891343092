import PageLayout from '../../../component/layouts/PageLayout';
import { ROUTES } from '../../../core/const/routes';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { onSnapshot } from 'firebase/firestore';
import { IApartment } from '@wohnsinn/ws-ts-lib';
import LoadingAnimation from '../../../component/atoms/LoadingAnimation';
import Headline, { HEADLINE_COLOR, HEADLINE_SIZE } from '../../../component/atoms/typographie/Headline';
import {
  Checkbox,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import CTAButton from '../../../component/atoms/Buttons/CTAButton';
import printDate from '../../../core/helper/printDate';
import Text from '../../../component/atoms/typographie/Text';
import { Unsubscribe } from '@firebase/firestore';
import SearchInput from '../../../component/atoms/formElement/SearchInput';
import { wohnsinnServices } from '../../../App';

enum ACTION_TYPE {
  PUBLISH = 'PUBLISH',
  DEPUBLISH = 'DEPUBLISH',
  DELETE = 'DELETE',
}

const AdminUserManagement = () => {
  // custom hooks
  const { t } = useTranslation('common');
  const { t: r } = useTranslation('routes');
  // contexts
  const { apartmentService } = wohnsinnServices;
  // states
  const [allApartmentsCount, setAllApartmentsCount] = useState(0);
  const [apartmentList, setApartmentList] = useState<IApartment[]>([]);
  const [filteredApartmentList, setFilteredApartmentList] = useState<IApartment[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [publishedApartmentsCount, setPublishedApartmentsCount] = useState(0);
  const [selectedApartments, setSelectedApartments] = useState<IApartment[]>([]);
  const [actionType, setActionType] = useState<ACTION_TYPE>(ACTION_TYPE.PUBLISH);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);

  const fetchApartments = async (): Promise<Unsubscribe> => {
    setIsLoading(true);

    const allApartmentsListRef = apartmentService.getAllApartmentsListRef(null, false);
    const unsubscribe = onSnapshot(
      allApartmentsListRef,
      (apartmentListSnap) => {
        const apartments = apartmentListSnap.docs.map((doc) => doc.data());
        setAllApartmentsCount(apartments.length);
        let publishedApartmentsCount = 0;
        apartments.forEach((apartment) => {
          if (apartment.isPublished) publishedApartmentsCount++;
        });

        setPublishedApartmentsCount(publishedApartmentsCount);
        setApartmentList(apartments);
        setFilteredApartmentList(apartments);
      },
      (err) => console.error('error on useApartmentHook: ', err)
    );

    setIsLoading(false);
    return unsubscribe;
  };

  useEffect(() => {
    let unsub;
    (async () => {
      unsub = await fetchApartments();
    })();
    return unsub;
  }, []);

  const handleSelectApartment = (selectedApartment: IApartment) => {
    let foundIndex;
    const found = selectedApartments.find((apartment: IApartment, index) => {
      if (apartment.id === selectedApartment.id) {
        foundIndex = index;
        return apartment.id === selectedApartment.id;
      }
    });

    if (found) {
      const apartments = selectedApartments;
      apartments.splice(foundIndex, 1);
      setSelectedApartments([...apartments]);
    } else {
      setSelectedApartments([...selectedApartments, selectedApartment]);
    }
  };

  const handleSearch = (searchKey: string) => {
    if (!searchKey) {
      setFilteredApartmentList([...apartmentList]);
    } else {
      const newList = apartmentList.filter((apartment) => apartment.id.toLowerCase().includes(searchKey.toLowerCase()));
      setFilteredApartmentList([...newList]);
    }
  };

  const handleAction = async () => {
    try {
      switch (actionType) {
        case ACTION_TYPE.DELETE:
          if (confirm('Sicher dass du die ausgewählten Objekte dauerhaft löschen möchtest?')) {
            await apartmentService.deleteApartmentList(selectedApartments);
            setSelectedApartments([]);
          }
          break;
        case ACTION_TYPE.DEPUBLISH:
          await apartmentService.updateApartmentsPublishedStates(selectedApartments, false);
          break;
        case ACTION_TYPE.PUBLISH:
          await apartmentService.updateApartmentsPublishedStates(selectedApartments, true);
          break;
        default:
          await apartmentService.updateApartmentsPublishedStates(selectedApartments, true);
      }
    } catch (e) {
      console.error('Error on handleAction', e);
    }
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <PageLayout pageTitle={r(ROUTES.adminRoutes.apartments.title)}>
      <Grid item xs={12}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div>
            {isLoading ? (
              <LoadingAnimation />
            ) : (
              <Headline size={HEADLINE_SIZE.H3}>
                <Headline tag={'span'} size={HEADLINE_SIZE.H2} color={HEADLINE_COLOR.HEADLINE_COLOR_PRIMARY}>
                  {publishedApartmentsCount}{' '}
                </Headline>
                von insgesamt{' '}
                <Headline tag={'span'} size={HEADLINE_SIZE.H2}>
                  {allApartmentsCount}{' '}
                </Headline>{' '}
                Objekten veröffentlicht
              </Headline>
            )}
          </div>
          <div>
            <SearchInput name={'Suche'} placeholder={'Apartment ID'} onChange={handleSearch} />
          </div>
        </div>
      </Grid>
      <Grid item xs={12}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Text>
            {selectedApartments.length} {`Objekt${selectedApartments.length > 1 ? 'e' : ''} ausgewählt.`}
          </Text>

          <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
            <select name="action" id="action" onChange={(e) => setActionType(e.target.value as ACTION_TYPE)}>
              <option value={ACTION_TYPE.PUBLISH}>Veröffentlichen</option>
              <option value={ACTION_TYPE.DEPUBLISH}>Deaktivieren</option>
              <option value={ACTION_TYPE.DELETE}>Löschen</option>
            </select>
            <CTAButton disabled={selectedApartments.length <= 0} buttonText={'Speichern'} onClick={handleAction} />
          </div>
        </div>
      </Grid>
      <Grid>
        {isLoading ? (
          <LoadingAnimation />
        ) : (
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell width={70}>Select</TableCell>
                  <TableCell width={70}>ID</TableCell>
                  <TableCell>Street</TableCell>
                  <TableCell>Published At</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? filteredApartmentList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  : filteredApartmentList
                ).map((apartment: IApartment, index) => (
                  <TableRow key={apartment.id + index}>
                    <TableCell width={70} component="th" scope="row">
                      <Checkbox onChange={() => handleSelectApartment(apartment)} />
                    </TableCell>
                    <TableCell width={70} component="th" scope="row">
                      {apartment.id}
                    </TableCell>
                    <TableCell>
                      {apartment?.mainInformation?.address?.street ?? 'no street'}{' '}
                      {apartment?.mainInformation?.address?.houseNumber ?? ''}
                    </TableCell>
                    <TableCell>
                      {apartment?.publishedAt
                        ? printDate(apartment.publishedAt, t, t('dateFormatWithTime'))
                        : 'not published'}
                    </TableCell>
                    <TableCell>{apartment.isPublished ? 'true' : 'false'}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                    colSpan={5}
                    count={filteredApartmentList.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    slotProps={{
                      select: {
                        inputProps: {
                          'aria-label': 'rows per page',
                        },
                        native: true,
                      },
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    // ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        )}
      </Grid>
    </PageLayout>
  );
};

export default AdminUserManagement;
