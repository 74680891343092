import { useTranslation } from 'react-i18next';
import ModalWrapper from '../ModalWrapper';
import { useContext } from 'react';
import ModalContext from 'core/context/modal.context';
import Text from 'component/atoms/typographie/Text';

const YouHaveBeenInvitedModal = () => {
  const { t } = useTranslation('common');
  const { t: m } = useTranslation('common', { keyPrefix: 'component.molecules.modals.YouveBeenInvitedModal' });
  const { closeModal } = useContext(ModalContext);

  return (
    <ModalWrapper
      title={m('title')}
      icon={m('image')}
      buttons={{
        primary: { buttonText: t('getStarted'), onClick: closeModal },
      }}
    >
      <Text align={'center'}>{m('text')}</Text>
    </ModalWrapper>
  );
};

export default YouHaveBeenInvitedModal;
