import { FC } from 'react';
import { Tab, Tabs as MuiTabs } from '@mui/material';
import Text, { TEXT_COLOR, TEXT_TYPE, TEXT_WEIGHT } from '../typographie/Text';
import styles from './Tabs.module.scss';

export interface TABS_TAB_PROPS {
  value: any;
  label: string;
  icon?: string;
  iconAlt?: string;
  id: string;
  badge?: boolean;
}

const Tabs: FC<{ value: any; onChange: any; id: string; tabs: TABS_TAB_PROPS[] }> = ({ value, onChange, id, tabs }) => {
  const renderTabs = () =>
    tabs.map((tab) => (
      <Tab
        className={`${styles.tab} ${tab.value === value ? styles.active : ''}`}
        key={tab.id}
        value={tab.value}
        label={
          <Text
            tag={'span'}
            color={tab.value === value ? TEXT_COLOR.TEXT_COLOR_PRIMARY : TEXT_COLOR.TEXT_COLOR_ACCENT}
            type={TEXT_TYPE.TEXT_TYPE_CAPTION}
            weight={TEXT_WEIGHT.TEXT_WEIGHT_SEMI_BOLD}
            align={'center'}
          >
            {tab.label}{' '}
            {tab.icon ? (
              <img style={{ width: 24, marginLeft: 8, display: 'inline' }} alt={tab.iconAlt} src={tab.icon} />
            ) : null}
            {tab.badge ? <div className={styles.badge} /> : null}
          </Text>
        }
        id={'application-tab-maybe'}
        aria-controls={`application-tab-maybe`}
      />
    ));

  return (
    <>
      <MuiTabs
        className={styles.tabs}
        id={id}
        centered
        // variant="fullWidth"
        value={value}
        onChange={onChange}
        aria-label={id}
      >
        {renderTabs()}
      </MuiTabs>
    </>
  );
};

export default Tabs;
