import { FC, useContext, useEffect, useState } from 'react';
import Toggle from 'component/atoms/formElement/Toggle';
import TextInput from 'component/atoms/formElement/InputText';
import { FormContext } from 'core/context/form.context';
import { useTranslation } from 'react-i18next';
import { SUBMIT_BUTTON_MODE } from 'core/enum/submit-button-mode.enum';
import { useForm } from 'react-hook-form';
import { EQUIPMENT, IApartmentEquipment } from '@wohnsinn/ws-ts-lib';
import { wohnsinnServices } from 'App';
import { Grid } from '@mui/material';
import SnackBarContext from 'core/context/snackbar.context';
import { useQueryClient } from '@tanstack/react-query';
import useFormErrorHook from 'core/hook/use-form-error.hook';
import { ICreateApartmentTunnel } from 'view/landlord/apartment/ApartmentCreateView';
import useApartment from 'core/hook/apartment.hook';
import { ROUTES } from 'core/const/routes';
import { useNavigate } from 'react-router-dom';
import { TEXT_COLOR } from 'component/atoms/typographie/Text';
import FormNavigation from 'component/molecules/FormNavigation';
import FormHeader from 'component/atoms/FormHeader';
import BackButton from 'component/atoms/Buttons/BackButton';
import useWindowSize from 'core/hook/windowsize.hook';

const ApartmentEquipmentForm: FC<ICreateApartmentTunnel> = ({ isTunnelView }) => {
  const { apartmentService } = wohnsinnServices;
  const queryClient = useQueryClient();
  const { apartment } = useApartment();
  const { handleError } = useFormErrorHook('ApartmentEquipmentForm');
  const { handleSnackBar } = useContext(SnackBarContext);
  const { t } = useTranslation('common');
  const { t: r } = useTranslation('routes');
  const { t: a } = useTranslation('common', { keyPrefix: 'apartment.equipment' });
  const [buttonSubmitMode, setButtonSubmitMode] = useState<SUBMIT_BUTTON_MODE>(SUBMIT_BUTTON_MODE.NONE);
  const navigate = useNavigate();
  const { control, reset, handleSubmit, watch, setValue } = useForm<any>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
  });
  const { isSmallerMd } = useWindowSize();

  const hasBalconyOrTerrace: boolean = watch('hasBalconyOrTerrace');
  const hasKitchen: boolean = watch('hasKitchen');
  const hasElevator: boolean = watch('hasElevator');
  const hasGarden: boolean = watch('hasGarden');
  const hasParkingSpot: boolean = watch('hasParkingSpot');
  const isBarrierFree: boolean = watch('isBarrierFree');
  const isWheelChairAccessible: boolean = watch('isWheelChairAccessible');
  const hasBasement: boolean = watch('hasBasement');
  const hasGuestToilet: boolean = watch('hasGuestToilet');
  const isFurnished: boolean = watch('isFurnished');
  const description: string = watch('description');

  useEffect(() => {
    if (!!apartment?.equipment) {
      reset({
        hasBalconyOrTerrace: apartment.equipment.equipmentList.includes(EQUIPMENT.HAS_BALCONY_OR_TERRACE),
        hasBasement: apartment.equipment.equipmentList.includes(EQUIPMENT.HAS_BASEMENT),
        hasElevator: apartment.equipment.equipmentList.includes(EQUIPMENT.HAS_ELEVATOR),
        hasGarden: apartment.equipment.equipmentList.includes(EQUIPMENT.HAS_GARDEN),
        hasGuestToilet: apartment.equipment.equipmentList.includes(EQUIPMENT.HAS_GUEST_TOILET),
        hasKitchen: apartment.equipment.equipmentList.includes(EQUIPMENT.HAS_KITCHEN),
        hasParkingSpot: apartment.equipment.equipmentList.includes(EQUIPMENT.HAS_PARKING_SPOT),
        isBarrierFree: apartment.equipment.equipmentList.includes(EQUIPMENT.IS_BARRIER_FREE),
        isWheelChairAccessible: apartment.equipment.equipmentList.includes(EQUIPMENT.IS_WHEEL_CHAIR_ACCESSIBLE),
        isFurnished: apartment.equipment.equipmentList.includes(EQUIPMENT.IS_FURNISHED),
        description: apartment.equipment.description,
        hasKitchenPrice: apartment.equipment?.equipmentCosts?.kitchenPrice,
        equipmentCosts: apartment.equipment?.equipmentCosts,
        hasCarport: apartment.equipment.equipmentList.includes(EQUIPMENT.HAS_CARPORT),
        hasGarage: apartment.equipment.equipmentList.includes(EQUIPMENT.HAS_GARAGE),
        hasBasementGarage: apartment.equipment.equipmentList.includes(EQUIPMENT.HAS_BASEMENT_GARAGE),
        hasFreeSpace: apartment.equipment.equipmentList.includes(EQUIPMENT.HAS_FREE_SPACE),
        hasCarPark: apartment.equipment.equipmentList.includes(EQUIPMENT.HAS_CAR_PARK),
        hasDuplex: apartment.equipment.equipmentList.includes(EQUIPMENT.HAS_DUPLEX),
      });
    }
  }, [apartment]);

  const hasCarport = watch('hasCarport');
  const hasGarage = watch('hasGarage');
  const hasFreeSpace = watch('hasFreeSpace');
  const hasBasementGarage = watch('hasBasementGarage');
  const hasCarPark = watch('hasCarPark');
  const hasDuplex = watch('hasDuplex');

  const formatEquipment = (): IApartmentEquipment => {
    const equipmentList: EQUIPMENT[] = [];

    if (hasBalconyOrTerrace) {
      equipmentList.push(EQUIPMENT.HAS_BALCONY_OR_TERRACE);
    }

    if (hasElevator) {
      equipmentList.push(EQUIPMENT.HAS_ELEVATOR);
    }

    if (hasKitchen) {
      equipmentList.push(EQUIPMENT.HAS_KITCHEN);
    }

    if (hasGarden) {
      equipmentList.push(EQUIPMENT.HAS_GARDEN);
    }

    if (hasParkingSpot) {
      equipmentList.push(EQUIPMENT.HAS_PARKING_SPOT);
    }

    if (isBarrierFree) {
      equipmentList.push(EQUIPMENT.IS_BARRIER_FREE);
    }

    if (isWheelChairAccessible) {
      equipmentList.push(EQUIPMENT.IS_WHEEL_CHAIR_ACCESSIBLE);
    }

    if (hasBasement) {
      equipmentList.push(EQUIPMENT.HAS_BASEMENT);
    }

    if (hasGuestToilet) {
      equipmentList.push(EQUIPMENT.HAS_GUEST_TOILET);
    }

    if (isFurnished) {
      equipmentList.push(EQUIPMENT.IS_FURNISHED);
    }

    if (hasBasementGarage) {
      equipmentList.push(EQUIPMENT.HAS_BASEMENT_GARAGE);
    } else {
      setValue('equipmentCosts.basementGarageRent', null);
    }

    if (hasCarport) {
      equipmentList.push(EQUIPMENT.HAS_CARPORT);
    } else {
      setValue('equipmentCosts.carportRent', null);
    }

    if (hasCarPark) {
      equipmentList.push(EQUIPMENT.HAS_CAR_PARK);
    } else {
      setValue('equipmentCosts.carParkRent', null);
    }

    if (hasGarage) {
      equipmentList.push(EQUIPMENT.HAS_GARAGE);
    } else {
      setValue('equipmentCosts.garageRent', null);
    }

    if (hasFreeSpace) {
      equipmentList.push(EQUIPMENT.HAS_FREE_SPACE);
    } else {
      setValue('equipmentCosts.freeSpaceRent', null);
    }

    if (hasDuplex) {
      equipmentList.push(EQUIPMENT.HAS_DUPLEX);
    } else {
      setValue('equipmentCosts.duplexRent', null);
    }

    return {
      equipmentCosts: watch('equipmentCosts') ?? null,
      description,
      equipmentList,
    };
  };

  const onError = (err: any): void => {
    console.error(err);
    setButtonSubmitMode(SUBMIT_BUTTON_MODE.ERROR);
  };

  const saveDataToDB = async (): Promise<void> => {
    setButtonSubmitMode(SUBMIT_BUTTON_MODE.SUBMITTING);
    try {
      await apartmentService.updateApartment({
        data: { equipment: formatEquipment() },
        creatorId: apartment.creatorId,
        apartmentId: apartment.id,
      });
      await queryClient.invalidateQueries({ queryKey: ['apartments'] });
      setButtonSubmitMode(SUBMIT_BUTTON_MODE.SUCCESS);
      if (isTunnelView) {
        navigate(r(ROUTES.landlordRoutes.apartment.create.energySupply.path).replace(':apartmentId', apartment?.id));
      } else {
        handleSnackBar('toast.success.saved', 'success');
        navigate(r(ROUTES.landlordRoutes.apartment.overview.path).replace(':apartmentId', apartment?.id));
      }
    } catch (e) {
      handleError(e);
      setButtonSubmitMode(SUBMIT_BUTTON_MODE.ERROR);
    }
  };

  return (
    <>
      <FormContext.Provider value={{ control }}>
        <form
          onSubmit={handleSubmit(saveDataToDB, onError)}
          noValidate
          autoComplete="off"
          id={'apartmentEquipmentForm'}
          className={'form-with-navigation'}
        >
          {!isTunnelView && !isSmallerMd && (
            <div style={{ paddingBottom: '1rem' }}>
              <BackButton />
            </div>
          )}
          <FormHeader title={r(ROUTES.landlordRoutes.apartment.equipment.title)} />
          <Grid container className={'form-with-navigation'}>
            <Grid item xs={12} md={6}>
              <Grid container rowSpacing={2}>
                <Grid item xs={12}>
                  <Toggle label={a('equipmentList.HAS_ELEVATOR')} name={'hasElevator'} />
                </Grid>
                <Grid item xs={12}>
                  <Toggle label={a('equipmentList.HAS_BALCONY_OR_TERRACE')} name={'hasBalconyOrTerrace'} />
                </Grid>

                <Grid item xs={12}>
                  <Toggle label={a('equipmentList.IS_BARRIER_FREE')} name={'isBarrierFree'} />
                </Grid>

                <Grid item xs={12}>
                  <Toggle label={a('equipmentList.HAS_GARDEN')} name={'hasGarden'} />
                </Grid>
                <Grid item xs={12}>
                  <Toggle label={a('equipmentList.HAS_GUEST_TOILET')} name={'hasGuestToilet'} />
                </Grid>
                <Grid item xs={12}>
                  <Toggle label={a('equipmentList.HAS_BASEMENT')} name={'hasBasement'} />
                </Grid>
                <Grid item xs={12}>
                  <Toggle label={a('equipmentList.IS_FURNISHED')} name={'isFurnished'} />
                </Grid>

                <Grid item xs={12}>
                  <Grid container rowSpacing={2}>
                    <Grid item xs={12}>
                      <Toggle label={a('equipmentList.HAS_KITCHEN_LABEL')} name={'hasKitchen'} />
                    </Grid>

                    {watch('hasKitchen') && (
                      <>
                        <Grid item xs={1} />
                        <Grid item xs={11}>
                          <Toggle label={a('takeoverPriceLabel')} name={'hasKitchenPrice'} />
                        </Grid>
                        {watch('hasKitchenPrice') && (
                          <>
                            <Grid item xs={1} />
                            <Grid item xs={11}>
                              <TextInput
                                showLabel={false}
                                name={'equipmentCosts.kitchenPrice'}
                                type={'number'}
                                endAdornment={'€'}
                                pattern="[0-9]*"
                                unit={t('currency')}
                                label={a('takeoverPrice')}
                              />
                            </Grid>
                          </>
                        )}
                      </>
                    )}
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid container rowSpacing={2}>
                    <Grid item xs={12}>
                      <Toggle label={'Gibt es eine Parkmöglichkeit?'} name={'hasParkingSpot'} />
                    </Grid>

                    {watch('hasParkingSpot') ? (
                      <>
                        <Grid item xs={1} />
                        <Grid item xs={11}>
                          <Toggle label={a('equipmentList.HAS_GARAGE')} name={'hasGarage'} />
                        </Grid>

                        {watch('hasGarage') ? (
                          <>
                            <Grid item xs={1} />
                            <Grid item xs={11}>
                              <TextInput
                                label={a('equipmentCosts.garageRent')}
                                name={'equipmentCosts.garageRent'}
                                type={'number'}
                                showLabel={false}
                                endAdornment={'€'}
                                pattern="[0-9]*"
                                unit={t('currencyPerMonth')}
                              />
                            </Grid>
                          </>
                        ) : null}

                        <Grid item xs={1} />
                        <Grid item xs={11}>
                          <Toggle label={a('equipmentList.HAS_BASEMENT_GARAGE')} name={'hasBasementGarage'} />
                        </Grid>
                        {watch('hasBasementGarage') ? (
                          <>
                            <Grid item xs={1} />
                            <Grid item xs={11}>
                              <TextInput
                                label={a('equipmentCosts.basementGarageRent')}
                                name={'equipmentCosts.basementGarageRent'}
                                type={'number'}
                                pattern="[0-9]*"
                                unit={t('currencyPerMonth')}
                                showLabel={false}
                                endAdornment={'€'}
                              />
                            </Grid>
                          </>
                        ) : null}

                        <Grid item xs={1} />
                        <Grid item xs={11}>
                          <Toggle label={a('equipmentList.HAS_CARPORT')} name={'hasCarport'} />
                        </Grid>
                        {watch('hasCarport') ? (
                          <>
                            <Grid item xs={1} />
                            <Grid item xs={11}>
                              <TextInput
                                label={a('equipmentCosts.carportRent')}
                                name={'equipmentCosts.carportRent'}
                                type={'number'}
                                pattern="[0-9]*"
                                unit={t('currencyPerMonth')}
                                showLabel={false}
                                endAdornment={'€'}
                              />
                            </Grid>
                          </>
                        ) : null}

                        <Grid item xs={1} />
                        <Grid item xs={11}>
                          <Toggle label={a('equipmentList.HAS_FREE_SPACE')} name={'hasFreeSpace'} />
                        </Grid>
                        {watch('hasFreeSpace') ? (
                          <>
                            <Grid item xs={1} />
                            <Grid item xs={11}>
                              <TextInput
                                label={a('equipmentCosts.freeSpaceRent')}
                                name={'equipmentCosts.freeSpaceRent'}
                                type={'number'}
                                pattern="[0-9]*"
                                unit={t('currencyPerMonth')}
                                showLabel={false}
                                endAdornment={'€'}
                              />
                            </Grid>
                          </>
                        ) : null}

                        <Grid item xs={1} />
                        <Grid item xs={11}>
                          <Toggle label={a('equipmentList.HAS_CAR_PARK')} name={'hasCarPark'} />
                        </Grid>
                        {watch('hasCarPark') ? (
                          <>
                            <Grid item xs={1} />
                            <Grid item xs={11}>
                              <TextInput
                                label={a('equipmentCosts.carParkRent')}
                                name={'equipmentCosts.carParkRent'}
                                type={'number'}
                                pattern="[0-9]*"
                                unit={t('currencyPerMonth')}
                                showLabel={false}
                                endAdornment={'€'}
                              />
                            </Grid>
                          </>
                        ) : null}

                        <Grid item xs={1} />
                        <Grid item xs={11}>
                          <Toggle label={a('equipmentList.HAS_DUPLEX')} name={'hasDuplex'} />
                        </Grid>
                        {watch('hasDuplex') ? (
                          <>
                            <Grid item xs={1} />
                            <Grid item xs={11}>
                              <TextInput
                                label={a('equipmentCosts.duplexRent')}
                                name={'equipmentCosts.duplexRent'}
                                type={'number'}
                                pattern="[0-9]*"
                                unit={t('currencyPerMonth')}
                                showLabel={false}
                                endAdornment={'€'}
                              />
                            </Grid>
                          </>
                        ) : null}
                      </>
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <FormNavigation
            showSecondaryButtonMobile={isTunnelView}
            flipButtons={true}
            buttonSubmitMode={buttonSubmitMode}
            disabled={false}
            formId={'apartmentEquipmentForm'}
            submitButtonText={isTunnelView ? t('saveAndContinue') : t('save')}
            secondaryButtonAction={() => navigate(-1)}
            secondaryButtonColor={TEXT_COLOR.TEXT_COLOR_ACCENT}
            secondaryButtonText={t('back')}
          />
        </form>
      </FormContext.Provider>
    </>
  );
};

export default ApartmentEquipmentForm;
