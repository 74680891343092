import { FC } from 'react';
import styles from './ItemList.module.scss';
import Text, { TEXT_COLOR, TEXT_WEIGHT } from '../../atoms/typographie/Text';

export type TItemListItem = {
  label: string;
  key: string;
  value: string | number;
};

export interface IItemListProps {
  items: TItemListItem[];
  testId?: string;
}

const ItemList: FC<IItemListProps> = ({ items, testId }) => {
  return (
    <div className={styles.wrapper} data-testid={`itemList.${testId}`}>
      {items.map((item, index) => (
        <div key={`${item.key}-${index}`} className={styles.item}>
          <div data-testid={`item.label.${item.key}`} className={styles.label}>
            <Text weight={TEXT_WEIGHT.TEXT_WEIGHT_LIGHT} color={TEXT_COLOR.TEXT_COLOR_ACCENT}>
              {item.label}
            </Text>
          </div>
          <Text align={'right'} weight={TEXT_WEIGHT.TEXT_WEIGHT_SEMI_BOLD} color={TEXT_COLOR.TEXT_COLOR_DARK}>
            {item.value}
          </Text>
        </div>
      ))}
    </div>
  );
};

export default ItemList;
