import AGBView from 'view/landing-page/AGBView';
import AboutUsView from 'view/landing-page/AboutUsView';
import ContactView from 'view/landing-page/ContactView';
import CooperativeLandingView from 'view/landing-page/CooperativeLandingView';
import ImprintView from 'view/landing-page/ImprintView';
import LandingPageView from 'view/landing-page/LandingPageView';
import LandlordLandingView from 'view/landing-page/LandlordLandingView';
import MaintenanceModeView from 'view/landing-page/MaintenanceModeView';
import HuGLandingPageView from 'view/landing-page/HuGLandingPageView';
import PressView from 'view/landing-page/PressView';
import PricesView from 'view/landing-page/PricesView';
import PrivacyView from 'view/landing-page/PrivacyView';
import { IProtectedRouteProps } from 'AppRouter';
import { ROUTES } from '../routes';
import JobsView from 'view/landing-page/JobsView';
import PropertyManagementView from 'view/landing-page/PropertyManagementView';
import RealEstateAgentView from 'view/landing-page/RealEstateAgentView';

// These routes are always available, even in maintenanceMode
export const LANDING_PAGE_ROUTES: IProtectedRouteProps[] = [
  { path: ROUTES.landingPageRoutes.about.path, Component: AboutUsView },
  { path: ROUTES.landingPageRoutes.agb.path, Component: AGBView },
  { path: ROUTES.landingPageRoutes.contact.path, Component: ContactView },
  { path: ROUTES.landingPageRoutes.cooperative.path, Component: CooperativeLandingView },
  { path: ROUTES.landingPageRoutes.hug.path, Component: HuGLandingPageView },
  { path: ROUTES.landingPageRoutes.imprint.path, Component: ImprintView },
  { path: ROUTES.landingPageRoutes.landlord.path, Component: LandlordLandingView },
  { path: ROUTES.landingPageRoutes.maintenance.path, Component: MaintenanceModeView },
  { path: ROUTES.landingPageRoutes.press.path, Component: PressView },
  { path: ROUTES.landingPageRoutes.jobs.path, Component: JobsView },
  { path: ROUTES.landingPageRoutes.propertyManagement.path, Component: PropertyManagementView },
  { path: ROUTES.landingPageRoutes.realEstateAgent.path, Component: RealEstateAgentView },
  { path: ROUTES.landingPageRoutes.prices.path, Component: PricesView },
  { path: ROUTES.landingPageRoutes.privacy.path, Component: PrivacyView },
  { path: ROUTES.landingPageRoutes.tenant.path, Component: LandingPageView },
];
