import ModalWrapper from '../../ModalWrapper';
import { FC, useContext } from 'react';
import ModalContext from 'core/context/modal.context';
import Text, { TEXT_TYPE } from 'component/atoms/typographie/Text';
import { useTranslation } from 'react-i18next';
import TenantFilterParamsContext from 'core/context/tenant-filter-params.context';

const FilterResetModal: FC = () => {
  const { closeModal, modalData } = useContext(ModalContext);
  const { resetTenantFilterParams } = useContext(TenantFilterParamsContext);
  const { t } = useTranslation('common');

  const updateTenantFilterParamsHandler = () => {
    resetTenantFilterParams();
    closeModal();
    if (modalData?.onClose) {
      modalData.onClose();
    }
  };

  return (
    <ModalWrapper
      icon={t('pictogram.filter')}
      title={'Filter zurücksetzen'}
      buttons={{
        primary: {
          buttonText: 'Filter zurücksetzen',
          type: 'button',
          onClick: () => updateTenantFilterParamsHandler(),
        },
      }}
    >
      <Text align={'center'} type={TEXT_TYPE.TEXT_TYPE_BODY}>
        Möchtest du wirklich alle Filter zurücksetzen?
      </Text>
    </ModalWrapper>
  );
};

export default FilterResetModal;
