import { FC } from 'react';
import Text, { TEXT_COLOR, TEXT_TYPE, TEXT_WEIGHT } from '../typographie/Text';
import styles from './SplitLine.module.scss';

const SplitLine: FC<{ text?: string; size?: 'small' | 'big'; whiteBg?: boolean }> = ({
  text,
  size = 'big',
  whiteBg,
}) => {
  return (
    <div className={`${styles.outer} ${size === 'small' ? styles.small : ''}`}>
      <div className={styles.wrapper}>
        {!!text && (
          <div className={`${styles.text} ${whiteBg ? styles.white : ''}`}>
            <Text
              type={TEXT_TYPE.TEXT_TYPE_CAPTION}
              color={TEXT_COLOR.TEXT_COLOR_ACCENT}
              weight={TEXT_WEIGHT.TEXT_WEIGHT_BOLD}
            >
              {text}
            </Text>
          </div>
        )}
      </div>
    </div>
  );
};

export default SplitLine;
