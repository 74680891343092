import { useContext, useState } from 'react';
import { SUBMIT_BUTTON_MODE } from '../../../../core/enum/submit-button-mode.enum';
import { SearchTunnelStepContext } from '../../../../core/context/search-tunnel-step.context';
import TenantFilterParamsContext from '../../../../core/context/tenant-filter-params.context';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { SEARCH_TUNNEL_STEPS } from '../../../../view/static/SearchTunnelView';
import Slider from '../../../atoms/formElement/Slider';
import Headline, { HEADLINE_SIZE } from '../../../atoms/typographie/Headline';
import SearchTunnelStepLayout from '../SearchTunnelStepLayout';
import { useTranslation } from 'react-i18next';

const SELECT_MAX_RENT_FORM_ID = 'SELECT_MAX_RENT_FORM_ID';
const DEFAULT_VALUE = 5;
const SelectNumberOfRoomsStep = () => {
  const { setCurrentSearchTunnelStep } = useContext(SearchTunnelStepContext);
  const { tenantFilterParams, updateTenantFilterParams } = useContext(TenantFilterParamsContext);
  const [buttonSubmitMode, setButtonSubmitMode] = useState<SUBMIT_BUTTON_MODE>(SUBMIT_BUTTON_MODE.NONE);
  const { t: s } = useTranslation('common', { keyPrefix: 'component.organisms.searchTunnel.selectNumberOfRoomsStep' });

  const { control, handleSubmit, watch, formState } = useForm<{
    numberOfRooms: number;
  }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: joiResolver(Joi.object({ numberOfRooms: Joi.number() })),
    defaultValues: {
      numberOfRooms: DEFAULT_VALUE,
    },
  });
  const numberOfRooms = watch('numberOfRooms');

  const handleFormSubmit = () => {
    setButtonSubmitMode(SUBMIT_BUTTON_MODE.SUBMITTING);
    setCurrentSearchTunnelStep(SEARCH_TUNNEL_STEPS.SELECT_APARTMENT_SIZE);
    updateTenantFilterParams({ ...tenantFilterParams, rooms: numberOfRooms });
  };

  return (
    <SearchTunnelStepLayout
      formId={SELECT_MAX_RENT_FORM_ID}
      control={control}
      disabled={buttonSubmitMode === SUBMIT_BUTTON_MODE.SUBMITTING || !formState.isValid}
      buttonSubmitMode={buttonSubmitMode}
      onSubmit={handleSubmit(handleFormSubmit, console.error)}
      title={s('title')}
      backAction={() => {
        const newTfp = tenantFilterParams;
        delete newTfp.location;
        updateTenantFilterParams({ ...newTfp });
        setCurrentSearchTunnelStep(SEARCH_TUNNEL_STEPS.SELECT_MAX_RENT);
      }}
    >
      <Headline size={HEADLINE_SIZE.DISPLAY} align={'center'}>
        {numberOfRooms ? `ab ${numberOfRooms}` : '0'} Zimmer
      </Headline>
      <Slider defaultValue={DEFAULT_VALUE} min={1} step={0.5} name={'numberOfRooms'} max={10} />
    </SearchTunnelStepLayout>
  );
};

export default SelectNumberOfRoomsStep;
