import { FC } from 'react';
import styles from './Spacer.module.scss';

export enum SPACER_SIZE {
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XL = 'xl',
  NONE = 'none',
}

const Spacer: FC<{ size?: SPACER_SIZE; mobileSize?: SPACER_SIZE }> = ({ size, mobileSize }) => {
  return <div className={`${styles.spacer} ${styles[size]} ${styles[`mobile--${mobileSize}`]}`} />;
};

export default Spacer;
