import CreateLandlordTunnel from '../../../view/user/registration/CreateLandlordTunnel';
import CreatePasswordView from '../../../view/user/registration/CreatePasswordView';
import CreateTenantTunnel from '../../../view/user/registration/CreateTenantTunnel';
import SettingsView from '../../../view/user/SettingsView';
import { IProtectedRouteProps } from '../../../AppRouter';
import { ROUTES } from '../routes';

// routes only logged-in user can see
export const USER_ROUTES: IProtectedRouteProps[] = [
  { path: ROUTES.userRoutes.registration.createLandlordTunnel.path, Component: CreateLandlordTunnel },
  { path: ROUTES.userRoutes.registration.createPassword.path, Component: CreatePasswordView },
  { path: ROUTES.userRoutes.registration.createTenantTunnel.path, Component: CreateTenantTunnel },
  { path: ROUTES.userRoutes.settings.path, Component: SettingsView },
];
