export interface IImage {
  src: string;
  alt: string;
  width?: number;
  height?: number;
  sm?: string;
  md?: string;
  lg?: string;
}

export const IMAGES = {
  forLandlords: {
    private: <IImage>{
      src: 'forLandlords.private.src',
      alt: 'forLandlords.private.alt',
    },
    propertyManagement: <IImage>{
      src: 'forLandlords.propertyManagement.src',
      alt: 'forLandlords.propertyManagement.alt',
    },
    realEstateAgent: <IImage>{
      src: 'forLandlords.realEstateAgent.src',
      alt: 'forLandlords.realEstateAgent.alt',
    },
    cooperative: <IImage>{
      src: 'forLandlords.cooperative.src',
      alt: 'forLandlords.cooperative.alt',
    },
  },
  cities: {
    cologne: <IImage>{
      src: 'cities.cologne.src',
      alt: 'cities.cologne.alt',
      sm: 'cities.cologne.sm',
      md: 'cities.cologne.md',
      lg: 'cities.cologne.lg',
    },
    bonn: <IImage>{
      src: 'cities.bonn.src',
      alt: 'cities.bonn.alt',
      sm: 'cities.bonn.sm',
      md: 'cities.bonn.md',
      lg: 'cities.bonn.lg',
    },
    leverkusen: <IImage>{
      src: 'cities.leverkusen.src',
      alt: 'cities.leverkusen.alt',
      sm: 'cities.leverkusen.sm',
      md: 'cities.leverkusen.md',
      lg: 'cities.leverkusen.lg',
    },
    berlin: <IImage>{
      src: 'cities.berlin.src',
      alt: 'cities.berlin.alt',
      sm: 'cities.berlin.sm',
      md: 'cities.berlin.md',
      lg: 'cities.berlin.lg',
    },
  },
  collections: {
    oneRoomCollection: <IImage>{
      src: 'collections.oneRoomCollection.src',
      alt: 'collections.oneRoomCollection.alt',
    },
    twoRoomsCollection: <IImage>{
      src: 'collections.twoRoomsCollection.src',
      alt: 'collections.twoRoomsCollection.alt',
    },
    threeRoomsCollection: <IImage>{
      src: 'collections.threeRoomsCollection.src',
      alt: 'collections.threeRoomsCollection.alt',
    },
    collectionWithBalcony: <IImage>{
      src: 'collections.collectionWithBalcony.src',
      alt: 'collections.collectionWithBalcony.alt',
    },
  },
  graphics: {
    about: <IImage>{
      src: 'graphics.about.src',
      alt: 'graphics.about.alt',
    },
    faster: <IImage>{
      src: 'graphics.faster.src',
      alt: 'graphics.faster.alt',
    },
    heart: <IImage>{
      src: 'graphics.heart.src',
      alt: 'graphics.heart.alt',
    },
    profit: <IImage>{
      src: 'graphics.profit.src',
      alt: 'graphics.profit.alt',
    },
    profile: <IImage>{
      src: 'graphics.profile.src',
      alt: 'graphics.profile.alt',
    },
    moveIn: <IImage>{
      src: 'graphics.moveIn.src',
      alt: 'graphics.moveIn.alt',
    },
    swipe: <IImage>{
      src: 'graphics.swipe.src',
      alt: 'graphics.swipe.alt',
    },
  },
  icons: {
    heart: <IImage>{
      src: 'icons.heart.src',
      alt: 'icons.heart.alt',
    },
    brokenHeart: <IImage>{
      src: 'icons.brokenHeart.src',
      alt: 'icons.brokenHeart.alt',
    },
  },
  landing: {
    hero: <IImage>{
      src: 'landing.hero.src',
      alt: 'landing.hero.alt',
      sm: 'landing.hero.sm',
      md: 'landing.hero.md',
      lg: 'landing.hero.lg',
    },
  },
  images: {
    felixChris: <IImage>{
      src: 'images.felixChris.src',
      alt: 'images.felixChris.alt',
    },
    juliaHeidel: <IImage>{
      src: 'images.juliaHeidel.src',
      alt: 'images.juliaHeidel.alt',
    },
    michaelReiserer: <IImage>{
      src: 'images.michaelReiserer.src',
      alt: 'images.michaelReiserer.alt',
    },
    pascalKurth: <IImage>{
      src: 'images.pascalKurth.src',
      alt: 'images.pascalKurth.alt',
    },
    florianGlossner: <IImage>{
      src: 'images.florianGlossner.src',
      alt: 'images.florianGlossner.alt',
    },
    hugXws: <IImage>{
      src: 'images.hugXws.src',
      alt: 'images.hugXws.alt',
      sm: 'images.hugXws.sm',
      md: 'images.hugXws.md',
      lg: 'images.hugXws.lg',
    },
    cityCologne: <IImage>{
      src: 'images.cityCologne.src',
      alt: 'images.cityCologne.alt',
    },
    cityOther: <IImage>{
      src: 'images.cityOther.src',
      alt: 'images.cityOther.alt',
    },
  },
  team: {
    felix: <IImage>{
      src: 'team.felix.src',
      alt: 'team.felix.src',
    },
    christian: <IImage>{
      src: 'team.christian.src',
      alt: 'team.christian.src',
    },
    niklas: <IImage>{
      src: 'team.niklas.src',
      alt: 'team.niklas.src',
    },
    dom: <IImage>{
      src: 'team.dom.src',
      alt: 'team.dom.src',
    },
  },
  logos: {
    social: {
      facebook: <IImage>{
        src: '/assets/icon/social/facebook.png',
        alt: 'facebook logo',
      },
      whatsapp: <IImage>{
        src: '/assets/icon/social/whatsapp.png',
        alt: 'whatsapp logo',
      },
      xTwitter: <IImage>{
        src: '/assets/icon/social/xTwitter.png',
        alt: 'x twitter logo',
      },
    },
    crm: {
      ammon: <IImage>{
        src: '/assets/icon/crm/ammon.svg',
        alt: 'Ammon',
      },
      flowfact: <IImage>{
        src: '/assets/icon/crm/flowfact.svg',
        alt: 'flowfact',
      },
      immoprofessional: <IImage>{
        src: '/assets/icon/crm/immoprofessional.svg',
        alt: 'immoprofessional',
      },
      estateSmart: <IImage>{
        src: '/assets/icon/crm/estateSmart.svg',
        alt: 'estateSmart',
      },
      immoware: <IImage>{
        src: '/assets/icon/crm/immoware.svg',
        alt: 'immoware',
      },
      onOffice: <IImage>{
        src: '/assets/icon/crm/onOffice.svg',
        alt: 'onoffice',
      },
      openImmo: <IImage>{
        src: '/assets/icon/crm/openImmo.svg',
        alt: 'openImmo',
      },
      polyEstate: <IImage>{
        src: '/assets/icon/crm/polyEstate.svg',
        alt: 'polyEstate',
        width: 100,
      },
      propstack: <IImage>{
        src: '/assets/icon/crm/propstack.svg',
        alt: 'propstack',
      },
    },
    digihub: <IImage>{
      src: '/assets/icon/partner-logos/Digihub.svg',
      alt: 'Digihub',
      width: 600,
    },
    googleForStartups: <IImage>{
      src: '/assets/icon/partner-logos/Google_for_Startups.svg',
      alt: 'Google for Startups',
      width: 600,
    },
    group: <IImage>{
      src: '/assets/icon/partner-logos/Group.svg',
      alt: 'Group',
      width: 400,
    },
    handelsblatt: <IImage>{
      src: '/assets/icon/partner-logos/handelsblatt.svg',
      alt: 'handelsblatt',
      width: 400,
    },
    deutscheStartups: <IImage>{
      src: '/assets/icon/partner-logos/Logo.svg',
      alt: 'deutsche Startups',
    },
    hug: <IImage>{
      src: '/assets/icon/partner-logos/hausUndGrundBonn.svg',
      alt: 'Haus und Grund Bonn',
      width: 400,
    },
    nrw: <IImage>{
      src: '/assets/icon/partner-logos/nrw.svg',
      alt: 'Ministerium für Wirtschaft, Industrie, Klimaschutz und Energie NRW Logo',
      width: 600,
    },
    startupInsider: <IImage>{
      src: '/assets/icon/partner-logos/Startup_Insider.svg',
      alt: 'Startup_Insider',
    },
    startupValley: <IImage>{
      src: '/assets/icon/partner-logos/startupValley.png',
      alt: 'startupValley',
    },
    theProperty: <IImage>{
      src: '/assets/icon/partner-logos/theProperty.svg',
      alt: 'theProperty',
      width: 400,
    },
    VDW: <IImage>{
      src: '/assets/icon/partner-logos/VdW.svg',
      alt: 'vdw',
    },
    woge: <IImage>{
      src: '/assets/logos/WOGE_Logo.jpeg',
      alt: 'woge-logo',
    },
    wogeAlt: <IImage>{
      src: '/assets/logos/woge_logo.svg',
      alt: 'Woge Logo',
    },
    zia: <IImage>{
      src: '/assets/icon/partner-logos/zia1.svg',
      alt: 'zia',
      width: 200,
    },
    BE: <IImage>{
      src: '/assets/icon/clients/BE_Logo.svg',
      alt: 'BE',
    },
    Bellasai: <IImage>{
      src: '/assets/icon/clients/Bellassai_und_Sigmung.svg',
      width: 300,
      alt: 'Bellasai und Sigmund',
    },
    Burgmeyer: <IImage>{
      src: '/assets/icon/clients/burgmeyer.svg',
      alt: 'Burgmeyer',
      width: 300,
    },
    Dahmann: <IImage>{
      src: '/assets/icon/clients/Dahmann_Logo.svg',
      alt: 'Dahmann_Logo',
    },
    ivvg: <IImage>{
      src: '/assets/icon/clients/ivvg.svg',
      alt: 'Ivvg',
    },
    josefklein: <IImage>{
      src: '/assets/icon/clients/josefklein_logo.svg',
      alt: 'josefklein',
      width: 150,
    },
    larbig: <IImage>{
      src: '/assets/icon/clients/Larbig_und_Mortag.svg',
      alt: 'Larbig_und_Mortag',
      width: 450,
    },
    metropol: <IImage>{
      src: '/assets/icon/clients/metropol.svg',
      alt: 'metropol',
    },
    mvgm: <IImage>{
      src: '/assets/icon/clients/MVGM_Logo.svg',
      alt: 'Mvgm',
      width: 350,
    },
    ndi: <IImage>{
      src: '/assets/icon/clients/ndi-gmbh_logo.svg',
      alt: 'ndi',
      width: 150,
    },
    optimarum: <IImage>{
      src: '/assets/icon/clients/Optimarum_Logo.svg',
      alt: 'optimarum',
    },
    rida: <IImage>{
      src: '/assets/icon/clients/Rida.svg',
      alt: 'Rida',
    },
    skz: <IImage>{
      src: '/assets/icon/clients/SKZ_Logo.svg',
      alt: 'SKZ',
    },
    sps: <IImage>{
      src: '/assets/icon/clients/SPS_Logo.svg',
      alt: 'SPS',
      width: 350,
    },
  },
  mockups: {
    apartmentCard: <IImage>{
      src: 'mockups.apartmentCard.src',
      alt: 'mockups.apartmentCard.alt',
    },
    tenantProfile: <IImage>{
      src: 'mockups.tenantProfile.src',
      alt: 'mockups.tenantProfile.alt',
    },
    verwalteGesuche: <IImage>{
      src: 'mockups.verwalteGesuche.src',
      alt: 'mockups.verwalteGesuche.alt',
    },
    effizientesMatching: <IImage>{
      src: 'mockups.effizientesMatching.src',
      alt: 'mockups.effizientesMatching.alt',
    },
    transparent: <IImage>{
      src: 'mockups.transparent.src',
      alt: 'mockups.transparent.alt',
    },
  },
  pictograms: {
    building: <IImage>{
      src: 'pictograms.building.src',
      alt: 'pictograms.building.alt',
    },
    checkmark: <IImage>{
      src: 'pictograms.checkmark.src',
      alt: 'pictograms.checkmark.alt',
    },
    camera: <IImage>{
      src: 'pictograms.camera.src',
      alt: 'pictograms.camera.alt',
    },
    house: <IImage>{
      src: 'pictograms.house.src',
      alt: 'pictograms.house.alt',
    },
    time: <IImage>{
      src: 'pictograms.time.src',
      alt: 'pictograms.time.alt',
    },
    speech: <IImage>{
      src: 'pictograms.speech.src',
      alt: 'pictograms.speech.alt',
    },
    efficiency: <IImage>{
      src: 'pictograms.efficiency.src',
      alt: 'pictograms.efficiency.alt',
    },
    output: <IImage>{
      src: 'pictograms.output.src',
      alt: 'pictograms.output.alt',
    },
    brokenHeart: <IImage>{
      src: 'icons.brokenHeart.src',
      alt: 'icons.brokenHeart.alt',
    },
    bookAppointment: <IImage>{
      src: 'pictograms.bookAppointment.src',
      alt: 'pictograms.bookAppointment.alt',
    },
    callUs: <IImage>{
      src: 'pictograms.callUs.src',
      alt: 'pictograms.callUs.alt',
    },
    emailUs: <IImage>{
      src: 'pictograms.emailUs.src',
      alt: 'pictograms.emailUs.alt',
    },
    thumbDown: <IImage>{
      src: 'pictograms.thumbDown.src',
      alt: 'pictograms.thumbDown.alt',
    },
    openMail: <IImage>{
      src: 'pictograms.openMail.src',
      alt: 'pictograms.openMail.alt',
    },
    dollarSign: <IImage>{
      src: 'pictograms.dollarSign.src',
      alt: 'pictograms.dollarSign.alt',
    },
    lock: <IImage>{
      src: 'pictograms.lock.src',
      alt: 'pictograms.lock.alt',
    },
  },
  pros: {
    tenant: {
      exclusive: <IImage>{
        src: 'pros.tenant.exclusive.src',
        alt: 'pros.tenant.exclusive.alt',
      },
      betterChances: <IImage>{
        src: 'pros.tenant.betterChances.src',
        alt: 'pros.tenant.betterChances.alt',
      },
      fun: <IImage>{
        src: 'pros.tenant.fun.src',
        alt: 'pros.tenant.fun.alt',
      },
    },
    landlord: {
      autoSelect: <IImage>{
        src: 'pros.landlord.autoSelect.src',
        alt: 'pros.landlord.autoSelect.alt',
      },
      noMoreDocs: <IImage>{
        src: 'pros.landlord.noMoreDocs.src',
        alt: 'pros.landlord.noMoreDocs.alt',
      },
      safeTime: <IImage>{
        src: 'pros.landlord.safeTime.src',
        alt: 'pros.landlord.safeTime.alt',
      },
      graph: <IImage>{
        src: 'pros.landlord.graph.src',
        alt: 'pros.landlord.graph.alt',
      },
      crm: <IImage>{
        src: 'pros.landlord.crm.src',
        alt: 'pros.landlord.crm.alt',
      },
      maxReach: <IImage>{
        src: 'pros.landlord.maxReach.src',
        alt: 'pros.landlord.maxReach.alt',
      },
      hug: <IImage>{
        src: 'pros.landlord.hug.src',
        alt: 'pros.landlord.hug.alt',
      },
    },
  },
  testimonials: {
    anna: <IImage>{
      src: 'testimonials.anna.src',
      alt: 'testimonials.anna.alt',
    },
    katrin: <IImage>{
      src: 'testimonials.katrin.src',
      alt: 'testimonials.katrin.alt',
    },
    tobias: <IImage>{
      src: 'testimonials.tobias.src',
      alt: 'testimonials.tobias.alt',
    },
  },
};
