import { FC, useContext } from 'react';
import { Controller } from 'react-hook-form';
import { FormContext } from 'core/context/form.context';
import SelectNumber from 'component/atoms/SelectNumber';
import FormErrorMessage from 'component/atoms/formElement/FormErrorMessage';

const InputNumber: FC<{
  name: string;
  label: string;
  placeHolder?: string;
  disabled?: boolean;
  required?: boolean;
  step?: number;
  max: number;
}> = ({ name, label, placeHolder, disabled = false, required = false, max, step = 0.5 }) => {
  const { control } = useContext(FormContext);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        return (
          <>
            <SelectNumber
              required={required}
              step={step}
              error={!field?.value ? error : null}
              disabled={disabled}
              placeHolder={placeHolder}
              max={max}
              value={field?.value ? field.value : ''}
              label={label}
              onChange={field.onChange}
            />
            {<FormErrorMessage error={!field?.value ? error : null} />}
          </>
        );
      }}
    />
  );
};

export default InputNumber;
