import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import PageLayout from '../../../../component/layouts/PageLayout';
import { ROUTES } from '../../../../core/const/routes';
import AccountBreadcrumbs from '../../../../component/molecules/Breadcrumbs/AccountBreadcrumbs';
import { IAccountStepperViewProps } from '../AboutMeView';
import TunnelStepper, {
  ITunnelStepperStepProps,
  TUNNEL_STEP_STATE,
} from '../../../../component/organisms/Tunnels/TunnelStepper';
import TenantSchufaForm from '../../../../component/forms/tenant/TenantSchufaForm';

const SchufaUploadView: FC<IAccountStepperViewProps> = ({ isApplicationFolderTunnel = false }) => {
  const { t: r } = useTranslation('routes');

  const APPLICATION_FOLDER_STEPS_INCOME_PROOF: ITunnelStepperStepProps[] = [
    { label: 'Profil', state: TUNNEL_STEP_STATE.ACTIVE, link: ROUTES.tenantRoutes.account.applicationFolder.profile },
    {
      label: 'Haushalt',
      state: TUNNEL_STEP_STATE.ACTIVE,
      link: ROUTES.tenantRoutes.account.applicationFolder.household,
    },
    {
      label: 'Vorstellungsvideo',
      state: TUNNEL_STEP_STATE.ACTIVE,
      link: ROUTES.tenantRoutes.account.applicationFolder.introductionVideo,
    },
    {
      label: 'Über mich',
      state: TUNNEL_STEP_STATE.ACTIVE,
      link: ROUTES.tenantRoutes.account.applicationFolder.aboutMe,
    },
    { label: 'Schufa', state: TUNNEL_STEP_STATE.CURRENT, link: ROUTES.tenantRoutes.account.applicationFolder.schufa },
    {
      label: 'Einkommensnachweis',
      state: TUNNEL_STEP_STATE.INACTIVE,
      link: ROUTES.tenantRoutes.account.applicationFolder.incomeProof,
    },
  ];

  return (
    <PageLayout showPageTitle={false} pageTitle={r(ROUTES.tenantRoutes.account.schufa.title)} showBackButton>
      {isApplicationFolderTunnel ? (
        <TunnelStepper steps={APPLICATION_FOLDER_STEPS_INCOME_PROOF} />
      ) : (
        <AccountBreadcrumbs page={ROUTES.tenantRoutes.account.schufa} />
      )}
      <TenantSchufaForm isApplicationFolderTunnel={isApplicationFolderTunnel} />
    </PageLayout>
  );
};

export default SchufaUploadView;
