import { Grid } from '@mui/material';
import InputNumber from 'component/atoms/formElement/InputNumber';
import InputText from 'component/atoms/formElement/InputText';
import { useTranslation } from 'react-i18next';
import FormSectionTitle from 'component/atoms/FormSectionTitle';
import Spacer, { SPACER_SIZE } from 'component/atoms/Spacer';

const ParkingSlotsInputs = () => {
  const { t: ac } = useTranslation('common', { keyPrefix: 'apartment.cost' });
  const { t } = useTranslation('common');

  return (
    <>
      <Grid item xs={12}>
        <FormSectionTitle title={ac('parkingSpace')} />
      </Grid>
      <Grid item xs={12} md={6}>
        <InputNumber step={1} name={'stpCarport.count'} label={ac('stpCarport.count.label')} max={10} />
      </Grid>
      <Grid item xs={12} md={6}>
        <Spacer mobileSize={SPACER_SIZE.NONE} />
        <InputText
          pattern="[0-9]*"
          type={'number'}
          name={'stpCarport.purchasePrice'}
          label={ac('stpCarport.purchasePrice.label')}
          unit={t('currency')}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <InputNumber step={1} name={'stpDuplex.count'} label={ac('stpDuplex.count.label')} max={10} />
      </Grid>
      <Grid item xs={12} md={6}>
        <Spacer mobileSize={SPACER_SIZE.NONE} />
        <InputText
          pattern="[0-9]*"
          type={'number'}
          name={'stpDuplex.purchasePrice'}
          label={ac('stpDuplex.purchasePrice.label')}
          unit={t('currency')}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <InputNumber step={1} name={'stpOutdoorParking.count'} label={ac('stpOutdoorParking.count.label')} max={10} />
      </Grid>
      <Grid item xs={12} md={6}>
        <Spacer mobileSize={SPACER_SIZE.NONE} />
        <InputText
          pattern="[0-9]*"
          type={'number'}
          name={'stpOutdoorParking.purchasePrice'}
          label={ac('stpOutdoorParking.purchasePrice.label')}
          unit={t('currency')}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <InputNumber step={1} name={'stpGarage.count'} label={ac('stpGarage.count.label')} max={10} />
      </Grid>
      <Grid item xs={12} md={6}>
        <Spacer mobileSize={SPACER_SIZE.NONE} />
        <InputText
          pattern="[0-9]*"
          type={'number'}
          name={'stpGarage.purchasePrice'}
          label={ac('stpGarage.purchasePrice.label')}
          unit={t('currency')}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <InputNumber step={1} name={'stpParkingHouse.count'} label={ac('stpParkingHouse.count.label')} max={10} />
      </Grid>
      <Grid item xs={12} md={6}>
        <Spacer mobileSize={SPACER_SIZE.NONE} />
        <InputText
          pattern="[0-9]*"
          type={'number'}
          name={'stpParkingHouse.purchasePrice'}
          label={ac('stpParkingHouse.purchasePrice.label')}
          unit={t('currency')}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <InputNumber
          step={1}
          name={'stpUndergroundParking.count'}
          label={ac('stpUndergroundParking.count.label')}
          max={10}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Spacer mobileSize={SPACER_SIZE.NONE} />
        <InputText
          pattern="[0-9]*"
          type={'number'}
          name={'stpUndergroundParking.purchasePrice'}
          label={ac('stpUndergroundParking.purchasePrice.label')}
          unit={t('currency')}
        />
      </Grid>
    </>
  );
};

export default ParkingSlotsInputs;
