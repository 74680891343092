import styles from './LoadingAnimation.module.scss';
import { FC } from 'react';

export interface ILoadingAnimationProps {
  color?: 'PRIMARY' | 'DEFAULT';
  size?: 'BIG' | 'DEFAULT';
  fullScreen?: boolean;
}

const LoadingAnimation: FC<ILoadingAnimationProps> = ({ color = 'DEFAULT', size = 'DEFAULT', fullScreen = false }) => {
  return (
    <div className={`${fullScreen ? styles.fullscreen : ''}`}>
      <div className={`${styles.loadingAnimation} ${styles[size]}`}>
        <div className={`${styles.loader} ${styles[color]} ${styles[size]}`} />
        <div className={`${styles.loader} ${styles[color]} ${styles[size]}`} />
        <div className={`${styles.loader} ${styles[color]} ${styles[size]}`} />
        <div className="sr-only">is loading</div>
      </div>
    </div>
  );
};

export default LoadingAnimation;
