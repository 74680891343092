import { FC } from 'react';
import styles from './MediaUploadButton.module.scss';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { faCamcorder } from '@fortawesome/pro-light-svg-icons';
import { UPLOAD_TYPE } from '../DocumentUploadDropZone';
import CTAButton from '../../atoms/Buttons/CTAButton';

const MediaUploadButton: FC<{ text?: string; onDrop: any; multiple?: boolean; uploadType?: UPLOAD_TYPE }> = ({
  text,
  onDrop,
  multiple = false,
  uploadType = UPLOAD_TYPE.APARTMENT_MEDIA,
}) => {
  const { t: c } = useTranslation('common', { keyPrefix: 'component.molecules.MediaUploadButton' });
  const acceptedFileTypes =
    uploadType === UPLOAD_TYPE.INTRODUCTION_VIDEO
      ? {
          'video/mp4': ['.mp4'],
          'video/hevc': ['.mov'],
        }
      : {
          'image/jpeg': ['.jpeg', '.jpg'],
          'image/png': ['.png'],
          'image/webp': ['.webp'],
          'image/heic': ['.heic'],
        };
  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
    multiple,
    accept: acceptedFileTypes,
  });

  return (
    <div {...getRootProps()} className={`${styles.wrapper} ${isDragActive ? styles.activeDrag : ''}`}>
      <input {...getInputProps()} />
      <CTAButton
        mobileNotRounded
        expandMobile={true}
        icon={faCamcorder}
        buttonText={text ? text : c('title')}
        onClick={open}
      />
    </div>
  );
};

export default MediaUploadButton;
