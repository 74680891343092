import styles from './ApplicantInformationHeader.module.scss';
import { faPrint, faTimes } from '@fortawesome/pro-solid-svg-icons';
import RoundIconButton from '../../../atoms/Buttons/RoundIconButton';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import CTAButton, { BUTTON_STYLE } from '../../../atoms/Buttons/CTAButton';
import LandlordRatingButtons from '../../../molecules/LandlordRatingButtons';
import { ITenantProfile, MATCHING_MODE } from '@wohnsinn/ws-ts-lib';

const ApplicantInformationHeader: FC<{
  closeAction?: () => any;
  landlordRating?: MATCHING_MODE;
  tenantProfile: ITenantProfile;
}> = ({ closeAction, landlordRating, tenantProfile }) => {
  const { t } = useTranslation('common');

  const handlePrint = async () => {
    try {
      document.execCommand('print', false, null);
    } catch {
      window.print();
    }
  };

  return (
    <div className={styles.header}>
      <CTAButton
        onClick={handlePrint}
        buttonText={t('print')}
        buttonStyle={BUTTON_STYLE.SECONDARY}
        size={'small'}
        icon={faPrint}
      />
      <>
        {landlordRating ? (
          <LandlordRatingButtons big={false} landlordRating={landlordRating} tenantProfileId={tenantProfile.uid} />
        ) : null}
        <div>
          {closeAction ? <RoundIconButton onClick={closeAction} buttonText={t('close')} icon={faTimes} /> : null}
        </div>
      </>
    </div>
  );
};

export default ApplicantInformationHeader;
