class MapBoxService {
  constructor() {}

  public getLocationSuggestionList = async (searchString: string): Promise<any> => {
    try {
      const mapBoxApiUrl = `https://api.mapbox.com/geocoding/v5/mapbox.places/${searchString}.json`;

      const result = await fetch(
        `${mapBoxApiUrl}?country=DE&language=DE&&types=place,locality,postcode,district,neighborhood&&&access_token=${process.env.REACT_APP_MAP_BOX_TOKEN}`
      );

      const data = await result.json();
      return data?.features;
    } catch (e) {
      console.error('Error on getLocationSuggestionList', e);
    }
  };
}

export default MapBoxService;
