import ModalWrapper, { IModalWrapperButtonProps } from '../ModalWrapper';
import { useTranslation } from 'react-i18next';
import { SUBMIT_BUTTON_MODE } from 'core/enum/submit-button-mode.enum';
import { FormContext } from 'core/context/form.context';
import { useContext, useEffect, useState } from 'react';
import ModalContext from 'core/context/modal.context';
import { wohnsinnServices } from 'App';
import { IApplication } from '@wohnsinn/ws-ts-lib';
import { useForm } from 'react-hook-form';
import { getSubmitIcon } from 'component/atoms/Buttons/CTAButton';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import TextEditor, { TEXT_EDITOR_SIZE } from 'component/atoms/formElement/TextEditor';

const Note_Scheme: any = {
  notes: Joi.string().optional(),
};

const ApplicantNotesModal = () => {
  const { t } = useTranslation('common');
  const [buttonSubmitMode, setButtonSubmitMode] = useState(SUBMIT_BUTTON_MODE.NONE);
  const { applicationService } = wohnsinnServices;
  const { modalData, closeModal } = useContext(ModalContext);
  const application = modalData?.application as IApplication;
  const {
    control,
    watch,
    handleSubmit,
    setValue,
    formState: { isValid },
  } = useForm<{ notes?: string }>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    resolver: joiResolver(Joi.object(Note_Scheme)),
  });

  useEffect(() => {
    if (modalData?.application) {
      setValue('notes', application?.notes);
    }
  }, [modalData]);

  const submitForm = async () => {
    setButtonSubmitMode(SUBMIT_BUTTON_MODE.SUBMITTING);
    const data: Partial<IApplication> = {
      notes: watch('notes'),
    };
    try {
      await applicationService.updateApplication(
        application?.landlordId,
        application?.apartmentId,
        application?.tenantProfile?.uid,
        data
      );
      closeModal();
    } catch (e) {
      setButtonSubmitMode(SUBMIT_BUTTON_MODE.ERROR);
      console.error('Error on invite user modal', e);
    }
  };

  const MODAL_BUTTONS: IModalWrapperButtonProps = {
    primary: {
      form: 'applicationNotesForm',
      icon: getSubmitIcon(buttonSubmitMode),
      buttonText: t('save'),
      spinIcon: buttonSubmitMode === SUBMIT_BUTTON_MODE.SUBMITTING,
      disabled:
        buttonSubmitMode === SUBMIT_BUTTON_MODE.ERROR || buttonSubmitMode === SUBMIT_BUTTON_MODE.SUBMITTING || !isValid,
    },
  };

  return (
    <ModalWrapper
      title={t('component.molecules.modals.applicantNotesModal.title')}
      subtitle={
        application?.organizationId
          ? t('component.molecules.modals.applicantNotesModal.subTitleCompany')
          : t('component.molecules.modals.applicantNotesModal.subTitle')
      }
      buttons={MODAL_BUTTONS}
    >
      <FormContext.Provider value={{ control }}>
        <form
          id={'applicationNotesForm'}
          autoComplete={'off'}
          onSubmit={handleSubmit(submitForm, (error) => console.error(error))}
        >
          <TextEditor hideToolbar={true} name={'notes'} size={TEXT_EDITOR_SIZE.MEDIUM} placeholder={t('notes')} />
        </form>
      </FormContext.Provider>
    </ModalWrapper>
  );
};

export default ApplicantNotesModal;
