import Headline, { HEADLINE_SIZE } from '../typographie/Headline';
import CTAButton, { getSubmitIcon } from '../Buttons/CTAButton';
import styles from './FormHeader.module.scss';
import { FC } from 'react';
import { SUBMIT_BUTTON_MODE } from '../../../core/enum/submit-button-mode.enum';
import Text, { TEXT_COLOR } from '../typographie/Text';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useTranslation } from 'react-i18next';
import BackButton from 'component/atoms/Buttons/BackButton';

const FormHeader: FC<{
  customCtaIcon?: IconProp;
  customOnClick?: () => any;
  customCtaButtonTitle?: string;
  buttonSubmitMode?: SUBMIT_BUTTON_MODE;
  title: string;
  showBackButton?: boolean;
  subtitle?: string;
}> = ({
  buttonSubmitMode,
  title,
  subtitle,
  customCtaButtonTitle,
  customOnClick,
  customCtaIcon,
  showBackButton = false,
}) => {
  const { t } = useTranslation('common');
  return (
    <div style={{ paddingBottom: '2rem', display: 'flex', gap: '2rem', flexDirection: 'column', alignItems: 'start' }}>
      {showBackButton && <BackButton />}
      <div className={styles.formHeader}>
        <div>
          <Headline tag={'span'} size={HEADLINE_SIZE.H2}>
            {title}
          </Headline>{' '}
          <br />
          <br />
          <Text tag={'span'} color={TEXT_COLOR.TEXT_COLOR_ACCENT}>
            {subtitle}
          </Text>
        </div>
        {customOnClick && customCtaButtonTitle ? (
          <CTAButton
            onClick={customOnClick}
            type={'button'}
            rounded={false}
            icon={customCtaIcon ? customCtaIcon : null}
            buttonText={customCtaButtonTitle}
          />
        ) : null}

        {buttonSubmitMode ? (
          <CTAButton
            spinIcon={buttonSubmitMode === SUBMIT_BUTTON_MODE.SUBMITTING}
            icon={getSubmitIcon(buttonSubmitMode)}
            buttonText={t('save')}
            rounded={false}
          />
        ) : null}
      </div>
    </div>
  );
};

export default FormHeader;
