import styles from './DrawerWrapper.module.scss';
import { Dispatch, FC, PropsWithChildren, SetStateAction } from 'react';
import CloseButton from '../../atoms/Buttons/CloseButton';
import Headline from '../../atoms/typographie/Headline';
import { Link } from 'react-router-dom';
const DrawerWrapper: FC<
  PropsWithChildren<{
    onClose: Dispatch<SetStateAction<boolean>>;
    logo?: string;
    title?: string;
    fullScreen?: boolean;
    noTitleMargin?: boolean;
    route?: string;
  }>
> = ({ children, onClose, logo, title, fullScreen = false, noTitleMargin = false, route }) => {
  return (
    <div className={`${styles.wrapper} ${fullScreen ? styles.fullScreen : null}`}>
      <div
        className={`${styles.header} ${fullScreen ? styles.fullScreen : null} ${
          noTitleMargin ? styles.noTitleMargin : ''
        }`}
      >
        <div>
          {title ? <Headline>{title}</Headline> : null}
          {route && logo ? (
            <Link to={route}>
              <img className={styles.logo} src={logo} alt="logo" />
            </Link>
          ) : null}
          {!route && logo ? <img className={styles.logo} src={logo} alt="logo" /> : null}
        </div>
        <CloseButton color={'dark'} onClose={onClose} />
      </div>
      {children}
    </div>
  );
};

export default DrawerWrapper;
