import Joi from 'joi';
import { APARTMENT_TYPE_OF_MARKETING } from '@wohnsinn/ws-ts-lib';

export const APARTMENT_CREATE_FORM_SCHEMA = Joi.object({
  mainInformation: {
    typeOfMarketing: Joi.string().required(),
    typeOfUsage: Joi.any().required(),
    typeOfImmo: Joi.string().min(5).required(),
    address: {
      hasPublishedAddress: Joi.boolean().required(),
      city: Joi.string().required(),
      postalCode: Joi.string().length(5).required(),
      street: Joi.string().required(),
      houseNumber: Joi.string().required(),
    },
  },
  cost: {
    purchasePrice: {
      onRequest: Joi.boolean(),
      value: Joi.when('....mainInformation.typeOfMarketing', {
        is: APARTMENT_TYPE_OF_MARKETING.SALE,
        then: Joi.number().required(),
        otherwise: Joi.any(),
      }),
    },
    coldRent: Joi.when('..mainInformation.typeOfMarketing', {
      is: APARTMENT_TYPE_OF_MARKETING.RENT,
      then: Joi.number().required(),
      otherwise: Joi.any(),
    }),
  },
});
