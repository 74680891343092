import { bestImage, sortedImages, blankImage } from 'utilities/image.js';
import { h, render, Component } from 'preact';
import { assign } from 'utilities/obj.js';
import { elemInDom } from 'utilities/elem.js';
import { getTranslation, defineTranslations } from './translations.js';

defineTranslations('en-US', {
  THUMBNAIL_VIDEO_THUMBNAIL: 'Video Thumbnail',
});

export class Thumbnail extends Component {
  constructor(props) {
    super(props);
    this.initialState = this.state = {
      isLoaded: false,
      isDisplaying: false,
    };

    this.onDisplay = this.props.onDisplay;
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.images !== this.props.images) {
      clearTimeout(this.loadTimeout);

      // clear the cache if we're changing which images to operate on.
      this._sortedImages = null;
      this.loadTimeout = null;
      this.setState({ isLoaded: false, isDisplaying: false });
    }

    if (!this.onDisplay && nextProps.onDisplay) {
      this.onDisplay = nextProps.onDisplay;
    }
  }

  render() {
    const altText =
      this.props.thumbnailAltText !== undefined
        ? this.props.thumbnailAltText
        : this.translate('VIDEO_THUMBNAIL');
    return (
      <div style={this.wrapperStyle()} class="w-css-reset">
        <img
          class="w-css-reset"
          srcset={this.props.images.length > 1 ? this.srcSet() : null}
          src={this.bestSrc()}
          style={this.imgStyle()}
          alt={altText}
          ref={(e) => (this.imgElem = e)}
          aria-hidden={this.props.ariaHidden ? 'true' : null}
        />
      </div>
    );
  }

  translate(key) {
    return getTranslation(this.props.playerLanguage, `THUMBNAIL_${key}`);
  }

  componentDidMount() {
    this.setStateBasedOnImgStatus();
    this.maybeCallOnDisplay(this.initialState);
  }

  componentDidUpdate(prevProps, prevState) {
    this.setStateBasedOnImgStatus();
    this.maybeCallOnDisplay(prevState);
  }

  maybeCallOnDisplay() {
    if (this.onDisplay && this.state.isDisplaying && !this.calledOnDisplay) {
      // There may be components outside the thumbnail that depend on this.
      // For example, we might not want to show controls until the thumbnail
      // is displayed.
      this.calledOnDisplay = true;
      this.onDisplay();
    }
  }

  setStateBasedOnImgStatus() {
    const state = this.state;

    const imgElem = this.imgElem;
    if (!state.isLoaded) {
      if (!imgElem.onload) {
        imgElem.onload = () => {
          if (elemInDom(imgElem)) {
            this.setState({ isLoaded: true });
          }
        };
      }

      if (imgElem.complete) {
        this.setState({ isLoaded: true });
      }
    }

    // If the img has loaded or we've waited 1 second, let's display it either
    // way. If transparent-letterbox is not set, then this will show a black
    // background for the thumbnail. If transparent-letterbox is set, then nothing will
    // show, even though it will technically still be in an "isDisplaying"
    // state--it's just transparent.
    if (!state.isDisplaying && state.isLoaded) {
      this.setState({ isDisplaying: true });
    }
  }

  wrapperStyle() {
    const shouldShow = this.state.isDisplaying && this.props.isVisible;
    return assign({}, this.props.wrapperStyle, {
      display: this.state.isLoaded && !shouldShow ? 'none' : 'block',
    });
  }

  baseStyle() {
    const fitStrategy = this.props.fitStrategy;
    if (fitStrategy === 'cover') {
      return this.coverStyle();
    }
    if (fitStrategy === 'contain') {
      return this.containStyle();
    }
    if (fitStrategy === 'fill') {
      return this.fillStyle();
    }
    if (fitStrategy === 'naturalHeight') {
      return this.naturalHeightStyle();
    }
    if (fitStrategy === 'modernContain') {
      return this.modernContainStyle();
    }
    return this.containStyle();
  }

  imgStyle() {
    const shouldShow = this.state.isDisplaying && this.props.isVisible;
    return assign(this.baseStyle(), {
      // We use position/clip to hide/show the thumbnail until it has loaded
      // because the img may not try to load if it's not visible. But after
      // it's loaded, we use display: none/block to defeat over-aggressive css
      // that might do something like position: static !important.
      clip: shouldShow ? 'auto' : 'rect(0,0,0,0)',
      display: this.state.isLoaded && !shouldShow ? 'none' : 'block',
      borderRadius: `${this.props.playerBorderRadius}px`,
    });
  }

  modernContainStyle() {
    return {
      height: '100%',
      objectFit: 'contain',
      position: 'absolute',
      width: '100%',
      top: 0,
      left: 0,
    };
  }

  containStyle() {
    if (!this.imgElem) {
      // in Firefox, if we style the imgElem with the incorrect aspect ratio
      // (like a default aspect ratio of 16 / 9 that doesn't actually match what
      // the eventual imgElem's natural aspect ratio will be), and then
      // switch the imgElem from display none to block once it loads, Firefox
      // will first quickly show the image at that wrong aspect ratio, even if
      // we've already corrected it. We avoid this by simply not returning any
      // styles here on the first render of the img elem. It will always render
      // a second time, when we setState to make isLoaded true.
      return {
        height: '100%',
        left: 0,
        position: 'absolute',
        top: 0,
        width: '100%',
      };
    }
    const props = this.props;
    const containerWidth = props.videoWidth;
    const containerHeight = props.videoHeight;
    const containerAspect = containerWidth / containerHeight;
    const imageAspect = this.imgElem.naturalWidth / this.imgElem.naturalHeight;

    let newWidth;
    let newHeight;
    if (props.stillSnap === false) {
      newWidth = containerWidth;
      newHeight = containerHeight;
    } else if (containerAspect > imageAspect) {
      newHeight = containerHeight;
      newWidth = Math.round(newHeight * imageAspect);
    } else if (containerAspect <= imageAspect) {
      newWidth = containerWidth;
      newHeight = Math.round(newWidth / imageAspect);
    }

    // Figure out how much border we need to add
    const ySpace = Math.max(0, containerHeight - newHeight);
    const xSpace = Math.max(0, containerWidth - newWidth);
    const yBorder = Math.round(ySpace / 2);
    const xBorder = Math.round(xSpace / 2);
    let topBorder = yBorder;
    let rightBorder = xBorder;
    let bottomBorder = yBorder;
    let leftBorder = xBorder;

    // Don't worry about stretching up to 10px
    if (bottomBorder * 2 <= this.stretchLimit()) {
      bottomBorder = 0;
      topBorder = 0;
      newHeight = containerHeight;
    }
    if (leftBorder * 2 <= this.stretchLimit()) {
      leftBorder = 0;
      rightBorder = 0;
      newWidth = containerWidth;
    }

    // When letterboxing, we show a black background by default. We can use
    const borderColor = this.props.backgroundColor || '#000';
    return {
      borderTop: `${topBorder}px solid ${borderColor}`,
      borderBottom: `${bottomBorder}px solid ${borderColor}`,
      borderLeft: `${leftBorder}px solid ${borderColor}`,
      borderRight: `${rightBorder}px solid ${borderColor}`,
      boxSizing: 'content-box',
      height: `${newHeight}px`,
      left: 0,
      position: 'absolute',
      top: 0,
      webkitTouchCallout: 'none',
      width: `${newWidth}px`,
    };
  }

  coverStyle() {
    // IE11 does not support objectFit. Screw em.
    return {
      height: '100%',
      objectFit: 'cover',
      position: 'absolute',
      width: '100%',
    };
  }

  fillStyle() {
    // Though object-fit will be ignored, this will work in IE1 because the
    // default is to stretch the image.
    return {
      height: '100%',
      objectFit: 'fill',
      position: 'absolute',
      width: '100%',
    };
  }

  naturalHeightStyle() {
    return {
      width: '100%',
      position: 'relative',
    };
  }

  bestSrc() {
    return bestImage(this.props.images, {
      videoWidth: this.props.videoWidth,
      videoHeight: this.props.videoHeight,
    }).url;
  }

  srcSet() {
    let sortedImages = this.sortedImages();

    if (sortedImages.length === 0) {
      sortedImages = [blankImage(this.props.videoWidth, this.props.videoHeight)];
    }

    return sortedImages
      .map((image) => {
        return `${image.url} ${image.width}w`;
      })
      .join(', ');
  }

  sortedImages() {
    if (this._sortedImages) {
      return this._sortedImages;
    }

    this._sortedImages = sortedImages(this.props.images);

    return this._sortedImages;
  }

  stretchLimit() {
    const stretchLimit = this.props.stretchLimit;
    if (stretchLimit != null) {
      return stretchLimit;
    }
    return 10;
  }
}
