import { IImage } from '../../../core/const/images';
import Text from '../../atoms/typographie/Text';
import styles from './CallToActionScreen.module.scss';
import CTAButton from '../../atoms/Buttons/CTAButton';
import { FC } from 'react';
import Headline from '../../atoms/typographie/Headline';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import BouncingHeart from '../../atoms/BouncingHeart';

const CallToActionScreen: FC<{
  buttonText?: string;
  headline?: string;
  text?: string;
  onClick?: any;
  img?: IImage;
  animateImg?: boolean;
  buttonSpinIcon?: boolean;
  buttonIcon?: IconProp;
}> = ({ buttonText, onClick, img, animateImg, headline, text, buttonIcon, buttonSpinIcon }) => {
  return (
    <div className={styles.wrapper}>
      {img ? <BouncingHeart img={img} animate={animateImg} /> : null}
      {headline ? <Headline align={'center'}>{headline}</Headline> : null}
      {text ? <Text align={'center'}>{text}</Text> : null}
      {buttonText && onClick ? (
        <CTAButton
          spinIcon={buttonSpinIcon}
          icon={buttonIcon}
          rounded={false}
          buttonText={buttonText}
          onClick={onClick}
        />
      ) : null}
    </div>
  );
};

export default CallToActionScreen;
