import { ILocationSelection } from '@wohnsinn/ws-ts-lib';

export const COLOGNE: ILocationSelection = {
  center: [6.9598022, 50.9390823],
  geoPoint: {
    lat: 50.9390823,
    lng: 6.9598022,
  },
  place_name: 'Köln, Nordrhein-Westfalen, Deutschland',
  radius: 10,
  id: 'place.41748538',
};

export const LEVERKUSEN: ILocationSelection = {
  center: [6.988119, 51.032474],
  geoPoint: {
    lat: 51.032474,
    lng: 6.988119,
  },
  place_name: 'Leverkusen, Nordrhein-Westfalen, Deutschland',
  radius: 10,
  id: 'place.46155834',
};

export const BONN: ILocationSelection = {
  center: [7.10066, 50.735851],
  geoPoint: {
    lat: 50.735851,
    lng: 7.10066,
  },
  place_name: 'Bonn, Nordrhein-Westfalen, Deutschland',
  radius: 10,
  id: 'place.9594938',
};

export const BERLIN: ILocationSelection = {
  center: [13.3888599, 52.5170365],
  geoPoint: {
    lat: 52.5170365,
    lng: 13.3888599,
  },
  place_name: 'Berlin, Deutschland',
  radius: 10,
  id: 'place.115770',
};
