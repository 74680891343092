import { FC } from 'react';
import styles from './CTACard.module.scss';
import CTAButton, { BUTTON_STYLE } from '../../../atoms/Buttons/CTAButton';
import Headline, { HEADLINE_COLOR, HEADLINE_SIZE } from '../../../atoms/typographie/Headline';
import Text, { TEXT_COLOR, TEXT_TYPE } from '../../../atoms/typographie/Text';
import Spacer, { SPACER_SIZE } from '../../../atoms/Spacer';

export interface ICTACardProps {
  title: string;
  text?: string;
  imgSrc?: string;
  imgAltText?: string;
  ctaText?: string;
  onClick?: any;
  link?: string;
  secondaryCTAText?: string;
  secondaryOnClick?: any;
  href?: string;
  secondaryLink?: string;
  inverted?: boolean;
  alternativeStyle?: boolean;
  headlineSize?: HEADLINE_SIZE;
  textColor?: TEXT_COLOR;
  shrinkButton?: boolean;
  imgHeight?: number;
}

const CTACard: FC<ICTACardProps> = ({
  alternativeStyle,
  inverted,
  title,
  text,
  imgSrc,
  imgAltText,
  ctaText,
  link,
  onClick,
  secondaryCTAText,
  secondaryOnClick,
  secondaryLink,
  href,
  shrinkButton = false,
  headlineSize = HEADLINE_SIZE.H2,
  textColor = TEXT_COLOR.TEXT_COLOR_DARK,
  imgHeight,
}) => {
  return (
    <div
      className={`${styles.ctaCard} ${alternativeStyle ? styles.alternative : ''} ${inverted ? styles.inverted : ''}`}
    >
      {imgSrc ? (
        <img className={styles.image} style={imgHeight ? { height: imgHeight } : null} src={imgSrc} alt={imgAltText} />
      ) : null}

      <div className={styles.textWrapper}>
        <Headline
          tag={'h2'}
          color={inverted ? HEADLINE_COLOR.HEADLINE_COLOR_LIGHT : HEADLINE_COLOR.HEADLINE_COLOR_DARK}
          size={headlineSize}
          align={'center'}
        >
          {title}
        </Headline>

        <Spacer size={SPACER_SIZE.MD} />
        {text ? (
          <Text
            type={TEXT_TYPE.TEXT_TYPE_BODY}
            align={'center'}
            tag={'p'}
            color={inverted ? TEXT_COLOR.TEXT_COLOR_LIGHT : textColor}
          >
            {text}
          </Text>
        ) : null}
      </div>

      <Spacer size={SPACER_SIZE.XS} />
      {(href || link || onClick) && ctaText ? (
        <div className={styles.ctaWrapper}>
          <CTAButton
            rounded={false}
            expand={shrinkButton ? null : 'block'}
            link={link ? link : undefined}
            href={href ? href : undefined}
            onClick={onClick ? onClick : undefined}
            buttonText={ctaText}
            buttonStyle={inverted ? BUTTON_STYLE.SECONDARY : BUTTON_STYLE.PRIMARY}
            color={TEXT_COLOR.TEXT_COLOR_DARK}
          />
          {secondaryCTAText && (secondaryOnClick || secondaryLink) && (
            <CTAButton
              buttonStyle={BUTTON_STYLE.SECONDARY}
              expand={shrinkButton ? null : 'block'}
              link={secondaryLink ? secondaryLink : undefined}
              onClick={secondaryOnClick ? secondaryOnClick : undefined}
              buttonText={secondaryCTAText}
            />
          )}
        </div>
      ) : null}
    </div>
  );
};

export default CTACard;
