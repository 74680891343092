import { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLightbulbOn, faShieldHalved } from '@fortawesome/pro-solid-svg-icons';
import styles from './InfoBox.module.scss';
import Text, { TEXT_TYPE, TEXT_WEIGHT } from '../typographie/Text';
import CTAButton from '../Buttons/CTAButton';

export interface IInfoBox {
  text: string;
  icon?: string;
  bold?: boolean;
  textType?: TEXT_TYPE;
  action?: any;
  size?: 'small' | 'big';
}

export enum INFO_BOX_ICONS {
  LIGHT_BULB = 'LIGHT_BULB',
  SHIELD = 'SHIELD',
}

const getIcon = (icon: string) => {
  switch (icon) {
    case INFO_BOX_ICONS.SHIELD:
      return faShieldHalved;
    case INFO_BOX_ICONS.LIGHT_BULB:
      return faLightbulbOn;
  }
};

const InfoBox: FC<IInfoBox> = ({
  text,
  icon = INFO_BOX_ICONS.LIGHT_BULB,
  textType = TEXT_TYPE.TEXT_TYPE_BODY,
  action,
  size = 'small',
  bold = false,
}) => {
  return (
    <div className={`${styles.infoBox} ${styles[size]}`}>
      <div className={styles.content}>
        <div className={styles.textWrapper}>
          {icon ? <FontAwesomeIcon icon={getIcon(icon)} size={size === 'small' ? '1x' : '2x'} /> : null}
          <Text
            weight={bold ? TEXT_WEIGHT.TEXT_WEIGHT_BOLD : TEXT_WEIGHT.TEXT_WEIGHT_LIGHT}
            type={textType}
            tag={'span'}
          >
            {text}
          </Text>
        </div>
        {action ? <CTAButton onClick={action} expand={'block'} buttonText={'Ich will benachrichtigt werden'} /> : null}
      </div>
    </div>
  );
};

export default InfoBox;
