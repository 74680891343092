// chapters has two ways of being defined in embed options.
// The current way is as an object inside `embedOptions.plugin`
// The old way was as root embed options like `chaptersOn` and `chapterList`.
// This function normalizes the old root embed option format to an object
// like the current model
export const normalizeChapters = (embedOptions) => {
  const { plugin, chaptersOn, chapterList } = embedOptions;

  if (plugin?.chapters) {
    return plugin.chapters;
  }

  if (chapterList || chaptersOn) {
    const list = chapterList && chapterList.length > 0 ? chapterList : [];
    return {
      on: chaptersOn,
      chapterList: list,
    };
  }

  return undefined;
};
