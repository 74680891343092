import HeadlineTextBanner from '../../molecules/HeadlineTextBanner';
import Spacer, { SPACER_SIZE } from '../../atoms/Spacer';
import { Grid } from '@mui/material';
import Image from '../../atoms/Image';
import { IMAGES } from '../../../core/const/images';
import Headline from '../../atoms/typographie/Headline';
import Text, { TEXT_WEIGHT } from '../../atoms/typographie/Text';
import styles from '../../../view/static/SearchView/SearchView.module.scss';
import CTACard from '../../molecules/Cards/CTACard';
import { MODAL_IDENTIFIER } from '../../../core/enum/modals.enum';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import ModalContext from '../../../core/context/modal.context';
import UserContext from '../../../core/context/user.context';

const NoMatchesScreen = () => {
  const { t } = useTranslation('common');
  const { t: l } = useTranslation('common', { keyPrefix: 'view.ApartmentListView' });
  const { openModal } = useContext(ModalContext);
  const { user } = useContext(UserContext);
  return (
    <div>
      <Grid container padding={2} justifyContent={'center'} alignItems={'center'} rowSpacing={{ xs: 8, md: 12 }}>
        <Grid className={styles.cardParent} item xs={12} sm={6} md={6}>
          <div className={styles.ctaCardWrapper}>
            <CTACard
              imgSrc={t('pictogram.alarm')}
              alternativeStyle
              title={'Nix dabei? Keine Sorge! Jeden Tag kommen neue Wohnungen dazu.'}
              text={'Wir sagen dir Bescheid, sobald neue Wohnungen für dich verfügbar sind!'}
              ctaText={user ? null : l('notification.linkText')}
              onClick={
                !user
                  ? () =>
                      openModal({
                        id: MODAL_IDENTIFIER.SIGN_UP_USER,
                        data: {
                          title: 'Erstelle ein Konto, um deine Suche zu speichern',
                          isProbablyTenant: true,
                        },
                      })
                  : undefined
              }
            />
          </div>
        </Grid>
        <Grid item xs={12} paddingTop={{ xs: 4, md: 10 }} paddingBottom={{ xs: 4, md: 10 }}>
          <HeadlineTextBanner tag={'Über uns'} headline={'Wer steckt hinter Wohnsinn'} />
          <Spacer size={SPACER_SIZE.SM} />

          <Grid container justifyContent={'center'}>
            <Grid item xs={12} md={6}>
              <Image image={IMAGES.images.felixChris} rounded />
            </Grid>
          </Grid>
          <Spacer />
          <Spacer />

          <Grid container rowGap={2}>
            <Grid item xs={12} md={4}>
              <Grid container>
                <Grid item xs={2}>
                  <Headline align={'center'}>🚀</Headline>
                </Grid>
                <Grid item xs={10}>
                  <Text>
                    <Text tag={'span'} weight={TEXT_WEIGHT.TEXT_WEIGHT_BOLD}>
                      Startup aus Köln
                    </Text>
                    <br />
                    Wir sind in 2023 an den Start gegangen
                  </Text>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
              <Grid container>
                <Grid item xs={2}>
                  <Headline align={'center'}>✨</Headline>
                </Grid>
                <Grid item xs={10}>
                  <Text>
                    <Text tag={'span'} weight={TEXT_WEIGHT.TEXT_WEIGHT_BOLD}>
                      +2.000 Mieter nutzen Wohnsinn
                    </Text>
                    <br />
                    Jeden Tag kommen neue Suchende dazu
                  </Text>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
              <Grid container>
                <Grid item xs={2}>
                  <Headline align={'center'}>💙</Headline>
                </Grid>
                <Grid item xs={10}>
                  <Text>
                    <Text tag={'span'} weight={TEXT_WEIGHT.TEXT_WEIGHT_BOLD}>
                      Bei uns ist jeder Premium
                    </Text>
                    <br />
                    Bei uns gibt es keine teuren Abofallen
                  </Text>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default NoMatchesScreen;
