import { useContext } from 'react';
import SnackBarContext from 'core/context/snackbar.context';

const useFormErrorHook = (formName: any): { handleError: (e: any) => void } => {
  const { handleSnackBar } = useContext(SnackBarContext);

  const handleError = (e: any) => {
    console.error(`Error on ${formName}`, e);
    handleSnackBar('toast.error.form', 'error');
  };
  return { handleError };
};

export default useFormErrorHook;
