import { useCallback, useEffect, useState } from 'react';

export type TWindowSize = null | {
  width: number;
  height: number;
  isSmallerSm: boolean;
  isSmallerLg: boolean;
  isSmallerMd: boolean;
  isGreaterMd: boolean;
  isGreaterLg: boolean;
};

export default function useWindowSize() {
  const hasWindow = typeof window !== 'undefined';

  const getWindowDimensions = useCallback(() => {
    const width = hasWindow ? window.innerWidth : 0;
    const height = hasWindow ? window.innerHeight : 0;
    const isSmallerSm = width < 576;
    const isSmallerLg = width <= 992;
    const isSmallerMd = width < 768;
    const isGreaterMd = width > 767;
    const isGreaterLg = width > 992;
    return {
      width,
      height,
      isSmallerSm,
      isSmallerLg,
      isSmallerMd,
      isGreaterMd,
      isGreaterLg,
    };
  }, [window.innerWidth, window.innerHeight]);

  const [windowHeight, setWindowHeight] = useState<string>(window.innerHeight + 'px');
  const [windowDimensions, setWindowDimensions] = useState<TWindowSize>(getWindowDimensions());

  useEffect(() => {
    if (window) {
      setWindowHeight(window.innerHeight + 'px');
      const handleResize = () => {
        setWindowDimensions(getWindowDimensions());
      };

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
  }, [hasWindow, getWindowDimensions]);

  return {
    height: windowDimensions.height,
    width: windowDimensions.width,
    isSmallerSm: windowDimensions.isSmallerSm,
    isSmallerLg: windowDimensions.isSmallerLg,
    isSmallerMd: windowDimensions.isSmallerMd,
    isGreaterMd: windowDimensions.isGreaterMd,
    isGreaterLg: windowDimensions.isGreaterLg,
    windowHeight,
  };
}
