import styles from './BurgerMenu.module.scss';
import Avatar, { AVATAR_SIZE } from '../../../atoms/Avatar';
import { REGISTRATION_STATES, USER_TYPE } from '@wohnsinn/ws-ts-lib';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/pro-solid-svg-icons';
import UserContext from '../../../../core/context/user.context';
import { FC, useContext, useState } from 'react';
import { Drawer } from '@mui/material';
import DrawerMenu from '../../DrawerMenu';
import { IMenuItemProps } from '../../../../core/const/menus';

const BurgerMenu: FC<{ menu: IMenuItemProps[] }> = ({ menu }) => {
  const { activeUserType, landlordProfile, tenantProfile, user } = useContext(UserContext);
  const [showDrawer, setShowDrawer] = useState<boolean>(false);

  const toggleDrawer = () => {
    setShowDrawer(!showDrawer);
  };

  if (user && user?.registrationState !== REGISTRATION_STATES.FINISHED) {
    return null;
  }

  return (
    <button onClick={toggleDrawer} className={`${styles.avatar} ${user ? styles.withImg : ''}`}>
      {menu?.some((link) => link.notifications > 0) ? <div className={styles.notification} /> : null}
      {user ? (
        <Avatar
          size={AVATAR_SIZE.sm}
          avatarUrl={activeUserType === USER_TYPE.LANDLORD ? landlordProfile?.photoUrl : tenantProfile?.photoUrl}
        />
      ) : null}
      <FontAwesomeIcon icon={faBars} />
      <Drawer anchor={'right'} open={showDrawer} onClose={() => setShowDrawer(false)}>
        <DrawerMenu menu={menu} onClose={() => setShowDrawer(false)} />
      </Drawer>
    </button>
  );
};

export default BurgerMenu;
