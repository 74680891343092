import { createContext } from 'react';
import { IUserWithRoles, USER_TYPE, ILandlordProfile, ITenantProfile } from '@wohnsinn/ws-ts-lib';

const UserContext = createContext<{
  activeUserType: USER_TYPE;
  landlordProfile: ILandlordProfile | null;
  tenantProfile: ITenantProfile | null;
  user: IUserWithRoles | null;
}>({
  activeUserType: USER_TYPE.NONE,
  landlordProfile: null,
  tenantProfile: null,
  user: null,
});

export default UserContext;
