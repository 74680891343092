import { FC } from 'react';

import { ROUTES } from 'core/const/routes';
import PageLayout from 'component/layouts/PageLayout';
import ApartmentMediaForm from 'component/forms/apartment/ApartmentMediaForm';
import { ICreateApartmentTunnel } from 'view/landlord/apartment/ApartmentCreateView';
import ProgressBar from 'component/atoms/ProgressBar';
import { useTranslation } from 'react-i18next';
import useWindowSize from 'core/hook/windowsize.hook';

const ApartmentMediaFormView: FC<ICreateApartmentTunnel> = ({ isTunnelView, progress = 10 }) => {
  const { t: r } = useTranslation('routes');
  const { isSmallerMd } = useWindowSize();

  return (
    <PageLayout
      showPageTitle={false}
      pageTitle={r(ROUTES.landlordRoutes.apartment.mainInformation.title)}
      showBackButton={isSmallerMd && !isTunnelView}
      hideMenu={isTunnelView}
    >
      {isTunnelView && <ProgressBar progress={progress} />}

      <ApartmentMediaForm isTunnelView={isTunnelView} />
    </PageLayout>
  );
};

export default ApartmentMediaFormView;
