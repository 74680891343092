export const initial = {
  x: 0,
  y: 0,
  scale: 1,
  rotate: 0,
};

export const animateToLeft = {
  x: '-200%',
  y: '0%',
  scale: 0.3,
  rotate: -70,
  transition: { duration: 0.7, delay: 0 },
};

export const animateToRight = {
  x: '200%',
  y: '0%',
  scale: 0.01,
  rotate: +70,
  transition: { duration: 0.7, delay: 0 },
};
