import { Swiper, SwiperSlide } from 'swiper/react';
import styles from './HowToSlides.module.scss';
import HowToSlideLike from './HowToSlideLike';
import HowToSlideApply from './HowToSlideApply';
import HowToSlideNope from './HowToSlideNope';
import HowToSlideFinish from './HowToSlideFinish';
import HowToSlideIntro from './HowToSlideIntro';

const HowToSlides = () => {
  return (
    <div className={styles.wrapper}>
      <Swiper
        onSlideChange={(swiper) => (swiper.allowSlideNext = false)}
        className={styles.swiper}
        allowSlideNext={false}
        noSwiping={true}
        draggable={false}
      >
        <SwiperSlide>
          <HowToSlideIntro />
        </SwiperSlide>
        <SwiperSlide>
          <HowToSlideLike />
        </SwiperSlide>
        <SwiperSlide>
          <HowToSlideNope />
        </SwiperSlide>
        <SwiperSlide>
          <HowToSlideApply />
        </SwiperSlide>
        <SwiperSlide>
          <HowToSlideFinish />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default HowToSlides;
