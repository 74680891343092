import LOCAL_STORAGE_KEYS from '../enum/local-storage.enum';
import { useState } from 'react';
import { MODAL_IDENTIFIER } from '../enum/modals.enum';

export interface IOpenModalProps {
  id: MODAL_IDENTIFIER;
  onDismiss?: () => void;
  allowBackdropDismiss?: boolean;
  data?: any;
}

const useModal = (): {
  closeModal: () => void;
  openModal: (props: IOpenModalProps) => void;
  modalData: any;
  currentModalId: MODAL_IDENTIFIER;
  onDismiss: () => void;
  allowBackdropDismiss: boolean;
} => {
  const [currentModalId, setCurrentModalId] = useState<MODAL_IDENTIFIER>(
    localStorage.getItem(LOCAL_STORAGE_KEYS.CURRENT_MODAL) as MODAL_IDENTIFIER
  );
  const [modalData, setModalData] = useState<any>(null);
  const [allowBackdropDismiss, setAllowBackdropDismiss] = useState<boolean>(null);
  const [onDismiss, setOnDismiss] = useState<() => void>(null);

  const openModal = async ({
    id = null,
    onDismiss = null,
    data = null,
    allowBackdropDismiss = true,
  }: IOpenModalProps) => {
    setOnDismiss(onDismiss);
    setAllowBackdropDismiss(allowBackdropDismiss);
    if (currentModalId) {
      closeModal();
    }
    setCurrentModalId(id);
    setModalData(data);
    localStorage.setItem(LOCAL_STORAGE_KEYS.CURRENT_MODAL, id);
  };

  const closeModal = () => {
    setCurrentModalId(null);
    setOnDismiss(null);
    setModalData(null);
    setAllowBackdropDismiss(true);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.CURRENT_MODAL);
  };

  return { openModal, closeModal, currentModalId, modalData, onDismiss, allowBackdropDismiss };
};

export default useModal;
