import { FC } from 'react';
import { IOrganization } from '@wohnsinn/ws-ts-lib';
import styles from './OrganizationInformation.module.scss';
import Text, { TEXT_COLOR } from '../typographie/Text';
import { useTranslation } from 'react-i18next';
import Badge, { BADGE_COLORS } from 'component/atoms/Badge';
import SplitLine from '../SplitLine';

const OrganizationInformation: FC<{ organization: IOrganization }> = ({ organization }) => {
  const { t } = useTranslation('common');

  return (
    <div className={styles.wrapper}>
      {organization ? (
        <>
          {organization?.photoUrl && (
            <img className={styles.image} src={organization.photoUrl} alt={`${organization?.title} - Logo`} />
          )}
          {organization?.title && <Text tag={'span'}>{organization.title}</Text>} <br />
          {organization.address ? (
            <>
              <Text tag={'span'}>
                {organization.address.street ?? organization.address.street}{' '}
                {organization.address.houseNumber ?? organization.address.houseNumber}
              </Text>
              <br />
              <Text tag={'span'}>
                {organization.address.postalCode ?? organization.address.postalCode}{' '}
                {organization.address.city ?? organization.address.city}
              </Text>
            </>
          ) : null}
          <SplitLine size={'small'} />
          {organization?.website && (
            <a href={organization.website}>
              <Text color={TEXT_COLOR.TEXT_COLOR_PRIMARY} tag={'span'}>
                {t('website')}
              </Text>
            </a>
          )}
          {organization?.imprintLink && (
            <a href={organization.imprintLink} target={'_blank'} rel="noreferrer">
              <br />
              <Text color={TEXT_COLOR.TEXT_COLOR_PRIMARY} tag={'span'}>
                {t('imprint')}
              </Text>
            </a>
          )}
          {organization?.dataProtectionLink && (
            <a href={organization.dataProtectionLink} target={'_blank'} rel="noreferrer">
              <br />
              <Text color={TEXT_COLOR.TEXT_COLOR_PRIMARY} tag={'span'}>
                {t('dataProtection')}
              </Text>
            </a>
          )}
        </>
      ) : (
        <Badge color={BADGE_COLORS.PRIMARY} text={t('privateLandlord')} />
      )}
    </div>
  );
};

export default OrganizationInformation;
