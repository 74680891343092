import { FC, PropsWithChildren } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { BREAKPOINTS } from 'core/const/breakpoints';
import LPHeader from 'component/layouts/LandingPageLayout/LPHeader';
import Footer from 'component/molecules/Footer';

const LandingPageLayout: FC<PropsWithChildren<{ pageTitle: string; onlyChildren?: boolean }>> = ({
  children,
  pageTitle,
  onlyChildren,
}) => {
  const { t } = useTranslation('common');

  return (
    <div>
      <Helmet>
        <title>{`${t('appTitle')} - ${t(pageTitle)}`}</title>
      </Helmet>

      <LPHeader />

      <main>
        {onlyChildren ? (
          children
        ) : (
          <Grid container px={3} marginX={'auto'} maxWidth={{ lg: BREAKPOINTS.xl }}>
            {children}
          </Grid>
        )}
      </main>

      <Footer />
    </div>
  );
};

export default LandingPageLayout;
