import { Functions, httpsCallableFromURL, HttpsCallableResult } from 'firebase/functions';

export class FirebaseFunctionsService {
  constructor(private functions: Functions) {}

  public callFbFunction<REQ = any, RES = any>(functionUrl: string, params: REQ): Promise<HttpsCallableResult<RES>> {
    const executableFn = httpsCallableFromURL<REQ, RES>(this.functions, functionUrl);

    return executableFn(params);
  }
}
