import { toSeconds, parse } from 'iso8601-duration';
import { isNil, isNumber } from '@wistia/type-guards';

// flexibleDuration is a duration format that uses as
// few digits as possible to express that duration
export const flexibleDuration = (total) => {
  const { hours, minutes, seconds } = secondsConverter(total, 'hms');

  if (hours === 0) {
    return `${minutes}:${padNumber(seconds, 2)}`;
  }
  return `${hours}:${padNumber(minutes, 2)}:${padNumber(seconds, 2)}`;
};

export const formattedDurationToSeconds = (dur) => {
  if (isNil(dur) || isNumber(dur)) {
    return dur;
  }

  try {
    const parsed = parse(dur.toUpperCase());
    return toSeconds(parsed);
  } catch (e) {
    /* empty, we want to swallow and continue */
  }

  // We allow for non-standard IS0 6801 Duration strings, eg. 1H30M,
  // the parsing lib can do that if prefixed with the correct ISO Duration prefixes
  try {
    const parsed = parse(`PT${dur.toUpperCase()}`);
    return toSeconds(parsed);
  } catch (e) {
    /* empty, we want to swallow and continue */
  }

  return dur;
};

export const accessibilityDuration = (duration) => {
  const { hours, minutes, seconds } = secondsConverter(duration, 'hms');
  const zeroHours = hours === 0;
  const zeroMinutes = minutes === 0;
  if (zeroHours && zeroMinutes) {
    return `${seconds} second${seconds === 1 ? '' : 's'}`;
  }

  const hoursText = zeroHours ? '' : `${hours} hour${hours === 1 ? '' : 's'}`;
  const minText = zeroMinutes ? '' : `${minutes} minute${hours === 1 ? '' : 's'}`;

  return `${hoursText} ${minText}`.trim();
};

export const humanReadableDuration = (duration) => {
  const { hours, minutes, seconds } = secondsConverter(duration, 'hms');
  const zeroHours = hours === 0;
  const zeroMinutes = minutes === 0;
  if (zeroHours && zeroMinutes) return `${seconds} Sec`;

  const hoursText = zeroHours ? '' : `${hours} Hr`;
  const minText = zeroMinutes ? '' : `${minutes} Min`;
  return `${hoursText} ${minText}`.trim();
};

export const padNumber = (num, length = 0) => {
  let result = `${num}`;
  while (result.length < length) {
    result = `0${result}`;
  }
  return result;
};

export const secondsConverter = (total, format) => {
  let hours = 0;
  let minutes = 0;
  let seconds = 0;

  const useHours = /h/.test(format);
  const useMinutes = /m/.test(format);

  if (useHours && total > 0) {
    hours += Math.floor(total / 3600);
    total %= 3600;
  }

  if (useMinutes && total > 0) {
    minutes += Math.floor(total / 60);
    total %= 60;
  }

  seconds = Math.round(total);

  if (useHours && minutes === 60) {
    hours += 1;
    minutes = 0;
  }

  if (useMinutes && seconds === 60) {
    minutes += 1;
    seconds = 0;
  }

  return {
    hours,
    minutes,
    seconds,
  };
};

export const getSecondsRemaining = (start, end) => {
  const remaining = end.getTime() - start.getTime();
  return remaining / 1000;
};
