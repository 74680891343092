import { ROUTES } from 'core/const/routes';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import LandingPageLayout from '../../../component/layouts/LandingPageLayout';
import ContactSection from 'component/organisms/landing-page-sections/ContactSection';

const ContactView = () => {
  const { t: r } = useTranslation('routes');
  return (
    <LandingPageLayout pageTitle={r(ROUTES.landingPageRoutes.contact.title)}>
      <Grid container className={'ws-full-width-bg ws-full-width-bg--white'}>
        <Grid item xs={12} paddingTop={{ xs: 4, md: 10 }} paddingBottom={{ xs: 4, md: 10 }}>
          <ContactSection headline={'Kontakt'} />
        </Grid>
      </Grid>
    </LandingPageLayout>
  );
};

export default ContactView;
