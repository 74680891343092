import { FC } from 'react';
import { IApartmentArea } from '@wohnsinn/ws-ts-lib';
import ItemList, { TItemListItem } from '../ItemList';
import { useTranslation } from 'react-i18next';
import Headline, { HEADLINE_SIZE } from 'component/atoms/typographie/Headline';
import styles from 'component/organisms/ApartmentInformation/ApartmentInformation.module.scss';

const ApartmentRooms: FC<{ areas: IApartmentArea }> = ({ areas }) => {
  const { t: a } = useTranslation('common', { keyPrefix: 'apartment.areas' });
  const { t } = useTranslation('common');

  const items: TItemListItem[] = [];

  if (areas?.numberOfRooms) {
    items.push({
      key: 'numberOfRooms,',
      label: a('numberOfRooms.label'),
      value: areas?.numberOfRooms,
    });
  }

  if (areas?.numberOfBedrooms) {
    items.push({
      key: 'numberOfBedrooms,',
      label: a('numberOfBedrooms.label'),
      value: areas?.numberOfBedrooms,
    });
  }

  if (areas?.numberOfBathrooms) {
    items.push({
      key: 'numberOfBathrooms,',
      label: a('numberOfBathrooms.label'),
      value: areas?.numberOfBathrooms,
    });
  }

  if (areas?.numberOfSeparateToilets) {
    items.push({
      key: 'numberOfSeparateToilets,',
      label: a('numberOfSeparateToilets.label'),
      value: areas?.numberOfSeparateToilets,
    });
  }
  if (areas?.numberOfBalconies) {
    items.push({
      key: 'numberOfBalconies,',
      label: a('numberOfBalconies.label'),
      value: areas?.numberOfBalconies,
    });
  }
  if (areas?.numberOfTerraces) {
    items.push({
      key: 'numberOfTerraces,',
      label: a('numberOfTerraces.label'),
      value: areas?.numberOfTerraces,
    });
  }
  if (areas?.numberOfLoggias) {
    items.push({
      key: 'numberOfLoggias,',
      label: a('numberOfLoggias.label'),
      value: areas?.numberOfLoggias,
    });
  }

  if (areas?.numberOfLivingBedrooms) {
    items.push({
      key: 'numberOfLivingBedrooms,',
      label: a('numberOfLivingBedrooms.label'),
      value: areas?.numberOfLivingBedrooms,
    });
  }
  if (areas?.numberOfConferenceRooms) {
    items.push({
      key: 'numberOfConferenceRooms,',
      label: a('numberOfConferenceRooms.label'),
      value: areas?.numberOfConferenceRooms,
    });
  }
  if (areas?.numberOfResidentialUnits) {
    items.push({
      key: 'numberOfResidentialUnits,',
      label: a('numberOfResidentialUnits.label'),
      value: areas?.numberOfResidentialUnits,
    });
  }
  if (areas?.numberOfCommercialUnits) {
    items.push({
      key: 'numberOfCommercialUnits,',
      label: a('numberOfCommercialUnits.label'),
      value: areas?.numberOfCommercialUnits,
    });
  }
  if (areas?.seatsInDiningArea) {
    items.push({
      key: 'seatsInDiningArea,',
      label: a('seatsInDiningArea.label'),
      value: areas?.seatsInDiningArea,
    });
  }
  if (areas?.grz) {
    items.push({
      key: 'grz,',
      label: a('grz.label'),
      value: areas?.grz,
    });
  }
  if (areas?.gfz) {
    items.push({
      key: 'gfz,',
      label: a('gfz.label'),
      value: areas?.gfz,
    });
  }
  if (areas?.bmz) {
    items.push({
      key: 'bmz,',
      label: a('bmz.label'),
      value: areas?.bmz,
    });
  }
  if (areas?.bgf) {
    items.push({
      key: 'bgf,',
      label: a('bgf.label'),
      value: areas?.bgf,
    });
  }

  if (items?.length) {
    return (
      <div className={styles.informationSection}>
        <Headline size={HEADLINE_SIZE.H3} tag={'span'}>
          {t('apartment.areas.rooms')}
        </Headline>
        <ItemList items={items} />
      </div>
    );
  }
};

export default ApartmentRooms;
