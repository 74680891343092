import styles from '../HowToSlides.module.scss';
import HowToSlideWrapper from '../HowToSlideWrapper';
import CTAButton from '../../../atoms/Buttons/CTAButton';
import { useContext } from 'react';
import ModalContext from '../../../../core/context/modal.context';
import { useTranslation } from 'react-i18next';
import LOCAL_STORAGE_KEYS from '../../../../core/enum/local-storage.enum';
import { useSwiper } from 'swiper/react';

const HowToSlideFinish = () => {
  const swiper = useSwiper();
  swiper.allowSlidePrev = false;

  const { closeModal } = useContext(ModalContext);
  const { t } = useTranslation('common', { keyPrefix: 'component.organisms.HowToSlides.finishSlide' });

  const handleFinishSlides = () => {
    localStorage.setItem(LOCAL_STORAGE_KEYS.USER_HAS_SEEN_HOW_TO_SLIDES, 'true');
    closeModal();
  };

  return (
    <HowToSlideWrapper title={t('title')} subtitle={t('text')}>
      <div className={styles.applyWrapper}>
        <img className={styles.image} src={t('image')} alt="how-to-apply" />
        <div className={styles.button}>
          <CTAButton expand={'full'} onClick={handleFinishSlides} buttonText={t('cta')} />
        </div>
      </div>
    </HowToSlideWrapper>
  );
};

export default HowToSlideFinish;
