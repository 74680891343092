import { FC, useEffect } from 'react';
import { Grid } from '@mui/material';
import CallToActionScreen from '../../../molecules/CallToActionScreen';
import { IMAGES } from '../../../../core/const/images';
import { useNavigate } from 'react-router-dom';

const FinishedTunnelRedirectScreen: FC<{
  redirectUrl?: string;
  headline?: string;
  hideText?: boolean;
  onFinish?: () => any;
}> = ({ redirectUrl, onFinish, headline, hideText = false }) => {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(async () => {
      onFinish ? onFinish() : null;
      navigate(redirectUrl ?? '/');
    }, 3000);
  }, []);

  return (
    <Grid container maxWidth={null} marginX={'auto'} paddingTop={{ xs: 6, md: 8 }}>
      <Grid item xs={12}>
        <CallToActionScreen
          img={IMAGES.icons.heart}
          animateImg={true}
          headline={headline ?? '👏 Du hast es geschafft!'}
          text={hideText ? null : 'Du bist nun bereit bei Wohnsinn durchzustarten!'}
        />
      </Grid>
    </Grid>
  );
};

export default FinishedTunnelRedirectScreen;
