import Joi from 'joi';
import { IApartmentCost, RENT_TYPE } from '@wohnsinn/ws-ts-lib';

export type SchemaOf<T> = {
  [P in keyof T]: T[P] extends object ? Joi.ObjectSchema<SchemaOf<T[P]>> : Joi.Schema;
};

const ParkingSchema = Joi.object({
  rentPrice: Joi.number().allow(''),
  purchasePrice: Joi.number().allow(''),
  count: Joi.number().allow(''),
});

const PurchasePriceSchema = Joi.object({
  value: Joi.number().allow(''),
  onRequest: Joi.boolean(),
});

export const APARTMENT_COST_FORM_SCHEMA = <SchemaOf<IApartmentCost>>{
  additionalCosts: Joi.number().allow(''),
  brokerageNote: Joi.string().allow(''),
  coldRent: Joi.number().required(),
  commissionGross: Joi.number().allow(''),
  deposit: Joi.string().allow(''),
  depositText: Joi.string().allow(''),
  developmentCosts: Joi.number().allow(''),
  flatRateRent: Joi.number().allow(''),
  freeTextPrice: Joi.string().allow(''),
  heatingCosts: Joi.number().allow(''),
  houseMoney: Joi.number().optional().allow(''),
  indemnity: Joi.number().allow(''),
  isCommissionMandatory: Joi.boolean().allow(''),
  isHeatingIncluded: Joi.boolean().allow(''),
  leasehold: Joi.number().allow(''),
  operationalCosts: Joi.number().allow(''),
  purchasePrice: PurchasePriceSchema,
  purchasePricePerSqM: Joi.number().allow(''),
  rentPricePerSqM: Joi.number().allow(''),
  rentType: Joi.valid(...Object.keys(RENT_TYPE)),
  targetPricePerSqM: Joi.number().allow(''),
  totalVAT: Joi.number().allow(''),
  vatRate: Joi.number().allow(''),
  warmRent: Joi.number().allow(''),
  coldRentNet: Joi.number().allow(''),
  stpOutdoorParking: ParkingSchema,
  outsideCommission: Joi.any().allow(''),
  stpGarage: ParkingSchema,
  stpCarport: ParkingSchema,
  stpDuplex: ParkingSchema,
  /**Freiplatz*/
  stpParkingHouse: ParkingSchema,
  stpUndergroundParking: ParkingSchema,
  plusVAT: Joi.boolean(),
  rentSurcharges: Joi.number().allow(''),
};
