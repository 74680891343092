import Joi from 'joi';
import OptionList, { IOptionListItem } from 'component/atoms/formElement/OptionList';
import TenantFilterParamsContext from 'core/context/tenant-filter-params.context';
import { SEARCH_TUNNEL_STEPS } from 'view/static/SearchTunnelView';
import { SUBMIT_BUTTON_MODE } from 'core/enum/submit-button-mode.enum';
import { SearchTunnelStepContext } from 'core/context/search-tunnel-step.context';
import { joiResolver } from '@hookform/resolvers/joi';
import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import SearchTunnelStepLayout from '../SearchTunnelStepLayout';
import { BONN, COLOGNE, LEVERKUSEN } from '../../../../core/const/city-locations';

const SELECT_LOCATION_FORM_ID = 'SELECT_LOCATION_FORM_ID';
const SelectCityStep = () => {
  const { updateTenantFilterParams } = useContext(TenantFilterParamsContext);
  const { setCurrentSearchTunnelStep } = useContext(SearchTunnelStepContext);
  const { t: s } = useTranslation('common', { keyPrefix: 'component.organisms.searchTunnel.selectCityStep' });
  const { t: i } = useTranslation('images');
  const [buttonSubmitMode, setButtonSubmitMode] = useState<SUBMIT_BUTTON_MODE>(SUBMIT_BUTTON_MODE.NONE);

  const { control, handleSubmit, watch, formState } = useForm<{
    location: 'LEVERKUSEN' | 'BONN' | 'COLOGNE' | 'OTHER';
  }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: joiResolver(Joi.object({ location: Joi.any().required() })),
  });
  const location = watch('location');

  const searchLocationOptions: IOptionListItem[] = [
    {
      heading: s('options.cologne.heading'),
      image: i('images.cityCologne.src'),
      imageAlt: i('images.cityCologne.alt'),
      value: 'COLOGNE',
    },
    {
      heading: s('options.leverkusen.heading'),
      image: i('images.cityLeverkusen.src'),
      imageAlt: i('images.cityLeverkusen.alt'),
      value: 'LEVERKUSEN',
    },
    {
      heading: s('options.bonn.heading'),
      image: i('images.cityBonn.src'),
      imageAlt: i('images.cityBonn.alt'),
      value: 'BONN',
    },
    {
      heading: s('options.other.heading'),
      image: i('images.cityOther.src'),
      imageAlt: i('images.cityOther.alt'),
      value: 'OTHER',
    },
  ];

  const handleFormSubmit = () => {
    setButtonSubmitMode(SUBMIT_BUTTON_MODE.SUBMITTING);
    switch (location) {
      case 'COLOGNE':
        updateTenantFilterParams({ location: COLOGNE });
        break;
      case 'LEVERKUSEN':
        updateTenantFilterParams({ location: LEVERKUSEN });
        break;
      case 'BONN':
        updateTenantFilterParams({ location: BONN });
        break;
    }
    setCurrentSearchTunnelStep(SEARCH_TUNNEL_STEPS.SELECT_LOCATION);
  };

  return (
    <SearchTunnelStepLayout
      formId={SELECT_LOCATION_FORM_ID}
      control={control}
      disabled={buttonSubmitMode === SUBMIT_BUTTON_MODE.SUBMITTING || !formState.isValid}
      buttonSubmitMode={buttonSubmitMode}
      onSubmit={handleSubmit(handleFormSubmit, console.error)}
      title={s('title')}
    >
      <OptionList twoColumns optionsList={searchLocationOptions} name={'location'} />
    </SearchTunnelStepLayout>
  );
};

export default SelectCityStep;
