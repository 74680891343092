import { Grid } from '@mui/material';
import { IMAGES } from 'core/const/images';

const KnownFromSection = () => {
  return (
    <Grid container spacing={2} alignItems={'center'} justifyContent={'center'} paddingTop={{ xs: 2, md: 4 }}>
      {COMMERCIAL_LOGOS.map((logo) => (
        <Grid key={logo.alt} item xs={4} md={2.4}>
          <img src={logo.src} alt={logo.alt} />
        </Grid>
      ))}
    </Grid>
  );
};

const COMMERCIAL_LOGOS = [
  IMAGES.logos.googleForStartups,
  IMAGES.logos.handelsblatt,
  IMAGES.logos.hug,
  IMAGES.logos.group,
  IMAGES.logos.nrw,
];

export default KnownFromSection;
