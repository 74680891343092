import styles from './ApplicantChatHeader.module.scss';
import Avatar, { AVATAR_SIZE } from 'component/atoms/Avatar';
import Headline, { HEADLINE_SIZE } from 'component/atoms/typographie/Headline';
import RoundIconButton from 'component/atoms/Buttons/RoundIconButton';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FC } from 'react';
import { IApplication } from '@wohnsinn/ws-ts-lib';
import { useTranslation } from 'react-i18next';

const ApplicantChatHeader: FC<{ application: IApplication; onClose: () => void }> = ({ application, onClose }) => {
  const { t } = useTranslation('common');

  return (
    <div className={styles.header}>
      <div className={styles.profile}>
        <Avatar size={AVATAR_SIZE.md} avatarUrl={application.tenantProfile.photoUrl} />
        <span>
          {application.tenantProfile?.personalInformation?.firstName &&
          application.tenantProfile?.personalInformation?.lastName ? (
            <Headline size={HEADLINE_SIZE.H3} tag={'span'}>
              {application.tenantProfile?.personalInformation?.firstName}{' '}
              {application.tenantProfile?.personalInformation?.lastName}
            </Headline>
          ) : null}
        </span>
      </div>
      <div>
        <RoundIconButton onClick={onClose} buttonText={t('close')} icon={faTimes} />
      </div>
    </div>
  );
};

export default ApplicantChatHeader;
