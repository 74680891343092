import FormSectionTitle from 'component/atoms/FormSectionTitle';
import TextInput from 'component/atoms/formElement/InputText';
import InputOptionList, { RADIO_INPUT_COLUMNS_COUNT } from 'component/atoms/formElement/InputOptionList';
import { BOOLEAN_SELECT_OPTIONS, getTranslatedOptions } from 'core/const/select-options';
import { FormContext } from 'core/context/form.context';
import { SUBMIT_BUTTON_MODE } from 'core/enum/submit-button-mode.enum';
import UserContext from 'core/context/user.context';
import { DEFAULT_HOUSEHOLD_DATA } from 'view/tenant/account/HouseholdView';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { IHousehold, HOUSEHOLD_SCHEMA } from '@wohnsinn/ws-ts-lib';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { FC, useContext, useEffect, useState } from 'react';
import { ITenantFormProps } from '../TenantProfileForm';
import PageTitle from 'component/molecules/PageTitle';
import { ROUTES } from 'core/const/routes';
import { TENANT_PETS_SELECT_OPTION_LIST } from '../../apartment/ApartmentDesiredTenantForm/desired-tenant-form-select-options';
import { wohnsinnServices } from 'App';
import { Grid } from '@mui/material';
import SnackBarContext from 'core/context/snackbar.context';
import InfoText from 'component/molecules/InfoText';
import InfoTextContent from '../InfoTextContent';
import { useNavigate } from 'react-router-dom';
import FormLayout from 'component/organisms/FormLayout';

const TenantHouseHoldForm: FC<ITenantFormProps> = ({ isApplicationFolderTunnel }) => {
  const { tenantService } = wohnsinnServices;
  const { tenantProfile } = useContext(UserContext);
  const { handleSnackBar } = useContext(SnackBarContext);
  const { t } = useTranslation('common');
  const { t: r } = useTranslation('routes');
  const { t: h } = useTranslation('common', { keyPrefix: 'view.AccountView.HouseholdView' });
  const [buttonSubmitMode, setButtonSubmitMode] = useState<SUBMIT_BUTTON_MODE>(SUBMIT_BUTTON_MODE.NONE);
  const navigate = useNavigate();

  const { control, watch, handleSubmit, setValue } = useForm<IHousehold>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    resolver: joiResolver(Joi.object(HOUSEHOLD_SCHEMA)),
    defaultValues: tenantProfile?.household ? tenantProfile?.household : DEFAULT_HOUSEHOLD_DATA,
  });
  const numberOfPeopleMovingIn = watch('numberOfPeopleMovingIn');
  const numberOfChildrenMovingIn = watch('numberOfChildrenMovingIn');

  useEffect(() => {
    if (numberOfPeopleMovingIn === 1) {
      setValue('numberOfChildrenMovingIn', null);
    }
  }, [numberOfPeopleMovingIn]);

  const onSuccess = async (): Promise<void> => {
    setButtonSubmitMode(SUBMIT_BUTTON_MODE.NONE);
    try {
      if (!isApplicationFolderTunnel) {
        handleSnackBar('toast.profileUpdated', 'success');
      }

      if (isApplicationFolderTunnel) {
        navigate(r(ROUTES.tenantRoutes.account.applicationFolder.introductionVideo));
      } else {
        navigate(r(ROUTES.tenantRoutes.account.overview.path));
      }
    } catch (e) {
      console.error(e);
    }
  };

  const updateTenantHousehold = async (): Promise<boolean | void> => {
    setButtonSubmitMode(SUBMIT_BUTTON_MODE.SUBMITTING);
    await tenantService.updateTenantHousehold(tenantProfile.uid, watch() as IHousehold);
    await onSuccess();
  };

  const CANCEL_BUTTON = {
    text: isApplicationFolderTunnel ? t('back') : t('cancel'),
    action: () =>
      isApplicationFolderTunnel
        ? navigate(r(ROUTES.tenantRoutes.account.applicationFolder.profile))
        : navigate(r(ROUTES.tenantRoutes.account.overview.path)),
  };

  return (
    <FormContext.Provider value={{ control }}>
      <form onSubmit={handleSubmit(updateTenantHousehold, (err) => console.error(err))}>
        <FormLayout isTunnel={isApplicationFolderTunnel} submitMode={buttonSubmitMode} notSubmitButton={CANCEL_BUTTON}>
          <PageTitle
            notSubmitButton={CANCEL_BUTTON}
            pageTitle={r(ROUTES.tenantRoutes.account.household.title)}
            showSubmitButton
            submitMode={buttonSubmitMode}
            submitText={isApplicationFolderTunnel ? 'next' : 'save'}
          />
          <Grid container spacing={4}>
            <Grid item xs={12} md={7} lg={8}>
              <Grid item xs={12}>
                <FormSectionTitle isFirst title={h('household')} />
                <TextInput
                  required
                  pattern="[0-9]*"
                  unit={
                    numberOfPeopleMovingIn > 1
                      ? t('numberOfPeopleMovingIn.unit.plural')
                      : t('numberOfPeopleMovingIn.unit.singular')
                  }
                  name={'numberOfPeopleMovingIn'}
                  type={'number'}
                  label={t('numberOfPeopleMovingIn.tenantLabel')}
                />

                {numberOfPeopleMovingIn > 1 && (
                  <TextInput
                    unit={
                      numberOfChildrenMovingIn > 1
                        ? t('numberOfChildrenMovingIn.unit.plural')
                        : t('numberOfChildrenMovingIn.unit.singular')
                    }
                    pattern="[0-9]*"
                    name={'numberOfChildrenMovingIn'}
                    type={'number'}
                    label={t('numberOfChildrenMovingIn.label')}
                  />
                )}
                <InputOptionList
                  label={h('personalInformation.sharedUsageTitle')}
                  mode={'radio'}
                  name={'isSharedUsagePossible'}
                  options={getTranslatedOptions(BOOLEAN_SELECT_OPTIONS, t)}
                  columns={RADIO_INPUT_COLUMNS_COUNT.TWO}
                />
                <InputOptionList
                  label={t('arePetsAllowed.label')}
                  mode={'radio'}
                  name={'arePetsAllowed'}
                  options={getTranslatedOptions(BOOLEAN_SELECT_OPTIONS, t)}
                  columns={RADIO_INPUT_COLUMNS_COUNT.TWO}
                />
                {watch('arePetsAllowed') && (
                  <InputOptionList
                    mode={'radio'}
                    name={'pets'}
                    options={getTranslatedOptions(TENANT_PETS_SELECT_OPTION_LIST, t)}
                    columns={RADIO_INPUT_COLUMNS_COUNT.THREE}
                    label={t('arePetsAllowed.label')}
                  />
                )}
              </Grid>

              <Grid item xs={12}>
                <FormSectionTitle title={h('income')} />
                <TextInput
                  name={'monthlyIncome'}
                  type={'number'}
                  pattern="[0-9]*"
                  label={t('view.AccountView.HouseholdView.personalInformation.monthlyIncome')}
                  unit={t('currency')}
                />
                <InputOptionList
                  label={t('hasPledge.label')}
                  mode={'radio'}
                  name={'hasPledge'}
                  options={getTranslatedOptions(BOOLEAN_SELECT_OPTIONS, t)}
                  columns={RADIO_INPUT_COLUMNS_COUNT.TWO}
                />
                <InputOptionList
                  mode={'radio'}
                  name={'canBeSubsidized'}
                  options={getTranslatedOptions(BOOLEAN_SELECT_OPTIONS, t)}
                  columns={RADIO_INPUT_COLUMNS_COUNT.TWO}
                  label={t('canBeSubsidized')}
                />
              </Grid>
            </Grid>
            <Grid item md={5} lg={4} display={{ xs: 'none', md: 'block' }}>
              <InfoText>
                <InfoTextContent />
              </InfoText>
            </Grid>
          </Grid>
        </FormLayout>
      </form>
    </FormContext.Provider>
  );
};

export default TenantHouseHoldForm;
