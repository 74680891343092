import { getDocs, limit, orderBy, query, QueryDocumentSnapshot, startAfter } from 'firebase/firestore';
import {
  FIRESTORE_COLLECTION_PATH,
  ILandlordProfile,
  INotificationSettings,
  ITenantProfile,
  IUserWithRoles,
  REGISTRATION_REFERRER,
  REGISTRATION_STATES,
  TLandlordProfileCreate,
  TTenantProfileCreate,
  USER_TYPE,
} from '@wohnsinn/ws-ts-lib';
import MixpanelTrackingService from './mixpanel-tracking.service';
import FirestoreService from './firestore.service';

class UserService {
  constructor(private firestoreService: FirestoreService, private mixpanelService: MixpanelTrackingService) {}

  public async createNewProfileType(newUserType: USER_TYPE, profile: ITenantProfile | ILandlordProfile): Promise<void> {
    let userRolesData;
    const { uid, displayName, email, photoUrl } = profile;
    const newProfile: TTenantProfileCreate | TLandlordProfileCreate = {
      displayName: displayName,
      email: email,
      photoUrl: photoUrl,
      uid: uid,
    };

    let newProfileDocPath: string;
    if (newUserType === USER_TYPE.LANDLORD) {
      userRolesData = { isLandlord: true, registrationState: REGISTRATION_STATES.SELECT_LANDLORD_TYPE };
      newProfileDocPath = `${FIRESTORE_COLLECTION_PATH.users.landlordProfiles.root.replace('{uid}', uid)}/${uid}`;
    }

    if (newUserType === USER_TYPE.TENANT) {
      userRolesData = { isTenant: true };
      newProfileDocPath = `${FIRESTORE_COLLECTION_PATH.users.tenantProfiles.root.replace('{uid}', uid)}/${uid}`;
    }

    await this.firestoreService.setDbDoc<TLandlordProfileCreate | TTenantProfileCreate>(
      newProfile,
      newProfileDocPath,
      true,
      {
        setUpdatedAt: true,
        setCreatedAt: true,
      }
    );

    const userDocPath = `${FIRESTORE_COLLECTION_PATH.users.root}/${uid}`;

    await this.firestoreService.setDbDoc<Partial<IUserWithRoles>>(userRolesData, userDocPath, true, {
      setUpdatedAt: true,
    });
  }

  public setActiveUserType(uid: string, userType: USER_TYPE): Promise<void> {
    const path = `${FIRESTORE_COLLECTION_PATH.users.root}/${uid}`;
    return this.firestoreService.setDbDoc<{ activeUserType: USER_TYPE; updatedAt: Date }>(
      { activeUserType: userType, updatedAt: new Date() },
      path
    );
  }

  public updateUserNotificationSettings(
    uid: string,
    notificationSettings: Partial<INotificationSettings>
  ): Promise<void> {
    const path = `${FIRESTORE_COLLECTION_PATH.users.root}/${uid}`;
    return this.firestoreService.setDbDoc<Partial<IUserWithRoles>>(
      {
        notificationSettings: {
          isPushEnabled: true,
          isWhatsappEnabled: notificationSettings.isWhatsappEnabled,
          isMailEnabled: notificationSettings.isMailEnabled,
        },
      },
      path
    );
  }

  public async fetchPaginatedUsers(lastDoc: QueryDocumentSnapshot | null = null, usersPerPage = 20) {
    let usersQuery = query(
      this.firestoreService.getCollectionRef<IUserWithRoles>(FIRESTORE_COLLECTION_PATH.users.root),
      orderBy('uid'),
      limit(usersPerPage)
    );

    if (lastDoc) {
      usersQuery = query(usersQuery, startAfter(lastDoc));
    }

    const querySnapshot = await getDocs(usersQuery);
    const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
    const users = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

    return {
      users,
      lastVisible, // You'll use this for the next page query
    };
  }

  /**
   * Updates the registration state on the user profile
   * @param data
   * @param uid
   */
  public async updateUserData(data: Partial<IUserWithRoles>, uid: string): Promise<void> {
    try {
      const path = `${FIRESTORE_COLLECTION_PATH.users.root}/${uid}`;
      await this.firestoreService.setDbDoc<Partial<IUserWithRoles>>({ ...data }, path);
    } catch (e) {
      console.error('Error on updateUserRegistrationState', e);
    }
  }

  /**
   * Create a default user profile after user verifies email
   * @param uid
   * @param userType
   * @param isPhoneNumberVerified
   * @param registrationState
   * @param referrer
   */
  public async createDefaultUserProfile(
    uid: string,
    userType: USER_TYPE,
    isPhoneNumberVerified = false,
    registrationState: REGISTRATION_STATES,
    referrer: REGISTRATION_REFERRER = REGISTRATION_REFERRER.NONE
  ) {
    const DEFAULT_USER: IUserWithRoles = {
      activeUserType: userType,
      isAdmin: false,
      isEmailVerified: true,
      isLandlord: userType === USER_TYPE.LANDLORD,
      isPhoneNumberVerified,
      isTenant: userType === USER_TYPE.TENANT,
      notificationSettings: { isWhatsappEnabled: true, isMailEnabled: true, isPushEnabled: true },
      referrer,
      registrationState,
      uid,
    };

    try {
      await this.firestoreService.setDbDoc<IUserWithRoles>(
        DEFAULT_USER,
        `${FIRESTORE_COLLECTION_PATH.users.root}/${uid}`,
        true,
        {
          setUpdatedAt: true,
          setCreatedAt: true,
        }
      );

      return true;
    } catch (e) {
      console.error('Error on createDefaultUserProfile', e);
    }
  }
}

export default UserService;
