import { ROUTES } from 'core/const/routes';
import { useTranslation } from 'react-i18next';
import CTACard from 'component/molecules/Cards/CTACard';
import { Grid } from '@mui/material';
import LandingPageLayout from 'component/layouts/LandingPageLayout';

const MaintenanceModeView = () => {
  const { t: r } = useTranslation('routes');

  return (
    <LandingPageLayout pageTitle={r(ROUTES.landingPageRoutes.maintenance.title)}>
      <Grid item xs={12} style={{ marginTop: 100 }}>
        <CTACard
          title={'Wartungsmodus'}
          imgAltText={'wartungsmodus'}
          imgSrc={'/assets/images/redirect/basketball.gif'}
          text={'Wir befinden uns gerade im Wartungsmodus, sind aber bald wieder für euch da!'}
        />
      </Grid>
    </LandingPageLayout>
  );
};

export default MaintenanceModeView;
