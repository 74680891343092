import { FC, PropsWithChildren, useContext, useEffect } from 'react';
import UserContext from '../../../core/context/user.context';
import mixpanel from 'mixpanel-browser';
import { useCookies } from 'react-cookie';
import TenantFilterParamsContextProvider from '../../../core/provider/TenantFilterParamsContextProvider';
import ApplicationListContextProvider from '../../../core/provider/ApplicationListContext.Provider';
const AppDataProvider: FC<PropsWithChildren> = ({ children }) => {
  const [cookies] = useCookies();

  mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
    debug: process.env.REACT_APP_ENVIRONMENT !== 'production',
  });
  const { user } = useContext(UserContext);

  useEffect(() => {
    const isAnalyticsYesOnCookieYesCookie = cookies?.['cookieyes-consent']?.includes('analytics:yes');

    if (process.env.REACT_APP_USE_EMULATOR !== 'true') {
      if (window && document) {
        document.addEventListener('cookieyes_consent_update', async function (eventData) {
          // @ts-ignore
          const data: any = eventData?.detail;
          if (data?.accepted?.includes('analytics')) {
            mixpanel.opt_in_tracking();
          } else {
            mixpanel.opt_out_tracking();
            mixpanel.has_opted_out_tracking({
              cookie_prefix: 'has_opted_out_tracking: else analytics is included',
            });
          }
        });
        if (isAnalyticsYesOnCookieYesCookie) {
          mixpanel.opt_in_tracking();
          mixpanel.track('isTrackingActive', { isTrackingActive: 'yes' });
        }
      }
      mixpanel.opt_in_tracking();
    } else {
      mixpanel.opt_in_tracking();
      mixpanel.track('emulatorActive', { isTrackingActive: 'yes' });
    }
  }, [window, document]);

  useEffect(() => {
    if (user?.uid) {
      mixpanel.identify(user.uid);
    }
  }, [user]);

  return (
    <TenantFilterParamsContextProvider>
      <ApplicationListContextProvider>{children}</ApplicationListContextProvider>
    </TenantFilterParamsContextProvider>
  );
};

export default AppDataProvider;
