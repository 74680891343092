import Text, { TEXT_COLOR, TEXT_TYPE, TEXT_VARIANT, TEXT_WEIGHT } from '../../../../component/atoms/typographie/Text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo, faComment, faDown, faMinus, faPlay, faPlus, faUp } from '@fortawesome/pro-solid-svg-icons';
import { faClipboard, faPencil, faTimes } from '@fortawesome/pro-light-svg-icons';
import { TENANT_JOB_SELECT_OPTION_LIST } from 'component/forms/tenant/TenantProfileForm';
import styles from './ApplicantsTable.module.scss';
import Avatar, { AVATAR_SIZE } from '../../../../component/atoms/Avatar';
import printDate from '../../../../core/helper/printDate';
import CTAButton, { BUTTON_STYLE } from '../../../../component/atoms/Buttons/CTAButton';
import { useTranslation } from 'react-i18next';
import { ChangeEvent, Dispatch, FC, SetStateAction, useContext, useEffect, useState } from 'react';
import { IApplication, IMedia, MATCHING_MODE } from '@wohnsinn/ws-ts-lib';
import useWindowSize from '../../../../core/hook/windowsize.hook';
import NotificationBadge, { NOTIFICATION_BADGE_SIZE } from '../../../atoms/NotificationBadge';
import SanitizedHTML from 'component/atoms/SanitzedHtml';
import UserContext from 'core/context/user.context';
import filterByAppliedFilters from 'view/landlord/apartment/ApartmentApplicationsView/filter-applications';
import { sortApplicationsByIncome } from 'view/landlord/apartment/ApartmentApplicationsView/sort-applications-helper';
import { APPLIED_FILTER } from 'view/landlord/apartment/ApartmentApplicationsView';
import RatingTabs from 'component/organisms/RatingTabs';
import { Checkbox, Paper, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import LandlordRatingButtons from 'component/molecules/LandlordRatingButtons';
import { Box } from '@mui/system';
import { visuallyHidden } from '@mui/utils';
import formatDate from 'core/helper/convert-date-to-last-message-date';
import { MODAL_IDENTIFIER } from 'core/enum/modals.enum';
import ModalContext from 'core/context/modal.context';

const ApplicantsTable: FC<{
  tenantApplicationList: IApplication[];
  setTenantApplicationList: Dispatch<SetStateAction<IApplication[]>>;
  handleApplicantInformationClick: (application: IApplication) => any;
  handleIntroductionVideoClick: (video: IMedia) => any;
  handleClickOnApplicantInfo: (application: IApplication) => any;
  handleClickOnChat: (application: IApplication) => any;
  likeApplications: IApplication[];
  maybeApplications: IApplication[];
  nopeApplications: IApplication[];
  unsortedApplications: IApplication[];
  appliedFilter: APPLIED_FILTER;
}> = ({
  handleApplicantInformationClick,
  handleIntroductionVideoClick,
  handleClickOnChat,
  tenantApplicationList,
  setTenantApplicationList,
  handleClickOnApplicantInfo,
  likeApplications,
  maybeApplications,
  nopeApplications,
  unsortedApplications,
  appliedFilter,
}) => {
  const { isSmallerMd } = useWindowSize();
  const [selectedTenantId, setSelectedTenantId] = useState<string>(null);
  const { user } = useContext(UserContext);
  const [applications, setApplications] = useState([...unsortedApplications]);
  const [ratingMode, setRatingMode] = useState<MATCHING_MODE>(MATCHING_MODE.NONE);
  const { t: a } = useTranslation('common', { keyPrefix: 'view.ApplicationOverviewView' });
  const { t } = useTranslation('common');
  const [sortAsc, setSortAsc] = useState(true);
  const { openModal } = useContext(ModalContext);

  const getSelectedList = () => {
    switch (ratingMode) {
      case MATCHING_MODE.LIKE:
        return likeApplications;
      case MATCHING_MODE.MAYBE:
        return maybeApplications;
      case MATCHING_MODE.NOPE:
        return nopeApplications;
      case MATCHING_MODE.NONE:
        return unsortedApplications;
    }
  };

  useEffect(() => {
    let list = [...getSelectedList()];
    for (const [key, value] of Object.entries(appliedFilter)) {
      list = filterByAppliedFilters(list, key, value);
    }

    setApplications(list);
  }, [ratingMode, appliedFilter, maybeApplications, nopeApplications, unsortedApplications]);

  const sortApplicantByIncome = () => {
    const sortedList = sortApplicationsByIncome([...applications], sortAsc);
    setApplications([...sortedList]);
    setSortAsc(!sortAsc);
  };

  const getJobStatus = (jobStatus: string) => {
    return TENANT_JOB_SELECT_OPTION_LIST.filter((list) => list.value === jobStatus)[0].label;
  };

  useEffect(() => {
    setTenantApplicationList([]);
  }, [applications]);

  const handleSelectAll = (e: any, checked: boolean) => {
    if (checked) {
      setTenantApplicationList(applications);
    } else {
      setTenantApplicationList([]);
    }
  };
  const handleCheckBox = (e: ChangeEvent<HTMLInputElement>, checked: boolean, application: IApplication) => {
    setTenantApplicationList((prevState) => {
      if (checked) {
        if (!prevState.includes(application)) {
          return [...prevState, application];
        }
        return prevState;
      } else {
        return prevState.filter((currentApplication) => currentApplication !== application);
      }
    });
  };

  const isEmptyOrWhitespace = (str: string): boolean => {
    // Trim leading and trailing whitespace
    const trimmedStr = str.trim();

    // Regular expression to check if the string is either empty, contains only <br> tags,
    // or contains tags that have only whitespace inside
    const emptyOrWhitespaceRegex = /^(<p>\s*<\/p>|<p>\s*<br>\s*<\/p>|<br>\s*|\s*)$/;

    return emptyOrWhitespaceRegex.test(trimmedStr);
  };

  if (isSmallerMd) {
    return (
      <div className={styles.mobile}>
        <RatingTabs
          selectedRating={ratingMode}
          setSelectedRating={setRatingMode}
          likeApplicationsLength={ratingMode === MATCHING_MODE.LIKE ? applications.length : likeApplications.length}
          maybeApplicationsLength={ratingMode === MATCHING_MODE.MAYBE ? applications.length : maybeApplications.length}
          nopeApplicationsLength={ratingMode === MATCHING_MODE.NOPE ? applications.length : nopeApplications.length}
          unsortedApplicationsLength={
            ratingMode === MATCHING_MODE.NONE ? applications.length : unsortedApplications.length
          }
        />
        {applications.map((application: IApplication) => {
          return (
            <div className={styles.element} key={`${application?.apartmentId}-${application?.tenantProfile.uid}`}>
              <div className={styles.head}>
                <Checkbox
                  checked={tenantApplicationList?.includes(application)}
                  onChange={(e, checked) => handleCheckBox(e, checked, application)}
                />
                <button
                  className={styles.infoWrapper}
                  onClick={() =>
                    setSelectedTenantId(
                      selectedTenantId === application.tenantProfile.uid ? null : application.tenantProfile.uid
                    )
                  }
                >
                  <FontAwesomeIcon
                    className={styles.plus}
                    icon={selectedTenantId === application.tenantProfile.uid ? faMinus : faPlus}
                  />
                  <Avatar size={AVATAR_SIZE.xs} avatarUrl={application?.tenantProfile?.photoUrl} />
                  <div className={styles.infoText}>
                    <Text tag={'span'} weight={TEXT_WEIGHT.TEXT_WEIGHT_REGULAR} type={TEXT_TYPE.TEXT_TYPE_CAPTION}>
                      {application?.tenantProfile?.personalInformation?.firstName}{' '}
                      {application?.tenantProfile?.personalInformation?.lastName}
                    </Text>
                    <Text
                      color={TEXT_COLOR.TEXT_COLOR_ACCENT}
                      tag={'span'}
                      type={TEXT_TYPE.TEXT_TYPE_CAPTION}
                      variant={TEXT_VARIANT.TEXT_VARIANT_SMALL}
                      weight={TEXT_WEIGHT.TEXT_WEIGHT_REGULAR}
                    >
                      {a('born')} {printDate(application?.tenantProfile?.personalInformation?.dateOfBirth, t)}
                    </Text>
                  </div>
                </button>
                <div className={styles.ctaWrapper}>
                  <CTAButton
                    buttonStyle={BUTTON_STYLE.SECONDARY}
                    buttonText={t('showApplicantInfo')}
                    hideText
                    icon={faCircleInfo}
                    onClick={() => handleApplicantInformationClick(application)}
                    size={'small'}
                  />
                  <div className={styles.unreadMessage}>
                    {application?.unreadTenantMessagesRef?.length ? (
                      <div className={styles.messagesBadge}>
                        <NotificationBadge
                          size={NOTIFICATION_BADGE_SIZE.SMALL}
                          count={application.unreadTenantMessagesRef.length}
                        />
                      </div>
                    ) : null}
                    <CTAButton
                      buttonStyle={BUTTON_STYLE.SECONDARY}
                      buttonText={a('messages')}
                      hideText
                      icon={faComment}
                      onClick={() => handleClickOnChat(application)}
                      size={'small'}
                    />
                  </div>
                </div>
              </div>
              <div
                className={`${styles.moreInfo} ${
                  selectedTenantId === application.tenantProfile.uid ? styles.show : ''
                }`}
              >
                <div>
                  <div>
                    <Text type={TEXT_TYPE.TEXT_TYPE_CAPTION} tag={'span'} weight={TEXT_WEIGHT.TEXT_WEIGHT_LIGHT}>
                      Beruf:
                    </Text>
                    <Text type={TEXT_TYPE.TEXT_TYPE_CAPTION} tag={'span'} weight={TEXT_WEIGHT.TEXT_WEIGHT_BOLD}>
                      {getJobStatus(application?.tenantProfile?.personalInformation?.jobStatus)}
                      {application?.tenantProfile?.personalInformation?.customJobDescription ? ', ' : null}{' '}
                      {application?.tenantProfile?.personalInformation?.customJobDescription
                        ? application?.tenantProfile?.personalInformation?.customJobDescription
                        : ''}
                    </Text>
                  </div>
                  <div>
                    <Text type={TEXT_TYPE.TEXT_TYPE_CAPTION} tag={'span'} weight={TEXT_WEIGHT.TEXT_WEIGHT_LIGHT}>
                      Einkommen:
                    </Text>{' '}
                    <Text
                      type={TEXT_TYPE.TEXT_TYPE_CAPTION}
                      variant={TEXT_VARIANT.TEXT_VARIANT_SMALL}
                      tag={'span'}
                      weight={TEXT_WEIGHT.TEXT_WEIGHT_LIGHT}
                    >
                      {application?.tenantProfile?.household?.monthlyIncome
                        ? `${application?.tenantProfile.household.monthlyIncome} ${t('currency')}`
                        : `0 ${t('currency')}`}
                    </Text>
                  </div>

                  <div>
                    <Text type={TEXT_TYPE.TEXT_TYPE_CAPTION} tag={'span'} weight={TEXT_WEIGHT.TEXT_WEIGHT_LIGHT}>
                      Video:
                    </Text>
                    {application?.tenantProfile?.aboutMe?.introductionVideo?.url ? (
                      <CTAButton
                        buttonStyle={BUTTON_STYLE.SECONDARY}
                        buttonText={a('playVideo')}
                        hideText
                        icon={faPlay}
                        onClick={() =>
                          handleIntroductionVideoClick(application?.tenantProfile?.aboutMe.introductionVideo)
                        }
                        size={'small'}
                      />
                    ) : (
                      <Text tag={'span'} color={TEXT_COLOR.TEXT_COLOR_ACCENT}>
                        <FontAwesomeIcon icon={faTimes} />
                      </Text>
                    )}
                  </div>
                </div>

                <div>
                  <LandlordRatingButtons
                    big={false}
                    landlordRating={application?.landlordRating}
                    tenantProfileId={application?.tenantProfile?.uid}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <>
      <RatingTabs
        selectedRating={ratingMode}
        setSelectedRating={setRatingMode}
        likeApplicationsLength={ratingMode === MATCHING_MODE.LIKE ? applications.length : likeApplications.length}
        maybeApplicationsLength={ratingMode === MATCHING_MODE.MAYBE ? applications.length : maybeApplications.length}
        nopeApplicationsLength={ratingMode === MATCHING_MODE.NOPE ? applications.length : nopeApplications.length}
        unsortedApplicationsLength={
          ratingMode === MATCHING_MODE.NONE ? applications.length : unsortedApplications.length
        }
      />
      <TableContainer component={Paper}>
        <TableHead>
          <TableRow>
            <TableCell>
              {' '}
              <Checkbox
                checked={!!tenantApplicationList?.length}
                onChange={(e, checked) => handleSelectAll(e, checked)}
              />
            </TableCell>
            <TableCell style={{ padding: 0 }}>Bild</TableCell>
            <TableCell>Chat</TableCell>
            <TableCell>{a('tableHead.birthDate')}</TableCell>
            <TableCell>{a('tableHead.job')}</TableCell>
            <TableCell>{'Notizen'}</TableCell>

            <TableCell>
              <button
                style={{ position: 'relative', display: 'flex', gap: '5px', alignItems: 'center' }}
                onClick={sortApplicantByIncome}
              >
                {a('tableHead.income')}
                <Box sx={visuallyHidden}>
                  {a('sortIncome')} {sortAsc ? a('asc') : a('desc')}
                </Box>
                <FontAwesomeIcon icon={sortAsc ? faUp : faDown} size={'xs'} />
              </button>
            </TableCell>
            <TableCell>{a('tableHead.video')}</TableCell>
            <TableCell>{a('tableHead.rating')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {applications.map((application) => (
            <TableRow key={`${application.apartmentId}-${application.tenantProfile.uid}`}>
              <TableCell>
                <Checkbox
                  checked={tenantApplicationList?.includes(application)}
                  onChange={(e, checked) => handleCheckBox(e, checked, application)}
                />
              </TableCell>
              <TableCell className={styles.link} onClick={() => handleClickOnApplicantInfo(application)}>
                <Avatar size={AVATAR_SIZE.md} avatarUrl={application?.tenantProfile?.photoUrl} />
              </TableCell>
              <TableCell className={`${styles.nameCell}`} onClick={() => handleClickOnApplicantInfo(application)}>
                <div style={{ display: 'flex' }}>
                  <div className={styles.name}>
                    <Text
                      tag={'span'}
                      weight={TEXT_WEIGHT.TEXT_WEIGHT_REGULAR}
                      variant={TEXT_VARIANT.TEXT_VARIANT_SMALL}
                    >
                      {application?.tenantProfile?.personalInformation?.firstName}{' '}
                      {application?.tenantProfile?.personalInformation?.lastName}{' '}
                    </Text>

                    <div className={styles.message}>
                      {application?.lastMessage && (
                        <Tooltip
                          title={
                            <SanitizedHTML
                              className={` ${
                                !!application.unreadTenantMessagesRef?.length &&
                                application?.lastMessageSenderId === application?.tenantProfile?.uid &&
                                styles.bold
                              }`}
                              dirty={application.lastMessage}
                              removeNewLines
                            />
                          }
                        >
                          <div className={styles.text}>
                            <SanitizedHTML
                              className={`${
                                application?.unreadTenantMessagesRef?.length &&
                                application?.lastMessageSenderId === application?.tenantProfile?.uid
                                  ? styles.bold
                                  : styles.light
                              }`}
                              dirty={application.lastMessage}
                              removeNewLines
                            />
                          </div>
                        </Tooltip>
                      )}
                    </div>
                  </div>

                  <div className={styles.unreadMessage}>
                    <Text
                      tag={'span'}
                      weight={TEXT_WEIGHT.TEXT_WEIGHT_LIGHT}
                      variant={TEXT_VARIANT.TEXT_VARIANT_SMALL}
                      type={TEXT_TYPE.TEXT_TYPE_CAPTION}
                    >
                      {!!application?.lastMessageSent ? formatDate(application?.lastMessageSent) : ''}
                    </Text>
                    {application?.unreadTenantMessagesRef?.length ? (
                      <div className={styles.messagesBadge}>
                        <NotificationBadge
                          size={NOTIFICATION_BADGE_SIZE.MEDIUM}
                          count={application?.unreadTenantMessagesRef.length}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              </TableCell>
              <TableCell>
                <Text
                  color={TEXT_COLOR.TEXT_COLOR_ACCENT}
                  tag={'span'}
                  type={TEXT_TYPE.TEXT_TYPE_CAPTION}
                  weight={TEXT_WEIGHT.TEXT_WEIGHT_REGULAR}
                >
                  {a('born')} {printDate(application?.tenantProfile?.personalInformation?.dateOfBirth, t)}
                </Text>
              </TableCell>
              <TableCell>
                <Text variant={TEXT_VARIANT.TEXT_VARIANT_SMALL} tag={'span'} weight={TEXT_WEIGHT.TEXT_WEIGHT_LIGHT}>
                  <Text variant={TEXT_VARIANT.TEXT_VARIANT_SMALL} tag={'span'} weight={TEXT_WEIGHT.TEXT_WEIGHT_LIGHT}>
                    {getJobStatus(application?.tenantProfile?.personalInformation?.jobStatus)}
                  </Text>
                  <br />
                  <Text
                    color={TEXT_COLOR.TEXT_COLOR_ACCENT}
                    tag={'span'}
                    variant={TEXT_VARIANT.TEXT_VARIANT_SMALL}
                    weight={TEXT_WEIGHT.TEXT_WEIGHT_LIGHT}
                  >
                    {application?.tenantProfile?.personalInformation?.customJobDescription
                      ? application?.tenantProfile?.personalInformation?.customJobDescription
                      : ''}
                  </Text>
                </Text>
              </TableCell>
              <TableCell>
                <CTAButton
                  buttonStyle={BUTTON_STYLE.SECONDARY}
                  buttonText={
                    application?.notes && !isEmptyOrWhitespace(application?.notes) ? t('viewNotes') : t('createNotes')
                  }
                  icon={application?.notes && !isEmptyOrWhitespace(application?.notes) ? faClipboard : faPencil}
                  onClick={() => openModal({ id: MODAL_IDENTIFIER.APPLICANT_NOTES_MODAL, data: { application } })}
                  size={'small'}
                  rounded={false}
                />
              </TableCell>
              <TableCell>
                <Text variant={TEXT_VARIANT.TEXT_VARIANT_SMALL} tag={'span'} weight={TEXT_WEIGHT.TEXT_WEIGHT_LIGHT}>
                  {application?.tenantProfile?.household?.monthlyIncome
                    ? `${application?.tenantProfile.household.monthlyIncome} ${t('currency')}`
                    : `0 ${t('currency')}`}
                </Text>
              </TableCell>
              <TableCell>
                {application?.tenantProfile?.aboutMe?.introductionVideo?.url ? (
                  <CTAButton
                    buttonStyle={BUTTON_STYLE.SECONDARY}
                    buttonText={a('playVideo')}
                    hideText
                    icon={faPlay}
                    onClick={() => handleIntroductionVideoClick(application?.tenantProfile?.aboutMe.introductionVideo)}
                    size={'small'}
                  />
                ) : (
                  <Text tag={'span'} color={TEXT_COLOR.TEXT_COLOR_ACCENT}>
                    <FontAwesomeIcon icon={faTimes} />
                  </Text>
                )}
              </TableCell>
              <TableCell>
                <LandlordRatingButtons
                  big={false}
                  landlordRating={application?.landlordRating}
                  tenantProfileId={application?.tenantProfile?.uid}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </TableContainer>
    </>
  );
};

export default ApplicantsTable;
