import { useTranslation } from 'react-i18next';
import PageLayout from '../../../component/layouts/PageLayout';
import SearchProfiles from '../../../component/organisms/SearchProfiles';
import { ROUTES } from '../../../core/const/routes';

const SearchProfilesView = () => {
  const { t: r } = useTranslation('routes');

  return (
    <PageLayout showPageTitle={false} pageTitle={r(ROUTES.tenantRoutes.searchProfiles.title)}>
      <SearchProfiles />
    </PageLayout>
  );
};

export default SearchProfilesView;
