import { useContext } from 'react';
import { FormContext } from 'core/context/form.context';
import { Controller } from 'react-hook-form';
import { ISelectOption } from '@wohnsinn/ws-ts-lib';
import { InputLabel, OutlinedInput, Select, SelectChangeEvent } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import styles from './MultiSelect.module.scss';
import FormErrorMessage from 'component/atoms/formElement/FormErrorMessage';
export interface ISelectOptionWithComingSoon<T = unknown> extends ISelectOption {
  isComingSoon?: boolean;
  toolTip?: string;
}

export interface InputSuggestionsDropdownProps<T = unknown> {
  disabled?: boolean;
  hideErrorMessage?: boolean;
  label: string;
  name: string;
  required?: boolean;
  optionList: ISelectOptionWithComingSoon<T>[];
  helperText?: string;
  placeholder?: string;
  onChange?: any;
}

const MultiSelect = (props: InputSuggestionsDropdownProps<string[]>) => {
  const { control } = useContext(FormContext);

  const { name, required, optionList, placeholder = 'Bitte wählen' } = props;
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const AvailableOptions = optionList.filter((oL) => !oL?.isComingSoon);
  const handleChange = (field: any, event: SelectChangeEvent) => {
    const {
      target: { value },
    } = event;

    field.onChange(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        return (
          <>
            <InputLabel htmlFor={name} required={required}>
              {props?.label}
            </InputLabel>
            <div className={styles.selectWrapper}>
              {(!field?.value || field?.value == '') && placeholder ? (
                <span className={styles.placeholder}>{placeholder}</span>
              ) : null}

              <Select
                fullWidth
                multiple={true}
                size={'small'}
                value={field.value ? (typeof field?.value === 'string' ? [field?.value] : field.value) : []}
                onChange={(e) => handleChange(field, e)}
                input={<OutlinedInput />}
                MenuProps={MenuProps}
                defaultValue={[]}
              >
                {AvailableOptions.map((o) => (
                  <MenuItem key={`${name}-${o.value}`} value={(o.value as string) || (o.value as number)}>
                    {o.label}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <FormErrorMessage error={error} />
          </>
        );
      }}
    />
  );
};

export default MultiSelect;
