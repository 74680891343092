import { IApartment } from '@wohnsinn/ws-ts-lib';
import { IApartmentCostsProps } from 'component/molecules/ApartmentCosts';

export const createApartmentCosts = (apartment: IApartment): IApartmentCostsProps => {
  let costs: IApartmentCostsProps = {};

  if (apartment?.cost && Object.keys(apartment.cost)?.length) {
    costs = { ...costs, apartmentCosts: apartment.cost };
  }

  if (apartment.equipment?.equipmentCosts?.kitchenPrice) {
    costs.kitchenPrice = apartment.equipment.equipmentCosts.kitchenPrice;
  }

  if (apartment.equipment?.equipmentCosts?.carParkRent) {
    costs.carParkRent = apartment.equipment.equipmentCosts.carParkRent;
  }

  if (apartment.equipment?.equipmentCosts?.carportRent) {
    costs.carportRent = apartment.equipment.equipmentCosts.carportRent;
  }

  if (apartment.equipment?.equipmentCosts?.garageRent) {
    costs.garageRent = apartment.equipment.equipmentCosts.garageRent;
  }

  if (apartment.equipment?.equipmentCosts?.freeSpaceRent) {
    costs.freeSpaceRent = apartment.equipment.equipmentCosts.freeSpaceRent;
  }

  if (apartment.equipment?.equipmentCosts?.basementGarageRent) {
    costs.basementGarageRent = apartment.equipment.equipmentCosts.basementGarageRent;
  }

  if (apartment.equipment?.equipmentCosts?.duplexRent) {
    costs.duplexRent = apartment.equipment.equipmentCosts.duplexRent;
  }

  return costs;
};
