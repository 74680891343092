import LandlordUserManagementForm from 'component/forms/landlord/LandlordUserManagementForm';
import PageTitle from '../../../../molecules/PageTitle';
import RegistrationLayout from '../../RegistrationLayout';
import UserContext from 'core/context/user.context';
import { Grid } from '@mui/material';
import { REGISTRATION_STATES } from '@wohnsinn/ws-ts-lib';
import { SUBMIT_BUTTON_MODE } from 'core/enum/submit-button-mode.enum';
import { useContext, useState } from 'react';
import { wohnsinnServices } from 'App';

const INVITE_MEMBER_FORM = 'invite-member-form';
const CreateLandlordInviteOrganizationMember = () => {
  const { userService } = wohnsinnServices;
  const { landlordProfile } = useContext(UserContext);
  const [buttonSubmitMode, setButtonSubmitMode] = useState<SUBMIT_BUTTON_MODE>(SUBMIT_BUTTON_MODE.NONE);

  const handleNextStep = (e: any) => {
    e.preventDefault();
    try {
      setButtonSubmitMode(SUBMIT_BUTTON_MODE.SUBMITTING);
      setTimeout(async () => {
        await userService.updateUserData(
          { registrationState: REGISTRATION_STATES.FINISH_LOADING_SCREEN },
          landlordProfile.uid
        );
        setButtonSubmitMode(SUBMIT_BUTTON_MODE.NONE);
      }, 500);
    } catch (e) {
      setButtonSubmitMode(SUBMIT_BUTTON_MODE.ERROR);
      console.error('Error on handlenextstep: ', e);
    }
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <PageTitle
          pageTitle={'Lade Teamkollegen zu deiner Organisation ein'}
          secondPageTitle={'Du kannst in deinem Konto jederzeit weitere Mitarbeiter einladen'}
        />

        <form id={INVITE_MEMBER_FORM} onSubmit={handleNextStep}>
          <RegistrationLayout
            isValidForm={true}
            buttonSubmitMode={buttonSubmitMode}
            formId={INVITE_MEMBER_FORM}
            submitButtonText={'Registrierung abschließen'}
          >
            <LandlordUserManagementForm isRegistration={true} />
          </RegistrationLayout>
        </form>
      </Grid>
    </Grid>
  );
};

export default CreateLandlordInviteOrganizationMember;
