import { Wistia } from 'wistia_namespace.js';

if (Wistia._remoteData == null) {
  Wistia._remoteData = {};
}

export const cacheMediaData = (hashedId, data) => {
  return (Wistia._remoteData[`media_${hashedId}`] = data);
};

export const uncacheMediaData = (hashedId) => {
  Wistia._remoteData[`media_${hashedId}`] = null;
  delete Wistia._remoteData[`media_${hashedId}`];
};
