import { useState, useEffect } from 'react';
import { IApartment, ITenantProfile } from '@wohnsinn/ws-ts-lib';

// ErrorCodes.ts
export enum ErrorCodes {
  PetsNotAllowed = 'HAUSTIER',
  NumberOfPeopleExceeded = 'numberOfPeopleMovingIn',
  SharedUsageNotAllowed = 'isSharedUsagePossible',
  HousingCertificateRequired = 'isHousingEntitlementCertificateMandatory',
}

const useDesiredTenant = (
  apartment: IApartment,
  tenantProfile: ITenantProfile
): { desiredTenantErrors: ErrorCodes[] } => {
  const [desiredTenantErrors, setDesiredTenantErrors] = useState<ErrorCodes[]>([]);

  useEffect(() => {
    const checkDesiredTenant = () => {
      if (apartment?.desiredTenant) {
        const errorArray: ErrorCodes[] = [];

        if (apartment?.desiredTenant?.arePetsAllowed === false && tenantProfile?.household?.arePetsAllowed) {
          errorArray.push(ErrorCodes.PetsNotAllowed);
        }

        if (
          apartment?.desiredTenant?.numberOfPeopleMovingIn &&
          apartment?.desiredTenant?.numberOfPeopleMovingIn < tenantProfile?.household?.numberOfPeopleMovingIn
        ) {
          errorArray.push(ErrorCodes.NumberOfPeopleExceeded);
        }

        if (
          apartment?.desiredTenant?.isSharedUsagePossible === false &&
          tenantProfile?.household?.isSharedUsagePossible
        ) {
          errorArray.push(ErrorCodes.SharedUsageNotAllowed);
        }

        if (
          apartment?.desiredTenant?.isHousingEntitlementCertificateMandatory === true &&
          tenantProfile?.household?.canBeSubsidized === false
        ) {
          errorArray.push(ErrorCodes.HousingCertificateRequired);
        }

        setDesiredTenantErrors(errorArray);
      } else {
        setDesiredTenantErrors([]);
      }
    };

    checkDesiredTenant();
  }, [apartment, tenantProfile]);

  return { desiredTenantErrors };
};

export default useDesiredTenant;
